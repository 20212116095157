import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { Account } from '../account/account';
import { PostableObjectInterface, PostableObjectError } from './../../interfaces/postable-object.inerface';

export type InviteStatus = (
  'PENDING' |
  'RESENDING' |
  'SENT' |
  ''
);

export class PartnerStaff implements PostableObjectInterface {

  partner_staff_key: number;
  user_access_key: number;
  full_name: string;
  email_address: string;
  contact_phone: string;
  owner_flag: boolean;
  admin_flag: boolean;
  pending_flag: boolean;

  invite_status: InviteStatus;

  constructor(
    partner_staff_key: number = null, user_access_key: number = null,
    full_name: string = null, email_address: string = null, contact_phone: string = null,
    owner_flag: boolean = false, admin_flag: boolean = true, pending_flag: boolean = false
  ) {
    this.partner_staff_key = partner_staff_key;
    this.user_access_key = user_access_key;
    this.full_name = full_name;
    this.email_address = email_address;
    this.contact_phone = contact_phone;
    this.owner_flag = owner_flag;
    this.admin_flag = admin_flag;
    this.pending_flag = pending_flag;

    this.invite_status = this.pending_flag ? 'PENDING' : ''
  }

  formatForPosting(toDelete: boolean = false, resendFlag: boolean = false): any {
    if (!this.hasErrors()) {
      return {
        partner_staff_key: this.partner_staff_key,
        full_name: this.full_name,
        email_address: this.email_address,
        contact_phone: this.contact_phone,
        admin_flag: this.admin_flag,
        deleted_flag: toDelete,
        resend_invite_flag: resendFlag
      };
    }
    return null;
  }

  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  getErrors(): PostableObjectError[] {
    const errors: PostableObjectError[] = [];

    if (!this.full_name) {
      errors.push({
        error_path: 'full_name',
        error_message: 'Please enter a contact name'
      });
    }
    if (!this.email_address){
      errors.push({
        error_path: 'email_address',
        error_message: 'Please enter a contact email'
      });
    }
    else if (!CoreUtilService.validateEmailAddress(this.email_address)) {
      errors.push({
        error_path: 'email_address',
        error_message: 'Please enter a valid contact email'
      });
    }

    return errors;
  }
}
