<div class="login-logo-container">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_White.svg">
</div>

<div class="ph-autoPilot-card"
     *ngIf="loading">
  <div class="ph-loadingIcon">
    <img src="assets/imgs/App_Loader.gif">
  </div>
</div>

<div class="ph-autoPilot-card ph-ap-payment-container"
     *ngIf="!loading && !error_message && !success">

  <div class="ph-ap-payment-header">
    <div class="headerCenter">Pay with Bank Transfer</div>
  </div>

  <div class="ph-autoPilot-details"
       [ngStyle]="{'opacity': loading ? '0.3':''}">

    <div class="ph-autoPilot-details-grid">
      <div>
        <p class="ph-text-grey">Pay Cycle:</p>
        <h2>{{pay_details.pay_cycle_name}}</h2>
      </div>
      <div>
        <p class="ph-text-grey">Pay Period:</p>
        <h2>{{pay_details.start_date | date: 'd'}} - {{pay_details.end_date | date: 'd MMM,'}} <span
                class="ph-text-grey">{{pay_details.end_date | date: 'yyyy'}}</span></h2>
      </div>
      <div>
        <p class="ph-text-grey">Pay Date:</p>
        <h2>{{pay_details.pay_date | date: 'd MMM,'}} <span class="ph-text-grey">{{pay_details.pay_date | date:
            'yyyy'}}</span></h2>
      </div>
      <div>
        <p class="ph-text-grey">Total Payroll:</p>
        <h2>{{pay_details.gross_total | appCurrency}}</h2>
      </div>
    </div>



    <div class="ph-ap-payment-container">
      <div class="bank-reference-details">
        <div>Please make payment manually to our bank account for <b>{{pay_details.total_payment_amount | appCurrency}}</b>
          <span *ngIf="pay_details.manual_payment_reference"> using the following details: </span>
          <span *ngIf="!pay_details.manual_payment_reference">. Payment details can be viewed on the sent pay in PayHero.</span>
        </div>
        <span *ngIf="pay_details.manual_payment_reference">
          <p class="ph-text-grey">Bank Account: <b>{{manual_bank_account_number}}</b></p>
          <p class="ph-text-grey">Reference: <b>{{pay_details.manual_payment_reference}}</b></p>
        </span>
      </div>
    </div>
    <div class="ph-autoPilot-details">
      <div>
        You can view these details at any time by <a (click)="loginToPayHeroPay()">signing in to PayHero</a>
      </div>
    </div>





  </div>
</div>


<!-- The token is no longer valid -->
<div class="ph-autoPilot-card ph-autoPilot-error-state"
     *ngIf="!loading && !!error_message && !success">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_Failed.png">
  <h3>{{error_message}}</h3>
  <p (click)="loginToPayHero()"><a>Sign In to PayHero</a></p>
</div>


<footer class="ph-footer ph-auth-footer -desktop-only">
  <img src="assets/product-imgs/payhero/PayHero_Full_Registered.svg">
</footer>
