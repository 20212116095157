import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';

type CreditCardType = (
  'americanexpress' | 'dinersclub' | 'discover' | 'jcb' | 'mastercard' | 'visa'
);

@Component({
  selector: 'credit-card-icon',
  templateUrl: './credit-card-icon.component.html',
  styleUrls: ['./credit-card-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardIconComponent implements OnChanges {

  readonly valid_card_types: Set<CreditCardType> = new Set([
    'mastercard', 'visa'
  ])

  @Input() card_type: CreditCardType = null;

  img: string = null;

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.card_type) {
      if (!!this.card_type && this.valid_card_types.has(this.card_type)) {
        this.img = 'assets/cards/' + this.card_type + 'CardSVG.svg';
      }
      else {
        this.img = null;
      }

    }
  }

}
