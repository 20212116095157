<div class="ph-formItem">
  <div class="ph-formItem-value"
       [hidden]="readonly">
    <list-drop-down #listdropdown
                    [listFillWidth]="true"
                    [sortItems]="false"
                    [itemNameProperty]="'calling_code'"
                    [itemDescriptionProperty]="'country_name'"
                    [buttonTitle]="value?.calling_code || null"
                    [buttonTitlePlaceholder]="'Country'"
                    [buttonColorClass]="'-color-white-outline'"
                    [buttonTypeClass]="'-type-dropdown'"
                    [listDisabled]="disabled"
                    [items]="countries"
                    [searchBar]="true"
                    (itemSelected)="selectCountryCode($event.item)">
    </list-drop-down>
  </div>
</div>
