import { PartnerStaff } from './../../models/partner-staff/partner-staff';

export class ClientPartnerStaff {

  partner_staff: PartnerStaff;
  account_access: Set<number>;

  constructor(
    partner_staff: PartnerStaff, account_access: Set<number>
  ) {
    this.partner_staff = partner_staff;
    this.account_access = account_access;
  }
}
