import { TimeUtilService } from './../../services/time-util/time-util.service';

export class SubscriptionCard {

  subscription_card_key: number;
  subscription_key: number;
  card_display: string;
  expiry_date: Date;
  card_name: string;
  card_type: string;
  card_img: string;

  constructor(
    subscription_card_key: number = null, subscription_key: number = null, card_display: string = null,
    expiry_date: string = null, card_name: string = null, card_type: string = null
  ) {
    this.subscription_card_key = subscription_card_key;
    this.subscription_key = subscription_key;
    this.card_display = card_display;
    this.expiry_date = TimeUtilService.dateTimeStringToDate(expiry_date);
    this.card_name = card_name;
    this.card_type = card_type;
    this.card_img = card_type ? 'assets/cards/' + card_type.toLocaleLowerCase().replace(' ','') + 'CardSVG.svg' : null
  }
}
