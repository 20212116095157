import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { AppStateDeclaration } from './../../app.types';
import { SubscriptionComponent } from './subscription.component';
import { SubscriptionDashComponent } from './subscription-dash/subscription-dash.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { SubscriptionInvoiceComponent } from './subscription-invoice/subscription-invoice.component';
import { SubscriptionRebateComponent } from './subscription-rebate/subscription-rebate.component';
import { SubscriptionAccountComponent } from './subscription-account/subscription-account.component';
import { SubscriptionBillingComponent } from './subscription-billing/subscription-billing.component';

export const SUBSCRIPTION_CONFIG: AppStateDeclaration[] = [
  {
    name: 'app.subscription',
    url: '/subscription',
    component: SubscriptionComponent,
    abstract: true
  },
  {
    name: 'app.subscription.dash',
    url: '/dash',
    component: SubscriptionDashComponent,
    default_state: true,
    params: {
      action: null,
      company_product_key: null
    }
  },
  {
    name: 'app.subscription.account',
    url: '/account?{company_product_key:int}&product_value',
    component: SubscriptionAccountComponent,
    params: {
      company_product_key: null,
      product_value: null
    }
  },
  {
    name: 'app.subscription.invoice',
    url: '/invoice',
    component: SubscriptionInvoiceComponent
  },
  {
    name: 'app.subscription.rebate',
    url: '/rebate',
    component: SubscriptionRebateComponent
  },
  {
    name: 'app.subscription.billing',
    url: '/billing',
    component: SubscriptionBillingComponent,
    params: {
      openPaymentModal: false
    }
  }
];

@NgModule({
  declarations: [
    SubscriptionComponent,
    SubscriptionDashComponent,
    SubscriptionInvoiceComponent,
    SubscriptionRebateComponent,
    SubscriptionAccountComponent,
    SubscriptionBillingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild({ states: SUBSCRIPTION_CONFIG })
  ]
})
export class SubscriptionModule { }
