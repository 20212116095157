import { Component, TemplateRef, ContentChild, HostBinding, Input, Output, EventEmitter } from '@angular/core';

import { DpListItemDirective } from './dp-list-item/dp-list-item.directive';
import { CoreUtilService } from './../../services/core-util/core-util.service';
import { SortUtilService } from './../../services/sort-util/sort-util.service';
import { InfiniteParams } from './../../app.types';

import * as  _ from 'lodash-es';

export type DpListTableHeader = {
  label: string,
  property?: string,
  desktop_only?: boolean
};

@Component({
  selector: 'dp-list',
  templateUrl: './dp-list.component.html',
  styleUrls: ['./dp-list.component.scss']
})
export class DpListComponent {

  @HostBinding('class.-listEmpty') listEmpty: boolean;
  @HostBinding('class.-alternateDesign') @Input() alternateDesign: boolean = false;

  @ContentChild(DpListItemDirective, { static: true, read: TemplateRef }) dpListItemTemplate: TemplateRef<any>;

  listId: number = Math.floor(Math.random() * 10000000);

  // Sorting ////////////
  @Input() tableHeaders: DpListTableHeader[];
  @Input() sortDisabled: boolean = false;
  @Input() primarySortProperty: string;
  @Input() secondarySortProperty: string = null;
  @Input() forwardOrder: boolean = true;

  @Input() infiniteParams: InfiniteParams = {
    rowsToRender: 40,
    indexOfLastVisibleItem: -1,
    infiniteScrollDisabled: false
  };

  @Input() itemType: string = 'item';
  @Input() itemTypePlural: string = 'items';
  @Input() noItemsMessage: string = null;
  @Input() itemSelectDisabled: boolean = false;
  @Input() canDeleteItems: boolean = false;
  @Input() canAddItems: boolean = false;
  @Input() itemDeleteDisabled: boolean = false;
  @Input() itemSelectEnabledProperty: string = '';
  @Input() itemDeleteHiddenProperty: string = '';
  @Input() itemAddDisabled: boolean = false;
  @Input() emptyDescriptionOnly: boolean = false;

  // Infinite Scroll ////////////
  private _items: any[];
  @Input()
  get items(): any[] {
    return this._items;
  }
  set items(items: any[]) {
    this._items = !this.sortDisabled ? SortUtilService.sortList(items, this.primarySortProperty, this.secondarySortProperty, this.forwardOrder) : items;
    this.reloadVisibleItems();

    if (this.loading) {
      this.loading = false;
    }
  }

  visibleItems: any[] = [];

  loading: boolean = true;

  @Output() itemSelected = new EventEmitter();
  @Output() itemDeleted = new EventEmitter();
  @Output() itemAdded = new EventEmitter();

  @Input() itemIsVisible: (item: any) => boolean = () => true;


  reloadVisibleItems() {
    CoreUtilService.reloadVisibleItems(
      this.infiniteParams,
      this.visibleItems,
      this.items,
      (item: any) => this.itemIsVisible(item)
    );

    this.listEmpty = this.items.length === 0 || this.visibleItems.length === 0;
  }

  loadMoreVisibleItems() {
    CoreUtilService.loadMoreVisibleItems(
      this.infiniteParams,
      this.visibleItems,
      this.items,
      (item: any) => this.itemIsVisible(item)
    );
  }

  orderItems(property: string = null) {
    if (property !== null && !this.sortDisabled) {
      this.forwardOrder = this.primarySortProperty === property ? !this.forwardOrder : true;
      this.primarySortProperty = property || this.primarySortProperty;

      this._items = SortUtilService.sortList(this.items, this.primarySortProperty, this.secondarySortProperty, this.forwardOrder);
      this.reloadVisibleItems();
    }
  }

  selectItem(item: any) {
    if (
      this.itemSelectDisabled ||
      (!!this.itemSelectEnabledProperty && !item[this.itemSelectEnabledProperty])
    ) {
      return;
    }
    this.itemSelected.emit({ item });
  }

  deleteItem(event: any, item: any) {
    if (!this.itemDeleteDisabled && (!this.itemDeleteHiddenProperty || !item[this.itemDeleteHiddenProperty])) {
      event.stopPropagation();
      this.itemDeleted.emit({ item });
    }
  }

  addItem(event: any, item: any) {
    if (!this.itemAddDisabled) {
      event.stopPropagation();
      this.itemAdded.emit({ item });
    }
  }

}
