<div [ngClass]="{'input-group': true, '-invalid': showError, '-typing-disabled': typing_disabled}"
     (click)="openDatePicker(false)">
  <input class="form-control"
         name="dp"
         ngbDatepicker
         #ngbDatepicker="ngbDatepicker"
         [datepickerClass]="'dropdown'"
         [container]="'body'"
         [(ngModel)]="value"
         [disabled]="disabled"
         [readonly]="readonly || typing_disabled"
         [maxDate]="max_date_struct"
         [minDate]="min_date_struct"
         [placeholder]="placeholder_title"
         (blur)="validateDate()"
         (dateSelect)="dateSelected()">
  <div class="input-group-append"
       *ngIf="!disabled && !readonly">
    <i class="bi bi-exclamation-triangle-fill"
      *ngIf="showError && errorMessage">
      <tooltip-popover [tooltipContent]="errorMessage"></tooltip-popover>
    </i>
    <button class="-type-icon -color-link-clear"
            [ngClass]="icon_button_class"
            (click)="openDatePicker(true)"
            type="button">
      <i class="ion ion-md-calendar"></i>
    </button>
  </div>
</div>
