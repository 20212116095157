import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() current_step: number = 0;
  @Input() total_steps: number = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
