
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { ProductValueAndName, ProductValue, NZRegion } from './../../app.types';
import { UserService } from './../../services/user/user.service';
import { AccountService } from './../../services/account/account.service';
import { APISearchComponent } from 'src/app/components/api-search/api-search.component';
import { SortUtilService } from 'src/app/services/sort-util/sort-util.service';

@Component({
  selector: 'app-add-account-modal',
  templateUrl: './add-account-modal.component.html',
  styleUrls: ['./add-account-modal.component.scss']
})
export class AddAccountModalComponent implements OnInit {

  @ViewChild('companyNZBNSearch') companyNZBNSearch: APISearchComponent;
  @ViewChild('numberNZBNSearch') numberNZBNSearch: APISearchComponent;

  @Input() demo_accounts_optional: boolean = false;
  @Input() demo_accounts_only: boolean = false;
  @Input() creating_partner_account: boolean = false;

  readonly regions: NZRegion[] = CoreUtilService.nz_regions;
  readonly is_partner: boolean = this.userService.is_partner;

  readonly employee_options: string[] = ['1 - 10', '11 - 25', '26 - 50', '51 - 100', '101 - 500', '500+'];
  readonly partner_karmly_warning_message: string = this.accountService.partner_karmly_warning_message;

  product_dropdown: ProductValueAndName[] = []; // Changed to any for Invoxy rebrand
  selected_product: ProductValueAndName = null; // Changed to any for Invoxy rebrand
  selected_region: NZRegion = null;
  number_of_employees: string = null;
  ird_number: string = '';

  contact_phone: string = '';

  ird_number_valid: boolean = false;

  industry_map: Record<number, string> = null;
  business_types: string[] = null;
  anzsic_class_map: Record<string, number> = null;
  selected_industry_key: number = null;
  selected_business_type: string = null;
  industries: string[];

  nzbn_access_token: string;
  nzbn_minimum_search_term_length: number = 3;
  company_nzbn_loading: boolean = false;
  number_nzbn_loading: boolean = false;

  company_name: string;
  company_nzbn: string;
  company_industry_classification: string;

  user_terms_agreed: boolean = false;

  show_demo_account_link: boolean = false;

  droppah_industries: string[] = [];
  payhero_industries: string[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public accountService: AccountService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    if (this.demo_accounts_only) {
      this.company_name = 'Demo Account'; // Default to this but let them change
    }
    this.product_dropdown = this.accountService.getAvailableProductsForAccountCreation(this.creating_partner_account);
    // Get the list of availalbe industries
    this.industry_map = this.accountService.getIndustryMap();
    this.anzsic_class_map = this.accountService.getANZSICClassMap();
    this.industries = Object.values(this.industry_map);
    this.business_types = this.accountService.getBusinessTypes();

    SortUtilService.sortList(this.industries);

    this.payhero_industries = this.industries;

    this.accountService.getNZBNAccessToken()
      .then(access_token => {
        this.nzbn_access_token = access_token;
      });
  }

  validateIrdNumber() {
    this.ird_number_valid = CoreUtilService.validateIRDNumber(this.ird_number);
  }

  validateAccountCreation(is_demo_account: boolean): boolean {
    return this.accountService.validateAccountCreation(
      is_demo_account,
      this.selected_product?.product_value || null,
      this.company_name,
      this.user_terms_agreed,
      this.contact_phone
    );
  }

  createAccount() {
    if (this.validateAccountCreation(false)) {
      const account_data: any = {
        product_value: this.selected_product.product_value,
        company_name: this.company_name,
        create_demo: false,
        sub_agreement_flag: this.user_terms_agreed,
      };

      if (
        account_data.product_value === 'PAYHERO' &&
        !account_data.create_demo
      ) {
        if (!!this.ird_number) {
          this.validateIrdNumber();

          if (this.ird_number_valid) {
            account_data.ird_number = this.ird_number;
          }
          else {
            return;
          }
        }
        if (!!this.selected_region) {
          account_data.region = this.selected_region;
        }
        if (!!this.selected_industry_key) {
          account_data.industry_key = this.selected_industry_key;
        }
        if (!!this.company_nzbn) {
          account_data.nzbn = this.company_nzbn;
        }
        if (!!this.company_industry_classification) {
          account_data.industry_classification = this.company_industry_classification;
        }
      }
      else if (account_data.product_value === 'KARMLY') {
        account_data.company_name = null; // clear so will default to name in service
      }
      else if (account_data.product_value === 'DROPPAH') {
        if (!!this.contact_phone) {
          account_data.contact_phone = this.contact_phone;
        }

        if (!!this.selected_business_type) {
          account_data.business_type = this.selected_business_type;

          // If the business type is not 'Other' then set industry to Hospitality 
          if (this.selected_business_type !== 'Other') {
            const hospitality_key: number = parseInt(Object.keys(this.industry_map).find(key => this.industry_map[key] === 'Hospitality'));
            account_data.industry_key = hospitality_key;
          }
        }
      }
      // Number of Employees applying to PH + Droppah. Want to specify here as they can change the product on the fly
      if (['PAYHERO', 'DROPPAH'].includes(account_data.product_value)) {
        if (!!this.number_of_employees) {
          account_data.number_of_employees = this.number_of_employees;
        }
      }

      this.activeModal.close(account_data);
    }
  }

  createDemoAccount() {
    if (this.validateAccountCreation(true)) {
      const account_data = {
        product_value: this.selected_product.product_value,
        company_name: this.is_partner ? this.company_name : 'Demo Account',
        create_demo: true
      };

      this.activeModal.close(account_data);
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  selectRegion(region: NZRegion) {
    this.selected_region = region;
  }

  selectNumberOfEmployees(option: string) {
    this.number_of_employees = option;
  }

  selectProduct(option: ProductValueAndName) {
    this.selected_product = option;

    this.show_demo_account_link =
      this.demo_accounts_optional &&
      !this.demo_accounts_only &&
      !!this.selected_product &&
      (this.is_partner || !this.accountService.activeSampleAccountsMap[this.selected_product.product_value]);

    if (this.selected_product.product_value === 'PAYHERO') {
      this.industries = this.payhero_industries;
    }

    if (this.selected_product.product_value === 'DROPPAH') {
      this.selected_industry_key = null;
    } else {
      this.selected_business_type = null;
    }
  }

  selectIndustry(industry: string) {
    for (const key in this.industry_map) {
      if (industry === this.industry_map[key]) {
        this.selected_industry_key = parseInt(key);
        break;
      }
    }
  }

  selectBusinessType(business_type: string) {
    if (this.business_types.includes(business_type)) {
      this.selected_business_type = business_type;
    }
  }

  companyNZBNSearchUpdated(searchTerm: string) {
    this.company_name = searchTerm;
    this.company_nzbn_loading = true;

    // Search NZBN registry
    this.accountService.searchNZBN(this.nzbn_access_token, searchTerm)
      .then(result => {
        this.companyNZBNSearch.updateSearchItems(result.search_term, result.items);
      })
      .finally(() => {
        this.company_nzbn_loading = false;
      });
  }

  numberNZBNSearchUpdated(searchTerm: string) {
    this.company_nzbn = searchTerm;
    this.number_nzbn_loading = true;

    // Search NZBN registry
    this.accountService.searchNZBN(this.nzbn_access_token, searchTerm)
      .then(result => {
        this.numberNZBNSearch.updateSearchItems(result.search_term, result.items);
      })
      .finally(() => {
        this.number_nzbn_loading = false;
      });
  }

  nzbnItemSelected(item: any, fillName: boolean, fillNZBN: boolean, fillIndustry: boolean) {

    // Company name update. 'No trading name' seems to be a default tradingName value returned when there isn't one set
    if (fillName && item.tradingNames && item.tradingNames.length && item.tradingNames[0].name !== 'No trading name') {
      this.company_name = item.tradingNames[0].name;
      this.companyNZBNSearch.searchTerm = item.tradingNames[0].name;
      this.companyNZBNSearch.searchTermInputUpdated(item.tradingNames[0].name);
    }
    else if (fillName && item.entityName) {
      this.company_name = item.entityName;
      this.companyNZBNSearch.searchTerm = item.entityName;
      this.companyNZBNSearch.searchTermInputUpdated(item.entityName);
    }

    // NZBN number update
    if (fillNZBN && item.nzbn) {
      this.company_nzbn = item.nzbn;
      this.numberNZBNSearch.searchTerm = item.nzbn;
      this.numberNZBNSearch.searchTermInputUpdated(item.nzbn);
    }

    // Industry update
    if (fillIndustry && !this.selected_industry_key) {
      this.accountService.getNZBNCompany(this.nzbn_access_token, item.nzbn)
        .then(result => {

          if (result.industryClassifications?.length) {
            const code = result.industryClassifications[0].classificationCode;
            const class_code = code.substring(0, 5);
            const industry_key = this.anzsic_class_map[class_code];

            this.selected_industry_key = industry_key;
            this.company_industry_classification = code;
          }
        });
    }
  }

}
