import { Injectable, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, ComponentRef, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject, Observable } from 'rxjs';

import { BannerConfig } from '../../app.types';
import { BackdropComponent } from './../../components/backdrop/backdrop.component';

@Injectable({
  providedIn: 'root'
})
export class DomService implements OnInit {

  backdropRef: ComponentRef<BackdropComponent> = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private transitionService: TransitionService
  ) { }

  ngOnInit() {
    this._destroyComponentsOnStateChange();
  }

  private _bannerEvent = new Subject<BannerConfig>();

  getBannerEvent(): Observable<BannerConfig> {
    return this._bannerEvent.asObservable();
  }

  showBanner(banner_config: BannerConfig) {
    this._bannerEvent.next(banner_config);
  }

  hideBanner(){
    this._bannerEvent.next(null);
  }

  private _destroyComponentsOnStateChange() {
    this.transitionService.onSuccess({}, () => {
      this._destroyComponent('backdropRef');
    });
  }

  // Update Component Input ///////////////////////////////

  // Destroy Component ////////////////////////////////////

  closeBackdrop() {
    this._destroyComponent('backdropRef');
  }

  // Create Component /////////////////////////////////////
  openBackdrop(
    backdropClicked: () => void = null,
    forceDarkBackdrop: boolean = false
  ): ComponentRef<BackdropComponent> {
    if (!this.backdropRef) {
      const element = this._createComponent('backdropRef', BackdropComponent);
      this._appendElementToDom(element);

      this.backdropRef.instance.forceDarkBackdrop = forceDarkBackdrop;

      this.backdropRef.instance.backdropClicked.subscribe(() => {
        if (backdropClicked) {
          backdropClicked();
        }
        this.closeBackdrop();
      });
    }
    return this.backdropRef;
  }

  /**
   * Generate a component instance and return a reference to its HTML Element
   */
  private _createComponent(componentRefName: string, component: any): HTMLElement {
    // Create a component reference from the component class
    this[componentRefName] = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this[componentRefName].hostView);

    // Return DOM element for component
    return (this[componentRefName].hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
  }

  /**
   * Destroy a component instance and detach its HTML Element from the DOM
   */
  private _destroyComponent(componentRefName: string) {
    if (this[componentRefName]) {
      this.appRef.detachView(this[componentRefName].hostView);
      this[componentRefName].destroy();
      this[componentRefName] = null;
    }
  }

  /**
   * Update a component's input variable
   */
  private _updateComponentInput(componentRefName: string, inputName: string, inputValue: any) {
    if (this[componentRefName] !== null) {
      this[componentRefName].instance[inputName] = inputValue;
    }
  }

  /**
   * Append an HTML Element to the DOM
   */
  private _appendElementToDom(element: HTMLElement, elementToAppendTo: HTMLElement = null) {
    if (elementToAppendTo !== null) {
      elementToAppendTo.appendChild(element);
    }
    else {
      document.body.appendChild(element);
    }
  }

}
