<a class="app-footer-userAgreement"
   *ngIf="!!support_centre_link"
   [href]="support_centre_link"
   target="_blank">
  Support Centre
</a>
<div class="-spacer" *ngIf="!!support_centre_link">·</div>
<a class="app-footer-userAgreement"
   [href]="flexitime_terms_of_use_url"
   target="_blank">
  Terms of Use
</a>
<div class="-spacer">·</div>
<a class="app-footer-userAgreement"
   [href]="flexitime_privacy_policy_url"
   target="_blank">
  Privacy Policy
</a>
