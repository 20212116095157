<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          {{is_partner ? 'Demo' : ''}} Accounts
        </div>
      </div>

      <div class="app-nav-rowRight">
        <button class="-full -color-payhero"
                *ngIf="showAddAccountButton"
                (click)="newAccount()">
          New Account
        </button>
        <button class="-full -color-payhero"
                *ngIf="showAddDemoAccountButton"
                (click)="newAccount(true)">
          New Demo Account
        </button>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window"
         [ngClass]="{ '-noAccounts': !accounts.length && !demoAccounts.length && !has_droppah_mobile_access }">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="dp-list-empty"
           *ngIf="!accounts.length && !demoAccounts.length && !has_droppah_mobile_access">
        <div class="dp-list-emptyHeader">
          No Accounts
        </div>
        <div class="dp-list-emptyDescription">
          You don't have any accounts set up
        </div>
        <button class="-type-text -color-payhero-clear"
                *ngIf="showAddAccountButton"
                (click)="newAccount()">
          New Account
        </button>
        <button class="-type-text -color-payhero-clear"
                *ngIf="showAddDemoAccountButton"
                (click)="newAccount(true)">
          New Demo Account
        </button>
      </div>

      <div class="-demoAccountsContainer">
        <account-list class="-demoAccounts"
                      *ngIf="demoAccounts.length"
                      [accounts]="demoAccounts"
                      (selectAccount)="selectAccount($event.account)"
                      (signIn)="signIn($event.account, $event.external_state_name)"
                      (deleteDemoAccount)="deleteDemoAccount($event.account)">
        </account-list>
      </div>


      <div class="app-dashListDivider"
           *ngIf="accounts.length && demoAccounts.length">
      </div>

      <div class="-accountsContainer">
        <account-list class="-accounts"
                      *ngIf="accounts.length || has_droppah_mobile_access"
                      [accounts]="accounts"
                      [show_unified_droppah_mobile_account]="true"
                      (selectAccount)="selectAccount($event.account)"
                      (subscribe)="subscribe($event.account)"
                      (signIn)="signIn($event.account, $event.external_state_name)"
                      (viewSubscription)="viewSubscription($event.account)">
        </account-list>
      </div>

    </div>

  </div>
</div>
