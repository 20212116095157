import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

import { Client } from '../../models/client/client';
import { ClientNote } from '../../models/client-note/client-note';
import { DbUtilService } from '../db-util/db-util.service';
import { SortUtilService } from './../sort-util/sort-util.service';
import { PartnerStaffService } from './../partner-staff/partner-staff.service';
import { BlobService } from '../blob/blob.service';
import { ClientPartnerStaff } from './../../models/client-partner-staff/client-partner-staff';

import { env } from './../../../environments/environment';
import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  partnerClient: Client = null;
  clients: Client[] = [];
  clientsMap: Record<number, Client> = {};

  private _accountServiceUpdateEventListener: Subscription = null;
  private _partnerStaffServiceUpdateEventListener: Subscription = null;

  serviceSetup: boolean = false;

  constructor(
    public dbUtilService: DbUtilService,
    public partnerStaffService: PartnerStaffService,
    public blobService: BlobService
  ) { }

  // Initialisation ///////////////

  initialiseService() {
    return new Promise<any>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve(this.clients);
      }
      else {
        this.initEventListeners();
        this.loadClients()
          .then(() => {
            this.serviceSetup = true;
            resolve(this.clients);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.partnerClient = null;
    this.clients = null;
    this.clientsMap = {};
    this.clearEventListeners();
    this.serviceSetup = false;
  }

  initEventListeners() {
    this._partnerStaffServiceUpdateEventListener = this.partnerStaffService.getServiceDataUpdateEvent().subscribe(() => {
      this.loadClients();
    });
  }

  clearEventListeners() {
    if (!!this._accountServiceUpdateEventListener) {
      this._accountServiceUpdateEventListener.unsubscribe();
      this._accountServiceUpdateEventListener = null;
    }
    if (!!this._partnerStaffServiceUpdateEventListener) {
      this._partnerStaffServiceUpdateEventListener.unsubscribe();
      this._partnerStaffServiceUpdateEventListener = null;
    }
  }

  // Getters ///////////////

  get partner_client(): Client {
    return this.partnerClient ? _.cloneDeep(this.partnerClient) : null;
  }

  getClients(getReference: boolean = false): Client[] {
    return getReference ? this.clients : _.cloneDeep(this.clients);
  }

  getClient(client_key: number, getReference: boolean = false): Client {
    return getReference ? this.clientsMap[client_key] : _.cloneDeep(this.clientsMap[client_key]);
  }

  // Network Calls ///////////////

  loadClients(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIGet('client', null, true)
        .then((data) => {
          this.clients = [];
          this.clientsMap = {};

          const clients = this.setupClients(data);

          for (const client of clients) {
            if (client.partner_flag) {
              this.partnerClient = client;
            }
            else {
              this.clients.push(client);
            }

            this.clientsMap[client.client_key] = client;
          }

          SortUtilService.sortList(this.clients, 'client_name');

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // Reload single client
  reloadClient(client_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIGet('client', { client_key }, true)
        .then((data) => {
          const clients = this.setupClients(data);

          for (const client of clients) {
            if (this.partnerClient?.client_key === client.client_key) {
              this.partnerClient = client;
            }
            else {
              for (let i = 0; i < this.clients.length; i++) {
                if (this.clients[i].client_key === client.client_key) {
                  this.clients[i] = client;
                }
              }
            }
            this.clientsMap[client.client_key] = client;
          }

          SortUtilService.sortList(this.clients, 'client_name');
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  saveClient(
    client: Client, toDelete: boolean = false
  ): Promise<Client> {
    return new Promise<Client>((resolve, reject) => {

      const data = client.formatForPosting(toDelete);

      this.dbUtilService.APIPost('client', data)
        .then((res) => {
          this.loadClients()
            .finally(() => {
              if (toDelete) {
                resolve(null);
              }
              else {
                resolve(this.setupClient(res));
              }
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  saveClientNote(
    note: ClientNote, toDelete: boolean = false
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = note.formatForPosting(toDelete);

      this.dbUtilService.APIPost('client/note', data)
        .then(() => {
          this.reloadClient(note.client_key)
            .finally(() => {
              resolve();
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  // Formatting /////////////

  setupClients(clientData: any[]): Client[] {
    const clients = [];
    for (const c of clientData) {
      clients.push(this.setupClient(c));
    }
    return clients;
  }

  setupClient(c: any): Client {
    const client = new Client(
      c.client_key,
      c.client_name,
      c.client_address,
      c.contact_name,
      c.contact_phone,
      c.contact_email,
      c.partner_flag,
      c.client_logo ? env.subscription.blob_url + '/client-logos/' + c.client_logo + '?time=' + new Date().getTime() : null,
      this.setupClientPartnerStaff(c.client_partner_staff || []),
      this.setupClientNotes(c.client_notes || [])
    );
    return client;
  }

  setupClientPartnerStaff(clientPartnerStaffData: any[]): any[] {
    const client_partner_staff: ClientPartnerStaff[] = [];

    for (const cps of clientPartnerStaffData) {
      const cps_access: Set<number> = new Set();

      for (const staff_company of cps.staff_companies) {
        cps_access.add(staff_company.company_product_key);
      }

      client_partner_staff.push(new ClientPartnerStaff(
        this.partnerStaffService.getPartnerStaff(cps.partner_staff_key, true),
        cps_access
      ));
    }

    return client_partner_staff;
  }

  setupClientNotes(clientNoteData: any[]): ClientNote[] {
    const notes = [];
    for (const n of clientNoteData) {
      notes.push(this.setupClientNote(n));
    }
    return notes;
  }

  setupClientNote(n: any): ClientNote {
    return new ClientNote(
      n.client_note_key,
      n.client_key,
      n.note
    );
  }

}
