import { SubscriptionLineType } from './../../app.types';
import { SubscriptionPlan } from './../subscription-plan/subscription-plan';

export class AccountSubscriptionLine {

  subscription_plan: SubscriptionPlan;
  line_description: string;
  discount_percentage: number;
  line_total: number;
  line_type: SubscriptionLineType;
  quantity: number;
  unit_fee: number;
  unit_description: string;
  unit_description_plural: string;

  constructor(
    subscription_plan: SubscriptionPlan,
    line_description: string,
    discount_percentage: number,
    line_total: number,
    line_type: SubscriptionLineType,
    quantity: number,
    unit_fee: number,
    unit_description: string,
    unit_description_plural: string
  ) {
    this.subscription_plan = subscription_plan;
    this.line_description = line_description;
    this.discount_percentage = discount_percentage;
    this.line_total = line_total;
    this.line_type = line_type;
    this.quantity = quantity;
    this.unit_fee = unit_fee;
    this.unit_description = unit_description;
    this.unit_description_plural = unit_description_plural;
  }

}
