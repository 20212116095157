import { PostableObjectInterface, PostableObjectError } from './../../interfaces/postable-object.inerface';

import { CoreUtilService } from './../../services/core-util/core-util.service';

import * as _ from 'lodash-es';

export class Partner implements PostableObjectInterface {

  partner_key: number;
  bank_account_name: string;
  bank_account_number: string;
  company_logo: string;
  company_name: string;
  company_website: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  partner_description: string;
  receive_client_rebate_flag: boolean;

  constructor(
    partner_key: number = null, bank_account_name: string = null, bank_account_number: string = null,
    company_logo: string = null, company_name: string = null, company_website: string = null,
    contact_email: string = null, contact_name: string = null, contact_phone: string = null,
    partner_description: string = null, receive_client_rebate_flag: boolean = null
  ) {
    this.partner_key = partner_key;
    this.bank_account_name = bank_account_name;
    this.bank_account_number = bank_account_number;
    this.company_logo = company_logo;
    this.company_name = company_name;
    this.company_website = company_website;
    this.contact_email = contact_email;
    this.contact_name = contact_name;
    this.contact_phone = contact_phone;
    this.partner_description = partner_description;
    this.receive_client_rebate_flag = receive_client_rebate_flag;
  }

  formatForPosting(): any {
    if (!this.hasErrors()) {
      return {
        partner_key: this.partner_key,
        bank_account_name: this.bank_account_name,
        bank_account_number: this.bank_account_number,
        company_name: this.company_name,
        company_website: this.company_website,
        contact_email: this.contact_email,
        contact_name: this.contact_name,
        contact_phone: this.contact_phone,
        partner_description: this.partner_description
      };
    }
    return null;
  }

  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  getErrors(): PostableObjectError[] {
    const errors: PostableObjectError[] = [];

    if (!this.company_name) {
      errors.push({
        error_path: 'company_name',
        error_message: 'Please enter a Partner name'
      });
    }
    if (!this.contact_name) {
      errors.push({
        error_path: 'contact_name',
        error_message: 'Please enter a contact name'
      });
    }
    if (!this.contact_email){
      errors.push({
        error_path: 'contact_email',
        error_message: 'Please enter a contact email'
      });
    }
    else if (!CoreUtilService.validateEmailAddress(this.contact_email)) {
      errors.push({
        error_path: 'contact_email',
        error_message: 'Please enter a valid contact email'
      });
    }
    if (this.bank_account_number && !CoreUtilService.validateBankAccount(this.bank_account_number)) {
      errors.push({
        error_path: 'bank_account_number',
        error_message: 'Please enter a valid bank account number'
      });
    }

    return errors;
  }
}
