<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      New {{demo_accounts_only ? 'Demo' : ''}} Account
    </div>
  </div>

  <div class="ph-modal-container">
    <div class="ph-formItem-container -smallForm">

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Product
        </div>
        <div class="ph-formItem-value">
          <list-drop-down [itemNameProperty]="'product_name'"
                          [itemProductValueProperty]="'product_value'"
                          [buttonTitle]="selected_product?.product_name"
                          [buttonTitlePlaceholder]="'Select a Product'"
                          [buttonColorClass]="'-color-white-outline'"
                          [buttonTypeClass]="'-type-dropdown'"
                          [buttonIconClass]="'ion-md-arrow-dropdown'"
                          [buttonIconAlignLeft]="false"
                          [buttonProductValue]="selected_product?.product_value"
                          [listFillWidth]="true"
                          [sortItems]="false"
                          [items]="product_dropdown"
                          (itemSelected)="selectProduct($event.item)">
          </list-drop-down>
        </div>
      </div>

      <div class="app-infoPanel -warningPanel"
           *ngIf="is_partner && selected_product?.product_value === 'KARMLY'">
        <div class="app-infoPanel-row"
             [innerHTML]="partner_karmly_warning_message | safeHtml">
        </div>
      </div>

      <ng-container *ngIf="!(is_partner && selected_product?.product_value === 'KARMLY')">

        <div class="ph-formItem"
             *ngIf="show_demo_account_link && selected_product">
          <a (click)="createDemoAccount()">
            Try a demo account&nbsp;
          </a>
          to get a feel for how {{selected_product.product_name}} works.
        </div>

        <div class="ph-formItem"
             *ngIf="!!selected_product && selected_product?.product_value !== 'KARMLY'">
          <div class="ph-formItem-label">
            {{selected_product?.product_value === 'DROPPAH' ? 'Company Name' : 'Account Name'}}
          </div>
          <div class="ph-formItem-value"
               *ngIf="!!demo_accounts_only || selected_product?.product_value !== 'PAYHERO'">
            <input class="ph-formItem-input"
                   type="text"
                   placeholder="{{selected_product?.product_value === 'DROPPAH' ? 'Company Name' : 'Account Name'}}"
                   [(ngModel)]="company_name">
          </div>
          <div class="ph-formItem-value"
               *ngIf="!demo_accounts_only && selected_product?.product_value === 'PAYHERO'">
            <api-search #companyNZBNSearch
                        [loading]="company_nzbn_loading"
                        [dropdownDisabled]="!nzbn_access_token"
                        [inputPlaceholder]="'Account Name'"
                        [minimumSearchTermLength]="nzbn_minimum_search_term_length"
                        [primaryItemProperty]="'entityName'"
                        [secondaryItemProperty]="'entityStatusDescription'"
                        [taggedItemProperty]="'nzbn'"
                        (searchTermUpdated)="companyNZBNSearchUpdated($event)"
                        (itemSelected)="nzbnItemSelected($event, true, true, true)">
            </api-search>
          </div>
        </div>


        <div class="ph-formItem"
             *ngIf="!demo_accounts_only && selected_product?.product_value === 'DROPPAH'">
          <div class="ph-formItem-label">
            Contact Phone
          </div>
          <div class="ph-formItem-value">
            <input class="form-control"
                   placeholder="Contact Phone"
                   [mask]="'00000000000'"
                   [(ngModel)]="contact_phone">
          </div>
        </div>


        <div class="ph-formItem"
             *ngIf="!demo_accounts_only && ['PAYHERO','DROPPAH'].includes(selected_product?.product_value)">
          <div class="ph-formItem-label">
            Number of Employees
          </div>
          <div class="ph-formItem-value">
            <list-drop-down [buttonTitle]="number_of_employees"
                            [buttonTitlePlaceholder]="'Number of Employees'"
                            [buttonColorClass]="'-color-white-outline'"
                            [buttonTypeClass]="'-type-dropdown'"
                            [buttonIconClass]="'ion-md-arrow-dropdown'"
                            [listHeight]="'300px'"
                            [listFillWidth]="true"
                            [sortItems]="false"
                            [items]="employee_options"
                            (itemSelected)="selectNumberOfEmployees($event.item)">
            </list-drop-down>
          </div>
        </div>

        <div class="ph-formItem"
             *ngIf="!demo_accounts_only && selected_product?.product_value === 'PAYHERO'">
          <div class="ph-formItem-label">
            Region
          </div>
          <div class="ph-formItem-value">
            <list-drop-down [buttonTitle]="selected_region"
                            [buttonTitlePlaceholder]="'Region'"
                            [buttonColorClass]="'-color-white-outline'"
                            [buttonTypeClass]="'-type-dropdown'"
                            [buttonIconClass]="'ion-md-arrow-dropdown'"
                            [listHeight]="'300px'"
                            [listFillWidth]="true"
                            [items]="regions"
                            (itemSelected)="selectRegion($event.item)">
            </list-drop-down>
          </div>
        </div>

        <div class="ph-formItem"
             *ngIf="!demo_accounts_only && selected_product?.product_value === 'PAYHERO'">
          <div class="ph-formItem-label">
            Industry
          </div>
          <div class="ph-formItem-value">
            <list-drop-down [buttonTitle]="industry_map[selected_industry_key]"
                            [buttonTitlePlaceholder]="'Industry'"
                            [buttonColorClass]="'-color-white-outline'"
                            [buttonTypeClass]="'-type-dropdown'"
                            [buttonIconClass]="'ion-md-arrow-dropdown'"
                            [listHeight]="'300px'"
                            [listFillWidth]="true"
                            [sortItems]="false"
                            [items]="industries"
                            (itemSelected)="selectIndustry($event.item)">
            </list-drop-down>
          </div>
        </div>

        <div class="ph-formItem"
             *ngIf="!demo_accounts_only && selected_product?.product_value === 'DROPPAH'">
          <div class="ph-formItem-label">
            Business Type
          </div>
          <div class="ph-formItem-value">
            <list-drop-down [buttonTitle]="selected_business_type"
                            [buttonTitlePlaceholder]="'Business Type'"
                            [buttonColorClass]="'-color-white-outline'"
                            [buttonTypeClass]="'-type-dropdown'"
                            [buttonIconClass]="'ion-md-arrow-dropdown'"
                            [listHeight]="'300px'"
                            [listFillWidth]="true"
                            [sortItems]="false"
                            [items]="business_types"
                            (itemSelected)="selectBusinessType($event.item)">
            </list-drop-down>
          </div>
        </div>
        

        <div class="ph-formItem"
             *ngIf="!demo_accounts_only && selected_product?.product_value === 'PAYHERO'">
          <div class="ph-formItem-label">
            NZBN Number
          </div>
          <div class="ph-formItem-value">
            <api-search #numberNZBNSearch
                        [loading]="number_nzbn_loading"
                        [dropdownDisabled]="!nzbn_access_token"
                        [inputPlaceholder]="'NZBN Number'"
                        [minimumSearchTermLength]="nzbn_minimum_search_term_length"
                        [primaryItemProperty]="'entityName'"
                        [secondaryItemProperty]="'entityStatusDescription'"
                        [taggedItemProperty]="'nzbn'"
                        (searchTermUpdated)="numberNZBNSearchUpdated($event)"
                        (itemSelected)="nzbnItemSelected($event, false, true, false)">
            </api-search>
          </div>
        </div>

        <div class="ph-formItem"
             *ngIf="!demo_accounts_only && selected_product?.product_value === 'PAYHERO'">
          <div class="ph-formItem-label">
            IRD Number
          </div>
          <div class="ph-formItem-value">
            <input class="ph-formItem-input"
                   type="text"
                   placeholder="000-000-000"
                   mask="000-000-000"
                   [dropSpecialCharacters]="true"
                   [ngClass]="{ '-invalid': !!ird_number && !ird_number_valid }"
                   [(ngModel)]="ird_number"
                   [ngModelOptions]="{ 'updateOn': 'blur' }"
                   (ngModelChange)="validateIrdNumber()">
          </div>
          <div class="ph-formItem-error"
               *ngIf="!!ird_number && !ird_number_valid">
            Invalid IRD Number
          </div>
        </div>

        <div class="app-auth-accessAgreement"
             *ngIf="(selected_product?.product_value === 'DROPPAH' && !demo_accounts_only) || (selected_product?.product_value === 'KARMLY')">
          <ph-checkbox [(ngModel)]="user_terms_agreed"
                       [ngModelOptions]="{ standalone: true }">
          </ph-checkbox>

          <span>I acknowledge that I have read and agree to FlexiTime's
            <a href="https://www.flexitime.works/subscription-agreement?reload"
               target="_blank">
              Subscription Agreement
            </a>
          </span>
        </div>

      </ng-container>

    </div>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="cancel()">
      Cancel
    </button>
    <button class="-color-success ph-modal-btnRight"
            [disabled]="!validateAccountCreation(demo_accounts_only)"
            (click)="demo_accounts_only ? createDemoAccount() : createAccount()">
      Create
    </button>
  </div>
</div>
