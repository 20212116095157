<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          <button class="app-nav-previousPage -type-text -desktop-only"
                  (click)="backToClients()">
            <i class="ion ion-ios-arrow-back"></i> <span>Clients</span>
          </button>
          <div class="app-nav-titleInner">
            <div>{{client.client_name}}</div>
            <i class="partnerIcon ion ion-md-person"
               *ngIf="client.partner_flag"></i>
          </div>
        </div>
      </div>

      <div class="app-nav-rowRight"
           *ngIf="!client.partner_flag">
        <list-drop-down *ngIf="clientDropdownOptions.length"
                        [itemNameProperty]="'option_name'"
                        [itemFontColorProperty]="'option_color'"
                        [buttonTypeClass]="'-type-icon'"
                        [buttonIconClass]="'ion-ios-more'"
                        [buttonColorClass]="'-color-grey-clear'"
                        [listWidth]="'300px'"
                        [sortItems]="false"
                        [items]="clientDropdownOptions"
                        (itemSelected)="dropdownOptionSelected($event.item.option_value)">
        </list-drop-down>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">
      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="clientEdit-sidePanel">

        <div class="ph-formItem-container -smallForm">

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Name
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !client.client_name}">
              {{client.client_name || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Name
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !client.contact_name}">
              {{client.contact_name || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Number
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !client.contact_phone}">
              {{client.contact_phone || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Email
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !client.contact_email}">
              {{client.contact_email || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Address
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !client.client_address}">
              {{client.client_address || '-'}}
            </div>
          </div>

          <div class="app-buttonRow">
            <button class="-type-text -color-payhero-clear"
                    *ngIf="has_partner_admin_access"
                    (click)="editClientDetails()">
              Edit Details
            </button>
          </div>
        </div>
      </div>

      <div class="clientEdit-content">
        <div class="clientEdit-list">
          <div class="ph-formItem-header">
            Accounts
          </div>

          <client-account-list class="clientEdit-list -accountList"
                               [noAccountsMessage]="'No accounts associated with ' + client.client_name"
                               [accounts]="clientAccounts"
                               (unlinkAccountFromClient)="updateAccount($event.account, true)"
                               (goToAccountSettings)="goToAccountSettings($event.account)"
                               (signIn)="signIn($event.account)">
          </client-account-list>

          <div class="app-buttonRow">
            <list-drop-down *ngIf="has_partner_admin_access"
                            [itemNameProperty]="'company_name'"
                            [itemProductValueProperty]="'product_value'"
                            [itemSortProperty]="'company_name'"
                            [buttonTypeClass]="'-type-text'"
                            [buttonColorClass]="'-color-payhero-clear'"
                            [buttonTitle]="'+ Account'"
                            [listDisabled]="loading"
                            [includeEmptyItem]="true"
                            [emptyItemLabel]="'New Account'"
                            [listName]="'accounts'"
                            [items]="unlinkedAccounts"
                            (itemSelected)="updateAccount($event.item)">
            </list-drop-down>
            <button class="-type-text -color-payhero-clear"
                    *ngIf="!has_partner_admin_access"
                    (click)="addAccount()">
              + Account
            </button>
          </div>
        </div>


        <div class="clientEdit-list">
          <div class="ph-formItem-header">
            Staff
          </div>

          <client-staff-list [client]="client"
                             (manageStaffAccountAccess)="manageStaffAccountAccess($event.client_partner_staff)"
                             (deleteStaff)="updateStaff($event.client_partner_staff.partner_staff, true)">
          </client-staff-list>

          <div class="app-buttonRow"
               *ngIf="has_partner_admin_access">
            <list-drop-down [itemNameProperty]="'full_name'"
                            [itemSortProperty]="'full_name'"
                            [buttonTypeClass]="'-type-text'"
                            [buttonColorClass]="'-color-payhero-clear'"
                            [buttonTitle]="'+ Staff'"
                            [listName]="'staff'"
                            [searchBar]="true"
                            [listDisabled]="loading || !availableStaff.length"
                            [items]="availableStaff"
                            (itemSelected)="updateStaff($event.item)">
            </list-drop-down>
          </div>
        </div>


        <div class="clientEdit-list">
          <div class="ph-formItem-header">
            Notes
          </div>

          <client-note-list [client]="client"
                            (viewNote)="manageNote($event.note, true)"
                            (editNote)="manageNote($event.note)"
                            (deleteNote)="deleteNote($event.note)">
          </client-note-list>

          <div class="app-buttonRow"
               *ngIf="has_partner_admin_access">
            <button class="-type-text -color-payhero-clear"
                    (click)="manageNote()">
              + Note
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
