<div class="megaSearch-container"
     ngbDropdown
     #megaSearchDropdown
     [placement]="alignLeft ? 'bottom-left' : 'bottom-right'"
     [container]="'body'"
     [autoClose]="'outside'"
     (openChange)="dropdownToggled($event)">

  <div class="megaSearch-inputContainer"
       [ngClass]="{ '-desktop-only': !instantSearch }"
       ngbDropdownToggle
       (click)="toggleDropdown()">
    <i class="ion ion-ios-search megaSearch-searchIcon"></i>
    <input class="megaSearch-input"
           autocomplete="off"
           #megaSearchInput
           [id]="'megaSearchInput_' + megaSearchId"
           [placeholder]="inputPlaceholder"
           [(ngModel)]="searchInput"
           (keydown)="keyPressed($event)">
    <div class="dp-remove"
         [hidden]="searchInput.length === 0"
         (click)="clearSearchInput()">
      <i class="ion ion-md-close"></i>
    </div>
    <div class="megaSearch-selectedSearchPropertyCount"
         [hidden]="searchInput.length !== 0 || selectedSearchProperties.length === 0">
      <span>{{selectedSearchProperties.length}}</span>
    </div>
  </div>

  <button class="-type-icon -mobile-only"
          *ngIf="!instantSearch"
          [ngClass]="selectedSearchProperties.length ? '-color-success-outline' : '-color-white-outline'"
          (click)="openDropdown()">
    <i class="ion ion-ios-search"></i>
  </button>

  <div ngbDropdownMenu
       class="megaSearch-dropdown"
       [ngStyle]="{ 'width': listWidth }"
       [hidden]="instantSearch">

    <div class="megaSearch-inputContainer -mobile-only"
         [ngStyle]="{ 'margin-bottom': filteredSearchProperties.length || selectedSearchProperties.length || searchInput.length ? '10px' : '' }"
         *ngIf="!instantSearch">
      <i class="ion ion-ios-search megaSearch-searchIcon"></i>
      <input class="megaSearch-input"
             autocomplete="off"
             #megaSearchInputMobile
             [id]="'megaSearchInputMobile_' + megaSearchId"
             [placeholder]="inputPlaceholder"
             [(ngModel)]="searchInput"
             (keydown)="keyPressed($event)">
      <div class="dp-remove"
           [hidden]="searchInput.length === 0"
           (click)="clearSearchInput()">
        <i class="ion ion-md-close"></i>
      </div>
      <div class="megaSearch-selectedSearchPropertyCount"
           [hidden]="searchInput.length !== 0 || selectedSearchProperties.length === 0">
        <span>{{selectedSearchProperties.length}}</span>
      </div>
    </div>

    <div class="megaSearch-dropdown-contents">

      <div class="megaSearch-dropdown-filteredProperties"
           *ngIf="filteredSearchProperties.length">
        <div class="megaSearch-dropdown-filteredProperty"
             *ngFor="let filtered of filteredSearchProperties; let i = index"
             [ngClass]="{ '-highlighted': i === highlightedFilteredSearchPropertyIndex }"
             (click)="selectFilteredSearchProperty(filtered)">
          <div class="-itemValue">
            {{allItemPropValueToUnformattedItemPropValueMap[filtered.item_prop_value]}}
          </div>
          <div class="-searchPropertyLabel">
            <span>{{searchPropertyMap[filtered.search_prop_key].label}}</span>
          </div>
        </div>
      </div>

      <div class="megaSearch-dropdown-filteredPropertiesEmpty"
           *ngIf="searchInput.length !== 0 && filteredSearchProperties.length === 0">
        <span>No results for search</span>
      </div>

      <div class="megaSearch-dropdown-spacer"
           *ngIf="searchInput.length !== 0 && selectedSearchProperties.length">
      </div>

      <div class="megaSearch-dropdown-header"
           *ngIf="selectedSearchProperties.length">
        <span>Current Filters:</span>
        <button class="-type-text -color-link-clear"
                (click)="clearAllSelectedSearchProperties()">
          Clear All
        </button>
      </div>

      <div class="megaSearch-dropdown-selectedProperties"
           *ngIf="selectedSearchProperties.length">
        <div class="megaSearch-dropdown-selectedProperty"
             *ngFor="let selected of selectedSearchProperties">
          <div class="-itemValue"
               *ngIf="selected.search_prop_key !== null">
            {{allItemPropValueToUnformattedItemPropValueMap[selected.item_prop_value]}}
          </div>
          <div class="-itemValue"
               *ngIf="selected.search_prop_key === null">
            {{globalSearchValueToUnformattedSearchValueMap[selected.item_prop_value]}}
          </div>
          <div class="-searchPropertyLabel"
               *ngIf="selected.search_prop_key !== null">
            <span>{{searchPropertyMap[selected.search_prop_key].label}}</span>
          </div>
          <div class="dp-remove"
               (click)="removeSelectedSearchProperty(selected)">
            <i class="ion ion-md-close"></i>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
