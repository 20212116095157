import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'date-divider',
  templateUrl: './date-divider.component.html',
  styleUrls: ['./date-divider.component.scss']
})
export class DateDividerComponent implements OnInit {

  constructor() { }

  @Input() date: Date;
  @Input() format: string = 'cccc, d';
  @Input() sticky: boolean = false;
  @Input() inactive: boolean = false;

  ngOnInit(): void {
  }
}
