import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';

import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { AppCurrencyPipe } from './app-currency/app-currency.pipe';
import { DateToTimePipe } from './date-to-time/date-to-time.pipe';
import { AppNumberPipe } from './app-number/app-number.pipe';
import { HoursToTimePipe } from './hours-to-time/hours-to-time.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    AppCurrencyPipe,
    DateToTimePipe,
    AppNumberPipe,
    HoursToTimePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeHtmlPipe,
    AppCurrencyPipe,
    DateToTimePipe,
    AppNumberPipe,
    HoursToTimePipe
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    HoursToTimePipe,
    DateToTimePipe
  ]
})
export class PipesModule {}
