<div class="ph-modal-content">
    <div class="ph-modal-header">
        <div class="headerLeft">
            Add Employee
        </div>
        <i class="ion ion-ios-close ph-modal-close lg-close"
           (click)="dismiss()">
        </i>
    </div>

    <div class="ph-modal-container">
        <div class="employee-info">
            <div class="employee-info-header">
            The details you'll need to add an employee:
            </div>
            <ul class="employee-info-list">
            <li>First & last name</li>
            <li>Employment start date</li>
            <li>Bank account number</li>
            <li>Payment details & rate</li>
            <li>Work pattern details</li>
            <li>IRD number</li>
            <li>Tax code</li>
            </ul>
        </div>
    </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-payhero ph-modal-btnRight"
            (click)="close()">
        <span>Get Started</span>
    </button>
  </div>
</div>
