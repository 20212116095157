import { Component, OnInit, AfterContentInit, HostBinding } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { TokenService } from './../../../services/token/token.service';
import { ModalService } from './../../../services/modal/modal.service';
import { ProductService } from './../../../services/product/product.service';
import { ProductTheme, ProductValue } from './../../../app.types';

declare var Sha1: any;

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, AfterContentInit {

  @HostBinding('class.app-page') app_page: boolean = true;

  expiredMessage = 'This link is no longer valid.';

  productTheme: ProductTheme;
  product_value: ProductValue;

  token: string = null;
  email: string = null;
  loading: boolean = false;
  submitting: boolean = false;
  login_token: string = null;

  newPassword: string = null;
  confirmPassword: string = null;
  errorMessage: string = null;

  constructor(
    private stateService: StateService,
    private tokenService: TokenService,
    private modalService: ModalService,
    private productService: ProductService,
    private uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit() {
    if (this.uiRouterGlobals.params.product){
      this.productService.current_product = this.uiRouterGlobals.params.product;
    }
    this.productTheme = this.productService.product_theme;
    this.product_value = this.productService.current_product;

    this.productService.setTitle('Password Reset');

    this.token = this.uiRouterGlobals.params.token || null;

    if (this.token) {
      this.getSubTokenInfo();
    }
    else {
      this.login();
    }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (document.getElementById('newPassword')) {
        document.getElementById('newPassword').focus();
      }
    }, 500);
  }

  hideError() {
    this.errorMessage = null;
  }

  confirm() {
    if (!this.newPassword || this.newPassword.length < 8) {
      this.errorMessage = 'Password must be at least 8 characters long';
    }
    else if (this.newPassword !== this.confirmPassword) {
      this.errorMessage = 'Passwords don\'t match';
    }
    else {
      this.errorMessage = null;
    }

    if (!this.errorMessage) {
      this.resetPassword();
    }
  }

  private resetPassword() {
    this.submitting = true;

    this.tokenService.postPasswordReset(this.token, Sha1.hash(this.newPassword))
      .then((result) => {
        this.modalService.successModal('Password Set', 'Your new password has been set.');
        if (result && result.login_token) {
          this.login_token = result.login_token;
        }
        this.login();
      })
      .catch(() => {
        this.login();
      });
  }


  private getSubTokenInfo() {
    this.loading = true;
    this.errorMessage = null;
    this.tokenService.getSubTokenInfo(this.token)
      .then((result) => {
        this.email = result.email;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        if (err) { this.modalService.errorModal(err); }
        this.login();
      });
  }

  private login() {
    this.stateService.go('login', {
      username: this.email,
      token: this.login_token
    });
  }

}
