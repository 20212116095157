export class FtAccount {

  company_code: string;
  company_name: string;
  migration_access_key: string;
  subscribed_flag: boolean;
  cancelled_flag: boolean;
  migrated_flag: boolean;
  migrated_date: Date;
  company_contact_name: string;
  company_contact_email: string;
  // Used by megasearch as a unique numeric identifier
  company_code_hash: number;

  constructor(
    company_code: string,
    company_name: string,
    migration_access_key: string,
    subscribed_flag: boolean,
    cancelled_flag: boolean,
    migrated_flag: boolean = false,
    migrated_date: Date = null,
    company_contact_name: string = null,
    company_contact_email: string = null
  ) {
    this.company_code = company_code;
    this.company_name = company_name;
    this.migration_access_key = migration_access_key;
    this.subscribed_flag = subscribed_flag;
    this.cancelled_flag = cancelled_flag;
    this.migrated_flag = migrated_flag;
    this.migrated_date = migrated_date;
    this.company_contact_name = company_contact_name;
    this.company_contact_email = company_contact_email;
    this._hashCompanyCode();
  }

  private _hashCompanyCode(): void {
    let hash = 0;
    if (this.company_code.length === 0) {
      this.company_code_hash = 0;
    };

    for (let i = 0; i < this.company_code.length; i++) {
      let char = this.company_code.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }

    this.company_code_hash = hash;
  }

}
