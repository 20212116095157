import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import * as _ from 'lodash-es';
import { ModalService } from '../../../services/modal/modal.service';
import { SortUtilService } from '../../../services/sort-util/sort-util.service';

import { TokenInvoxyService } from './../../../services/token-invoxy/token-invoxy.service';

type ApprovalProject = {
  project_name: string,
  project_key: string,
  client_name: string,
  client_key: string,
  unit_groups: ApprovalProjectUnitGroup[]
};

type ApprovalProjectUnitGroup = {
  unit_type: string,
  segments: any[],
  total: number
};


@Component({
  selector: 'app-approval-request',
  templateUrl: './approval-request.component.html',
  styleUrls: ['./approval-request.component.scss']
})
export class ApprovalRequestComponent implements OnInit {

  token: string = null
  company_domain: string = null
  expiredState: boolean = false;

  display_name: string;
  status: string;
  show_start_end_times_flag: boolean;
  currency_symbol: string;
  projects: any[];
  company_logo: string;

  time_changed: boolean = false;
  total_days: number = 0;
  total_hours: number = 0;

  reason: string = '';
  approving: boolean = false;
  declining: boolean = false;
  declined: boolean = false;
  approved: boolean = false;

  initialised: boolean = false;
  loading: boolean = false;

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public tokenInvoxyService: TokenInvoxyService,
    public stateService: StateService,
    public modalService: ModalService
  ) { }

  ngOnInit() {
    window.document.title = 'Approval Request | Invoxy';

    this.token = this.uiRouterGlobals.params.token || null;
    this.company_domain = this.uiRouterGlobals.params.domain || null;
    this.approved = this.uiRouterGlobals.params.approved || false;

    if (this.token) {
      this.getCompanyLogo().then(() => {
        this.getApprovalRequest();
      });
    }
    else {
      this.stateService.go('login', { destination: 'INVOXY_APP' })
    }
  }

  approve() {
    if (this.expiredState) return;
    this.approving = true;
    this.respond();
  }

  decline() {
    if (this.expiredState) return;
    this.declining = true;
    this.respond();
  }

  private getCompanyLogo() {
    return new Promise<any>((resolve, reject) => {
      this.tokenInvoxyService.getCompanyLogo(this.company_domain)
        .then((result) => {
          this.company_logo = result;
        }).catch((err) => {
          this.company_logo = null;
        })
        .finally(() => {
          resolve(null);
        });
    });
  }


  private getApprovalRequest() {
    this.loading = true;
    this.tokenInvoxyService.getApprovalRequest(this.token)
      .then((result) => {

        this.display_name = result.display_name;
        this.status = result.status;
        this.show_start_end_times_flag = result.show_start_end_times_flag;
        this.currency_symbol = result.money_symbol;
        this.projects = this.groupApprovalRequestSegments(result.segments);

        for (const seg of result.segments) {
          if (seg.unit_type === 'days') {
            this.total_days += seg.current_units;
          }
          else if (!seg.unit_type || seg.unit_type === 'hours') {
            this.total_hours += seg.current_units;
          }

          if (seg.current_units !== seg.verify_units) {
            this.time_changed = true;
          }
        }

        if (this.time_changed) {
          this.approved = false;
          this.modalService.errorModal('One or more records have been edited since the request was made. Please review these changes before responding.');
          this.loading = false;
        }
        else if (this.approved) {
          this.approve()
        }
        else {
          this.loading = false;
        }
      })
      .catch((err) => {
        this.expiredState = true;
        this.loading = false;
      })
      .finally(() => {
        this.initialised = true;
      });
  }

  private respond() {
    this.tokenInvoxyService.answerApproval(this.token, this.approving, this.reason)
      .then(() => {
        this.declined = this.declining;
        this.approved = this.approving;

        this.approving = false;
        this.declining = false;

        if (this.declined) this.status = 'Rejected';
        if (this.approved) this.status = 'Approved'

        this.loading = false;

      })
      .catch(() => {
        this.getApprovalRequest();

        this.approving = false;
        this.declining = false;

        this.loading = false;
      });
  }


  groupApprovalRequestSegments(segments: any[]): ApprovalProject[] {
    const approval_projects: ApprovalProject[] = [];

    for (const segment of segments) {
      let approval_project: ApprovalProject = null;

      for (const ap of approval_projects) {
        if (ap.project_key === segment.project_key) {
          approval_project = ap;
          break;
        }
      }

      if (!approval_project) {
        approval_project = {
          project_name: segment.project_name,
          project_key: segment.project_key,
          client_name: segment.client_name,
          client_key: segment.client_key,
          unit_groups: []
        };

        approval_projects.push(approval_project);
      }

      const unit_type = !segment.unit_flag ? 'hours' : segment.unit_type;

      let group = _.find(approval_project.unit_groups, { unit_type });

      if (!group) {
        group = {
          unit_type,
          segments: [],
          total: 0
        };

        approval_project.unit_groups.push(group);
      }

      group.segments.push(segment);
      group.total += segment.unit_flag ? segment.units : segment.duration;
    }

    for (const ap of approval_projects) {
      for (const ug of ap.unit_groups) {
        SortUtilService.sortList(ug.segments, 'segment_date', 'start_time');
      }
      this._sortUnitTypeGroupsByUnit(ap);
    }
    SortUtilService.sortList(approval_projects, 'project_name');

    return approval_projects;
  }

  private _sortUnitTypeGroupsByUnit(approval_project: ApprovalProject) {
    approval_project.unit_groups.sort((a, b) => {
      const a_unit_type = a.unit_type.toUpperCase().replace(' ', '');
      const b_unit_type = b.unit_type.toUpperCase().replace(' ', '');

      if (a_unit_type > b_unit_type) {
        return 1;
      }
      else if (a_unit_type < b_unit_type) {
        return -1;
      }
      return 0;
    });

    for (let i = 0; i < approval_project.unit_groups.length; i++) {
      const group = approval_project.unit_groups[i];

      if (group.unit_type === 'expense') {
        approval_project.unit_groups.splice(i, 1);
        approval_project.unit_groups.unshift(group);
        break;
      }
    }
    for (let i = 0; i < approval_project.unit_groups.length; i++) {
      const group = approval_project.unit_groups[i];

      if (group.unit_type === 'days') {
        approval_project.unit_groups.splice(i, 1);
        approval_project.unit_groups.unshift(group);
        break;
      }
    }
    for (let i = 0; i < approval_project.unit_groups.length; i++) {
      const group = approval_project.unit_groups[i];

      if (group.unit_type === 'hours') {
        approval_project.unit_groups.splice(i, 1);
        approval_project.unit_groups.unshift(group);
        break;
      }
    }
  }


}
