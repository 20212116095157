import { Component, Input, OnInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PostableObjectError } from './../../interfaces/postable-object.inerface';
import { BankAccountInputComponent } from './../../components/bank-account-input/bank-account-input.component';
import { BannerConfig, FormInputField } from './../../app.types';
import { BannerComponent } from './../../components/banner/banner.component';
import { AddressInputComponent } from './../../components/address-input/address-input.component';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { CountryInputComponent } from './../../components/country-input/country-input.component';

import * as  _ from 'lodash-es';


@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit {

  @ViewChild('banner') banner: BannerComponent;

  banner_config: BannerConfig = {
    type: 'ERROR',
    message: null
  };

  @ViewChildren('bankAccountInput') bankAccountInputs: QueryList<BankAccountInputComponent>;
  @ViewChildren('addressInput') addressInputs: QueryList<AddressInputComponent>;
  @ViewChildren('countryInput') countryInputs: QueryList<CountryInputComponent>;


  @Input() title: string;
  @Input() formFields: FormInputField[];
  @Input() object: any;

  objectCopy: any;

  new_file: File;

  formFieldErrors: Record<string, string> = {};

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.object = _.cloneDeep(this.object);
    this.objectCopy = _.cloneDeep(this.object);
  }

  close() {
    this.activeModal.dismiss();
  }


  updateFile(file: File) {
    this.new_file = file;
    this.object['file'] = this.new_file;

  }

  removeImage(remove_file_flag: Boolean) {
    if (remove_file_flag) {
      this.object['removeImage'] = remove_file_flag;
    }
  }

  save() {



    let validated = false;
    // Object implements PostableObjectInterface so use it's built in error handling
    if (!!this.object.formatForPosting && !!this.object.getErrors) {
      validated = this.validatePostableObject();
    }
    // Custom object, Use form modal's own error handling
    else {
      validated = this.validateCustomObject();
    }

    if (validated) {
      for (const formField of this.formFields) {
        this.object[formField.property] = this.objectCopy[formField.property];
      }

      this.activeModal.close(this.object);
    }
  }

  validatePostableObject(): boolean {
    if (!!this.object.formatForPosting && !!this.object.getErrors) {
      const po_errors: PostableObjectError[] = this.objectCopy.getErrors();
      const ff_errors: Record<string, string> = {};

      for (const error of po_errors) {
        let error_matches_form_field = false;

        for (const form_field of this.formFields) {
          if (form_field.property === error.error_path) {
            ff_errors[error.error_path] = error.error_message;
            error_matches_form_field = true;
          }
        }

        if (!error_matches_form_field) {
          this.banner_config.message = error.error_message;
        }
      }

      if (this.banner_config.message !== null) {
        this.banner?.showBanner();
      }
      this.formFieldErrors = ff_errors;
      return po_errors.length === 0;
    }
    return false;
  }

  validateCustomObject(): boolean {
    this.banner_config.message = null;
    const ff_errors: Record<string, string> = {};
    let error_found = false;

    for (const form_field of this.formFields) {

      if (['bank', 'address', 'staff-role', 'country'].indexOf(form_field.field_type) === -1) {
        // Invalid email
        if (form_field.field_type === 'email' && !CoreUtilService.validateEmailAddress(this.objectCopy[form_field.property])) {
          ff_errors[form_field.property] = 'Invalid email address';
        }
        // Required field
        else if (!this.objectCopy[form_field.property] && form_field.field_required) {
          ff_errors[form_field.property] = form_field.label + ' required';
        }
      }
    }
    this.formFieldErrors = ff_errors;

    // BankAccountInput has its own error handling
    const bank_account_input_components = this.bankAccountInputs.toArray();
    for (const bank_account_input of bank_account_input_components) {
      if (!bank_account_input.validateBankAccount()) {
        error_found = true;
      }
    }

    // AddressInput has its own error handling
    const address_input_components = this.addressInputs.toArray();
    for (const address_input of address_input_components) {
      if (!address_input.validateAddress()) {
        error_found = true;
      }
    }

    // CountryInput has its own error handling
    const country_input_components = this.countryInputs.toArray();
    for (const country_input of country_input_components) {
      if (!country_input.validateCountry()) {
        error_found = true;
      }
    }

    return !error_found;
  }

  clearFormFieldError(property: string): void {
    this.clearBannerError();
    if (this.formFieldErrors[property]) {
      this.formFieldErrors[property] = null;
    }
  }

  clearBannerError() {
    this.banner_config.message = null;
  }

  triggerFieldVisibility(form_field: FormInputField) {
    form_field.field_visibility_requires_button_click = false;
  }

}
