import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { CoreUtilService } from './../../../services/core-util/core-util.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { AuthService } from '../../../services/auth/auth.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { BannerConfig } from '../../../app.types';
import { BannerComponent } from './../../../components/banner/banner.component';
import { PartnerService } from 'src/app/services/partner/partner.service';
import { ProductService } from './../../../services/product/product.service';
import { DomService } from 'src/app/services/dom/dom.service';

import * as _ from 'lodash-es';
import { RedirectService } from '../../../services/redirect/redirect.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { Subscription } from 'rxjs';
import { SubscriptionInvoice } from 'src/app/models/subscription-invoice/subscription-invoice';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AccountService } from 'src/app/services/account/account.service';

type NavMenuItem = {
  name: string,
  root_state: string,
  imgClass?: string,
  itemSelected: () => void
};

type NavMenuItemChild = {
  name: string,
  state: string,
  itemSelected: () => void
};

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @HostBinding('class.-menuOpen') private _menuOpen: boolean = false;

  @ViewChild('banner') banner: BannerComponent;

  readonly is_partner: boolean = this.stateAccessService.isPartner;
  readonly is_partner_owner: boolean = this.stateAccessService.isPartnerOwner;

  readonly defaultStickiedStates: Record<string, string> = {
    'app.client': 'app.client.dash',
    'app.account': 'app.account.dash',
    'app.subscription': 'app.subscription.dash',
    'app.staff': 'app.staff.dash',
    'app.partner': 'app.partner.edit',
    'app.profile': 'app.profile.edit'
  };

  readonly stickieableStates: Record<string, string[]> = {
    'app.client': ['dash'],
    'app.account': ['dash', 'partnerDash'],
    'app.subscription': ['dash', 'billing', 'invoice', 'rebate'],
    'app.staff': ['dash'],
    'app.partner': ['edit'],
    'app.profile': ['edit']
  };

  readonly nonMenuStates: string[] = [
    'app.account.create'
  ];

  readonly navMenuConfig: Record<string, NavMenuItem> = {
    'app.client': {
      name: 'Clients',
      root_state: 'app.client',
      itemSelected: () => {
        this.goToStickiedState('app.client');
        this.closeMenuIfMobile();
      }
    },
    'app.account': {
      name: 'My Accounts',
      root_state: 'app.account',
      itemSelected: () => {
        if (this.activeRootState !== 'app.account') {
          this.goToStickiedState('app.account');
        }
      }
    },
    'app.subscription': {
      name: 'Billing',
      root_state: 'app.subscription',
      itemSelected: () => {
        if (this.activeRootState !== 'app.subscription') {
          this.goToStickiedState('app.subscription');
        }
      }
    },
    'app.staff': {
      name: 'Staff',
      root_state: 'app.staff',
      itemSelected: () => {
        this.goToStickiedState('app.staff');
        this.closeMenuIfMobile();
      }
    },
    'app.profile': {
      name: 'User Access',
      root_state: 'app.profile',
      itemSelected: () => {
        this.goToStickiedState('app.profile');
        this.closeMenuIfMobile();
      }
    },
    'app.partner': {
      name: 'Manage Partner',
      root_state: 'app.partner',
      itemSelected: () => {
        this.goToStickiedState('app.partner');
        this.closeMenuIfMobile();
      }
    }
  };

  readonly navMenuChildrenConfig: Record<string, Record<string, NavMenuItemChild>> = {
    'app.account': {
      'app.account.dash': {
        name: 'Demo Accounts',
        state: 'app.account.dash',
        itemSelected: () => {
          this.goToState('app.account.dash');
          this.closeMenuIfMobile();
        }
      },
      'app.account.partnerDash': {
        name: 'Partner Accounts',
        state: 'app.account.partnerDash',
        itemSelected: () => {
          this.goToState('app.account.partnerDash');
          this.closeMenuIfMobile();
        }
      }
    },
    'app.subscription': {
      'app.subscription.dash': {
        name: 'Subscription',
        state: 'app.subscription.dash',
        itemSelected: () => {
          this.goToState('app.subscription.dash');
          this.closeMenuIfMobile();
        }
      },
      'app.subscription.billing': {
        name: 'Payment Details',
        state: 'app.subscription.billing',
        itemSelected: () => {
          this.goToState('app.subscription.billing');
          this.closeMenuIfMobile();
        }
      },
      'app.subscription.invoice': {
        name: 'Invoice History',
        state: 'app.subscription.invoice',
        itemSelected: () => {
          this.goToState('app.subscription.invoice');
          this.closeMenuIfMobile();
        }
      },
      'app.subscription.rebate': {
        name: 'Rebates',
        state: 'app.subscription.rebate',
        itemSelected: () => {
          this.goToState('app.subscription.rebate');
          this.closeMenuIfMobile();
        }
      }
    }
  };

  menuItems: NavMenuItem[][];
  menuItemChildren: Record<string, NavMenuItemChild[]>;

  selectedMenuItemIndex: number = null;

  stateChangeSuccessListener: any;

  activeStateIsStickieable: boolean;
  activeRootState: string;
  activeStateIsNonMenu: boolean;

  navHeaderImage: string;

  banner_config: BannerConfig = {
    type: null,
    message: '',
    auto_hide_timer: null
  };

  error: string;

  event_subscriptions: Subscription[] = [];

  private _activeState: string;
  get activeState(): string {
    return this._activeState;
  }
  set activeState(state: string) {
    this._activeState = state;

    const end_index = CoreUtilService.indexOfNthOccurance(
      this._activeState, '.', 2
    );
    this.activeRootState = this._activeState.slice(0, end_index);
    this.activeStateIsStickieable = this._checkActiveStateIsStickieable();
  }

  get menuOpen(): boolean {
    return this._menuOpen;
  }
  set menuOpen(menuOpen: boolean) {
    this._menuOpen = menuOpen;
    if (!this._menuOpen) {
      this.selectedMenuItemIndex = null;
    }
  }

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public uiRouterGlobals: UIRouterGlobals,
    public transitionService: TransitionService,
    public authService: AuthService,
    public stateDataService: StateDataService,
    public domService: DomService,
    public productService: ProductService,
    public partnerService: PartnerService,
    public redirectService: RedirectService,
    public subscriptionService: SubscriptionService,
    public modalService: ModalService,
    public accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.activeState = this.uiRouterGlobals.current.name;
    this.checkNonMenuState();

    this.updateSuspensionBanner();

    this.initMenuItems();
    this.initEventListeners();

    this.navHeaderImage = this.productService.productConfigs.FLEXITIME.colorLogo;
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  initMenuItems() {
    const active_nav_items = this.stateAccessService.activeNavItems;

    const menu_items: NavMenuItem[][] = [[], []];
    const menu_item_children: Record<string, NavMenuItemChild[]> = {};

    for (let i = 0; i < active_nav_items.length; i++) {
      for (const active_nav_item of active_nav_items[i]) {

        const root_state = active_nav_item.root_state;
        const item: NavMenuItem = _.cloneDeep(this.navMenuConfig[root_state]);
        const item_children = [];

        if (active_nav_item.states.length > 1) {
          for (const state of active_nav_item.states) {
            item_children.push(_.cloneDeep(this.navMenuChildrenConfig[root_state][state]));
          }
        }

        menu_items[i].push(item);
        menu_item_children[root_state] = item_children;
      }
    }

    if (this.stateAccessService.isPartner) {
      menu_items[0].push({
        name: 'Reporting',
        root_state: null,
        imgClass: 'ion-md-open',
        itemSelected: () => {
          this.redirectService.redirectToFlexiTimeApp();
        }
      });
      menu_items[1].push({
        name: 'Partner Resources',
        root_state: null,
        imgClass: 'ion-md-open',
        itemSelected: () => {
          this.goToPartnerResources();
        }
      });
    }
    menu_items[1].push({
      name: 'Sign Out',
      root_state: null,
      imgClass: 'ion-ios-log-out',
      itemSelected: () => {
        this.manualLogout();
      }
    });

    this.menuItems = menu_items;
    this.menuItemChildren = menu_item_children;
  }

  initEventListeners() {
    this.stateChangeSuccessListener = this.transitionService.onSuccess({}, (trans) => {
      this.activeState = trans.to().name;
      this.checkNonMenuState();
      this.updateStickiedState();
    });
    this.domService.getBannerEvent().subscribe((banner_config) => {
      if (banner_config !== null) {
        this.showBanner(banner_config);
      }
      else {
        this.hideBanner();
      }
    });

    this.event_subscriptions.push(
      this.subscriptionService.getServiceDataUpdateEvent().subscribe(() => {
        this.updateSuspensionBanner();
      })
    );
  }

  updateSuspensionBanner() {
    const subscription = this.subscriptionService.getSubscription();
    const suspended_invoices: SubscriptionInvoice[] = this.subscriptionService.checkSuspended();

    const all_accounts = this.accountService.getAllAccounts(['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO']);
    const unsuspended_accounts = _.filter(all_accounts, (account) => !account.suspended_flag);

    if (!!suspended_invoices && !!suspended_invoices.length) {
      let message = 'Your ' + (unsuspended_accounts.length ? 'subscribed' : '') + ' accounts have been suspended because you have '
        + (suspended_invoices.length === 1 ? 'an overdue invoice.' : 'overdue invoices.');

      if (this.is_partner && !this.is_partner_owner) {
        message += ' Contact ' + subscription.billing_email + ' to resolve this issue.';
      }

      this.showBanner({
        message: message,
        type: 'SUSPENDED',
        hide_disabled: true,
        button_config: (!this.is_partner || !!this.is_partner_owner) ? {
          button_title: suspended_invoices.length === 1 ? 'Pay Invoice' : 'Pay Invoices',
          button_function: () => this.payInvoicesModal(
            suspended_invoices,
            subscription.subscription_card,
            subscription.payment_method,
            subscription.billing_email
          )
        } : null
      });
    } else {
      this.hideBanner();
    }
  }

  payInvoicesModal(suspended_inovices, subscription_card, payment_method, billing_email) {
    this.modalService.payInvoiceModal(suspended_inovices, subscription_card, payment_method, billing_email)
      .then(() => {
        this._toggleBannerloadingState(true);

        this.subscriptionService.paySuspendedInvoices()
          .then(() => {
            this.accountService.loadAccounts()
              .then(() => {
                this._toggleBannerloadingState(false);

                this.modalService.successModal(
                  'Payment Received',
                  null,
                  'Thanks for paying your invoices. <br> You can now access any previously suspended accounts.'
                );
              })
              .catch(() => this._toggleBannerloadingState(false));
          })
          .catch(() => this._toggleBannerloadingState(false));
      })
      .catch(() => this._toggleBannerloadingState(false));
  }

  private _toggleBannerloadingState(is_loading: boolean) {
    this.banner_config.show_spinner = is_loading;
    this.banner_config.button_config.disabled = is_loading;
  }

  back() {
    this.stateChangeService.back();
  }

  updateStickiedState() {
    const currentState = this.uiRouterGlobals.current.name;

    const index = CoreUtilService.indexOfNthOccurance(currentState, '.', 2);
    const rootState = currentState.substring(0, index);
    const subState = currentState.substring(index + 1);

    if (
      this.stickieableStates[rootState] &&
      this.stickieableStates[rootState].indexOf(subState) !== -1
    ) {
      this.stateDataService.setStickiedMenuState(rootState, subState);
    }
  }

  goToStickiedState(rootState: string) {
    if (this.activeState.indexOf(rootState) === 0) {
      return;
    }

    const stickiedState = this.stateService.get(this.stateDataService.getStickiedMenuState(rootState));

    if (stickiedState !== null) {
      this.stateService.go(stickiedState.name, null);
    }
    else {
      this.stateService.go(this.defaultStickiedStates[rootState]);
    }
  }

  goToState(state: string) {
    if (this.activeState.indexOf(state) === 0) {
      return;
    }

    this.stateService.go(state);
  }

  manualLogout() {
    this.authService.logout();
  }

  goToPartnerResources() {
    window.open('https://www.flexitime.works/partner-resources', '_blank');
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
  }

  closeMenuIfMobile() {
    if (CoreUtilService.is_mobile) {
      this.closeMenu();
    }
  }

  checkNonMenuState() {
    this.activeStateIsNonMenu = this.nonMenuStates.find((state) => state === this.activeState) !== undefined;
  }

  showErrorToast(message: string) {
    this.error = message;

    setTimeout(() => {
      if (this.error === message) {
        this.error = '';
      }
    }, 5000);
  }

  showBanner(banner_config: BannerConfig) {
    this.banner_config = banner_config;
    setTimeout(() => this.banner?.showBanner());
  }

  hideBanner() {
    this.banner?.hideBanner();
  }

  private _checkActiveStateIsStickieable(): boolean {
    const start_index = CoreUtilService.indexOfNthOccurance(
      this._activeState, '.', 2
    ) + 1;
    const sub_state = this._activeState.slice(start_index);
    return this.stickieableStates[this.activeRootState]?.indexOf(sub_state) !== -1;
  }

  // private _getAccountsMenuItemName(): string {
  //   if (!this.is_partner){
  //     return 'My Accounts'
  //   }
  //   else {
  //     return this.partnerService.getAllPartnerAccounts(['EXCLUDE_CANCELLED', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS'], null, true)?.length > 0 ? 'Accounts' : 'My Accounts';
  //   }
  // }

  private _clearEventListeners(): void {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.event_subscriptions = [];
  }

}
