<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      {{modal_title}}
    </div>
  </div>

  <div class="ph-modal-container">

    <ng-container *ngFor="let agreement of updated_agreements; let i = index">
      <div class="uaaModal-agreement-container">
        <div class="uaaModal-agreement-name">
          {{agreement.name}}
        </div>
        <div class="uaaModal-agreement-description"
             *ngIf="!!agreement.message"
             [innerHTML]="agreement.message | safeHtml">
        </div>
        <div class="uaaModal-agreement-link">
          <a [href]="agreement.url"
             target="_blank">
            Click here</a>
          to view the {{agreement.name}}
        </div>
      </div>

      <div class="-divider"
           *ngIf="i !== updated_agreements.length - 1">
      </div>
    </ng-container>

    <div class="uaaModal-checkbox-container">
      <ph-checkbox [(ngModel)]="agree_checked"></ph-checkbox>
      <div class="uaaModal-checkbox-description"
           [innerHTML]="checkbox_description | safeHtml"
           (click)="toggleCheckbox()">
      </div>
    </div>

  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="cancel()">
      Cancel
    </button>
    <button class="-color-success ph-modal-btnRight"
            [disabled]="!agree_checked"
            (click)="accept()">
      Accept
    </button>
  </div>
</div>
