<div class="app-auth-full-container">

  <div class="ph-loadingIcon"
      *ngIf="loading">
    <img src="assets/imgs/App_Loader.gif">
  </div>

  <img class="app-auth-logo"
      [ngClass]="{'-externalPage' : product_value === 'DROPPAH'}"
      [src]="productTheme.headerLogo">

  <div class="app-auth-contentContainer">

    <div class="-productGraphic"
        *ngIf="product_value === 'DROPPAH' && productTheme?.graphicLeft">
      <img [src]="productTheme.graphicLeft">
    </div>

    <div class="app-auth-card"
        *ngIf="!loading">

      <div class="ph-loadingIcon"
          *ngIf="submitting">
        <img src="assets/imgs/App_Loader.gif">
      </div>

      <h2 *ngIf="product_value !== 'DROPPAH'">Reset Password</h2>

      <div class="app-auth-card-inner"
          [ngClass]="{ '-submitting': loading }">

        <div class="app-auth-pageTitleContainer -expandTitle"
            *ngIf="product_value === 'DROPPAH'">
          <h2 class="app-auth-pageTitle">Reset your Password</h2>
        </div>

        <form class="app-auth-form"
              (ngSubmit)="confirm()"
              [ngStyle]="{'opacity': (submitting) ? '0.3':''}">

          <input class="app-input -inputLarge -disabled"
                disabled
                value={{email}} />

          <div class="app-auth-errorMessage"
              *ngIf="errorMessage">
            <i class="ion ion-ios-warning sm-icon"></i> {{errorMessage}}
          </div>

          <input class="app-input -inputLarge"
                type="password"
                placeholder="New Password"
                id="newPassword"
                name="newPassword"
                [(ngModel)]="newPassword"
                (ngModelChange)="hideError()"
                [autofocus]>

          <input class="app-input -inputLarge"
                type="password"
                placeholder="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                [(ngModel)]="confirmPassword"
                (ngModelChange)="hideError()">


          <button class="app-auth-submit"
                  [disabled]="submitting"
                  [ngClass]="{ '-color-flexitime-yellow' : product_value !== 'DROPPAH' }">
            {{submitting ? "Confirming..." : "Confirm"}}
          </button>
        </form>
      </div>
    </div>
    <div class="-productGraphic"
        *ngIf="product_value === 'DROPPAH' && productTheme?.graphicRight">
      <img [src]="productTheme.graphicRight">
    </div>

  </div>
</div>

<footer class="app-footer">
  <div class="app-footer-fromFlexitime"
       *ngIf="product_value !== 'DROPPAH'">
    <img [src]="productTheme.loginFooterProductLogos.FLEXITIME" />
  </div>

  <a class="app-footer-byFlexitime"
     *ngIf="product_value === 'DROPPAH'"
     href="https://www.flexitime.works"
     target="_blank">
    <img [src]="productTheme.fromFlexiTimeLogo">
  </a>
</footer>
