<div ngbDropdown
     #ngbDropdown="ngbDropdown"
     [id]="'ngbDropDown_' + dropdownId"
     [title]="buttonTitle || ''"
     [placement]="dropUp ? (alignLeft ? 'top-left' : 'top-right') : (alignLeft ? 'bottom-left' : 'bottom-right')"
     [container]="disableBodyAppend ? null : 'body'"
     [autoClose]="listAutoClose && !itemNestedListProperty ? true : 'outside'"
     (openChange)="dropdownToggled($event)">

  <button tabindex="-1"
          [ngClass]="[buttonTypeClass, buttonColorClass]"
          [class.-invalid]="showError"
          [ngStyle]="{
            'text-transform': buttonTitleUppercase ? 'uppercase' : ''
          }"
          [disabled]="listDisabled"
          (mousedown)="determineDropAlignment($event)"
          ngbDropdownToggle>

    <i class="ion"
       *ngIf="buttonIconClass && buttonIconAlignLeft"
       [ngClass]="buttonIconClass">
    </i>

    <div class="buttonColor"
         *ngIf="buttonFillColour || buttonTypeClass === '-type-colourpicker'"
         [ngStyle]="{
           'background-color': buttonFillColour,
           'border': buttonFillColour ? '' : '1px solid #dedede' }">
    </div>
    <product-logo *ngIf="buttonProductValue"
                  [productValue]="buttonProductValue"
                  [iconOnly]="true">
    </product-logo>
    <img *ngIf="buttonImgIcon"
         [src]="'../../../assets/icon/' + buttonImgIcon">
    <div class="-colorPrefix"
         *ngIf="buttonColorPrefix"
         [ngStyle]="{ 'background-color': buttonColorPrefix }">
    </div>
    <span class="-textPrefix"
          *ngIf="buttonTitlePrefix">
      {{buttonTitlePrefix}}
    </span>
    <span class="-title"
          *ngIf="buttonTitle || buttonTitlePlaceholder"
          [ngStyle]="{ 'margin-right': buttonDescription ? 0 : '' }"
          [ngClass]="{ '-titlePlaceholder': ((!buttonTitle && buttonTitlePlaceholder) || (!!buttonTitlePlaceholder && buttonTitle === buttonTitlePlaceholder)) && !buttonTitlePlaceholderDark }">
      {{buttonTitle || buttonTitlePlaceholder}}
      <span *ngIf="buttonDescription"
            class="-description">
        {{buttonDescription}}
      </span>
    </span>


    <i class="bi bi-exclamation-triangle-fill"
       *ngIf="showError && errorMessage">
      <tooltip-popover [tooltipContent]="errorMessage"></tooltip-popover>
    </i>

    <i class="ion"
       *ngIf="buttonIconClass && !buttonIconAlignLeft"
       [ngClass]="buttonIconClass">
    </i>

    <tooltip-popover *ngIf="buttonTooltip"
                     [tooltipContent]="buttonTooltip">
    </tooltip-popover>
  </button>

  <ul ngbDropdownMenu
      class="listDropDownUL"
      [ngStyle]="{
        'min-width': listWidth || '',
        'max-width': listWidth || '',
        'max-height': 'min(' + listHeight + ', 50vh)' }">

    <div class="listDropDown-header -title"
         *ngIf="listTitle">
      <div>{{listTitle}}</div>
    </div>

    <div class="listDropDown-header"
         *ngIf="searchBar || (!!itemNestedListProperty && !!selectedNestedListParent)"
         [hidden]="searchBar && !!itemNestedListProperty && !selectedNestedListParent">

      <button class="-color-default-clear -type-icon listDropDown-back"
              *ngIf="selectedNestedListParent !== null"
              (click)="backFromNestedList($event)">
        <i class="ion ion-ios-arrow-back"></i>
      </button>

      <div class="listDropDown-nestedListParent"
           *ngIf="!searchBar && !!itemNestedListProperty && !!selectedNestedListParent">
        {{itemNameProperty ? selectedNestedListParent[itemNameProperty] : selectedNestedListParent}}
      </div>

      <div class="app-search"
           *ngIf="searchBar">
        <i class="ion ion-ios-search"></i>
        <input class="form-control pull-right"
               type="search"
               placeholder="Search {{listName}}"
               [id]="'ngbDropDown_search_' + dropdownId"
               [(ngModel)]="search"
               (ngModelChange)="searchUpdated()"
               (click)="$event.stopPropagation()"
               (keydown)="keyPressed($event)">
        <i [hidden]="!search"
           class="ion ion-ios-close"
           (click)="$event.stopPropagation(); clearSearchInput(); searchUpdated()">
        </i>
      </div>
    </div>

    <div *ngIf="!isDoubleArray || selectedNestedListParent !== null">

      <li *ngIf="includeEmptyItem"
          ngbDropdownItem
          (click)="selectItem(emptyItemValue)">
        <div *ngIf="emptyItemColour"
             class="itemColour"
             [ngStyle]="{'background-color': emptyItemColour}">
        </div>
        <div class="itemName"
             [ngStyle]="{'margin-left': emptyItemColour ? '10px' : '' }">
          {{emptyItemLabel}}
        </div>
      </li>

      <li *ngIf="includeEmptyItem"
          class="ph-listDropDown-divider">
      </li>

      <ng-container *ngFor="let item of searchedItems; let i = index">

        <li *ngIf="!item"
            class="ph-listDropDown-divider">
        </li>


        <li *ngIf="!!item"
            ngbDropdownItem
            [hidden]="itemHideProperty && item[itemHideProperty]"
            [ngClass]="{
            '-itemDisabled' : itemDisabledProperty ? item[itemDisabledProperty] : false,
            '-itemSelected': highlightItemMatchingTitle && !!buttonTitle && buttonTitle === (itemNameProperty ? item[itemNameProperty] : item),
            '-itemHighlighted': highlightedSearchItemIndex === i
           }"
            (click)="selectItem(item)">

          <div class="itemLeft">
            <div class="itemColour"
                 *ngIf="itemColorProperty"
                 [ngStyle]="{'background-color': item[itemColorProperty]}"
                 [ngClass]="{
                 '-itemColourLine': itemColorStyle === 'line',
                 '-itemColourDot': itemColorStyle === 'dot'
                }">
            </div>
            <product-logo *ngIf="!!itemProductValueProperty"
                          [productValue]="item[itemProductValueProperty]"
                          [iconOnly]="true">
            </product-logo>
            <div class="itemLeftInner">
              <div class="itemName"
                   [ngStyle]="{ 'color': itemFontColorProperty && item[itemFontColorProperty] ? item[itemFontColorProperty] : '',
                 'font-weight': itemNameFontWeight }">
                {{itemNameProperty ? item[itemNameProperty] : item}}
              </div>
              <div class="itemDescription"
                   *ngIf="itemDescriptionProperty"
                   [ngStyle]="{ 'white-space': itemDescriptionWrap ? 'pre-line' : ''}">
                {{item[itemDescriptionProperty]}}
              </div>
            </div>
          </div>

          <div class="itemRight">
            <ph-toggle *ngIf="itemIsToggleableProperty && item[itemIsToggleableProperty]"
                       [(ngModel)]="item[itemToggleValueProperty]"
                       [disableInternalToggle]="true">
            </ph-toggle>
            <ph-checkbox *ngIf="itemIsCheckableProperty && item[itemIsCheckableProperty]"
                         [(ngModel)]="item[itemCheckedValueProperty]"
                         [disableInternalToggle]="true">
            </ph-checkbox>
            <i class="ion ion-ios-arrow-forward"
               *ngIf="!item[itemDisabledProperty] && itemNestedListProperty && item[itemNestedListProperty] && item[itemNestedListProperty].length">
            </i>
          </div>

        </li>
      </ng-container>
    </div>


    <div *ngIf="isDoubleArray && selectedNestedListParent === null">
      <div *ngFor="let itemGroup of searchedItems; let i = index">
        <li *ngFor="let item of itemGroup"
            ngbDropdownItem
            [hidden]="itemHideProperty && item[itemHideProperty]"
            [ngClass]="{
              '-itemDisabled' : itemDisabledProperty ? item[itemDisabledProperty] : false,
              '-itemSelected': highlightItemMatchingTitle && !!buttonTitle && buttonTitle === (itemNameProperty ? item[itemNameProperty] : item)
            }"
            (click)="selectItem(item)">

          <div class="itemLeft">
            <div class="itemColour"
                 *ngIf="itemColorProperty"
                 [ngStyle]="{'background-color': item[itemColorProperty]}"
                 [ngClass]="{
                  '-itemColourLine': itemColorStyle === 'line',
                  '-itemColourDot': itemColorStyle === 'dot'
                 }">
            </div>
            <product-logo *ngIf="!!itemProductValueProperty"
                          [productValue]="item[itemProductValueProperty]"
                          [iconOnly]="true">
            </product-logo>
            <div class="itemLeftInner">
              <div class="itemName"
                   [ngStyle]="{ 'color': itemFontColorProperty && item[itemFontColorProperty] ? item[itemFontColorProperty] : '' }">
                {{itemNameProperty ? item[itemNameProperty] : item}}
              </div>
              <div class="itemDescription"
                   *ngIf="itemDescriptionProperty">
                {{item[itemDescriptionProperty]}}
              </div>
            </div>
          </div>

          <div class="itemRight">
            <ph-toggle *ngIf="itemIsToggleableProperty && item[itemIsToggleableProperty]"
                       [(ngModel)]="item[itemToggleValueProperty]"
                       [disableInternalToggle]="true">
            </ph-toggle>
            <ph-checkbox *ngIf="itemIsCheckableProperty && item[itemIsCheckableProperty]"
                         [(ngModel)]="item[itemCheckedValueProperty]"
                         [disableInternalToggle]="true">
            </ph-checkbox>
            <i class="ion ion-ios-arrow-forward"
               *ngIf="!item[itemDisabledProperty] && itemNestedListProperty && item[itemNestedListProperty] && item[itemNestedListProperty].length">
            </i>
          </div>

        </li>

        <li [hidden]="i === searchedItems.length - 1"
            class="ph-listDropDown-divider">
        </li>
      </div>
    </div>

    <div *ngIf="!searchedItems.length"
         class="ph-listDropDown-empty">
      No {{listName || "items"}} found
    </div>

  </ul>
</div>
