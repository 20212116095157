import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostBinding } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ProductService } from './../../../services/product/product.service';
import { ProductTheme, ProductValue } from './../../../app.types';


import * as $ from 'jquery';

@Component({
  selector: 'app-login-two-factor',
  templateUrl: './login-two-factor.component.html',
  styleUrls: ['./login-two-factor.component.scss']
})
export class LoginTwoFactorComponent implements OnInit, AfterViewInit {

  @HostBinding('class.app-page') app_page: boolean = true;
  @HostBinding('class.-noMobileGraphic') noMobileGraphic: boolean = false;

  @ViewChild('twoFactorCodeElem') twoFactorCodeElem: ElementRef;

  // StateParams
  verificationToken: string;
  twoFactorEnabled: boolean;

  targetStateName: string;
  targetStateParams: any; // Expecting JSON Object of params or NULL/{}

  productTheme: ProductTheme;
  product_value: ProductValue;

  // Working
  loading: boolean;
  saving: boolean;
  showQRKey: boolean;
  qrKeyCopied: boolean;
  qrKeyCopiedTimer: any;
  qrURI: string;
  qrKey: string;
  twoFactorCode: string;
  rememberMe: boolean = false;
  errorMessage: string;


  // Cached
  // cachedTwoFactorToken: string;

  constructor(
    private stateService: StateService,
    private authService: AuthService,
    private productService: ProductService,
    private uiRouterGlobals: UIRouterGlobals,
  ) { }

  ngOnInit(): void {

    this.loading = true;

    this.verificationToken = this.uiRouterGlobals.params.verification_token || null;
    this.twoFactorEnabled = this.uiRouterGlobals.params.two_factor_enabled || null;

    this.targetStateName = this.uiRouterGlobals.params.targetStateName || 'login'; // Fallback to Login state
    this.targetStateParams = this.uiRouterGlobals.params.targetStateParams || null;

    this.showQRKey = false;

    if (!this.verificationToken) {
      // Could possibly use the targetState, but if no verification token we're kinda broken anyway.
      // May be worth adding an error modal here indicating user should 'retry'.
      this.stateService.go('login');
    }
    else {
      if (!this.twoFactorEnabled) {
        this.initTwoFactor();
      }
      else {
        this.loading = false;
      }
    }

    // this.productService.current_product = 'FLEXITIME';
    this.productTheme = this.productService.product_theme;
    this.product_value = this.productService.current_product;

    this.noMobileGraphic = this.product_value === 'FLEXITIME';
  }

  ngAfterViewInit(){
    this.twoFactorCodeElem?.nativeElement.focus();
  }

  initTwoFactor(): void {
    // Call API passing up verificationToken.
    this.authService.initialiseTwoFactor(this.verificationToken)
      .then((qr) => {
        this.qrURI = qr.qr_uri;
        this.qrKey = qr.qr_key;
        this.loading = false;

        setTimeout(() => {
        });
      })
      .catch((error) => {
        this.loading = false;
        if (error && error.message) {
          this.errorMessage = error.message;
        }
        else {
          this.errorMessage = AuthService.fallbackAuthError;
        }
      });
  }

  verify(): void {
    if (!this.twoFactorCode) {
      return;
    }
    this.saving = true;

    this.authService.verifyTwoFactor(this.verificationToken, this.twoFactorCode, this.rememberMe || this.productService.login_destination === 'PAYHERO_MOBILE')
      .then((result) => {
        if (result && result.verified && result.two_factor_token) {

          // Put the verified 2FA token on the target state params
          if (this.targetStateParams) {
            this.targetStateParams.two_factor_token = result.two_factor_token;
          }
          else {
            this.targetStateParams = {
              two_factor_token: result.two_factor_token
            };
          }


          this.stateService.go(this.targetStateName, this.targetStateParams);
        }
        else {
          this.saving = false;
          this.errorMessage = 'Invalid code. Please retry.';
        }
      })
      .catch((error) => {
        this.saving = false;

        if (error && error.message) {
          this.errorMessage = error.message;
        }
        else {
          this.errorMessage = AuthService.fallbackAuthError;
        }
      });
  }


  toggleQRKey() {
    this.showQRKey = !this.showQRKey;
  }

  copyQRKey(el) {
    const input = $(el);
    input.select();
    try {
      document.execCommand('copy');
      input.blur();
      this.qrKeyCopied = true;
    }
    catch (err) {
      console.log(err);
    }

    if (this.qrKeyCopiedTimer) { clearTimeout(this.qrKeyCopiedTimer); }

    this.qrKeyCopiedTimer = setTimeout(() => {
      this.qrKeyCopied = false;
    }, 3000);
  }

  hideError() {
    this.errorMessage = '';
  }

  logout() {
    this.authService.logout();
  }

}
