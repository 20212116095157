import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { PaymentMethod } from 'src/app/app.types';
import { DpListTableHeader } from 'src/app/components/dp-list/dp-list.component';
import { SubscriptionCard } from 'src/app/models/subscription-card/subscription-card';
import { SubscriptionInvoice } from 'src/app/models/subscription-invoice/subscription-invoice';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-pay-invoice-modal',
  templateUrl: './pay-invoice-modal.component.html',
  styleUrls: ['./pay-invoice-modal.component.scss']
})
export class PayInvoiceModalComponent implements OnInit {

  @Input() overdue_invoices: SubscriptionInvoice[] = [];
  @Input() subscription_card: SubscriptionCard = null;
  @Input() payment_method: PaymentMethod = null;
  @Input() email: String = '';

  total_due: number = 0;

  editingPaymentMethod: boolean = false;


  tableHeaders: DpListTableHeader[] = [
    { label: 'Invoice Date', property: 'invoice_date' },
    { label: 'Total Payment', property: 'payment_amount' },
    { label: null, desktop_only: true },
  ];

  error_message: string = null;

  event_subscriptions: Subscription[] = [];


  loading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    if (this.overdue_invoices.length > 1) {
      for (const invoice of this.overdue_invoices) {
        this.total_due += invoice.invoice_gross_total;
      }
    }

    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.subscriptionService.getCardUpdateEvent().subscribe((event) => {
        if (!event.success) {
          this.error_message = event.message;
          this.editingPaymentMethod = true;
          this.loading = false;
          setTimeout(() => {
            this.error_message = '';
          }, 5000);
        } else {
          this.reloadPaymentMethod();
        }
      })
    );
  }

  downloadInvoice(payment: SubscriptionInvoice) {
    if (payment.downloading) {
      return;
    }

    payment.downloading = true;

    this.subscriptionService.downloadPDFSchedule(payment)
      .finally(() => {
        payment.downloading = false;
      });
  }

  editCard() {
    this.editingPaymentMethod = true;
  }

  setErrorMessage(message: string) {
    this.error_message = message;
    setTimeout(() => {
      this.error_message = '';
    }, 5000);
  }

  reloadPaymentMethod() {
    this.subscriptionService.loadSubscription()
      .then(() => {
        this.subscription_card = this.subscriptionService.getSubscription().subscription_card;
        if (!this.error_message) {
          this.editingPaymentMethod = false;
        }
        this.loading = false;
      });
  }


  close() {
    this.activeModal.dismiss();
  }

  payNow() {
    this.activeModal.close();
  }

  private _clearEventListeners(): void {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.event_subscriptions = [];
  }

}
