import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { DbUtilService } from '../db-util/db-util.service';
import { Partner } from '../../models/partner/partner';
import { Account } from '../../models/account/account';
import { AccountService } from './../account/account.service';
import { AccountFilter, ProductValue } from './../../app.types';
import { ClientService } from './../client/client.service';
import { BlobService } from './../blob/blob.service';

import * as _ from 'lodash-es';


@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  partner: Partner;

  private _serviceDataUpdateEvent = new Subject<void>();

  serviceSetup: boolean;

  constructor(
    public dbUtilService: DbUtilService,
    public accountService: AccountService,
    public clientService: ClientService,
    public blobService: BlobService
  ) { }

  // Initialisation ///////////////

  initialiseService() {
    return new Promise<any>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve(this.partner);
      }
      else {
        this.loadPartner()
          .then(() => {
            this.serviceSetup = true;
            resolve(this.partner);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.partner = null;
    this.serviceSetup = false;
  }

  // Getters ///////////////

  getServiceDataUpdateEvent(): Observable<any> {
    return this._serviceDataUpdateEvent.asObservable();
  }

  getPartner(getReference: boolean = false): Partner {
    return getReference ? this.partner : _.cloneDeep(this.partner);
  }

  getAllPartnerAccounts(
    filters: AccountFilter[],
    product_value: ProductValue = null,
    get_reference: boolean = false
  ): Account[] {
    const partner_client = this.clientService.partner_client;

    filters = filters || [];
    filters.push('EXCLUDE_DEMO');

    if (!!partner_client) {
      return this.accountService.getAllAccounts(
        filters, partner_client.client_key, [product_value], null, get_reference
      );
    }
    return [];
  }

  // Network Calls ///////////////

  loadPartner(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.dbUtilService.APIGet('partner', null, true)
        .then((data) => {
          this.partner = this.setupPartner(data);

          this._serviceDataUpdateEvent.next();
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  savePartner(
    partner: Partner
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = partner.formatForPosting();

      this.dbUtilService.APIPost('partner', data)
        .then(() => {
          this.loadPartner()
            .finally(() => {
              resolve();
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  // Formatting /////////////

  setupPartner(partnerData: any): Partner {
    return new Partner(
      partnerData.partner_key,
      partnerData.bank_account_name,
      partnerData.bank_account_number,
      partnerData.company_logo,
      partnerData.company_name,
      partnerData.company_website,
      partnerData.contact_email,
      partnerData.contact_name,
      partnerData.contact_phone,
      partnerData.partner_description,
      partnerData.receive_client_rebate_flag
    );
  }

}
