import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { DbUtilService } from '../db-util/db-util.service';
import { ImageUtilService } from '../image-util/image-util.service'
import { env } from './../../../environments/environment';


export type BlobUpdateData = {
  existing_image_url?: string,
  image_url: string,
  sas_delete_key?: string,
  sas_key: string,
  container?: string,
  file_name?: string
};




@Injectable({
  providedIn: 'root'
})



export class BlobService {
  readonly BLOB_CONTAINER_KEY_PROFILE_IMAGES_SUB_APP: string = 'profile-images';

  readonly BLOB_CONTAINER_KEY_THUMBNAILS_SUB_APP: string = 'profile-images-thumbnail';

  readonly thumbnailSize: number = 96;

  constructor(
    public dbUtilService: DbUtilService,
  ) { }

  modifyBlob(containerName: string, blobName: string, sasKey: string, blob: Blob) {
    const blobServiceClient = new BlobServiceClient(env.subscription.blob_url + '?' + sasKey);
    const containerClient = blobServiceClient.getContainerClient(containerName)
    const blobClient = containerClient.getBlockBlobClient(blobName);

    return blobClient.upload(blob, blob.size);
  }


  postBlob(data: BlobUpdateData, file: File = null): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const promises = [];

      if (!!file && !!data.image_url) {
        promises.push(this._saveDocumentBlob(data, file));
      }

      Promise.all(promises)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  private _saveDocumentBlob(data: BlobUpdateData, file: File) {
    const blob_service_client = new BlobServiceClient(env.subscription.blob_url + '?' + data.sas_key);
    const container_client = blob_service_client.getContainerClient(data.container);
    const blob_client = container_client.getBlockBlobClient(data.image_url);

    const blobOptions = { blobHTTPHeaders: { blobContentType: file.type } };

    return blob_client.upload(file, file.size, blobOptions);
  }

  uploadImage(
    file_name: string,
    file: File,
    product: string,
    min_dimension: number = 0,
    generateThumbNail: boolean = true

  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!file) { resolve(null); }

      const params = {
        file_name,
        product
      };

      this.dbUtilService.APIGet('blob/document/sas', params)
        .then((res) => {


          const thumbData = {
            image_url: res.thumb_url,
            sas_key: res.thumb_sas_key,
            container: this.BLOB_CONTAINER_KEY_THUMBNAILS_SUB_APP
          };

          if (generateThumbNail) {
            ImageUtilService.scaleDownImageToMaxSize(file, this.thumbnailSize)
              .then((res1) => {
                const thumbnail_file = res1 as File;
                this.postBlob(thumbData, thumbnail_file)
                  .catch((err) => reject(err));
              });



          }


          const profileData = {
            image_url: res.image_url,
            sas_key: res.sas_key,
            file_name: file_name,
            container: this.BLOB_CONTAINER_KEY_PROFILE_IMAGES_SUB_APP,
            thumbContainer: this.BLOB_CONTAINER_KEY_THUMBNAILS_SUB_APP
          };


          if (!!min_dimension) {
            ImageUtilService.scaleDownImageToMaxSize(file, min_dimension)
              .then((res2) => {
                const resized_file = res2 as File;
                this.postBlob(profileData, resized_file)
                  .then(() => resolve(profileData))
                  .catch((err) => reject(err));
              });

          } else {
            this.postBlob(profileData, file)
              .then(() => resolve(profileData))
              .catch((err) => reject(err));

          }
        });
    });
  }


}
