import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { BannerConfig, Country, FormInputField } from 'src/app/app.types';
import { BannerComponent } from 'src/app/components/banner/banner.component';
import { UserAccess } from 'src/app/models/user-access/user-access';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-user-access-modal',
  templateUrl: './user-access-modal.component.html',
  styleUrls: ['./user-access-modal.component.scss']
})
export class UserAccessModalComponent implements OnInit, OnDestroy {

  @Input() user_access: UserAccess;

  @ViewChild('banner') banner: BannerComponent;

  banner_config: BannerConfig = {
    type: 'ERROR',
    message: null
  };

  formFields: FormInputField[] = [];

  formFieldErrors: Record<string, string> = {};

  private _event_subscriptions: Subscription[] = [];

  phone_verification_disabled: boolean = this.userService.getPhoneVerificationDisabled();
  phone_verification_countdown: number = this.userService.getPhoneVerificationCountdown();

  is_droppah_candidate: boolean = this.userService.is_droppah_candidate;
  // is_droppah_candidate: boolean = false;

  selected_country: Country = null;
  contact_phone: string = '';
  contact_phone_otp: string = '';

  contact_phone_copy: string = null;

  mobile_edit: boolean = false;
  show_otp_input: boolean = false;

  loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this._initEventListeners();

    this.formFields = [
      // { property: 'profile_picture', label: 'Profile Picture', field_type: 'image' },
      { property: 'full_name', label: 'Contact Name', field_required: true },
      { property: 'contact_phone', label: 'Mobile' },
      { property: 'registered_email', label: 'Login Email', field_type: 'email', field_required: true },
      { property: 'username', label: 'Login Username', field_visibility_requires_button_click: this.user_access.username === this.user_access.registered_email }
    ];

    this.selected_country = this.userService.getCountryForPhoneNumber(this.user_access.contact_phone);

    if (!this.selected_country) {
      this.selected_country = this.userService.getCountry('NZ');
    }
    this._initContactPhone();
  }

  ngOnDestroy() {
    this._clearEventListeners();
  }

  clearFormFieldError(property: string): void {
    this.clearBannerError();
    if (this.formFieldErrors[property]) {
      this.formFieldErrors[property] = null;
    }
  }


  triggerFieldVisibility(form_field: FormInputField) {
    form_field.field_visibility_requires_button_click = false;
  }

  clearBannerError() {
    this.banner_config.message = null;
  }

  toggleEditMobile() {
    this.mobile_edit = !this.mobile_edit;
    if (!this.mobile_edit) {
      this.show_otp_input = false;
    }
  }

  sendVerificationCode() {
    if (!this.loading) {
      if (!this.contact_phone) {
        this.formFieldErrors['contact_phone'] = 'Please enter a valid mobile phone number.';
      }
      else {
        this.loading = true;

        this.userService.verifyPhoneNumber(
          this.selected_country.calling_code,
          this.contact_phone
        )
          .then(() => this.show_otp_input = true)
          .catch(() => { })
          .finally(() => this.loading = false);
      }
    }
  }

  otpChanged() {
    if (!this.loading) {
      if (this.contact_phone_otp?.length === 6) {
        this.loading = true;

        this.userService.verifyOTP(
          this.selected_country.calling_code,
          this.contact_phone,
          this.contact_phone_otp
        )
          .then(() => this.userService.updateUserAccessMobile(
            this.selected_country.calling_code,
            this.contact_phone
          ))
          .then((contact_phone) => this._contactPhoneUpdated(contact_phone))
          .catch(() => {
            this.formFieldErrors['verification_code'] = 'Incorrect Code. Please try again.';
          })
          .finally(() => this.loading = false);
      }
    }
  }

  private _contactPhoneUpdated(contact_phone: string) {
    this.user_access.contact_phone = contact_phone;
    this._initContactPhone();

    this.show_otp_input = false;
    this.mobile_edit = false;
  }

  private _initContactPhone() {
    if (!!this.user_access.contact_phone) {
      this.contact_phone = this.user_access.contact_phone.slice(this.selected_country.calling_code.length);
    }
    else {
      this.contact_phone = null;
    }
    this.contact_phone_copy = cloneDeep(this.contact_phone);
  }

  save() {
    this.formFieldErrors = {};

    const errors = this.user_access.getErrors();

    if (!errors.length) {
      if (!this.is_droppah_candidate){
        this.user_access.contact_phone = this.contact_phone;
      }
      this.activeModal.close(this.user_access);
    }
    else {
      for (const error of errors) {
        let error_matches_form_field = false;

        for (const form_field of this.formFields) {
          if (form_field.property === error.error_path) {
            this.formFieldErrors[error.error_path] = error.error_message;
            error_matches_form_field = true;
          }
        }

        if (!error_matches_form_field) {
          this.banner_config.message = error.error_message;
        }
      }

      if (this.banner_config.message !== null) {
        this.banner?.showBanner();
      }
    }
  }


  cancel() {
    this.activeModal.dismiss();
  }

  private _initEventListeners() {
    this._clearEventListeners();

    this._event_subscriptions = [
      this.userService.subscribeToEvent(
        'PHONE_VERIFICATION_TOGGLED',
        () => {
          this.phone_verification_disabled = this.userService.getPhoneVerificationDisabled();
        }
      ),
      this.userService.subscribeToEvent(
        'PHONE_VERIFICATION_COUNTDOWN',
        () => {
          this.phone_verification_countdown = this.userService.getPhoneVerificationCountdown();
        }
      )
    ];
  }

  private _clearEventListeners() {
    this._event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this._event_subscriptions = [];
  }

}
