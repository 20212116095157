import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FTSubscription } from 'src/app/models/ft-subscription/ft-subscription';

@Component({
  selector: 'bank-view',
  templateUrl: './bank-view.component.html',
  styleUrls: ['./bank-view.component.scss']
})
export class BankViewComponent implements OnInit {

  @Input() subscription: FTSubscription;
  @Input() saving: boolean = false;
  @Input() hide_button_row: boolean = false;
  @Output() editDetails: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
