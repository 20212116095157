import { Component, OnInit } from '@angular/core';

import { ModalService } from './../../../services/modal/modal.service';
import { FTSubscription } from './../../../models/ft-subscription/ft-subscription';
import { SubscriptionService } from './../../../services/subscription/subscription.service';
import { PartnerService } from 'src/app/services/partner/partner.service';
import { Partner } from 'src/app/models/partner/partner';
import { UIRouterGlobals } from '@uirouter/core';

@Component({
  selector: 'app-subscription-billing',
  templateUrl: './subscription-billing.component.html',
  styleUrls: ['./subscription-billing.component.scss']
})
export class SubscriptionBillingComponent implements OnInit {

  reloading_payment_details: boolean = false;
  reloading_billing_details: boolean = false;

  subscription: FTSubscription;

  partner: Partner;

  constructor(
    public modalService: ModalService,
    public subscriptionService: SubscriptionService,
    public partnerService: PartnerService,
    public uiRouterGlobals: UIRouterGlobals,
  ) { }

  ngOnInit(): void {
    this.refreshSubscription();
    this.partner = this.partnerService.getPartner();
    if (this.uiRouterGlobals.params?.openPaymentModal) {
      this.updatePaymentMethod();
    }
  }

  updatePaymentMethod() {
    this.modalService.updatePaymentMethodModal()
      .then((reloadSub) => {
        if (reloadSub) {
          this.reloading_payment_details = true;

          this.subscriptionService.loadSubscription()
            .catch(() => { })
            .finally(() => {
              this.refreshSubscription();
              this.reloading_payment_details = false;
            });
        }
      })
      .catch(() => { });
  }

  editBillingDetails() {
    this.modalService.formModal(
      'Edit Billing Details',
      this.subscription,
      [
        {
          property: 'billing_name',
          label: 'Billing Name',
          field_required: true
        },
        {
          property: 'billing_email',
          label: 'Billing Email',
          field_type: 'email',
          field_required: true
        },
        {
          property: 'billing_address',
          label: 'Billing Address',
          field_type: 'address'
        },
        {
          property: 'country',
          label: 'Billing Country',
          field_readonly: !this.subscription.has_no_payment_method,
          field_required: true,
          field_type: 'country'
        }
      ]
    )
      .then((updated_subscription) => {
        this.reloading_billing_details = true;

        this.subscriptionService.saveBillingInformation(updated_subscription)
          .catch(() => { })
          .finally(() => {
            this.subscription = this.subscriptionService.getSubscription();
            this.reloading_billing_details = false;
          });
      })
      .catch(() => { });
  }

  editRevenueShareDetails() {

    this.modalService.formModal(
      'Edit Revenue Share Payment Details',
      this.partner,
      [
        { property: 'bank_account_name', label: 'Bank Account Name' },
        { property: 'bank_account_number', label: 'Bank Account Number', field_type: 'bank' }
      ]
    )
      .then((updated_details) => {
        this.partnerService.savePartner(updated_details)
          .finally(() => {
            this.partner = this.partnerService.getPartner();
          });
      })
      .catch(() => { });
  }

  refreshSubscription() {
    this.subscription = this.subscriptionService.getSubscription();
  }

}
