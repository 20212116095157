<div class="grid-container">
  <date-divider [date]="month.date"
                [format]="'MMMM'"></date-divider>
  <div class="day-header-container">
    <div class="day"
         *ngFor="let string of daysOfTheWeek;">
      <div>{{string}}</div>
    </div>
  </div>


  <div class="day-list-container"
       *ngFor="let week of month.weeks">
    <div class="{{day.style}} day-container"
         *ngFor="let day of week;">
      <span></span>
      <div>
        <div *ngIf="day.hide === false"
             class="{{day?.keyDate}}">{{day.date | date : 'd'}}</div>
      </div>
    </div>
  </div>
</div>



<!-- [class.first-date-selected] = "firstDateSelected === day.dateLabel"
    [class.second-date-selected] = "secondDateSelected === day.dateLabel" -->
