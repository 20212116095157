import { Component, Input, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { AbstractValueAccessor, MakeProvider } from 'src/app/classes/abstract/abstract-value-accessor/abstract-value-accessor';

import { } from 'google.maps';

@Component({
  selector: 'address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [MakeProvider(AddressInputComponent)]
})
export class AddressInputComponent extends AbstractValueAccessor implements AfterViewInit {

  @ViewChild('addressInput') addressInput: ElementRef;

  @Input() inputLabel: string = 'Address';
  @Input() activeValidationEnabled: boolean = true;
  @Input() addressRequired: boolean = false;
  @Input() clearErrorsOnClick: boolean = true;
  @Input() customError: string = null;
  @Input() readonly: boolean = false;
  @Input() countryCode: string = null;

  auto_complete: google.maps.places.Autocomplete;

  missing_address: boolean = false;

  _value: string;

  @HostListener('click', ['$event'])
  handleClick() {
    if (this.clearErrorsOnClick) {
      this.clearErrors();
    }
  }

  ngAfterViewInit(){
    this.initAutoComplete();
  }

  initAutoComplete() {
    const auto_complete_options: google.maps.places.AutocompleteOptions = {
      types: ['address']
    };

    if (!!this.countryCode){
      auto_complete_options.componentRestrictions = { country: this.countryCode }
    }

    this.auto_complete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, auto_complete_options);
    this.auto_complete.setFields(['address_component']);
    this.auto_complete.addListener('place_changed', () => {
      this.value = this.addressInput.nativeElement.value;
      this.addressUpdated();
    });
  }

  addressUpdated() {
    if (this.activeValidationEnabled) {
      this.validateAddress();
    }
  }

  validateAddress() {
    this.missing_address = false;

    if (this.addressRequired && !this._value) {
      this.missing_address = true;
    }
    return !this.missing_address;
  }

  clearErrors() {
    this.customError = null;
    this.missing_address = false;
  }

}
