import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';

import { ProductValue, DropdownOption } from './../../../app.types';
import { AccountAccessService } from './../../../services/account-access/account-access.service';
import { ClientNote } from 'src/app/models/client-note/client-note';
import { ClientPartnerStaff } from './../../../models/client-partner-staff/client-partner-staff';
import { UserService } from 'src/app/services/user/user.service';
import { PartnerStaffService } from './../../../services/partner-staff/partner-staff.service';
import { AccountService } from './../../../services/account/account.service';
import { RedirectService } from './../../../services/redirect/redirect.service';
import { ProductService } from './../../../services/product/product.service';
import { PartnerService } from 'src/app/services/partner/partner.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ClientService } from 'src/app/services/client/client.service';

import { PartnerStaff } from './../../../models/partner-staff/partner-staff';
import { Client } from './../../../models/client/client';
import { Partner } from './../../../models/partner/partner';
import { Account } from './../../../models/account/account';

import { env } from './../../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.scss']
})
export class PartnerEditComponent implements OnInit {

  loading: boolean = false;

  clientDropdownOptions: DropdownOption[] = [];
  staffDropdownOptions: DropdownOption[][] = [];
  noteDropdownOptions: DropdownOption[][] = [];

  partner: Partner;
  partnerClient: Client;
  partnerAccounts: Account[] = [];
  unlinkedAccounts: Account[] = [];

  staffAccessMap: Record<number, number>;
  availableStaff: PartnerStaff[] = [];

  hideAddAccountButton: boolean = false;

  event_subscriptions: Subscription[] = [];

  constructor(
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public clientService: ClientService,
    public modalService: ModalService,
    public partnerService: PartnerService,
    public productService: ProductService,
    public redirectService: RedirectService,
    public accountService: AccountService,
    public partnerStaffService: PartnerStaffService,
    public userService: UserService,
    public accountAccessService: AccountAccessService
  ) { }

  ngOnInit(): void {
    this.refreshPartner();
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  refreshPartner() {
    this.partner = this.partnerService.getPartner();
    this.partnerClient = this.clientService.partnerClient;

    this.partnerAccounts = this.accountService.getAllAccounts(
      ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO'],
      this.partnerClient.client_key, null, null, true
    );

    this.updateUnlinkedAccounts();
    this.updateAvailableStaff();
  }

  updateAccount(account: Account, to_delete: boolean = false) {
    if (!account) {
      return this.addAccount();
    }

    if (to_delete) {
      this.modalService.confirmModal('Custom', null, null, null, null, {
        icon: null,
        title: 'Remove ' + account.company_name + ' from Partner',
        question: '',
        warning: 'This will unsubscribe ' + account.company_name + ' and remove all user access to the account',
        buttonTitle: 'Remove',
        buttonConfirmClass: '-color-danger'
      })
        .then(() => {
          this.loading = true;

          this.accountService.addOrRemoveAccountFromClient(this.partnerClient, account, to_delete)
            .finally(() => {
              this.refreshPartner();
              this.loading = false;
            });
        })
        .catch(() => { });
    }
    else {
      this.loading = true;

      this.accountService.addOrRemoveAccountFromClient(this.partnerClient, account, to_delete)
        .finally(() => {
          this.refreshPartner();
          this.loading = false;
        });
    }
  }

  addAccount() {
    this.modalService.addAccountModal(false, false, true)
      .then((data) => {
        this.loading = true;

        this.accountService.createAccount(
          data.company_name,
          this.partnerClient.client_key,
          data.product_value,
          data.number_of_employees,
          data.region,
          data.industry_key,
          data.business_type,
          data.nzbn,
          data.industry_classification,
          data.ird_number,
          false,
          false,
          data.sub_agreement_flag,
          false,
          data.contact_phone
        )
          .finally(() => {
            this.refreshPartner();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  goToAccountSettings(account: Account) {
    this.signIn(account, env[account.product_value.toLowerCase()].external_settings_state);
  }

  editDetails() {
    this.modalService.formModal(
      'Edit Partner Details',
      this.partner,
      [
        { property: 'company_name', label: 'Organisation Name', field_required: true },
        { property: 'contact_name', label: 'Contact Name', field_required: true },
        { property: 'contact_phone', label: 'Contact Number' },
        { property: 'contact_email', label: 'Contact Email', field_type: 'email', field_required: true }
      ]
    )
      .then((updated_partner) => {
        this.loading = true;

        this.partnerService.savePartner(updated_partner)
          .finally(() => {
            this.refreshPartner();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  signIn(
    account: Account,
    external_state_name: string = null
  ) {
    this.loading = true;

    if (!account) {
      this.loading = false;
      return;
    }

    if (account.allow_user_access) {
      this.productService.startNewSession(account.company_product_key, account.product_value)
        .then((session) => {
          this.redirectService.redirectToProduct(session.login_source, true, external_state_name);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
    else {
      this.loading = false;
      this.modalService.errorModal(
        'Access to ' + (account.company_name ? account.company_name : 'this account') + ' is not allowed'
      );
    }
  }

  manageStaffAccountAccess(client_partner_staff: ClientPartnerStaff) {
    this.modalService.manageClientStaffAccessModal(
      this.partnerClient.client_key,
      client_partner_staff.partner_staff.partner_staff_key
    )
      .finally(() => {
        this.refreshPartner();
        this.loading = false;
      });
  }

  updateStaff(partner_staff: PartnerStaff, to_delete: boolean) {
    this.accountAccessService.addOrRemovePartnerStaffFromClient(
      this.partnerClient, partner_staff, to_delete
    )
      .finally(() => {
        this.refreshPartner();
        this.loading = false;
      });
  }

  manageNote(note: ClientNote = null, viewOnly: boolean = false) {
    this.modalService.addClientNoteModal(note || new ClientNote(null, this.partnerClient.client_key, ''), viewOnly)
      .then((result) => {
        this.loading = true;

        this.clientService.saveClientNote(result.note, result.toDelete)
          .finally(() => {
            this.refreshPartner();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  deleteNote(note: ClientNote) {
    this.loading = true;

    this.clientService.saveClientNote(note, true)
      .finally(() => {
        this.refreshPartner();
        this.loading = false;
      });
  }

  updateAvailableStaff() {
    this.availableStaff = this.partnerStaffService.getAllPartnerStaff().filter(partner_staff => {
      return !this.partnerClient.containsPartnerStaff(partner_staff.partner_staff_key);
    });
  }

  updateUnlinkedAccounts() {
    const products_to_exclude: Set<ProductValue> = new Set();
    for (const account of this.partnerAccounts) {
      products_to_exclude.add(account.product_value);
    }

    this.hideAddAccountButton =
      products_to_exclude.has('DROPPAH') &&
      products_to_exclude.has('PAYHERO') &&
      products_to_exclude.has('INVOXY') &&
      products_to_exclude.has('KARMLY');

    this.unlinkedAccounts = this.accountService.getAllAccounts(
      ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO', 'UNLINKED_ONLY', 'EXCLUDE_NO_SUBSCRIPTION', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS'],
      null, null, [...products_to_exclude], true
    );
  }

  private _initEventListeners(): void {
    this.event_subscriptions.push(
      this.accountService.getServiceDataUpdateEvent().subscribe(() => {
        this.updateUnlinkedAccounts();
      })
    );
  }

  private _clearEventListeners(): void {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.event_subscriptions = [];
  }

}
