import { Component, HostBinding, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { DateTime } from 'luxon';

import { TokenPayheroService } from './../../../services/token-payhero/token-payhero.service';

@Component({
  selector: 'app-leave-request',
  templateUrl: './leave-request.component.html',
  styleUrls: ['./leave-request.component.scss']
})
export class LeaveRequestComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  token: string = null;
  row_version: number = null;
  loading: boolean = false;
  approving: boolean = false;
  declining: boolean = false;
  declined: boolean = false;
  approved: boolean = false;
  expiredState: boolean = false;
  leaveLocked: boolean = false;
  showOverlappingDetails: boolean;

  approved_data_changes = {
    leave_type: false,
    start_date: false,
    end_date: false,
    part_day: false
  };

  leaveDetails: any = null;

  constructor(
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public tokenPayHeroService: TokenPayheroService
  ) { }

  ngOnInit() {
    window.document.title = 'Leave Request | PayHero';

    this.token = this.uiRouterGlobals.params.token || null;

    if (this.token) {
      this.getLeave();
    }
    else {
      this.expiredState = true;
    }
  }

  approve() {
    this.approving = true;
    this.respond();
  }

  decline() {
    this.declining = true;
    this.respond();
  }

  private respond() {
    this.tokenPayHeroService.postLeave(this.token, this.row_version, new Date(), this.approving)
      .then(() => {
        this.declined = this.declining;
        this.approved = this.approving;

        this.approving = false;
        this.declining = false;
      })
      .catch(() => {
        this.getLeave();

        this.approving = false;
        this.declining = false;
      });
  }

  private _updateApprovedDataChanges() {
    if (this.leaveDetails.approved_data) {
      this.approved_data_changes = {
        leave_type: this.leaveDetails.leave_type !== this.leaveDetails.approved_data.leave_type,
        start_date: !DateTime.fromJSDate(this.leaveDetails.start_date).hasSame(DateTime.fromJSDate(this.leaveDetails.approved_data.start_date), 'day'),
        end_date: !DateTime.fromJSDate(this.leaveDetails.end_date).hasSame(DateTime.fromJSDate(this.leaveDetails.approved_data.end_date), 'day'),
        part_day: this.leaveDetails.part_day !== this.leaveDetails.approved_data.part_day
      };
    }
  }

  private getLeave() {
    this.loading = true;
    this.showOverlappingDetails = false;
    this.tokenPayHeroService.getLeave(this.token)
      .then((leave) => {
        this.leaveDetails = leave;
        this._updateApprovedDataChanges();
        this.loading = false;

        if (!this.leaveDetails) {
          this.expiredState = true;
        }
        else {
          window.document.title = 'Leave Request | ' + this.leaveDetails.company_name;
          this.row_version = this.leaveDetails.row_version;
          this.leaveLocked = this.leaveDetails.leave_locked_flag;
          this.approved = !!this.leaveDetails.approval_date;
          this.declined = !!this.leaveDetails.declined_date;
        }
      })
      .catch(() => {
        this.loading = false;
        this.expiredState = true;
      });
  }

}
