import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BannerConfig } from '../../app.types';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  @Input() bannerConfig: BannerConfig = null;

  private _bannerVisible: boolean = false;
  get bannerVisible(): boolean {
    return this._bannerVisible;
  }
  set bannerVisible(is_visible: boolean) {
    if (is_visible !== this._bannerVisible) {
      this._bannerVisible = is_visible;

      if (this._bannerVisible && !!this.bannerConfig.auto_hide_timer) {
        setTimeout(() => {
          this._bannerVisible = false;
        }, this.bannerConfig.auto_hide_timer);
      }
    }
  }

  @Output() bannerhidden = new EventEmitter();

  showBanner(){
    this.bannerVisible = true;
  }

  hideBanner() {
    this.bannerVisible = false;
    this.bannerhidden.emit();
  }

}
