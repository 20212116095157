<div class="apiSearch-container"
     ngbDropdown
     #apiSearchDropdown
     [placement]="alignLeft ? 'bottom-left' : 'bottom-right'"
     [container]="'body'"
     [autoClose]="'outside'">

  <div class="apiSearch-inputContainer"
       [ngClass]="{'-invalid': showError}"
       ngbDropdownToggle
       (click)="toggleDropdown()">
    <input class="apiSearch-input"
           autocomplete="off"
           #apiSearchInput
           [placeholder]="inputPlaceholder"
           [(ngModel)]="searchTerm"
           (ngModelChange)="searchTermInputUpdated($event)">
    <i class="bi bi-exclamation-triangle-fill"
    *ngIf="showError && errorMessage">
      <tooltip-popover [tooltipContent]="errorMessage"></tooltip-popover>
    </i>
  </div>

  <div ngbDropdownMenu
       class="apiSearch-dropdown"
       [ngStyle]="{ 'width': listWidth }"
       [hidden]="(dropdownDisabled || searchTerm.length < minimumSearchTermLength || !items.length)">

    <div class="apiSearch-dropdown-contents">
      <div class="apiSearch-dropdown-itemContainer"
           *ngIf="!!is_mobile"
           [ngStyle]="{'margin-top': '2px', 'margin-bottom': '10px'}">
        <div class="apiSearch-dropdown-item">
          <div class="apiSearch-inputContainer">
            <input class="apiSearch-input"
                autocomplete="off"
                [placeholder]="inputPlaceholder"
                [(ngModel)]="searchTerm"
                (ngModelChange)="searchTermInputUpdated($event)">
          </div>
        </div>
      </div>

      <div class="apiSearch-dropdown-itemsLoading"
           *ngIf="loading">
        <spinner class="-spinnerFlex"></spinner>
      </div>

      <div class="apiSearch-dropdown-itemsEmpty"
           *ngIf="!loading && searchTerm.length < minimumSearchTermLength">
        <span>Type to search...</span>
      </div>

      <div class="apiSearch-dropdown-itemsEmpty"
           *ngIf="!loading && searchTerm.length >= minimumSearchTermLength && !items.length">
        <span>No results for search</span>
      </div>

      <div class="apiSearch-dropdown-itemContainer"
           *ngIf="!loading && searchTerm.length >= minimumSearchTermLength && items.length">
        <div class="apiSearch-dropdown-item"
             *ngFor="let item of items"
             (click)="selectItem(item)">

          <div class="-itemValue"
               *ngIf="primaryItemProperty && item[primaryItemProperty]">
            <span>{{item[primaryItemProperty]}}</span>

            <div class="-subItemValue"
                  *ngIf="secondaryItemProperty">
                <span>{{item[secondaryItemProperty]}}</span>
              </div>
          </div>
          <div class="-itemValue"
                  *ngIf="!item[primaryItemProperty] && secondaryItemProperty">
              <span>{{item[secondaryItemProperty]}}</span>
            </div>

          <div class="-taggedItemValue"
               *ngIf="taggedItemProperty">
            <span>{{item[taggedItemProperty]}}</span>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
