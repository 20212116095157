import { Component, HostBinding, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';

import { Subscription } from 'rxjs';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { AuthService } from '../../../services/auth/auth.service';
import { AccountFilter, ProductValue, UserAccessAgreements } from './../../../app.types';
import { ProductService } from './../../../services/product/product.service';
import { UserService } from './../../../services/user/user.service';
import { AccountService } from './../../../services/account/account.service';
import { Account } from './../../../models/account/account';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PhAccount } from './../../../models/account/ph-account';
import { FtAccountService } from 'src/app/services/ft-account/ft-account.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  current_product: ProductValue;

  refreshStateName: string = null;
  refreshStateParams: any = null;

  // External state info only used when auto-logging in to a product
  externalStateName: string = null;
  externalStateParams: any = null;

  auto_login_flag: boolean = false;
  auto_login_partner_flag: boolean = false; // For bypassing the default behaviour of not auto-logging partners
  auto_login_company_product_key: number = null;

  event_subscriptions: Subscription[] = [];
  wait_subscription_service_setup: boolean = false;

  constructor(
    public stateAccessService: StateAccessService,
    public authService: AuthService,
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public productService: ProductService,
    public userService: UserService,
    public accountService: AccountService,
    public ftAccountService: FtAccountService,
    public stateDataService: StateDataService,
    public redirectService: RedirectService,
    public modalService: ModalService,
    public subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.current_product = this.productService.current_product;

    this._initEventListeners();
    this.initialiseAppServices();
  }

  ngOnDestroy() {
    this._clearEventListeners();
  }

  initialiseAppServices() {
    this.refreshStateName = this.uiRouterGlobals.params.refreshStateName || null;
    this.refreshStateParams = this.uiRouterGlobals.params.refreshStateParams || null;
    this.externalStateName = this.uiRouterGlobals.params.externalStateName || null;
    this.externalStateParams = this.uiRouterGlobals.params.externalStateParams || null;
    this.auto_login_flag = this.uiRouterGlobals.params.auto_login_flag || false;
    this.auto_login_partner_flag = this.uiRouterGlobals.params.auto_login_partner_flag || false;
    this.auto_login_company_product_key = this.uiRouterGlobals.params.auto_login_company_product_key || null;

    this.userService.loadUserAccessAgreements()
      .then((user_access_agreements) => {
        this._ensureAgreementsUpToDate(user_access_agreements)
          .then(() => {
            // 15/02/22:  No longer required. Especially now that this initAppServices function is run when navigating to a subscription state for the first time.
            // this.stateAccessService.clearServiceData();
            this.stateAccessService.ensureServicesInitialised()
              .then(() => {
                this._tryAutoLogin()
                  .then((session) => {
                    this._windcaveUpdateCardModal();
                    this._initIntercom();
                    if (session !== null) {
                      this.redirectService.redirectToProduct(session.login_source, false, this.externalStateName, this.externalStateParams);
                    }
                    else {
                      if (!this.refreshStateParams || this.refreshStateParams && !this.refreshStateParams.product) {
                        this.productService.current_product = 'FLEXITIME';
                      }
                      // initialising subscription service should be triggered but visiting for first time may not be finished loading
                      if (this.refreshStateName?.indexOf('app.subscription.') > -1 && !this.subscriptionService.serviceSetup) {
                        this.wait_subscription_service_setup = true;
                      }
                      else {
                        this.stateAccessService.reloadStateAccess(this.refreshStateName, this.refreshStateParams);
                      }
                    }
                  });
              })
              .catch((err) => {
                this._logoutOnError(err);
              });
          })
          .catch(() => {
            this._logoutOnError();
          });
      })
      .catch((err) => {
        this._logoutOnError(err);
      });
  }

  private _tryAutoLogin(): Promise<any> {
    return new Promise<any>((resolve) => {
      const auto_select_account = this.auto_login_flag ?
        this.accountService.getAutoLoginAccountForSplashScreen(this.auto_login_company_product_key, this.auto_login_partner_flag) : null;

      //15/10/24 Dont want to auto login if a user needs to update their card as we move away from windcave
      // check can be removed later on
      if (auto_select_account !== null && !this.userService.user_access?.prompt_new_card_flag) {
        // If product = payhero & there are incomplete onboarding steps, send them to onboarding
        if (auto_select_account.product_value === 'PAYHERO' && !!auto_select_account.incomplete_onboarding_steps?.length) {
          this.stateAccessService.reloadStateAccess(
            'app.account.create',
            {
              product: auto_select_account.product_value,
              company_product_key: auto_select_account.company_product_key
            });
        }
        else {
          // Otherwise just log them
          this._startSession(auto_select_account)
            .then((session) => { resolve(session); })
            .catch(() => { resolve(null); });
        }
      }
      else {
        resolve(null);
      }
    });
  }

  private _getRecentProductLogin(product_value: ProductValue): number {
    const rpl = this.stateDataService.getCachedLocalData(
      'recent_product_logins'
    ) || {
      PAYHERO: null,
      DROPPAH: null,
      INVOXY: null,
      KARMLY: null
    };
    return rpl[product_value] || null;
  }

  private _startSession(account: Account): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productService.startNewSession(account.company_product_key, account.product_value)
        .then((session) => {
          resolve(session);
        })
        .catch(() => {
          reject();
        });
    });
  }

  private _initIntercom(): void {
    if (this.userService.user_access.intercom_settings) {
      const intercomSettingsCopy = Object.assign({}, this.userService.user_access.intercom_settings);

      intercomSettingsCopy.user_hash = this.stateDataService.getCachedLocalData('intercom_hash');
      window.Intercom('boot', intercomSettingsCopy);
    }
  }

  private _logoutOnError(err: any = null) {
    console.log(err);
    this.authService.logout();
  }

  private _ensureAgreementsUpToDate(uaa: UserAccessAgreements): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!uaa.subscription_updated_flag && !uaa.partner_updated_flag) {
        resolve();
      }
      else {
        this.modalService.userAccessAgreementsModal(uaa)
          .then(() => resolve())
          .catch(() => reject());
      }
    });
  }

  private _initEventListeners(): void {
    this.event_subscriptions.push(
      this.accountService.getServiceDataUpdateEvent().subscribe(() => {
        if (this.wait_subscription_service_setup) {
          this.wait_subscription_service_setup = false;
          this.stateAccessService.reloadStateAccess(this.refreshStateName, this.refreshStateParams);
        }
      })
    );
  }

  private _clearEventListeners(): void {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.event_subscriptions = [];
  }

  private _windcaveUpdateCardModal() {
    if (this.userService.user_access?.prompt_new_card_flag && !this.refreshStateName) {
      this.modalService.openWindcavePromptModal()
        .catch(() => { });

    }
  }

}
