<banner #banner
        [bannerConfig]="banner_config">
</banner>

<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      Edit User Access
    </div>
  </div>

  <div class="ph-modal-container">

    <div class="ph-formItem-container -smallForm -userAccessSlide"
         [ngStyle]="{'left': !mobile_edit ? 0 : -450 + 'px', 'height': !mobile_edit ? '' : 0, 'margin': !mobile_edit ? '' : 0}">

      <div *ngFor="let formField of formFields">

        <div *ngIf="formField.property !== 'contact_phone'">
          <form-input *ngIf="!formField.field_visibility_requires_button_click && (!formField.field_type || formField.field_type === 'number' || formField.field_type === 'email')"
                      [formField]="formField"
                      [formFieldError]="formFieldErrors[formField.property]"
                      [formObject]="user_access"
                      (clearFormFieldError)="clearFormFieldError($event.property)">
          </form-input>

          <div class="ph-formItem"
               *ngIf="formField.field_visibility_requires_button_click">
            <div class="ph-formItem-label">
              {{formField.label}}
            </div>
            <div class="ph-formItem-value">
              <button class="-type-text -color-link-clear"
                      (click)="triggerFieldVisibility(formField)">
                Set {{formField.label}}
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="formField.property === 'contact_phone'">
          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Mobile
            </div>

            <ng-container *ngIf="is_droppah_candidate">
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !user_access.contact_phone }">
                {{user_access.contact_phone || '-'}}
              </div>
              <div class="ph-formItem-value">
                <button class="-type-text -color-link-clear"
                        *ngIf="is_droppah_candidate"
                        (click)="toggleEditMobile()">
                  Edit Mobile
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="!is_droppah_candidate">
              <div class="ph-formItem-value">
                <input *ngIf="!is_droppah_candidate"
                       class="form-control"
                       placeholder="Mobile"
                       [mask]="'00000000000'"
                       [(ngModel)]="contact_phone"
                       (click)="clearFormFieldError('contact_phone')">
              </div>
            </ng-container>

          </div>
        </div>
      </div>

    </div>

    <div class="ph-formItem-container -smallForm -mobileSlide"
         [style.left]="!!mobile_edit ? 0 : 450 + 'px'"
         [style.height]="!!mobile_edit ? '' : 0"
         [style.margin]="!!mobile_edit ? '' : 0">
      <div class="ph-formItem -mobileContainer">
        <div class="ph-formItem-label">
          Mobile
        </div>
        <country-code-input [activeValidationEnabled]="false"
                            [disabled]="!!show_otp_input"
                            [(ngModel)]="selected_country"
                            (click)="clearBannerError()">
        </country-code-input>
        <div class="ph-formItem-value">
          <input class="ph-formItem-input"
                 type="text"
                 placeholder="Mobile"
                 [disabled]="!!show_otp_input"
                 [mask]="'00000000000'"
                 [(ngModel)]="contact_phone"
                 (click)="clearFormFieldError('contact_phone')">
        </div>
        <div class="ph-formItem-error"
             *ngIf="formFieldErrors['contact_phone']">
          {{formFieldErrors['contact_phone']}}
        </div>
      </div>

      <div class="ph-formItem -codeInput"
           *ngIf="!!show_otp_input">

        <div class="ph-formItem-label">
          SMS Code
        </div>

        <input class="form-control"
               placeholder="- - - - - -"
               highlight-on-click
               [mask]="'999999'"
               [(ngModel)]="contact_phone_otp"
               (ngModelChange)="otpChanged()">

        <div class="ph-formItem-error"
             *ngIf="formFieldErrors['verification_code']">
          {{formFieldErrors['verification_code']}}
        </div>
      </div>
    </div>
  </div>

  <div class="ph-modal-btnFooter">
    <div class="ph-modal-btnFooterRight">

      <spinner *ngIf="!!loading"
               class="-spinnerFlex">
      </spinner>

      <button class="-color-success ph-modal-btnRight"
              *ngIf="!!mobile_edit"
              [disabled]="phone_verification_disabled || loading || contact_phone_copy === contact_phone"
              (click)="sendVerificationCode()">
        <span *ngIf="!phone_verification_disabled">
          Send SMS Code
        </span>
        <span *ngIf="phone_verification_disabled">
          {{phone_verification_countdown}}
        </span>
      </button>

      <button class="-color-success ph-modal-btnRight"
              *ngIf="!mobile_edit"
              (click)="save()">
        Save
      </button>

      <button class="-color-white-outline ph-modal-btnRight"
              (click)="!!mobile_edit ? toggleEditMobile() : cancel()">
        Cancel
      </button>

    </div>
  </div>

</div>
