import { ClientPartnerStaff } from './../client-partner-staff/client-partner-staff';
import { PostableObjectInterface, PostableObjectError } from './../../interfaces/postable-object.inerface';

import { ClientNote } from '../client-note/client-note';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';

export class Client implements PostableObjectInterface {

  client_key: number;
  client_name: string;
  client_address: string;
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  partner_flag: boolean;
  client_logo: string;

  client_partner_staff: ClientPartnerStaff[];
  client_notes: ClientNote[];

  constructor(
    client_key: number = null, client_name: string = null, client_address: string = null,
    contact_name: string = null, contact_phone: string = null, contact_email: string = null,
    partner_flag: boolean = false, client_logo: string = null,
    client_partner_staff: ClientPartnerStaff[] = [], client_notes: ClientNote[] = []
  ) {
    this.client_key = client_key;
    this.client_name = client_name;
    this.client_address = client_address;
    this.contact_name = contact_name;
    this.contact_phone = contact_phone;
    this.contact_email = contact_email;
    this.partner_flag = partner_flag;
    this.client_logo = client_logo;

    this.client_partner_staff = client_partner_staff;
    this.client_notes = client_notes || [];
  }

  getClientPartnerStaff(partner_staff_key: number): ClientPartnerStaff {
    for (const cps of this.client_partner_staff) {
      if (cps.partner_staff.partner_staff_key === partner_staff_key) {
        return cps;
      }
    }
    return null;
  }

  containsPartnerStaff(partner_staff_key: number): boolean {
    for (const cps of this.client_partner_staff) {
      if (cps.partner_staff.partner_staff_key === partner_staff_key) {
        return true;
      }
    }
    return false;
  }

  formatForPosting(toDelete: boolean = false): any {
    if (!this.hasErrors()) {
      return {
        client_key: this.client_key,
        client_name: this.client_name,
        client_address: this.client_address,
        contact_name: this.contact_name,
        contact_phone: this.contact_phone,
        contact_email: this.contact_email,
        deleted_flag: toDelete
      };
    }
    return null;
  }

  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  getErrors(): PostableObjectError[] {
    const errors: PostableObjectError[] = [];

    if (!this.client_name) {
      errors.push({
        error_path: 'client_name',
        error_message: 'Please enter a Client name'
      });
    }
    if (this.contact_email && !CoreUtilService.validateEmailAddress(this.contact_email)) {
      errors.push({
        error_path: 'contact_email',
        error_message: 'Please enter a valid contact email'
      });
    }

    return errors;
  }
}
