<div class="app-nav">
  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          {{partner.company_name}}
        </div>
      </div>
      <div class="app-nav-rowRight">
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="partnerEdit-sidePanel">

        <div class="ph-formItem-container -smallForm">

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Organisation Name
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !partner.company_name}">
              {{partner.company_name || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Name
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !partner.contact_name}">
              {{partner.contact_name || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Number
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !partner.contact_phone}">
              {{partner.contact_phone || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Email
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !partner.contact_email}">
              {{partner.contact_email || '-'}}
            </div>
          </div>

          <div class="app-buttonRow">
            <button class="-type-text -color-payhero-clear"
                    (click)="editDetails()">
              Edit Details
            </button>
          </div>

        </div>
      </div>
      <div class="partnerEdit-content">

        <div class="partnerEdit-list">
          <div class="ph-formItem-header">
            Partner Accounts
          </div>

          <client-account-list class="clientEdit-list -accountList"
                               #accountList
                               [noAccountsMessage]="'No accounts associated with ' + partner.company_name"
                               [accounts]="partnerAccounts"
                               (unlinkAccountFromClient)="updateAccount($event.account, true)"
                               (goToAccountSettings)="goToAccountSettings($event.account)"
                               (signIn)="signIn($event.account)">
          </client-account-list>

          <div class="app-buttonRow"
               *ngIf="!hideAddAccountButton">
            <list-drop-down [itemNameProperty]="'company_name'"
                            [itemProductValueProperty]="'product_value'"
                            [itemSortProperty]="'company_name'"
                            [buttonTypeClass]="'-type-text'"
                            [buttonColorClass]="'-color-payhero-clear'"
                            [buttonTitle]="'+ Account'"
                            [listDisabled]="loading"
                            [includeEmptyItem]="true"
                            [emptyItemLabel]="'New Account'"
                            [items]="unlinkedAccounts"
                            (itemSelected)="updateAccount($event.item)">
            </list-drop-down>
          </div>
        </div>

        <div class="partnerEdit-list">
          <div class="ph-formItem-header">
            Staff
          </div>

          <client-staff-list [client]="partnerClient"
                             (manageStaffAccountAccess)="manageStaffAccountAccess($event.client_partner_staff)"
                             (deleteStaff)="updateStaff($event.client_partner_staff.partner_staff, true)">
          </client-staff-list>

          <div class="app-buttonRow">
            <list-drop-down [itemNameProperty]="'full_name'"
                            [itemSortProperty]="'full_name'"
                            [buttonTypeClass]="'-type-text'"
                            [buttonColorClass]="'-color-payhero-clear'"
                            [buttonTitle]="'+ Staff'"
                            [searchBar]="true"
                            [listDisabled]="loading || !availableStaff.length"
                            [items]="availableStaff"
                            (itemSelected)="updateStaff($event.item, false)">
            </list-drop-down>
          </div>
        </div>

        <div class="partnerEdit-list">
          <div class="ph-formItem-header">
            Notes
          </div>

          <client-note-list [client]="partnerClient"
                            (viewNote)="manageNote($event.note, true)"
                            (editNote)="manageNote($event.note)"
                            (deleteNote)="deleteNote($event.note)">
          </client-note-list>

          <div class="app-buttonRow">
            <button class="-type-text -color-payhero-clear"
                    (click)="manageNote()">
              + Note
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
