<dp-list [primarySortProperty]="'company_name'"
         [itemType]="'account'"
         [itemTypePlural]="'accounts'"
         [itemSelectEnabledProperty]="'allow_account_select'"
         [items]="visible_accounts"
         (itemSelected)="selectAccount.emit({ account: $event.item })">
  <ng-template dpListItem
               let-account>

    <ng-container *ngIf="!account.company_product_key">
      <div class="dp-list-itemCell">
        <account-logo *ngIf="droppah_mobile_accounts.length === 1"
                      [account]="droppah_mobile_accounts[0]">
        </account-logo>
        <user-icon *ngIf="droppah_mobile_accounts.length !== 1"
                   [display_image]="user_access.thumbnail_picture"
                   [display_name]="user_access.full_name">
        </user-icon>
        <div class="al-accountDetails">

          <div class="al-accountDetails-name"
               *ngIf="droppah_mobile_accounts.length === 1">
            {{droppah_mobile_accounts[0].company_name}}
          </div>
          <div class="al-accountDetails-name -multiAccount"
               *ngIf="droppah_mobile_accounts.length > 1">
            {{droppah_mobile_accounts.length}} Accounts
          </div>

          <product-logo [productValue]="account.product_value"
                        [fullLogoOnly]="true">
          </product-logo>

          <tooltip-popover *ngIf="droppah_mobile_accounts.length > 1"
                           [tooltipContent]="droppah_multi_account_tooltip">
          </tooltip-popover>
        </div>
      </div>

      <div class="dp-list-itemCell">

      </div>
    </ng-container>

    <ng-container *ngIf="!!account.company_product_key">

      <div class="dp-list-itemCell">
        <account-logo [account]="account"
                      *ngIf="!is_partner && !account.sample_company">
        </account-logo>
        <div class="al-accountDetails">
          <div class="al-accountDetails-name">
            {{account.company_name}}
          </div>
          <product-logo [productValue]="account.product_value"
                        [fullLogoOnly]="true">
          </product-logo>
        </div>
      </div>

      <div class="dp-list-itemCell -accountSuspended"
           *ngIf="account.account_status === 'SUSPENDED'">
        <i class="bi bi-exclamation-triangle-fill"></i>
        <div class="-desktop-only">Suspended</div>
      </div>

      <div class="dp-list-itemCell"
           *ngIf="account.account_status === 'DEMO'">
        Demo Resets in:&nbsp;
        <span>{{account.trial_days_remaining}} {{account.trial_days_remaining === 1 ? 'day' : 'days'}}</span>
      </div>

      <div class="dp-list-itemCell"
           *ngIf="account.account_status === 'SUBSCRIBED'">
      </div>

      <div class="dp-list-itemCell"
           *ngIf="account.account_status === 'TRIAL_EXPIRED'">
        <div>Trial Expired</div>
      </div>

      <div class="dp-list-itemCell"
           *ngIf="account.account_status === 'ON_TRIAL'">
        Trial:&nbsp;
        <span>{{account.trial_days_remaining}} {{account.trial_days_remaining === 1 ? 'day' : 'days'}} remaining</span>
      </div>

      <div class="dp-list-itemCell"
           *ngIf="account.account_status === 'UNSUBSCRIBED'">
        Unsubscribed
      </div>

      <div class="dp-list-itemCell -desktop-only">
        <div
             *ngIf="account.last_session_date &&
        !(!account.account_subscription && (account.account_status === 'TRIAL_EXPIRED' || account.account_status === 'SUSPENDED'))">
          Last signed in {{getLastSignedInText(account.last_session_date)}}
        </div>
        <div *ngIf="(!account.account_subscription && (account.account_status === 'TRIAL_EXPIRED' || account.account_status === 'SUSPENDED'))"
             class="-expiredAccountSubscriptionOwnerDetails">
          <div>
            The subscription for this account is managed by
            {{account.subscription_billing_name}} ({{account.subscription_billing_email}})
          </div>
        </div>
      </div>

      <div class="dp-list-itemCell">
        <div class="al-noAccess"
             *ngIf="!account.allow_user_access && (account.account_status === 'SUBSCRIBED' || account.account_status === 'ON_TRIAL')">
          No Access
        </div>

        <list-drop-down *ngIf="account_dropdown_options[account.company_product_key][0].length || account_dropdown_options[account.company_product_key][1].length"
                        [itemNameProperty]="'option_name'"
                        [itemIsToggleableProperty]="'option_is_toggleable'"
                        [itemToggleValueProperty]="'option_toggle_value'"
                        [itemFontColorProperty]="'option_color'"
                        [buttonTypeClass]="'-type-icon'"
                        [buttonIconClass]="'ion-ios-more'"
                        [buttonColorClass]="'-color-grey-clear'"
                        [listWidth]="'200px'"
                        [sortItems]="false"
                        [items]="account_dropdown_options[account.company_product_key]"
                        (itemSelected)="accountDropdownOptionSelected($event.item.option_value, account)"
                        (click)="$event.stopPropagation()">
        </list-drop-down>

      </div>
    </ng-container>


  </ng-template>
</dp-list>
