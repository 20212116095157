import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ph-import-modal',
  templateUrl: './ph-import-modal.component.html',
  styleUrls: ['./ph-import-modal.component.scss']
})
export class PhImportModalComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void { }

  close() {
    this.activeModal.close();
  }

}
