import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginIntegration } from 'src/app/app.types';

@Component({
  selector: 'integration-button',
  templateUrl: './integration-button.component.html',
  styleUrls: ['./integration-button.component.scss']
})
export class IntegrationButtonComponent implements OnInit {

  @Input() loginIntegration: LoginIntegration;
  @Input() customPrefix: string;
  @Input() isDroppah?: boolean;

  @Output() buttonPressed = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  emitEvent(integration_type: string): void {
    this.buttonPressed.emit({ integration_type });
  }

}
