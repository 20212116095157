<img *ngIf="subscriptioncard.card_img"
     [src]="subscriptioncard.card_img">
<div>
  <div>{{subscriptioncard.card_name}}</div>
  <div class="sb-cardNumber">
    {{subscriptioncard.card_display}} <b>&nbsp; - &nbsp;</b>
    {{subscriptioncard.expiry_date | date: "MM/yy"}}
  </div>
</div>

<spinner *ngIf="loading"
         class="-spinnerFlex">
</spinner>
