import { AccountAccessService } from './../../services/account-access/account-access.service';
import { AccountService } from './../../services/account/account.service';
import { ClientService } from './../../services/client/client.service';
import { ClientPartnerStaff } from './../../models/client-partner-staff/client-partner-staff';
import { Client } from 'src/app/models/client/client';
import { PartnerStaffService } from './../../services/partner-staff/partner-staff.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Account } from 'src/app/models/account/account';

@Component({
  selector: 'app-manage-client-staff-access-modal',
  templateUrl: './manage-client-staff-access-modal.component.html',
  styleUrls: ['./manage-client-staff-access-modal.component.scss']
})
export class ManageClientStaffAccessModalComponent implements OnInit {

  @Input() client_key: number;
  @Input() partner_staff_key: number;

  client: Client;
  client_accounts: Account[];
  client_partner_staff: ClientPartnerStaff;
  staff_account_access_map: Record<number, { has_access: boolean, loading: boolean }>;

  constructor(
    public activeModal: NgbActiveModal,
    public partnerStaffService: PartnerStaffService,
    public clientService: ClientService,
    public accountService: AccountService,
    public accountAccessService: AccountAccessService
  ) { }

  ngOnInit(): void {
    this.initialiseModal();
  }

  initialiseModal() {
    this.client = this.clientService.getClient(this.client_key);
    this.client_accounts = this.accountService.getAllAccounts(
      ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO'],
      this.client.client_key
    );
    this.client_partner_staff = this.client.getClientPartnerStaff(this.partner_staff_key);

    if (!this.client || !this.client_partner_staff) {
      this.close();
    }
    else {
      this.refreshStaffAccountAccessMap();
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  refreshStaffAccountAccessMap() {
    this.staff_account_access_map = {};

    for (const account of this.client_accounts) {
      const has_access = this.client_partner_staff.account_access.has(account.company_product_key);

      this.staff_account_access_map[account.company_product_key] = {
        has_access,
        loading: false
      };
    }
  }

  toggleAccess(account: Account) {
    const account_access = this.staff_account_access_map[account.company_product_key];

    if (!account_access.loading) {
      account_access.loading = true;
      account_access.has_access = !account_access.has_access;

      this.accountAccessService.updateClientPartnerStaffAccountAccess(
        this.client.client_key,
        this.client_partner_staff.partner_staff.partner_staff_key,
        account.company_product_key,
        account_access.has_access
      )
        .finally(() => {
          this.initialiseModal();
        });
    }
  }

}
