<div class="option"
     [ngClass]="{'selected': adminFlag}"
     (click)="adminFlag = true; adminFlagChange.emit(adminFlag)">

  <div class="picker">
    <div *ngIf="adminFlag"></div>
  </div>

  <div class="info">
    <div class="name admin">
      <i class="ion ion-ios-contact"></i>
      Admin
    </div>

    <div class="description">
      Admins can manage Clients, Client Accounts, Client Subscriptions, and Partner Staff.
    </div>
  </div>
</div>

<div class="option"
     [ngClass]="{'selected': !adminFlag}"
     (click)="adminFlag = false; adminFlagChange.emit(adminFlag)">

  <div class="picker">
    <div *ngIf="!adminFlag"></div>
  </div>

  <div class="info">
    <div class="name readOnly">
      <i class="ion ion-md-eye"></i>
      Advisor
    </div>

    <div class="description">
      Advisors can access Client Accounts they have been added to.
    </div>
  </div>
</div>
