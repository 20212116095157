import { StateDataService } from './../../../services/state-data/state-data.service';
import { UserService } from './../../../services/user/user.service';
import { PartnerService } from 'src/app/services/partner/partner.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';

import { AccountService } from '../../../services/account/account.service';
import { Account } from '../../../models/account/account';
import { ProductService } from '../../../services/product/product.service';
import { RedirectService } from '../../../services/redirect/redirect.service';
import { ModalService } from '../../../services/modal/modal.service';
import { ProductValue, AccountFilter } from './../../../app.types';

import { Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-account-dash',
  templateUrl: './account-dash.component.html',
  styleUrls: ['./account-dash.component.scss'],
})
export class AccountDashComponent implements OnInit {

  loading: boolean;
  readonly is_partner: boolean = this.stateAccessService.isPartner;
  readonly has_subscription: boolean = this.userService.has_subscription;

  accounts: Account[] = [];
  demoAccounts: Account[] = [];
  has_droppah_mobile_access: boolean = false;

  showAddDemoAccountButton: boolean = true;
  showAddAccountButton: boolean = false;

  autoSelectAccount: boolean = this.uiRouterGlobals.params.auto_select_account_flag;
  autoSelectAccountProductValue: ProductValue = this.uiRouterGlobals.params.auto_select_account_product_value;

  event_subscriptions: Subscription[] = [];

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public accountService: AccountService,
    public modalService: ModalService,
    public productService: ProductService,
    public redirectService: RedirectService,
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public partnerService: PartnerService,
    public userService: UserService,
    public stateDataService: StateDataService,
    public subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.updateAccounts();
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  updateAccounts() {
    const filters: AccountFilter[] = ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS', 'EXCLUDE_DROPPAH_MOBILE_ACCOUNTS'];

    this.accounts = this.is_partner ? [] : this.accountService.getAllAccounts(filters, null, null, null, true);
    this.demoAccounts = this.accountService.getAllAccounts(['DEMO_ONLY'], null, null, null, true);
    this.has_droppah_mobile_access = this.accountService.hasDroppahMobileAccess();


    this.updateShowAddDemoAccountButton();
  }

  updateShowAddDemoAccountButton() {
    if (this.is_partner) {
      this.showAddAccountButton = false;
      // this.showAddDemoAccountButton = !this.accountService.userHasAllSampleAccountsActive();
      this.showAddDemoAccountButton = true;
    }
    else if (this.has_subscription) {
      this.showAddAccountButton = true;
      this.showAddDemoAccountButton = false;
    }
    else {
      this.showAddAccountButton = false;
      this.showAddDemoAccountButton = false;
    }
  }

  newAccount(demo_only: boolean = false) {
    this.modalService.addAccountModal(!demo_only, demo_only)
      .then((data) => {
        this.loading = true;

        if (data.create_demo) {
          this.accountService.createDemoAccount(data.product_value, data.company_name)
            .finally(() => {
              this.updateAccounts();
              this.loading = false;
            });
        }
        else {
          this.accountService.createAccount(
            data.company_name,
            null,
            data.product_value,
            data.number_of_employees,
            data.region,
            data.industry_key,
            data.business_type,
            data.nzbn,
            data.industry_classification,
            data.ird_number,
            true,
            true,
            data.sub_agreement_flag,
            false,
            data.contact_phone
          )
            .finally(() => {
              this.updateAccounts();
              this.loading = false;
            });
        }

      })
      .catch(() => this.updateAccounts());
  }

  deleteDemoAccount(account: Account) {
    this.modalService.confirmModal('Delete', 'Demo Account', account.company_name, null, 'All data associated with this Demo Account will be deleted.')
      .then(() => {
        this.accountService.deleteDemoAccount(account?.company_product_key)
          .finally(() => {
            this.updateAccounts();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  subscribe(account: Account) {
    this.stateService.go('app.subscription.dash', {
      action: 'subscribe',
      company_product_key: account.company_product_key
    });
  }

  selectAccount(account: Account) {
    if (account.allow_user_access) {
      this.signIn(account);
    }
    else if (!!account.suspended_flag && !!account.account_subscription) {
      this.subscriptionService.paySuspendedInvoicesModal()
        .then((payment_made_flag) => {
          if (payment_made_flag) this.accountService.loadAccounts();
        })
        .catch(() => { });
    }
    else if (account.account_subscription?.show_subscribe_button) {
      this.subscribe(account);
    }
    else if (!account.cancelled_flag && (account.trial_expired_flag || !!account.suspended_flag)) {
      this.modalService.errorModal(
        (!!account.suspended_flag ? 'Account Suspended':'Trial Expired'),
        '<div>The subscription for this account is managed by ' +
        account.subscription_billing_name + ' ' +
        '(' + account.subscription_billing_email + ')</div>'
      );
    }
  }

  signIn(
    account: Account,
    external_state_name: string = null,
  ) {
    this.loading = true;

    if (!account) {
      this.loading = false;
      return;
    }

    if (account.allow_user_access) {
      if (!account.incomplete_onboarding_steps?.length) {

        this.productService.startNewSession(account.company_product_key, account.product_value)
          .then((session) => {
            // this._setRecentProductLogin(account.product_value, account.company_product_key);
            this.redirectService.redirectToProduct(session.login_source, this.is_partner, external_state_name);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      else if (account.product_value === 'PAYHERO') {
        this.stateService.go('app.account.create', { product: account.product_value, company_product_key: account.company_product_key });
        this.loading = false;
      }

    }
    else {
      this.loading = false;
      this.modalService.errorModal('Access to ' + (account.company_name ? account.company_name : 'this account') + ' is not allowed');
    }
  }

  viewSubscription(account: Account) {
    this.stateService.go('app.subscription.account', {
      company_product_key: account.company_product_key,
      product_value: account.product_value
    });
  }

  private _initEventListeners(): void {
    this.event_subscriptions.push(
      this.accountService.getServiceDataUpdateEvent().subscribe(() => {
        this.updateAccounts();
      })
    );
  }

  private _clearEventListeners(): void {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.event_subscriptions = [];
  }
}
