<div class="dp-loadingOverlay"
     *ngIf="loading">
  <img src="assets/imgs/App_Loader.gif">
</div>

<div class="token-page-header">
  <img class="token-page-header-logo"
       src="assets/product-imgs/droppah/Droppah_Single.svg"
       alt="">

  <h3 class="token-page-header-title">
    Cover Request
  </h3>
</div>

<div class="dp-cover-request-container">

  <div class="dp-cover-request-card"
       *ngIf="!loading && !tokenExpired">

    <p *ngIf="cover_request.approved_declined_date && cover_request.request_approved"
       class="dp-cover-request-approved-flag">
      This cover request has been approved
    </p>

    <p *ngIf="cover_request.approved_declined_date && !cover_request.request_approved"
       class="dp-cover-request-declined-flag">
      This cover request has been declined
    </p>

    <div class="dp-cover-request-people">

      <div class="dp-cover-request-person">
        <user-icon [display_name]="cover_request.original_person_display_name"
                   [display_image]="cover_request.original_person_profile_image"
                   [placeholder_color]="'var(--droppah-color-stone)'"
                   [text_color]="'var(--app-color-black)'">
        </user-icon>
        <p>{{cover_request.original_person_display_name}}</p>
      </div>
      <i class="bi bi-arrow-right"></i>
      <div class="dp-cover-request-person">
        <user-icon [display_name]="cover_request.new_person_display_name"
                   [display_image]="cover_request.new_person_profile_image"
                   [placeholder_color]="'var(--droppah-color-stone)'"
                   [text_color]="'var(--app-color-black)'">
        </user-icon>
        <p>{{cover_request.new_person_display_name}}</p>
      </div>
    </div>
    <div class="dp-cover-request-shift"
         [style.background-color]="cover_request.skill_color"
         [style.color]="skill_text_color">
      <p>{{cover_request.skill_name}}</p>
      <div>
        <p>{{ cover_request.shift_date | date:'mediumDate' }}</p>
        <p>{{ cover_request.start_time | date:'shortTime' }} - {{ cover_request.end_time | date:'shortTime' }}</p>
      </div>
    </div>

    <span class="dp-cover-request-messages"
          *ngIf="!!cover_request.notes.length">
      <p class="dp-cover-request-info-title">
        Messages
      </p>
      <p class="dp-cover-request-message"
         *ngFor="let message of cover_request.notes">
        {{message.display_name}}: <span>{{message.note}}</span>
      </p>
    </span>

    <span *ngIf="!!cover_request && !cover_request.approved_declined_date">
      <p class="dp-cover-request-info-title">Up to you...</p>
      <textarea placeholder="Respond..."
                rows="4"
                [(ngModel)]="message">
      </textarea>

      <span class="dp-cover-request-buttons">
        <button class="-color-danger-outline"
                (click)="declineRequest()">
          Decline
        </button>
        <button class="-color-droppah"
                (click)="acceptRequest()">
          Approve
        </button>

      </span>
    </span>

  </div>

  <div class="dp-cover-request-card -expired"
       *ngIf="!loading && !!tokenExpired">
    <span>This link is no longer valid</span>
    <a href="https://login.droppah.com/#/login?destination=DROPPAH_APP">
      Sign In to Droppah
    </a>
  </div>
</div>
