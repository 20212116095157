export class PromoCode {

  promo_code: string;
  description: string;
  credit_amount: number;
  discount_months: number;
  expired_flag: boolean;

  constructor(
    promo_code: string = null, description: string = null, credit_amount: number = 0,
    discount_months: number = 0, expired_flag: boolean = true
  ) {
    this.promo_code = promo_code;
    this.description = description;
    this.credit_amount = credit_amount;
    this.discount_months = discount_months;
    this.expired_flag = expired_flag;
  }

}
