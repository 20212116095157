import { UIRouterGlobals } from '@uirouter/core';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TransitionService, StateService } from '@uirouter/angular';

import { StateDataService } from './../../services/state-data/state-data.service';
import { DomService } from './../../services/dom/dom.service';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';

import * as $ from 'jquery';

// Public Types
export type MegaSearchSearchProperty = {
  label: string,
  key: string,
  paths: string[]
};

export type MegaSearchConfig = {
  // -- search_properties examples --
  // Searching for plans with a particular person
  // eg. { label: 'Person', key: 'person_name', paths: [ 'plan_shifts.shift_skills.shift_skill_people.person.display_name' ] }

  // Searching for plans with a person that has a particular email address
  // eg. { label: 'Email', key: 'person_email', paths: [ 'plan_shifts.shift_skills.shift_skill_people.person.email' ] }

  // Searching for plans with a deleted shift
  // Note: Because plan_shifts.deleted_flag is a boolean, we need to provide a custom string value
  //       which users can search against for cases when plan_shifts.deleted_flag === true.
  //       The string must be prefixed with '::', so in this case, the search string is 'deleted'
  // eg. { label: 'Deleted Shift', key: 'deleted_shift', paths: [ 'plan_shifts.deleted_flag::deleted' ] }
  search_properties: MegaSearchSearchProperty[],
  // Property name of the unique identifier key for each item in this.items
  // eg. 'plan_key'
  item_key_property: string,
  // Map of item keys that match the current filters
  filtered_keys: Set<number>
};

// Private Types
type _ActiveSearchPropRef = {
  search_prop_key: string, // MegaSearchSearchProperty.key
  item_prop_value: string // searchInput.toUpperCase()
};
type _SearchPropertyMap = Record<string, MegaSearchSearchProperty>;

// eg { *client_key*: { 'client_name': { 'GREGS MOWING' } } }
type _ItemKeyToItemPropValueMap = Record<number, Record<string, Set<string>>>;
// eg { 'GEORGE FOREMAN': { 'person_name': { *person_key* } } }
type _ItemPropValueToItemKeyMap = Record<string, Record<string, Set<number>>>;

@Component({
  selector: 'mega-search',
  templateUrl: './mega-search.component.html',
  styleUrls: ['./mega-search.component.scss']
})
export class MegaSearchComponent implements OnInit {

  @ViewChild(NgbDropdown) megaSearchDropdown: NgbDropdown;
  @ViewChild('megaSearchInput') megaSearchInput: ElementRef;
  @ViewChild('megaSearchInputMobile') megaSearchInputMobile: ElementRef;

  // Required when multiple megaSearch components exist on a single page
  // Also used for caching search terms to session storage
  @Input() megaSearchId: string = null;
  // A separate MegaSearchConfig is required for each list
  // The key for each config in the map must match the key for each list in this.items
  @Input() megaSearchConfigMap: Record<string, MegaSearchConfig> = null;
  @Input() maxFilteredSearchPropertiesToShow: number = 8;
  @Input() inputPlaceholder: string = 'Search...';
  @Input() instantSearch: boolean = false;
  @Input() cachingEnabled: boolean = true;
  @Input() disabled: boolean = false;

  // Tracks whether any filters are currently active, regardless of whether the filters have any valid item matches
  filterActive: boolean = false;

  // One or many lists
  // Each list needs to be wrapped in a map(Record),
  // each with a unique id(list_key) eg. { 'clients': [...], 'people': [...] }
  private _items: Record<string, any[]> = {};
  @Input()
  get items(): Record<string, any[]> {
    return this._items;
  }
  set items(items: Record<string, any[]>) {
    if (this.megaSearchConfigMap !== null) {
      this._items = items;

      if (!!this._items) {
        this.buildMaps();
        this.searchInput = '';

        if (!this.initialised) {
          this.initialised = true;

          if (!this.instantSearch && this.cachingEnabled) {
            this.loadCachedSelectedSearchProperties();
          }
          else {
            this.selectedSearchPropertiesUpdated();
          }
        }
        else {
          this.selectedSearchPropertiesUpdated();
        }
      }
      else {
        this._items = {};
      }
    }
  }

  // The emitted event will contain a map of keys found by the current search terms
  // eg { 'people': { 1, 45, 67 }, 'clients': { 3, 45 } }
  @Output() searchUpdated = new EventEmitter<(Set<number> | Record<string, Set<number>>)>();

  private _searchInput: string = '';
  get searchInput(): string {
    return this._searchInput;
  }
  set searchInput(searchInput: string) {
    if (searchInput !== this._searchInput) {
      this._searchInput = searchInput;
      this.highlightedFilteredSearchPropertyIndex = null;
      this.searchInputUpdated();

      if (this.instantSearch) {
        this.selectGlobalSearchProperty();
      }
      else {
        this.toggleDropdown();
      }
    }
  }

  searchPropertyMap: Record<string, _SearchPropertyMap> = {};

  // eg
  // {
  //   'people': {
  //     *person_key*: {
  //       'person_skills': { 'CHEF', 'BAR' }
  //     }
  //   }
  // }
  itemKeyToItemPropValueMap: Record<string, _ItemKeyToItemPropValueMap> = {};

  // eg
  // {
  //   'clients': {
  //     'PETES PUB': {
  //       'client_name': { *client_key* },
  //       'company_name': { *company_product_key* }
  //     }
  //   }
  // }
  itemPropValueToItemKeyMap: Record<string, _ItemPropValueToItemKeyMap> = {};

  filteredSearchProperties: _ActiveSearchPropRef[] = [];
  selectedSearchProperties: _ActiveSearchPropRef[] = [];

  // Grouped by list
  itemPropValueToUnformattedItemPropValueMap: Record<string, Record<string, string>> = {};
  // Concatenated map of itemPropValueToUnformattedItemPropValueMap
  allItemPropValueToUnformattedItemPropValueMap: Record<string, string> = {};
  // eg user searchs for 'George' => { 'GEORGE': 'George' }
  globalSearchValueToUnformattedSearchValueMap: Record<string, string> = {};

  listWidth: string;
  alignLeft: boolean;
  highlightedFilteredSearchPropertyIndex: number = null;

  initialised: boolean = false;

  constructor(
    public eRef: ElementRef,
    public transitionService: TransitionService,
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public stateDataService: StateDataService,
    public domService: DomService
  ) { }

  ngOnInit() {
    if (!this.megaSearchId) {
      this.cachingEnabled = false;
      this.megaSearchId = Math.floor(Math.random() * 10000000) + '';
    }
    this.selectedSearchPropertiesUpdated();
  }

  focusInput() {
    setTimeout(() => {
      if (CoreUtilService.is_mobile) {
        $('#megaSearchInputMobile_' + this.megaSearchId).trigger('focus');
      }
      else {
        $('#megaSearchInput_' + this.megaSearchId).trigger('focus');
      }
    });
  }

  dropdownToggled(isOpen: boolean) {
    if (!this.instantSearch) {
      if (isOpen) {
        this.domService.openBackdrop();

        if (CoreUtilService.is_mobile) {
          this.focusInput();
        }
      }
      else {
        this.domService.closeBackdrop();
      }
    }
  }

  clearSearchInput() {
    this.searchInput = '';
  }

  toggleDropdown() {
    if (
      this.searchInput.length !== 0 ||
      this.filteredSearchProperties.length !== 0 ||
      this.selectedSearchProperties.length !== 0
    ) {
      this.openDropdown();
    }
    else {
      this.closeDropdown();
    }
  }

  openDropdown() {
    if (this.megaSearchDropdown && !this.instantSearch) {
      this.determineDropAlignment();
      this.megaSearchDropdown.open();
    }
  }

  closeDropdown() {
    this.highlightedFilteredSearchPropertyIndex = null;

    if (this.megaSearchDropdown) {
      this.determineDropAlignment();
      this.megaSearchDropdown.close();
    }
  }

  determineDropAlignment() {
    const windowWidth = $(window).width();
    const xOffsetFromWindow = this.eRef.nativeElement.getBoundingClientRect().left;

    this.listWidth = this.eRef.nativeElement.offsetWidth + 'px';
    this.alignLeft = xOffsetFromWindow < (windowWidth / 2);
  }

  // Search against a specific search property
  selectFilteredSearchProperty(
    filteredSearchProperty: _ActiveSearchPropRef
  ): void {
    const selectedSearchPropertiesMap = this._buildSelectedSearchPropertiesMap();

    // Only add filteredSearchProperty if it doesn't already exist in this.selectedSearchProperties
    if (!selectedSearchPropertiesMap[
      filteredSearchProperty.search_prop_key + '_' + filteredSearchProperty.item_prop_value
    ]) {
      this.selectedSearchProperties.push(filteredSearchProperty);
    }

    this.selectedSearchPropertiesUpdated();
    this.closeDropdown();
  }

  // Search against all search properties
  selectGlobalSearchProperty() {
    if (this.instantSearch) {
      if (this.searchInput.length) {
        const item_prop_value = this.searchInput.toUpperCase();

        this.selectedSearchProperties = [{
          search_prop_key: null,
          item_prop_value
        }];

        if (!this.globalSearchValueToUnformattedSearchValueMap) {
          this.globalSearchValueToUnformattedSearchValueMap = {};
        }
        this.globalSearchValueToUnformattedSearchValueMap[item_prop_value] = this.searchInput;

        this.selectedSearchPropertiesUpdated();
      }
      else {
        this.clearAllSelectedSearchProperties();
      }
    }
    else {
      if (this.searchInput.length) {
        const item_prop_value = this.searchInput.toUpperCase();
        const selectedSearchPropertiesMap = this._buildSelectedSearchPropertiesMap();

        // Only add filteredSearchProperty if it doesn't already exist in this.selectedSearchProperties
        if (!selectedSearchPropertiesMap[
          item_prop_value
        ]) {
          this.selectedSearchProperties.push({
            search_prop_key: null,
            item_prop_value
          });

          if (!this.globalSearchValueToUnformattedSearchValueMap) {
            this.globalSearchValueToUnformattedSearchValueMap = {};
          }
          this.globalSearchValueToUnformattedSearchValueMap[item_prop_value] = this.searchInput;
        }

        this.selectedSearchPropertiesUpdated();
      }
    }
  }

  removeSelectedSearchProperty(
    selectedSearchProperty: _ActiveSearchPropRef
  ): void {

    for (let i = 0; i < this.selectedSearchProperties.length; i++) {
      const selected = this.selectedSearchProperties[i];

      if (
        selected.search_prop_key === selectedSearchProperty.search_prop_key &&
        selected.item_prop_value === selectedSearchProperty.item_prop_value
      ) {
        this.selectedSearchProperties.splice(i, 1);

        // global search property
        if (selected.search_prop_key === null) {
          delete this.globalSearchValueToUnformattedSearchValueMap[selected.item_prop_value];
        }
      }
    }

    this.selectedSearchPropertiesUpdated();

    if (this.selectedSearchProperties.length === 0) {
      this.closeDropdown();
    }
  }

  clearAllSelectedSearchProperties() {
    this.selectedSearchProperties = [];
    this.selectedSearchPropertiesUpdated();
    this.closeDropdown();
    this.globalSearchValueToUnformattedSearchValueMap = {};
  }

  selectedSearchPropertiesUpdated(): void {
    if (this.selectedSearchProperties.length) {
      for (const list_key of Object.keys(this.megaSearchConfigMap)) {
        this.megaSearchConfigMap[list_key].filtered_keys = this._getFilteredItemKeys(
          this.itemKeyToItemPropValueMap[list_key]
        );
      }
    }
    // No selected filters, return all item keys
    else {
      for (const list_key of Object.keys(this.megaSearchConfigMap)) {
        this.megaSearchConfigMap[list_key].filtered_keys = this._getFilteredItemKeysWhenNoSearchPropertiesSelected(
          this.itemKeyToItemPropValueMap[list_key]
        );
      }
    }

    if (!this.instantSearch) {
      this.searchInput = '';
    }

    this.filterActive = this.selectedSearchProperties.length > 0;

    this.searchUpdated.emit();

    this.cacheSelectedSearchProperties();
  }

  private _getFilteredItemKeys(
    itemKeyToItemPropValueMap: _ItemKeyToItemPropValueMap
  ): Set<number> {
    const selected_search_prop_to_item_keys: Record<string, Set<number>> = {};
    let least_item_keys: Set<number> = null;

    for (const selected_search_prop of this.selectedSearchProperties) {

      const item_prop_value = selected_search_prop.item_prop_value;
      const item_keys = new Set<number>();

      // Search against a specific search property
      if (selected_search_prop.search_prop_key !== null) {
        for (const item_key of Object.keys(itemKeyToItemPropValueMap)) {

          if (itemKeyToItemPropValueMap[item_key][selected_search_prop.search_prop_key]) {
            if (itemKeyToItemPropValueMap[item_key][selected_search_prop.search_prop_key].has(item_prop_value)) {

              item_keys.add(parseInt(item_key));
            }
          }
        }

        selected_search_prop_to_item_keys[selected_search_prop.search_prop_key + '_' + item_prop_value] = item_keys;
      }
      // Search against all search properties
      else {

        for (const item_key of Object.keys(itemKeyToItemPropValueMap)) {
          itemSearchPropLoop: for (const search_prop_key of Object.keys(itemKeyToItemPropValueMap[item_key])) {

            for (const ipv of itemKeyToItemPropValueMap[item_key][search_prop_key]) {
              if (ipv.indexOf(item_prop_value) !== -1) {

                item_keys.add(parseInt(item_key));
                break itemSearchPropLoop;
              }
            }
          }
        }

        selected_search_prop_to_item_keys[item_prop_value] = item_keys;
      }

      // Keep track of search_prop_key_to_item_key map with the least number of item_keys
      // to improve performance when checking that each item_key exists in each nested search_prop_key_to_item_keys map
      if (
        least_item_keys === null ||
        item_keys.size < least_item_keys.size
      ) {
        least_item_keys = item_keys;
      }
    }

    const filteredItemKeys: Set<number> = new Set();

    for (const item_key of least_item_keys) {
      let item_key_in_all_maps = true;

      for (const key of Object.keys(selected_search_prop_to_item_keys)) {
        if (!selected_search_prop_to_item_keys[key].has(item_key)) {
          item_key_in_all_maps = false;
          break;
        }
      }

      if (item_key_in_all_maps === true) {
        filteredItemKeys.add(item_key);
      }
    }

    return filteredItemKeys;
  }

  private _getFilteredItemKeysWhenNoSearchPropertiesSelected(
    itemKeyToItemPropValueMap: _ItemKeyToItemPropValueMap
  ): Set<number> {
    const filteredItemKeys: Set<number> = new Set();
    for (const item_key of Object.keys(itemKeyToItemPropValueMap)) {
      filteredItemKeys.add(parseInt(item_key));
    }
    return filteredItemKeys;
  }

  private _buildSelectedSearchPropertiesMap(): Set<string> {
    const selectedSearchPropertiesMap = new Set<string>();

    for (const ssp of this.selectedSearchProperties) {
      selectedSearchPropertiesMap.add(ssp.search_prop_key + '_' + ssp.item_prop_value);
    }

    return selectedSearchPropertiesMap;
  }

  searchInputUpdated(): void {
    this.filteredSearchProperties = [];

    if (!!this.searchInput) {
      const searchInput = this.searchInput.toUpperCase();

      const selectedSearchPropertiesMap = this._buildSelectedSearchPropertiesMap();
      let filteredSearchPropertiesMap: Record<string, _ActiveSearchPropRef> = {};

      for (const list_key of Object.keys(this.megaSearchConfigMap)) {

        filteredSearchPropertiesMap = this._buildFilteredSearchPropertiesMap(
          this.itemPropValueToItemKeyMap[list_key],
          filteredSearchPropertiesMap,
          selectedSearchPropertiesMap,
          searchInput
        );
      }

      for (const key of Object.keys(filteredSearchPropertiesMap)) {
        this.filteredSearchProperties.push(filteredSearchPropertiesMap[key]);
      }
    }
  }

  private _buildFilteredSearchPropertiesMap(
    itemPropValueToItemKeyMap: _ItemPropValueToItemKeyMap,
    filteredSearchPropertiesMap: Record<string, _ActiveSearchPropRef>,
    selectedSearchPropertiesMap: Set<string>,
    searchInput: string
  ): Record<string, _ActiveSearchPropRef> {

    // Prioritise prop_values that match searchInput from the beginning of the string
    for (const item_prop_value of Object.keys(itemPropValueToItemKeyMap)) {
      // Look for prop_values that match searchInput from the beginning of the string
      if (item_prop_value.indexOf(searchInput) === 0) {
        filteredSearchPropertiesMap = this._addToFilteredSearchPropertiesMap(
          itemPropValueToItemKeyMap,
          selectedSearchPropertiesMap,
          filteredSearchPropertiesMap,
          item_prop_value
        );
      }
      if (Object.keys(filteredSearchPropertiesMap).length === this.maxFilteredSearchPropertiesToShow) {
        break;
      }
    }

    if (Object.keys(filteredSearchPropertiesMap).length < this.maxFilteredSearchPropertiesToShow) {
      // Look for prop_values that match searchInput from any point in the string
      for (const item_prop_value of Object.keys(itemPropValueToItemKeyMap)) {
        if (item_prop_value.indexOf(searchInput) !== -1) {
          filteredSearchPropertiesMap = this._addToFilteredSearchPropertiesMap(
            itemPropValueToItemKeyMap,
            selectedSearchPropertiesMap,
            filteredSearchPropertiesMap,
            item_prop_value
          );
        }
        if (Object.keys(filteredSearchPropertiesMap).length === this.maxFilteredSearchPropertiesToShow) {
          break;
        }
      }
    }

    return filteredSearchPropertiesMap;
  }

  private _addToFilteredSearchPropertiesMap(
    itemPropValueToItemKeyMap: _ItemPropValueToItemKeyMap,
    selectedSearchPropertiesMap: Set<string>,
    filteredSearchPropertiesMap: Record<string, _ActiveSearchPropRef>,
    item_prop_value: string
  ): Record<string, _ActiveSearchPropRef> {
    for (const search_prop_key of Object.keys(itemPropValueToItemKeyMap[item_prop_value])) {

      // Skip search_property-item-value combinations that've already added to this.selectedSearchProperties
      if (!selectedSearchPropertiesMap.has(search_prop_key + '_' + item_prop_value)) {
        // Only add search_property-item-value combinations if they match at least
        // one item in the current megaSearchConfig.filtered_keys map
        if (this._filteredSearchPropertyMatchesAtLeastOneFilteredItemKey(
          search_prop_key,
          item_prop_value
        )) {
          filteredSearchPropertiesMap[search_prop_key + '_' + item_prop_value] = {
            search_prop_key,
            item_prop_value
          };

          if (Object.keys(filteredSearchPropertiesMap).length === this.maxFilteredSearchPropertiesToShow) {
            return filteredSearchPropertiesMap;
          }
        }
      }
    }
    return filteredSearchPropertiesMap;
  }

  private _filteredSearchPropertyMatchesAtLeastOneFilteredItemKey(
    search_prop_key: string, item_prop_value: string
  ): boolean {

    for (const list_key of (Object.keys(this.megaSearchConfigMap))) {
      for (const item_key of this.megaSearchConfigMap[list_key].filtered_keys) {

        if (this.itemKeyToItemPropValueMap[list_key][item_key][search_prop_key]) {
          if (this.itemKeyToItemPropValueMap[list_key][item_key][search_prop_key].has(item_prop_value)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  buildMaps(): void {
    this.buildSearchPropertyMap();
    this.buildItemKeyToItemPropValueMap();
    this.buildItemPropValueToItemKeyMap();
  }

  buildSearchPropertyMap(): void {
    const searchPropertyMap = {};

    for (const list_key of (Object.keys(this.megaSearchConfigMap))) {
      for (const msp of this.megaSearchConfigMap[list_key].search_properties) {
        searchPropertyMap[msp.key] = msp;
      }
    }
    this.searchPropertyMap = searchPropertyMap;
  }

  buildItemKeyToItemPropValueMap(): void {
    this.itemPropValueToUnformattedItemPropValueMap = {};
    this.allItemPropValueToUnformattedItemPropValueMap = {};
    this.itemKeyToItemPropValueMap = {};

    for (const list_key of Object.keys(this.items)) {
      this.itemPropValueToUnformattedItemPropValueMap[list_key] = {};
      this.itemKeyToItemPropValueMap[list_key] = {};

      for (const item of this.items[list_key]) {
        // eg. 'client.client_key'
        const item_key = item[this.megaSearchConfigMap[list_key].item_key_property];

        this.itemKeyToItemPropValueMap[list_key][item_key] = this._getItemPropValuesForList(item, list_key);
      }
    }
  }

  private _getItemPropValuesForList(
    item: any,
    list_key: string = null
  ): Record<string, Set<string>> {
    const prop_values_map = {};

    for (const search_property of this.megaSearchConfigMap[list_key].search_properties) {
      this._getItemPropValuesForSearchPropertyInList(item, prop_values_map, search_property, list_key);
    }

    return prop_values_map;
  }

  private _getItemPropValuesForSearchPropertyInList(
    item: any,
    prop_values_map: Record<string, Set<string>>,
    search_property: MegaSearchSearchProperty,
    list_key: string = null
  ): void {
    prop_values_map[search_property.key] = new Set();

    for (let path of search_property.paths) {
      // eg. 'person_skill.skill.skill_colour' => ['person_skill', 'skill', 'skill_colour']
      const prop_path = path.split('.');

      this._getItemNodeValue(item, search_property.label, search_property.key, prop_path, 0, prop_values_map, list_key);
    }
  }

  private _getItemNodeValue(
    node: any,
    prop_label: string,
    prop_key: string,
    prop_path: string[],
    path_depth: number,
    prop_values_map: Record<string, Set<string>>,
    list_key: string = null
  ): void {
    if (node) {
      let node_prop = prop_path[path_depth];

      // End point node so add its value
      if (path_depth === prop_path.length - 1) {
        const bool_id_index = node_prop.indexOf('::');

        // Property is a boolean
        // This is indicated by the '::' identifier
        // The substring after the identifier is used to determine the value to search against for this property
        if (bool_id_index !== -1) {
          const node_prop_value = node_prop.substring(bool_id_index + 2);
          const formatted_node_prop_value = node_prop_value.toUpperCase();

          node_prop = node_prop.substring(0, bool_id_index);

          if (node[node_prop] === true) {
            prop_values_map[prop_key].add(formatted_node_prop_value);
            this.itemPropValueToUnformattedItemPropValueMap[list_key][formatted_node_prop_value] = node_prop_value;
            this.allItemPropValueToUnformattedItemPropValueMap[formatted_node_prop_value] = node_prop_value;
          }
        }
        // Property is a string
        else {
          if (node[node_prop]) {
            const node_prop_value = node[node_prop] + '';
            const formatted_node_prop_value = node_prop_value.toUpperCase();

            prop_values_map[prop_key].add(formatted_node_prop_value);
            this.itemPropValueToUnformattedItemPropValueMap[list_key][formatted_node_prop_value] = node_prop_value;
            this.allItemPropValueToUnformattedItemPropValueMap[formatted_node_prop_value] = node_prop_value;
          }
        }
      }
      // Need to go deeper
      else {
        // If current node is an array, need to run through each item in array
        if (node[node_prop] instanceof Array) {
          for (const n of node[node_prop]) {
            this._getItemNodeValue(n, prop_label, prop_key, prop_path, path_depth + 1, prop_values_map, list_key);
          }
        }
        else {
          this._getItemNodeValue(node[node_prop], prop_label, prop_key, prop_path, path_depth + 1, prop_values_map, list_key);
        }
      }
    }
  }

  buildItemPropValueToItemKeyMap(): void {
    this.itemPropValueToItemKeyMap = {};

    for (const list_key of (Object.keys(this.megaSearchConfigMap))) {
      this.itemPropValueToItemKeyMap[list_key] = {};

      for (const prop_value of Object.keys(this.itemPropValueToUnformattedItemPropValueMap[list_key])) {
        this.itemPropValueToItemKeyMap[list_key][prop_value] = {};
      }

      // eg. client_key
      for (let item_key of Object.keys(this.itemKeyToItemPropValueMap[list_key])) {
        // MegaSearchSearchProperty.key
        // eg. client_name
        for (const search_prop_key of Object.keys(this.itemKeyToItemPropValueMap[list_key][item_key])) {
          // eg. 'GREGS MOWING'
          for (const prop_value of this.itemKeyToItemPropValueMap[list_key][item_key][search_prop_key]) {
            if (!this.itemPropValueToItemKeyMap[list_key][prop_value][search_prop_key]) {
              this.itemPropValueToItemKeyMap[list_key][prop_value][search_prop_key] = new Set();
            }

            this.itemPropValueToItemKeyMap[list_key][prop_value][search_prop_key].add(parseInt(item_key));
          }
        }
      }
    }
  }

  keyPressed(event: KeyboardEvent): void {
    if (!this.instantSearch) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.highlightedFilteredSearchPropertyIndex === null) {
            this.highlightedFilteredSearchPropertyIndex = 0;
          }
          else if (this.highlightedFilteredSearchPropertyIndex === this.filteredSearchProperties.length - 1) {
            this.highlightedFilteredSearchPropertyIndex = 0;
          }
          else {
            this.highlightedFilteredSearchPropertyIndex++;
          }
          break;
        case 'ArrowUp':
          if (this.highlightedFilteredSearchPropertyIndex === null) {
            this.highlightedFilteredSearchPropertyIndex = this.filteredSearchProperties.length - 1;
          }
          else if (this.highlightedFilteredSearchPropertyIndex === 0) {
            this.highlightedFilteredSearchPropertyIndex = this.filteredSearchProperties.length - 1;
          }
          else {
            this.highlightedFilteredSearchPropertyIndex--;
          }
          break;
        case 'Enter':
          if (this.highlightedFilteredSearchPropertyIndex === null) {
            this.selectGlobalSearchProperty();
            this.closeDropdown();
          }
          else {
            const filtered_search_property = this.filteredSearchProperties[this.highlightedFilteredSearchPropertyIndex];
            this.selectFilteredSearchProperty(filtered_search_property);
          }
          break;
        case 'Backspace':
          if (this.searchInput.length === 0 && this.selectedSearchProperties.length !== 0) {
            this.removeSelectedSearchProperty(this.selectedSearchProperties[this.selectedSearchProperties.length - 1]);

            if (this.selectedSearchProperties.length === 0) {
              this.closeDropdown();
            }
          }
          break;
      }
    }
  }

  cacheSelectedSearchProperties(): void {
    if (this.cachingEnabled && this.megaSearchId && !this.instantSearch) {
      const cacheId = this.megaSearchId + '_' + this.uiRouterGlobals.current.name;

      this.stateDataService.cacheComponentSessionData(
        'MegaSearchComponent_' + cacheId,
        'selectedSearchProperties',
        this.selectedSearchProperties
      );
      this.stateDataService.cacheComponentSessionData(
        'MegaSearchComponent_' + cacheId,
        'globalSearchValueToUnformattedSearchValueMap',
        this.globalSearchValueToUnformattedSearchValueMap
      );
    }
  }

  loadCachedSelectedSearchProperties(): void {
    if (this.cachingEnabled && this.megaSearchId && !this.instantSearch) {
      const cacheId = this.megaSearchId + '_' + this.uiRouterGlobals.current.name;

      const selectedSearchProperties = this.stateDataService.getCachedComponentSessionData(
        'MegaSearchComponent_' + cacheId,
        'selectedSearchProperties'
      );
      if (selectedSearchProperties !== null) {
        this.selectedSearchProperties = selectedSearchProperties;
      }

      const globalSearchValueToUnformattedSearchValueMap = this.stateDataService.getCachedComponentSessionData(
        'MegaSearchComponent_' + cacheId,
        'globalSearchValueToUnformattedSearchValueMap'
      );
      if (globalSearchValueToUnformattedSearchValueMap !== null) {
        this.globalSearchValueToUnformattedSearchValueMap = globalSearchValueToUnformattedSearchValueMap;
      }

      this.selectedSearchPropertiesUpdated();
    }
  }

}
