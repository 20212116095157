import { Component, OnInit, Input, SimpleChanges, HostListener, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { env } from './../../../environments/environment';
import { SubscriptionService } from '../../services/subscription/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'paystation-card-info',
  templateUrl: './paystation-card-info.component.html',
  styleUrls: ['./paystation-card-info.component.scss']
})
export class PaystationCardInfoComponent implements OnInit {

  @HostListener('window:message', ['$event'])
  onMessageEvent(event) {
    if (env.subscription.api_url.startsWith(event.origin)) {
      if (event.type === 'message' && event.data.status === 'state failed') {
        this.getPaystationCardUrl();
        this.error.emit({ error: event.data.message });
      }
    }
  }

  @Output() error = new EventEmitter<{ error: string }>();


  cardUpdateEventListener: Subscription = null;
  card_url: string;
  iframeUrl: SafeResourceUrl;
  loading: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
    this.getPaystationCardUrl();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  private updateIframeUrl(): void {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.card_url);
  }

  getPaystationCardUrl() {
    this.loading = true;
    this.subscriptionService.getCardURLPaystation()
      .then((card_url) => {
        this.card_url = card_url;
        this.updateIframeUrl();
      })
      .catch((err) => {
        this.error.emit({ error: err.message });
      })
      .finally(() => {
        this.loading = false;
      });
  }



  private _initEventListeners() {
    this.cardUpdateEventListener = this.subscriptionService.getCardUpdateEvent().subscribe((event) => {
      if (!event.success) {
        this.getPaystationCardUrl();
      }
    });
  }

  private _clearEventListeners() {
    this.cardUpdateEventListener?.unsubscribe();
  }
}
