import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ItemBulletType } from '../../app.types';


@Component({
  selector: 'item-bullet',
  templateUrl: './item-bullet.component.html',
  styleUrls: ['./item-bullet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemBulletComponent implements OnInit {

  @Input() bullet_type: ItemBulletType = 'line';
  @Input() tooltip_content: string = null;

  @HostBinding('class.-type-line') bullet_type_line = true;
  @HostBinding('class.-type-dot') bullet_type_dot = false;
  @HostBinding('class.-type-square') bullet_type_square = false;

  @HostBinding('style.background-color') @Input() bullet_colour: string;

  ngOnInit(): void {
    this.bullet_type_line = !this.bullet_type || this.bullet_type === 'line';
    this.bullet_type_dot = this.bullet_type === 'dot';
    this.bullet_type_square = this.bullet_type === 'square';
  }

}
