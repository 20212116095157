import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';

import { CoreUtilService } from './../../../services/core-util/core-util.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProductService } from 'src/app/services/product/product.service';

declare var Base64: any;

@Component({
  selector: 'app-login-external',
  templateUrl: './login-external.component.html',
  styleUrls: ['./login-external.component.scss']
})
export class LoginExternalComponent implements OnInit {

  urlParams: any = null;

  constructor(
    private stateService: StateService,
    private authService: AuthService,
    private uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit() {

    this.urlParams = CoreUtilService.parseJSON(Base64.decode(this.uiRouterGlobals.params.urlParams));

    // Subscription App
    if (this.urlParams.state) {
      this.authService.session_key = this.urlParams.session_key;

      if (this.urlParams.state === 'profile.edit') {
        this.urlParams.state = 'app.profile.edit';
      }

      const splashParams = this.urlParams.splashParams || {};

      // Temp to retain old functionality. Could be replaces by updated places that call this external login page to provide splashParams
      if (!splashParams.refreshStateName) splashParams.refreshStateName = this.urlParams.state;
      if (!splashParams.refreshStateParams) splashParams.refreshStateParams = this.urlParams.params;

      this.stateService.go('splash', splashParams);
    }
  }

}
