<div class="ph-modal-content">
  <div class="ph-modal-header">
    <span class="ion" [ngClass]="feedbackState ? 'ion-ios-checkmark-circle feedback-check':'ion-ios-close-circle'"></span>
    <div class="headerCenter">
      {{feedbackState ? 'Account Cancelled' : 'Cancel Account'}}
    </div>
    <div *ngIf="feedbackState">
      Sorry to see you go. We'd really appreciate your feedback to help us learn what we could do better.
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close" *ngIf="!feedbackState"
       (click)="close()">
    </i>
  </div>

  <!-- Cancel State -->
  <div class="ph-modal-container" *ngIf="!feedbackState">
    <h5 class="ph-modal-info warning">
      <i class="ion ion-ios-warning sm-icon"></i>
      <span>{{account.company_name}} will no longer be accessible</span>
    </h5>
    <h5> Are you sure you want to cancel <b>{{account.company_name}}</b>?</h5>
  </div>
  <!-- -->

  <!-- Feedback State -->
  <div class="ph-modal-container" *ngIf="feedbackState">
    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Reason for cancellation
      </div>
      <div class="ph-formItem-value">
        <list-drop-down [itemNameProperty]="'title'"
                        [itemProductValueProperty]="'value'"
                        [buttonTitle]="selectedReason?.title"
                        [buttonTitlePlaceholder]="'Select a Reason'"
                        [buttonColorClass]="'-color-white-outline'"
                        [buttonTypeClass]="'-type-dropdown'"
                        [buttonIconClass]="'ion-md-arrow-dropdown'"
                        [buttonIconAlignLeft]="false"
                        [buttonProductValue]="selectedReason?.value"
                        [listFillWidth]="true"
                        [sortItems]="false"
                        [items]="cancellationReasons"
                        (itemSelected)="selectReason($event.item)">
        </list-drop-down>
      </div>
    </div>

    <div class="ph-formItem" *ngIf="selectedReason?.value === 'OTHER'">
      <div class="ph-formItem-value">
        <input class="ph-formItem-input"
        type="text"
        placeholder="Other Reason"
        [(ngModel)]="otherCancellationReason">
      </div>
    </div>

    <div class="ph-formItem" *ngIf="account.product_value === 'PAYHERO' && !['EX CLIENT', 'CLOSED','RESTRUCTURE'].includes(selectedReason?.value)">
      <div class="ph-formItem-label">
        Which system are you switching to?
      </div>
      <div class="ph-formItem-value">
        <list-drop-down [itemNameProperty]="'title'"
                        [itemProductValueProperty]="'value'"
                        [buttonTitle]="selectedSystem?.title"
                        [buttonTitlePlaceholder]="'Select a System'"
                        [buttonColorClass]="'-color-white-outline'"
                        [buttonTypeClass]="'-type-dropdown'"
                        [buttonIconClass]="'ion-md-arrow-dropdown'"
                        [buttonIconAlignLeft]="false"
                        [buttonProductValue]="selectedSystem?.value"
                        [listFillWidth]="true"
                        [sortItems]="false"
                        [items]="altSystems"
                        (itemSelected)="selectSystem($event.item)">
        </list-drop-down>
      </div>
    </div>

    <div class="ph-formItem" *ngIf="selectedSystem?.value === 'OTHER'">
      <div class="ph-formItem-value">
        <input class="ph-formItem-input"
        type="text"
        placeholder="Other System"
        [(ngModel)]="otherSystem">
      </div>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Any other comments?
      </div>
      <div class="ph-formItem-value">
        <textarea class="feedback-textarea" [(ngModel)]="feedbackComment" maxlength="500" placeholder="Other comments"> </textarea>
      </div>
    </div>
  </div>
  <!-- -->

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
    *ngIf="!feedbackState"
            (click)="close()">
      Close
    </button>
    <button class="ph-modal-btnRight -color-danger"
            *ngIf="!feedbackState"
            [disabled]="loading"
            (click)="cancelAccount()">
      <span>Cancel Account</span>
    </button>

    <spinner *ngIf="loading" class="feedback-spinner"></spinner>

    <button class="ph-modal-btnRight -color-success"
            *ngIf="feedbackState"
            (click)="sendFeedback()">
      <span>Finish</span>
    </button>
  </div>
</div>
