<img class="app-splash-logo"
     src="assets/product-imgs/payhero/PayHero_Full_Registered.svg">

<p class="migration-loader-text">Upgrading FlexiTime Account to PayHero. This can take a few minutes.</p>

<spinner class="app-splash-spinner"></spinner>

<footer class="app-footer">
  <div class="app-footer-fromFlexitime">
    <img src="../../../../assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg" />
  </div>
</footer>
