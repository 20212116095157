<div class="fileUploader-container">
  <label *ngIf="!file && !file_url && !disabled && !custom_label"
         for="fileUploader-input"
         class="fileUploader-label">
    <i class="ion ion-md-cloud-upload"></i>
    <div>Click here to select a{{file_type_starts_with_vowel ? 'n' : ''}} {{file_type}}</div>
  </label>
  <label *ngIf="!file && !file_url && !disabled && !!custom_label"
         for="fileUploader-input"
         class="fileUploader-label">
    <i class="ion ion-ios-attach"></i>
    <div>{{custom_label}}</div>
  </label>

  <input id="fileUploader-input"
         type="file"
         accept="{{accept_input_types}}"
         file-reader
         [file]="file"
         (file_changed)="fileChanged($event.file)"
         (file_error)="file_error.emit({ error: $event.error })" />


  <div *ngIf="!!file_url && file_type === 'image'"
       class="fileUploader-preview">
    <div class="-fileName">
      <img class="profile-image" [src]="file_url" />
      <i class="ion ion-md-close app-remove imageDelete"
         *ngIf="!disabled"
         (click)="fileCleared()">
      </i>
    </div>
  </div>


  <div class="fileUploader-preview"
       *ngIf="!file && !file_url && disabled">
    <i class="ion ion-md-document"></i>
    <div class="-fileName">
      <div>No {{file_type}} attached</div>
    </div>
  </div>

</div>
