import { Component, OnInit, AfterViewInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { env } from '../../../../environments/environment';

@Component({
  selector: 'app-migration-loader',
  templateUrl: './migration-loader.component.html',
  styleUrls: ['./migration-loader.component.scss']
})
export class MigrationLoaderComponent implements OnInit, AfterViewInit {

  access_key: string = null;
  session_key: string = null;

  constructor(
    public uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void {

    window.document.title = 'Upgrading Account... | PayHero';

    this.access_key = this.uiRouterGlobals.params.access_key || null;
    this.session_key = this.uiRouterGlobals.params.session_key || null;
  }

  ngAfterViewInit(): void {
    window.location.href = env.subscription.api_url + 'flexitime/migrate?access_key=' + this.access_key + '&session_key=' + this.session_key;
  }

}
