<div class="userIcon-initials"
     *ngIf="!invite_pending && !display_image || img_error">
  {{initials}}
</div>

<img class="userIcon-img"
     *ngIf="!invite_pending && !!display_image && !img_error"
     [src]="display_image"
     (error)="onError()">


<i *ngIf="invite_pending"
   class="ion ion-ios-paper-plane userIcon-invitePending">
</i>
<div class="-active-container"
     *ngIf="active_flag === true">
  <div class="-active-green"></div>
</div>
