import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { BankAccountInputComponent } from 'src/app/components/bank-account-input/bank-account-input.component';
import { PostableObjectError } from 'src/app/interfaces/postable-object.inerface';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { PhEmployee, PhWorkPatternValue } from '../../models/ph-employee/ph-employee';

type PhEmployeeModalTab = ('DETAILS' | 'PAYMENT' | 'WORK' | 'TAX');


type PhWorkPattern = {
  title: string,
  description: string,
  value: PhWorkPatternValue
}

type PhHoursDays = {
  day: string,
  property: string
}

@Component({
  selector: 'app-ph-employee-modal',
  templateUrl: './ph-employee-modal.component.html',
  styleUrls: ['./ph-employee-modal.component.scss']
})
export class PhEmployeeModalComponent implements OnInit {

  @ViewChild('bankAccountInput') bankAccountInput: BankAccountInputComponent;

  selected_tab: PhEmployeeModalTab;
  tab_array: PhEmployeeModalTab[] = ['DETAILS', 'PAYMENT', 'WORK', 'TAX' ];
  selected_value: number = 0;

  @Input() employee: PhEmployee = null;

  work_patterns: PhWorkPattern[] = []
  selected_work_pattern: PhWorkPattern = null;

  errors: Record<string, string> = {};

  hours_per_day: PhHoursDays[] = [];

  // The same list exists in PayItemService in payhero & will need to stay the same
  tax_codes: string[] = [
    'CAE', 'EDW', 'M', 'ME', 'M SL', 'ME SL', 'ND', 'NSW', 'S', 'SA',
    'SB', 'SH', 'ST', 'ST SL', 'S SL', 'SA SL', 'SB SL', 'SH SL', 'STC', 'WT'
  ];

  // Required OR required to be validated if input
  // Bank account not checked in employee (uses bank account input component function)
  requiredFields: Record<string, string[]> = {
    'DETAILS': ['first_name', 'last_name', 'email', 'portal_invite'],
    'PAYMENT': ['employment_start_date', 'pay_rate', 'hourly', 'salary'],
    'WORK': ['work_pattern'],
    'TAX': ['ird_number', 'tax_code']
  }

  next_pressed: boolean = false;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.selected_tab = 'DETAILS';

    if (!this.employee) {
      this.employee = new PhEmployee();
    }

    this.initHoursPerDay();
    this._updateWorkDescriptions();
    this._initWorkPattern();
  }

  initHoursPerDay() {
    this.hours_per_day = [
      {
        day: 'M',
        property: 'hours_mon'
      },
      {
        day: 'T',
        property: 'hours_tue'
      },
      {
        day: 'W',
        property: 'hours_wed'
      },
      {
        day: 'T',
        property: 'hours_thu'
      },
      {
        day: 'F',
        property: 'hours_fri'
      },
      {
        day: 'S',
        property: 'hours_sat'
      },
      {
        day: 'S',
        property: 'hours_sun'
      }
    ]
  }

  close() {
    this.next_pressed = true;
    if (this.validateInputs()) {
      this.activeModal.close(this.employee);
    }
  }

  dismiss() {
    this.activeModal.close(null);
  }

  next() {
    this.next_pressed = true;
    if (this.validateInputs()) {
      // update work patterns with first name
      if (this.selected_tab === 'DETAILS') {
        this._updateWorkDescriptions();
      }

      this.selected_value++;
      this.selected_tab = this.tab_array[this.selected_value];
      this.next_pressed = false;
    }
  }

  back() {
    this.selected_value--;
    this.selected_tab = this.tab_array[this.selected_value];
  }

  validateInputs() {
    const errors = this.employee.getErrors();
    const res = errors.filter((e)=> this.requiredFields[this.selected_tab].includes(e.error_path));

    this.errors = {};
    for (const error of res) {
      this.errors[error.error_path] = error.error_message;
    }

    if (this.selected_tab === 'PAYMENT' && !this.bankAccountInput.validateBankAccount()) {
      return false;
    }
    return res.length === 0;
  }

  _updateWorkDescriptions() {
    if (!this.employee.first_name) return;

    this.work_patterns = [];

    this.work_patterns = [
      {
        title: 'Regular Week',
        description: `${this.employee.first_name} usually works the same hours and days each week.`,
        value: 'REGULAR_WEEK'
      },
      {
        title: 'Regular Days and Hours',
        description: `${this.employee.first_name} works fixed hours per day and days per week, but not necessarily the exact same days each week.`,
        value: 'REGULAR_DAYS_HOURS'
      },
      {
        title: 'No Regular Days',
        description: `${this.employee.first_name} has an expected total hours per week, but their days per week change each week.`,
        value: 'NO_REGULAR_DAYS'
      },
    ];

    // only add the following work patterns if hourly
    if (!this.employee.salary_flag) {
      this.work_patterns.push(
        {
          title: 'No Regular Hours',
          description: `${this.employee.first_name} works the same number of days each week, but their hours fluctuate from day to day.`,
          value: 'NO_REGULAR_HOURS'
        },

        {
          title: 'No Regular Week',
          description: `${this.employee.first_name} works completely different hours and days each week.`,
          value: 'NO_PATTERN'
        }
      );
    }

    // only select 'Regular Week' as default for NEW employees
    if (!this.employee.employee_key && !this.employee.selected_work_pattern) {
      this.selectWorkPattern(this.work_patterns[0]);
    } else {
      // reselect work pattern so that any name changes stay updated
      this.selectWorkPattern(_.find(this.work_patterns, (work_pattern) => work_pattern?.value === this.employee.selected_work_pattern));
    }
  }


  private _initWorkPattern() {
    let selected = 'REGULAR_WEEK';

    if (this.employee.has_hours_for_weekdays) {
        selected = 'REGULAR_WEEK';
    }
    else if (this.employee.has_days_per_week) {
        selected = this.employee.hours_per_day > 0 ? 'REGULAR_DAYS_HOURS' : 'NO_REGULAR_HOURS';
    }
    else if (this.employee.has_hours_per_week) {
        selected = 'NO_REGULAR_DAYS';
    } else {
      selected = 'NO_PATTERN';
    }
    this.selectWorkPattern(_.find(this.work_patterns, (work_pattern) => work_pattern?.value === selected) || this.work_patterns[0]);

  }

  selectWorkPattern(workPattern: PhWorkPattern) {
    this.selected_work_pattern = workPattern;
    this.employee.selected_work_pattern = this.selected_work_pattern?.value;

    this.hoursUpdated();
  }

  selectTaxCode(tax_code: string) {
    this.employee.tax_code = tax_code;
  }

  hoursUpdated() {
    if (this.selected_work_pattern?.value === 'REGULAR_WEEK') {
      this.employee.normal_hours_per_week = 0;
      // tally up hours across all days
      for (const day of this.hours_per_day) {
        this.employee.normal_hours_per_week += this.employee[day.property];
      }

      // count all days with hours > 0
      this.employee.days_per_week = 0;
      const non_zero_days = _.filter(this.hours_per_day, (day) => this.employee[day.property] > 0);
      this.employee.days_per_week = non_zero_days?.length ? non_zero_days.length : 0;

      // get average hours per day
      if (this.employee.days_per_week > 0) {
        this.employee.hours_per_day = this.employee.normal_hours_per_week / this.employee.days_per_week;
      } else {
        this.employee.hours_per_day = 0;
      }
    } else if (this.selected_work_pattern?.value === 'REGULAR_DAYS_HOURS') {
      this.employee.normal_hours_per_week = this.employee.days_per_week * this.employee.hours_per_day;
    }
  }


  salaryFlagChanged() {
    if (this.employee.salary_flag) {
      this.employee.hourly_rate = null;
    } else {
      this.employee.annual_salary = null;
    }

    this._updateWorkDescriptions();

    // refresh errors if errors already display (change hourly rate required to annual salary required, or vice versa)
    if (Object.keys(this.errors).length > 0) { this.validateInputs(); }
  }

}
