import { Component, OnInit, ViewChildren, QueryList, ViewChild, OnDestroy } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';

import { FTSubscription } from '../../../models/ft-subscription/ft-subscription';
import { ModalService } from '../../../services/modal/modal.service';
import { SubscriptionService } from '../../../services/subscription/subscription.service';
import { ClientService } from 'src/app/services/client/client.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { Account } from 'src/app/models/account/account';
import { Client } from 'src/app/models/client/client';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { AccountService } from './../../../services/account/account.service';
import { MegaSearchConfig, MegaSearchComponent } from './../../../components/mega-search/mega-search.component';
import { Partner } from './../../../models/partner/partner';
import { PartnerService } from './../../../services/partner/partner.service';
import { SubscriptionAccountListComponent } from './../../../components/subscription-account-list/subscription-account-list.component';
import { AccountFilter, DropdownOption } from './../../../app.types';

import { clone } from 'lodash-es';
import { Subscription } from 'rxjs';

type SubDashClientData = {
  monthly_cost: number,
  pending_trial_count: number,
  pending_ownership_transfer_count: number
};

@Component({
  selector: 'app-subscription-dash',
  templateUrl: './subscription-dash.component.html',
  styleUrls: ['./subscription-dash.component.scss']
})
export class SubscriptionDashComponent implements OnInit, OnDestroy {

  @ViewChild('megaSearch') megaSearch: MegaSearchComponent;
  @ViewChildren('accountList') accountLists: QueryList<SubscriptionAccountListComponent>;

  readonly isPartner: boolean = this.stateAccessService.isPartner;

  loading: boolean = false;

  // Searching ////////////
  megaSearchId: string = 'SubscriptionDashComponent_partner';
  megaSearchConfigMap: Record<string, MegaSearchConfig> = {
    clients: {
      search_properties: [
        { label: 'Client', key: 'client_name', paths: ['client_name'] },
        { label: 'Email', key: 'contact_email', paths: ['contact_email'] },
        { label: 'Phone', key: 'contact_phone', paths: ['contact_phone'] }
      ],
      item_key_property: 'client_key',
      filtered_keys: new Set()
    },
    accounts: {
      search_properties: [
        { label: 'Account', key: 'account_name', paths: ['company_name'] }
      ],
      item_key_property: 'company_product_key',
      filtered_keys: new Set()
    }
  };

  // Dropdown Options ////////////
  dropdownOptions: DropdownOption[] = [
    {
      option_value: 'CANCELLED',
      option_name: 'Show Cancelled Accounts',
      option_is_toggleable: true,
      option_toggle_value: this.stateDataService.getCachedComponentSessionData('SubscriptionDashComponent', 'showCancelledAccounts') || false
    }
  ];

  subscription: FTSubscription = null;
  partner: Partner = null;
  clients: Client[] = [];
  accounts: Account[] = [];
  clientAccounts: Record<number, Account[]> = {};
  unlinkedAccounts: Account[] = [];
  partnerAccounts: Account[] = [];

  visibleClients: Client[] = [];
  unlinkedAccountIsVisible: boolean = false;
  partnerAccountIsVisible: boolean = false;
  clientAccountVisibility: Record<number, boolean> = {};
  clientDataMap: Record<number, SubDashClientData> = {};

  totalCostBeforeCredit: number = 0;

  action: String = null;
  company_product_key: Number = null;

  private _event_subscriptions: Subscription[] = [];

  clientIsVisible: (client: Client) => boolean = (client) => {
    if (this.megaSearchConfigMap.clients.filtered_keys.has(client.client_key)) {
      return true;
    }
    else {
      for (const account of this.clientAccounts[client.client_key]) {
        if (this.accountIsVisible(account)) {
          return true;
        }
      }
      return false;
    }
  };

  accountIsVisible: (account: Account) => boolean = (account) => {
    return (this.dropdownOptions[0].option_toggle_value || !account.cancelled_flag) &&
      !!account.account_subscription &&
      (this.megaSearchConfigMap.accounts.filtered_keys.has(account.company_product_key) ||
        this.megaSearchConfigMap.clients.filtered_keys.has(account.client_key));
  };

  constructor(
    public subscriptionService: SubscriptionService,
    public modalService: ModalService,
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public stateAccessService: StateAccessService,
    public clientService: ClientService,
    public accountService: AccountService,
    public stateDataService: StateDataService,
    public partnerService: PartnerService
  ) { }

  ngOnInit(): void {
    this.partner = this.partnerService.getPartner(true);

    if (this.isPartner) {
      this.clients = this.clientService.getClients(true).filter((client) => {
        const client_accounts = this.accountService.getAllAccounts(
          ['EXCLUDE_NO_SUBSCRIPTION'],
          client.client_key
        );
        // Needs at least one account with an account_subscription
        for (const account of client_accounts) {
          if (!!account.account_subscription) {
            return true;
          }
        }
        return false;
      });

      this.dropdownOptions.push({
        option_value: 'EXPAND_CLIENTS',
        option_name: 'Expand All Clients',
        option_hidden: false
      });
      this.dropdownOptions.push({
        option_value: 'COLLAPSE_CLIENTS',
        option_name: 'Collapse All Clients',
        option_hidden: true
      });
    }

    this.refreshSubscription();
    this.initClientAccountVisibility();

    this.action = this.uiRouterGlobals.params.action || null;
    this.company_product_key = this.uiRouterGlobals.params.company_product_key || null;

    if (this.action === 'subscribe' && !!this.company_product_key) {
      const account = this.accounts.find(account => {
        return account.company_product_key === this.company_product_key;
      });
      if (!!account) {
        this.subscribe(account);
      }
    }

    this.initEventListeners();
  }

  ngOnDestroy(): void {
    this.clearEventListeners();
  }

  initEventListeners() {
    this._event_subscriptions.push(
      this.accountService.getServiceDataUpdateEvent().subscribe(() => {
        this.refreshSubscription();
      })
    );
  }

  clearEventListeners() {
    this._event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this._event_subscriptions = [];
  }


  megaSearchUpdated() {
    this.reloadVisibleClients();
    this.unlinkedAccountIsVisible = this._unlinkedAccountIsVisible();
    this.partnerAccountIsVisible = this._partnerAccountIsVisible();

    setTimeout(() => {
      const account_lists = this.accountLists.toArray();
      for (const account_list of account_lists) {
        account_list.reloadVisibleAccounts();
      }
      this.toggleClientAccountVisibilityOnSearch();
    });
  }

  reloadVisibleClients() {
    const visible_clients: Client[] = [];
    for (const client of this.clients) {
      if (this.clientIsVisible(client)) {
        visible_clients.push(client);
      }
    }
    this.visibleClients = visible_clients;
  }

  clientHasVisibleAccount(client_key: number): boolean {
    for (const account of this.clientAccounts[client_key]) {
      if (this.accountIsVisible(account)) {
        return true;
      }
    }
    return false;
  }

  initClientAccountVisibility() {
    if (this.isPartner) {
      this.clientAccountVisibility = this.stateDataService.getCachedComponentSessionData(
        'SubscriptionDashComponent',
        'clientAccountVisibility'
      );

      if (this.clientAccountVisibility === null) {
        const cav = {};
        for (const client of this.clients) {
          cav[client.client_key] = false;
        }
        this.clientAccountVisibility = cav;
      }

      for (const option of this.dropdownOptions) {
        if (option.option_value === 'EXPAND') {
          option.option_toggle_value = Object.keys(this.clientAccountVisibility).length === this.clients.length;
        }
      }
      this._updateExpandCollapseDropdownVisibility();
    }
  }

  refreshSubscription() {
    this.subscription = this.subscriptionService.getSubscription();

    this._initAccounts();
  }

  private _initAccounts() {
    const account_filters: AccountFilter[] = ['EXCLUDE_NO_SUBSCRIPTION'];
    if (!this.dropdownOptions[0].option_toggle_value) {
      account_filters.push('EXCLUDE_CANCELLED');
    }

    this.accounts = this.accountService.getAllAccounts(account_filters, null, null, null, true);
    for (const account of this.accounts) {
      if (account.subscribed_flag && !account.cancelled_flag) {
        this.totalCostBeforeCredit += (account.account_subscription?.monthly_cost || 0);
      }
    }

    if (this.isPartner) {
      this.clientAccounts = this._getClientAccounts(account_filters);
      this.unlinkedAccounts = this.accountService.getAllAccounts(
        account_filters.concat(['UNLINKED_ONLY', 'EXCLUDE_PARTNER_ACCOUNTS']), null, null, null, true
      );
      this.partnerAccounts = this.partnerService.getAllPartnerAccounts(
        ['EXCLUDE_CANCELLED', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS'],
        null, true
      );
      this.refreshClientDataMap();
    }
  }

  private _getClientAccounts(account_filters: AccountFilter[]): Record<number, Account[]> {
    const client_accounts: Record<number, Account[]> = {};
    for (const client of this.clients) {
      client_accounts[client.client_key] = this.accountService.getAllAccounts(
        account_filters,
        client.client_key,
        null, null, true
      );
    }
    return client_accounts;
  }

  refreshClientDataMap() {
    const client_data_map: Record<number, SubDashClientData> = {};
    for (const client of this.clients) {
      const client_data: SubDashClientData = {
        monthly_cost: 0,
        pending_trial_count: 0,
        pending_ownership_transfer_count: 0
      };

      for (const account of this.clientAccounts[client.client_key]) {
        if (account.account_subscription) {
          client_data.monthly_cost += account.account_subscription.monthly_cost;

          if (account.account_status === 'ON_TRIAL' || account.account_status === 'TRIAL_EXPIRED') {
            client_data.pending_trial_count++;
          }
          if (account.ownership_transfer_status !== null) {
            client_data.pending_ownership_transfer_count++;
          }
        }
      }

      client_data_map[client.client_key] = client_data;
    }
    this.clientDataMap = client_data_map;
  }

  dropdownOptionSelected(option: string) {
    switch (option) {
      case 'CANCELLED':
        this.toggleCancelledAccounts();
        break;
      case 'EXPAND_CLIENTS':
        this.expandAllClientAccountVisibility();
        break;
      case 'COLLAPSE_CLIENTS':
        this.collapseAllClientAccountVisibility();
        break;
    }
  }

  toggleCancelledAccounts() {
    this.dropdownOptions[0].option_toggle_value = !this.dropdownOptions[0].option_toggle_value;
    this.stateDataService.cacheComponentSessionData(
      'SubscriptionDashComponent',
      'showCancelledAccounts',
      this.dropdownOptions[0].option_toggle_value
    );

    this.refreshSubscription();
  }

  toggleClientAccountVisibility(client: Client) {
    if (this.clientAccounts[client.client_key]?.length) {
      this.clientAccountVisibility[client.client_key] = !this.clientAccountVisibility[client.client_key];
      this.stateDataService.cacheComponentSessionData(
        'SubscriptionDashComponent',
        'clientAccountVisibility',
        this.clientAccountVisibility
      );
      this._updateExpandCollapseDropdownVisibility();
    }
  }

  expandAllClientAccountVisibility() {
    for (const client of this.clients) {
      if (this.clientHasVisibleAccount(client.client_key)){
        this.clientAccountVisibility[client.client_key] = true;
      }
    }
    this.stateDataService.cacheComponentSessionData(
      'SubscriptionDashComponent',
      'clientAccountVisibility',
      this.clientAccountVisibility
    );
    this._updateExpandCollapseDropdownVisibility();
  }

  collapseAllClientAccountVisibility() {
    for (const client of this.clients) {
      this.clientAccountVisibility[client.client_key] = false;
    }
    this.stateDataService.cacheComponentSessionData(
      'SubscriptionDashComponent',
      'clientAccountVisibility',
      this.clientAccountVisibility
    );
    this._updateExpandCollapseDropdownVisibility();
  }

  toggleClientAccountVisibilityOnSearch() {
    if (this.megaSearch.filterActive) {
      for (const client of this.visibleClients) {
        this.clientAccountVisibility[client.client_key] = true;
      }
    }
    this.stateDataService.cacheComponentSessionData(
      'SubscriptionDashComponent',
      'clientAccountVisibility',
      this.clientAccountVisibility
    );
    this._updateExpandCollapseDropdownVisibility();
  }

  subscribe(account: Account) {
    this.modalService.subscribeModal(account)
      .finally(() => {
        this.refreshSubscription();
      });
  }

  cancelAccount(account: Account) {
    this.modalService.cancellationModal(
      account,
      this.isPartner
    )
    .then((feedback) => {
      if (feedback) this.subscriptionService.postCancellationFeedback(feedback); // no need to wait
    })
    .catch(() => { })
    .finally(() => {
      this.refreshSubscription();
    });
  }

  transferOwnership(account: Account) {
    this.modalService.transferOwnershipModal(account)
      .then((result) => {
        this.loading = true;

        this.accountService.transferAccountSubscription(
          result.account,
          result.transfer_contact_name,
          result.transfer_email_address
        )
          .finally(() => {
            this.refreshSubscription();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  declineOwnershipTransfer(account: Account) {
    this.modalService.confirmModal('Custom', null, null, null, null, {
      icon: null,
      title: 'Decline Account Subscription Transfer',
      question: '',
      warning: '',
      buttonTitle: 'Decline',
      buttonConfirmClass: '-color-danger'
    })
      .then((result) => {
        if (result === true) {
          this.loading = true;

          this.accountService.revokeAccountSubscriptionTransfer(
            account
          )
            .finally(() => {
              this.refreshSubscription();
              this.loading = false;
            });
        }
      })
      .catch(() => { });
  }

  revokeOwnershipTransfer(account: Account) {
    this.modalService.confirmModal('Custom', null, null, null, null, {
      icon: null,
      title: 'Revoke Account Subscription Transfer',
      question: '',
      warning: '',
      buttonTitle: 'Revoke',
      buttonConfirmClass: '-color-danger'
    })
      .then((result) => {
        if (result === true) {
          this.loading = true;

          this.accountService.revokeAccountSubscriptionTransfer(
            account
          )
            .finally(() => {
              this.refreshSubscription();
              this.loading = false;
            });
        }
      })
      .catch(() => { });
  }

  goToSubscriptionAccount(account: Account) {
    this.stateService.go('app.subscription.account', {
      company_product_key: account.company_product_key
    });
  }

  private _updateExpandCollapseDropdownVisibility() {
    if (this.isPartner) {
      if (this._allClientAccountsVisible()) {
        this.dropdownOptions[1].option_hidden = true;
        this.dropdownOptions[2].option_hidden = false;
      }
      else {
        this.dropdownOptions[1].option_hidden = false;
        this.dropdownOptions[2].option_hidden = true;
      }
      this.dropdownOptions = clone(this.dropdownOptions);
    }
  }

  private _allClientAccountsVisible(): boolean {
    for (const client_key of Object.keys(this.clientAccountVisibility)) {
      if (this.clientHasVisibleAccount(parseInt(client_key))){
        if (this.clientAccountVisibility[client_key] !== true) {
          return false;
        }
      }
    }
    return true;
  }

  private _unlinkedAccountIsVisible() {
    for (const account of this.unlinkedAccounts) {
      if (this.accountIsVisible(account)) {
        return true;
      }
    }
    return false;
  }

  private _partnerAccountIsVisible() {
    for (const account of this.partnerAccounts) {
      if (this.accountIsVisible(account)) {
        return true;
      }
    }
    return false;
  }

}
