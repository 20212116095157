import { Component, Input } from '@angular/core';

@Component({
  selector: 'required-field-icon',
  templateUrl: './required-field-icon.component.html',
  styleUrls: ['./required-field-icon.component.scss']
})
export class RequiredFieldIconComponent {

  @Input() fieldName: string = null;

}
