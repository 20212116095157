import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import * as validator from 'card-validator';

import { FTSubscription } from '../../models/ft-subscription/ft-subscription';
import { SubscriptionCard } from '../../models/subscription-card/subscription-card';
import { SubscriptionInvoice } from '../../models/subscription-invoice/subscription-invoice';
import { SubscriptionPlan } from './../../models/subscription-plan/subscription-plan';
import { Account } from './../../models/account/account';
import { AccountSubscription } from './../../models/account-subscription/account-subscription';
import { AccountSubscriptionLine } from './../../models/account-subscription-line/account-subscription-line';
import { PromoCode } from './../../models/promo-code/promo-code';
import { UserService } from './../user/user.service';
import { SubscriptionLine } from './../../models/subscription-line/subscription-line';
import { DbUtilService } from '../db-util/db-util.service';
import { SocketService } from '../socket/socket.service';
import { Country } from './../../app.types';
import { CardDetails } from '../../models/card-details/card-details';
import { find, cloneDeep, filter } from 'lodash-es';

import { DomService } from '../dom/dom.service';
import { ModalService } from '../modal/modal.service';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  subscription: FTSubscription = null;
  subscriptionPlanMap: Record<number, SubscriptionPlan> = {};
  accountSubscriptionMap: Record<number, AccountSubscription> = {};

  private _serviceDataUpdateEvent = new Subject<void>();
  private _cardUpdateEvent = new Subject<any>();

  serviceSetupLoading: boolean = false;
  serviceSetup: boolean = false;

  constructor(
    public dbUtilService: DbUtilService,
    public socketService: SocketService,
    public userService: UserService,
    public datePipe: DatePipe,
    public domService: DomService,
    public modalService: ModalService
  ) { }

  getCardUpdateEvent(): Observable<any> {
    return this._cardUpdateEvent.asObservable();
  }

  // Initialisation ///////////////

  initialiseService(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      if (this.serviceSetup || this.serviceSetupLoading) {
        resolve();
      }
      else {
        this.serviceSetupLoading = true;
        this._initSocketEvents();
        this.loadSubscription()
          .then(() => {
            this.serviceSetup = true;
            this.serviceSetupLoading = false;
            this._serviceDataUpdateEvent.next();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.subscription = null;
    this.subscriptionPlanMap = {};
    this.accountSubscriptionMap = {};
    this.serviceSetup = false;
  }

  private _initSocketEvents() {
    this.socketService.subToEvent('card_event', 'SubscriptionService', (event) => {
      this._cardUpdateEvent.next(event);
    });
  }

  // Getters ///////////////

  static getCreditCardType(credit_card_number: string): string {
    const types = (validator.creditCardType as any).default(credit_card_number);
    // Only return value if we've found exactly one matching type
    if (types.length === 1) {
      return types[0].type;
    }
    return null;
  }

  get currency_code(): string {
    return this.subscription?.country?.billing_currency || 'NZD';
  }

  get service_set_up(): boolean {
    return this.serviceSetup;
  }

  getServiceDataUpdateEvent(): Observable<any> {
    return this._serviceDataUpdateEvent.asObservable();
  }

  getSubscription(get_reference: boolean = false): FTSubscription {
    return get_reference ? this.subscription : cloneDeep(this.subscription);
  }

  getAccountSubscriptionForAccount(company_product_key: number, get_reference: boolean = false): AccountSubscription {
    if (!!this.accountSubscriptionMap) {
      const account_subscription = this.accountSubscriptionMap[company_product_key] || null;
      return get_reference ? account_subscription : cloneDeep(account_subscription);
    }
    return null;
  }

  getSubscriptionPlan(subscription_plan_key: number, get_reference: boolean = false): SubscriptionPlan {
    if (!!this.accountSubscriptionMap) {
      const subscription_plan = this.subscriptionPlanMap[subscription_plan_key] || null;
      return get_reference ? subscription_plan : cloneDeep(subscription_plan);
    }
    return;
  }

  checkSuspended(): SubscriptionInvoice[] {
    if (!!this.subscription && !!this.subscription.suspension_date && !!this.subscription.payments?.length) {
      const overdue_invoices = filter(this.subscription.payments, (payment) => !!payment.suspension_date);
      if (!!overdue_invoices.length) {
        return overdue_invoices;
      }
    }

    return null;
  }


  // Network Calls ///////////////

  loadSubscription() {
    return new Promise<void>((resolve, reject) => {

      if (this.userService.has_subscription) {
        this.dbUtilService.APIGet('subscription', null, true)
          .then((data) => {
            const subscription_data = this.setupSubscription(data);

            this.subscription = subscription_data.subscription;
            this.subscriptionPlanMap = subscription_data.subscription_plan_map;
            this.accountSubscriptionMap = subscription_data.account_subscription_map;

            this._serviceDataUpdateEvent.next();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
      else {
        resolve();
      }
    });
  }

  saveBillingInformation(subscription: FTSubscription) {
    return new Promise<any>((resolve, reject) => {

      const data = subscription.formatForPosting();

      this.dbUtilService.APIPost('subscription/billing', data)
        .then((res) => {
          this.loadSubscription()
            .finally(() => {
              resolve(res);
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  getAvailableSubscriptionPlans(account: Account) {
    return new Promise<any>((resolve, reject) => {

      const params = {
        company_product_key: account.company_product_key,
      };

      this.dbUtilService.APIGet('subscription/plans', params, true)
        .then((data) => {
          resolve(this.setupSubscriptionPlans(data));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postCancellationFeedback(feedback: any) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIPost('feedback/cancellation', feedback, true)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }


  getCardURLPaystation() {
    return new Promise<any>((resolve, reject) => {

      const params = {
        subscription_key: this.subscription.subscription_key,
        billing_currency: this.subscription.country.billing_currency
      };

      this.dbUtilService.APIGet('subscription/card/add/url/paystation', params, true)
        .then((data) => {
          resolve(data.url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  authoriseDirectDebit(subscription: FTSubscription) {
    return new Promise<any>((resolve, reject) => {

      const data = subscription.formatForPosting();

      this.dbUtilService.APIPost('subscription/directdebit', data)
        .then((res) => {
          this.loadSubscription()
            .finally(() => {
              resolve(res);
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  paySuspendedInvoicesModal() {
    return new Promise<boolean>((resolve, reject) => {
      const suspended_invoices: SubscriptionInvoice[] = this.checkSuspended();
      if (!!suspended_invoices && !!suspended_invoices.length) {
        this.modalService.payInvoiceModal(
          suspended_invoices,
          this.subscription.subscription_card,
          this.subscription.payment_method,
          this.subscription.billing_email
        )
          .then(() => {
            this.paySuspendedInvoices()
              .then(() => {
                this.modalService.successModal(
                  'Payment Received',
                  null,
                  'Thanks for paying your invoices. <br> You can now access any previously suspended accounts.'
                );
                resolve(true);
              });
          })
          .catch(() => { reject(); });
      } else { resolve(false); }
    });
  }

  paySuspendedInvoices() {
    return new Promise<void>((resolve, reject) => {

      this.dbUtilService.APIPost('subscription/unsuspend', {})
        .then(() => {
          this.loadSubscription()
            .then(() => resolve())
            .catch(() => reject());
        })
        .catch(() => reject());
    });
  }

  /**
   * Downloads a pdf of an invoice
   */
  downloadPDFSchedule(subscription_invoice: SubscriptionInvoice): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const params: any = {
        subscription_invoice_key: subscription_invoice.subscription_invoice_key
      };

      this.dbUtilService.APIGet('subscription/invoice/pdf', params, false, 'arraybuffer')
        .then((pdf) => {
          const file = new Blob([pdf], { type: 'application/pdf' });

          const fileName = 'Invoice - ' + this.datePipe.transform(subscription_invoice.invoice_date, 'y-M-d').toUpperCase();

          saveAs(file, fileName + '.pdf');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setupSubscription(s: any): {
    subscription: FTSubscription,
    account_subscription_map: Record<number, AccountSubscription>,
    subscription_plan_map: Record<number, SubscriptionPlan>
  } {
    const subscription_plans = this.setupSubscriptionPlans(s.subscription_plans);

    const subscription_plan_map = {};
    for (const sub_plan of subscription_plans) {
      subscription_plan_map[sub_plan.subscription_plan_key] = sub_plan;
    }

    const account_subscriptions = this.setupAccountSubscriptions(s.companies, subscription_plan_map);

    const account_subscription_map = {};
    for (const comp_sub of account_subscriptions) {
      account_subscription_map[comp_sub.company_product_key] = comp_sub;
    }

    const country: Country = {
      country_key: s.country_key,
      country_name: s.country_name,
      billing_currency: s.billing_currency,
      money_symbol: null,
      timezone_name: null,
      calling_code: null
    };

    const subscription = new FTSubscription(
      s.bank_account_name,
      s.bank_account_number,
      s.bank_code,
      s.bank_name,
      s.billing_email,
      s.billing_name,
      s.billing_address,
      s.cancelled_date,
      account_subscriptions,
      subscription_plans,
      country,
      s.discount_description,
      s.discount_percentage,
      s.discount_until_date,
      s.first_invoice_date,
      s.next_invoice_date,
      s.last_invoice_date,
      s.last_payment_date,
      s.money_symbol,
      s.billing_tax_type || 'No Tax',
      s.monthly_credit,
      s.credit_description,
      s.credit_amount,
      s.monthly_discount,
      s.monthly_net,
      s.monthly_tax,
      s.has_no_payment_method,
      s.payment_method,
      this.setupSubscriptionInvoices(s.payments),
      this.setupSubscriptionCard(s.subscription_card),
      s.subscription_date,
      s.subscription_key,
      s.suspension_date,
      s.total_monthly_cost
    );

    return {
      subscription,
      account_subscription_map,
      subscription_plan_map
    };
  }

  setupSubscriptionPlans(sub_plan_data: any[]): SubscriptionPlan[] {
    const sub_plans = [];

    for (const sp of sub_plan_data) {
      sub_plans.push(new SubscriptionPlan(
        sp.subscription_plan_key,
        sp.plan_display_name,
        sp.base_plan_flag,
        sp.billing_period,
        sp.first_payment_date,
        sp.included_units,
        sp.minimum_billing_fee,
        sp.months_free,
        sp.period_fee,
        sp.plan_type,
        sp.unit_description,
        sp.unit_description_plural,
        sp.unit_fee,
        sp.unit_limit,
        this.setupPromoCodes(sp.promo_codes || [])
      ));
    }

    return sub_plans;
  }

  setupAccountSubscriptions(
    account_sub_data: any[], sub_plan_map: Record<number, SubscriptionPlan>
  ): AccountSubscription[] {
    const account_subs = [];

    for (const cs of account_sub_data) {
      account_subs.push(new AccountSubscription(
        cs.company_product_key,
        cs.show_subscribe_button,
        cs.can_change_plan,
        cs.monthly_cost,
        cs.unit_count,
        cs.subscription_charges,
        this.setupAccountSubscriptionLines(cs.invoice_lines, sub_plan_map)
      ));
    }

    return account_subs;
  }

  setupAccountSubscriptionLines(
    account_sub_line_data: any[], sub_plan_map: Record<number, SubscriptionPlan>
  ): AccountSubscriptionLine[] {
    const account_sub_lines = [];

    for (const l of account_sub_line_data) {
      account_sub_lines.push(new AccountSubscriptionLine(
        sub_plan_map[l.subscription_plan_key] || null,
        l.line_description,
        l.discount_percentage,
        l.line_total,
        l.line_type,
        l.quantity,
        l.unit_fee,
        l.unit_description,
        l.unit_description_plural
      ));
    }

    return account_sub_lines;
  }

  setupSubscriptionInvoices(subInvoiceData: any[]): SubscriptionInvoice[] {
    const payments = [];

    for (const i of subInvoiceData) {
      payments.push(new SubscriptionInvoice(
        i.due_date,
        i.invoice_date,
        i.suspension_date,
        i.payment_date,
        i.invoice_units,
        i.invoice_gross_total,
        i.payment_amount,
        i.subscription_invoice_key,
        i.subscription_key
      ));
    }

    return payments;
  }

  setupSubscriptionCard(sc: any): SubscriptionCard {
    if (!sc) {
      return null;
    }

    return new SubscriptionCard(
      sc.subscription_card_key,
      sc.subscription_key,
      sc.card_display,
      sc.expiry_date,
      sc.card_name,
      sc.card_type
    );
  }

  setupPromoCodes(pc: any): PromoCode[] {
    const promo_codes = [];

    for (const c of pc) {
      promo_codes.push(new PromoCode(
        c.promo_code,
        c.description,
        c.credit_amount,
        c.discount_months,
        c.expired_flag
      ));
    }

    return promo_codes;
  }

}
