import { Component, Input, HostListener } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from 'src/app/classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [MakeProvider(RadioButtonComponent)]
})
export class RadioButtonComponent extends AbstractValueAccessor {
  _value: boolean;

  get value(): any {
    if (this.invertModel) {
      return !this._value;
    }
    else {
      return this._value;
    }
  }
  set value(v: any) {
    if (this.invertModel) {
      super.value = !v;
    }
    else {
      super.value = v;
    }
  }

  @Input() invertModel: boolean = false;
  @Input() label: string = null;
  // @Input() labelLeft: boolean = false;

  @HostListener('click', ['$event'])
  handleClick() {
    this.value = !this.value;
  }

}
