<div class="ph-loadingIcon"
     *ngIf="loading">
  <img src="assets/imgs/App_Loader.gif">
</div>


<div class="ph-leave-header"
     *ngIf="!loading">
  <img src="{{expiredState ? 'assets/product-imgs/payhero/PayHero_Full_Registered.svg' : leaveDetails.company_logo}}">

  <div *ngIf="approved"
       class="ph-leave-status-badge -approved">
    Approved
  </div>
  <div *ngIf="declined"
       class="ph-leave-status-badge -declined">
    Declined
  </div>

  <h2 *ngIf="!expiredState">Leave Request</h2>
</div>


<div class="ph-leave-card"
     *ngIf="!loading && !expiredState">

  <div class="ph-loadingIcon"
       *ngIf="approving || declining">
    <img src="assets/imgs/App_Loader.gif">
  </div>
  <div class="ph-leave-details"
       [ngStyle]="{'opacity': (approving || declining) ? '0.3':''}">

    <div class="ph-leave-details-grid">
      <div>
        <p class="ph-text-grey">Employee:</p>
        <h2>{{leaveDetails.employee_code}}</h2>
      </div>

      <div>
        <p class="ph-text-grey">Leave Type:</p>
        <div class="leaveCatColor"
             [ngStyle]="{'background-color': leaveDetails.leave_type_colour}">
        </div>
        <h2>{{leaveDetails.leave_type}}</h2>
        <p class="ph-text-grey -lineThrough"
           *ngIf="approved_data_changes?.leave_type">
          {{leaveDetails.approved_data.leave_type}}
        </p>
      </div>

      <div>
        <p class="ph-text-grey"
           *ngIf="!leaveDetails.single_day">
          Start Date:
        </p>
        <p class="ph-text-grey"
           *ngIf="leaveDetails.single_day">
          Date:
        </p>

        <h2>
          <span class="weekday">
            {{leaveDetails.start_date | date: 'EEE, '}}
          </span>
          {{leaveDetails.start_date | date: 'd MMM'}}
          <span class="ph-text-grey">
            {{leaveDetails.start_date | date: 'yyyy'}}
          </span>
        </h2>

        <p class="ph-text-grey -lineThrough"
           *ngIf="approved_data_changes?.start_date">
          {{leaveDetails.approved_data.start_date | date : 'EEE, d MMM, yyyy'}}
        </p>
      </div>

      <div *ngIf="!leaveDetails.single_day">
        <p class="ph-text-grey">End Date:</p>

        <h2>
          <span class="weekday">
            {{leaveDetails.end_date | date: 'EEE, '}}
          </span>
          {{leaveDetails.end_date | date: 'd MMM'}}
          <span class="ph-text-grey">
            {{leaveDetails.end_date | date: 'yyyy'}}
          </span>
        </h2>

        <ng-container *ngTemplateOutlet="approved_data_end_date">
        </ng-container>
      </div>

      <div *ngIf="leaveDetails.single_day">
        <p class="ph-text-grey">Part Day:</p>
        <h2>
          {{leaveDetails.part_day}}
        </h2>
        <ng-container *ngTemplateOutlet="approved_data_end_date">
        </ng-container>
      </div>
    </div>

    <div *ngIf="leaveDetails.notes"
         class="ph-leave-notes">
      <p class="ph-text-grey">Notes:</p>
      <div>{{leaveDetails.notes}}</div>
    </div>

    <div class="ph-leave-info"
         [ngClass]="{'-hide-border': showOverlappingDetails}">
      <p class="ph-text-grey">Details:</p>

      <div *ngIf="leaveDetails.info.days_requested">
        Requested <b>{{leaveDetails.info.days_requested.toFixed(2)}}</b>
        {{leaveDetails.info.days_requested === 1 ? 'day' : 'days'}}
      </div>

      <div *ngIf="leaveDetails.info.days_due">
        <b>{{leaveDetails.info.days_due.toFixed(2)}}</b>
        {{leaveDetails.info.days_due === 1 ? 'day' : 'days'}}
        {{leaveDetails.pay_code === "SICK" ? 'available' : 'due'}}
      </div>

      <div *ngIf="leaveDetails.info.days_on_request_start && !leaveDetails.info.holiday_pay_as_you_go_flag">
        <b>{{leaveDetails.info.days_on_request_start.toFixed(2)}}</b>
        {{leaveDetails.info.days_on_request_start === 1 ? 'day' : 'days'}} estimated at
        {{leaveDetails.info.estimated_to_date | date: 'd MMM,'}}
        <span class="ph-text-grey">{{leaveDetails.info.estimated_to_date | date: 'yyyy'}}</span>
      </div>

      <div *ngIf="leaveDetails.info.other_days_requested">
        <b>{{leaveDetails.info.other_days_requested.toFixed(2)}}</b>
        other {{leaveDetails.info.other_days_requested === 1 ? 'day' : 'days'}} requested
      </div>

      <div *ngIf="leaveDetails.overlapping_requests.length && leaveDetails.pay_code !== 'PARENTAL'">
        <b>{{leaveDetails.overlapping_requests.length}}</b>
        overlapping leave {{leaveDetails.overlapping_requests.length === 1 ? 'request' : 'requests'}}
        <a (click)="showOverlappingDetails = !showOverlappingDetails">
          {{!showOverlappingDetails ? 'Show' : 'Hide'}}
        </a>
      </div>

      <div *ngIf="showOverlappingDetails"
           class="ph-leave-overlapping-requests">
        <div class="ph-leave-overlapping-request"
             *ngFor="let overlapped of leaveDetails.overlapping_requests">
          <div>
            <p>{{overlapped.employee_code}}</p>
            <span *ngIf="overlapped.is_approved"
                  class="ph-leave-overlapping-request-status -approved">
              Approved
            </span>
            <span *ngIf="!overlapped.is_approved"
                  class="ph-leave-overlapping-request-status -pending">
              Pending
            </span>
          </div>
          <div>
            {{overlapped.start_date | date: 'd MMM'}}
            -
            {{overlapped.end_date | date: 'd MMM,'}}
            <span class="ph-text-grey">{{overlapped.end_date | date: 'yyyy'}}</span>

          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="ph-leave-response-options">
    <p *ngIf="leaveLocked && approved"
       class="ph-leave-locked-reason -approved">
      <i class="ion ion-md-checkmark"></i>
      This leave request was <span class="status">approved</span>
      on {{leaveDetails.approval_date | date: 'dd/MM/yy'}}
      <span *ngIf="leaveDetails.approval_user_name">
        by {{leaveDetails.approval_user_name}}
      </span>
    </p>

    <p *ngIf="leaveLocked && declined"
       class="ph-leave-locked-reason -decliend">
      <i class="ion ion-md-close"></i>
      This leave request was <span class="status">declined</span>
      on {{leaveDetails.declined_date | date: 'dd/MM/yy'}}
      <span *ngIf="leaveDetails.approval_user_name">
        by {{leaveDetails.approval_user_name}}
      </span>
    </p>


    <button class="-color-success"
            *ngIf="!leaveLocked && !(approved || declined)"
            [disabled]="approving || declining"
            (click)="approve()">
      {{approving ? "Approving..." : "Approve"}}
    </button>
    <button class="-color-danger"
            *ngIf="!leaveLocked && !(approved || declined)"
            [disabled]="approving || declining"
            (click)="decline()">
      {{declining ? "Declining..." : "Decline"}}
    </button>
  </div>
</div>


<!-- The token is no longer valid -->
<div class="ph-leave-card ph-leave-expired-state"
     *ngIf="expiredState">
  <i class="ion ion-md-alert"></i>
  <h3>This link is no longer valid</h3>
  <p><a href="https://login.payhero.co.nz/#/login?destination=PAYHERO_APP">Sign In to PayHero</a></p>
</div>


<footer class="ph-footer ph-leave-footer">
  <img src="assets/product-imgs/payhero/PayHero_Full_Registered.svg">
</footer>

<ng-template #approved_data_end_date>
  <p class="ph-text-grey -lineThrough"
     *ngIf="approved_data_changes?.end_date">
    {{leaveDetails.approved_data.end_date | date : 'EEE, d MMM, yyyy'}}
  </p>
  <p class="ph-text-grey -lineThrough"
     *ngIf="!approved_data_changes?.end_date && approved_data_changes?.part_day">
     {{leaveDetails.approved_data.part_day}}
  </p>
</ng-template>
