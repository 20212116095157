import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';

import { SubscriptionService } from './../../../services/subscription/subscription.service';
import { ModalService } from './../../../services/modal/modal.service';
import { FTSubscription } from './../../../models/ft-subscription/ft-subscription';
import { Account } from 'src/app/models/account/account';
import { AccountService } from './../../../services/account/account.service';
import { AccountSubscriptionLine } from './../../../models/account-subscription-line/account-subscription-line';
import { SubscriptionPlan } from './../../../models/subscription-plan/subscription-plan';
import { DropdownOption } from '../../../app.types';
import { StateAccessService } from 'src/app/services/state-access/state-access.service';

type AccountPlanLines = {
  plan: SubscriptionPlan,
  lines: AccountSubscriptionLine[],
  plan_total: number
};

@Component({
  selector: 'app-subscription-account',
  templateUrl: './subscription-account.component.html',
  styleUrls: ['./subscription-account.component.scss']
})
export class SubscriptionAccountComponent implements OnInit {

  loading: boolean = false;

  subscription: FTSubscription;
  account: Account;

  account_has_discount_flag: boolean = false;

  readonly isPartner: boolean = this.stateAccessService.isPartner;

  account_plan_lines: AccountPlanLines[] = [];
  independant_lines: AccountSubscriptionLine[] = [];

  dropdown_options: DropdownOption[][] = [];

  constructor(
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public subscriptionService: SubscriptionService,
    public stateAccessService: StateAccessService,
    public modalService: ModalService,
    public accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.reloadSubscriptionAndAccount();
    this.reloadLineGroups();

    if (!this.account) {
      this.backToSubscriptionDash();
    }

    if (this.account_plan_lines.some(account_plan => account_plan.lines.some(line => line.discount_percentage))) {
      this.account_has_discount_flag = true;
    }

    this.initDropdownOptions();
  }

  reloadLineGroups() {
    const account_plan_line_map: Record<number, AccountPlanLines> = {};
    const independant_lines: AccountSubscriptionLine[] = [];

    for (const line of this.account.account_subscription?.account_subscription_lines) {
      if (!line.subscription_plan) {
        independant_lines.push(line);
      }
      else {
        if (!account_plan_line_map[line.subscription_plan.subscription_plan_key]) {
          account_plan_line_map[line.subscription_plan.subscription_plan_key] = {
            plan: line.subscription_plan,
            lines: [],
            plan_total: 0
          };
        }
        account_plan_line_map[line.subscription_plan.subscription_plan_key].lines.push(line);
        account_plan_line_map[line.subscription_plan.subscription_plan_key].plan_total += line.line_total;
      }
    }

    const account_plan_lines = [];
    for (const subscription_plan_key of Object.keys(account_plan_line_map)) {
      account_plan_lines.push(account_plan_line_map[subscription_plan_key]);
    }

    this.account_plan_lines = account_plan_lines;
    this.independant_lines = independant_lines;
  }

  reloadSubscriptionAndAccount() {
    this.subscription = this.subscriptionService.getSubscription(true);

    this.account = this.accountService.getAccount(
      this.uiRouterGlobals.params.company_product_key, true
    );
  }

  initDropdownOptions() {
    const options: DropdownOption[][] = [[], []];

    if (this.account.account_actions.has('TRANSFER_RESPOND')) {
      options[0].push({
        option_value: 'TRANSFER_RESPOND',
        option_name: 'Respond',
        option_color: '#FFAD3D'
      });
      options[1].push({
        option_value: 'TRANSFER_DECLINE',
        option_name: 'Decline Subscription Transfer',
        option_color: '#d9534f'
      });
    }
    if (this.account.account_actions.has('CHANGE_PLAN')) {
      options[0].push({
        option_value: 'CHANGE_PLAN',
        option_name: 'Change Plan',
        option_color: '#00adef'
      });
    }
    if (this.account.account_actions.has('TRANSFER_REVOKE')) {
      options[0].push({
        option_value: 'TRANSFER_REVOKE',
        option_name: 'Revoke Subscription Transfer',
        option_color: '#d9534f'
      });
    }
    if (this.account.account_actions.has('TRANSFER_START')) {
      options[0].push({
        option_value: 'TRANSFER_START',
        option_name: 'Transfer Subscription'
      });
    }
    if (this.account.account_actions.has('CANCEL_ACCOUNT')) {
      options[1].push({
        option_value: 'CANCEL_ACCOUNT',
        option_name: 'Cancel Account',
        option_color: '#d9534f'
      });
    }

    this.dropdown_options = options;
  }

  dropdownOptionSelected(option: DropdownOption) {
    switch (option.option_value) {
      case 'CHANGE_PLAN':
        this.changePlan();
        break;
      case 'TRANSFER_RESPOND':
        this.revokeOwnershipTransfer();
        break;
      case 'CANCEL_ACCOUNT':
        this.cancelAccount();
        break;
      case 'TRANSFER_START':
        this.transferOwnership();
        break;
      case 'TRANSFER_DECLINE':
        this.declineOwnershipTransfer();
        break;
    }
  }

  cancelAccount() {
    this.modalService.cancellationModal(
      this.account,
      this.isPartner
    )
    .then((feedback) => {
      if (feedback) this.subscriptionService.postCancellationFeedback(feedback); // no need to wait
      this.backToSubscriptionDash();
    })
    .catch(() => { });
  }

  transferOwnership() {
    this.modalService.transferOwnershipModal(this.account)
      .then((result) => {
        this.loading = true;

        this.accountService.transferAccountSubscription(
          result.account,
          result.transfer_contact_name,
          result.transfer_email_address
        )
          .finally(() => {
            this.reloadSubscriptionAndAccount();
            this.reloadLineGroups();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  declineOwnershipTransfer() {
    this.modalService.confirmModal('Custom', null, null, null, null, {
      icon: null,
      title: 'Decline Account Subscription Transfer',
      question: '',
      warning: '',
      buttonTitle: 'Decline',
      buttonConfirmClass: '-color-danger'
    })
      .then((result) => {
        if (result === true) {
          this.loading = true;

          this.accountService.revokeAccountSubscriptionTransfer(
            this.account
          )
            .finally(() => {
              this.reloadSubscriptionAndAccount();
              this.reloadLineGroups();
              this.loading = false;
            });
        }
      })
      .catch(() => { });
  }

  revokeOwnershipTransfer() {
    this.modalService.confirmModal('Custom', null, null, null, null, {
      icon: null,
      title: 'Revoke Account Subscription Transfer',
      question: '',
      warning: '',
      buttonTitle: 'Revoke',
      buttonConfirmClass: '-color-danger'
    })
      .then((result) => {
        if (result === true) {
          this.loading = true;

          this.accountService.revokeAccountSubscriptionTransfer(
            this.account
          )
            .finally(() => {
              this.reloadSubscriptionAndAccount();
              this.reloadLineGroups();
              this.loading = false;
            });
        }
      })
      .catch(() => { });
  }

  changePlan() {
    this.modalService.subscribeModal(this.account)
      .finally(() => {
        this.reloadSubscriptionAndAccount();
      });
  }

  backToSubscriptionDash() {
    this.stateService.go('app.subscription.dash');
  }
}
