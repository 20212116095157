import { Component, HostBinding, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { TokenPayheroService } from './../../../services/token-payhero/token-payhero.service';

@Component({
  selector: 'app-autopilot-send-pay',
  templateUrl: './autopilot-send-pay.component.html',
  styleUrls: ['./autopilot-send-pay.component.scss']
})
export class AutopilotSendPayComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  loading: boolean = true;
  payDetails: any = null;
  expiredState: boolean = false;
  sending: boolean = false;
  success: boolean = false;

  token: string;

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public stateService: StateService,
    public tokenPayHeroService: TokenPayheroService
  ) { }

  ngOnInit() {
    window.document.title = 'Send Pay | Payroll AutoPilot';

    this.token = this.uiRouterGlobals.params.token || null;

    if (this.token) {
      this.tokenPayHeroService.getAutoPilotPayDetails(this.token)
        .then((pay_details) => {
          this.payDetails = pay_details;
          this.loading = false;

          if (!this.payDetails) {
            this.expiredState = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.expiredState = true;
        });
    }
    else {
      this.loading = false;
      this.expiredState = true;
    }

  }

  sendPay() {
    this.sending = true;

    this.tokenPayHeroService.autoPilotSendPay(this.token)
      .then(() => {
        this.sending = false;
        this.success = true;
      })
      .catch(() => {
        // Most likely errors here are attempting to re-use a pay_token
        this.sending = false;
      });
  }

  login() {
    this.stateService.go('login', {
      destination: 'PAYHERO_APP'
    });
  }

}
