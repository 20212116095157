import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';

import { UserAccess } from './../../../models/user-access/user-access';
import { AccountAccessService } from './../../../services/account-access/account-access.service';
import { UserService } from './../../../services/user/user.service';
import { PartnerStaffService } from './../../../services/partner-staff/partner-staff.service';
import { DpListComponent } from 'src/app/components/dp-list/dp-list.component';
import { Client } from 'src/app/models/client/client';
import { PartnerStaff } from 'src/app/models/partner-staff/partner-staff';
import { ClientService } from 'src/app/services/client/client.service';
import { ModalService } from 'src/app/services/modal/modal.service';

import * as _ from 'lodash-es';

@Component({
  selector: 'app-staff-edit',
  templateUrl: './staff-edit.component.html',
  styleUrls: ['./staff-edit.component.scss']
})
export class StaffEditComponent implements OnInit {

  @ViewChild('clientList') clientList: DpListComponent;

  readonly is_partner_owner: boolean = this.userService.is_partner_owner;

  dropdownOptions: any[][] = [
    [
      {
        option_type: 'MANAGE',
        option_name: 'Manage Role'
      }
    ], [
      {
        option_type: 'DELETE',
        option_name: 'Delete Staff Member',
        option_color: '#d9534f'
      }
    ]
  ];

  clientDropdownOptions: any[] = [
    {
      option_type: 'DELETE',
      option_name: 'Delete Client',
      option_color: '#d9534f'
    }
  ];

  userAccess: UserAccess = this.userService.user_access;
  staff: PartnerStaff;
  staffClients: Client[];
  availableClients: Client[];

  loading: boolean

  constructor(
    public stateService: StateService,
    public clientService: ClientService,
    public uiRouterGlobals: UIRouterGlobals,
    public modalService: ModalService,
    public partnerStaffService: PartnerStaffService,
    public userService: UserService,
    public accountAccessService: AccountAccessService
  ) { }

  ngOnInit(): void {
    const partner_staff_key = this.uiRouterGlobals.params.partner_staff_key;
    this.staff = this.partnerStaffService.getPartnerStaff(partner_staff_key);

    if (!this.staff) {
      this.backToStaff();
    }
    else {
      this.availableClients = [];

      if (this.staff.pending_flag) {
        this.dropdownOptions[1][0].option_name = 'Cancel Invite';

        this.dropdownOptions[0].unshift({
          option_type: 'RESEND',
          option_name: 'Resend Invite',
          option_color: '#00adef'
        });
      }

      this.updateStaffClients();
      this.updateAvailableClients();
    }
  }

  editStaffDetails() {
    this.modalService.formModal(
      'Edit Staff Details',
      this.staff,
      [
        { property: 'full_name', label: 'Name', field_required: true },
        { property: 'email_address', label: 'Contact Email', field_required: true, field_type: 'email' },
        { property: 'contact_phone', label: 'Contact Phone' }
      ]
    ).then((updated_staff) => {
      this.loading = true;

      this.partnerStaffService.savePartnerStaff(updated_staff)
        .finally(() => {
          this.staff = this.partnerStaffService.getPartnerStaff(this.staff.partner_staff_key);
          this.loading = false;
        });
    })
      .catch(() => { });
  }

  updateStaffClients() {
    this.staffClients = this.clientService.getClients().filter(client => {
      return client.containsPartnerStaff(this.staff.partner_staff_key);
    });
  }

  updateAvailableClients() {
    this.availableClients = this.clientService.getClients().filter(client => {
      return !client.containsPartnerStaff(this.staff.partner_staff_key);
    });
  }

  backToStaff() {
    this.stateService.go('app.staff.dash');
  }

  dropdownOptionSelected(option: string) {
    if (this.staff.user_access_key !== this.userAccess.user_access_key) {
      switch (option) {
        case 'RESEND':
          this.resendInvite();
          break;
        case 'MANAGE':
          this.manageStaffAccess();
          break;
        case 'DELETE':
          this.deleteStaffMember();
          break;
      }
    }
  }

  clientDropdownOptionSelected(option: string, client: Client) {
    switch (option) {
      case 'DELETE':
        this.deleteClient(client);
        break;
    }
  }

  resendInvite() {
    this.staff.invite_status = 'RESENDING';

    this.partnerStaffService.savePartnerStaff(this.staff, false, true)
      .then(() => {
        this.staff.invite_status = 'SENT';

        setTimeout(() => {
          this.staff.invite_status = 'PENDING';
        }, 4000);
      })
      .catch((err) => {
        this.staff.invite_status = 'PENDING'
      })
  }

  manageStaffAccess() {
    this.modalService.manageStaffRoleModal(this.staff.full_name, this.staff.admin_flag)
      .then((new_admin_flag) => {
        if (this.staff.admin_flag === new_admin_flag) {
          return;
        }

        this.loading = true;
        this.staff.admin_flag = new_admin_flag;
        this.partnerStaffService.savePartnerStaff(this.staff)
          .catch((err) => {
            this.staff.admin_flag = !new_admin_flag;
          })
          .finally(() => {
            this.loading = false;
          })
      })
      .catch(() => { });
  }

  deleteStaffMember() {
    this.modalService.confirmModal(
      'Delete',
      'Staff Member',
      this.staff.full_name,
      null,
      this.staff.full_name + ' will no longer be accessible.', null
    )
      .then(() => {
        this.loading = true;

        this.partnerStaffService.savePartnerStaff(this.staff, true)
          .then(() => {
            this.backToStaff();
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  deleteClient(client: Client) {
    this.loading = true;

    this.accountAccessService.addOrRemovePartnerStaffFromClient(client, this.staff, true)
      .then(() => {
        this.updateStaffClients();
        this.updateAvailableClients();
      })
      .finally(() => {
        this.loading = false;
      })
  }

  addClients(clients: Client[]) {
    this.loading = true;

    this.accountAccessService.addClientsToPartnerStaff(this.staff, clients)
      .then(() => {
        this.updateStaffClients();
        this.updateAvailableClients();
      })
      .finally(() => {
        this.loading = false;
      })
  }
}
