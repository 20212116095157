import { Component, OnInit } from '@angular/core';

import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-token-payhero',
  templateUrl: './token-payhero.component.html',
  styleUrls: ['./token-payhero.component.scss']
})
export class TokenPayheroComponent implements OnInit {

  constructor(
    public productService: ProductService
  ) { }

  ngOnInit(): void {
    this.productService.current_product = 'PAYHERO';
  }

}
