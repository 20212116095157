import { Ng2StateDeclaration } from '@uirouter/angular';

export type AppStateDeclaration = Ng2StateDeclaration & { default_state?: boolean };
export type ActiveNavItems = ActiveNavItem[][];
export type ActiveNavItem = { root_state: string, states: string[] };

export type IntegrationSocketEventType = (
  'Integration_Login_Success' |
  'Integration_Connection_Exists' |
  'Integration_Connection_Complete' |
  'Integration_Signup_Complete' |
  'Integration_Error' |
  'Integration_Login_Not_Found'
);

export type SocketEventType = (
  'card_event' |
  'flexitime_account_updated' |
  IntegrationSocketEventType
);

export type SubscriptionLineType = ('UNITS' | 'FIXED');

export type DestinationValue = (
  'DROPPAH_APP' |
  'DROPPAH_MOBILE' |
  'PAYHERO_APP' |
  'PAYHERO_PORTAL' |
  'PAYHERO_MOBILE' |
  'PAYHERO_SHIFT' |
  'INVOXY_APP' |
  'INVOXY_MOBILE' |
  'KARMLY_APP'
);
export type ProductValue = (
  'DROPPAH' | 'PAYHERO' | 'INVOXY' | 'KARMLY' | 'FLEXITIME'
);
export type ProductName = (
  'Droppah' | 'PayHero' | 'Invoxy' | 'Karmly' | 'FlexiTime'
);
export type ProductValueAndName = {
  product_value: ProductValue,
  product_name: ProductName
};

export type ProductTheme = {
  loginFooterProductLogos: Record<string, string>,
  headerLogo: string,
  colour: string,
  graphicLeft: string,
  graphicRight: string,
  graphicMobileFooterFull: string,
  graphicMobile: string,
  displayName: string,
  hideFlexiTime: boolean,
  fromFlexiTimeLogo: string,
  signUpLinkLabel: string,
  signUpURL: string,
  pricingURL: string,
  employeeAccURL: string,
  supportCentreURL: string
};

export type IntegrationType = (
  'GOOGLE' |
  'XERO'
)

export type LoginIntegration = {
  integration_type: IntegrationType,
  integration_name: string,
  integration_logo_path: string
}

export type PaymentMethod = (
  'CARD' | 'DIRECTDEBIT' | 'INVOICE'
);

export type InfiniteParams = {
  rowsToRender: number,
  indexOfLastVisibleItem: number,
  infiniteScrollDisabled: boolean
};

export type ConfirmModalType = (
  'Delete' |
  'Remove' |
  'Continue' |
  'Custom' |
  'Disconnect' |
  'Cancel'
);

export type ConfirmModalCustomData = {
  icon?: string,
  title?: string,
  question?: string,
  warning?: string,
  buttonTitle?: string,
  buttonConfirmClass?: string,
  onlyWarningState?: boolean
};

export type NZRegion = (
  'Northland' |
  'Auckland' |
  'Waikato' |
  'Bay of Plenty' |
  'Gisborne' |
  'Hawke\'s Bay' |
  'Taranaki' |
  'Manawatū - Whanganui' |
  'Wellington' |
  'Nelson' |
  'Tasman' |
  'Marlborough' |
  'West Coast' |
  'Canterbury' |
  'Otago' |
  'Southland' |
  'Chatham Islands'
);

export type BannerType = (
  'ERROR' | 'INFO' | 'SUCCESS' | 'SUSPENDED'
);

export type BannerConfig = {
  type: BannerType,
  message: string,
  auto_hide_timer?: number,
  hide_disabled?: boolean,
  button_config?: BannerButtonConfig,
  show_spinner?: boolean
};

export type BannerButtonConfig = {
  button_title: string,
  disabled?: boolean,
  button_function: Function
}

export type AccountFilter = (
  'EXCLUDE_DROPPAH_MOBILE_ACCOUNTS' |
  'EXCLUDE_CANCELLED' |
  'EXCLUDE_NO_SUBSCRIPTION' |
  'EXCLUDE_NO_USER_ACCESS' |
  'EXCLUDE_NO_SUBSCRIPTION_ACCESS' |
  'EXCLUDE_DEMO' |
  'EXCLUDE_PARTNER_ACCOUNTS' |
  'UNLINKED_ONLY' |
  'DEMO_ONLY' |
  'ADMIN_ONLY'
);

export type AccountStatus = (
  'DEMO' |
  'CANCELLED' |
  'SUSPENDED' |
  'SUBSCRIBED' |
  'TRIAL_EXPIRED' |
  'ON_TRIAL' |
  'UNSUBSCRIBED'
);

export type AccountOwnershipTransferStatus = (
  'PENDING_NEW_OWNER' |
  'PENDING_OLD_OWNER'
);

export type AccountAction = (
  'SUBSCRIBE' |
  'CHANGE_PLAN' |
  'CANCEL_ACCOUNT' |
  'TRANSFER_START' |
  'TRANSFER_REVOKE' |
  'TRANSFER_RESPOND'
);

export type DropdownOption = {
  option_value: string | number,
  option_name: string,
  option_disabled?: boolean,
  option_hidden?: boolean,
  option_color?: string,
  option_is_toggleable?: boolean,
  option_toggle_value?: boolean,
  nested_options?: DropdownOption[]
}

export type FormInputField = {
  property: string,
  label: string,
  field_type?: FormInputFieldType,
  field_required?: boolean,
  field_readonly?: boolean,
  field_visibility_requires_button_click?: boolean,
};

export type FormInputFieldType = (
  'number' |
  'email' |
  'bank' |
  'address' |
  'staff-role' |
  'country' |
  'image' |
  'mobile-number'
);

export type Country = {
  country_key: string,
  country_name: string,
  money_symbol: string,
  timezone_name: string,
  calling_code: string,
  billing_currency: string
};

export type UserAccessAgreements = {
  subscription_message: string,
  subscription_updated_flag: boolean,
  partner_message: string,
  partner_updated_flag: boolean,
  new_user_flag: boolean,
  new_partner_flag: boolean
};

export type UserAccessAgreementFlags = (
  'subscription_agreed_flag' |
  'partner_agreed_flag'
);

export type AccountDomainData = {
  company_key: number,
  company_product_key: number,
  company_logo: string,
  company_name: string
}

export type ItemBulletType = ('line' | 'dot' | 'square');

export type AppTab = {
  value: any,
  label: string,
  required_fields?: string[]
};

export type DpCoverRequest = {
  approved_declined_date: Date,
  company_key: number,
  end_time: Date,
  new_person_display_name: string,
  new_person_key: number,
  new_person_profile_image: string
  notes: DpCoverRequestNote[],
  original_person_display_name: string
  original_person_profile_image: string,
  request_approved: boolean,
  roster_segment_cover_request_key: number,
  shift_date: Date,
  skill_color: string,
  skill_name: string,
  start_time: string
};

export type DpCoverRequestNote = {
  created_date: Date,
  display_name: string,
  note: string
};

export type DpLeaveRequest = {
  approved_declined_by: string,
  approved_declined_date: Date,
  start_time: Date,
  end_time: Date,
  approved_flag: boolean,
  declined_flag: boolean,
  display_name: string,
  company_key: number,
  person_key: number,
  person_leave_key: number,
  person_leave_request_key: number,
  notes: DpLeaveRequestNote[]
}

export type DpLeaveRequestNote = {
  created_date: Date,
  display_name: string,
  note: string
}

export type AutoPilotPayDetails = {
  pay_cycle_name: string,
  pay_key: number,
  start_date: Date,
  end_date: Date,
  pay_date: Date,
  gross_total: number,
  sub_company_product_key: number,
  direct_debit_status: string,
  direct_debit_verified_flag: boolean,
  direct_debit_contact_phone: string,
  debit_date?: Date,
  manual_payment_reference?: string,
  total_payment_amount?: number,
  maximum_pay_now?: number,
  dd_delay_flag?: boolean
}

export type BankEditStep = ('FORM' | 'TERMS');
