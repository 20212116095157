import { UIRouterGlobals } from '@uirouter/core';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, ComponentRef, OnDestroy, HostListener } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TransitionService, StateService } from '@uirouter/angular';

import { StateDataService } from './../../services/state-data/state-data.service';
import { DomService } from './../../services/dom/dom.service';
import { BackdropComponent } from '../backdrop/backdrop.component';

import * as $ from 'jquery';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';

@Component({
  selector: 'api-search',
  templateUrl: './api-search.component.html',
  styleUrls: ['./api-search.component.scss']
})
export class APISearchComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown) apiSearchDropdown: NgbDropdown;
  @ViewChild('apiSearchInput') apiSearchInput: ElementRef;

  @Input() initalSearchTerm: string = '';
  @Input() loading: boolean = true;
  @Input() disabled: boolean = false;
  @Input() dropdownDisabled: boolean = false;
  @Input() inputPlaceholder: string = 'Search...';
  @Input() minimumSearchTermLength: number = 0;
  @Input() primaryItemProperty: string;
  @Input() secondaryItemProperty: string;
  @Input() taggedItemProperty: string;
  @Input() focusOnInit: boolean = false;
  @Input() errorMessage: string = '';
  @Input() showError: boolean = false;

  @Output() searchTermUpdated = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<string>();
  @Output() nzbnUpdated = new EventEmitter<string>();

  backdropRef: ComponentRef<BackdropComponent> = null;

  searchTerm: string = '';
  items: any[] = [];

  listWidth: string;
  alignLeft: boolean;

  is_mobile = CoreUtilService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.is_mobile !== CoreUtilService.is_mobile) {
      this.is_mobile = CoreUtilService.is_mobile;
    }
  }
  constructor(
    public eRef: ElementRef,
    public transitionService: TransitionService,
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public stateDataService: StateDataService,
    public domService: DomService
  ) { }

  ngOnInit() {
    this.searchTerm = this.initalSearchTerm;

    if (this.focusOnInit) {
      setTimeout(() => {
        this.apiSearchInput?.nativeElement.focus();
        this.apiSearchInput?.nativeElement.click();
      });
    }
  }

  ngOnDestroy() {
    // if (this.backdropRef) {
    //   this.domService.closeBackdrop();
    // }
  }

  // dropdownToggled(isOpen: boolean) {
  //   if (isOpen) {
  //     this.backdropRef = this.domService.openBackdrop();
  //   }
  //   else {
  //     this.domService.closeBackdrop();
  //   }
  // }

  toggleDropdown() {
    this.openDropdown();
  }

  openDropdown() {
    if (this.apiSearchDropdown) {
      this.determineDropAlignment();
      this.apiSearchDropdown.open();
    }
  }

  closeDropdown() {
    if (this.apiSearchDropdown) {
      this.determineDropAlignment();
      this.apiSearchDropdown.close();
    }
  }

  determineDropAlignment() {
    const windowWidth = $(window).width();
    const xOffsetFromWindow = this.eRef.nativeElement.getBoundingClientRect().left;

    this.listWidth = this.eRef.nativeElement.offsetWidth + 'px';
    this.alignLeft = xOffsetFromWindow < (windowWidth / 2);
  }

  searchTermInputUpdated(updatedSearchTerm: string, itemSelected: boolean = false) {
    setTimeout(() => {
      if (this.searchTerm === updatedSearchTerm) {
        if (!itemSelected) {
          this.nzbnUpdated.emit('');
        }
        this.searchTermUpdated.emit(this.searchTerm);
      }
    }, 500)
  }

  updateSearchItems(searchTerm: string, items: any[]) {
    if (searchTerm === this.searchTerm) {
      this.items = items;
    }
  }

  selectItem(item: any) {
    this.closeDropdown();
    this.itemSelected.emit(item);
  }

}
