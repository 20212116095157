<button class="-type-icon"
        type="button"
        [ngClass]="{
          '-color-success' : !!value,
          '-color-white-outline' : !value,
          '-size-btn-lg': !btnSmall,
          '-size-btn-sm': btnSmall,
          '-size-icon-sm': btnSmall }">
  <i class="ion-md-checkmark"
     [ngStyle]="{ 'display': !!value ? 'block' : 'none'}">
  </i>
</button>

<div class="ph-checkbox-label"
     *ngIf="checkboxLabel">
  {{checkboxLabel}}
</div>
