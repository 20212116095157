import { Injectable } from '@angular/core';
import { DpCoverRequest, DpCoverRequestNote, DpLeaveRequest } from '../../app.types';
import { CoreUtilService } from '../core-util/core-util.service';

import { DbUtilService } from '../db-util/db-util.service';
import { TimeUtilService } from '../time-util/time-util.service';

import { env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenDroppahService {

  constructor(
    private dbUtilService: DbUtilService
  ) { }

  getLeave(token: string) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIGetDroppah('personleave/token', { token_uid: token })
        .then((res) => {
          const leave_request = this.setupLeaveRequest(res);
          if (!!leave_request) {
            resolve(leave_request);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCover(token: string) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIGetDroppah('roster/token', { token_uid: token })
        .then((cover_request) => {
          cover_request = this.setupCoverRequest(cover_request);
          if (!!cover_request) {
            resolve(cover_request);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postLeave(token_uid: string, approved_flag: boolean, declined_flag: boolean, note: string = null) {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIPostDroppah('personleave/token/respond',
        {
          token_uid: token_uid,
          approved_flag,
          declined_flag,
          note
        })
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }

  postCover(token_uid: string, approved_flag: boolean, declined_flag: boolean, note: string = null) {
    return new Promise<void>((resolve, reject) => {
      this.dbUtilService.APIPostDroppah('roster/token/respond',
        {
          token_uid: token_uid,
          approved_flag,
          declined_flag,
          note
        })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  setupLeaveRequest(d: any): DpLeaveRequest {
    if (d?.person_leave_request_key) {
      const notes = [];
      for (const n of d.notes) {
        notes.push({
          created_date: TimeUtilService.dateTimeStringToDate(n.created_date, null, true),
          display_name: n.display_name,
          note: n.note
        });
      }

      return {
        approved_declined_by: d.approved_declined_by,
        approved_declined_date: TimeUtilService.dateTimeStringToDate(d.approved_declined_date, null, true),
        start_time: TimeUtilService.dateStringToDate(d.start_time),
        end_time: TimeUtilService.dateStringToDate(d.end_time),
        approved_flag: d.approved_flag,
        declined_flag: d.declined_flag,
        display_name: d.display_name,
        company_key: d.company_key,
        person_key: d.person_key,
        person_leave_key: d.person_leave_key,
        person_leave_request_key: d.person_leave_request_key,
        notes
      };
    }
    return null;
  }

  setupCoverRequest(c: any): DpCoverRequest {
    // TODO: ensure the API is returning null for invalid coverrequests
    // It currently returns {}
    if (!!c?.roster_segment_cover_request_key) {
      const notes: DpCoverRequestNote[] = [];

      if (!!c.notes) {
        for (const n of c.notes) {
          notes.push({
            created_date: TimeUtilService.dateTimeStringToDate(n.created_date, null, true),
            display_name: n.display_name,
            note: n.note
          });
        }
      }

      return {
        approved_declined_date: TimeUtilService.dateTimeStringToDate(c.approved_declined_date, null, true),
        company_key: c.company_key,
        end_time: TimeUtilService.dateTimeStringToDate(c.end_time),
        new_person_display_name: c.new_person_display_name,
        new_person_key: c.new_person_key,
        new_person_profile_image: this._getProfileImage(c.new_person_profile_image),
        notes,
        original_person_display_name: c.original_person_display_name,
        original_person_profile_image: this._getProfileImage(c.original_person_profile_image),
        request_approved: !!c.request_approved,
        roster_segment_cover_request_key: c.roster_segment_cover_request_key,
        shift_date: TimeUtilService.dateStringToDate(c.shift_date),
        skill_color: CoreUtilService.intToHexColor(c.skill_color),
        skill_name: c.skill_name,
        start_time: TimeUtilService.dateTimeStringToDate(c.start_time),
      };
    }
    return null;
  }

  private _getProfileImage(imageURL: string) {
    if (!!imageURL) {
      return env.subscription.blob_url + imageURL;
    }
  }


}
