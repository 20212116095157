<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          <button class="app-nav-previousPage -type-text -desktop-only"
                  (click)="backToSubscriptionDash()">
            <i class="ion ion-ios-arrow-back"></i>
            <div>Subscription</div>
          </button>

          <div class="app-nav-titleInner">
            <div>
              <span>{{account.company_name}}</span>
            </div>
            <product-logo class="-desktop-only"
                          [productValue]="account.product_value"
                          [fullLogoOnly]="true">
            </product-logo>
          </div>
        </div>
      </div>
      <div class="app-nav-rowRight">
        <div class="subAccount-nextInvoiceDate"
             *ngIf="account.subscribed_flag">
          Next Invoice Date: <span>{{subscription.next_invoice_date | date: 'd MMM, y'}}</span>
        </div>
        <list-drop-down *ngIf="dropdown_options.length"
                        [itemNameProperty]="'option_name'"
                        [itemFontColorProperty]="'option_color'"
                        [buttonTypeClass]="'-type-icon'"
                        [buttonIconClass]="'ion-ios-more'"
                        [buttonColorClass]="'-color-grey-clear'"
                        [listWidth]="'240px'"
                        [sortItems]="false"
                        [items]="dropdown_options"
                        (itemSelected)="dropdownOptionSelected($event.item)">
        </list-drop-down>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="ph-formItem-header">
        <product-logo class="-mobile-only"
                      [productValue]="account.product_value"
                      [fullLogoOnly]="true">
        </product-logo>
        <div>
          Subscription Details
        </div>
        <div class="subAccount-pendingTransfer"
             *ngIf="account.ownership_transfer_status !== null">
          Pending Subscription Transfer
        </div>
        <div class="subAccount-trialStatus"
             *ngIf="account.trial_days_remaining !== null">
          Free Trial:
          <span *ngIf="account.trial_days_remaining < 0">
            Expired
          </span>
          <span *ngIf="account.trial_days_remaining === 1">
            {{account.trial_days_remaining + ' day remaining'}}
          </span>
          <span *ngIf="account.trial_days_remaining === 0 || account.trial_days_remaining > 1">
            {{account.trial_days_remaining + ' days remaining'}}
          </span>
        </div>
        <div class="subAccount-suspended"
             *ngIf="account.account_status === 'SUSPENDED'">
          <i class="bi bi-exclamation-triangle-fill"></i>
          Suspended
        </div>
      </div>

      <div class="dp-list"
           *ngFor="let account_plan of account_plan_lines">
        <div class="dp-list-item -header -itemSelectDisabled">
          <div class="dp-list-itemCell">
            {{account_plan.plan.plan_display_name}}
          </div>
          <div class="dp-list-itemCell"></div> <!-- Extra details div in lines -->
          <div class="dp-list-itemCell -rightAlign"
               [ngStyle]="{ 'opacity': !account_has_discount_flag ? 0 : '' }">
            Discount
          </div>
          <div class="dp-list-itemCell"></div> <!-- Total div in lines -->
        </div>
        <div class="dp-list-item -itemSelectDisabled"
             *ngFor="let line of account_plan.lines">
          <div class="dp-list-itemCell"
               *ngIf="line.line_type === 'FIXED'">
            <div>{{line.line_description}}</div>
          </div>

          <div class="dp-list-itemCell"
               *ngIf="line.line_type === 'UNITS'">
            <div>{{line.quantity}} {{line.quantity === 1 ? line.unit_description : line.unit_description_plural}}</div>
          </div>
          <div class="dp-list-itemCell"
               [ngStyle]="{ 'opacity': line.line_type !== 'UNITS' ? 0 : '' }">
            <div>{{line.unit_fee | appCurrency}} per {{line.unit_description}}</div>
          </div>

          <div class="dp-list-itemCell -rightAlign"
               [ngStyle]="{ 'opacity': !line.discount_percentage ? 0 : '' }">
            <div>{{line.discount_percentage}}%</div>
          </div>

          <div class="dp-list-itemCell"
               [ngStyle]="{ 'font-weight': account_plan.lines.length === 1 ? 500 : '' }">
            <div>{{line.line_total | appCurrency}}</div>
          </div>
        </div>
        <div class="dp-list-item -footer -itemSelectDisabled"
             *ngIf="account_plan.lines.length > 1">
          <div class="dp-list-itemCell">
            {{account_plan.plan_total | appCurrency}}
          </div>
        </div>
      </div>

      <div class="dp-list"
           *ngFor="let line of independant_lines">
        <div class="dp-list-item -header -itemSelectDisabled">
          <div class="dp-list-itemCell"
               *ngIf="line.line_type === 'FIXED'">
            <div>{{line.line_description}}</div>
          </div>

          <div class="dp-list-itemCell"
               *ngIf="line.line_type === 'UNITS'">
            <div>{{line.quantity}} {{line.quantity === 1 ? line.unit_description : line.unit_description_plural}}</div>
          </div>
          <div class="dp-list-itemCell"
               *ngIf="line.line_type === 'UNITS'">
            <div>{{line.unit_fee | appCurrency}} per {{line.unit_description}}</div>
          </div>

          <div class="dp-list-itemCell">
            <div>{{line.line_total | appCurrency}}</div>
          </div>
        </div>
      </div>

      <div class="subAccount-monthlyCost">
        Monthly Cost: <span>{{account.account_subscription.monthly_cost | appCurrency}}</span>
      </div>

    </div>
  </div>
</div>
