<div class="approvalRequest-container"
     *ngIf="!expiredState">

  <div class="ph-loadingIcon"
       *ngIf="!initialised || loading">
    <img src="assets/imgs/App_Loader.gif">
  </div>

  <div class="approvalRequest-header"
       *ngIf="!loading && initialised">
    <div class="approvalRequest-header-compayLogo">
      <img src="{{company_logo || 'assets/product-imgs/invoxy/Invoxy_Full_Registered.svg'}}">
    </div>

    <div class="approvalRequest-header-details">
      <div class="approvalRequest-title">
        <div> Approval Request:</div>
        <div class="-label">{{display_name}}</div>
      </div>
      <div class="approvalRequest-status">
        <div>Current Status:&nbsp;</div>
        <div [ngClass]="{'-approved': approved, '-declined': declined, '-label': !approved && !declined}">
          {{status}}
        </div>
      </div>
    </div>
  </div>

  <ng-container  *ngIf="initialised">
  <div class="app-approvalProject"
       *ngFor="let project of projects">
    <div class="app-approvalProject-header">
      <div class="app-approvalProject-projectName">
        {{project.project_name}}
      </div>
      <div class="app-approvalProject-clientName">
        {{project.client_name}}
      </div>
    </div>
    <div class="app-approvalProject-unitGroups">
      <div class="app-approvalProject-unitGroup"
           *ngFor="let unit_group of project.unit_groups">

        <div class="app-approvalProject-unitGroup-header">
          {{unit_group.unit_type}}
        </div>
        <div class="app-approvalProject-unitGroup-segments">
          <div class="app-approvalProject-unitGroup-segment"
               *ngFor="let segment of unit_group.segments">

            <div class="-segment-date">
              {{segment.segment_date | date : 'EEE, d MMM'}}
            </div>
            <div class="-segment-details">
              <item-bullet [bullet_colour]="segment.task_colour"></item-bullet>
              <div class="-segment-detailsInner">
                <div>
                  <div>{{segment.task_name}}</div>
                  <i class="ion ion-md-create"
                     *ngIf="!!segment.notes">
                    <tooltip-popover [tooltipContent]="segment.notes"></tooltip-popover>
                  </i>
                </div>
                <div *ngIf="!!segment.start_time || !!segment.break_duration">
                  <div *ngIf="!!segment.start_time">
                    {{segment.start_time | dateToTimePipe}} - {{segment.end_time | dateToTimePipe}}
                  </div>
                  <div class="-label"
                       *ngIf="!!segment.break_duration">
                    ({{(60 * segment.break_duration).toFixed(0)}} mins break)
                  </div>
                </div>
                <div class="-label"
                     *ngIf="segment.unit_flag && segment.units < 0">
                  CREDIT
                </div>
              </div>
            </div>
            <div class="-segment-units">
              <segment-value [hide_hours_unit_type]="true"
                             [segment]="segment">
              </segment-value>
            </div>
          </div>
        </div>
        <div class="app-approvalProject-unitGroup-footer">
          <segment-value [hide_hours_unit_type]="true"
                         [unit_type]="unit_group.unit_type"
                         [unit_value]="unit_group.total">
          </segment-value>
        </div>
      </div>
    </div>
  </div>
  </ng-container>

  <textarea placeholder="Note or Reason (optional)"
            type="text"
            [(ngModel)]="reason"
            [disabled]="approved || declined"
            *ngIf="!loading && initialised">
     </textarea>

  <div class="approvalRequest-responseOptions"
       *ngIf="!loading && initialised">
       <p *ngIf="approved"
       class="approvalRequest-locked-reason -approved">
       <i class="ion ion-md-checkmark"></i>
       This request as been successfully approved
   </p>

   <p *ngIf="declined"
       class="approvalRequest-locked-reason -decliend">
       <i class="ion ion-md-close"></i>
       This request as been successfully rejected
   </p>


    <button class="-color-success"
            *ngIf="!(approved || declined)"
            [disabled]="approving || declining"
            (click)="approve()">
      {{approving ? "Approving..." : "Approve"}}
    </button>
    <button class="-color-danger-hoverFill"
            *ngIf="!(approved || declined)"
            [disabled]="approving || declining"
            (click)="decline()">
      {{declining ? "Rejecting..." : "Reject"}}
    </button>
  </div>
</div>
<div class="approvalRequest-container"
     *ngIf="expiredState && initialised">

  <div class="ph-loadingIcon"
       *ngIf="loading">
    <img src="assets/imgs/App_Loader.gif">
  </div>

  <div class="approvalRequest-header -noBorder"
       *ngIf="!loading">
    <div class="approvalRequest-header-compayLogo">
      <img src="{{company_logo || 'assets/product-imgs/invoxy/Invoxy_Full_Registered.svg'}}">
    </div>
  </div>


  <!-- The token is no longer valid -->
  <div class="approvalRequest-card approvalRequest-expired-state"
       *ngIf="!loading">
    <i class="ion ion-md-alert"></i>
    <h3>Expired Approval Request</h3>
    <p>You can no longer respond to this approval request.</p>
  </div>

</div>
<footer class="ph-footer approvalRequest-footer">
  <img src="assets/product-imgs/invoxy/Invoxy_Full_Registered.svg">
</footer>
