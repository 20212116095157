import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { AppStateDeclaration } from './../../app.types';
import { ComponentsModule } from './../../components/components.module';
import { ClientComponent } from './client.component';
import { ClientDashComponent } from './client-dash/client-dash.component';
import { ClientEditComponent } from './client-edit/client-edit.component';

export const CLIENT_CONFIG: AppStateDeclaration[] = [
  {
    name: 'app.client',
    url: '/client',
    component: ClientComponent,
    abstract: true
  },
  {
    name: 'app.client.dash',
    url: '/dash',
    component: ClientDashComponent,
    default_state: true
  },
  {
    name: 'app.client.edit',
    url: '/edit?{client_key:int}',
    component: ClientEditComponent,
    params: {
      client_key: null
    }
  }
];

@NgModule({
  declarations: [
    ClientComponent,
    ClientDashComponent,
    ClientEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild({ states: CLIENT_CONFIG })
  ]
})
export class ClientModule { }
