import { Injectable } from '@angular/core';
import { env } from '../../../environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  readonly accepted_domains = ['droppah', 'invoxy', 'payhero', 'karmly', 'flexitime', 'default']


  readonly gtagMap = {
    'payhero': {
      sign_up: 'AW-1055506550/RRnoCM6oi5MYEPaAp_cD',
      subscribe: 'AW-1055506550/McEJCMDplJMYEPaAp_cD'
    },
    'karmly': {
      sign_up: 'AW-11112237983/oTC-COuEjJMYEJ-X3bIp',
      subscribe: ''
    },
    'droppah': {
      sign_up: 'AW-329017539/ywa9CJvxlJMYEMPR8ZwB',
      subscribe: 'AW-329017539/Tyd6CLXkkJMYEMPR8ZwB'
    }
  };

  scriptsLoaded: boolean = false;

  constructor() { }

  init() {
    this._insertScript();
  }

  _insertScript() {
    if (!this.scriptsLoaded && !!env.google_analytics.default_measurement_id) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${env.google_analytics.default_measurement_id}`;
      document.head.appendChild(gTagManagerScript);

      this._registerGoogleAnalytics();
      this.scriptsLoaded = true;
    }
  }

  _registerGoogleAnalytics() {
    (window as any).dataLayer = (window as any).dataLayer || [];
    function gtag(...args) { (window as any).dataLayer.push(arguments); }
    gtag('js', new Date());
    (window as any).gtag = gtag
  }

  _updateUserID(id: number, group: string = null) {
    if (!!this.scriptsLoaded) {
      if (!!group && this.accepted_domains.includes(group)) {
        gtag('config', env.google_analytics[group + '_measurement_id'], { 'groups': group, 'send_page_view': true, 'user_id': id });
      }
    }
  }

  trackEvent(event_name: string = 'sign_in', group: string = null, user_access_key: number = null, company_product_key: number = null) {
    if (!!this.scriptsLoaded) {
      if (!group) {
        const domain = window.location.hostname;
        for (const product of this.accepted_domains) {
          if (domain.includes(product)) {
            group = product;
          }
        }
      }
      group = group || 'default';

      this._updateUserID(user_access_key, group)

      if (!!company_product_key) {
        gtag('event', event_name, { 'send_to': group, 'company_product_key': company_product_key });
      }
      else{
        gtag('event', event_name, { 'send_to': group });
      }

      if (event_name === 'sign_up' && group !== 'default') {
        gtag('event', 'conversion', { 'send_to': this.gtagMap[group]?.sign_up, 'company_product_key': company_product_key })
      }

      if (event_name === 'subcribe' && (group !== 'karmly' && group !== 'default')) {
        gtag('event', 'conversion', { 'send_to': this.gtagMap[group]?.subscribe, 'company_product_key': company_product_key })
      }
    }
  }
}
