import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';

import { UserService } from './../../services/user/user.service';
import { Client } from 'src/app/models/client/client';
import { ClientNote } from 'src/app/models/client-note/client-note';
import { CoreUtilService } from './../../services/core-util/core-util.service';
import { DpListComponent } from 'src/app/components/dp-list/dp-list.component';
import { DropdownOption } from '../../app.types';

@Component({
  selector: 'client-note-list',
  templateUrl: './client-note-list.component.html',
  styleUrls: ['./client-note-list.component.scss']
})
export class ClientNoteListComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateNoteDropdownOptions();
  }

  @ViewChild('clientNoteList') clientNoteList: DpListComponent;

  readonly has_partner_admin_access: boolean = this.userService.has_partner_admin_access;

  @Input() client: Client = null;

  noteDropdownOptions: DropdownOption[][] = [];

  @Output() viewNote = new EventEmitter();
  @Output() editNote = new EventEmitter();
  @Output() deleteNote = new EventEmitter();

  constructor(
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.updateNoteDropdownOptions();
  }

  reloadVisibleAccounts() {
    setTimeout(() => {
      this.clientNoteList.reloadVisibleItems();
    });
  }

  updateNoteDropdownOptions() {
    const options: DropdownOption[][] = [[], []];

    if (this.has_partner_admin_access) {
      options[0].push({
        option_value: 'EDIT',
        option_name: 'Edit Note'
      });
    }

    if (CoreUtilService.is_mobile) {
      options[1].push({
        option_value: 'VIEW',
        option_name: 'View Note',
        option_color: '#00adef'
      });
    }

    this.noteDropdownOptions = options;
  }

  noteDropdownOptionSelected(option: string, note: ClientNote) {
    switch (option) {
      case 'VIEW':
        this.viewNote.emit({ note });
        break;
      case 'EDIT':
        this.editNote.emit({ note });
        break;
      case 'DELETE':
        this.deleteNote.emit({ note });
        break;
    }
  }

}
