import { Component, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { DpListComponent } from './../dp-list/dp-list.component';
import { UserService } from './../../services/user/user.service';
import { DropdownOption } from './../../app.types';
import { Account } from '../../models/account/account';
import { PhAccount } from '../../models/account/ph-account';
import { ProductService } from './../../services/product/product.service';
import { AccountService } from './../../services/account/account.service';
import { clone } from 'lodash-es';

import { env } from './../../../environments/environment';

import * as moment from 'moment';
import { DpAccount } from '../../models/account/dp-account';

@Component({
  selector: 'account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnChanges {

  @ViewChild('accountList') accountList: DpListComponent;

  readonly is_partner_owner: boolean = this.userService.is_partner_owner;
  readonly is_partner: boolean = this.userService.is_partner;
  readonly has_partner_admin_access: boolean = this.userService.has_partner_admin_access;

  @Input() accountIsVisible: (account: Account) => boolean = () => true;
  @Input() accounts: Account[] = [];
  @Input() show_unified_droppah_mobile_account: boolean = false;

  @Output() selectAccount = new EventEmitter();
  @Output() subscribe = new EventEmitter();
  @Output() signIn = new EventEmitter();
  @Output() viewSubscription = new EventEmitter();
  @Output() deleteDemoAccount = new EventEmitter();

  user_access = this.userService.user_access;

  account_dropdown_options: Record<number, DropdownOption[][]> = null;

  unified_droppah_mobile_account: DpAccount = null;
  droppah_mobile_accounts: DpAccount[] = [];
  droppah_multi_account_tooltip: string = null;

  visible_accounts: Account[] = [];

  constructor(
    public userService: UserService,
    public productService: ProductService,
    public accountService: AccountService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.accounts) {
      this.setupAccountDropdownOptions();
    }
    if (!!changes.show_unified_droppah_mobile_account) {
      this._updateUnifiedDroppahMobileAccount();
    }
    if (!!changes.accounts || !!changes.show_unified_droppah_mobile_account) {
      this._updateVisibleAccounts();
    }
  }

  private _updateUnifiedDroppahMobileAccount() {
    if (this.show_unified_droppah_mobile_account) {
      this.unified_droppah_mobile_account = this.accountService.getUnifiedDroppahMobileAccount();
    }
    else {
      this.unified_droppah_mobile_account = null;
    }
    this.droppah_mobile_accounts = this.accountService.getDroppahMobileAccounts();
    this._updateDroppahMultiAccountTooltip();
  }

  private _updateDroppahMultiAccountTooltip() {
    this.droppah_multi_account_tooltip = '';
    for (const account of this.droppah_mobile_accounts) {
      this.droppah_multi_account_tooltip += (account.company_name + '<br>');
    }
  }

  private _updateVisibleAccounts() {
    this.visible_accounts = clone(this.accounts);

    if (!!this.unified_droppah_mobile_account) {
      this.visible_accounts.unshift(this.unified_droppah_mobile_account);
    }
  }

  reloadVisibleAccounts() {
    setTimeout(() => {
      this.accountList.reloadVisibleItems();
    });
  }

  setupAccountDropdownOptions() {
    this.account_dropdown_options = {};

    for (const account of this.accounts) {
      const account_options: DropdownOption[][] = [[], []];

      if (!!account.account_subscription) {
        if (account.account_subscription.show_subscribe_button) {
          account_options[0].push({
            option_value: 'SUBSCRIBE',
            option_name: 'Subscribe',
            option_color: '#5eb22e',
          });
        }
        else if (account.subscribed_flag) {
          account_options[1].push({
            option_value: 'VIEW_SUBSCRIPTION',
            option_name: 'View Subscription',
            option_color: '#333'
          });
        }
      }

      if (account.allow_user_access) {

        if (account.has_company_settings_access) {
          account_options[1].push({
            option_value: 'SETTINGS',
            option_name: 'Account Settings',
            option_color: '#00adef'
          });
        }

        if (account instanceof PhAccount && account.has_payhero_shift_access) {
          account_options[1].push({
            option_value: 'SHIFT',
            option_name: 'Sign in to Shift',
            option_color: '#00adef'
          });
        }
      }

      if (account.sample_company && account.owner_flag) {
        account_options[0].push({
          option_value: 'DELETE_DEMO',
          option_name: 'Delete Demo Account',
          option_color: '#d9534f'
        });
      }

      this.account_dropdown_options[
        account.company_product_key
      ] = account_options;
    }
  }

  accountDropdownOptionSelected(option: string, account: Account) {
    switch (option) {
      case 'SUBSCRIBE':
        this.subscribe.emit({ account });
        break;
      case 'SETTINGS':
        this.signIn.emit({ account, external_state_name: env[account.product_value.toLowerCase()].external_settings_state });
        break;
      case 'VIEW_SUBSCRIPTION':
        this.viewSubscription.emit({ account });
        break;
      case 'DELETE_DEMO':
        this.deleteDemoAccount.emit({ account });
        break;
      case 'SHIFT':
        this.productService.login_destination = 'PAYHERO_SHIFT';
        this.signIn.emit({ account });
        break;
    }
  }

  getLastSignedInText(last_session_date: string): string {
    return last_session_date ? moment(last_session_date).fromNow() : '';
  }

}
