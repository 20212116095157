import { Component, Input, ViewChild } from '@angular/core';
import { Country } from 'src/app/app.types';
import { AbstractValueAccessor, MakeProvider } from 'src/app/classes/abstract/abstract-value-accessor/abstract-value-accessor';
import { UserService } from 'src/app/services/user/user.service';
import { ListDropDownComponent } from '../list-drop-down/list-drop-down.component';

@Component({
  selector: 'country-code-input',
  templateUrl: './country-code-input.component.html',
  styleUrls: ['./country-code-input.component.scss'],
  providers: [MakeProvider(CountryCodeInputComponent)]
})
export class CountryCodeInputComponent extends AbstractValueAccessor {

  @Input() activeValidationEnabled: boolean = true;
  @Input() clearErrorsOnClick: boolean = true;
  @Input() customError: string = null;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  @ViewChild('listdropdown') listdropdown: ListDropDownComponent;

  missing_country: boolean = false;
  countries: Country[] = this.userService.getCountries();

  _value: Country;

  constructor(
    public userService: UserService
  ) {
    super();
  }

  selectCountryCode(countryCode: Country) {
    this.value = countryCode;
  }

}
