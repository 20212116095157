import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'dateToTimePipe'
})
export class DateToTimePipe implements PipeTransform {

  transform(
    value: Date,
    format: string = null
  ): string {
    const default_format = 'h' + (value?.getMinutes() === 0 ? '' : ':mm') + 'a';
    return moment(value).format(!!format ? format : default_format);
  }

}
