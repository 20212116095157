import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { PostableObjectError } from '../../interfaces/postable-object.inerface';
import { CardDetails } from '../../models/card-details/card-details';
import { SubscriptionService } from '../../services/subscription/subscription.service';


@Component({
  selector: 'card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent implements OnChanges {

  @Input() card: CardDetails;
  @Input() card_errors: PostableObjectError[] = [];

  card_type: string = null;

  readonly valid_card_types = new Set(['visa', 'mastercard']);

  card_error_map: Record<string, string> = {};

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.card_errors) {
      this._updateCardErrorMap();
    }
  }

  clearError(error_path: string) {
    delete this.card_error_map[error_path];
  }

  cardNumberUpdated() {
    this.card_type = SubscriptionService.getCreditCardType(this.card?.cardNumber);
  }

  private _updateCardErrorMap() {
    this.card_error_map = {};

    for (const error of this.card_errors) {
      this.card_error_map[error.error_path] = error.error_message;
    }
  }

}
