import { Component, Input, HostListener } from '@angular/core';

import { AbstractValueAccessor, MakeProvider } from 'src/app/classes/abstract/abstract-value-accessor/abstract-value-accessor';
import { CoreUtilService } from './../../services/core-util/core-util.service';

@Component({
  selector: 'bank-account-input',
  templateUrl: './bank-account-input.component.html',
  styleUrls: ['./bank-account-input.component.scss'],
  providers: [MakeProvider(BankAccountInputComponent)]
})
export class BankAccountInputComponent extends AbstractValueAccessor {

  @Input() inputLabel: string = 'Bank Account Number';
  @Input() activeValidationEnabled: boolean = true;
  @Input() bankAccountRequired: boolean = false;
  @Input() clearErrorsOnClick: boolean = true;
  @Input() customError: string = null;
  @Input() readonly: boolean = false;
  @Input() inlineErrorStyling: boolean = false;

  invalid_bank_account: boolean = false;
  missing_bank_account: boolean = false;

  _value: string;

  @HostListener('click', ['$event'])
  handleClick() {
    if (this.clearErrorsOnClick){
      this.clearErrors();
    }
  }

  bankAccountUpdated() {
    if (this.activeValidationEnabled) {
      this.validateBankAccount();
    }
  }

  validateBankAccount(): boolean {
    this.invalid_bank_account = false;
    this.missing_bank_account = false;

    if (this.bankAccountRequired && !this._value){
      this.missing_bank_account = true;
    }
    if (!CoreUtilService.validateBankAccount(this._value, !this.bankAccountRequired)) {
      this.invalid_bank_account = true;
    }
    return !this.missing_bank_account && !this.invalid_bank_account;
  }

  clearErrors(){
    this.customError = null;
    this.invalid_bank_account = false;
    this.missing_bank_account = false;
  }

}
