<dp-list #clientNoteList
         [noItemsMessage]="'Notes are a great way to keep staff informed about ' + client.client_name"
         [tableHeaders]="[]"
         [primarySortProperty]="'date_added'"
         [itemType]="'note'"
         [itemTypePlural]="'notes'"
         [itemSelectDisabled]="true"
         [items]="client.client_notes">
  <ng-template dpListItem
               let-item>

    <div class="dp-list-itemCell">
      {{item.note}}
    </div>

    <div class="dp-list-itemCell">
      <button class="-type-text -color-payhero-clear -desktop-only"
              (click)="viewNote.emit({ note: item })">
        View Note
      </button>

      <list-drop-down *ngIf="noteDropdownOptions[0].length || noteDropdownOptions[1].length"
                      [itemNameProperty]="'option_name'"
                      [itemFontColorProperty]="'option_color'"
                      [buttonTypeClass]="'-type-icon'"
                      [buttonIconClass]="'ion-ios-more'"
                      [buttonColorClass]="'-color-grey-clear'"
                      [listWidth]="'200px'"
                      [sortItems]="false"
                      [items]="noteDropdownOptions"
                      (itemSelected)="noteDropdownOptionSelected($event.item.option_value, item)">
      </list-drop-down>
    </div>

  </ng-template>
</dp-list>
