import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import * as  _ from 'lodash-es';
import { AppNumberPipe } from 'src/app/pipes/app-number/app-number.pipe';

export type WeekDayShort = (
  'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'
);
@Injectable({
  providedIn: 'root'
})


export class TimeUtilService {

  static dateIsValid(date) {
    return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
  }

  static getYearLabels() {
    return [
      'January', 'February', 'March', 'April', 'May',
      'June', 'July', 'August', 'September', 'October',
      'November', 'December'
    ];
  }

  static getWeekLabels() {
    return [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
    ]
  }

  /**
   * Creates a copy of the provided date object, increments the
   * relevant portion of the date and returns the resulting value.
   *
   * Use these functions to ensure that Angular's change detection is
   * triggered properly because the standard setFullYear(), setMonth() etc
   * only modify the existing variable which means Angular won't detect a variable change
   *
   * @param {Date} date
   * @param {number} value
   */
  static incrementYear(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setFullYear(newDate.getFullYear() + value);
    return newDate;
  }
  static incrementMonth(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMonth(newDate.getMonth() + value);
    return newDate;
  }
  static incrementDate(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setDate(newDate.getDate() + value);
    return newDate;
  }
  static incrementHours(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setHours(newDate.getHours() + value);
    return newDate;
  }
  static incrementMinutes(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMinutes(newDate.getMinutes() + value);
    return newDate;
  }

  /**
   * Creates a copy of the provided date object, updates the
   * relevant portion of the date and returns the resulting value.
   *
   * Use these functions to ensure that Angular's change detection is
   * triggered properly because the standard setFullYear(), setMonth() etc
   * only modify the existing variable which means Angular won't detect a variable change
   *
   * @param {Date} date
   * @param {number} value
   */
  static updateYear(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setFullYear(value);
    return newDate;
  }
  static updateMonth(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMonth(value);
    return newDate;
  }
  static updateDate(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setDate(value);
    return newDate;
  }
  static updateHours(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setHours(value);
    return newDate;
  }
  static updateMinutes(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMinutes(value);
    return newDate;
  }

  /**
   * Creates a start_time Date object and end_time Date object from the provided
   * start_time_string and end_time_string.
   *
   * The provided date object is then used to set year, month, date fields of
   * start_time and end_time
   *
   * @param {string} start_time_string - 'hh:mm'
   * @param {string} end_time_string - 'hh:mm'
   * @param {Date} date
   * @returns {any}
   */
  static setupStartAndEndTimesFromTimeStrings(start_time_string, end_time_string, date) {
    // Date objects with valid time values but invalid date values
    const start_time_only = TimeUtilService.timeStringToDate(start_time_string);
    const end_time_only = TimeUtilService.timeStringToDate(end_time_string);

    // Create a copy of date to get valid date values and then set valid time values
    const start_time = _.cloneDeep(date);
    start_time.setHours(start_time_only.getHours());
    start_time.setMinutes(start_time_only.getMinutes());

    const end_time = _.cloneDeep(date);
    end_time.setHours(end_time_only.getHours());
    end_time.setMinutes(end_time_only.getMinutes());

    // If end_time is before start_time then it must be on the next day
    if (end_time.valueOf() < start_time.valueOf()) {
      end_time.setDate(end_time.getDate() + 1);
    }

    return {
      start_time,
      end_time
    };
  }

  /**
   * Updates the date (dd/mm/yyyy) portion of a segment start or end time, using the provided segment date
   *
   * @param {Date} segmentTime
   * @param {Date} segmentDate
   * @returns {Date}
   */
  static updateDatePortionOfSegmentTime(segmentTime, segmentDate) {
    const newTime = _.cloneDeep(segmentDate);

    newTime.setHours(segmentTime.getHours());
    newTime.setMinutes(segmentTime.getMinutes());

    return newTime;
  }

  /**
   * Determines if the time portion of a end date is less than the time portion of an start date
   */
  static endTimeLessThanStartTime(start: Date, end: Date): boolean {
    const startMins = (start.getHours() * 60) + start.getMinutes();
    const endMins = (end.getHours() * 60) + end.getMinutes();

    return endMins < startMins;
  }

  /**
   * Calculates the start time for a new segment using the provided segmentDate
   *
   * If a defaultStart is provided, it will try to use this as the start time unless it overlaps with existing segments,
   * in which case the start time will be pushed back to the end time of the last of the existing segments
   *
   * If no defaultStart is provided and there is no overlap with existing segments, it will fallback to 9:00am
   *
   * @param {Date} segmentDate
   * @param {Date} defaultStart
   * @param {Array<Segment>} existingSegments
   * @returns {Date}
   */
  static calculateStartTimeForNewSegment(segmentDate, defaultStart, existingSegments) {
    const startTime = _.cloneDeep(segmentDate);
    startTime.setSeconds(0, 0);

    // Filter to segments that match the segmentDate
    let daysSegments = TimeUtilService.getSegmentsForDay(existingSegments, segmentDate);
    // Sort the segments and remove any UnitSegmentsse
    daysSegments = TimeUtilService.orderSegments(daysSegments, true);

    // Get the last existing TimeSegment on the provided segmentDate
    const lastSegmentOnDay = TimeUtilService.findLastTimeSegmentInList(daysSegments);

    if (lastSegmentOnDay) {
      // If defaultStart is after lastSegmentOnDay.end_time, use defaultStart
      if (defaultStart && moment(defaultStart).isSameOrAfter(moment(lastSegmentOnDay.end_time), 'minutes')) {
        startTime.setHours(defaultStart.getHours(), defaultStart.getMinutes());
      }
      // If defaultStart is before lastSegmentOnDay.end_time, use lastSegmentOnDay.end_time
      else {
        startTime.setHours(lastSegmentOnDay.end_time.getHours(), lastSegmentOnDay.end_time.getMinutes());
      }
    }
    else {
      if (defaultStart) {
        startTime.setHours(defaultStart.getHours(), defaultStart.getMinutes());
      }
      // Default the start time to 9:00 if no defaultStart and no other segments exist on this day
      else {
        startTime.setHours(9, 0);
      }
    }

    return startTime;
  }

  /**
   * Takes a list of Segments, filters them to TimeSegments and extends or reduces their individual durations/times
   * so that the sum of their durations matches the totalDuration provided
   *
   * Returns an array of updated segments that need to be posted
   *
   * @param {Array<Segment>} segments
   * @param {Number} totalDuration
   * @returns {Array<Segment>}
   */
  static updateSegmentsFromEditedDuration(segments, totalDuration) {
    segments = TimeUtilService.orderSegments(segments, true);
    const currentTotalDuration = TimeUtilService.calculateTotalDurationOfSegments(segments);

    if (currentTotalDuration > 24) {
      throw new Error('The total time cannot be edited when it is greater than 24 hours. Please edit each time segment manually.');
    }
    else {
      if (totalDuration < 0) {
        totalDuration = 0;
      }
      // Update existing segments
      if (segments.length > 0) {
        // Increase duration of segments
        if (totalDuration > currentTotalDuration) {
          let lastSegNewDuration = totalDuration;

          // Calculate new duration of last segment
          for (let i = 0; i < segments.length - 1; i++) {
            lastSegNewDuration -= segments[i].duration;
          }

          const lastSegment = segments[segments.length - 1];
          lastSegment.end_time = lastSegNewDuration;
          // Updates the end_time using existing start_time, break_duration and new duration
          lastSegment.durationChanged();

          return [lastSegment];
        }
        // Reduce duration of segments
        else if (totalDuration < currentTotalDuration) {
          let durationDiffRemaining = currentTotalDuration - totalDuration;
          const updatedSegments = [];

          // Work backwards through segments,
          // calculating new duration values based on the remaining
          // difference between the current total duration and the new total duration
          for (let i = segments.length - 1; i <= 0; i--) {
            const seg = segments[i];

            if (seg.duration < durationDiffRemaining) {
              durationDiffRemaining -= seg.duration;
              seg.duration = 0;
            }
            else {
              seg.duration -= durationDiffRemaining;
              durationDiffRemaining = 0;
            }

            // Updates the end_time using existing start_time, break_duration and new duration
            seg.durationChanged();

            updatedSegments.push(seg);

            if (durationDiffRemaining === 0) {
              break;
            }
          }

          return updatedSegments;
        }
      }
    }
  }

  /**
   * Calculates a TimeSegment end time based on its start_time, duration and break_duration
   *
   * @param {Segment} segment
   * @returns {null|Date}
   */
  static calculcateEndTimeForSegment(segment) {
    if (segment.isTimeSegment()) {
      const totalDuration = segment.duration + segment.break_duration;
      const hours = Math.floor(totalDuration);
      const mins = Math.ceil((totalDuration - hours) * 60);

      const endTime = _.cloneDeep(segment.start_time);
      endTime.setHours(hours, mins, 0, 0);

      return endTime;
    }
    else {
      return null;
    }
  }

  /**
   * Determines the default start time for a new segment on a given day
   * Tries to use the provided default time provided unless there are other segments
   * on the day already, in which case the start time will be set to the end time of the last segment on the day
   *
   * @param {Date} segmentDate
   * @param {Array<Segment>} currentSegments
   * @param {Date} defaultStart
   */
  static getStartTimeForNewSegment(segmentDate, currentSegments, defaultStart) {
    const startTime = _.cloneDeep(segmentDate);

    if (defaultStart) {
      startTime.setHours(defaultStart.getHours(), defaultStart.getMinutes(), 0, 0);
    }
    else {
      startTime.setHours(9, 0, 0, 0);
    }

    const daysSegments = TimeUtilService.getSegmentsForDay(currentSegments, segmentDate);
    const lastSegmentOnDay = TimeUtilService.findLastTimeSegmentInList(daysSegments);

    // Set the start time to the end time of the current last segment on this day
    // if it's greater than the default start time
    if (lastSegmentOnDay &&
      lastSegmentOnDay.start_time.valueOf() > startTime.start_time.valueOf()) {

      startTime.setHours(lastSegmentOnDay.end_time.getHours(), lastSegmentOnDay.end_time.getMinutes());
    }
  }

  /**
   * Returns a subset of segments where the segment_date's match the given day
   *
   * @param {Array<Segment>} segments
   * @param {Date} day
   * @returns {Array<Segment>}
   */
  static getSegmentsForDay(segments, day) {
    const daysSegments = [];
    const date = moment(day);

    for (const seg of segments) {
      if (moment(seg.segment_date).isSame(date, 'day')) {
        daysSegments.push(seg);
      }
    }

    return daysSegments;
  }

  /**
   * Formats a date object into a string for posting to API
   *
   * @param {Date} date
   * @param {Boolean} includeTime
   * @returns {String}
   */
  static formatDateForPosting(date, includeTime) {
    if (includeTime) {
      return TimeUtilService.dateToDateTimeString(date, 'YYYYMMDD HH:mm');
    }
    else {
      return TimeUtilService.dateToDateString(date, 'YYYYMMDD');
    }
  }

  /**
   * Converts date object to date string
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD'
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToDateString(date, stringFormat = null) {
    return TimeUtilService.dateToDateTimeString(date, (stringFormat || 'YYYY-MM-DD'));
  }

  /**
   * Converts date object to date time string
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD HH:mm'
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToDateTimeString(date, stringFormat = null) {
    if (!TimeUtilService.dateIsValid(date)) {
      return null;
    }
    if (stringFormat) {
      return moment(date).format(stringFormat);
    }
    else {
      return moment(date).format('YYYY-MM-DD HH:mm');
    }
  }

  /**
   * Converts date object to time string
   * If no stringFormat is provided, defaults to 'HH:mm'
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToTimeString(date, stringFormat) {
    if (!TimeUtilService.dateIsValid(date)) {
      return null;
    }
    if (stringFormat) {
      return moment(date).format(stringFormat);
    }
    else {
      return moment(date).format('HH:mm');
    }
  }

  /**
   * Coverts date string to date object with time set to midnight
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD'
   *
   * @param {String} dateString
   * @param {String} stringFormat
   * @param {Boolean} localise Whether or not to convert date to the local timezone (Used for converting GMT (system times) to local )
   * @returns {Date}
   */
  static dateStringToDate(dateString: string, stringFormat: string = null, localise: boolean = false) {
    return TimeUtilService.dateTimeStringToDate(dateString, (stringFormat || 'YYYY-MM-DD'), localise);
  }

  /**
   * Converts date string to date object
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD HH:mm'
   *
   * @param {String} dateString
   * @param {String} stringFormat
   * @param {Boolean} localise Whether or not to convert date to the local timezone (Used for converting GMT (system times) to local )
   * @returns {Date}
   */
  static dateTimeStringToDate(dateString: string, stringFormat: string = null, localise: boolean = false) {
    let date;

    if (localise) {
      dateString = moment(dateString).isValid() ? moment.utc(dateString).local().format() : dateString;
    }

    if (stringFormat) {
      date = moment(dateString, stringFormat).toDate();
    }
    else {
      date = moment(dateString, 'YYYY-MM-DD HH:mm').toDate();
    }

    // Check for invalid dates
    return TimeUtilService.dateIsValid(date) ? date : null;
  }

  /**
   * Converts time string to date object
   * e.g 'HH:MM', '09:30'
   *
   * @param {String} timeString
   * @param {String} stringFormat
   * @returns {Date}
   */
  static timeStringToDate(timeString) {
    return TimeUtilService.dateTimeStringToDate(timeString, 'HH:mm', false);
  }

  /**
   * Converts an hours based duration in decimal format into an array with two numeric values.
   * The first value is the number of hours, the second being the number of minutes
   * eg. 1.75 -> [1, 45]
   *
   * @param {number} dec
   * @returns {[number , number]}
   */
  static decimalAsHoursMins(dec) {
    let hours = Math.floor(dec);
    let mins = Math.round(60 * (dec - hours));

    // Need to account for possible rounding up of decimal
    if (mins === 60) {
      hours++;
      mins = 0;
    }

    return [hours, mins];
  }

  /**
   * Returns the difference in duration between two date objects as an hours decimal value
   * eg Difference between 2:00pm and 4:45pm is 2.75 hours
   *
   * @param {Date} dateA
   * @param {Date} dateB
   * @returns {number}
   */
  static differenceBetweenTwoDatesAsHoursDecimal(dateA, dateB) {
    const totalSeconds = Math.abs(Math.floor(dateA.valueOf() - dateB.valueOf()));

    return moment.duration(totalSeconds, 'milliseconds').asHours();
  }


  /**
   * Returns the date a week after the provided date
   *
   * @param {Date} weekStart
   * @returns {Date}
   */
  static getWeekEnd(weekStart) {
    if (!weekStart) {
      throw new Error('week start required to calculate week end');
    }

    const end = _.cloneDeep(weekStart);
    end.setDate(end.getDate() + 6);
    return moment(end).endOf('d').toDate();
  }

  /**
   * Returns the Monday date that was prior to the given date
   *
   * @param {Date} d
   * @returns {Date}
   */
  static getMonday(d) {
    const date = _.cloneDeep(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

    date.setDate(diff);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Returns an index of the given day in the week
   *
   * @param {String} day
   * @returns {int}
   */
  static getWeekDayValue(day) {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(day);
  }

  static getWeekDayValueInMonth(date): number {
    return date.getDate() / 7;
  }

  /**
   * Returns the index of the given day based on the given start date for the week
   *
   * @param {Date} date
   * @param {Date} weekStart
   * @returns {int}
   */
  // TODO check weekStart/weeksDates usage when moving function calls to this
  // TODO Old version of function uses weeksDates array instead of weekStart
  // TODO Check if functions expect a null or -1 returned for bad data

  // TODO New
  static getDayIndex(date, weekStart) {
    const weeksDates = TimeUtilService.generateWeeksDates(weekStart);

    if (!(date instanceof Date)) {
      return -1;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    for (let i = 0; i < 7; i++) {
      if (day === weeksDates[i].getDate() &&
        month === weeksDates[i].getMonth() &&
        year === weeksDates[i].getFullYear()) {
        return i;
      }
    }
    return -1;
  }

  // TODO Old
  // static getDayIndex(date, weeksDates){
  //     if (!(date instanceof Date)){
  //         return null;
  //     }
  //
  //     let day = date.getDate();
  //     let month = date.getMonth();
  //     let year = date.getFullYear();
  //
  //     for (var i = 0; i < 7; i++){
  //         if (day === weeksDates[i].getDate() &&
  //             month === weeksDates[i].getMonth() &&
  //             year === weeksDates[i].getFullYear()){
  //             return i;
  //         }
  //     }
  //     return null;
  // }

  /**
   * Generates an array of date objects for a week based on the given start date
   *
   * @param {Date} startDate
   * @returns {Array<Date>}
   */
  static generateWeeksDates(startDate) {
    const week = [];
    let date = _.cloneDeep(startDate);
    date.setHours(0, 0, 0, 0);

    for (let i = 0; i < 7; i++) {
      week.push(date);

      date = _.cloneDeep(date);
      date.setDate(date.getDate() + 1);
    }

    return week;
  }

  /**
   * Determines if provided date is today
   *
   * @param {Date} day
   * @returns {boolean}
   */
  static isToday(day) {
    return moment().isSame(moment(day), 'day');
  }

  // TODO New
  static calculateTotalUnitsOfSegments(segments) {
    let units = 0;

    for (const segment of segments) {
      if (segment.isUnitSegment()) {
        units += segment.units;
      }
    }

    return units;
  }

  // TODO New
  static calculateTotalDurationOfSegments(segments) {
    let duration = 0;

    for (const segment of segments) {
      if (segment.isTimeSegment()) {
        duration += segment.duration;
      }
    }

    return duration;
  }

  // TODO Old
  // static recalculateDurationOfDaysSegments(day){
  //     day.duration = 0;
  //
  //     for (let i = 0; i < day.segments.length; i++){
  //
  //         if (day.segments[i].duration){
  //             day.duration += day.segments[i].duration;
  //         }
  //
  //         else if (day.segments[i].units) {
  //             day.duration += day.segments[i].units;
  //         }
  //     }
  // }

  /**
   * Sums the durations of all segments
   *
   * @param {Object} segments
   * @returns {number}
   */
  // TODO Old
  // static calcDurationOfSegments(segments){
  //     let duration = 0;
  //
  //     for (let i = 0; i < segments.length; i++){
  //         let seg = segments[i];
  //
  //         if (seg.duration){
  //             duration += seg.duration;
  //         }
  //     }
  //
  //     return duration;
  // }

  /**
   * Returns the segment with the latest end time
   *
   * @param {Array<Segment>} segments
   * @returns {Segment}
   */
  static findLastTimeSegmentInList(segments) {
    let lastSegment = null;

    for (const seg of segments) {
      if (lastSegment === null &&
        seg.isTimeSegment()) {

        lastSegment = seg;
      }
      else if (lastSegment !== null &&
        seg.isTimeSegment() &&
        seg.end_time.valueOf() > lastSegment.end_time.valueOf()) {

        lastSegment = seg;
      }
    }

    return lastSegment;
  }

  /**
   * Returns the segment with the earliest start time
   *
   * @param {Array<Segment>} segments
   * @returns {Segment}
   */
  static findFirstSegmentInList(segments) {
    let firstSegment = null;

    for (const seg of segments) {
      if (firstSegment === null &&
        seg.isTimeSegment()) {

        firstSegment = seg;
      }
      else if (firstSegment !== null &&
        seg.isTimeSegment() &&
        seg.start_time.valueOf() < firstSegment.start_time.valueOf()) {

        firstSegment = seg;
      }
    }

    return firstSegment;
  }

  /**
   * Determines if the given segments start_time / end_time will
   * cause it to overlap with another segment in the list
   *
   * @param {Segment} s
   * @param {Array<Segment>} segments
   * @returns {boolean}
   */
  static segmentOverlapsAnotherInList(s, segments) {
    for (const seg of segments) {
      if (seg.isUnitSegment()) {
        continue;
      }
      if (s.start_time.valueOf() < seg.end_time.valueOf() &&
        s.end_time.valueOf() > seg.start_time.valueOf()) {
        const sKey = s.segment_key;
        const segKey = seg.segment_key;

        if (!sKey || sKey && sKey !== segKey) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Order Segments by their start_time
   * UnitSegments will be sorted to the end of the list or removed based on the discardUnitSegments param
   *
   * @param {Array<Segment>} segments
   * @param {Boolean} discardUnitSegments
   * @returns {Array<Segment>}
   */
  // TODO New
  static orderSegments(segments, discardUnitSegments) {
    segments = _.cloneDeep(segments);

    if (discardUnitSegments) {
      for (let i = segments.length - 1; i >= 0; i--) {
        if (segments[i].isUnitSegment()) {
          segments.splice(i, 1);
        }
      }
    }

    segments.sort((a, b) => {
      if (a.isUnitSegment()) {
        return 1;
      }
      else if (b.isUnitSegment()) {
        return -1;
      }
      else {
        return a.start_time.valueOf() - b.start_time.valueOf();
      }
    });

    return segments;
  }

  static getFirstDayOfMonthFromDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  static getWeekStartForDate(date: Date, week_start_day: WeekDayShort): Date {
    date = this.getCleanDate(date);
    const day = date.getDay();

    const week_start_value = this.getWeekDayValue(week_start_day);
    const day_diff = day - week_start_value;

    const value = date.getDate() - (day_diff < 0 ? (7 + day_diff) : day_diff);

    date.setDate(value);
    return date;
  }

  static getCleanDate(date: Date): Date {
    try {
      date = !!date ? _.cloneDeep(date) : new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }
    catch (err) {
      return date;
    }
  }

  static datesAreEqual(date_a: Date, date_b: Date, include_time: boolean = false): boolean {
    const a = DateTime.fromJSDate(date_a);
    const b = DateTime.fromJSDate(date_b);

    return a.isValid && b.isValid && a.hasSame(b, include_time ? 'minute' : 'day');
  }

  static getIndexOfDateInWeek(date: Date, week_start: Date): number {
    const l_date = DateTime.fromJSDate(date).startOf('day');
    const l_week_start = DateTime.fromJSDate(week_start).startOf('day');

    const diff = l_date.diff(l_week_start, 'days').days;
    return (diff >= 0 && diff <= 6) ? diff : null;
  }

  static generateDatesInRange(start_date: Date, end_date: Date): Date[] {
    const dates: Date[] = [];
    let current_date: Date = start_date;
    while (current_date <= end_date) {
      dates.push(current_date);
      current_date = this.incrementDate(current_date, 1);
    }
    return dates;
  }

  // TODO Old
  // static orderDaysSegments(day){
  //     let segments = _.cloneDeep(day.segments);
  //
  //     segments.sort((a, b) => {
  //         return a.start_time - b.start_time;
  //     });
  //
  //     return segments;
  // }
}
