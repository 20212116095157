import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AppCurrencyPipe } from '../../pipes/app-currency/app-currency.pipe';
import { AppNumberPipe } from '../../pipes/app-number/app-number.pipe';
import { HoursToTimePipe } from '../../pipes/hours-to-time/hours-to-time.pipe';

@Component({
  selector: 'segment-value',
  templateUrl: './segment-value.component.html',
  styleUrls: ['./segment-value.component.scss'],
  providers: [
    HoursToTimePipe,
    AppCurrencyPipe,
    AppNumberPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentValueComponent implements OnInit {

  @HostBinding('class.-boldValue') @Input() bold_value: boolean = false;

  private _segment: any = null;
  @Input()
  get segment(): any {
    return this._segment;
  }
  set segment(val: any) {
    this._segment = val || null;
    this._updateDisplayValue();
  }

  // OR
  private _unit_value: number = null;
  @Input()
  get unit_value(): number {
    return this._unit_value;
  }
  set unit_value(val: number) {
    this._unit_value = val || 0;

    if (this._unit_type !== null) {
      this._updateDisplayValue();
    }
  }

  private _unit_type: string = null;
  @Input()
  get unit_type(): string {
    return this._unit_type;
  }
  set unit_type(val: string) {
    this._unit_type = !!val ? val.toLowerCase() : null;

    if (this._unit_value !== null) {
      this._updateDisplayValue();
    }
  }

  @Input() reverse_display: boolean = false;
  @Input() hide_hours_unit_type: boolean = false;
  @Input() hours_as_decimal: boolean = false;

  display_value: string = '';
  display_unit: string = '';

  constructor(
    public hoursToTimePipe: HoursToTimePipe,
    public appCurrencyPipe: AppCurrencyPipe,
    public appNumberPipe: AppNumberPipe
  ) { }

  ngOnInit(){
    this._updateDisplayValue();
  }

  private _updateDisplayValue() {
    const unit_type = this._getUnitType();
    const unit_value = this._getUnitValue();

    if (unit_type === 'hours' || !unit_type) {
      this.display_value = this.hours_as_decimal ? this.appNumberPipe.transform(unit_value, 2) : this.hoursToTimePipe.transform(unit_value);
      this.display_unit = unit_value === 1 && !this.reverse_display ? 'hour' : 'hours';
    }
    else if (unit_type === '$' || unit_type === 'expense') {
      this.display_value = this.appCurrencyPipe.transform(unit_value);
      this.display_unit = null;
    }
    else {
      this.display_value = this.appNumberPipe.transform(unit_value, 0);
      if (unit_type === 'days') {
        this.display_unit = unit_value === 1 && !this.reverse_display ? 'day' : 'days';
      }
      else {
        this.display_unit = unit_type;
      }
    }
  }

  private _getUnitType(): string {
    if (!!this.segment) {
      return this.segment.unit_type || 'hours';
    }
    return this.unit_type;
  }

  private _getUnitValue(): number {
    if (!!this.segment){
      return this.segment.unit_flag ? this.segment.units : this.segment.duration;
    }
    return this.unit_value;
  }

}
