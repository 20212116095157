import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ClientNote } from 'src/app/models/client-note/client-note';

@Component({
  selector: 'app-add-client-note-modal',
  templateUrl: './add-client-note-modal.component.html',
  styleUrls: ['./add-client-note-modal.component.scss']
})
export class AddClientNoteModalComponent implements OnInit {

  note: ClientNote;
  viewOnly: boolean;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.dismiss();
  }

  saveNote() {
    this.activeModal.close({
      note: this.note
    })
  }

  deleteNote() {
    this.activeModal.close({
      note: this.note,
      toDelete: true
    })
  }

}
