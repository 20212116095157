import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

type CurrencyFormat = (
  'code' | 'symbol' | 'symbol-narrow' | string | boolean
);

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {

  constructor(
    public currencyPipe: CurrencyPipe,
    public subscriptionService: SubscriptionService
  ) { }

  transform(value: number | string, display: CurrencyFormat = 'symbol-narrow') {
    let result = this.currencyPipe.transform(value, this.subscriptionService.currency_code, display);
    if (
      this.subscriptionService.currency_code === 'USD' &&
      display === 'symbol'
    ) {
      result = result.replace('$', 'US$');
    }
    return result;
  }

}
