import { AccountSubscriptionLine } from './../account-subscription-line/account-subscription-line';
import { SubscriptionPlan } from './../subscription-plan/subscription-plan';

export class AccountSubscription {

  company_product_key: number;
  show_subscribe_button: boolean;
  can_change_plan: boolean;
  monthly_cost: number;
  unit_count: number;
  subscription_charges: any[];
  account_subscription_lines: AccountSubscriptionLine[];
  base_plan: SubscriptionPlan;

  constructor(
    company_product_key: number,
    show_subscribe_button: boolean,
    can_change_plan: boolean,
    monthly_cost: number,
    unit_count: number,
    subscription_charges: any,
    account_subscription_lines: AccountSubscriptionLine[]
  ) {
    this.company_product_key = company_product_key;
    this.show_subscribe_button = show_subscribe_button;
    this.can_change_plan = can_change_plan;
    this.monthly_cost = monthly_cost;
    this.unit_count = unit_count;
    this.subscription_charges = subscription_charges;
    this.account_subscription_lines = account_subscription_lines;

    this._initBasePlan();
  }

  private _initBasePlan(){
    for (const line of this.account_subscription_lines){
      if (line.subscription_plan?.base_plan_flag){
        this.base_plan = line.subscription_plan;
      }
    }
  }

}
