import { AccountSubscription } from './../account-subscription/account-subscription';
import { Account } from './account';
import { AccountInterface } from './../../interfaces/account.interface';
import { ProductName } from './../../app.types';

export class DpAccount extends Account implements AccountInterface {
  number_of_people: number;

  constructor(
    access_deactivated: boolean,
    admin_flag: boolean,
    allow_user_access: boolean,
    allow_external_connect: boolean,
    allow_account_select: boolean,
    cancelled_flag: boolean,
    client_key: number,
    company_logo: string,
    company_name: string,
    company_product_key: number,
    external_company_reference: string,
    last_session_date: Date,
    owner_flag: boolean,
    pending_owner_flag: boolean,
    pending_subscription_key: number,
    pending_subscription_contact_name: string,
    pending_subscription_contact_email: string,
    product_key: number,
    product_name: ProductName,
    sample_company: boolean,
    subscription_key: number,
    subscription_billing_name: string,
    subscription_billing_email: string,
    no_subscription_access_flag: boolean,
    suspended_flag: boolean,
    trial_days_remaining: number,
    trial_expired_flag: boolean,
    subscribed_flag: boolean,
    user_access_key: number,
    account_subscription: AccountSubscription,
    has_company_settings_access: boolean,
    number_of_people: number,
    auto_promo_code: string
  ) {
    super(
      access_deactivated,
      admin_flag,
      allow_user_access,
      allow_external_connect,
      allow_account_select,
      cancelled_flag,
      client_key,
      company_logo,
      company_name,
      company_product_key,
      external_company_reference,
      last_session_date,
      owner_flag,
      pending_owner_flag,
      pending_subscription_key,
      pending_subscription_contact_name,
      pending_subscription_contact_email,
      product_key,
      product_name,
      sample_company,
      subscription_key,
      subscription_billing_name,
      subscription_billing_email,
      no_subscription_access_flag,
      suspended_flag,
      trial_days_remaining,
      trial_expired_flag,
      subscribed_flag,
      user_access_key,
      account_subscription,
      has_company_settings_access,
      null,
      null,
      auto_promo_code
    );

    this.number_of_people = number_of_people;
  }
}
