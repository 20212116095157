import { Injectable } from '@angular/core';
import { DbUtilService } from '../db-util/db-util.service';
import { TimeUtilService } from '../time-util/time-util.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInvoxyService {

  constructor(public dbUtilService: DbUtilService) { }


  getCompanyLogo(company_domain: string): Promise<string> {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetInvoxy('company/logo', { company_domain }, false)
        .then((result) => {
          if (result !== null && result.length) {
            resolve(result[0].company_logo);
          }
          else {
            reject();
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }


  getApprovalRequest(token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetInvoxy('approval/requestedtime', { approval_token: token }, false)
        .then((result) => {
          if (result !== null && result.length) {
            resolve(this._formatRequestedTimeData(result[0]));
          }
          else {
            reject();
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }


  answerApproval(approval_token: string, approve: boolean, reason: string) {
    return new Promise<any>((resolve, reject) => {
      const params = {
        approval_token,
        approve,
        reason,
        response_date: TimeUtilService.dateToDateTimeString(new Date())
      };

      this.dbUtilService.APIPostInvoxy('token/approvalanswer', params, false)
        .then((result) => resolve(result))
        .catch((err) => reject());
    });
  }

  private _formatRequestedTimeData(res) {
    res.segments = res.segments || [];
    for (const segment of res.segments) {
      segment.segment_date = TimeUtilService.dateStringToDate(segment.segment_date);
      segment.start_time = TimeUtilService.dateTimeStringToDate(segment.start_time);
      segment.end_time = TimeUtilService.dateTimeStringToDate(segment.end_time);
    }
    return res;
  }

}
