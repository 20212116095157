import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product/product.service';

@Component({
  selector: 'app-token-invoxy',
  templateUrl: './token-invoxy.component.html',
  styleUrls: ['./token-invoxy.component.scss']
})
export class TokenInvoxyComponent implements OnInit {

  constructor(
    public productService: ProductService
  ) { }

  ngOnInit(): void {
    this.productService.current_product = 'INVOXY';
  }

}
