import { Component, HostBinding, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { TokenPayheroService } from 'src/app/services/token-payhero/token-payhero.service';
import { TimeUtilService } from 'src/app/services/time-util/time-util.service';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AppCurrencyPipe } from 'src/app/pipes/app-currency/app-currency.pipe';
import { AutoPilotPayDetails } from 'src/app/app.types';
import { env } from './../../../../environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-autopilot-direct-debit-payment',
  templateUrl: './autopilot-direct-debit-payment.component.html',
  styleUrls: ['./autopilot-direct-debit-payment.component.scss'],
  providers: [
    AppCurrencyPipe
  ]
})
export class AutopilotDirectDebitPaymentComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  token: string;
  pay_details: AutoPilotPayDetails;

  pay_message: string = '';

  success: boolean = false;

  pay_delayed: boolean = false;

  loading: boolean = true;

  error_message: string;

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public stateService: StateService,
    public tokenPayHeroService: TokenPayheroService,
    public redirectService: RedirectService,
    public modalService: ModalService,
    public appCurrencyPipe: AppCurrencyPipe,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    window.document.title = 'Pay Now | Payroll AutoPilot';

    this.token = this.uiRouterGlobals.params.token || null;

    if (this.token) {
      this.loadToken();
    }
    else {
      this.loading = false;
      this.error_message = 'This link is no longer valid';
    }
  }

  loadToken() {
    this.tokenPayHeroService.getAutoPilotPayDetails(this.token)
      .then((pay_details) => {
        this.pay_details = pay_details;
        this.loading = false;

        if (!this.pay_details) {
          throw new Error('Pay Now token expired');
        }

        this.pay_message = this.initialiseDirectDebitMessage();
      })
      .catch((err) => {
        this.loading = false;
        this.error_message = 'This link is no longer valid';
      });
  }

  initialiseDirectDebitMessage() {
    const over_max_flag = this.pay_details.maximum_pay_now < this.pay_details.total_payment_amount;

    let delayed_date;
    let pay_delayed = false;
    // 'AUTHORISED' doesn't take into account the max pay now amount
    // If AUTHORISED & under max, no delay
    if (this.pay_details.direct_debit_status === 'AUTHORISED' && !over_max_flag) {
      delayed_date = new Date();
      pay_delayed = false;
    } else if (this.pay_details.direct_debit_status === 'BAD BANK') {
      // BAD BANK = delay by 3 days
      delayed_date = new Date().setDate(new Date().getDate() + env.bad_bank_delay_days)
      pay_delayed = true;
    } else {
      // All other statuses (NEW AUTH | over_max_flag) = delay by 2 days
      delayed_date = new Date().setDate(new Date().getDate() + env.dd_delay_days);
      pay_delayed = true;
    }

    delayed_date = new Date(delayed_date).setHours(0, 0, 0, 0); // clear time portion of date

    var future_dated_pay_flag = false;
    if (this.pay_details.pay_date > delayed_date) {
      future_dated_pay_flag = true;
      pay_delayed = false;
    }

    let base_message = '';

    // If delay is caused by over max, show the max value
    if (over_max_flag && pay_delayed) {
      base_message = `Payments over ${this.appCurrencyPipe.transform(this.pay_details.maximum_pay_now)} have a two day clearance period before your employees receive payment. `;
    }


    base_message = base_message + `Your bank account will be debited the pay total of <b>${this.appCurrencyPipe.transform(this.pay_details.total_payment_amount)}</b> `;

    // Determine when they will be debited
    if (this.pay_details.direct_debit_verified_flag) {
      // Verified = debited today & paid out either on pay date or delayed date (eg. NEW AUTH) or today
      base_message = base_message + `today. ${(future_dated_pay_flag || pay_delayed) ? 'Your ' : ''}`;
    } else if (this.pay_details.dd_delay_flag) {
      // Delayed debiting because of trial/IRD & not verified = debited once verified & paid out after verification
      base_message = base_message + `once your bank account has been verified. ${future_dated_pay_flag ? 'Your ' : `${pay_delayed ? 'If verification is completed today, your ' : ''}`}`;
    } else {
      // Debiting is not delayed because of trial/IRD but bank account is not verified = debited today & paid out after verification
      base_message = base_message + `today. ${future_dated_pay_flag ? 'Your ' : 'Once your bank account has been verified, your '}`;
    }

    // Determine when their employees will be paid out
    if (future_dated_pay_flag) {
      // If pay date is in the future (beyond any delays), show pay date
      base_message = base_message + `employees will be paid out on the pay date of <b>${this.datePipe.transform(this.pay_details.pay_date)}</b>.`;
    } else if (pay_delayed) {
      // If the pay is delayed, show delayed date
      base_message = base_message + `employees will be paid out on <b>${this.datePipe.transform(delayed_date)}</b>.`;
    } else if (!this.pay_details.dd_delay_flag && !this.pay_details.direct_debit_verified_flag) {
      base_message = base_message + `employees will be paid out.`;
    }
    // If pay is not delayed and not in the future, show nothing.

    return base_message;
  }


  payNow() {
    this.modalService.confirmModal('Custom', null, null, null, null, {
      title: 'Confirm Direct Debit',
      question: `Are you sure you want to make this payment of ${this.appCurrencyPipe.transform(this.pay_details.total_payment_amount)}?`,
      buttonTitle: 'Pay Now',
      buttonConfirmClass: '-color-success'
    })
      .then(() => {
        this.tokenPayHeroService.initiateDirectDebitPayment(this.token, this.pay_details.pay_key)
          .then(() => {
            this.success = true;
          })
          .catch((err) => {
            this.loading = false;
            this.error_message = err.message;
          });
      })
      .catch(() => {
        this.loading = false;
      });
  }



  loginToPayHero() {
    this.stateService.go('login', {
      destination: 'PAYHERO_APP'
    });
  }

  loginToPayHeroPay() {
    if (!this.pay_details) {
      this.loginToPayHero();
    }

    const targetStateParams = {
      selected_month: this.pay_details.pay_date,
      selected_pay_key: this.pay_details.pay_key
    };

    this.redirectService.redirectToProduct(
      'PAYHERO_APP',
      false,
      'app.pay.sent',
      targetStateParams
    );
  }
}
