<div class="ph-loadingIcon" *ngIf="loading">
    <img src="assets/imgs/App_Loader.gif">
</div>


<div class="ph-expense-header"
    *ngIf="!loading">
    <img src="{{expiredState ? 'assets/product-imgs/payhero/PayHero_Full_Registered.svg' : expenseDetails.company_logo}}">

    <div *ngIf="approved" class="ph-expense-status-badge -approved">Approved</div>
    <div *ngIf="declined" class="ph-expense-status-badge -declined">Declined</div>

    <h2 *ngIf="!expiredState">Expense Reimbursement </h2>
</div>


<div class="ph-expense-card"
    *ngIf="!loading && !expiredState">

    <div class="ph-loadingIcon" *ngIf="approving || declining">
        <img src="assets/imgs/App_Loader.gif">
    </div>
    <div class="ph-expense-details" [ngStyle]="{'opacity': (approving || declining) ? '0.3':''}">

        <div class="ph-expense-details-grid">
            <div>
                <p class="ph-text-grey">Employee:</p>
                <h2>{{expenseDetails.employee_code}}</h2>
            </div>
            <div>
                <p class="ph-text-grey">Work:</p>
                <div class="expenseCatColor"
                    [ngStyle]="{'background-color': expenseDetails.project_colour}"
                    style="background-color: rgb(0, 173, 239);">
                </div>
                <h2>{{expenseDetails.project_title}}</h2>
            </div>
            <div>
                <p class="ph-text-grey">Date:</p>
                <h2>
                    {{expenseDetails.expense_date | date: 'd MMM,'}}
                    <span class="ph-text-grey">{{expenseDetails.expense_date | date: 'yyyy'}}</span>
                </h2>
            </div>
            <div>
                <p class="ph-text-grey">Amount:</p>
                <h2>{{expenseDetails.expense_amount | appCurrency}}</h2>
            </div>
        </div>

        <div *ngIf="expenseDetails.description"
            class="ph-expense-description">
            <p class="ph-text-grey">Description:</p>
            <div>{{expenseDetails.description}}</div>
        </div>
        <div class="ph-expense-image"
            *ngIf="expenseDetails.expense_image">
            <img src="{{'https://payhero.blob.core.windows.net/' + expenseDetails.expense_image}}">
        </div>
    </div>
    <div class="ph-expense-response-options">
        <p *ngIf="expenseLocked && approved"
            class="ph-expense-locked-reason -approved">
            <i class="ion ion-md-checkmark"></i>
            This expense was <span class="status">approved</span> on {{expenseDetails.approval_date | date: 'dd/MM/yy'}} by {{expenseDetails.approval_user_name}}
        </p>

        <p *ngIf="expenseLocked && declined"
            class="ph-expense-locked-reason -decliend">
            <i class="ion ion-md-close"></i>
            This expense was <span class="status">declined</span> on {{expenseDetails.declined_date | date: 'dd/MM/yy'}} by {{expenseDetails.approval_user_name}}
        </p>


        <button class="-color-success"
            *ngIf="!expenseLocked && !(approved || declined)"
            [disabled]="approving || declining"
            (click)="approve()">
            {{approving ? "Approving..." : "Approve"}}
        </button>
        <button class="-color-danger"
            *ngIf="!expenseLocked && !(approved || declined)"
            [disabled]="approving || declining"
            (click)="decline()">
            {{declining ? "Declining..." : "Decline"}}
        </button>
    </div>
</div>


<!-- The token is no longer valid -->
<div class="ph-expense-card ph-expense-expired-state"
    *ngIf="expiredState">
    <i class="ion ion-md-alert"></i>
    <h3>This link is no longer valid</h3>
    <p><a href="https://login.payhero.co.nz/#/login?destination=PAYHERO_APP">Sign In to PayHero</a></p>
</div>


<footer class="ph-footer ph-expense-footer">
    <img src="assets/product-imgs/payhero/PayHero_Full_Registered.svg">
</footer>
