<div class="ph-formItem-container -smallForm">

  <div class="ph-formItem">
    <div class="ph-formItem-label">
      <span>Name On Card</span>
    </div>
    <div class="ph-formItem-value">
      <input [(ngModel)]="card.cardHolderName"
             [ngClass]="{ '-invalid': card_error_map['card_name']}"
             [placeholder]="'Name On Card'"
             (focus)="clearError('card_name')">
    </div>
    <div class="ph-formItem-error"
         *ngIf="!!card_error_map['card_name']">
      {{card_error_map['card_name']}}
    </div>
  </div>

  <div class="ph-formItem -cardNumber">
    <div class="ph-formItem-label">
      <span>Card Number</span>
      <credit-card-icon [card_type]="'visa'"></credit-card-icon>
      <credit-card-icon [card_type]="'mastercard'"></credit-card-icon>
    </div>
    <div class="ph-formItem-value">
      <input [(ngModel)]="card.cardNumber"
             (ngModelChange)="cardNumberUpdated()"
             [ngClass]="{ '-invalid': card_error_map['card_number']}"
             [mask]="'0000-0000-0000-0000'"
             [placeholder]="'Card Number'"
             (focus)="clearError('card_number')">

      <credit-card-icon [card_type]="card_type"></credit-card-icon>
    </div>
    <div class="ph-formItem-error"
         *ngIf="!!card_error_map['card_number']">
      {{card_error_map['card_number']}}
    </div>
  </div>

  <div class="ph-formItem-row">

    <div class="ph-formItem -expiry">
      <div class="ph-formItem-label">
        <span>Expires</span>
      </div>
      <div class="ph-formItem-value">
        <input [(ngModel)]="card.expiryDate"
               [ngClass]="{ '-invalid': card_error_map['expiry_date']}"
               [mask]="'00/00'"
               [placeholder]="'MM/YY'"
               (focus)="clearError('expiry_date')">
      </div>
      <div class="ph-formItem-error"
           *ngIf="!!card_error_map['expiry_date']">
        {{card_error_map['expiry_date']}}
      </div>
    </div>

    <div class="ph-formItem -cvc">
      <div class="ph-formItem-label">
        <span>CVC</span>
        <div class="help-icon">
          <i class="ion ion-md-help-circle"></i>
          <tooltip-popover
                           [tooltipContent]="'The three-digit number found on the back of your card typically to the right of your signature'">
          </tooltip-popover>
        </div>
      </div>
      <div class="ph-formItem-value">
        <input [(ngModel)]="card.cvc2"
               [ngClass]="{ '-invalid': card_error_map['cvc']}"
               [placeholder]="'CVC'"
               [mask]="'000'"
               (focus)="clearError('cvc')">
      </div>
      <div class="ph-formItem-error"
           *ngIf="!!card_error_map['cvc']">
        {{card_error_map['cvc']}}
      </div>
    </div>

  </div>

</div>
