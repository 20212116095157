<img class="-dp"
     *ngIf="productValue === 'DROPPAH' && !iconOnly"
     [ngClass]="fullLogoOnly ? '' : '-desktop-only'"
     [src]="'assets/product-imgs/droppah/Droppah_Full_Black.svg'">
<img class="-dp -icon"
     *ngIf="productValue === 'DROPPAH' && !fullLogoOnly"
     [ngClass]="iconOnly ? '' : '-mobile-only'"
     [src]="'assets/product-imgs/droppah/Droppah_Single.svg'">

<img class="-ph"
     *ngIf="productValue === 'PAYHERO' && !iconOnly"
     [ngClass]="fullLogoOnly ? '' : '-desktop-only'"
     [src]="'assets/product-imgs/payhero/PayHero_Full_Registered.svg'">
<img class="-ph -icon"
     *ngIf="productValue === 'PAYHERO' && !fullLogoOnly"
     [ngClass]="iconOnly ? '' : '-mobile-only'"
     [src]="'assets/product-imgs/payhero/PayHero_Icon.svg'">

<img class="-inv"
     *ngIf="productValue === 'INVOXY' && !iconOnly"
     [ngClass]="fullLogoOnly ? '' : '-desktop-only'"
     [src]="'assets/product-imgs/invoxy/Invoxy_Full_Registered.svg'">
<img class="-inv -icon"
     *ngIf="productValue === 'INVOXY' && !fullLogoOnly"
     [ngClass]="iconOnly ? '' : '-mobile-only'"
     [src]="'assets/product-imgs/invoxy/Invoxy_Icon.svg'">

<img class="-km"
     *ngIf="productValue === 'KARMLY' && !iconOnly"
     [ngClass]="fullLogoOnly ? '' : '-desktop-only'"
     [src]="'assets/product-imgs/karmly/Karmly_Full.svg'">
<img class="-km -icon"
     *ngIf="productValue === 'KARMLY' && !fullLogoOnly"
     [ngClass]="iconOnly ? '' : '-mobile-only'"
     [src]="'assets/product-imgs/karmly/Karmly_Icon.svg'">
