import { Subject, Observable } from 'rxjs';
import { StateService } from '@uirouter/core';
import { Injectable } from '@angular/core';

import { PartnerStaff } from './../../models/partner-staff/partner-staff';
import { DbUtilService } from './../db-util/db-util.service';
import { SortUtilService } from './../sort-util/sort-util.service';
import { UserService } from './../user/user.service';
import { AuthService } from './../auth/auth.service';

import *  as _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class PartnerStaffService {

  private _serviceDataUpdateEvent = new Subject<void>();

  partnerStaff: PartnerStaff[] = [];
  partnerStaffMap: Record<number, PartnerStaff> = null;

  serviceSetup: boolean = false;

  constructor(
    public dbUtilService: DbUtilService,
    public userService: UserService,
    public stateService: StateService,
    public authService: AuthService
  ) { }

  initialiseService() {
    return new Promise<any>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve(this.partnerStaff);
      }
      else {
        this.loadPartnerStaff()
          .then(() => {
            this.serviceSetup = true;
            resolve(this.partnerStaff);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.partnerStaff = null;
    this.partnerStaffMap = null;
    this.serviceSetup = false;
  }

  // Getters ///////////////

  getServiceDataUpdateEvent(): Observable<any> {
    return this._serviceDataUpdateEvent.asObservable();
  }

  getPartnerStaff(partner_staff_key: number, getReference: boolean = false): PartnerStaff {
    return getReference ? this.partnerStaffMap[partner_staff_key] : _.cloneDeep(this.partnerStaffMap[partner_staff_key]);
  }

  getAllPartnerStaff(getReference: boolean = false): PartnerStaff[] {
    return getReference ? this.partnerStaff : _.cloneDeep(this.partnerStaff);
  }

  // Network Calls ///////////////

  loadPartnerStaff(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.dbUtilService.APIGet('partner/staff', true)
        .then((data) => {
          this.partnerStaff = [];
          this.partnerStaffMap = {};

          const partner_staff = this.setupPartnerStaff(data);

          for (const ps of partner_staff) {
            this.partnerStaff.push(ps);
            this.partnerStaffMap[ps.partner_staff_key] = ps;
          }

          SortUtilService.sortList(this.partnerStaff, 'full_name');
          this._serviceDataUpdateEvent.next();

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // Reload single partner staff member
  reloadPartnerStaffMember(partner_staff_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.dbUtilService.APIGet('partner/staff', { partner_staff_key }, true)
        .then((data) => {
          const partner_staff = this.setupPartnerStaff(data);

          for (const ps of partner_staff) {
            for (let i = 0; i < this.partnerStaff.length; i++){
              if (this.partnerStaff[i].partner_staff_key === ps.partner_staff_key){
                this.partnerStaff[i] = ps;
              }
            }
            this.partnerStaffMap[ps.partner_staff_key] = ps;
          }

          SortUtilService.sortList(this.partnerStaff, 'full_name');
          this._serviceDataUpdateEvent.next();
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  savePartnerStaff(
    staff: PartnerStaff, toDelete: boolean = false, resendInvite: boolean = false
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const data = staff.formatForPosting(toDelete, resendInvite);

      this.dbUtilService.APIPost('partner/staff', data)
        .then(() => {

          // Logout when deleting self
          if (toDelete && staff.user_access_key === this.userService.user_access_key) {
            this.authService.logout();
            this._serviceDataUpdateEvent.next();
            resolve();
          }
          else {
            this.loadPartnerStaff()
              .finally(() => {
                this._serviceDataUpdateEvent.next();
                resolve();
              });
          }
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  setupPartnerStaff(staffData: any[]): PartnerStaff[] {
    const staff = [];
    for (const ps of staffData){
      staff.push(this.setupPartnerStaffMember(ps));
    }
    return staff;
  }

  setupPartnerStaffMember(ps: any): PartnerStaff {
    return new PartnerStaff(
      ps.partner_staff_key,
      ps.user_access_key,
      ps.full_name,
      ps.email_address,
      ps.contact_phone,
      ps.owner_flag,
      ps.admin_flag,
      ps.pending_flag
    );
  }

}
