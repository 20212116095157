import { Component, HostBinding, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { TokenPayheroService } from 'src/app/services/token-payhero/token-payhero.service';
import { TimeUtilService } from 'src/app/services/time-util/time-util.service';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AppCurrencyPipe } from 'src/app/pipes/app-currency/app-currency.pipe';
import { AutoPilotPayDetails } from 'src/app/app.types';

import { env } from './../../../../environments/environment';

@Component({
  selector: 'app-autopilot-bank-transfer-payment',
  templateUrl: './autopilot-bank-transfer-payment.component.html',
  styleUrls: ['./autopilot-bank-transfer-payment.component.scss'],
  providers: [
    AppCurrencyPipe
  ]
})
export class AutopilotBankTransferPaymentComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  token: string;
  pay_details: AutoPilotPayDetails;

  success: boolean = false;

  loading: boolean = true;

  error_message: string;

  manual_bank_account_number: string = env.manual_dd_bank;

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public stateService: StateService,
    public tokenPayHeroService: TokenPayheroService,
    public redirectService: RedirectService,
    public modalService: ModalService,
    public appCurrencyPipe: AppCurrencyPipe,
  ) { }

  ngOnInit(): void {
    window.document.title = 'Pay Now | Payroll AutoPilot';

    this.token = this.uiRouterGlobals.params.token || null;

    if (this.token) {
      this.loadToken();
    } else {
      this.loading = false;
      this.error_message = 'This link is no longer valid';
    }

  }

  loadToken() {
    this.tokenPayHeroService.getAutoPilotPayDetails(this.token)
      .then((pay_details) => {
        this.pay_details = pay_details;
        this.loading = false;

        if (!this.pay_details) {
          throw new Error('Pay Now token expired');
        }

      })
      .catch((err) => {
        this.loading = false;
        this.error_message = 'This link is no longer valid';
      });
  }



  loginToPayHero() {
    this.stateService.go('login', {
      destination: 'PAYHERO_APP'
    });
  }

  loginToPayHeroPay() {
    if (!this.pay_details) {
      this.loginToPayHero();
    }

    const targetStateParams = {
      selected_month: this.pay_details.pay_date,
      selected_pay_key: this.pay_details.pay_key
    };

    this.redirectService.redirectToProduct(
      'PAYHERO_APP',
      false,
      'app.pay.sent',
      targetStateParams
    );
  }

}
