<div class="ph-formItem">
  <div class="ph-formItem-label">
    {{inputLabel}}
    <div class="app-fieldOptional"
         *ngIf="!addressRequired">
      (optional)
    </div>
  </div>
  <div class="ph-formItem-value"
       [hidden]="readonly">
    <input #addressInput
           class="ph-formItem-input"
           type="text"
           [placeholder]="inputLabel"
           [ngClass]="{ '-invalid': customError || missing_address }"
           [(ngModel)]="value"
           (ngModelChange)="addressUpdated()">
  </div>
  <div class="ph-formItem-value"
       *ngIf="readonly">
    {{value}}
  </div>
  <div class="ph-formItem-error"
       *ngIf="!customError && missing_address">
    {{inputLabel}} Required
  </div>
  <div class="ph-formItem-error"
       *ngIf="customError">
    {{customError}}
  </div>
</div>
