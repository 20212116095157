<ng-template #popoverContent>
  <div [innerHTML]="tooltipContent"></div>
</ng-template>

<div #tooltip="ngbTooltip"
     [ngbTooltip]="popoverContent"
     [triggers]="'mouseenter:mouseleave'"
     [container]="'body'"
     [tooltipClass]="(smallFont ? '-smallFont' : '') + (invertColours ? ' -invertColours' : '') "
     (click)="open()">
</div>
