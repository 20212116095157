import { Injectable } from '@angular/core';

import { ProductService } from '../product/product.service';
import { AuthService } from '../auth/auth.service';
import { env } from './../../../environments/environment';
import { ProductValue, DestinationValue } from './../../app.types';
import { Account } from '../../models/account/account';
import { StateDataService } from '../state-data/state-data.service';
import { DbUtilService } from '../db-util/db-util.service';

// eslint-disable-next-line no-var
declare var Base64: any;

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  readonly stateNameMap: any = {
    'app.time.expenses': {
      mobile: 'app.expense.manager',
      portal: 'app.expenses.manager'
    },
    'app.leave.calendar': {
      mobile: 'app.leave.manager',
      portal: 'app.leave.manager'
    }
  };

  constructor(
    public authService: AuthService,
    public productService: ProductService,
    public stateDataService: StateDataService,
    public dbUtilService: DbUtilService
  ) { }

  get screenWidth(): number {
    return window.innerWidth;
  }

  redirectToFlexiTimeApp() {

    const data = {
      token_type: 'REPORTING REFRESH',
    };
    this.dbUtilService.APIPost('user_access/refresh/generate', data)
      .then((res) => {
        const url_params = {
          refresh_token: res.refresh_token
        };
        const url = env.flexitime.app_url + 'loginExternal?url_params=' + Base64.encode(JSON.stringify(url_params));

        window.open(url, '_blank');
      });
  }

  redirectToProduct(
    login_destination: DestinationValue = null,
    new_tab: boolean = false,
    external_state_name: string = null,
    external_state_params: any = null,
  ) {
    let url = null;
    login_destination = login_destination || this.productService.login_destination;

    if (this.productService.login_destination === 'DROPPAH_MOBILE') {
      login_destination = this.productService.login_destination;
    }
    if (login_destination === 'PAYHERO_SHIFT') {
      new_tab = false;
    }
    const product: ProductValue = this.productService.getProductFromDestination(login_destination);

    url = this.generateProductURL(login_destination);

    if (url) {
      url = `${url}loginExternal${this.getURLParams(product, login_destination, external_state_name, external_state_params)}`;
    }
    if (external_state_name === 'crowdhire') {
      window.location.href = 'droppahmobile://mobile.droppah.com?' + url;
      setTimeout(function () {
        window.location.href = url;
      }, 300);
    } else {
      if (url) {
        if (new_tab) {
          window.open(url);
        } else {
          window.location.href = url;
        }
      } else {
        this.authService.logout(AuthService.fallbackAuthError);
      }
    }

  }

  integrateWithAccount(account: Account) {
    if (this.authService.integration_redirect_flag) {
      const redirectURI = this.generateAccountIntegrationRedirectUri(account);

      // Clear the integration redirect session storage so it doesn't interfere if the user returns back to the aub app in the same session
      this.authService.integration_product = null;
      this.authService.integration_redirect = null;

      this.setWindowLocation(redirectURI);
    }
  }

  generateAccountIntegrationRedirectUri(account: Account) {
    const integrate_from: ProductValue = (this.authService.integration_redirect?.system as ProductValue) || null;
    const integrate_with: ProductValue = this.authService.integration_product;
    const integration_redirect = this.authService.integration_redirect;

    let uri = `${integration_redirect.uri}?state=${integration_redirect.state}`;

    // 26/06/2023:  Removed the specific checks regarding context - no need. If we've supplied either of these
    //              below fields then append them to redirect.
    if (integration_redirect.refresh_token) {
      uri += `&refresh_token=${integration_redirect.refresh_token}`;
    }

    if (integration_redirect.verification_token) {
      uri += `&verification_token=${integration_redirect.verification_token}`;
    }

    return uri;
  }

  getURLParams(
    product: ProductValue,
    login_destination: DestinationValue,
    external_state_name: string = null,
    external_state_params: any = null,
  ): string {

    const intercom_hash = product === 'KARMLY' ? this.stateDataService.getCachedLocalData('karmly_intercom_hash') : this.stateDataService.getCachedLocalData('intercom_hash');
    const params: any = {
      session_key: this.authService.external_session_key,
      user_access_company_key: this.authService.external_user_access_company_key,
      return_session_key: this.authService.session_key, // token to allow the external app to get back into the login app
      intercom_hash: intercom_hash,
      external_user_access_key: this.authService.user_access_key // used in droppah for multi-company auth
    };

    switch (product) {
      case 'PAYHERO':
        params.company_code = this.authService.external_company_key;
        break;
      default:
        if (login_destination !== 'DROPPAH_MOBILE') {
          params.company_key = this.authService.external_company_key;
        }
    }

    if (!!external_state_name) {
      params.state_name = external_state_name;
      params.state_params = external_state_params;
    }

    return '?urlParams=' + Base64.encode(JSON.stringify(params));
  }


  private generateProductURL(login_destination: DestinationValue = null): string {
    login_destination = login_destination || this.productService.login_destination;

    const product: ProductValue = this.productService.getProductFromDestination(login_destination);
    const product_lower = product.toLowerCase();

    switch (product) {
      case 'PAYHERO':
        switch (login_destination) {
          case 'PAYHERO_APP':
            return env[product_lower].app_url;
          case 'PAYHERO_PORTAL':
            return env[product_lower].portal_url;
          case 'PAYHERO_MOBILE':
            return env[product_lower].mobile_url;
          case 'PAYHERO_SHIFT':
            return env[product_lower].shift_url;
          default:
            return null;
        }
      case 'DROPPAH':
        switch (login_destination) {
          case 'DROPPAH_APP':
            return env[product_lower].app_url;
          case 'DROPPAH_MOBILE':
            return env[product_lower].mobile_url;
        }
        break;
      case 'KARMLY':
        return env[product_lower].app_url;
      case 'INVOXY':
        switch (login_destination) {
          case 'INVOXY_APP':
            return env[product_lower].app_url;
          case 'INVOXY_MOBILE':
            return env[product_lower].mobile_url;
          default:
            return null;
        }
      default:
        return null;
    }
  }

  setWindowLocation(URL: string) {
    window.location.href = URL;
  }


}
