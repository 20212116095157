import { RedirectService } from './../../../services/redirect/redirect.service';
import { ProductService } from './../../../services/product/product.service';
import { StateService } from '@uirouter/core';
import { ModalService } from './../../../services/modal/modal.service';
import { PartnerService } from 'src/app/services/partner/partner.service';
import { Component, OnInit } from '@angular/core';

import { AccountService } from './../../../services/account/account.service';
import { UserService } from './../../../services/user/user.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { Account } from '../../../models/account/account';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-partner-dash',
  templateUrl: './account-partner-dash.component.html',
  styleUrls: ['./account-partner-dash.component.scss']
})
export class AccountPartnerDashComponent implements OnInit {

  loading: boolean;
  readonly is_partner: boolean = this.stateAccessService.isPartner;
  readonly has_subscription: boolean = this.userService.has_subscription;

  showAddAccountButton: boolean = false;

  partnerAccounts: Account[] = [];

  event_subscriptions: Subscription[] = [];

  constructor(
    public stateAccessService: StateAccessService,
    public userService: UserService,
    public accountService: AccountService,
    public partnerService: PartnerService,
    public modalService: ModalService,
    public stateService: StateService,
    public productService: ProductService,
    public redirectService: RedirectService
  ) { }

  ngOnInit(): void {
    this.updateAccounts();
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  updateAccounts() {
    this.partnerAccounts = this.partnerService.getAllPartnerAccounts(
      ['EXCLUDE_CANCELLED', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS'], null, true
    );
  }

  newAccount() {
    this.modalService.addAccountModal()
      .then((data) => {
        this.loading = true;

        this.accountService.createAccount(
          data.company_name,
          null,
          data.product_value,
          data.number_of_employees,
          data.region,
          data.industry_key,
          data.business_type,
          data.nzbn,
          data.industry_classification,
          data.ird_number,
          true,
          true,
          data.sub_agreement_flag,
          false,
          data.contact_phone
        )
          .finally(() => {
            this.updateAccounts();
            this.loading = false;
          });

      })
      .catch(() => this.updateAccounts());
  }

  subscribe(account: Account) {
    this.stateService.go('app.subscription.dash', {
      action: 'subscribe',
      company_product_key: account.company_product_key
    });
  }

  selectAccount(account: Account) {
    if (account.allow_user_access) {
      this.signIn(account);
    }
    else if (account.account_subscription?.show_subscribe_button) {
      this.subscribe(account);
    }
    else if (!account.cancelled_flag && account.trial_expired_flag) {
      this.modalService.errorModal(
        'Trial Expired',
        '<div>The subscription for this account is managed by ' +
        account.subscription_billing_name + ' ' +
        '(' + account.subscription_billing_email + ')</div>'
      );
    }
  }

  signIn(
    account: Account,
    external_state_name: string = null,
  ) {
    this.loading = true;

    if (!account) {
      this.loading = false;
      return;
    }

    if (account.allow_user_access) {
      this.productService.startNewSession(account.company_product_key, account.product_value)
        .then((session) => {
          // this._setRecentProductLogin(account.product_value, account.company_product_key);
          this.redirectService.redirectToProduct(session.login_source, this.is_partner, external_state_name);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    }
    else {
      this.loading = false;
      this.modalService.errorModal('Access to ' + (account.company_name ? account.company_name : 'this account') + ' is not allowed');
    }
  }

  viewSubscription(account: Account) {
    this.stateService.go('app.subscription.account', {
      company_product_key: account.company_product_key,
      product_value: account.product_value
    });
  }

  private _initEventListeners(): void {
    this.event_subscriptions.push(
      this.accountService.getServiceDataUpdateEvent().subscribe(() => {
        this.updateAccounts();
      })
    );
  }

  private _clearEventListeners(): void {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.event_subscriptions = [];
  }

}
