<div class="steppedProgress-container">
  <div class="steppedProgress-headers">
      <div class="steppedProgress-header -desktop-only"
           *ngFor="let step of steps; index as i"
           [ngStyle]="{ 'flex-basis': (100 / steps.length) + '%' }"
           [ngClass]="{
              'completed': currentStep >= i,
              'selected': currentStep === i
           }">
           {{(i + 1) + '. ' + step}}
      </div>
      <div class="steppedProgress-header -mobile-only completed selected">
           {{(currentStep + 1) + '. ' + steps[currentStep]}}
      </div>
  </div>

  <div class="steppedProgress-bar">
      <div class="steppedProgress-bar spInner"
           [ngStyle]="{ 'width': (((currentStep + 1) / steps.length) * 100) + '%' }">
      </div>
  </div>
</div>
