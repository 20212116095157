import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';

import { Client } from 'src/app/models/client/client';
import { DpListComponent } from 'src/app/components/dp-list/dp-list.component';
import { UserService } from './../../services/user/user.service';
import { ClientPartnerStaff } from './../../models/client-partner-staff/client-partner-staff';
import { Account } from './../../models/account/account';
import { AccountService } from './../../services/account/account.service';
import { DropdownOption } from '../../app.types';

@Component({
  selector: 'client-staff-list',
  templateUrl: './client-staff-list.component.html',
  styleUrls: ['./client-staff-list.component.scss']
})
export class ClientStaffListComponent {

  @ViewChild('clientStaffList') clientStaffList: DpListComponent;

  readonly has_partner_admin_access: boolean = this.userService.has_partner_admin_access;

  private _client: Client = null;
  @Input()
  get client(): Client {
    return this._client;
  }
  set client(client: Client) {
    this._client = client;
    this.client_accounts = this.accountService.getAllAccounts(
      ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO'],
      this._client.client_key
    );
    this.updateStaffAccessMap();
    this.setupStaffDropdownOptions();
  }

  client_accounts: Account[] = [];

  staffAccessMap: Record<number, number>;
  staffDropdownOptions: Record<number, DropdownOption[][]> = null;

  @Output() manageStaffAccountAccess = new EventEmitter();
  @Output() deleteStaff = new EventEmitter();

  constructor(
    public userService: UserService,
    public accountService: AccountService
  ) { }

  reloadVisibleAccounts() {
    setTimeout(() => {
      this.clientStaffList.reloadVisibleItems();
    });
  }

  updateStaffAccessMap() {
    this.staffAccessMap = {};

    for (const cps of this.client.client_partner_staff) {
      this.staffAccessMap[cps.partner_staff.partner_staff_key] = 0;

      for (const account of this.client_accounts) {
        if (cps.account_access.has(account.company_product_key)) {
          this.staffAccessMap[cps.partner_staff.partner_staff_key]++;
        }
      }
    }
  }

  setupStaffDropdownOptions() {
    const options: Record<number, DropdownOption[][]> = {};

    if (this.has_partner_admin_access) {
      for (const cps of this.client.client_partner_staff) {
        const staff_options: DropdownOption[][] = [[], []]

        if (!(this.client.partner_flag && cps.partner_staff.owner_flag)) {
          if (this.client_accounts.length) {
            staff_options[0].push({
              option_value: 'MANAGE',
              option_name: 'Manage Access'
            });
          }
          staff_options[0].push({
            option_value: 'REMOVE',
            option_name: 'Remove Staff Member',
            option_color: '#d9534f'
          });
        }

        options[cps.partner_staff.partner_staff_key] = staff_options;
      }
    }

    this.staffDropdownOptions = options;
  }

  staffDropdownOptionSelected(option: string, client_partner_staff: ClientPartnerStaff) {
    switch (option) {
      case 'MANAGE':
        this.manageStaffAccountAccess.emit({ client_partner_staff });
        break;
      case 'REMOVE':
        this.deleteStaff.emit({ client_partner_staff });
        break;
    }
  }

}
