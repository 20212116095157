import { Component, Input, HostListener, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';

import { StateService } from '@uirouter/angular';
import { CoreUtilService } from './../../services/core-util/core-util.service';
import { Account } from '../../models/account/account';
import { DpListComponent, DpListTableHeader } from './../dp-list/dp-list.component';
import { DropdownOption } from '../../app.types';

@Component({
  selector: 'subscription-account-list',
  templateUrl: './subscription-account-list.component.html',
  styleUrls: ['./subscription-account-list.component.scss']
})
export class SubscriptionAccountListComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setupAccountDropdownOptions();
  }

  @ViewChild('subscriptionAccountList') subscriptionAccountList: DpListComponent;

  // Sorting ////////////
  tableHeaders: DpListTableHeader[] = [
    { label: 'Product', property: 'product_name', desktop_only: true },
    { label: 'Account', property: 'company_name' },
    { label: 'Plan', desktop_only: true },
    { label: 'Details', desktop_only: true },
    { label: 'Discount' },
    { label: 'Monthly Cost' },
    { label: '' }
  ];
  orderProperty: string = this.tableHeaders[1].property;

  accountDropdownOptions: Record<number, DropdownOption[][]> = {};

  account_has_discount_flag: boolean = true;

  private _accounts: Account[] = [];
  @Input()
  set accounts(accounts: Account[]) {
    this._accounts = accounts?.filter(account => {
      return !!account.account_subscription;
    }) || [];
    this.setupAccountDropdownOptions();
  }
  get accounts(): Account[] {
    return this._accounts;
  }

  @Input() isPartnerAccounts: boolean = false;
  @Input() noAccountsMessage: string = null;
  @Input() accountIsVisible: (account: Account) => boolean = () => true;

  @Output() subscribe = new EventEmitter();
  @Output() cancelAccount = new EventEmitter();
  @Output() transferOwnership = new EventEmitter();
  @Output() declineOwnershipTransfer = new EventEmitter();
  @Output() revokeOwnershipTransfer = new EventEmitter();

  constructor(
    public stateService: StateService
  ) { }

  ngOnInit() {
    if (this.isPartnerAccounts) {
      this.tableHeaders[3].label = '';
      this.tableHeaders[4].label = '';
      this.tableHeaders[5].label = '';
    }
    if (!this.accounts.some(account => account.account_subscription.account_subscription_lines
      .some(line => line.discount_percentage && account.account_status != 'ON_TRIAL' // don't show discount on trial accounts
        && account.account_status != 'TRIAL_EXPIRED'))) {
      this.account_has_discount_flag = false;
      this.tableHeaders[4].label = '';
    }
  }

  reloadVisibleAccounts() {
    setTimeout(() => {
      this.subscriptionAccountList.reloadVisibleItems();
    });
  }

  setupAccountDropdownOptions() {
    this.accountDropdownOptions = {};

    for (const account of this.accounts) {
      const account_options = [[], []];

      if (CoreUtilService.is_mobile) {
        if (account.account_actions.has('SUBSCRIBE')) {
          account_options[0].push({
            option_value: 'SUBSCRIBE',
            option_name: 'Subscribe',
            option_color: '#5eb22e'
          });
        }
        if (account.account_actions.has('TRANSFER_RESPOND')) {
          account_options[0].push({
            option_value: 'TRANSFER_RESPOND',
            option_name: 'Respond',
            option_color: '#FFAD3D'
          });
        }
        if (account.account_actions.has('TRANSFER_REVOKE')) {
          account_options[0].push({
            option_value: 'TRANSFER_REVOKE',
            option_name: 'Revoke Subscription Transfer',
            option_color: '#d9534f'
          });
        }
      }
      if (account.account_actions.has('TRANSFER_RESPOND')) {
        account_options[1].push({
          option_value: 'TRANSFER_DECLINE',
          option_name: 'Decline Subscription Transfer',
          option_color: '#d9534f'
        });
      }
      if (account.account_actions.has('CHANGE_PLAN')) {
        account_options[0].push({
          option_value: 'CHANGE_PLAN',
          option_name: 'Change Plan',
          option_color: '#00adef'
        });
      }
      if (account.account_actions.has('CANCEL_ACCOUNT')) {
        account_options[1].push({
          option_value: 'CANCEL_ACCOUNT',
          option_name: 'Cancel Account',
          option_color: '#d9534f'
        });
      }
      if (account.account_actions.has('TRANSFER_START')) {
        account_options[0].push({
          option_value: 'TRANSFER_START',
          option_name: 'Transfer Subscription'
        });
      }

      this.accountDropdownOptions[account.company_product_key] = account_options;
    }
  }

  accountDropdownOptionSelected(option: string, account: Account) {
    switch (option) {
      case 'SUBSCRIBE':
      case 'CHANGE_PLAN':
        this.subscribe.emit({ account });
        break;
      case 'TRANSFER_RESPOND':
        this.subscribe.emit({ account });
        break;
      case 'CANCEL_ACCOUNT':
        this.cancelAccount.emit({ account });
        break;
      case 'TRANSFER_START':
        this.transferOwnership.emit({ account });
        break;
      case 'TRANSFER_DECLINE':
        this.declineOwnershipTransfer.emit({ account });
        break;
    }
  }

  goToSubscriptionAccount(account: Account) {
    if (account.subscribed_flag && !account.cancelled_flag) {
      this.stateService.go('app.subscription.account', {
        company_product_key: account.company_product_key,
        product_value: account.product_value
      });
    }
  }

}
