<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerLeft">
      Add Employee
    </div>

    <progress-bar [current_step]="selected_value"
                  [total_steps]="tab_array.length - 1">
    </progress-bar>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="dismiss()">
    </i>
  </div>

  <!-- EMPLOYEE DETAILS -->

  <div *ngIf="selected_tab === 'DETAILS'">
    <div class="ph-formItem-container -smallForm">
      <div class="ph-formItem">
        <div class="ph-formItem-label">
          First Name
        </div>
        <div class="ph-formItem-value">
          <input type="text"
                placeholder="First Name"
                [(ngModel)]="employee.first_name"
                [ngClass]="{'-invalid': this.errors['first_name']}">
          <i class="bi bi-exclamation-triangle-fill"
                *ngIf="this.errors['first_name']">
          <tooltip-popover
                [tooltipContent]="this.errors['first_name']">
          </tooltip-popover>
          </i>
        </div>
        <div class="ph-formItem-error">
          <!-- Must provide a valid Bank Account Number -->
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Last Name
        </div>
        <div class="ph-formItem-value">
          <input type="text"
                 placeholder="Last Name"
                 [(ngModel)]="employee.last_name"
                 [ngClass]="{'-invalid': this.errors['last_name']}">
          <i class="bi bi-exclamation-triangle-fill"
                *ngIf="this.errors['last_name']">
           <tooltip-popover
                 [tooltipContent]="this.errors['last_name']">
           </tooltip-popover>
           </i>
        </div>
      </div>
    </div>

    <div class="ph-formItem-container -smallForm">
      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Email Address
        </div>
        <div class="ph-formItem-value">
          <input type="text"
                class="-abs"
                placeholder="Email Address"
                [(ngModel)]="employee.email"
                [ngClass]="{ '-invalid': this.errors['email'] || this.errors['portal_invite']}"
                [ngModelOptions]="{ 'updateOn': 'blur' }">
          <i class="bi bi-exclamation-triangle-fill"
                 *ngIf="this.errors['email'] || this.errors['portal_invite']">
          <tooltip-popover
                    [tooltipContent]="this.errors['portal_invite'] ?
                      this.errors['portal_invite'] : this.errors['email']">
          </tooltip-popover>
          </i>
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Invite to use PayHero Portal
          <a href="https://support.payhero.co.nz/hc/en-us/articles/360002749215-Employee-Portal-Overview"
             target="_blank"
             class="icon-link">
            <i class="ion bi-question-circle"></i>
          </a>
        </div>
        <div class="ph-formItem-value">
          <ph-toggle [(ngModel)]="employee.invite_to_portal_flag">
          </ph-toggle>
        </div>
      </div>
    </div>
  </div>

  <!-- PAYMENT DETAILS -->

  <div *ngIf="selected_tab === 'PAYMENT'">
    <div class="ph-formItem-container -smallForm">
      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Employment Start Date
        </div>
        <date-picker [(ngModel)]="employee.employment_start_date"
          class="employmentDate"
          [typing_disabled]="false"
          [placeholder_title]="'dd/mm/yy'"
          [showError]="this.errors['employment_start_date']"
          [errorMessage]="this.errors['employment_start_date']"
          [ngClass]="{'-invalid': this.errors['employment_start_date']}">
        </date-picker>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Bank Account Number
        </div>
        <div class="ph-formItem-value">
          <bank-account-input #bankAccountInput
            [(ngModel)]="employee.bank_acc"
            [inputLabel]="'Bank Account'"
            [clearErrorsOnClick]="false"
            [inlineErrorStyling]="true">
          </bank-account-input>
        </div>
      </div>
    </div>

    <div class="ph-formItem-container -smallForm">
      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Payment Basis
        </div>
        <div class="ph-formItem-value">
          <radio-button [(ngModel)]="!!employee.salary_flag"
                        (ngModelChange)="salaryFlagChanged()"
                        [invertModel]="true"
                        [label]="'Hourly'">
          </radio-button>
          <radio-button [(ngModel)]="!!employee.salary_flag"
                        (ngModelChange)="salaryFlagChanged()"
                        [label]="'Salary'">
          </radio-button>
        </div>
      </div>

      <div class="ph-formItem"
           *ngIf="!employee.salary_flag">
        <div class="ph-formItem-label">
          Hourly Rate
        </div>
        <div class="input-group">
          <div class="input-group-addon">
            $
          </div>
          <div class="ph-formItem-value">
            <input type="number"
                  placeholder="Normal Rate"
                  [(ngModel)]="employee.hourly_rate"
                  [ngClass]="{'-invalid': this.errors['pay_rate'] || this.errors['hourly']}">
            <i class="bi bi-exclamation-triangle-fill"
                  *ngIf="this.errors['pay_rate'] || this.errors['hourly']">
              <tooltip-popover
                  [tooltipContent]="this.errors['pay_rate'] || this.errors['hourly']">
              </tooltip-popover>
            </i>
          </div>
        </div>
      </div>

      <div class="ph-formItem"
           *ngIf="!!employee.salary_flag">
        <div class="ph-formItem-label">
          Annual Salary
        </div>
        <div class="input-group">
          <div class="input-group-addon">
            $
          </div>
          <div class="ph-formItem-value">
            <input type="number"
                  placeholder="Annual Salary"
                  [(ngModel)]="employee.annual_salary"
                  [ngClass]="{'-invalid': this.errors['pay_rate'] || this.errors['salary']}">
            <i class="bi bi-exclamation-triangle-fill"
                *ngIf="this.errors['pay_rate'] || this.errors['salary']">
              <tooltip-popover
                [tooltipContent]="this.errors['pay_rate'] || this.errors['salary']">
              </tooltip-popover>
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- WORK DETAILS -->

  <div *ngIf="selected_tab === 'WORK'">
    <div class="ph-formItem-container -fullWidth">
      <div class="ph-formItem -fullWidth">
        <div class="ph-formItem-label">
          Work Pattern
          <a href="https://support.payhero.co.nz/hc/en-us/articles/360002750395-Employee-Work-Pattern"
             target="_blank"
             class="icon-link">
            <i class="ion bi-question-circle"></i>
          </a>
        </div>
        <div class="ph-formItem-value -workPattern" *ngIf="work_patterns.length">
          <list-drop-down [buttonTitle]="selected_work_pattern.title"
                          [buttonDescription]="selected_work_pattern.description"
                          [buttonColorClass]="'-color-white-outline'"
                          [buttonTypeClass]="'-type-dropdown'"
                          [buttonIconClass]="'ion-md-arrow-dropdown'"
                          [listFillWidth]="true"
                          [itemNameProperty]="'title'"
                          [itemDescriptionProperty]="'description'"
                          [itemDescriptionWrap]="true"
                          [sortItems]="false"
                          [items]="work_patterns"
                          (itemSelected)="selectWorkPattern($event.item)">
          </list-drop-down>
        </div>
      </div>

      <!-- REGULAR WEEK -->

      <div class="days"
           *ngIf="selected_work_pattern.value === 'REGULAR_WEEK'">
        <div class="ph-formItem -day -regularWeek"
             *ngFor="let hours_day of hours_per_day">
          <div class="ph-formItem-label -day -regularWeek">
            {{hours_day.day}}
            <i class="bi bi-exclamation-triangle-fill -noMargin"
                *ngIf="employee[hours_day.property] < 0 || employee[hours_day.property] > 24">
              <tooltip-popover
                  [tooltipContent]="'Invalid hours'">
              </tooltip-popover>
            </i>
          </div>
          <div class="ph-formItem-value -day">
            <input type="number"
                   [(ngModel)]="employee[hours_day.property]"
                   (ngModelChange)="hoursUpdated()"
                   [ngClass]="{'-invalid': employee[hours_day.property] < 0 || employee[hours_day.property] > 24}"
                   onclick="this.select()">
          </div>
        </div>
      </div>

      <!-- REGULAR DAYS & HOURS -->

      <div class="days"
           *ngIf="selected_work_pattern.value === 'REGULAR_DAYS_HOURS'">
        <div class="ph-formItem">
          <div class="ph-formItem-label -day">
            Days worked per week
          </div>
          <div class="ph-formItem-value -units">
          <input class=""
                type="number"
                [(ngModel)]="employee.days_per_week"
                (ngModelChange)="hoursUpdated()"
                [ngClass]="{'-invalid': (!employee.days_per_week || employee.days_per_week <= 0 ||
                employee.days_per_week > 7) && next_pressed}"
                onclick="this.select()">
          <i class="bi bi-exclamation-triangle-fill"
                *ngIf="(!employee.days_per_week || employee.days_per_week <= 0 ||
                employee.days_per_week > 7) && next_pressed">
            <tooltip-popover
                [tooltipContent]="'Invalid days per week'">
            </tooltip-popover>
          </i>
          <span class="input-units">Days</span>
        </div>
        </div>

        <div class="ph-formItem">
          <div class="ph-formItem-label -day">
            Hours worked per day
          </div>
          <div class="ph-formItem-value -units">
            <input type="number"
                   [(ngModel)]="employee.hours_per_day"
                   (ngModelChange)="hoursUpdated()"
                   [ngClass]="{'-invalid': (!employee.hours_per_day || employee.hours_per_day <= 0 ||
                   employee.hours_per_day > 24) && next_pressed}"
                   onclick="this.select()">
            <i class="bi bi-exclamation-triangle-fill"
                  *ngIf="(!employee.hours_per_day || employee.hours_per_day <= 0 ||
                  employee.hours_per_day > 24) && next_pressed">
              <tooltip-popover
                  [tooltipContent]="'Invalid hours per day'">
              </tooltip-popover>
            </i>
            <span class="input-units">Hours</span>
          </div>
        </div>
      </div>

      <!-- NO REGULAR HOURS -->

      <div class="days"
           *ngIf="selected_work_pattern.value === 'NO_REGULAR_HOURS'">
        <div class="ph-formItem -onlyChild">
          <div class="ph-formItem-label -day">
            Days worked per week
          </div>
          <div class="ph-formItem-value -units">
            <input type="number"
                   [(ngModel)]="employee.days_per_week"
                   (ngModelChange)="hoursUpdated()"
                   [ngClass]="{'-invalid': (!employee.days_per_week || employee.days_per_week > 7 ||
                   employee.days_per_week <= 0 || employee.salary_flag) && next_pressed}"
                   onclick="this.select()">
            <i class="bi bi-exclamation-triangle-fill"
                  *ngIf="(!employee.days_per_week || employee.days_per_week > 7 ||
                  employee.days_per_week <= 0 || employee.salary_flag) && next_pressed">
              <tooltip-popover
                  [tooltipContent]="employee.salary_flag ? 'Cannot have no regular hours on salary' :
                  'Invalid days per week'">
              </tooltip-popover>
            </i>
            <span class="input-units">Days</span>
          </div>
        </div>
      </div>

      <!-- NO REGULAR DAYS -->

      <div class="days"
           *ngIf="selected_work_pattern.value === 'NO_REGULAR_DAYS'">
        <div class="ph-formItem -onlyChild">
          <div class="ph-formItem-label -day">
            Hours worked per week
          </div>
          <div class="ph-formItem-value -units">
            <input type="number"
                   [(ngModel)]="employee.normal_hours_per_week"
                   (ngModelChange)="hoursUpdated()"
                   [ngClass]="{'-invalid': (!employee.normal_hours_per_week ||
                   employee.normal_hours_per_week > 168 || employee.normal_hours_per_week <= 0) && next_pressed}"
                   onclick="this.select()">
            <i class="bi bi-exclamation-triangle-fill"
                *ngIf="!employee.validateEmployeeWorkPattern() && next_pressed">
              <tooltip-popover
                  [tooltipContent]="'Invalid hours per week'">
              </tooltip-popover>
            </i>
            <span class="input-units">Hours</span>
          </div>
        </div>
      </div>

      <!-- NO REGULAR PATTERN -->

      <div class="days -noPattern"
           *ngIf="selected_work_pattern.value === 'NO_PATTERN'">
        <div class="workPatternInfo">
          <i class="ion ion-md-information-circle"></i>
          <div class="workPatternText">
            Work patterns help PayHero correctly allocate your employees' leave entitlements.
            Unless this employee genuinely has no regular week, please choose another work pattern.
            <a href="https://support.payhero.co.nz/hc/en-us/articles/360002750395-Employee-Work-Pattern"
               target="_blank"
               class="icon-link">
              Learn more <i class="ion ion-md-open"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="days-total">
        <i class="bi bi-exclamation-triangle-fill -noMargin"
              *ngIf="employee.getNumDaysWithHours() === 0 && next_pressed &&
              selected_work_pattern.value === 'REGULAR_WEEK'">
          <tooltip-popover
              [tooltipContent]="'Invalid work pattern'">
          </tooltip-popover>
        </i>
        <div class="total"
             *ngIf="selected_work_pattern.value === 'REGULAR_WEEK'">
          Total Days: <span>{{employee.days_per_week}}</span>
        </div>
        <div class="total"
          *ngIf="selected_work_pattern.value === 'REGULAR_WEEK' || selected_work_pattern.value === 'REGULAR_DAYS_HOURS'">
          Total Hours: <span>{{employee.normal_hours_per_week}}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- TAX DETAILS -->

  <div *ngIf="selected_tab === 'TAX'">
    <div class="ph-formItem-container -smallForm">
      <div class="ph-formItem">
        <div class="ph-formItem-label">
          IRD Number
        </div>
        <div class="ph-formItem-value">
          <input type="text"
                 placeholder="000-000-000"
                 [mask]="'000-000-000'"
                 [(ngModel)]="employee.ird_number"
                 [ngClass]="{'-invalid': this.errors['ird_number']}">
          <i class="bi bi-exclamation-triangle-fill"
              *ngIf="this.errors['ird_number']">
            <tooltip-popover
                [tooltipContent]="this.errors['ird_number']">
            </tooltip-popover>
          </i>
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Tax Code
        </div>
        <div class="ph-formItem-value">
          <list-drop-down [buttonTitle]="employee.tax_code"
                          [buttonColorClass]="'-color-white-outline'"
                          [buttonTypeClass]="'-type-dropdown'"
                          [buttonIconClass]="'ion-md-arrow-dropdown'"
                          [buttonTitlePlaceholder]="'Select Tax Code'"
                          [listHeight]="'300px'"
                          [listFillWidth]="true"
                          [sortItems]="false"
                          [items]="tax_codes"
                          (itemSelected)="selectTaxCode($event.item)"
                          [ngClass]="{'-invalid': this.errors['tax_code']}"
                          [showError]="this.errors['tax_code']"
                          [errorMessage]="this.errors['tax_code']"
                          [errorIconSize]="'var(--bs-body-font-size)'">
          </list-drop-down>
        </div>
      </div>
    </div>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-clear ph-modal-btnLeft"
            *ngIf="selected_tab !== 'DETAILS'"
            (click)="back()">
      Back
    </button>
    <button class="-color-payhero ph-modal-btnRight"
            *ngIf="selected_tab !== 'TAX'"
            (click)="next()">
        <span>Next</span>
    </button>
    <button class="-color-payhero ph-modal-btnRight"
            *ngIf="selected_tab === 'TAX'"
            (click)="close()">
        Finish
    </button>
  </div>

</div>
