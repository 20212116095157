import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenInvoxyComponent } from './token-invoxy.component';
import { ApprovalRequestComponent } from './approval-request/approval-request.component';
import { UIRouterModule } from '@uirouter/angular';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';


export const TOKEN_INVOXY_CONFIG: any[] = [
  {
    name: 'tokenInvoxy',
    url: '/token/invoxy',
    component: TokenInvoxyComponent,
    abstract: true
  },
  {
    name: 'tokenInvoxy.aprovalRequest',
    url: '/approvalRequest?token&domain&approved',
    component: ApprovalRequestComponent,
    params: {
      token: null,
      domain: null,
      approved: null
    }
  }
];


@NgModule({
  declarations: [
    TokenInvoxyComponent,
    ApprovalRequestComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild({ states: TOKEN_INVOXY_CONFIG })
  ]
})
export class TokenInvoxyModule { }
