<div class="login-logo-container">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_White.svg">
</div>

<div class="ph-autoPilot-card"
     *ngIf="loading">
  <div class="ph-loadingIcon">
    <img src="assets/imgs/App_Loader.gif">
  </div>
</div>

<div class="ph-autoPilot-card ph-ap-payment-container"
     *ngIf="!loading && !error_message && !success">

  <div class="ph-ap-payment-header">
    <div class="headerCenter">Pay with Direct Debit</div>
  </div>

  <div class="ph-ap-payment-container">
    <div class="directDebitDelay">
      <div [innerHTML]="pay_message | safeHtml">
      </div>
      <div *ngIf="!pay_details.direct_debit_verified_flag">
        You will be contacted <span *ngIf="pay_details.direct_debit_contact_phone">on
          <b>{{pay_details.direct_debit_contact_phone}}</b></span> to verify your bank account.
      </div>
    </div>
  </div>

  <div class="ph-autoPilot-details"
       [ngStyle]="{'opacity': loading ? '0.3':''}">

    <div class="ph-autoPilot-details-grid">
      <div>
        <p class="ph-text-grey">Pay Cycle:</p>
        <h2>{{pay_details.pay_cycle_name}}</h2>
      </div>
      <div>
        <p class="ph-text-grey">Pay Period:</p>
        <h2>{{pay_details.start_date | date: 'd'}} - {{pay_details.end_date | date: 'd MMM,'}} <span
                class="ph-text-grey">{{pay_details.end_date | date: 'yyyy'}}</span></h2>
      </div>
      <div>
        <p class="ph-text-grey">Pay Date:</p>
        <h2>{{pay_details.pay_date | date: 'd MMM,'}} <span class="ph-text-grey">{{pay_details.pay_date | date:
            'yyyy'}}</span></h2>
      </div>
      <div>
        <p class="ph-text-grey">Total Payroll:</p>
        <h2>{{pay_details.gross_total | appCurrency}}</h2>
      </div>
    </div>

    <button class="-color-success"
            *ngIf="!success"
            [disabled]="loading"
            (click)="payNow()">
      Pay Now
    </button>
  </div>
</div>

<div class="ph-autoPilot-card ph-autoPilot-success-state"
     *ngIf="!loading && !!success">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_Success.png">
  <h1><strong><span style="color: #5eb22e">Payment Successful!</span></strong></h1>
  <p (click)="loginToPayHeroPay()"><a>View Pay</a></p>
</div>

<!-- The token is no longer valid -->
<div class="ph-autoPilot-card ph-autoPilot-error-state"
     *ngIf="!loading && !!error_message && !success">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_Failed.png">
  <h3>{{error_message}}</h3>
  <p (click)="loginToPayHero()"><a>Sign In to PayHero</a></p>
</div>


<footer class="ph-footer ph-auth-footer -desktop-only">
  <img src="assets/product-imgs/payhero/PayHero_Full_Registered.svg">
</footer>
