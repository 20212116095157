import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'ph-toggle',
  templateUrl: './ph-toggle.component.html',
  styleUrls: ['./ph-toggle.component.scss'],
  providers: [MakeProvider(PhToggleComponent)]
})
export class PhToggleComponent extends AbstractValueAccessor {

  @HostBinding('class.ph-toggled') _value: boolean;

  @Input() disableInternalToggle: boolean = false;
  @Input() trueValueColour: string = null;
  @Input() falseValueColour: string = null;

  @HostListener('click', ['$event'])
  handleClick() {
    if (!this.disableInternalToggle) {
      this.value = !this.value;
    }
  }

}
