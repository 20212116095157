import { BrowserModule } from '@angular/platform-browser';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterModule, UIRouter } from '@uirouter/angular';
import { NgModule, Injector } from '@angular/core';
import { DatePipe } from '@angular/common';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { NgbParserFormatterDDMMYYYY } from './classes/ngb-parser-formatter-ddmmyyyy/ngb-parser-formatter-ddmmyyyy';

import { StateChangeService } from './services/state-change/state-change.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';


import { ModalsModule } from './modals/modals.module';
import { PipesModule } from './pipes/pipes.module';
import { TokenModule } from './pages/token/token.module';
import { TokenPayheroModule } from './pages/token-payhero/token-payhero.module';
import { TokenInvoxyModule } from './pages/token-invoxy/token-invoxy.module';
import { TokenDroppahModule } from './pages/token-droppah/token-droppah.module';
import { LoginModule } from './pages/login/login.module';
import { ProfileModule } from './pages/profile/profile.module';
import { AccountModule } from './pages/account/account.module';
import { ComponentsModule } from './components/components.module';
import { CoreModule } from './pages/core/core.module';
import { SubscriptionModule } from './pages/subscription/subscription.module';
import { ClientModule } from './pages/client/client.module';
import { StaffModule } from './pages/staff/staff.module';
import { PartnerModule } from './pages/partner/partner.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function UIRouterConfig(router: UIRouter, injector: Injector) {
  // If no URL matches, go to the product select state by default
  router.urlService.rules.otherwise({ state: 'login' });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    ModalsModule,
    PipesModule,
    TokenModule,
    TokenPayheroModule,
    TokenInvoxyModule,
    TokenDroppahModule,
    LoginModule,
    ProfileModule,
    AccountModule,
    PartnerModule,
    ComponentsModule,
    CoreModule,
    SubscriptionModule,
    ClientModule,
    StaffModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(options),
    UIRouterModule.forRoot({
      useHash: true,
      config: UIRouterConfig
    })
  ],
  providers: [
    DatePipe,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbParserFormatterDDMMYYYY }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private stateChangeService: StateChangeService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.stateChangeService.initTransitionListeners();
    this.googleAnalyticsService.init();
  }
}
