<div class="app-nav">
  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          User Access
        </div>
      </div>
      <div class="app-nav-rowRight">
      </div>
    </div>
  </div>

  <div class="app-nav-content">

    <div class="dp-window">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="ph-formItem-container -smallForm">

        <!-- <div class="ph-formItem">
          <div class="ph-formItem-label">
            Profile Picture
          </div>
          <div *ngIf="!currentUser.profile_picture" class="ph-formItem-value -empty">
            -
          </div>

          <img class="profile-image" *ngIf="currentUser.profile_picture" [src]="currentUser.profile_picture">

        </div> -->

        <div class="ph-formItem">
          <div class="ph-formItem-label">
            Contact Name
          </div>
          <div class="ph-formItem-value"
               [ngClass]="{'-empty': !currentUser.full_name }">
            {{currentUser.full_name || '-'}}
          </div>
        </div>



        <div class="ph-formItem">
          <div class="ph-formItem-label">
            Mobile
          </div>
          <div class="ph-formItem-value"
               [ngClass]="{'-empty': !currentUser.contact_phone }">
            {{currentUser.contact_phone || '-'}}
          </div>
        </div>

        <div class="ph-formItem">
          <div class="ph-formItem-label">
            Login Email
          </div>
          <div class="ph-formItem-value"
               [ngClass]="{'-empty': !currentUser.registered_email }">
            {{currentUser.registered_email || '-'}}
          </div>
          <div class="ph-formItem-note"
               *ngIf="currentUser.verification_email">
            A verification email has been sent to {{currentUser.verification_email}}. <br>
            Please check your inbox to finish updating your login email.
          </div>
        </div>

        <div class="ph-formItem">
          <div class="ph-formItem-label">
            Login Username
          </div>
          <div class="ph-formItem-value"
               [ngClass]="{'-empty': currentUser.username === currentUser.registered_email }">
            {{currentUser.username !== currentUser.registered_email ? currentUser.username : '-'}}
          </div>
        </div>

      </div>

      <div class="app-buttonRow">
        <button class="-type-text -color-payhero-clear"
                (click)="editProfileDetails()">
          Edit
        </button>


      </div>


      <div class="-divider"></div>

      <div class="ph-formItem-container -smallForm">

        <div class="ph-formItem">
          <div class="ph-formItem-label">
            Password
          </div>
          <div class="ph-formItem-value">
            <button class="-color-link-clear -type-text"
                    (click)="changePassword()">
              {{currentUser.password_set ? 'Change' : 'Set'}} Password
            </button>
          </div>
        </div>

        <div class="ph-formItem">
          <div class="ph-formItem-label">
            Two Factor Authentication
          </div>
          <div class="ph-formItem-value">
            <button class="-color-link-clear -type-text"
                    *ngIf="!currentUser.two_factor_enabled"
                    (click)="twoFactorAuthentication()">
              Set up Two Factor Authentication
            </button>
            <button class="-color-link-clear -type-text"
                    *ngIf="currentUser.two_factor_enabled && !currentUser.two_factor_required"
                    (click)="twoFactorAuthentication()">
              Disable Two Factor Authentication
            </button>
            <div *ngIf="currentUser.two_factor_enabled && currentUser.two_factor_required">
              Two Factor Authentication is required by one of the accounts you have access to.
            </div>
          </div>
        </div>

        <div class="ph-formItem" *ngFor="let integration of loginIntegrations">
          <div class="ph-formItem-label">
            {{integration.integration_name}} Login
          </div>

          <integration-button [loginIntegration]="integration"
                              [customPrefix]="currentUser.login_integrations.has(integration.integration_type) ? 'Disconnect from' : 'Connect to'"
                              (buttonPressed)="updateIntegration(integration.integration_name, currentUser.login_integrations.has(integration.integration_type))"
                              *ngIf="!currentUser.login_integrations.has(integration.integration_type) || currentUser.password_set">
          </integration-button>

          <ng-container *ngIf="!currentUser.password_set && currentUser.login_integrations.has(integration.integration_type)">
            <div class="ph-formItem-value">
              <b>Connected</b>
            </div>
            <div class="ph-formItem-value">
              Set a password to disconnect your {{integration.integration_name}} Account
            </div>
          </ng-container>
        </div>

      </div>

    </div>
  </div>
