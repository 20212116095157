import { UserService } from './../../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserAccess } from './../../models/user-access/user-access';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  current_user: UserAccess = null;

  old_password: string = '';
  new_password: string = '';
  new_password_confirm: string = '';

  form_field_errors: Record<string, string> = {};

  constructor(
    private activeModal: NgbActiveModal,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.current_user = this.userService.user_access;
  }

  close() {
    this.activeModal.dismiss();
  }

  save() {
    if (this.validatePassword()) {
      this.activeModal.close({
        old_password: this.old_password,
        new_password: this.new_password
      });
    }
  }

  validatePassword(): boolean {
    this.form_field_errors = {};

    if (this.current_user.password_set && !this.old_password) {
      this.form_field_errors['old_password'] = 'Old password required';
    }
    if (!this.new_password) {
      this.form_field_errors['new_password'] = 'New password required';
    }
    if (!this.new_password_confirm) {
      this.form_field_errors['new_password_confirm'] = 'New password confirmation required';
    }
    if (!!this.new_password && !!this.new_password_confirm && this.new_password !== this.new_password_confirm) {
      this.form_field_errors['new_password_confirm'] = 'Passwords don\'t match';
    }

    return Object.keys(this.form_field_errors).length === 0;
  }

  clearFormFieldError(field_name: string): void {
    this.form_field_errors[field_name] = null;
  }

}
