import { PostableObjectInterface, PostableObjectError } from './../../interfaces/postable-object.inerface';

export class ClientNote implements PostableObjectInterface {

  client_note_key: number;
  client_key: number;
  note: string;

  constructor (
    client_note_key: number = null, client_key: number = null, note: string = null
  ) {
    this.client_note_key = client_note_key;
    this.client_key = client_key;
    this.note = note;
  }

  formatForPosting(toDelete: boolean = false): any {
    if (!this.hasErrors()) {
      return {
        client_note_key: this.client_note_key,
        client_key: this.client_key,
        note: this.note,
        deleted_flag: toDelete
      };
    }
    return null;
  }

  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  getErrors(): PostableObjectError[] {
    const errors: PostableObjectError[] = [];
    return errors;
  }
}
