import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'staff-role-picker',
  templateUrl: './staff-role-picker.component.html',
  styleUrls: ['./staff-role-picker.component.scss']
})
export class StaffRolePickerComponent {

  @Input() adminFlag: boolean;
  @Output() adminFlagChange = new EventEmitter<boolean>();

}
