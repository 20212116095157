import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-windcave-prompt-modal',
  templateUrl: './windcave-prompt-modal.component.html',
  styleUrls: ['./windcave-prompt-modal.component.scss']
})
export class WindcavePromptModalComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
    public stateService: StateService
  ) { }

  ngOnInit(): void {
  }


  confirm() {
    this.stateService.go('app.subscription.billing', {
      openPaymentModal: true
    });
    this.activeModal.close(true);
  }


  cancel() {
    this.activeModal.dismiss();
  }

}
