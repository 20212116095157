<div class="ph-modal-content">
  <div class="ph-modal-header">
    <span class="ion ion-ios-card">
    </span>
    <div class="headerCenter">
      {{title}}
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="cancel()">
    </i>
  </div>

  <div class="ph-modal-container">

    <paystation-card-info #paystationCardInfo
                          *ngIf="subscription.payment_method === 'CARD'"
                          (error)="setErrorMessage($event.error)">

    </paystation-card-info>
    <bank-edit #bankEdit
               *ngIf="subscription.payment_method === 'DIRECTDEBIT'"
               [subscription]="subscription"
               [current_step]="currentDDStep">
    </bank-edit>

  </div>

  <div class="app-errorMessage"
       [ngClass]="{ '-visible': !!error_message }">
    <i class="ion ion-ios-warning sm-icon"></i>
    {{error_message}}
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="cancel()">
      Cancel
    </button>

    <button class="-color-flexitime ph-modal-btnRight"
            *ngIf="subscription.payment_method === 'DIRECTDEBIT' && currentDDStep === 'FORM'"
            [disabled]="loading"
            (click)="goToDDStep('TERMS')">
      Next
    </button>


    <button class="-color-white-outline ph-modal-btnLeft"
            *ngIf="subscription.payment_method === 'DIRECTDEBIT' && currentDDStep === 'TERMS'"
            [disabled]="loading"
            (click)="goToDDStep('FORM')">
      Back
    </button>


    <button class="-color-success ph-modal-btnRight"
            *ngIf="(subscription.payment_method === 'DIRECTDEBIT' && currentDDStep === 'TERMS')"
            [disabled]="loading"
            (click)="updatePaymentMethod()">
      {{update_button_label}}
    </button>

    <div class=" ph-modal-btnRight -spinnerContainer"
         *ngIf="loading && subscription.payment_method !== 'CARD'">
      <spinner>
      </spinner>
    </div>
  </div>
</div>
