import { Injectable } from '@angular/core';
import { ProductService } from '../product/product.service';

import { DbUtilService } from './../db-util/db-util.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private dbUtilService: DbUtilService,
    private productService: ProductService
  ) {

  }

  accessSupport(return_to: string = null, view_requests: boolean = false, new_tab: boolean = false) {
    const params: any = {
      product_name: this.productService.current_product_name
    };

    if (view_requests) params.view_requests = 'true';
    if (return_to) params.return_to = return_to;


    this.dbUtilService.APIGet('zendesk/ssoURL', params, true, 'text')
      .then((res) => {
        const data = JSON.parse(res);
        if (!!data.fallback) {
          this.redirect(new_tab, data.fallback);
        } else {
          const input = document.getElementById('jwtInput') as HTMLInputElement;
          input.value = data.jwt;
          document.forms['jwtForm'].action = data.link;
          document.forms['jwtForm'].submit();
        }
      })
      .catch(() => {
        this.redirect(new_tab, return_to ? return_to : 'https://www.flexitime.works/support');
      });
  }

  redirect(new_tab: boolean, url: string) {
    if (!new_tab) {
      window.location.href = url;
    }
    else {
      window.open(url, '_blank');
    }
  }
}
