<div class="logoEditor-empty"
     *ngIf="has_partner_admin_access && !logo"
     (dragover)="$event.preventDefault()"
     (drop)="$event.preventDefault(); updateLogo.emit({ files: $event.dataTransfer.files })"
     (click)="editLogo()">

  <span>Click or drag file here to add a logo</span>
  <input #logoSelect
         type="file"
         accept="image/png"
         (change)="updateLogo.emit({ files: $event.target.files })">
</div>

<div class="logoEditor-imgContainer"
     *ngIf="!!logo"
     (click)="editLogo()">
  <div class="logoEditor-img">

    <img [src]="logo">

    <input #logoSelect
           *ngIf="has_partner_admin_access"
           type="file"
           accept="image/png"
           (change)="updateLogo.emit({ files: $event.target.files })">
  </div>
</div>

<div class="logoEditor-edit"
     *ngIf="has_partner_admin_access && !!logo"
     (click)="editLogo()">
  <i class="ion ion-md-create"></i>
</div>
