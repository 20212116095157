import { Component, Renderer2, ViewContainerRef } from '@angular/core';
import { ProductService } from './services/product/product.service';

import { env } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public renderer: Renderer2,
    public viewContainerRef: ViewContainerRef,
    public productService: ProductService
  ) {
    this._initBodyAppVersion();
    this._updateThemeClass();

    this.productService.subscribeToEvent('THEME_UPDATED', () => {
      setTimeout(() => this._updateThemeClass());
    });
  }

  private _removeExistingThemesFromBody() {
    document.body.classList.forEach((c) => {
      if (c.indexOf('-theme-') === 0) {
        this.renderer.removeClass(document.body, c);
      }
    });
  }

  private _updateThemeClass() {
    this._removeExistingThemesFromBody();
    const theme_class = '-theme-' + this.productService.current_product.toLowerCase();
    this.renderer.addClass(document.body, theme_class);
  }

  private _initBodyAppVersion(){
    this.renderer.setAttribute(document.body, 'app-version', env.app_version);
  }

}
