<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      {{viewOnly ? '' : note.client_note_key ? 'Edit' : 'Add'}} Note
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div class="ph-modal-container" *ngIf="!viewOnly">
    <textarea [(ngModel)]="note.note"></textarea>
  </div>

  <div class="ph-modal-container" *ngIf="viewOnly">
    {{note.note}}
  </div>

  <div class="ph-modal-btnFooter">
      <button class="-color-danger ph-modal-btnLeft"
              *ngIf="note.client_note_key && !viewOnly"
              (click)="deleteNote()">
          Delete
      </button>
      <button class="-color-white-outline ph-modal-btnRight"
              (click)="close()">
          {{ viewOnly ? 'Close' : 'Cancel'}}
      </button>
      <button class="-color-success ph-modal-btnRight"
              *ngIf="!viewOnly"
              (click)="saveNote()">
          Save
      </button>
  </div>
</div>
