<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          Clients
        </div>
      </div>

      <div class="app-nav-rowRight">
        <mega-search #megaSearch
                     [megaSearchId]="megaSearchId"
                     [megaSearchConfigMap]="megaSearchConfigMap"
                     [disabled]="loading"
                     [inputPlaceholder]="'Search...'"
                     [items]="{ clients: clients, accounts: accounts, ftAccounts: ftAccounts }"
                     (searchUpdated)="megaSearchUpdated()">
        </mega-search>
        <button class="-color-payhero ph-modal-btnRight"
                *ngIf="has_partner_admin_access"
                (click)="addClient()">
          New Client
        </button>
        <list-drop-down [itemNameProperty]="'option_name'"
                        [itemIsToggleableProperty]="'option_is_toggleable'"
                        [itemToggleValueProperty]="'option_toggle_value'"
                        [itemHideProperty]="'option_hidden'"
                        [buttonTypeClass]="'-type-icon'"
                        [buttonIconClass]="'ion-ios-more'"
                        [buttonColorClass]="'-color-grey-clear'"
                        [listWidth]="'300px'"
                        [listAutoClose]="false"
                        [sortItems]="false"
                        [items]="clientDropdownOptions"
                        (itemSelected)="clientDropdownOptionSelected($event.item.option_value)">
        </list-drop-down>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="dp-list clientDash-clientList"
           [ngClass]="{ '-fullHeight': unlinkedPayHeroAccounts.length === 0 && unlinkedDroppahAccounts.length === 0 }">
        <ng-container *ngFor="let client of visibleClients">
          <div class="dp-list-item"
               [ngClass]="{
               '-accountsVisible': !!clientAccountVisibility[client.client_key],
               '-itemSelectDisabled': clientAccounts[client.client_key].length === 0 }"
               (click)="toggleClientAccountVisibility(client)">

            <div class="dp-list-itemRow">
              <div class="dp-list-itemCell -clientName">
                <i class="ion ion-ios-folder-open -logoPlaceholder">
                </i>
                <div>{{client.client_name}}</div>
                <i class="ion -toggleAccounts"
                   *ngIf="clientAccounts[client.client_key].length"
                   [ngClass]="clientAccountVisibility[client.client_key] ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down'">
                </i>
              </div>

              <div class="dp-list-itemCell -clientContact -desktop-only">
                <a class="-email">{{client.contact_email}}</a>
                <span class="-divider -desktop-only"
                      *ngIf="client.contact_email && client.contact_phone">&#183;
                </span>
                <span class="-phone -desktop-only">
                  {{client.contact_phone}}
                </span>
              </div>

              <div class="dp-list-itemCell -desktop-only">
                {{clientAccounts[client.client_key].length}} {{clientAccounts[client.client_key].length === 1 ?
                'Account' :
                'Accounts'}}
              </div>

              <div class="dp-list-itemCell">
                <button class="-color-payhero-clear -type-text"
                        (click)="$event.stopPropagation(); selectClient(client)">
                  Manage Client
                </button>
              </div>
            </div>

            <div class="dp-list-itemRow -clientAccounts"
                 *ngIf="clientAccounts[client.client_key].length && !!clientAccountVisibility[client.client_key]"
                 (click)="$event.stopPropagation()">

              <client-account-list #accountList
                                   [accountIsVisible]="accountIsVisible"
                                   [accounts]="clientAccounts[client.client_key]"
                                   (unlinkAccountFromClient)="unlinkAccountFromClient($event.account)"
                                   (goToAccountSettings)="goToAccountSettings($event.account)"
                                   (signIn)="signIn($event.account)"
                                   (accountSelect)="accountSelect($event.account)">
              </client-account-list>
            </div>
          </div>
        </ng-container>


        <div class="dp-list-empty"
             *ngIf="allClientsAndAccountsHiddenBySearch">
          <div class="dp-list-emptyHeader">
            No Clients or Accounts
          </div>
          <div class="dp-list-emptyDescription">
            Couldn't find any clients or accounts for your current search
          </div>
        </div>

        <div class="dp-list-empty"
             *ngIf="clients.length === 0">
          <div class="dp-list-emptyHeader">
            No Clients
          </div>
          <div class="dp-list-emptyDescription"
               *ngIf="has_partner_admin_access">
            Get started by creating clients for easier management of your accounts
          </div>
          <div class="dp-list-emptyDescription"
               *ngIf="!has_partner_admin_access">
            You haven't yet been set up to manage any clients
          </div>
          <button class="-type-text -color-payhero-clear"
                  *ngIf="has_partner_admin_access"
                  (click)="addClient()">
            New Client
          </button>
        </div>
      </div>

      <div class="app-dashListDivider"
           *ngIf="!!visibleClients.length && (unlinkedPayHeroAccountsVisible || unlinkedDroppahAccountsVisible || unlinkedInvoxyAccounts || unlinkedKarmlyAccounts)">
      </div>

      <div class="clientDash-additionalAccounts"
           [hidden]="!unlinkedPayHeroAccountsVisible && !unlinkedDroppahAccountsVisible && !unlinkedInvoxyAccountsVisible && !unlinkedKarmlyAccountsVisible && !ftAccountsVisible">

        <div class="clientDash-additionalAccounts-header"
             [hidden]="!unlinkedPayHeroAccountsVisible && !unlinkedDroppahAccountsVisible && !unlinkedInvoxyAccountsVisible && !unlinkedKarmlyAccountsVisible">
          <div class="-header">
            No Client
          </div>
          <div class="-subHeader"
               *ngIf="clients.length">
               <div>Clients enable you to manage access to Accounts and Reporting.&nbsp;</div>
            <div>Add these Accounts to Clients using</div>
            <img src="assets/icon/Add_Client_Folder_Icon.svg">
          </div>
        </div>

        <div class="clientDash-additionalAccounts-listHeader"
             [hidden]="!unlinkedPayHeroAccountsVisible">
          <product-logo [productValue]="'PAYHERO'"
                        [fullLogoOnly]="true">
          </product-logo>
        </div>

        <client-account-list class="clientDash-unlinkedAccountList"
                             #accountList
                             *ngIf="unlinkedPayHeroAccounts.length"
                             [hidden]="!unlinkedPayHeroAccountsVisible"
                             [clients]="clients"
                             [hideProductLogo]="true"
                             [accountIsVisible]="accountIsVisible"
                             [accounts]="unlinkedPayHeroAccounts"
                             (linkAccountToClient)="linkAccountToClient($event.account, $event.client_key)"
                             (goToAccountSettings)="goToAccountSettings($event.account)"
                             (signIn)="signIn($event.account)"
                             (accountSelect)="accountSelect($event.account)">
        </client-account-list>

        <div class="clientDash-additionalAccounts-listHeader"
             [hidden]="!unlinkedDroppahAccountsVisible">
          <product-logo [productValue]="'DROPPAH'"
                        [fullLogoOnly]="true">
          </product-logo>
        </div>

        <client-account-list class="clientDash-unlinkedAccountList"
                             #accountList
                             *ngIf="unlinkedDroppahAccounts.length"
                             [hidden]="!unlinkedDroppahAccountsVisible"
                             [clients]="clients"
                             [hideProductLogo]="true"
                             [accountIsVisible]="accountIsVisible"
                             [accounts]="unlinkedDroppahAccounts"
                             (linkAccountToClient)="linkAccountToClient($event.account, $event.client_key)"
                             (goToAccountSettings)="goToAccountSettings($event.account)"
                             (signIn)="signIn($event.account)"
                             (accountSelect)="accountSelect($event.account)">
        </client-account-list>

        <div class="clientDash-additionalAccounts-listHeader"
             [hidden]="!unlinkedInvoxyAccountsVisible">
          <product-logo [productValue]="'INVOXY'"
                        [fullLogoOnly]="true">
          </product-logo>
        </div>

        <client-account-list class="clientDash-unlinkedAccountList"
                             #accountList
                             *ngIf="unlinkedInvoxyAccounts.length"
                             [hidden]="!unlinkedInvoxyAccountsVisible"
                             [clients]="clients"
                             [hideProductLogo]="true"
                             [accountIsVisible]="accountIsVisible"
                             [accounts]="unlinkedInvoxyAccounts"
                             (linkAccountToClient)="linkAccountToClient($event.account, $event.client_key)"
                             (goToAccountSettings)="goToAccountSettings($event.account)"
                             (signIn)="signIn($event.account)"
                             (accountSelect)="accountSelect($event.account)">
        </client-account-list>

        <div class="clientDash-additionalAccounts-listHeader"
             [hidden]="!unlinkedKarmlyAccountsVisible">
          <product-logo [productValue]="'KARMLY'"
                        [fullLogoOnly]="true">
          </product-logo>
        </div>

        <client-account-list class="clientDash-unlinkedAccountList"
                             #accountList
                             *ngIf="unlinkedKarmlyAccounts.length"
                             [hidden]="!unlinkedKarmlyAccountsVisible"
                             [clients]="clients"
                             [hideProductLogo]="true"
                             [accountIsVisible]="accountIsVisible"
                             [accounts]="unlinkedKarmlyAccounts"
                             (linkAccountToClient)="linkAccountToClient($event.account, $event.client_key)"
                             (goToAccountSettings)="goToAccountSettings($event.account)"
                             (signIn)="signIn($event.account)"
                             (accountSelect)="accountSelect($event.account)">
        </client-account-list>

        <div class="clientDash-additionalAccounts-listHeader"
             [hidden]="!ftAccountsVisible">
          <img src="assets/product-imgs/flexitimepayroll/FlexiTimePayroll_Full.svg"
               class="-ftPayroll">
        </div>

        <dp-list class="clientDash-ftAccountList"
                 #ftAccountList
                 *ngIf="ftAccounts.length"
                 [hidden]="!ftAccountsVisible"
                 [itemType]="'account'"
                 [itemTypePlural]="'accounts'"
                 [itemIsVisible]="ftAccountIsVisible"
                 [itemSelectDisabled]="true"
                 [items]="ftAccounts">
          <ng-template dpListItem
                       let-item>
            <div class="dp-list-itemCell">
              <div>{{item.company_name}}</div>
            </div>
            <div class="dp-list-itemCell -desktop-only">
              <div class="-migratedDate"
                   *ngIf="item.migrated_flag && item.migrated_date && !item.cancelled_flag">
                Upgraded:
                {{item.migrated_date | date : 'd MMMM, y'}}
              </div>
            </div>
            <div class="dp-list-itemCell -desktop-only">
              <button class="-color-payhero-clear -type-text"
                      *ngIf="!item.migrated_flag || item.cancelled_flag"
                      (click)="migrateToPayHero(item)">
                Upgrade to PayHero
              </button>
            </div>
          </ng-template>
        </dp-list>

      </div>

    </div>
  </div>

</div>
