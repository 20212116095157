<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      <span>Pay Overdue Invoice{{overdue_invoices.length > 1 ? 's' : ''}}</span>
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div class="ph-modal-container">
    <dp-list #invoiceList
             [tableHeaders]="tableHeaders"
             [itemType]="'invoice'"
             [itemTypePlural]="'invoices'"
             [itemSelectDisabled]="true"
             [primarySortProperty]="'invoice_date'"
             [items]="overdue_invoices">
      <ng-template dpListItem
                   let-item>

        <div class="dp-list-itemCell"
             [ngClass]="{ '-downloading': item.downloading }">
          {{ item.invoice_date | date: 'd MMM, y' }}
        </div>

        <div class="dp-list-itemCell -rightAligned"
             [ngClass]="{ '-downloading': item.downloading }">
          <div>{{ item.invoice_gross_total | appCurrency }}</div>
        </div>

        <div class="dp-list-itemCell -desktop-only -rightAligned">
          <button class="-color-payhero-clear -type-icon"
                  (click)="downloadInvoice(item)"
                  *ngIf="!item.downloading">
            <i class="ion ion-ios-download"></i>
          </button>

          <spinner class="-spinnerFlex"
                   *ngIf="item.downloading">
          </spinner>
        </div>

      </ng-template>
    </dp-list>

    <div class="invoiceModal-invoiceTotal"
         *ngIf="overdue_invoices.length > 1">
      <span>{{ total_due | appCurrency }}</span>
    </div>

    <div class="invoiceModal-paymentMethod">
      <span>Payment Method</span>
      <sub-card-info [subscriptioncard]="subscription_card"
                     *ngIf="!editingPaymentMethod && !!subscription_card">
      </sub-card-info>

      <button class="-type-text -color-payhero-clear"
              *ngIf="!editingPaymentMethod && !!subscription_card"
              (click)="editCard()">
        Edit
      </button>

      <div class="invoiceModal-directDebit"
           *ngIf="payment_method !== 'CARD' && !subscription_card">
        <b>Your current payment method is {{ payment_method === 'DIRECTDEBIT' ? 'Direct Debit' : 'Invoice' }}.</b>
        If you need to access your accounts now, you can add a credit card below and pay the outstanding invoice total
        as a one off payment.
        <em>Note that this will not change your current payment method.</em>

        <button class="-cardAdd -color-payhero-clear ph-modal-btnRight"
                *ngIf="!editingPaymentMethod"
                (click)="editCard()">
          + Credit Card
        </button>
      </div>

      <div class="subscribeModal-paymentSelect-card"
           *ngIf="editingPaymentMethod">
        <paystation-card-info #paystationCardInfo
                              (error)="setErrorMessage($event.error)">
        </paystation-card-info>

      </div>

      <div class="app-errorMessage"
           [ngClass]="{ '-visible': !!error_message }">
        <i class="ion ion-ios-warning sm-icon"></i>
        {{error_message}}
      </div>
    </div>

    <div class="invoiceModal-emailInfo">
      <i class="ion ion-md-information-circle"></i>
      <div>
        We've been sending emails about your subscription to <span class="email">{{email}}</span>.
        You can change your billing email address at any time by heading to <em>Billing > Payment Details</em>.
      </div>
    </div>
  </div>

  <div class="ph-modal-btnFooter">

    <button class="-color-white-outline ph-modal-btnRight"
            [disabled]="!!loading"
            (click)="close()">
      Cancel
    </button>
    <button class="-color-success ph-modal-btnRight"
            [disabled]="!!loading || (payment_method !== 'CARD' && !subscription_card) || (payment_method === 'CARD' && editingPaymentMethod)"
            (click)="payNow()">
      Pay Now
    </button>

    <spinner *ngIf="!!loading"
             class="-spinnerFlex">
    </spinner>
  </div>
</div>
