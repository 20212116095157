import { AccountAccessService } from './../../../services/account-access/account-access.service';
import { DomService } from 'src/app/services/dom/dom.service';
import { UserService } from 'src/app/services/user/user.service';
import { ClientPartnerStaff } from './../../../models/client-partner-staff/client-partner-staff';
import { PartnerStaffService } from './../../../services/partner-staff/partner-staff.service';
import { AccountService } from './../../../services/account/account.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { DpListComponent } from 'src/app/components/dp-list/dp-list.component';
import { ClientNote } from 'src/app/models/client-note/client-note';
import { Client } from 'src/app/models/client/client';
import { ClientService } from 'src/app/services/client/client.service';
import { ModalService } from 'src/app/services/modal/modal.service';

import { PartnerStaff } from 'src/app/models/partner-staff/partner-staff';
import { PartnerService } from 'src/app/services/partner/partner.service';
import { Account } from 'src/app/models/account/account';
import { ProductService } from 'src/app/services/product/product.service';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { DropdownOption } from '../../../app.types';

import { env } from './../../../../environments/environment';

import * as _ from 'lodash-es';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {

  @ViewChild('logoSelect') logoSelect: ElementRef;
  @ViewChild('noteList') noteList: DpListComponent;

  readonly has_partner_admin_access: boolean = this.userService.has_partner_admin_access;

  clientDropdownOptions: DropdownOption[] = [];
  noteDropdownOptions: DropdownOption[][] = [];

  client: Client;
  clientAccounts: Account[] = [];
  unlinkedAccounts: Account[] = [];

  staffAccessMap: Record<number, number>;
  availableStaff: PartnerStaff[];

  loading: boolean;

  constructor(
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public clientService: ClientService,
    public modalService: ModalService,
    public partnerService: PartnerService,
    public productService: ProductService,
    public redirectService: RedirectService,
    public accountService: AccountService,
    public partnerStaffService: PartnerStaffService,
    public userService: UserService,
    public domService: DomService,
    public accountAccessService: AccountAccessService
  ) { }

  ngOnInit(): void {
    const client_key = this.uiRouterGlobals.params.client_key;
    if (!client_key) {
      this.backToClients();
    }

    this.refreshClient(client_key);
  }

  refreshClient(client_key: number = null) {
    this.client = this.clientService.getClient(client_key || this.client.client_key);

    this.clientAccounts = this.accountService.getAllAccounts(
      ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO'],
      this.client.client_key
    );
    this.unlinkedAccounts = this.accountService.getAllAccounts(
      ['UNLINKED_ONLY', 'EXCLUDE_CANCELLED', 'EXCLUDE_DEMO', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS']
    );
    this.updateDropdownOptions();
    this.updateAvailableStaff();
  }

  backToClients() {
    this.stateService.go('app.client.dash');
  }

  editClientDetails() {
    if (this.has_partner_admin_access) {
      this.modalService.formModal(
        'Edit Client Details',
        this.client,
        [
          { property: 'client_name', label: 'Name', field_required: true },
          { property: 'contact_name', label: 'Contact Name' },
          { property: 'contact_phone', label: 'Contact Number' },
          { property: 'contact_email', label: 'Contact Email', field_type: 'email' },
          { property: 'client_address', label: 'Address', field_type: 'address' }
        ]
      ).then((updated_client) => {
        this.loading = true;

        this.clientService.saveClient(updated_client)
          .finally(() => {
            this.refreshClient();
            this.loading = false;
          });
      })
        .catch(() => { });
    }
  }

  deleteClient() {
    if (this.has_partner_admin_access) {
      this.modalService.confirmModal(
        'Delete',
        'Client',
        this.client.client_name,
        null,
        this.client.client_name + ' will no longer be accessible.', null
      )
        .then(() => {
          this.loading = true;

          this.clientService.saveClient(this.client, true)
            .finally(() => {
              this.accountService.loadAccounts()
                .finally(() => {
                  this.loading = false;
                  this.stateService.go('app.client.dash');
                });
            });
        })
        .catch(() => { });
    }
  }

  signIn(
    account: Account,
    external_state_name: string = null
  ) {
    this.loading = true;

    if (!account) {
      this.loading = false;
      return;
    }

    if (account.allow_user_access) {
      this.productService.startNewSession(account.company_product_key, account.product_value)
        .then((session) => {
          this.redirectService.redirectToProduct(session.login_source, true, external_state_name);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    }
    else {
      this.loading = false;
      this.modalService.errorModal(
        'Access to ' + (account.company_name ? account.company_name : 'this account') + ' is not allowed'
      );
    }
  }

  updateAccount(account: Account = null, toDelete: boolean = false) {
    if (!account || this.has_partner_admin_access) {
      if (!account) {
        return this.addAccount();
      }

      this.loading = true;

      this.accountService.addOrRemoveAccountFromClient(this.client, account, toDelete)
        .finally(() => {
          this.refreshClient();
          this.loading = false;
        });
    }
  }

  addAccount() {
    this.modalService.addAccountModal(false)
      .then((data) => {
        this.loading = true;

        this.accountService.createAccount(
          data.company_name,
          this.client.client_key,
          data.product_value,
          data.number_of_employees,
          data.region,
          data.industry_key,
          data.business_type,
          data.nzbn,
          data.industry_classification,
          data.ird_number,
          false,
          false,
          data.sub_agreement_flag,
          false,
          data.contact_phone
        )
          .finally(() => {
            this.refreshClient();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  updateStaff(staff: PartnerStaff, toDelete: boolean = false) {
    if (this.has_partner_admin_access) {
      this.loading = true;

      this.accountAccessService.addOrRemovePartnerStaffFromClient(
        this.client, staff, toDelete
      )
        .finally(() => {
          this.refreshClient();
          this.loading = false;
        });
    }
  }

  manageStaffAccountAccess(client_partner_staff: ClientPartnerStaff) {
    if (this.has_partner_admin_access) {
      this.modalService.manageClientStaffAccessModal(
        this.client.client_key,
        client_partner_staff.partner_staff.partner_staff_key
      )
        .finally(() => {
          this.refreshClient();
          this.loading = false;
        });
    }
  }

  manageNote(note: ClientNote = null, viewOnly: boolean = false) {
    if (viewOnly || this.has_partner_admin_access) {
      this.modalService.addClientNoteModal(note || new ClientNote(null, this.client.client_key, ''), viewOnly)
        .then((result) => {
          this.loading = true;

          this.clientService.saveClientNote(result.note, result.toDelete)
            .finally(() => {
              this.refreshClient();
              this.loading = false;
            });
        })
        .catch(() => { });
    }
  }

  deleteNote(note: ClientNote) {
    if (this.has_partner_admin_access) {
      this.loading = true;

      this.clientService.saveClientNote(note, true)
        .finally(() => {
          this.refreshClient();
          this.loading = false;
        });
    }
  }

  goToAccountSettings(account: Account) {
    this.signIn(account, env[account.product_value.toLowerCase()].external_settings_state);
  }

  dropdownOptionSelected(option: string) {
    switch (option) {
      case 'DELETE':
        this.deleteClient();
        break;
    }
  }

  accountDropdownOptionSelected(option: string, account: Account) {
    switch (option) {
      case 'SIGNIN':
        this.signIn(account);
        break;
      case 'UNLINK':
        this.updateAccount(account, true);
        break;
    }
  }

  updateAvailableStaff() {
    this.availableStaff = this.partnerStaffService.getAllPartnerStaff().filter(partner_staff => {
      return !this.client.containsPartnerStaff(partner_staff.partner_staff_key);
    });
  }

  updateDropdownOptions() {
    const options: DropdownOption[] = [];

    if (this.has_partner_admin_access) {
      options.push({
        option_value: 'DELETE',
        option_name: 'Delete Client',
        option_color: '#d9534f'
      });
    }

    this.clientDropdownOptions = options;
  }

}
