import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';

import { TokenPayheroService } from './../../../services/token-payhero/token-payhero.service';
import { ProductService } from './../../../services/product/product.service';
import { RedirectService } from 'src/app/services/redirect/redirect.service';

import * as _ from 'lodash-es';

export type AkahuPaymentState = (
  'ERROR' |
  'TWOFACTOR' |
  'ACCOUNTS' |
  'PAYMENTS' |
  'REVIEW' |
  'SUCCESS'
);

@Component({
  selector: 'app-autopilot-akahu-payment',
  templateUrl: './autopilot-akahu-payment.component.html',
  styleUrls: ['./autopilot-akahu-payment.component.scss']
})
export class AutopilotAkahuPaymentComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  @ViewChild('twoFactorCodeElem') twoFactorCodeElem: ElementRef;

  token: string;
  pay_details: any;

  state: AkahuPaymentState;
  error_message: string;

  product_session: any;
  login_two_factor_token: string;
  two_factor_user_name: string;
  two_factor_registered_email: string;
  two_factor_verification_token: string;
  two_factor_token: string;
  two_factor_code: string;
  two_factor_code_incorrect: boolean = false;

  acknowledged: boolean = false;
  current_step: number = 0;

  akahuAccounts: any[] = [];
  selectedAkahuAccount: any;
  lastRefreshed: string[] = [];
  payments: any[] = [];
  paymentAmount: number = 0;

  verifying: boolean = false;
  loading: boolean = true;

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public stateService: StateService,
    public tokenPayHeroService: TokenPayheroService,
    public productService: ProductService,
    public redirectService: RedirectService
  ) { }

  ngOnInit(): void {
    window.document.title = 'Pay Now | Payroll AutoPilot';

    this.token = this.uiRouterGlobals.params.token || null;
    this.login_two_factor_token = this.uiRouterGlobals.params.login_two_factor_token || null;
    this.pay_details = this.uiRouterGlobals.params.pay_details || null;

    if (this.token) {
      this.loadToken();
    }
    else if (this.pay_details) {
      this.authenticateTwoFactor();
    }
    else {
      this.loading = false;
      this.state = 'ERROR';
      this.error_message = 'This link is no longer valid'
    }
  }

  loadToken() {
    this.tokenPayHeroService.getAutoPilotPayDetails(this.token)
      .then((pay_details) => {
        this.pay_details = pay_details;

        if (!this.pay_details) {
          throw new Error('Pay Now token expired');
        }

        this.stateService.go('login', {
          internal_state_name: 'tokenPayhero.autoPilotAkahuPayment',
          internal_state_params: CoreUtilService.stringifyJSON({
            pay_details: this.pay_details
          })
        });
      })
      .catch(() => {
        this.loading = false;
        this.state = 'ERROR';
        this.error_message = 'This link is no longer valid'
      });
  }

  authenticateTwoFactor() {
    this.loading = true;

    this.productService.startNewSession(this.pay_details.sub_company_product_key, this.productService.current_product, true, true, null)
      .then((session) => {
        this.product_session = session;

        this.tokenPayHeroService.authenticateAkahuTwoFactor(this.product_session, this.login_two_factor_token)
          .then((result) => {

            if (result.two_factor_token) {
              this.two_factor_token = result.two_factor_token
              this.setupAkahuPayment();
              return;
            }

            this.two_factor_verification_token = result.two_factor_verification_token;
            this.two_factor_user_name = result.full_name;
            this.two_factor_registered_email = result.registered_email;

            this.loading = false;
            this.state = 'TWOFACTOR'

            // Wait for input to load
            setTimeout(() => {
              this.twoFactorCodeElem?.nativeElement.focus();
            }, 200)
          })
          .catch((err) => {
            this.loading = false;
            this.state = 'ERROR';
            this.error_message = err.message
          })
      })
      .catch((err) => {
        this.loading = false;
        this.state = 'ERROR';
        this.error_message = err.message
      });
  }

  verifyTwoFactor() {
    this.verifying = true;

    this.tokenPayHeroService.verifyAkahuTwoFactor(this.product_session, this.two_factor_verification_token, this.two_factor_code)
      .then((result) => {
        this.two_factor_token = result.two_factor_token;
        this.verifying = false;
        this.setupAkahuPayment();
      })
      .catch((err) => {
          this.two_factor_code_incorrect = true;
          this.verifying = false;

          // Wait for input to load
          setTimeout(() => {
            this.twoFactorCodeElem?.nativeElement.focus();
          }, 200)
      })
  }

  setupAkahuPayment() {
    this.loading = true;

    Promise.all([
      this.getAkahuAccounts(),
      this.getPayRecipients()
    ])
    .then(() => {
      this.loading = false;
      this.state = 'ACCOUNTS';
    })
    .catch((err) => {
      this.loading = false;
      this.state = 'ERROR';
      this.error_message = err.message;
    })
  }

  getAkahuAccounts() {
    return new Promise<any>((resolve, reject) => {
      this.tokenPayHeroService.getAkahuAccounts(this.product_session, this.two_factor_token)
        .then((akahu_accounts) => {
          this.akahuAccounts = akahu_accounts;

          for (let i = 0; i < this.akahuAccounts.length; i++) {
            var account = this.akahuAccounts[i]
            account.selected = false;

            if (i === 0) {
                this.selectAkahuAccount(account);
            }

            // Add connection last refresh string
            if (!this.lastRefreshed.includes(account.last_refreshed)) {
                this.lastRefreshed.push(account.last_refreshed)
            }
          }

          resolve(this.akahuAccounts);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  getPayRecipients() {
    return new Promise<any>((resolve, reject) => {
      this.tokenPayHeroService.getPayRecipients(this.product_session, this.pay_details.pay_key)
        .then((pay_recipients) => {
          this.payments = pay_recipients;

          this.paymentAmount = 0;
          for (const payment of this.payments) {
            this.paymentAmount += payment.amount;
          }

          resolve(this.payments);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  nextStep() {
    this.acknowledged = false;

    switch (this.state) {
      case 'ACCOUNTS':
        this.state = 'PAYMENTS';
        this.current_step = 1;
        break;
      case 'PAYMENTS':
        this.state = 'REVIEW';
        this.current_step = 2;
        break;
    }
  }

  backStep() {
    switch (this.state) {
      case 'PAYMENTS':
        this.state = 'ACCOUNTS';
        this.current_step = 0;
        break;
      case 'REVIEW':
        this.state = 'PAYMENTS'
        this.current_step = 1;
        break;
    }
  }

  selectAkahuAccount(akahuAccount: any) {
    for (const account of this.akahuAccounts) {
        account.selected = false;
    }

    akahuAccount.selected = true;
    this.selectedAkahuAccount = akahuAccount;
}

  payNow() {
    this.loading = true;

    this.tokenPayHeroService.makeAkahuPayment(this.product_session, this.two_factor_token, this.pay_details.pay_key, this.selectedAkahuAccount)
      .then((pay) => {
        this.loading = false;
        this.state = 'SUCCESS';
      })
      .catch((err) => {
        this.loading = false;
        this.state = 'ERROR';
        this.error_message = err.message
      })
  }

  loginToPayHero() {
    this.stateService.go('login', {
      destination: 'PAYHERO_APP'
    });
  }

  loginToPayHeroPay() {
    if (!this.pay_details || !this.product_session) {
      return;
    }

    const targetStateParams = {
      selected_month: this.pay_details.pay_date,
      selected_pay_key: this.pay_details.pay_key
    }

    this.redirectService.redirectToProduct(
      this.product_session.login_source,
      false,
      'app.pay.sent',
      targetStateParams
    );
  }

}
