import { PipesModule } from './../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { ComponentsModule } from './../../components/components.module';

import { NavComponent } from './nav/nav.component';
import { SplashComponent } from './splash/splash.component';
import { LockedComponent } from './locked/locked.component';

export const CORE_CONFIG: any[] = [
  {
    name: 'app',
    abstract: true,
    component: NavComponent
  },
  {
    name: 'splash',
    url: '/splash',
    component: SplashComponent,
    params: {
      refreshStateName: null,
      refreshStateParams: null,
      externalStateName: null,
      externalStateParams: null,
      auto_login_flag: false,
      auto_login_partner_flag: false,
      auto_login_company_product_key: null
    }
  },
  {
    name: 'locked',
    url: '/locked',
    component: LockedComponent,
    params: {
      ping_result: null
    }
  }
];

@NgModule({
  declarations: [
    NavComponent,
    SplashComponent,
    LockedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    UIRouterModule.forChild({ states: CORE_CONFIG }),
    ComponentsModule
  ]
})
export class CoreModule { }
