import { Subscription } from 'rxjs';
import { Component, OnInit, AfterContentInit, OnDestroy, HostBinding, ViewChild, ElementRef, HostListener } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { ModalService } from 'src/app/services/modal/modal.service';
import { ProductService } from 'src/app/services/product/product.service';
import { UserService } from 'src/app/services/user/user.service';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { RedirectService } from './../../../services/redirect/redirect.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SupportService } from './../../../services/support/support.service';
import { StateDataService } from 'src/app/services/state-data/state-data.service';
import { ProductTheme, ProductValue, IntegrationSocketEventType, AccountDomainData, LoginIntegration, ProductName } from './../../../app.types';
import { AccountService } from 'src/app/services/account/account.service';

import * as _ from 'lodash-es';
import { IntegrationService } from 'src/app/services/integration/integration.service';

import { GoogleAnalyticsService } from '../../../services/google-analytics/google-analytics.service';

type LoginType = ('PASSWORD' | 'TOKEN' | 'GOOGLE' | 'XERO' | 'INTEGRATION');

declare var Sha1: any;
declare var Base64: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterContentInit, OnDestroy {

  @HostBinding('class.app-page') app_page: boolean = true;
  @HostBinding('class.-noMobileGraphic') noMobileGraphic: boolean = false;

  @ViewChild('authCardInner') auth_card_inner: ElementRef;

  is_mobile = CoreUtilService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.is_mobile !== CoreUtilService.is_mobile) {
      this.is_mobile = CoreUtilService.is_mobile;
      this._updateAuthCardHeight();
    }
  }

  loading: boolean = true;
  initialised: boolean = false;

  productTheme: ProductTheme;
  product_value: ProductValue;

  domain: string;
  domain_data: AccountDomainData = null;

  token: string = null;
  googleToken: string;
  two_factor_token: string;

  username: string = '';
  password: string = '';
  showPassword: boolean = false;

  internal_state_name: string = null;
  internal_state_params: any = null;
  external_state_name: string = null;
  external_state_params: any = null;
  company_product_key: number = null;
  zendesk_return_to: string = null;

  forgottenPassword: boolean = false;
  resetEmail: string = '';
  signInButtonText: string = '';
  employeeHelpText: string = '';

  auth_card_height: number = null;

  ignore_cached_username: boolean = false;

  hideFlexiTime: boolean = false;

  private _errorMessage: string = '';
  showSuccessMessage: boolean = false;

  disableFooter: boolean = false;

  from_native: boolean = false;

  loginIntegrations: LoginIntegration[];

  private _integration_events: Partial<Record<IntegrationSocketEventType, Subscription>> = {
    'Integration_Login_Success': null,
    'Integration_Login_Not_Found': null,
    'Integration_Error': null
  };

  constructor(
    private authService: AuthService,
    private stateService: StateService,
    private modalService: ModalService,
    private productService: ProductService,
    private userService: UserService,
    private uiRouterGlobals: UIRouterGlobals,
    private redirectService: RedirectService,
    private accountService: AccountService,
    private supportService: SupportService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private stateDataService: StateDataService,
  ) { }

  get errorMessage(): string {
    return this._errorMessage;
  }
  set errorMessage(message: string) {
    this._errorMessage = message;
    this.showSuccessMessage = message.indexOf('Due to security changes we require that you reset your password by clicking on the Forgot? link in the password box below.') !== -1;

    // 200ms timeout needed to wait for errorMessage animation to finish
    setTimeout(() => {
      this._updateAuthCardHeight();
    }, 200);
  }

  ngOnInit() {
    this.initEventListeners();
    this._initStateParams();
    this._updateAuthCardHeight();

    this.initProduct();

    this.loginIntegrations = IntegrationService.login_integrations;

    if (this.token) {
      this.login('TOKEN');
    }
    else if (this.username && this.password) {
      this.login('PASSWORD');
    }
    else {
      this._initAccountDomain()
        .finally(() => {

          this.loading = false;
          this.initialised = true;
        });
    }

  }

  private _initAccountDomain() {
    return new Promise<void>((resolve) => {
      if (this.productService.current_product === 'INVOXY' && this.domain !== null) {
        this.accountService.getInvoxyAccountLogo(this.domain)
          .then((domain_data) => {
            this.domain_data = domain_data;
            this.company_product_key = this.domain_data?.company_product_key || this.company_product_key;
            this._updateAuthCardHeight();
          })
          .finally(() => {
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  toggleShowPassword(event) {
    event.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  private _openGooglePopup() {
    let tab = null;
    if (!this.from_native) {
      tab = this.userService.openIntegrationWindow();
    }
    this.userService.getGoogleLoginURI('SIGN_IN', null, null, null, null, null)
      .then((URL) => {
        const messageObj = { url: URL };
        const stringifiedMessageObj = JSON.stringify(messageObj);
        try {
          if ((window as any).webkit && (window as any).webkit.messageHandlers) {
            (window as any).webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);
          }
        } catch (e) { }
        if (!!tab) {
          tab.location.href = URL;
        }
      })
      .catch(() => {

      });
  }

  private _openXeroPopup() {
    const from_native = this.stateDataService.getCachedGlobalLocalData('from_native');
    let tab = null;
    if (!from_native) {
      tab = this.userService.openIntegrationWindow();
    }
    this.userService.getXeroAuthURI('SIGN_IN', null, this.productService.current_product_name, null, null, null, null)
      .then((URL) => {
        const messageObj = { url: URL };
        const stringifiedMessageObj = JSON.stringify(messageObj);
        try {
          if ((window as any).webkit && (window as any).webkit.messageHandlers) {
            (window as any).webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);
          }
        } catch (e) { }
        if (!!tab) {
          tab.location.href = URL;
        }
      })
      .catch(() => {

      });
  }

  private _initStateParams() {
    const encoded_params = this.uiRouterGlobals.params.encoded_params ?
      CoreUtilService.parseJSON(Base64.decode(this.uiRouterGlobals.params.encoded_params)) : {};
    const params = this.uiRouterGlobals.params;

    if (!!this.stateDataService.getCachedGlobalLocalData('from_native')) {
      this.from_native = true;
    }

    this.token = params.token || encoded_params.token || null;
    this.password = params.password || encoded_params.password || null;

    this.internal_state_name = params.internal_state_name || encoded_params.internal_state_name || null;
    this.internal_state_params = CoreUtilService.parseJSON(params.internal_state_params) || CoreUtilService.parseJSON(encoded_params.internal_state_params) || null;
    this.external_state_name = params.external_state_name || encoded_params.external_state_name || null;
    this.external_state_params = CoreUtilService.parseJSON(params.external_state_params) || CoreUtilService.parseJSON(encoded_params.external_state_params) || null;

    this.zendesk_return_to = params.zendesk_return_to ? decodeURIComponent(params.zendesk_return_to) : null;

    this.company_product_key = params.company_product_key || encoded_params.company_product_key || null;
    this.errorMessage = params.error_message || encoded_params.error_message || '';

    this.domain = params.domain || encoded_params.domain || null;

    if (encoded_params.integration_redirect_system) {
      this.authService.integration_redirect = {
        uri: encoded_params.integration_redirect_uri,
        state: encoded_params.integration_redirect_state,
        system: encoded_params.integration_redirect_system,
        company_code: encoded_params.integration_redirect_company_code || null,
        refresh_token: null,
        verification_token: null
      };
      this.authService.integration_product = encoded_params.integration_product;

      this.disableFooter = true;
    }
    else if (params.integration_redirect_system) {
      this.authService.integration_redirect = {
        uri: params.integration_redirect_uri,
        state: params.integration_redirect_state,
        system: params.integration_redirect_system,
        company_code: params.integration_redirect_company_code || null,
        refresh_token: null,
        verification_token: null
      };
      this.authService.integration_product = params.integration_product;

      this.disableFooter = true;
    }

    this.ignore_cached_username = encoded_params.ignore_cached_username || params.ignore_cached_username || false;

    if (encoded_params.username) {
      this.username = encoded_params.username;
    }
    else if (params.username) {
      this.username = decodeURIComponent(params.username);
    }
    else if (!this.ignore_cached_username) {
      this.username = this.authService.getCachedUsername() || '';
    }

    // Two factor token may be passed in from state params (coming from one of the apps)
    // or may be cached in localstorage if User selected remember me when verifying.
    if (encoded_params.two_factor_token) {
      this.two_factor_token = encoded_params.two_factor_token;
    }
    else if (params.two_factor_token) {
      this.two_factor_token = params.two_factor_token;
    }
    else {
      this.two_factor_token = this.authService.getCachedTwoFactor() || null;
    }
  }

  ngAfterContentInit(): void {
    if (this.loading) { return; }
    if (!this.username) { this.setInputFocus('username'); }
    else { this.setInputFocus('password'); }
  }

  ngOnDestroy() {
    this.clearEventListeners();
  }

  initEventListeners(): void {
    this._integration_events.Integration_Login_Success =
      this.userService.getIntegrationEvent('Integration_Login_Success').subscribe((event_data: any) => {
        if (event_data && event_data.login_token) {
          this.token = event_data.login_token;
          this.login(event_data.integration_name);
        }
      });
    this._integration_events.Integration_Login_Not_Found =
      this.userService.getIntegrationEvent('Integration_Login_Not_Found').subscribe((event_data: any) => {

        let integration: string;
        let message: string;

        if (event_data && event_data.integration_name) {
          integration = event_data.integration_name;
          message = '<p>You need to connect your ' + integration + ' Account with ' + this.productTheme.displayName
            + '. To connect, log in to ' + this.productTheme.displayName + ', go to <b>My Profile</b> '
            + 'and click <b>Connect to ' + integration + '</b>.</p>'
        }
        else {
          message = '<p>You need to connect your Account with ' + this.productTheme.displayName
            + '. To connect, log in to ' + this.productTheme.displayName + ', go to <b>My Profile</b> '
            + 'and connect your account.</p>'
        }

        this.token = null;
        this.modalService.errorModal(null, message);
      });
  }

  clearEventListeners(): void {
    this._integration_events.Integration_Login_Success?.unsubscribe();
    this._integration_events.Integration_Login_Success = null;

    this._integration_events.Integration_Login_Not_Found?.unsubscribe();
    this._integration_events.Integration_Login_Not_Found = null;
  }

  initProduct() {
    let base_url_product = window.location.host.split('.')[1];
    // Invoxy -> Karmly rebrand 
    if (base_url_product === 'karmly' && window.location.host.split('.')[2].substring(0, 3) === 'com') {
      base_url_product = 'invoxy';
    }
    const destination = this.uiRouterGlobals.params.destination;
    const zendesk_brand_name = this.uiRouterGlobals.params.zendesk_brand_name;

    // Product automatically set based on login destination
    if (this.productService.destinationIsValid(destination)) {
      this.productService.login_destination = destination;
    }
    else if (this.productService.productIsValid(zendesk_brand_name)) {
      this.productService.current_product = zendesk_brand_name;
    }
    // Set product from base url eg. login.payhero.co.nz
    else if (this.productService.productIsValid(base_url_product)) {
      this.productService.current_product = base_url_product as ProductValue;
    }
    // Set product from an integration redirect
    else if (this.authService.integration_redirect_flag) {
      this.productService.current_product = this.authService.integration_product;
    }
    // Set product from 2FA state change
    else if (this.uiRouterGlobals.params.product) {
      this.productService.current_product = this.uiRouterGlobals.params.product;
    }
    // Product will fallback to FLEXITIME if none of the above IF statements are triggered
    this.productTheme = this.productService.product_theme;
    this.product_value = this.productService.current_product;

    this.productService.setTitle('Sign In');

    this.noMobileGraphic = this.productTheme.displayName === 'FlexiTime';

    this.signInButtonText = this.product_value === 'DROPPAH' ? 'Let\'s Go' : 'Sign in with email';
    this.employeeHelpText = this.product_value === 'DROPPAH' ? 'Help! I\'m an employee. What\'s my login?' : 'Don\'t have an employee account?';

  }

  login(login_type: LoginType) {
    if (!this.token && this.forceRequiredField('username', this.username)) { return; }
    if (!this.token && this.forceRequiredField('password', this.password)) { return; }

    this.loading = true;

    this.authService.login(
      this.token ? null : this.username,
      this.token ? null : Sha1.hash(this.password),
      this.token,
      this.two_factor_token
    )
      .then((user_access) => {
        let token_data;

        this.googleAnalyticsService.trackEvent('sign_in', null, user_access.user_access_key);

        // Firstly parse our additional data if needed
        if (user_access?.additional_data) {
          token_data = CoreUtilService.parseJSON(user_access.additional_data);
        }

        this.company_product_key = this.company_product_key || token_data?.company_product_key || null;

        if (user_access.two_factor_required || user_access.two_factor_enabled || user_access.two_factor_setup_required) {
          const params = _.cloneDeep(this.uiRouterGlobals.params);
          params.token = this.token;
          params.username = this.username;
          params.password = this.password;

          this.stateService.go('loginTwoFactor', {
            verification_token: user_access.verification_token,
            two_factor_enabled: user_access.two_factor_enabled,
            targetStateName: 'login',
            targetStateParams: params
          });
        }
        else if (this.authService.integration_redirect_flag) {
          const integrated_companies = this.uiRouterGlobals.params?.company_codes;
          this.stateService.go('accountIntegration', {
            integrated_companies: integrated_companies
          });
        }
        else if (this.internal_state_name) {
          // Attach login two factor token for internal states that reauthenticate against two factor
          if (this.internal_state_params && this.two_factor_token) {
            this.internal_state_params.login_two_factor_token = this.two_factor_token;
          }

          this.stateService.go('splash', {
            refreshStateName: this.internal_state_name,
            refreshStateParams: this.internal_state_params
          });
        }
        else if (token_data?.registration_product) {
          // for accepting registration verification with integration, we get marketing data from token
          const marketing_additional_data = !!token_data?.marketing_additional_data ? token_data?.marketing_additional_data : null;
          this.stateService.go('splash', {
            refreshStateName: 'app.account.create',
            refreshStateParams: {
              company_name: token_data.company_name,
              product: token_data.registration_product,
              marketing_additional_data: marketing_additional_data,
              auto_account_create: token_data.registration_product === 'KARMLY'
            }
          });
        }
        else if (!!this.company_product_key) {
          this.stateService.go('splash', {
            externalStateName: this.external_state_name,
            externalStateParams: this.external_state_params,
            auto_login_flag: true,
            auto_login_company_product_key: this.company_product_key
          });
        }
        else if (this.zendesk_return_to) {
          this.supportService.accessSupport(this.zendesk_return_to);
        }
        else {
          this.stateService.go('splash', {
            externalStateName: this.external_state_name,
            externalStateParams: this.external_state_params,
            auto_login_flag: true
          });
        }
      })
      .catch((error) => {
        this.loading = false;
        this.initialised = true;
        this.token = null;

        if (error && error.message) {
          this.errorMessage = error.message;
        }
        else {
          this.errorMessage = AuthService.fallbackAuthError;
        }


      });
  }

  integrationButton(integration_type: string) {
    switch (integration_type) {
      case 'GOOGLE':
        this._openGooglePopup();
        break;
      case 'XERO':
        this._openXeroPopup();
        break;
    }
  }

  resetPassword(reset: boolean) {
    if (reset) {
      this.errorMessage = '';
      this.forgottenPassword = true;

      if (this.username && this.username.includes('@')) {
        this.resetEmail = this.username;
      }
      else { this.setInputFocus('email'); }
    }
    else {
      this.errorMessage = '';
      this.forgottenPassword = false;

      if (this.resetEmail) {
        this.username = this.resetEmail;
      }
      if (!this.username) {
        this.setInputFocus('username');
      }
      else {
        this.setInputFocus('password');
      }
      this.loading = false;
    }
    this._updateAuthCardHeight();
  }

  private _updateAuthCardHeight() {
    setTimeout(() => {
      this.auth_card_height = this.auth_card_inner.nativeElement.offsetHeight;
    });
  }

  sendPasswordReset() {
    if (this.forceRequiredField('email', this.resetEmail)) { return; }

    this.loading = true;

    this.userService.emailPasswordReset(this.resetEmail)
      .then(() => {
        this.modalService.successModal('Password Reset Sent', null,
          'An email has been sent to <b>'
          + this.resetEmail
          + '</b> with a password reset link.');
        this.resetPassword(false);
      })
      .catch(() => {
        this.loading = false;
      });
  }

  forceRequiredField(key: string, value: string): boolean {
    if (!value) {
      this.setInputFocus(key);
      this.errorMessage = key.charAt(0).toUpperCase() + key.substr(1) + ' is required';
      return true;
    }
    else { return false; }
  }

  hideError() {
    this.errorMessage = '';
  }

  setInputFocus(id: string): void {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 50);
  }

}
