import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'hoursToTimePipe',
})
export class HoursToTimePipe implements PipeTransform {

  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: number, allow_null: boolean = false) {
    if (allow_null && value === null){
      return null;
    }
    return moment.duration(value, 'hours').format('hh:mm', { trim: false });
  }
}
