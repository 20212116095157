import { Injectable } from '@angular/core';

import { DbUtilService } from '../db-util/db-util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { CoreUtilService } from './../core-util/core-util.service';
import { AutoPilotPayDetails } from 'src/app/app.types';

@Injectable({
  providedIn: 'root'
})
export class TokenPayheroService {

  constructor(
    private dbUtilService: DbUtilService
  ) { }

  getPasswordReset(token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('user/resetpass', { token_uid: token }, true)
        .then((result) => {
          if (result !== null) {
            resolve(result);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getExpense(token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('expense/token/get', { expense_token: token })
        .then((res) => {
          const expense = res.length ? res[0] : null;

          if (expense !== null) {
            expense.project_colour = CoreUtilService.intToHexColor(expense.project_colour);
            resolve(expense);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postExpense(token: string, approval_date: any, declined_date: any) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPostPayHero('expense/token/respond',
        {
          expense_token: token,
          approval_date: approval_date ? TimeUtilService.formatDateForPosting(approval_date, false) : null,
          declined_date: declined_date ? TimeUtilService.formatDateForPosting(declined_date, false) : null
        })
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }

  formatPartDay(part_day: number): string {
    switch (part_day) {
      case 0.25:
        return '1/4 Day';
      case 0.5:
        return '1/2 Day';
      case 0.75:
        return '3/4 Day';
      default:
        return 'Full Day';
    }
  }

  getLeave(token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('leaverequest/token/get', { leave_token: token })
        .then((leave) => {

          if (leave !== null) {
            leave.leave_type_colour = CoreUtilService.intToHexColor(leave.leave_type_colour);

            leave.part_day = this.formatPartDay(leave.part_day);
            leave.start_date = TimeUtilService.dateStringToDate(leave.start_date, null, false);
            leave.end_date = TimeUtilService.dateStringToDate(leave.end_date, null, false);
            leave.declined_date = TimeUtilService.dateStringToDate(leave.declined_date, null, true);
            leave.approval_date = TimeUtilService.dateStringToDate(leave.approval_date, null, true);

            if (leave.approved_data) {
              leave.approved_data.start_date = TimeUtilService.dateStringToDate(leave.approved_data.start_date, null, false);
              leave.approved_data.end_date = TimeUtilService.dateStringToDate(leave.approved_data.end_date, null, false);
              leave.approved_data.part_day = this.formatPartDay(leave.approved_data.part_day);
            }

            const today = new Date();
            // estimated_to_date is the start of the leave request OR today if the start date is in the past
            leave.info.estimated_to_date = leave.start_date > today ? leave.start_date : today;
            resolve(leave);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postLeave(token: string, row_version: number, response_date: any, approving_flag: boolean) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPostPayHero('leaverequest/token/respond',
        {
          leave_token: token,
          row_version,
          response_date: response_date ? TimeUtilService.formatDateForPosting(response_date, false) : null,
          approving_flag
        })
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }

  getSignupTokenInfo(token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('user/registered', { token_uid: token })
        .then((result) => {
          if (result !== null) {
            if (result.expired) { reject('This invitation is no longer valid.'); }
            else if (result.invalid) {
              reject('There was an issue with the account registration link or the link is no longer valid.'
                + ' You can request a new link from your company administrator.');
            }
            resolve(result);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject();
        });
    });
  }

  postAdminAcceptInvite(token: string, name: string, email: string, password: string, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPostPayHero('company/admin/accept',
        {
          token_uid: token,
          full_name: name,
          registered_email: email,
          password,
          two_factor_token
        })
        .then((data) => {
          if (data && data.length) { data = data[0]; }
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  postEmployeeAcceptInvite(token: string, email: string, password: string, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPostPayHero('employee/acceptinvite',
        {
          token_uid: token,
          registered_email: email,
          password,
          two_factor_token
        })
        .then((data) => {
          if (data && data.length) { data = data[0]; }
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  getAutoPilotPayDetails(token: string) {
    return new Promise<AutoPilotPayDetails>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('autopilot/pays/details', { pay_token: token }, true)
        .then((result) => {
          if (result !== null) {
            result.pay_date = TimeUtilService.dateStringToDate(result.pay_date);
            resolve(result);
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  autoPilotSendPay(token: string) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPostPayHero('autopilot/pays/send',
        {
          pay_token: token
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  // Note this is now posting to the subscription API
  continueFlexiTimeMigration(token: string, email: string, password: string, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {
      this.dbUtilService.APIPost('flexitime/migrate/continue',
        {
          migration_token: token,
          two_factor_token,
          registered_email: email,
          password
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  // AKAHU CALLS //
  /////////////////////////////////////

  authenticateAkahuTwoFactor(product_session: any, login_two_factor_token: string = null) {
    return new Promise<any>((resolve, reject) => {

      const params = {
        login_two_factor_token
      };

      this.dbUtilService.APIPostPayHero('akahu/authenticate2fa', params, true, product_session)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  verifyAkahuTwoFactor(product_session: any, two_factor_verification_token: string, two_factor_code: string) {
    return new Promise<any>((resolve, reject) => {

      const data = {
        two_factor_verification_token,
        two_factor_code
      };

      this.dbUtilService.APIPostPayHero('akahu/verify2fa', data, true, product_session)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAkahuAccounts(product_session: any, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {

      const params = {
        two_factor_token
      };

      this.dbUtilService.APIGetPayHero('akahu/accounts', params, true, null, product_session)
        .then((result) => {
          const akahu_accounts = [];

          for (const account of result) {
            akahu_accounts.push(this.setupAkahuAccount(account));
          }

          resolve(akahu_accounts);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPayRecipients(product_session: any, pay_key: number) {
    return new Promise<any>((resolve, reject) => {

      const params = {
        pay_key
      };

      this.dbUtilService.APIGetPayHero('pay/recipients', params, true, null, product_session)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  makeAkahuPayment(product_session: any, two_factor_token: string, pay_key: number, akahu_account: any) {
    return new Promise<any>((resolve, reject) => {

      const data = {
        two_factor_token,
        pay_key,
        akahu_account
      };

      this.dbUtilService.APIPostPayHero('akahu/payment', data, true, product_session)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setupAkahuAccount(akahu_account: any) {
    const refreshedDate = new Date(akahu_account.refreshed.balance);
    const currentDate = new Date();

    const timeDiff = currentDate.getTime() - refreshedDate.getTime();
    const minsDiff = Math.trunc(timeDiff / 1000 / 60);
    const hoursDiff = Math.trunc(minsDiff / 60);

    const connection = akahu_account.connection.name;

    if (minsDiff < 60) {
      akahu_account.last_refreshed = connection + ' accounts updated ' + (minsDiff >= 2 ? minsDiff + ' minutes ago' : ' just now');
    }
    else {
      akahu_account.last_refreshed = connection + ' accounts updated ' + hoursDiff + (hoursDiff === 1 ? ' hour ago' : ' hours ago');
    }

    return akahu_account;
  }

  /////////////////////////////////////

  initiateDirectDebitPayment(token: string, pay_key: number) {
    return new Promise<void>((resolve, reject) => {

      const params = {
        pay_now_token: token,
        pay_key
      };

      this.dbUtilService.APIPostPayHero('autopilot/pays/directdebit', params, true)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


}
