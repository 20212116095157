import { Component, Input, HostListener } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'ph-checkbox',
  templateUrl: './ph-checkbox.component.html',
  styleUrls: ['./ph-checkbox.component.scss'],
  providers: [MakeProvider(PhCheckboxComponent)]
})
export class PhCheckboxComponent extends AbstractValueAccessor {
  _value: boolean;

  @Input() disableInternalToggle: boolean = false;
  @Input() checkboxLabel: string = null;
  @Input() btnSmall: boolean = true;

  @HostListener('click', ['$event'])
  handleClick() {
    if (!this.disableInternalToggle) {
      this.value = !this.value;
    }
  }
}
