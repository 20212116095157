import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenDroppahComponent } from './token-droppah.component';
import { LeaveRequestComponent } from './leave-request/leave-request.component';
import { CoverRequestComponent } from './cover-request/cover-request.component';
import { UIRouterModule } from '@uirouter/angular';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';


export const TOKEN_DROPPAH_CONFIG: any[] = [
  {
    name: 'tokenDroppah',
    url: '/token/droppah',
    component: TokenDroppahComponent,
    abstract: true
  },
  {
    name: 'tokenDroppah.leaveRequest',
    url: '/leaveRequest?token',
    component: LeaveRequestComponent,
    params: {
      token: null,
    }
  },
  {
    name: 'tokenDroppah.coverRequest',
    url: '/coverRequest?token',
    component: CoverRequestComponent,
    params: {
      token: null,
    }
  }
];


@NgModule({
  declarations: [
    TokenDroppahComponent,
    LeaveRequestComponent,
    CoverRequestComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild({ states: TOKEN_DROPPAH_CONFIG })
  ]
})
export class TokenDroppahModule { }
