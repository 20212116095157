
import { Component, OnInit, Input, Output, EventEmitter, HostBinding, ViewChild, ElementRef, OnDestroy, ComponentRef, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SortUtilService } from './../../services/sort-util/sort-util.service';
import { BackdropComponent } from './../backdrop/backdrop.component';
import { DomService } from './../../services/dom/dom.service';

import * as $ from 'jquery';
import * as _ from 'lodash-es';

@Component({
  selector: 'list-drop-down',
  templateUrl: './list-drop-down.component.html',
  styleUrls: ['./list-drop-down.component.scss']
})
export class ListDropDownComponent implements OnInit, OnDestroy {

  @ViewChild("ngbDropdown") ngbDropDown: NgbDropdown;

  @HostBinding('style')
  get style() {
    const getCustomStyles = () => {
      let styles = '';

      if (this.buttonFillWidth === true) {
        if (this.buttonTypeClass !== '-type-icon') {
          styles += '--listDropDown-button-width: 100%;';
        }
        else {
          styles += '--listDropDown-button-width: --listDropDown-button-size;';
        }
      }
      if (this.buttonFontSize !== null) {
        styles += '--listDropDown-button-fontSize: ' + this.buttonFontSize + ';';
      }
      if (this.buttonIconSize !== null) {
        styles += '--listDropDown-button-iconSize: ' + this.buttonIconSize + ';';
      }
      if (this.errorIconSize !== null) {
        styles += '--listDropDown-error-iconSize: ' + this.errorIconSize + ';';
      }
      return styles;
    };

    return this.sanitizer.bypassSecurityTrustStyle(getCustomStyles());
  }

  // Item properties
  @Input() itemNameProperty: string = null;
  @Input() itemDescriptionProperty: string = null;
  @Input() itemColorProperty: string = null;
  @Input() itemColorStyle: string = 'line';
  @Input() itemNameFontWeight: string = null;
  @Input() itemFontColorProperty: string = null;
  @Input() itemDisabledProperty: string = null;
  @Input() itemHideProperty: string = null;
  @Input() itemSelectedProperty: string = null;
  @Input() itemIsToggleableProperty: string = null;
  @Input() itemToggleValueProperty: string = null;
  @Input() itemCheckedValueProperty: string = null;
  @Input() itemIsCheckableProperty: string = null;
  @Input() itemNestedListProperty: string = null;
  @Input() itemSortProperty: string = null;
  @Input() itemProductValueProperty: string = null;
  @Input() itemDescriptionWrap: boolean = false;


  @Input() includeEmptyItem: boolean = false;
  @Input() emptyItemLabel: string = null;
  @Input() emptyItemValue: any = null;
  @Input() emptyItemColour: string = null;

  // Search
  @Input() searchBar: boolean = false;

  // Button modifier classes.
  // Check styles/buttons.scss for class options
  @Input() buttonTypeClass: string = '';
  @Input() buttonColorClass: string = '';
  @Input() buttonIconClass: string = null;
  @Input() buttonIconAlignLeft: boolean = false;
  @Input() buttonImgIcon: string = null;
  @Input() buttonProductValue: string = null;

  // Button options
  @Input() buttonColorPrefix: string = null;
  @Input() buttonTitlePrefix: string = null;
  @Input() buttonTitle: string = null;
  @Input() buttonDescription: string = null;
  @Input() buttonTooltip: string = null;
  @Input() buttonFillColour: string = null;
  @Input() buttonFillWidth: boolean = false;
  @Input() buttonFontSize: string = null;
  @Input() buttonIconSize: string = null;
  @Input() errorIconSize: string = null;
  @Input() buttonTitlePlaceholder: string = null;
  @Input() buttonTitlePlaceholderDark: boolean = false;
  @Input() buttonTitleUppercase: boolean = false;

  // Misc
  @Input() listWidth: string = null;
  @Input() listHeight: string = '350px';
  @Input() listFillWidth: boolean = false;
  @Input() listAutoClose: boolean = true;
  @Input() listDisabled: boolean = false;
  @Input() listName: string = null;
  @Input() listTitle: string = null;

  @Input() highlightItemMatchingTitle: boolean = false;
  @Input() sortItems: boolean = true;
  @Input() disableBodyAppend: boolean = false;
  @Input() showError: boolean = false;
  @Input() errorMessage: string = '';

  @Input() debugId: number = null;

  private _items: any = [];
  @Input()
  set items(items: any[]) {
    this._items = items || [];
    this.itemsUpdated();
  }
  get items(): any[] {
    return this._items;
  }

  @Output() itemSelected = new EventEmitter();

  dropdownId: number = Math.floor(Math.random() * 10000000);
  searchBarHeight: number = 54;
  baseContainerHeight: number = 18;

  selectedNestedListParent: any = null;
  titleMatchesListItem: boolean = false;

  dropUp: boolean = false;
  alignLeft: boolean = false;

  search: string = '';
  filteredItems: any[] = [];
  searchedItems: any[] = [];

  highlightedSearchItemIndex: number = null;

  backdropRef: ComponentRef<BackdropComponent> = null;

  get isDoubleArray(): boolean {
    const items = !!this.selectedNestedListParent ?
      this.selectedNestedListParent[this.itemNestedListProperty] :
      this.items;

    return items.length && items[0] instanceof Array;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private eRef: ElementRef,
    private domService: DomService
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.backdropRef) {
      this.domService.closeBackdrop();
    }
  }

  dropdownToggled(isOpen: boolean) {
    if (isOpen) {
      this.focusSearchInput();
      this.backdropRef = this.domService.openBackdrop();
    }
    else {
      this.domService.closeBackdrop();
      this.highlightedSearchItemIndex = null;
    }
  }

  focusSearchInput() {
    if (this.searchBar) {
      setTimeout(() => {
        $('#ngbDropDown_search_' + this.dropdownId).trigger('focus');
      });
    }
  }

  closeDropdown() {
    this.domService.closeBackdrop();
    this.ngbDropDown.close();
  }

  itemsUpdated() {
    // Sort by default
    if (!!this.sortItems) {
      if (this.isDoubleArray) {

        for (const itemGroup of this._items) {
          this.sortGroup(itemGroup);
        }
      }
      else {
        this.sortGroup(this._items);
      }
    }

    this.updateFilteredItems();
  }

  determineDropAlignment(event: any) {
    const windowHeight = $(window).height();
    const windowWidth = $(window).width();
    const yOffsetFromWindow = $(event.target)[0].getBoundingClientRect().top;
    const xOffsetFromWindow = $(event.target)[0].getBoundingClientRect().left;

    if (this.listFillWidth) {
      this.listWidth = this.eRef.nativeElement.offsetWidth + 'px';
    }

    this.dropUp = yOffsetFromWindow > (windowHeight / 2);
    this.alignLeft = xOffsetFromWindow < (windowWidth / 2);
  }

  sortGroup(items: any[]) {
    const sortProperty = this.itemSortProperty || this.itemNameProperty || null;
    SortUtilService.sortList(items, sortProperty);
  }

  clearSearchInput() {
    this.search = '';
  }

  // Need to filter out hidden items
  updateFilteredItems() {
    this.filteredItems = [];

    // Nested items list
    if (this.selectedNestedListParent !== null) {
      const items = this.selectedNestedListParent[this.itemNestedListProperty];

      if (this.itemHideProperty) {

        for (const item of items) {
          if (!item[this.itemHideProperty]) {
            this.filteredItems.push(item);
          }
        }
      }
      else {
        this.filteredItems = items;
      }
    }
    // Primary items list
    else {
      const items = this._items;

      if (this.itemHideProperty) {
        // 2D Array
        if (this.isDoubleArray) {

          for (let i = 0; i < items.length; i++) {
            this.filteredItems.push([]);

            for (const item of items[i]) {
              if (!item[this.itemHideProperty]) {
                this.filteredItems[i].push(item);
              }
            }
          }
        }
        // 1D Array
        else {

          for (const item of items) {
            if (!item[this.itemHideProperty]) {
              this.filteredItems.push(item);
            }
          }
        }
      }
      else {
        this.filteredItems = items;
      }
    }

    this.searchUpdated();
  }

  // Further need to filter items to those that match the search value
  searchUpdated() {
    if (this.searchBar || this.search === '') {

      const searchString = this.search.toUpperCase();
      this.searchedItems = [];
      // 2D Array
      if (this.isDoubleArray) {

        for (const itemList of this.filteredItems) {
          const searchedItemList = [];

          for (const item of itemList) {
            if (this.itemMatchesSearch(item, searchString)) {
              searchedItemList.push(item);
            }
          }

          if (itemList.length) {
            this.searchedItems.push(searchedItemList);
          }
        }
      }
      // 1D Array
      else {
        for (const filteredItem of this.filteredItems) {

          if (this.itemMatchesSearch(filteredItem, searchString)) {
            this.searchedItems.push(filteredItem);
          }
          else if(searchString === '') {
            this.searchedItems.push(filteredItem);
          }
        }
      }
    }
    else {
      this.searchedItems = this.filteredItems;
    }
  }

  itemMatchesSearch(item: any, search_string: string) {
    if (!!item) {
      const item_name = (this.itemNameProperty ? item[this.itemNameProperty] : item).toUpperCase();
      const matches_name = item_name.indexOf(search_string) !== -1;

      const item_description = (this.itemDescriptionProperty ? item[this.itemDescriptionProperty] : '').toUpperCase();
      const matches_description = !!item_description && item_description.indexOf(search_string) !== -1;

      return matches_name || matches_description;
    }
  }

  selectItem(item: any) {
    // Item has a nested list so click through to this list instead
    if (
      this.itemNestedListProperty &&
      item[this.itemNestedListProperty]
    ) {

      this.selectedNestedListParent = item;

      if (this.search) {
        this.search = '';
      }
      this.updateFilteredItems();
    }
    else {
      const parent_item = this.selectedNestedListParent || null;
      this.selectedNestedListParent = null;

      setTimeout(() => {
        item = this.itemSelectedProperty ? item[this.itemSelectedProperty] : item;
        this.itemSelected.emit({ item, parent_item });
      });

      this.updateFilteredItems();
      // If the item selected is an end node of a nested list, close the list
      if (this.itemNestedListProperty !== null && !item[this.itemNestedListProperty]) {
        this.closeDropdown();
      }
    }
  }

  backFromNestedList(event: any) {
    event.stopPropagation();
    this.selectedNestedListParent = null;

    if (this.search) {
      this.search = '';
    }
    this.updateFilteredItems();
  }

  keyPressed(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
        if (
          this.highlightedSearchItemIndex === null ||
          this.highlightedSearchItemIndex === this.searchedItems.length - 1
        ) {
          this.highlightedSearchItemIndex = 0;
        }
        else {
          this.highlightedSearchItemIndex++;
        }
        break;
      case 'ArrowUp':
        if (
          this.highlightedSearchItemIndex === null ||
          this.highlightedSearchItemIndex === 0
        ) {
          this.highlightedSearchItemIndex = this.searchedItems.length - 1;
        }
        else {
          this.highlightedSearchItemIndex--;
        }
        break;
      case 'Enter':
        if (this.highlightedSearchItemIndex !== null) {
          this.selectItem(this.searchedItems[this.highlightedSearchItemIndex]);
          this.closeDropdown();
        }
        break;
    }
  }

}
