import { Component, OnInit, ElementRef, ViewChild, QueryList, ViewChildren, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TimeUtilService } from 'src/app/services/time-util/time-util.service';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import * as  _ from 'lodash-es';




import { ModalService } from '../../services/modal/modal.service';
import { UserService } from '../../services/user/user.service';


type AmcDay = {
  date: Date,
  style: string,
  hide: boolean,
  keyDate?: string

}

type AmcMonth = {
  date: Date,
  weeks: AmcDay[][]
}

@Component({
  selector: 'payhero-calendar',
  templateUrl: './payhero-calendar.component.html',
  styleUrls: ['./payhero-calendar.component.scss']
})
export class PayheroCalendarComponent implements OnChanges {

  currentMonthStart: Date = TimeUtilService.getFirstDayOfMonthFromDate(new Date());

  monthList: AmcMonth[] = [];


  @Input() pay_period_start: Date = null;
  @Input() pay_period_end: Date = null;
  @Input() pay_day: Date = null;


  constructor(
    public modalService: ModalService,
    public userService: UserService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
      if ((this.pay_period_start && this.pay_day && this.pay_period_end)) {
        this.payCycleChanged();
      }
  }


  async payCycleChanged() {

    this.monthList = [];

    let payRoll_start: Date = _.cloneDeep(this.pay_period_start);
    let payRollEnd_date: Date = _.cloneDeep(this.pay_period_end);

    let start_date: Date;
    let payRollDates = [];
    let end_date: Date;
    let amount: number = 2;

    start_date = TimeUtilService.incrementMonth(TimeUtilService.getFirstDayOfMonthFromDate(payRoll_start), 0);
    end_date = (this.pay_day > payRollEnd_date ? this.pay_day : payRollEnd_date);
    payRollDates = payRollDates.concat(TimeUtilService.generateDatesInRange(payRoll_start, payRollEnd_date));
    let dateList = this.generateAmcDayList(start_date, end_date, payRollDates);

    if (end_date.getMonth() === start_date.getMonth()) amount = 1;

    const addToMonthList = this.formatIntoAmcMonthList(dateList, amount, start_date, end_date);
    this.monthList = [...this.monthList, ...addToMonthList];

  }

  generateAmcDayList(start_date: Date, end_date: Date, payRollDates: Date[]): AmcDay[] {
    const generalDateList: AmcDay[] = [];
    let current_date: Date = start_date;

    while (current_date <= end_date) {
      let newDate: AmcDay = {
        date: current_date,
        style: '',
        hide: false,
      };
      if (!!payRollDates.find(item => { return TimeUtilService.datesAreEqual(current_date, item); })) {
        if (TimeUtilService.datesAreEqual(newDate.date, payRollDates[0])) {
          newDate.style = 'partial-payroll-day';
          newDate.keyDate = 'first-payroll-day';
        } else if (TimeUtilService.datesAreEqual(newDate.date, payRollDates[payRollDates.length - 1])) {
          newDate.style = 'last-payroll-day';
        }
        else {
          newDate.style = 'payroll-day';
        }
      }
      if (TimeUtilService.datesAreEqual(newDate.date, this.pay_day)) {
        newDate.keyDate = 'payDay-Background';
      }
      generalDateList.push(newDate);
      current_date = TimeUtilService.incrementDate(current_date, 1);
    }
    return generalDateList;
  }



  formatIntoAmcMonthList(dayList: AmcDay[], amountOfMonths: number, start_date: Date, end_date: Date) {
    let monthStartDate = TimeUtilService.getFirstDayOfMonthFromDate(start_date);

    let originalMonth = _.cloneDeep(monthStartDate);
    let monthList: AmcMonth[] = [];

    for (let i = 0; i < Math.abs(amountOfMonths); i++) {
      const firstDay = _.cloneDeep(monthStartDate);
      firstDay.setDate(2 - (monthStartDate.getDay() === 0 ? 7 : monthStartDate.getDay()));

      const slideMonth: AmcMonth = {
        date: monthStartDate,
        weeks: []
      };

      let currentDay = _.cloneDeep(firstDay);
      let month = monthStartDate.getMonth();
      let index = TimeUtilService.getDayIndex(monthStartDate, firstDay);

      //checks to see how manys weeks are needed to cover just the month
      index = (index >= 4 ? 6 : 5);

      //if not original month checks how many weeks are needed to cover up to end date
      if (month !== start_date.getMonth()) index = Math.ceil(end_date.getDate() / 7) + 1;


      // Iterate weeks
      for (let j = 0; j < index; j++) {
        const week = [];
        // Iterate days
        for (let k = 0; k < 7; k++) {
          let day = dayList.find(item => { return TimeUtilService.datesAreEqual(item.date, currentDay); });
          if (!day || !TimeUtilService.datesAreEqual(TimeUtilService.getFirstDayOfMonthFromDate(currentDay), monthStartDate)) {
            day = {
              date: currentDay,
              style: '',
              hide: false,
            };
          }
          if (day.date < monthStartDate || day.date.getMonth() != month || (day.date > end_date && day.date.getMonth() !== originalMonth.getMonth())) {
            day.hide = true;
          }
          week.push(day);
          currentDay = _.cloneDeep(currentDay);
          currentDay.setDate(currentDay.getDate() + 1);
        }
        slideMonth.weeks.push(week);

      }
      monthStartDate = _.cloneDeep(monthStartDate);
      monthStartDate.setMonth(monthStartDate.getMonth() + 1);
      monthList.push(slideMonth);
    }
    return monthList;
  }


}



