
import { Injectable } from '@angular/core';

import { env } from '../../../environments/environment';
import { SocketEventType } from './../../app.types';

import { io, Socket } from 'socket.io-client';
import * as _ from 'lodash-es';

type SocketEventSubscription = Record<
  string,
  (event_data: any) => void
>;
type SocketEvent = { event_type: SocketEventType, event_data: any };

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public subscription_api_url: string = env.subscription.api_url;

  socket: Socket = null;

  event_subscriptions: Record<SocketEventType, SocketEventSubscription> = this._newEventSubcriptionMap();
  pending_event_subscriptions: Record<SocketEventType, SocketEventSubscription> = this._newEventSubcriptionMap();

  constructor() { }

  get socket_id(): string {
    return this.socket.id;
  }

  initWebSocket(connectCallback: () => void = null) {
    if (this.socket) {
      return !!(this.socket.connected && connectCallback) ? connectCallback() : null;
    }

    this.socket = io(
      this.subscription_api_url,
      { transports: ['websocket','polling'] }
    );

    this.socket.on('connect', () => this.handleConnect(connectCallback));
    this.socket.on('message', (event: SocketEvent) => this.handleMessage(event));
    this.socket.on('disconnect', (event: any) => this.handleClose(event));
    this.socket.on('error', (event: any) => this.handleError(event));
  }

  updateSocketSessionKey(encoded_session_key: string) {
    this.initWebSocket(() => {
      this.socket.emit('session_key_update', encoded_session_key);
    });
  }

  closeSocket() {
    console.log('closeSocket');
    if (!this.socket) { return; }

    this.socket.disconnect();

    // Remove stored events from eventMap + unsubscribedEvents and uninitialise socket.
    this.socket = null;
    this.event_subscriptions = this._newEventSubcriptionMap();
    this.pending_event_subscriptions = this._newEventSubcriptionMap();
  }

  handleConnect(connectCallback: () => void) {
    // Copy events from previous connection across to unsubscribedEvents so that they will be registered to this socket.
    if (!_.isEmpty(this.event_subscriptions)) {
      this.pending_event_subscriptions = _.assign(this.event_subscriptions, this.pending_event_subscriptions);
      this.event_subscriptions = this._newEventSubcriptionMap();
    }

    // Send subscribe messages that were queued due to socket being closed.
    this.subscribeToUnsubscribedEvents();

    if (connectCallback) {
      connectCallback();
    }
  }

  handleMessage(event: SocketEvent) {
    if (this.event_subscriptions[event.event_type]) {
      const event_subscription = this.event_subscriptions[event.event_type];

      for (const component_name of Object.keys(event_subscription)) {
        event_subscription[component_name](event.event_data);
      }
    }
  }

  handleClose(event: any) {
    console.log('Socket Close');
    console.log(event);
  }

  handleError(event: any) {
    console.log('handleError', event);
    if (event === 'unauthorized') {
      this.closeSocket();
    }
  }

  subToEvent(
    event_type: SocketEventType,
    component_name: string,
    callback: (data: any) => void
  ): boolean {
    // Socket not yet initialised or not completely ready
    if (!this.socket || !this.socket.connected) {
      // Socket not yet initialised
      if (!this.socket) {
        this.initWebSocket();
      }
      // If socket is not ready then store event until socket is ready.
      this.pending_event_subscriptions[event_type][component_name] = callback;
      return false;
    }
    // Socket ready and not already subscribed to event for component
    else if (!this.event_subscriptions[event_type][component_name]) {
      // Send subscribe event to Server.
      this.socket.send({ event_type: 'subscribe', event_data: event_type });

      // Map callback function to event. This callback will be called in handleMessage() if a matching event_type is received.
      this.event_subscriptions[event_type][component_name] = callback;
    }
    return true;
  }

  subscribeToUnsubscribedEvents() {
    for (const event_type of Object.keys(this.pending_event_subscriptions) as SocketEventType[]) {
      for (const component_name of Object.keys(this.pending_event_subscriptions[event_type])) {
        const callback = this.pending_event_subscriptions[event_type][component_name];

        if (this.subToEvent(event_type, component_name, callback)) {
          delete this.pending_event_subscriptions[event_type][component_name];
        }
      }
    }
  }

  private _newEventSubcriptionMap(): Record<SocketEventType, SocketEventSubscription> {
    return {
      card_event: {},
      flexitime_account_updated: {},
      Integration_Login_Success: {},
      Integration_Login_Not_Found: {},
      Integration_Connection_Complete: {},
      Integration_Connection_Exists: {},
      Integration_Signup_Complete: {},
      Integration_Error: {}
    };
  }

}
