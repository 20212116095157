import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appNumber'
})
export class AppNumberPipe implements PipeTransform {

  constructor(
    public decimalPipe: DecimalPipe
  ) { }

  transform(value: number | string, num_dp: number = 2) {
    const digitsInfo = '1.' + num_dp + '-' + num_dp;
    return this.decimalPipe.transform(value, digitsInfo);
  }

}
