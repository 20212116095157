<div class="bankEdit-sectionHeader">
  Authority to Accept Direct Debits
</div>

<div class="bankEdit-sectionSubtitle">
  Authorisation Code - {{dd_auth_number}}
</div>

<div class=""
     *ngIf="current_step === 'FORM'">

  <!--Account Name-->
  <div class="ph-formItem-container -smallForm">
    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Name of Account to be Debited
      </div>
      <div class="ph-formItem-value">

        <input class="ph-formItem-input -invalid"
               type="text"
               placeholder="e.g. Current Account"
               [ngClass]="{ '-invalid': bank_errors.account_name}"
               [(ngModel)]="subscription.bank_account_name">
      </div>
      <div class="ph-formItem-error"
           *ngIf="bank_errors.account_name">
        Must provide a Bank Account Name
      </div>
    </div>

    <!--Account Number-->
    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Bank Account Number
      </div>
      <div class="ph-formItem-value">
        <input class="ph-formItem-input"
               type="text"
               placeholder="00-0000-0000000-00"
               mask="00-0000-0000000-009"
               [dropSpecialCharacters]="false"
               [ngClass]="{ '-invalid': bank_errors.account_number }"
               [(ngModel)]="subscription.bank_account_number">
      </div>
      <div class="ph-formItem-error"
           *ngIf="bank_errors.account_number">
        Must provide a valid Bank Account Number
      </div>
    </div>

    <!--Bank Name-->
    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Bank Name
      </div>
      <div class="ph-formItem-value">
        <input class="ph-formItem-input"
               placeholder="e.g. BNZ"
               [ngClass]="{ '-invalid': bank_errors.bank_name }"
               [(ngModel)]="subscription.bank_name">

      </div>
      <div class="ph-formItem-error"
           *ngIf="bank_errors.bank_name">
        Must provide a valid Bank Name
      </div>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Information to Appear on Your Bank Statement
      </div>
      <div class="ph-formItem-value">
        <input class="ph-formItem-input"
               type="text"
               placeholder="Payer Code"
               [ngClass]="{ '-invalid': bank_errors.bank_code }"
               [(ngModel)]="subscription.bank_code">
      </div>
      <div class="ph-formItem-error"
           *ngIf="bank_errors.bank_code">
        Bank Code must be 15 characters or less
      </div>
    </div>
  </div>
</div>

<div class=""
     *ngIf="current_step === 'TERMS'">
  <div class="ph-formItem">
    <div class="ph-formItem-value">
      By setting up the Direct Debit Authority, You confirm that:
    </div>
    <ol type="a">
      <li>You are either the sole account holder* or have been duly authorised by the sole account holder to establish
        and manage direct debit transactions on the account.</li>
      <li>All information provided regarding the account is accurate and complete.</li>
    </ol>
    <div style="margin-left: 10px">
      *Please note: if this bank account requires multiple signatories you cannot use direct debit. Please select
      another payment method.
    </div>
    <div class="ph-formItem-value -spaceAbove">
      You authorise FlexiTime Limited (FlexiTime) to debit the bank account with all amounts which FlexiTime may
      initiate by direct debit.
    </div>
    <div class="ph-formItem-value -spaceAbove">
      FlexiTime reserves the right to verify the authority to set up a direct debit on the bank account. This may
      include, but is not limited to, requesting documentation or confirmation from the account holder or financial
      institution.
    </div>
    <div class="ph-formItem-value -spaceAbove">
      You acknowledge and accept the terms of the <a href="https://www.flexitime.works/subscription-agreement"
         target="_blank">FlexiTime Subscription Agreement</a>.
    </div>
    <div class="ph-formItem-value -spaceAbove">
      You agree that the bank accepts the authority subject to the <a
         href="https://www.flexitime.works/subscription-agreement/#DirectDebit"
         target="_blank">Bank Terms and Conditions</a>.
    </div>
  </div>
</div>
