<div class="login-logo-container">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_White.svg">
</div>

<div class="ph-autoPilot-card"
     *ngIf="loading">
  <div class="ph-loadingIcon">
    <img src="assets/imgs/App_Loader.gif">
  </div>
</div>


<div class="ph-autoPilot-card ph-ap-twoFactor-container"
     *ngIf="!loading && state === 'TWOFACTOR'">
  <h1 class="ph-ap-twoFactor-title">
      Two Factor Authentication
  </h1>

  <p class="ph-ap-twoFactor-info">
      Please enter the two factor authentication code for <span>{{two_factor_user_name}} ({{two_factor_registered_email}})</span>
  </p>

  <div class="ph-ap-twoFactor-error"
       *ngIf="two_factor_code_incorrect"
       [ngClass]="{ '-hidden': !two_factor_code_incorrect }">
      <i class="ion ion-ios-warning sm-icon"></i> Invalid code. Please retry.
    </div>

  <form class="app-auth-form"
        (ngSubmit)="verifyTwoFactor()">
      <input class="app-input -inputLarge"
             #twoFactorCodeElem
             name="code"
             placeholder="Verification Code"
             autocomplete="off"
             [(ngModel)]="two_factor_code"
             [disabled]="verifying"
             [autofocus]>

      <button class="-color-flexitime-yellow app-auth-submit"
              [disabled]="loading || verifying || !two_factor_code">
        Verify
      </button>
    </form>
</div>

<div class="ph-autoPilot-card ph-ap-payment-container"
     *ngIf="!loading && ['ACCOUNTS', 'PAYMENTS', 'REVIEW'].includes(state)">

  <div class="ph-ap-payment-header">
    <img [src]="'assets/product-imgs/payhero/integrations/Akahu_logo.png'">
    <div class="headerCenter">Pay with Akahu</div>
  </div>

  <stepped-progress
    [steps]="['Account', 'Payments', 'Review']"
    [currentStep]="current_step">
  </stepped-progress>

  <div class="ph-ap-accounts-container"
        *ngIf="state === 'ACCOUNTS'">

    <div class="ph-ap-accounts-listContainer">
      <div class="akahuAccount"
          [ngClass]="{selected: account.selected}"
          *ngFor="let account of akahuAccounts"
          (click)="selectAkahuAccount(account)">

        <div class="akahuAccount-radioBtnOuter">
            <div class="akahuAccount-radioBtnInner"
                *ngIf="account.selected">
            </div>
        </div>

        <img [src]="account.connection.logo"/>

        <div class="akahuAccount-bankInfo">
          <div class="akahuAccount-accountInfo">
            <span class="akahuAccount-accountName">{{account.name}}</span>
            <span class="akahuAccount-accountNumber">{{account.formatted_account}}</span>
          </div>

          <div class="akahuAccount-accountBalance">
              <span class="akahuAccount-balanceName">Available Balance:</span>
              <span class="akahuAccount-balance">{{account.balance.available | currency}}</span>
          </div>
        </div>

      </div>
    </div>

   <div class="ph-ap-accounts-lastRefresh"
          *ngFor="let lastRefresh of lastRefreshed">
        {{lastRefresh}}
    </div>
  </div>

  <div class="ph-ap-payments-container"
        *ngIf="state === 'PAYMENTS'">

    <div class="ph-ap-payments-listContainer">
      <dp-list #paymentsList
              class="ph-ap-payments-list"
              [noItemsMessage]="'There are no Payment Recipients for this Pay'"
              [primarySortProperty]="'client_name'"
              [itemType]="'client'"
              [itemTypePlural]="'payments'"
              [itemSelectDisabled]="true"
              [items]="payments">
        <ng-template dpListItem
                      let-item>

          <div class="dp-list-itemCell -akahuPaymentLine">
            <div class="-akahuPaymentDetail">
              {{item.recipient}}
              <span>{{item.bank_account}}</span>
            </div>

            <div class="-akahuPaymentAmount">
              {{item.amount | currency}}
            </div>
          </div>

        </ng-template>
      </dp-list>
    </div>

    <div class="-akahuPaymentLine -totalPayment">
      <div class="-akahuPaymentDetail"></div>

      <div class="-akahuPaymentAmount">
        <span>Payment Amount</span>
        {{paymentAmount | currency}}
      </div>
    </div>
  </div>

  <div class="ph-ap-review-container"
        *ngIf="state === 'REVIEW'">
    <div class="-akahuPaymentLine -wrap">
      <div class="-akahuPaymentDetail"><span>Account:</span></div>
      <div class="-akahuPaymentAmount-account">
          <img [src]="selectedAkahuAccount.connection.logo"/>
          <div class="-akahuPaymentAmount-info">
              {{selectedAkahuAccount.name}}
              <span>{{selectedAkahuAccount.formatted_account}}</span>
          </div>
      </div>
    </div>

    <div class="-akahuPaymentLine -wrap">
      <div class="-akahuPaymentDetail"><span>Available Balance:</span></div>
      <div class="-akahuPaymentAmount">{{selectedAkahuAccount.balance.available | currency}}</div>
    </div>

    <div class="-akahuPaymentLine -wrap">
      <div class="-akahuPaymentDetail"><span>Payment Amount:</span></div>
      <div class="-akahuPaymentAmount">{{paymentAmount | currency}}</div>
    </div>

    <div class="-akahuPaymentLine -wrap">
      <div class="-akahuPaymentDetail"><span>Remaining Balance:</span></div>
      <div class="-akahuPaymentAmount">{{selectedAkahuAccount.balance.available - paymentAmount | currency}}</div>
    </div>

    <div class="ph-ap-review-acknowledge">
      <div>
        <ph-checkbox [(ngModel)]="acknowledged">
        </ph-checkbox>
      </div>

      <div>
          I understand that by clicking "Pay Now" payments will be made from the selected bank account.
      </div>
    </div>
  </div>

  <div class="ph-ap-btnFooter">
    <button class="-color-white-outline ph-ap-btnLeft"
            *ngIf="state === 'PAYMENTS' || state === 'REVIEW'"
            (click)="backStep()">
      Back
    </button>

    <button class="-color-payhero ph-ap-btnRight"
            *ngIf="state === 'ACCOUNTS' || state === 'PAYMENTS'"
            (click)="nextStep()">
      Next
    </button>

    <button class="-color-success ph-ap-btnRight"
            *ngIf="state === 'REVIEW'"
            [disabled]="!acknowledged"
            (click)="payNow()">
      Pay Now
    </button>
  </div>
</div>

<div class="ph-autoPilot-card ph-autoPilot-success-state"
     *ngIf="!loading && state === 'SUCCESS'">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_Success.png">
  <h1><strong><span style="color: #5eb22e">Employees Paid!</span></strong> A confirmation email is on its way</h1>
  <p (click)="loginToPayHeroPay()"><a>View Pay</a></p>
</div>

<!-- The token is no longer valid -->
<div class="ph-autoPilot-card ph-autoPilot-error-state"
     *ngIf="!loading && state === 'ERROR'">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_Failed.png">
  <h3>{{error_message}}</h3>
  <p (click)="loginToPayHero()"><a>Sign In to PayHero</a></p>
</div>


<footer class="ph-footer ph-auth-footer -desktop-only">
  <img src="assets/product-imgs/payhero/PayHero_Full_Registered.svg">
</footer>
