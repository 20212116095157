import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';

import { Account } from '../../models/account/account';

@Component({
  selector: 'app-transfer-ownership-modal',
  templateUrl: './transfer-ownership-modal.component.html',
  styleUrls: ['./transfer-ownership-modal.component.scss']
})
export class TransferOwnershipModalComponent implements OnInit {

  @Input() account: Account = null;

  transfer_contact_name: string = '';
  transfer_email_address: string = '';

  error_message: string;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  transfer() {
    if (!this.transfer_contact_name) {
      this.error_message = 'Contact name required';
    }
    else if (!this.transfer_email_address) {
      this.error_message = 'Email address required';
    }
    else if (!CoreUtilService.validateEmailAddress(this.transfer_email_address)) {
      this.error_message = 'Invalid email address'
    }
    else {
      this.activeModal.close({
        account: this.account,
        transfer_contact_name: this.transfer_contact_name,
        transfer_email_address: this.transfer_email_address
      });
    }
  }

}
