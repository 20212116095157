import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { AppStateDeclaration } from './../../app.types';
import { ComponentsModule } from 'src/app/components/components.module';
import { StaffComponent } from './staff.component';
import { StaffDashComponent } from './staff-dash/staff-dash.component';
import { StaffEditComponent } from './staff-edit/staff-edit.component';

export const STAFF_CONFIG: any[] = [
  {
    name: 'app.staff',
    url: '/staff',
    component: StaffComponent,
    abstract: true
  },
  {
    name: 'app.staff.dash',
    url: '/dash',
    component: StaffDashComponent,
    default_state: true
  },
  {
    name: 'app.staff.edit',
    url: '/edit?{partner_staff_key:int}',
    component: StaffEditComponent,
    params: {
      partner_staff_key: null
    }
  }
];

@NgModule({
  declarations: [
    StaffComponent,
    StaffDashComponent,
    StaffEditComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    PipesModule,
    UIRouterModule.forChild({ states: STAFF_CONFIG })
  ]
})
export class StaffModule { }
