<div class="cPopover-popoverIcon"
     #cPopover="ngbTooltip"
     [ngbTooltip]="popoverContent"
     [container]="'body'"
     [triggers]="'click'"
     [placement]="'bottom'"
     [tooltipClass]="'cost-popover'"
     [autoClose]="false"
     (click)="openBackdrop();">
  <span>?</span>
</div>

<ng-template #popoverContent>
  <div class="cPopover-container">

    <div class="cPopover-left -mobile-only cPopover-header">
      <img *ngIf="account.company_logo"
           [src]="account.company_logo">
      <span>{{account.company_name}}</span>
    </div>

    <hr class="cPopover-divider -mobile-only">

    <div class="cPopover-row"
         *ngFor="let line of account.account_subscription?.account_subscription_lines">

      <!-- Unit Based Lines-->
      <div class="cPopover-left"
           *ngIf="line.line_type === 'Unit Based'">
        {{line.quantity}} {{line.quantity === 1 ? line.unit_description : line.unit_description_plural}}
        <span class="label">{{line.unit_fee | appCurrency}} per {{line.unit_description}}</span>
      </div>

      <!-- Other Lines-->
      <div class="cPopover-left"
           *ngIf="line.line_type !== 'Unit Based'">
        {{line.line_description}}
      </div>

      <div class="cPopover-right">
        <span>{{line.line_total | appCurrency}}</span>
      </div>
    </div>

    <hr class="cPopover-divider">

    <div class="cPopover-row -sm">
      <div class="cPopover-left">
        Total Monthly Cost
      </div>
      <div class="cPopover-right">
        <span>{{account.account_subscription?.monthly_cost | appCurrency}}</span>
      </div>
    </div>

  </div>
</ng-template>
