import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../../components/components.module';

import { TokenPayheroComponent } from './token-payhero.component';
import { ExpenseRequestComponent } from './expense-request/expense-request.component';
import { LeaveRequestComponent } from './leave-request/leave-request.component';
import { AutopilotSendPayComponent } from './autopilot-send-pay/autopilot-send-pay.component';
import { MigrationContinueComponent } from './migration-continue/migration-continue.component';
import { MigrationLoaderComponent } from './migration-loader/migration-loader.component';
import { AutopilotAkahuPaymentComponent } from './autopilot-akahu-payment/autopilot-akahu-payment.component';
import { AutopilotDirectDebitPaymentComponent } from './autopilot-direct-debit-payment/autopilot-direct-debit-payment.component';
import { AutopilotBankTransferPaymentComponent } from './autopilot-bank-transfer-payment/autopilot-bank-transfer-payment.component';

export const TOKEN_PAYHERO_CONFIG: any[] = [
  {
    name: 'tokenPayhero',
    url: '/token/payhero',
    component: TokenPayheroComponent,
    abstract: true
  },
  {
    name: 'tokenPayhero.expenseRequest',
    url: '/expenseRequest?token',
    component: ExpenseRequestComponent,
    params: {
      token: null
    }
  },
  {
    name: 'tokenPayhero.leaveRequest',
    url: '/leaveRequest?token',
    component: LeaveRequestComponent,
    params: {
      token: null
    }
  },
  {
    name: 'tokenPayhero.autoPilotSendPay',
    url: '/AutoPilot/SendPay?token',
    component: AutopilotSendPayComponent,
    params: {
      token: null
    }
  },
  {
    name: 'tokenPayhero.autoPilotAkahuPayment',
    url: '/AutoPilot/AkahuPayment?token',
    component: AutopilotAkahuPaymentComponent,
    params: {
      token: null,
      login_two_factor_token: null,
      pay_details: null
    }
  },
  {
    name: 'tokenPayhero.directDebitPayment',
    url: '/AutoPilot/DirectDebitPayment?token',
    component: AutopilotDirectDebitPaymentComponent,
    params: {
      token: null
    }
  },
  {
    name: 'tokenPayhero.bankTransferPayment',
    url: '/AutoPilot/BankTransfer?token',
    component: AutopilotBankTransferPaymentComponent,
    params: {
      token: null
    }
  },
  {
    name: 'tokenPayhero.migrationContinue',
    url: '/migrate?token&email&exists&subscribed',
    component: MigrationContinueComponent,
    params: {
      token: null,
      email: null,
      exists: null,
      subscribed: null,
      // These two are not URL params
      two_factor_token: null,
      password: null
      // //
    }
  },
  {
    name: 'tokenPayhero.migrationLoader',
    url: '/migrationloader?access_key&session_key',
    component: MigrationLoaderComponent,
    params: {
      access_key: null,
      session_key: null
    }
  }
];


@NgModule({
  declarations: [
    TokenPayheroComponent,
    ExpenseRequestComponent,
    LeaveRequestComponent,
    AutopilotSendPayComponent,
    MigrationContinueComponent,
    MigrationLoaderComponent,
    AutopilotAkahuPaymentComponent,
    AutopilotDirectDebitPaymentComponent,
    AutopilotBankTransferPaymentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild( { states: TOKEN_PAYHERO_CONFIG } )
  ]
})
export class TokenPayheroModule { }
