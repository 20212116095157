<div class="ph-modal-content subscribing"
     *ngIf="!subscriptionComplete">

  <div class="ph-modal-header">

    <i class="ion ion-ios-close lg-close"
       (click)="close()">
    </i>

    <div class="headerLeft">
      <div *ngIf="pendingOwnershipTransfer">
        Accept Transfer of {{account.company_name}}
      </div>

      <div *ngIf="changingPlan">
        Change Plan for {{account.company_name}}
      </div>

      <div *ngIf="!pendingOwnershipTransfer && !changingPlan">
        Subscribe {{account.company_name}}
      </div>

      <product-logo [fullLogoOnly]="true"
                    [productValue]="account.product_value">
      </product-logo>
    </div>

    <div class="headerProgress">
      <div class="headerProgress-step -desktop-only"
           *ngFor="let step of progressSteps; let i = index"
           [ngClass]="{ 'active': step.active }">
        {{i + 1}}. {{step.name}}
      </div>

      <div class="headerProgress-step -mobile-only active">
        {{currentStepIndex + 1}}. {{progressSteps[currentStepIndex].name}}
      </div>

      <div class="headerProgress-track">
        <div [ngClass]="{ 'complete': subscriptionComplete }"
             [ngStyle]="{ 'width': progressTrackWidth + '%' }">
        </div>
      </div>
    </div>

  </div>

  <div style="width: 100%; height: 300px"
       *ngIf="loading">
    <spinner>
    </spinner>
  </div>

  <!-- Plan Breakdown-->
  <div class="ph-modal-container"
       *ngIf="currentStep === 'PLAN'  && !loading">

    <div class="ph-modal-info info"
         *ngIf="pendingOwnershipTransfer">
      To accept ownership of {{account.company_name}} you must confirm that this account will be
      added to your subscription and agree to FlexiTime's Subscription Agreement.
    </div>

    <div class="ph-modal-info info"
         *ngIf="isPartnerAdmin && subscription.has_no_payment_method">
      Your Partner Organisation does not have a payment method set up. To subscribe an account
      the owner of your organisation must set up a payment method.
    </div>

    <span class="subscribeModal-sectionHeader planHeader">
      Plans

      <list-drop-down *ngIf="subscription.has_no_payment_method && currentStep === 'PLAN'"
                      [buttonTitle]="subscription.country.country_name"
                      [buttonColorClass]="'-color-default-clear'"
                      [buttonTypeClass]="'-type-dropdown'"
                      [buttonIconClass]="'ion-md-arrow-dropdown'"
                      [searchBar]="true"
                      [listHeight]="'300px'"
                      [listWidth]="'300px'"
                      [listDisabled]="currentStep !== 'PLAN'"
                      [itemNameProperty]="'country_name'"
                      [items]="countries"
                      (itemSelected)="selectBillingCountry($event.item)">
      </list-drop-down>
    </span>

    <div class="subscribeModal-comparePlans">
      <div class="subscribeModal-plan"
           *ngFor="let plan of availablePlans; index as i"
           [ngClass]="{
             'selected' : selectedPlanIndex === i,
             'current' : currentPlanIndex === i,
             'droppah' : account.product_value === 'DROPPAH',
             'payhero' : account.product_value === 'PAYHERO',
             'invoxy' : account.product_value === 'INVOXY',
             'karmly' : account.product_value === 'KARMLY'
            }"
           (click)="selectPlan(i)">

        <div class="subscribeModal-plan-selected"
             [ngStyle]="{ 'display' : selectedPlanIndex === i && currentPlanIndex !== i? '' : 'none' }">
          Selected
        </div>

        <div class="subscribeModal-plan-selected current"
             *ngIf="currentPlanIndex === i">
          Current
        </div>

        <span class="subscribeModal-plan-name">{{plan.plan_display_name}}</span>

        <div class="subscribeModal-plan-unit">
          <span class="subscribeModal-plan-unitPrice">${{plan.unit_fee}}</span>
          <span class="subscribeModal-plan-unitType">per {{plan.unit_description.toLowerCase()}}</span>
          <span class="subscribeModal-plan-unitType">per {{plan.billing_period_description}}</span>
        </div>

        <span class="subscribeModal-plan-baseFee"
              *ngIf="plan.period_fee">
          + ${{plan.period_fee}} per {{plan.billing_period_description}} base fee
        </span>

        <span class="subscribeModal-plan-baseFee"
              *ngIf="plan.minimum_billing_fee">
          ${{plan.minimum_billing_fee}} per {{plan.billing_period_description}} minimum fee
          <span *ngIf="plan.included_units">
            ({{plan.included_units}} {{plan.unit_description_plural.toLowerCase()}})
          </span>
        </span>

        <div class="subscribeModal-plan-radioBtn">
          <div [ngStyle]="{ 'display' : selectedPlanIndex === i ? '' : 'none' }"></div>
        </div>
      </div>
    </div>

    <div class="subscribeModal-creditsCharges"
         *ngFor="let charge of charges">
      <div class="subscribeModal-charge">
        <div class="subscribeModal-plus">
          +
        </div>
        <div class="subscribeModal-chargeName">
          {{charge.charge_description}}
        </div>
        <div class="subscribeModal-chargeAmount">
          ${{charge.charge_monthly_amount ? charge.charge_monthly_amount : charge.charge_total }}
          <div class="subscribeModal-chargeDate">
            {{charge.charge_monthly_amount ? 'per month' : ''}}
            <div>
              {{ charge.renewal_date ? 'until ' + charge.formatted_renewal_date : ''}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subscribeModal-creditsCharges"
         *ngIf="subscription.credit_amount || subscription.discount_percentage">
      <div *ngIf="subscription.credit_amount"
           class="subscribeModal-charge"
           style="border: 1px solid var(--app-color-flexitime-yellow)">
        <div class="subscribeModal-plus"
             style="color: var(--app-color-flexitime-yellow)">
          -
        </div>
        <div class="subscribeModal-chargeName">
          Credit
        </div>
        <div class="subscribeModal-chargeAmount">
          {{'-' + (subscription.credit_amount | appCurrency)}}
        </div>
      </div>
      <div *ngIf="subscription.discount_percentage"
           class="subscribeModal-charge"
           style="border: 1px solid var(--app-color-flexitime-yellow)">
        <div class="subscribeModal-plus"
             style="color: var(--app-color-flexitime-yellow)">
          -
        </div>
        <div class="subscribeModal-chargeName">
          Discount
        </div>
        <div class="subscribeModal-chargeAmount">
          {{subscription.discount_percentage + '% off'}} {{subscription.discount_until_date ? ' until ' +
          (subscription.discount_until_date | date: "dd/MM/yyyy") : ' next invoice'}}
        </div>
      </div>
    </div>

    <div class="subscribeModal-comparePlans-subtitle">
      <button class="-type-text -color-payhero-clear"
              *ngIf="account.product_value !== 'INVOXY'"
              (click)="comparePlans()">
        <span>Compare Plans & Features</span>
        <i class="ion ion-md-open"></i>
      </button>

      <span class="subscribeModal-sectionHeader"
            [ngStyle]="account.product_value === 'INVOXY' ? {'padding-top': '30px'} : ''">
        Prices are in {{subscription.country.billing_currency}} & exclude {{subscription.country.country_key === 'NZ' ?
        'GST' : 'tax'}}
      </span>
    </div>

    <div class="subscribeModal-promo"
         [ngClass]="{ 'visible' : promoCodeDescription }">
      <div *ngIf="promoCodeDescription">
        <i class="ion ion-ios-pricetag sm-icon"></i>
        Promo Code: <span>{{promoCodeDescription}}</span>
      </div>
    </div>

    <div class="app-errorMessage"
         [ngClass]="{ '-visible': !!error_message }">
      <i class="ion ion-ios-warning sm-icon"></i> {{error_message}}
    </div>

  </div>

  <!-- Billing Details Section -->
  <div class="ph-modal-container"
       *ngIf="currentStep === 'DETAILS' && !loading && !error_message">

    <div class="ph-formItem-container -smallForm">

      <!-- simplified error handling -->
      <div *ngFor="let formField of formFields">
        <form-input class="ph-formItem-input"
                    [formObject]="subscription"
                    [formFieldError]="formFieldErrors[formField.property]"
                    [formField]="formField">
        </form-input>
      </div>

      <address-input #addressInput
                     [inputLabel]="'Address'"
                     [activeValidationEnabled]="false"
                     [(ngModel)]="subscription.billing_address">
      </address-input>

      <country-input #countryInput
                     [inputLabel]="'Country'"
                     [activeValidationEnabled]="false"
                     [countryRequired]="true"
                     [readonly]="!subscription.has_no_payment_method"
                     [(ngModel)]="subscription.country">
      </country-input>

    </div>
  </div>

  <!-- Payment Method Selection -->
  <!-- <div class="ph-modal-container subscribeModal-paymentSelect"
       *ngIf="currentStep === 'PAYMENT' && selectedPaymentMethod === 'CARD' && error_message">

    <div class="app-errorMessage"
         [ngClass]="{ '-visible': !!error_message }">
      <i class="ion ion-ios-warning sm-icon"></i> {{error_message}}
    </div>
  </div> -->

  <div class="ph-modal-container subscribeModal-paymentSelect"
       *ngIf="currentStep === 'PAYMENT' && selectedPaymentMethod === 'CARD' && !loading">

    <span class="subscribeModal-sectionHeader">
      Your Payment Method
    </span>

    <sub-card-info *ngIf="subscription.subscription_card && !editingPaymentMethod && !subscription.has_no_payment_method"
                   [subscriptioncard]="subscription.subscription_card">
    </sub-card-info>

    <paystation-card-info #paystationCardInfo
                          *ngIf="editingPaymentMethod"
                          (error)="setErrorMessage($event.error)"></paystation-card-info>
    <button class="-type-text -color-payhero-clear"
            *ngIf="!editingPaymentMethod && !subscription.has_no_payment_method"
            (click)="editCard()">
      Change Card
    </button>


    <div class="subscribeModal-paymentSelect-empty"
         *ngIf="!editingPaymentMethod && subscription.has_no_payment_method"
         (click)="editCard()">
      + Credit Card
    </div>

    <div class="subscribeModal-paymentSelect-swap"
         *ngIf="subscription.has_no_payment_method && subscription.country.country_key === 'NZ'">
      I'd like to use <a (click)="selectDirectDebitPaymentMethod()">Direct Debit</a> as my payment method
    </div>

    <div class="app-errorMessage"
         [ngClass]="{ '-visible': !!error_message }">
      <i class="ion ion-ios-warning sm-icon"></i> {{error_message}}
    </div>
  </div>

  <div class="ph-modal-container subscribeModal-paymentSelect"
       *ngIf="currentStep === 'PAYMENT' && selectedPaymentMethod === 'DIRECTDEBIT' && !loading">

    <span class="subscribeModal-sectionHeader">
      Your Payment Method
    </span>

    <bank-view *ngIf="!editingPaymentMethod"
               [subscription]="subscription"
               (editDetails)="editingPaymentMethod = true">
    </bank-view>

    <bank-edit #bankEdit
               *ngIf="editingPaymentMethod"
               [subscription]="subscription"
               [current_step]="currentDDStep">
    </bank-edit>

    <div *ngIf="subscription.has_no_payment_method">
      <hr style="margin-top: 30px">

      <div class="subscribeModal-paymentSelect-swap">
        I'd like to use <a (click)="selectCardPaymentMethod()">Credit Card</a> as my payment method
      </div>
    </div>

  </div>

  <!-- Payment Review -->
  <div class="ph-modal-container"
       *ngIf="currentStep === 'REVIEW' && !loading">

    <div class="ph-formItem-container -smallForm">

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Plan
        </div>
        <div class="ph-formItem-value subscribeModal-reviewLine plan">
          <span [ngClass]="{
            'invoxy' : account.product_value === 'INVOXY',
            'karmly' : account.product_value === 'KARMLY',
            'droppah' : account.product_value === 'DROPPAH',
            'payhero': account.product_value === 'PAYHERO'
           }">
            {{availablePlans[selectedPlanIndex].plan_display_name}}
          </span>

          <span>
            {{availablePlans[selectedPlanIndex].unit_fee | appCurrency : 'symbol'}}
            per {{availablePlans[selectedPlanIndex].unit_description.toLowerCase()}}
            per {{availablePlans[selectedPlanIndex].billing_period_description}}
          </span>

          <span *ngIf="availablePlans[selectedPlanIndex].period_fee">
            {{availablePlans[selectedPlanIndex].period_fee | appCurrency : 'symbol'}} base fee
            per {{availablePlans[selectedPlanIndex].billing_period_description}}
          </span>

          <span class="promo"
                *ngIf="promoCodeDescription">
            <i class="ion ion-ios-pricetag sm-icon"></i>
            {{promoCodeDescription}}
          </span>

        </div>
      </div>

      <div class="ph-formItem"
           *ngIf="charges.length > 0">
        <div class="ph-formItem-label">
          Charges
        </div>
        <div class="ph-formItem-value subscribeModal-reviewLine creditsCharges">
          <div *ngFor="let charge of charges; index as i">
            <div class="creditChargeSymbol"
                 [ngStyle]="i === 0 ? {'margin-top': '0px'} : ''">+
              <div class="creditChargeItem">
                <div class="title">
                  {{charge.charge_description}}
                </div>

                <div class="descr">
                  {{(charge.charge_monthly_amount ? charge.charge_monthly_amount : charge.charge_total) | appCurrency :
                  'symbol'}} {{charge.charge_monthly_amount ? 'per month' : ' next invoice'}} {{ charge.renewal_date ?
                  'until ' + charge.formatted_renewal_date : ''}}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="ph-formItem"
           *ngIf="subscription.credit_amount > 0 || subscription.discount_percentage > 0">
        <div class="ph-formItem-label">
          Credits
        </div>
        <div class="ph-formItem-value subscribeModal-reviewLine creditsCharges">
          <div *ngIf="subscription.discount_percentage"
               class="creditChargeSymbol"
               [style]="'color : var(--app-color-flexitime-yellow)'">-
            <div class="creditChargeItem">
              <div class="title">
                Discount
              </div>

              <div class="descr">
                {{(subscription.discount_percentage) + '% off'}}
              </div>
            </div>
          </div>
          <div *ngIf="subscription.credit_amount"
               class="creditChargeSymbol"
               [style]="'color : var(--app-color-flexitime-yellow); margin-top: 0px'">-
            <div class="creditChargeItem">
              <div class="title">
                Credit
              </div>

              <div class="descr">
                {{subscription.credit_amount | appCurrency : 'symbol'}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Billing Details
        </div>
        <div class="ph-formItem-value subscribeModal-reviewLine details">
          <span>{{subscription.billing_name}}</span>
          <span>{{subscription.billing_email}}</span>
          <span>{{subscription.billing_address}}</span>
        </div>
      </div>

      <div class="ph-formItem"
           *ngIf="!isPartnerAdmin">
        <div class="ph-formItem-label">
          Payment Method
        </div>

        <div class="ph-formItem-value subscribeModal-reviewLine"
             *ngIf="selectedPaymentMethod === 'CARD'">
          <i class="ion ion-ios-card"></i>
          {{subscription.subscription_card.card_type}} {{subscription.subscription_card.card_display}} expires
          {{subscription.subscription_card.expiry_date | date: "MM/yy"}}
        </div>

        <div class="ph-formItem-value subscribeModal-reviewLine"
             *ngIf="selectedPaymentMethod === 'DIRECTDEBIT'">
          <i class="ion ion-ios-cash"></i>
          Direct Debit with {{subscription.bank_account_number}}
        </div>

        <div class="ph-formItem-value subscribeModal-reviewLine"
             *ngIf="selectedPaymentMethod === 'INVOICE'">
          <i class="ion ion-ios-paper"></i>
          Invoice to {{subscription.billing_name}}
        </div>

      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          First {{availablePlans[selectedPlanIndex].first_payment_date ? 'Payment' : 'Invoice'}} Date
        </div>
        <div class="ph-formItem-value subscribeModal-reviewLine">
          {{availablePlans[selectedPlanIndex].first_payment_date || subscription.first_invoice_date | date: "d MMM,
          yyyy"}}
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Subscription Agreement
        </div>
        <div class="ph-formItem-value subscribeModal-subscriptionAgreement">
          <ph-checkbox [(ngModel)]="agreementAcknowledged">
          </ph-checkbox>

          <span>I acknowledge that I have read and agree to FlexiTime's
            <a href="https://www.flexitime.works/subscription-agreement?reload"
               target="_blank">
              Subscription Agreement
            </a>
          </span>

        </div>
      </div>

    </div>

  </div>

  <!-- Promo Code on Mobile-->
  <div class="promo-code-mobile ph-modal-btnFooter ph-modal-btnRight -mobile-only"
       *ngIf="currentStep === 'PLAN' && !pendingOwnershipTransfer && !changingPlan && !autoPromoCode">

    <button class="-color-payhero-clear"
            *ngIf="!editingPromoCode"
            (click)="editingPromoCode = true"
            [disabled]="loading">
      I have a promo code
    </button>

    <div class="ph-formItem-container -smallForm"
         *ngIf="editingPromoCode">

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          <span>Promo Code</span>
          <button class="-color-flexitime-clear"
                  (click)="cancelPromoCode()">
            Cancel
          </button>
        </div>
        <div class="ph-formItem-value">
          <input [(ngModel)]="promoCode"
                 (ngModelChange)="validatePromoCode()">

          <span class="promoCode-status invalid"
                *ngIf="promoCodeStatus === 'INVALID'">
            <i class="ion ion-ios-close-circle"></i>
            Invalid
          </span>

          <span class="promoCode-status expired"
                *ngIf="promoCodeStatus === 'EXPIRED'">
            <i class="ion ion-ios-alert"></i>
            Expired
          </span>

          <span class="promoCode-status valid"
                *ngIf="promoCodeStatus === 'VALID'">
            <i class="ion ion-ios-checkmark-circle"></i>
            Valid
          </span>
        </div>
      </div>

    </div>
  </div>


  <div class="ph-modal-btnFooter">

    <div class="ph-formItem-container -smallForm ph-modal-btnLeft -desktop-only"
         *ngIf="currentStep === 'PLAN' && editingPromoCode && !pendingOwnershipTransfer && !changingPlan && !autoPromoCode">

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          <span>Promo Code</span>
          <button class="-color-flexitime-clear"
                  (click)="cancelPromoCode()">
            Cancel
          </button>
        </div>
        <div class="ph-formItem-value">
          <input [(ngModel)]="promoCode"
                 (ngModelChange)="validatePromoCode()">

          <span class="promoCode-status invalid"
                *ngIf="promoCodeStatus === 'INVALID'">
            <i class="ion ion-ios-close-circle"></i>
            Invalid
          </span>

          <span class="promoCode-status expired"
                *ngIf="promoCodeStatus === 'EXPIRED'">
            <i class="ion ion-ios-alert"></i>
            Expired
          </span>

          <span class="promoCode-status valid"
                *ngIf="promoCodeStatus === 'VALID'">
            <i class="ion ion-ios-checkmark-circle"></i>
            Valid
          </span>
        </div>
      </div>

    </div>

    <button class="-color-payhero-clear ph-modal-btnLeft -desktop-only"
            *ngIf="currentStep === 'PLAN' && !editingPromoCode && !pendingOwnershipTransfer && !changingPlan && !autoPromoCode"
            (click)="editingPromoCode = true"
            [disabled]="loading">
      I have a promo code
    </button>
    <button class="-color-white-outline ph-modal-btnLeft"
            [hidden]="currentStep === 'PLAN' || (currentDDStep === 'TERMS' && currentStep === 'PAYMENT' && editingPaymentMethod && selectedPaymentMethod === 'DIRECTDEBIT')"
            (click)="gotoStep(this.currentStepIndex - 1);"
            [disabled]="loading">
      Back
    </button>
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="close()"
            [disabled]="loading">
      Cancel
    </button>
    <button class="-color-flexitime ph-modal-btnRight"
            *ngIf="currentStep !== 'REVIEW' && (currentStep !== 'PAYMENT' || !editingPaymentMethod) && (!error_message || currentStep == 'PLAN')"
            (click)="gotoStep(this.currentStepIndex + 1);"
            [disabled]="loading ||
                        selectedPlanIndex === currentPlanIndex ||
                        (this.currentStep === 'PAYMENT' && subscription.has_no_payment_method) ||
                        (this.isPartnerAdmin && subscription.has_no_payment_method)">
      Next
    </button>
    <!-- Buttons to control inner bank-edit steps -->
    <button class="-color-white-outline ph-modal-btnLeft"
            *ngIf="currentDDStep === 'TERMS' && currentStep === 'PAYMENT' && editingPaymentMethod && selectedPaymentMethod === 'DIRECTDEBIT'"
            (click)="goToDDStep('FORM')"
            [disabled]="loading">
      Back
    </button>
    <button class="-color-flexitime ph-modal-btnRight"
            *ngIf="currentStep === 'PAYMENT' && editingPaymentMethod && selectedPaymentMethod === 'DIRECTDEBIT' && currentDDStep === 'FORM'"
            (click)="goToDDStep('TERMS')"
            [disabled]="loading">
      Next
    </button>
    <!-- End inner bank-edit step buttons -->
    <button class="-color-success ph-modal-btnRight"
            *ngIf="currentStep === 'REVIEW' && !error_message"
            [disabled]="!agreementAcknowledged || loading"
            (click)="subscribe()">
      Subscribe
    </button>
    <button class="-color-flexitime ph-modal-btnRight"
            *ngIf="currentStep === 'PAYMENT' && selectedPaymentMethod === 'DIRECTDEBIT' && editingPaymentMethod && this.currentDDStep === 'TERMS'"
            (click)="authoriseDirectDebit()"
            [disabled]="loading">
      Authorise
    </button>
  </div>
</div>

<div class="ph-modal-content complete"
     *ngIf="subscriptionComplete">

  <div class="ph-modal-header">
    <div class="headerLeft">
      <product-logo [fullLogoOnly]="true"
                    [productValue]="account.product_value">
      </product-logo>
    </div>

    <img class="image-icon"
         src="assets/imgs/App_Success.svg">

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div class="ph-modal-container">

    <span *ngIf="!pendingOwnershipTransfer && !changingPlan">{{account.company_name}} Subscribed!</span>
    <span *ngIf="pendingOwnershipTransfer">{{account.company_name}} Ownership Transferred!</span>
    <span *ngIf="changingPlan">{{account.company_name}} Plan Changed!</span>

    <div *ngIf="!pendingOwnershipTransfer && !changingPlan">
      Thanks for subscribing to {{account.product_name}}! It’s great to have you on board. Your account is now live on
      the {{availablePlans[selectedPlanIndex].plan_display_name}} plan.
    </div>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnCenter"
            (click)="close()">
      Finish
    </button>
  </div>
</div>
