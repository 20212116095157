<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      Change Password
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div class="ph-modal-container">
    <div class="ph-formItem-container -smallForm">

      <div class="ph-formItem"
           *ngIf="current_user.password_set">
        <div class="ph-formItem-label">
          Old Password
        </div>
        <div class="ph-formItem-value">
          <input class="ph-formItem-input"
                 type="password"
                 placeholder="Old Password"
                 autocomplete="current-password"
                 [(ngModel)]="old_password"
                 [ngClass]="{ '-invalid': form_field_errors['old_password'] }"
                 (click)="clearFormFieldError('old_password')">
        </div>
        <div class="ph-formItem-error"
             *ngIf="!!form_field_errors['old_password']">
          {{form_field_errors['old_password']}}
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          New Password
        </div>
        <div class="ph-formItem-value">
          <input class="ph-formItem-input"
                 type="password"
                 placeholder="New Password"
                 autocomplete="new-password"
                 [(ngModel)]="new_password"
                 [ngClass]="{ '-invalid': form_field_errors['new_password'] }"
                 (click)="clearFormFieldError('new_password')">
        </div>
        <div class="ph-formItem-error"
             *ngIf="!!form_field_errors['new_password']">
          {{form_field_errors['new_password']}}
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Confirm New Password
        </div>
        <div class="ph-formItem-value">
          <input class="ph-formItem-input"
                 type="password"
                 placeholder="Confirm New Password"
                 autocomplete="new-password"
                 [(ngModel)]="new_password_confirm"
                 [ngClass]="{ '-invalid': form_field_errors['new_password_confirm'] }"
                 (click)="clearFormFieldError('new_password_confirm')">
        </div>
        <div class="ph-formItem-error"
             *ngIf="!!form_field_errors['new_password_confirm']">
          {{form_field_errors['new_password_confirm']}}
        </div>
      </div>

    </div>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="close()">
      Cancel
    </button>
    <button class="-color-success ph-modal-btnRight"
            (click)="save()">
      Save
    </button>
  </div>
</div>
