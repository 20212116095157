import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateDataService {

  localStorage: any = window.localStorage;
  sessionStorage: any = window.sessionStorage;

  private readonly _sessionCachePrefix: string = 'SUB_';
  private readonly _globalLocalCachePrefix: string = 'SUB_';
  private _localCachePrefix: string = null;

  // Session storage //////////////////////////////////////////
  cacheComponentSessionData(componentName: string, dataName: string, data: any): void {
    this._setSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName, data);
  }

  getCachedComponentSessionData(componentName: string, dataName: string): any {
    return this._getSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName);
  }

  clearCachedComponentSessionData(componentName: string, dataName: string): void {
    this._removeSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName);
  }

  clearAllSessionData() {
    for (const key of Object.keys(this.sessionStorage)) {
      delete this.sessionStorage[key];
    }
  }

  // Local storage //////////////////////////////////////////
  cacheLocalData(dataName: string, data: any): void {
    this._setLocalStorageData(this._localCachePrefix + dataName, data);
  }

  getCachedLocalData(dataName: string): any {
    return this._getLocalStorageData(this._localCachePrefix + dataName);
  }

  clearCachedLocalData(dataName: string): void {
    this._removeLocalStorageData(this._localCachePrefix + dataName);
  }

  // Minimal use cases, currently just for username and 2fa_token
  // Most localstorage data should be cached using the functions above to
  // ensure data is specific to a particular user_access_key
  cacheGlobalLocalData(dataName: string, data: any): void {
    this.localStorage.setItem(this._globalLocalCachePrefix + '_' + dataName, JSON.stringify(data));
  }

  getCachedGlobalLocalData(dataName: string): any {
    const data = this.localStorage.getItem(this._globalLocalCachePrefix + '_' + dataName);
    return StateDataService._parseJSON(data || null);
  }

  clearCachedGlobalLocalData(dataName: string): void {
    this.localStorage.removeItem(dataName);
  }
  ///////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////

  getStickiedMenuState(rootState: string): string {
    const stickiedMenuStates = this._getSessionStorageData(this._sessionCachePrefix + 'stickiedMenuStates');
    return stickiedMenuStates ? (stickiedMenuStates[rootState] || null) : null;
  }
  setStickiedMenuState(rootState: string, subState: string) {
    const stickiedMenuStates = this._getSessionStorageData(this._sessionCachePrefix + 'stickiedMenuStates') || {};
    stickiedMenuStates[rootState] = rootState + '.' + subState;
    this._setSessionStorageData(this._sessionCachePrefix + 'stickiedMenuStates', stickiedMenuStates);
  }

  intialiseLocalStorage(user_access_key: number): void {
    this._localCachePrefix = 'SUB_' + user_access_key + '_';
  }

  private get _localStorageInitialised(): boolean {
    return this._localCachePrefix !== null;
  }

  private _getLocalStorageData(dataName: string): any {
    if (this._localStorageInitialised) {
      const data = this.localStorage.getItem(dataName);
      return StateDataService._parseJSON(data || null);
    }
    else {
      console.log('LocalStorage not initialised');
      return null;
    }
  }

  private _setLocalStorageData(dataName: string, data: any): void {
    if (this._localStorageInitialised) {
      this.localStorage.setItem(dataName, JSON.stringify(data));
    }
    else {
      console.log('LocalStorage not initialised');
    }
  }

  private _removeLocalStorageData(dataName: string): void {
    if (this._localStorageInitialised) {
      this.localStorage.removeItem(dataName);
    }
    else {
      console.log('LocalStorage not initialised');
    }
  }

  private _getSessionStorageData(dataName: string): any {
    const data = this.sessionStorage.getItem(dataName);
    return StateDataService._parseJSON(data || null);
  }

  private _setSessionStorageData(dataName: string, data: any): void {
    this.sessionStorage.setItem(dataName, JSON.stringify(data));
  }

  private _removeSessionStorageData(dataName: string): void {
    this.sessionStorage.removeItem(dataName);
  }

  private static _parseJSON(data: any): any {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }

}
