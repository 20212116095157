<div class="app-auth-full-container">

  <img class="app-auth-logo -externalPage"
      *ngIf="productTheme"
      [src]="productTheme.headerLogo">
  <a class="app-header-byFlexitime"
    *ngIf="product_value !== 'FLEXITIME' && product_value !== 'DROPPAH'"
    href="https://www.flexitime.works"
    target="_blank">
    <img [src]="productTheme.fromFlexiTimeLogo">
  </a>

  <div class="app-auth-pageTitleContainer -expandTitle">
    <h2 class="app-auth-pageTitle">{{qrURI ? 'Set up' : ''}} Two Factor Authentication</h2>
  </div>

  <div class="app-auth-contentContainer"
      [ngClass]="{ '-errorMessage': errorMessage }">

    <div class="-productGraphic">
      <img *ngIf="productTheme?.graphicLeft"
          [src]="productTheme.graphicLeft">
    </div>
    <div class="app-auth-card -signup-or-login-page"
        [ngClass]="{'-whiteCard': product_value !== 'DROPPAH'}">
      <spinner *ngIf="loading || saving"
              [customColour]="productTheme.colour">
      </spinner>

      <div class="app-auth-card-inner"
          [ngClass]="{ '-submitting': !!loading || !!saving }">

        <div *ngIf="qrURI">
          <h4><b>1.</b> Install an Authenticator app such as
            <a id="googleauthhelp"
              href="https://support.google.com/accounts/answer/1066447"
              target="_blank"
              [ngStyle]="{ 'color': productTheme.colour }">
              Google Authenticator
            </a>
          </h4>
          <h4><b>2.</b> Choose 'Scan a QR code' and scan the code below:</h4>

          <div class="app-auth-2fa-qr">
            <img [src]="qrURI" />
            <h5><a [ngStyle]="{ 'color': productTheme.colour }"
                (click)="toggleQRKey()">or enter your setup key manually
              </a>
            </h5>
            <div *ngIf="showQRKey">
              <input class="form-control"
                    (click)="copyQRKey($event.currentTarget)"
                    [(ngModel)]="qrKey"
                    readonly />
              <a class="app-auth-2fa-qrKey">{{qrKeyCopied ? 'Copied' : 'Copy'}}</a>
            </div>
          </div>

          <h4><b>3.</b> Enter your verification code:</h4>
        </div>

        <div class="app-auth-errorMessage"
            [ngClass]="{ '-hidden': !errorMessage }">
          <i class="ion ion-ios-warning sm-icon"></i> {{errorMessage}}
        </div>

        <form class="app-auth-form"
              (ngSubmit)="verify()">
          <div class="fieldLabel"
              *ngIf="!qrURI && product_value !== 'DROPPAH'">
            Verification Code
          </div>
          <input class="app-input -inputLarge"
                id="code"
                name="code"
                autocomplete="off"
                [placeholder]="product_value === 'DROPPAH' ? 'Verification Code' : ''"
                #twoFactorCodeElem
                (ngModelChange)="hideError()"
                [(ngModel)]="twoFactorCode">

          <div class="app-auth-2fa-rememberMe">
            <ph-checkbox [(ngModel)]="rememberMe"
                        name="rememberMe"
                        [btnSmall]="true">
            </ph-checkbox>
            <h4>
              Remember me for 30 days
            </h4>
          </div>

          <button class="app-auth-submit"
                  [ngStyle]="{ 'background-color': productTheme.colour }"
                  [disabled]="saving || !twoFactorCode">
            {{qrURI ? 'Confirm' : 'Verify'}}
          </button>
        </form>

        <div class="app-auth-textLink"
            [ngStyle]="{ 'color': productTheme.colour }">
          <div (click)="logout()">Sign Out</div>
        </div>
      </div>
    </div>

    <div class="-productGraphic">
      <img *ngIf="productTheme?.graphicRight"
          [src]="productTheme.graphicRight">
    </div>
  </div>
</div>

<app-footer-user-agreements [support_centre_link]="productTheme.supportCentreURL"></app-footer-user-agreements>

<a class="app-footer-byFlexitime"
   *ngIf="product_value === 'DROPPAH'"
   href="https://www.flexitime.works"
   target="_blank">
  <img [src]="productTheme.fromFlexiTimeLogo">
</a>

<div class="app-auth-mobileGraphicFooter"
     *ngIf="product_value !== 'FLEXITIME' && product_value !== 'DROPPAH'">
  <img [src]="productTheme.graphicMobileFooterFull">
</div>
