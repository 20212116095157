<div class="msdd-container"
     ngbDropdown
     #msddDropDown
     [title]="buttonTitle"
     [placement]="dropUp ? (alignLeft ? 'top-left' : 'top-right') : (alignLeft ? 'bottom-left' : 'bottom-right')"
     [container]="disableBodyAppend ? null : 'body'"
     [autoClose]="'outside'"
     (openChange)="dropdownToggled($event)">

  <button class="msdd-button"
          [ngClass]="[buttonTypeClass, buttonColorClass]"
          [ngStyle]="{
            'text-transform': buttonTitleUppercase ? 'uppercase' : ''
          }"
          [disabled]="listDisabled"
          (mousedown)="determineDropAlignment($event)"
          ngbDropdownToggle>

    <i class="ion msdd-button-icon"
       *ngIf="buttonIconClass && buttonIconAlignLeft"
       [ngClass]="buttonIconClass">
    </i>

    <span class="msdd-button-title"
          *ngIf="buttonTitle">
      {{buttonTitle}}
    </span>

    <i class="ion msdd-button-icon"
       *ngIf="buttonIconClass && !buttonIconAlignLeft"
       [ngClass]="buttonIconClass">
    </i>
  </button>

  <div class="msdd-list"
       ngbDropdownMenu
       [ngStyle]="{
       'min-width': listWidth || '',
       'max-width': listWidth || ''}">
    <div class="msdd-listInner"
         [ngStyle]="{ 'max-height': listHeight }">

      <div class="msdd-listHeader">

        <mega-search class="msdd-listHeader-search"
                     #megaSearchComponent
                     [megaSearchId]="dropdownId"
                     [megaSearchConfigMap]="megaSearchConfigMap"
                     [inputPlaceholder]="searchPlaceholderMessage"
                     [instantSearch]="true"
                     [items]="{ items: items }"
                     (searchUpdated)="reloadVisibleItems()">
        </mega-search>
        <div class="msdd-listHeader-details" *ngIf="!singularSelect">
          <div class="msdd-listHeader-selectedCount">
            {{selectedItemCount}} / {{items.length}} Selected
          </div>
          <button class="-type-text -color-link-clear msdd-listHeader-toggleAll"
                  (click)="toggleAll()">
            {{selectedItemCount === items.length ? 'Deselect' : 'Select'}} All
          </button>
        </div>
      </div>

      <div class="msdd-list-scrollOuter"
           infinite-scroll
           [infiniteScrollContainer]="'.msdd-list-scrollOuter'"
           [infiniteScrollDistance]="1"
           [fromRoot]="true"
           (scrolled)="loadMoreVisibleItems()">
        <ul class="msdd-list-scrollInner">
          <li class="msdd-item"
              *ngFor="let item of visibleItems"
              ngbDropdownItem
              [ngClass]="{ '-selected': selectedItemsMap[item[itemKeyProperty]] }"
              (click)="toggleItem(item)">
            <div class="msdd-itemInner">
              <div class="msdd-item-color"
                   *ngIf="itemColorProperty"
                   [ngStyle]="{'background-color': item[itemColorProperty]}">
              </div>
              <div class="msdd-item-details">
                <div class="msdd-item-name"
                     [ngStyle]="{ 'color': itemFontColorProperty && item[itemFontColorProperty] ? item[itemFontColorProperty] : '' }">
                  {{item[itemNameProperty]}}
                </div>
                <div class="msdd-item-description"
                     *ngIf="itemDescriptionProperty">
                  {{item[itemDescriptionProperty]}}
                </div>
              </div>
            </div>
          </li>

          <li class="msdd-item -empty"
              *ngIf="items.length !== 0 && visibleItems.length === 0">
            <div class="msdd-itemInner">
              {{searchEmptyMessage}}
            </div>
          </li>

          <li class="msdd-item -empty"
              *ngIf="items.length === 0">
            <div class="msdd-itemInner">
              {{itemsEmptyMessage}}
            </div>
          </li>
        </ul>
      </div>


      <div class="msdd-listButtonContainer">
        <button class="-color-success"
                (click)="addSelected()"
                [disabled]="selectedItemCount === 0">
          <span>{{itemsSelectMessage}}</span>
        </button>
      </div>

    </div>
  </div>


</div>
