import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tooltip-popover',
  templateUrl: './tooltip-popover.component.html',
  styleUrls: ['./tooltip-popover.component.scss']
})
export class TooltipPopoverComponent implements OnInit {

  @ViewChild('tooltip') tooltip: NgbTooltip;

  @Input() tooltipContent: string;
  @Input() smallFont: boolean = false;
  @Input() invertColours: boolean = false;

  constructor() { }

  ngOnInit() {

  }

  open() {
    this.tooltip?.open();
  }

}
