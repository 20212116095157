import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../../components/components.module';

import { LoginComponent } from './login/login.component';
import { LoginExternalComponent } from './login-external/login-external.component';
import { LoginTwoFactorComponent } from './login-two-factor/login-two-factor.component';
import { ZendeskSsoComponent } from './zendesk-sso/zendesk-sso.component';

export const LOGIN_CONFIG: any[] = [
  {
    name: 'login',
    url: '/login?token&destination&integration_redirect_uri&integration_redirect_state&integration_redirect_system&integration_redirect_company_code&integration_product&internal_state_name&internal_state_params&external_state_name&external_state_params&company_product_key&error_message&product&domain&ignore_cached_username&encoded_params&brand_id&return_to&company_codes',
    component: LoginComponent,
    params: {
      username: null,
      password: null,
      token: null,
      two_factor_token: null,
      destination: null,
      integration_redirect_uri: null,
      integration_redirect_state: null,
      integration_redirect_system: null,
      integration_redirect_company_code: null,
      integration_product: null,
      internal_state_name: null,
      internal_state_params: null,
      external_state_name: null,
      external_state_params: null,
      company_product_key: null,
      error_message: null,
      product: null,
      domain: null,
      ignore_cached_username: null,
      encoded_params: null,
      zendesk_return_to: null,
      zendesk_brand_name: null,
      company_codes: null
    }
  },
  {
    name: 'zendesksso',
    url: '/zendesk/sso',
    component: ZendeskSsoComponent,
    params: {}
  },
  {
    name: 'loginTwoFactor',
    url: '/loginTwoFactor',
    component: LoginTwoFactorComponent,
    params: {
      verification_token: null,
      two_factor_enabled: null,
      targetStateName: null,
      targetStateParams: null
    }
  },
  {
    name: 'loginExternal',
    url: '/loginExternal?urlParams&product',
    component: LoginExternalComponent,
    params: {
      urlParams: null,
      product: null
    }
  }
];


@NgModule({
  declarations: [
    LoginComponent,
    LoginExternalComponent,
    LoginTwoFactorComponent,
    ZendeskSsoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild({ states: LOGIN_CONFIG })
  ]
})
export class LoginModule { }
