import { ProductValue } from './../../app.types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from '../auth/auth.service';

import { env } from './../../../environments/environment';
import { ErrorModalComponent } from 'src/app/modals/error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';

export type DbPingResult = {
  is_online: boolean,
  title: string,
  description: string
};

@Injectable({
  providedIn: 'root'
})
export class DbUtilService {

  public subscription_api_url: string = env.subscription.api_url;
  public payhero_api_url: string = env.payhero.api_url;
  public droppah_api_url: string = env.droppah.api_url;
  public invoxy_api_url: string = env.invoxy.api_url;
  public karmly_api_url: string = env.karmly.api_url;
  public nzbn_api_url: string = env.nzbn.nzbn_api_url;
  public nzbn_subscription_key: string = env.nzbn.nzbn_subscription_key;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private ngbModal: NgbModal,
  ) { }

  pingAPI(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      resolve();
      // reject(this._processPingResult(null));

      // this.http
      //   .get(this.subscription_api_url + 'hello')
      //   .toPromise()
      //   .then((result) => {
      //     console.log(result);
      //     resolve();
      //     // resolve(this._processPingResult(result));
      //   })
      //   .catch(() => {
      //     reject();
      //   });
    });
  }

  APIPost(URL: string, data: any, hideError: boolean = false) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        headers: this.authService.getHTTPHeader()
      };

      lastValueFrom(
        this.http.post(this.subscription_api_url + URL, data, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIGet(URL: string, params: any = null, hideError: boolean = false, responseType: string = null) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        headers: this.authService.getHTTPHeader(),
        params,
        responseType
      };

      // Only need to set as arraybuffer when downloading PDF
      if (!responseType) {
        delete options.responseType;
      }

      lastValueFrom(
        this.http.get(this.subscription_api_url + URL, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIPostPayHero(URL: string, data: any, hideError: boolean = false, product_session: any = null) {
    return new Promise<any>((resolve, reject) => {

      const options: any = {
        headers: this.authService.getProductHTTPHeader(product_session)
      };


      lastValueFrom(
        this.http.post(this.payhero_api_url + URL, data, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIGetPayHero(URL: string, params: any = null, hideError: boolean = false, responseType: string = null, product_session: any = null) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        headers: this.authService.getProductHTTPHeader(product_session),
        params,
        responseType
      };

      // Only need to set as arraybuffer when downloading PDF
      if (!responseType) {
        delete options.responseType;
      }

      lastValueFrom(
        this.http.get(this.payhero_api_url + URL, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIPostInvoxy(
    url: string,
    data: any,
    hide_error: boolean = false
  ) {
    return new Promise<any>((resolve, reject) => {

      lastValueFrom(
        this.http.post(this.invoxy_api_url + url, data, {})
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hide_error));
        });
    });
  }

  APIGetInvoxy(
    url: string,
    params: any = null,
    hide_error: boolean = false
  ) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        params
      };

      lastValueFrom(
        this.http.get(this.invoxy_api_url + url, options)
      )
        .then((res: any) => {
          if (res?.success) {
            resolve(res.result);
          }
          else {
            reject(this._processAPIError(res, hide_error));
          }
        })
        .catch((error) => {
          reject(this._processAPIError(error, hide_error));
        });
    });
  }

  APIPostKarmly(
    url: string,
    data: any,
    hide_error: boolean = false
  ) {
    return new Promise<any>((resolve, reject) => {

      lastValueFrom(
        this.http.post(this.karmly_api_url + url, data, {})
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hide_error));
        });
    });
  }

  APIGetKarmly(
    url: string,
    params: any = null,
    hide_error: boolean = false
  ) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        params
      };

      lastValueFrom(
        this.http.get(this.karmly_api_url + url, options)
      )
        .then((res: any) => {
          if (res?.success) {
            resolve(res.result);
          }
          else {
            reject(this._processAPIError(res, hide_error));
          }
        })
        .catch((error) => {
          reject(this._processAPIError(error, hide_error));
        });
    });
  }

  APIPostDroppah(URL: string, data: any, hideError: boolean = false) {
    return new Promise<any>((resolve, reject) => {

      lastValueFrom(
        this.http.post(this.droppah_api_url + URL, data, {})
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIGetDroppah(URL: string, params: any = null, hideError: boolean = false) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        params,
      };

      lastValueFrom(
        this.http.get(this.droppah_api_url + URL, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIGetNZBN(
    URL: string,
    access_token: string,
    params: any = null,
    hideError: boolean = false
  ) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        headers: { Authorization: 'Bearer ' + access_token, 'Ocp-Apim-Subscription-Key': this.nzbn_subscription_key },
        params
      };

      lastValueFrom(
        this.http.get(this.nzbn_api_url + URL, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIPostExternal(URL: string, headers: any = null, data: any = null, hideError: boolean = false) {
    return new Promise<any>((resolve, reject) => {

      const options: any = {}
      if (headers) options.headers = headers;

      lastValueFrom(
        this.http.post(URL, data, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          //TODO More generic error handling
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  APIGetExternal(URL: string, headers: any = null, params: any = null, hideError: boolean = false, responseType: string = null) {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        params,
        responseType
      };

      if (headers) {
        options.headers = headers;
      }

      // Only need to set as arraybuffer when downloading PDF
      if (!responseType) {
        delete options.responseType;
      }

      lastValueFrom(
        this.http.get(URL, options)
      )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          //TODO More generic error handling
          reject(this._processAPIError(error, hideError));
        });
    });
  }

  getBlobFromURL(URL: string) {
    return new Promise<any>((resolve, reject) => {

      lastValueFrom(
        this.http.get(URL, { responseType: 'blob' })
      )
        .then((blob) => {
          resolve(blob);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  }

  private _processAPIError(err: any, hideError: boolean): any {
    console.log(err);
    let res: any;

    if (err && err.error && typeof err.error === 'string') {
      res = {
        message: err.error,
        data: err
      };
    }
    else {
      res = {
        message: 'Looks like we encountered an issue. If this continues to happen please contact support.',
        data: err
      };
    }

    if (!hideError && res.message) {
      this.errorModal(null, res.message);
    }

    return res;
  }

  private _processPingResult(
    result: Partial<Record<ProductValue, any>>
  ): Partial<Record<ProductValue, DbPingResult>> {
    const ping_result: Partial<Record<ProductValue, DbPingResult>> = {
      FLEXITIME: null,
      PAYHERO: null,
      DROPPAH: null
    };

    if (!!result?.FLEXITIME) {
      ping_result.FLEXITIME = {
        is_online: result.FLEXITIME.is_online,
        title: result.FLEXITIME.title,
        description: result.FLEXITIME.description
      };
    }
    if (!!result?.PAYHERO) {
      ping_result.PAYHERO = {
        is_online: result.PAYHERO.is_online,
        title: result.PAYHERO.title,
        description: result.PAYHERO.description
      };
    }
    if (!!result?.DROPPAH) {
      ping_result.DROPPAH = {
        is_online: result.DROPPAH.is_online,
        title: result.DROPPAH.title,
        description: result.DROPPAH.description
      };
    }

    return ping_result;
  }

  async errorModal(message: string = null, htmlMessage: string = null) {
    const modal = this.ngbModal.open(ErrorModalComponent, { size: 'lg' });

    modal.componentInstance.message = message;
    modal.componentInstance.htmlMessage = htmlMessage;

    return modal.result
      .then(() => {
        return null;
      })
      .catch(() => {
        return null;
      });
  }
}
