import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'stepped-progress',
  templateUrl: './stepped-progress.component.html',
  styleUrls: ['./stepped-progress.component.scss']
})
export class SteppedProgressComponent implements OnInit {

  @Input() steps: string[] = [];
  @Input() currentStep: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
