import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { Country, IntegrationType, ProductName } from 'src/app/app.types';
import { ProductService } from 'src/app/services/product/product.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-signup-integration',
  templateUrl: './signup-integration.component.html',
  styleUrls: ['./signup-integration.component.scss']
})
export class SignupIntegrationComponent implements OnInit {

  product: ProductName;
  integration_type: IntegrationType;
  country_key: string;
  referral_code: string;
  marketing_additional_data: string;

  constructor(
    private uiRouterGlobals: UIRouterGlobals,
    private stateService: StateService,
    private userService: UserService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {

    this.product = this.uiRouterGlobals.params.product || this.productService.current_product_name;
    this.integration_type = this.uiRouterGlobals.params.integration_type;
    this.country_key = this.uiRouterGlobals.params.country_key;
    this.referral_code = this.uiRouterGlobals.params.referral_code;
    this.marketing_additional_data = this.uiRouterGlobals.params.marketing_additional_data;

    if (!this.product || this.product === 'FlexiTime' || !this.integration_type) return this.goLogin();

    this.getAuthURI();
  }

  goLogin() {
    this.stateService.go('login');
  }

  getAuthURI() {
    switch(this.integration_type) {
      case 'XERO':
        this.getXeroAuthURI();
        break;
      case 'GOOGLE':
        this.getGoogleAuthURI();
        break;
      default:
        this.goLogin();
    }
  }

  getXeroAuthURI() {
    this.userService.getXeroAuthURI('SIGN_UP', null, this.product, this.country_key, this.referral_code, null, this.marketing_additional_data)
    .then((URL) => {
      // Redirect in the current window to the Xero login flow
      window.location.href = URL;
    })
    .catch(() => {
      this.goLogin();
    });
  }

  getGoogleAuthURI() {
    this.userService.getGoogleLoginURI('SIGN_UP', null, this.product, this.country_key, null, this.marketing_additional_data)
    .then((URL) => {
      // Redirect in the current window to the Google login flow
      window.location.href = URL;
    })
    .catch(() => {
      this.goLogin();
    });
  }

}
