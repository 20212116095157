import { NumberValueAccessor } from "@angular/forms";
import { TimeUtilService } from "src/app/services/time-util/time-util.service";
import { PromoCode } from "../promo-code/promo-code";

export class SubscriptionPlan {

  subscription_plan_key: number;
  plan_display_name: string;
  base_plan_flag: boolean;
  billing_period: string;
  first_payment_date: Date;
  included_units: number;
  minimum_billing_fee: number;
  months_free: number;
  period_fee: number;
  plan_type: string;
  unit_description: string;
  unit_description_plural: string;
  unit_fee: number;
  unit_limit: number;
  promo_codes: Record<string, PromoCode>;

  billing_period_description: string;

  constructor(
    subscription_plan_key: number, plan_display_name: string, base_plan_flag: boolean,
    billing_period: string = null, first_payment_date: string = null, included_units: number = 0,
    minimum_billing_fee: number = 0, months_free: number = 0, period_fee: number = 0,
    plan_type: string = null, unit_description: string = null, unit_description_plural: string = null,
    unit_fee: number = 0, unit_limit: number = null, promo_codes: PromoCode[] = []
  ) {
    this.subscription_plan_key = subscription_plan_key;
    this.plan_display_name = plan_display_name;
    this.base_plan_flag = base_plan_flag;
    this.billing_period = billing_period;
    this.first_payment_date = TimeUtilService.dateStringToDate(first_payment_date);
    this.included_units = included_units;
    this.minimum_billing_fee = minimum_billing_fee;
    this.months_free = months_free;
    this.period_fee = period_fee;
    this.plan_type = plan_type;
    this.unit_description = unit_description;
    this.unit_description_plural = unit_description_plural;
    this.unit_fee = unit_fee;
    this.unit_limit = unit_limit;

    this.promo_codes = {};
    for (const code of promo_codes) {
      this.promo_codes[code.promo_code] = code;
    }

    this.initBillingPeriodDescription();
  }

  initBillingPeriodDescription() {
    if (!this.billing_period) {
      return;
    }

    switch(this.billing_period.toUpperCase()) {
      case 'MONTHLY':
        this.billing_period_description = 'month';
        break;
    }
  }

}
