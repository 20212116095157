import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../../components/components.module';
import { AppStateDeclaration } from './../../app.types';

import { PartnerComponent } from './partner.component';
import { PartnerEditComponent } from './partner-edit/partner-edit.component';


export const PARTNER_CONFIG: AppStateDeclaration[] = [
  {
    name: 'app.partner',
    url: '/partner',
    component: PartnerComponent,
    abstract: true
  },
  {
    name: 'app.partner.edit',
    url: '/edit',
    component: PartnerEditComponent,
  }
];

@NgModule({
  declarations: [
    PartnerComponent,
    PartnerEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild({ states: PARTNER_CONFIG })
  ]
})
export class PartnerModule { }
