import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  }
  else {
    return '';
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbParserFormatterDDMMYYYY extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');

      const day = dateParts[0];
      const month = dateParts[1];
      let year = dateParts[2];

      if (dateParts.length === 3 && isNumber(day) && isNumber(month) && isNumber(year) &&
          (day.length === 1 || day.length === 2) && 
          (month.length === 1 || month.length === 2) && 
          (year.length === 2 || year.length === 4)) {

        // If year is < 40, assume a 21st century date, otherwise assume 20th century
        if (year.length === 2){
          if (toInteger(year) < 40){
            year = '20' + year;
          }
          else {
            year = '19' + year;
          }
        }
            
        return {
          year: toInteger(year),
          month: toInteger(month),
          day: toInteger(day)
        };
      }
    }
    
    return null;
  }

  format(date: NgbDateStruct): string {
    let stringDate: string = '';

    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
      stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
      stringDate += date.year;
    }
    
    return stringDate;
  }
}
