<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          Staff
        </div>
      </div>

      <div class="app-nav-rowRight">
        <mega-search [megaSearchId]="megaSearchId"
                     [megaSearchConfigMap]="megaSearchConfigMap"
                     [disabled]="loading"
                     [inputPlaceholder]="'Search Staff...'"
                     [items]="{ staff: staff }"
                     (searchUpdated)="megaSearchUpdated()">
        </mega-search>
        <button class="-color-payhero ph-modal-btnRight"
                (click)="invite()">
          Invite Staff Member
        </button>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <dp-list class="staffDash-staffList -owner"
               #ownerList
               [items]="[owner]"
               (itemSelected)="selectStaff($event.item)">
        <ng-template dpListItem
                     let-item>

          <div class="dp-list-itemCell clientName">
            <span>{{item.full_name}}</span>
            <span>
              <i class="ion ion-md-key"></i>
              Owner
            </span>
          </div>

          <div class="dp-list-itemCell clientContact -desktop-only">
            <a>{{item.email_address}}</a>
            <span *ngIf="item.email_address && item.contact_phone">&#183;</span>
            {{item.contact_phone}}
          </div>

          <div class="dp-list-itemCell">

          </div>

        </ng-template>
      </dp-list>

      <div class="app-dashListDivider"></div>

      <dp-list class="staffDash-staffList"
               #staffList
               [primarySortProperty]="'full_name'"
               [tableHeaders]="owner ? [] : null"
               [itemType]="'staff'"
               [itemTypePlural]="'staff'"
               [itemIsVisible]="staffIsVisible"
               [items]="staff"
               (itemSelected)="selectStaff($event.item)">
        <ng-template dpListItem
                     let-item>

          <div class="dp-list-itemCell clientName">
            <span>{{item.full_name}}</span>
            <span class="sb-staffRole"
                  *ngIf="item.admin_flag">
              <i class="ion ion-ios-contact"></i>
              Admin
            </span>
          </div>

          <div class="dp-list-itemCell clientContact -desktop-only">
            <a>{{item.email_address}}</a>
            <span *ngIf="item.email_address && item.contact_phone">&#183;</span>
            {{item.contact_phone}}
          </div>

          <div class="dp-list-itemCell">
            <button class="-color-default pendingBtn"
                    *ngIf="item.invite_status === 'PENDING'"
                    [disabled]="true">
              Pending
            </button>
            <button class="-type-text- -color-payhero-clear"
                    *ngIf="item.invite_status === 'RESENDING'">
              Resending...
            </button>
            <button class="-type-text -color-success-clear"
                    *ngIf="item.invite_status === 'SENT'">
              Sent!
            </button>

            <list-drop-down *ngIf="item.user_access_key !== userAccess.user_access_key"
                            [itemNameProperty]="'option_name'"
                            [itemFontColorProperty]="'option_color'"
                            [buttonTypeClass]="'-type-icon'"
                            [buttonIconClass]="'ion-ios-more'"
                            [buttonColorClass]="'-color-grey-clear'"
                            [listWidth]="'250px'"
                            [sortItems]="false"
                            [items]="dropdownOptions[item.partner_staff_key]"
                            (itemSelected)="dropdownOptionSelected($event.item.option_type, item)"
                            (click)="$event.stopPropagation()">
            </list-drop-down>
          </div>

        </ng-template>
      </dp-list>

    </div>
  </div>

</div>
