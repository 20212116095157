import { Component, Input, HostListener } from '@angular/core';

import { AbstractValueAccessor, MakeProvider } from 'src/app/classes/abstract/abstract-value-accessor/abstract-value-accessor';

import { Country } from './../../app.types';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'country-input',
  templateUrl: './country-input.component.html',
  styleUrls: ['./country-input.component.scss'],
  providers: [MakeProvider(CountryInputComponent)]
})
export class CountryInputComponent extends AbstractValueAccessor {

  @Input() inputLabel: string = 'Country';
  @Input() activeValidationEnabled: boolean = true;
  @Input() countryRequired: boolean = false;
  @Input() clearErrorsOnClick: boolean = true;
  @Input() customError: string = null;
  @Input() readonly: boolean = false;

  missing_country: boolean = false;
  countries: Country[] = this.userService.getCountries();

  _value: Country;

  constructor(
    public userService: UserService
  ) {
    super();
  }

  @HostListener('click', ['$event'])
  handleClick() {
    if (this.clearErrorsOnClick) {
      this.clearErrors();
    }
  }

  countryUpdated(country: Country) {
    this.value = country;

    if (this.activeValidationEnabled) {
      this.validateCountry();
    }
  }

  validateCountry() {
    this.missing_country = false;

    if (this.countryRequired && !this._value) {
      this.missing_country = true;
    }
    return !this.missing_country;
  }

  clearErrors() {
    this.customError = null;
    this.missing_country = false;
  }

}
