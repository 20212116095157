import { Component } from '@angular/core';

import { ProductService } from '../../services/product/product.service';

@Component({
  selector: 'app-footer-products',
  templateUrl: './app-footer-products.component.html',
  styleUrls: ['./app-footer-products.component.scss']
})
export class AppFooterProductsComponent {

  product_logos = this.productService.getLoginFooterProductLogos();

  constructor(
    public productService: ProductService
  ) { }

}
