import { Account } from 'src/app/models/account/account';
import { Component, ComponentRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DomService } from 'src/app/services/dom/dom.service';
import { BackdropComponent } from '../backdrop/backdrop.component';

@Component({
  selector: 'cost-popover',
  templateUrl: './cost-popover.component.html',
  styleUrls: ['./cost-popover.component.scss']
})
export class CostPopoverComponent implements OnInit {

  @Input() account: Account;
  @ViewChild('cPopover') cPopover: NgbTooltip;
  backdropRef: ComponentRef<BackdropComponent> = null;

  totalCostBeforeTrial: number = 0;

  @HostListener('click', ['$event'])
  handleClick($event: any) {
    $event.stopPropagation();
  }

  constructor(
    private domService: DomService
  ) { }

  ngOnInit(): void {
    for (const line of this.account.account_subscription.account_subscription_lines) {
      this.totalCostBeforeTrial += line.line_total;
    }
  }

  openBackdrop() {
    this.backdropRef = this.domService.openBackdrop();
    this.backdropRef.instance.backdropClicked.subscribe(() => {
      this.cPopover.close();
    });
  }

}
