import { Injectable } from '@angular/core';

import { ClientService } from './../client/client.service';
import { AccountService } from './../account/account.service';
import { UserService } from './../user/user.service';
import { PartnerStaff } from './../../models/partner-staff/partner-staff';
import { DbUtilService } from './../db-util/db-util.service';
import { Client } from 'src/app/models/client/client';
import { DomService } from 'src/app/services/dom/dom.service';

@Injectable({
  providedIn: 'root'
})
export class AccountAccessService {

  constructor(
    public accountService: AccountService,
    public clientService: ClientService,
    public userService: UserService,
    public dbUtilService: DbUtilService,
    public domService: DomService
  ) { }

  addOrRemovePartnerStaffFromClient(
    client: Client, partner_staff: PartnerStaff, toDelete: boolean = false
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = {
        partner_staff_key: partner_staff.partner_staff_key,
        client_key: client.client_key,
        deleted_flag: toDelete
      };

      this.dbUtilService.APIPost('client/partnerstaff', data)
        .then((res) => {
          if (res.access_error_flag) {
            this.domService.showBanner({
              message: 'There was an issue modifying this User\'s access to a Client\'s Account',
              type: 'ERROR'
            });
          }
          this._checkIfUserAccountAccessNeedsUpdatingOnPartnerStaffUpdate(
            partner_staff
          )
            .finally(() => {
              this.clientService.loadClients()
                .finally(() => {
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  addClientsToPartnerStaff(
    partner_staff: PartnerStaff, clients: Client[]
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const formattedClients = [];

      for (const client of clients) {
        formattedClients.push(client.formatForPosting());
      }

      const data = {
        partner_staff_key: partner_staff.partner_staff_key,
        clients: formattedClients
      };

      this.dbUtilService.APIPost('partner/staff/clients', data)
        .then((res) => {
          if (res.access_error_flag) {
            this.domService.showBanner({
              message: 'There was an issue modifying this User\'s access to a Client\'s Account',
              type: 'ERROR'
            });
          }
          this._checkIfUserAccountAccessNeedsUpdatingOnPartnerStaffUpdate(
            partner_staff
          )
            .finally(() => {
              this.clientService.loadClients()
                .finally(() => {
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  updateClientPartnerStaffAccountAccess(
    client_key: number, partner_staff_key: number, company_product_key: number, enable_access: boolean
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = {
        client_key: client_key,
        partner_staff_key: partner_staff_key,
        company_product_key: company_product_key,
        deleted_flag: !enable_access
      };

      this.dbUtilService.APIPost('partner/staff/companyproduct', data)
        .then(() => {
          const client = this.clientService.getClient(client_key);
          const client_partner_staff = client.getClientPartnerStaff(partner_staff_key);

          this._checkIfUserAccountAccessNeedsUpdatingOnPartnerStaffUpdate(
            client_partner_staff.partner_staff, company_product_key
          )
            .finally(() => {
              this.clientService.reloadClient(client_key)
                .finally(() => {
                  resolve();
                });
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  private _checkIfUserAccountAccessNeedsUpdatingOnPartnerStaffUpdate(
    updated_partner_staff: PartnerStaff, updated_company_product_key: number = null
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      // Updating account access for currently logged in user so need to reload accounts
      if (updated_partner_staff.user_access_key === this.userService.user_access_key) {
        // Only need to update access for a specific account
        if (updated_company_product_key !== null) {
          this.accountService.reloadAccount(updated_company_product_key)
            .finally(() => {
              resolve();
            });
        }
        // Need to update all accounts
        else {
          this.accountService.loadAccounts()
            .finally(() => {
              resolve();
            });
        }
      }
      else {
        resolve();
      }
    });
  }
}
