<div class="token-page">
  <div class="dp-loadingOverlay"
       *ngIf="loading">
    <img src="assets/imgs/App_Loader.gif">
  </div>

  <div class="token-page-header">
    <img class="token-page-header-logo"
         src="assets/product-imgs/droppah/Droppah_Single.svg"
         alt="">

    <h3 class="token-page-header-title">
      Time Off Request
    </h3>
  </div>

  <div class="dp-leave-request-container">

    <div class="dp-leave-request-card"
         *ngIf="!loading && !token_expired">

      <p *ngIf="leave_request.approved_declined_date && leave_request.approved_flag"
         class="dp-leave-request-approved-flag">
        This time off request has been approved
      </p>

      <p *ngIf="leave_request.approved_declined_date && leave_request.declined_flag"
         class="dp-leave-request-declined-flag">
        This time off request has been declined
      </p>

      <div class="dp-leave-request-date-range">
        <div>
          <p class="dp-leave-request-info-title">
            Start Date:
          </p>
          <p class="dp-leave-request-info-value">{{ leave_request.start_time | date:'EEE, d MMM y' }}</p>
        </div>

        <div>
          <p class="dp-leave-request-info-title">
            End Date:
          </p>
          <p class="dp-leave-request-info-value">{{ leave_request.end_time | date:'EEE, d MMM y' }}</p>
        </div>

      </div>

      <span class="dp-leave-request-messages"
            *ngIf="!!leave_request.notes.length">
        <p class="dp-leave-request-info-title">
          Message
        </p>
        <p class="dp-leave-request-message"
           *ngFor="let message of leave_request.notes">
          {{message.display_name}}: <span>{{message.note}}</span>
        </p>
      </span>

      <span *ngIf="!!leave_request && !leave_request.approved_declined_date">
        <p class="dp-leave-request-info-title">Up to you...</p>
        <textarea placeholder="Respond..."
                  rows="4"
                  [(ngModel)]="message">
    </textarea>

        <span class="dp-leave-request-buttons">
          <button class="-color-danger-outline"
                  (click)="declineRequest()">
            Decline
          </button>
          <button class="-color-droppah"
                  (click)="acceptRequest()">
            Approve
          </button>

        </span>
      </span>

    </div>

    <div class="dp-leave-request-card -expired"
         *ngIf="!loading && !!token_expired">
      <span>This link is no longer valid</span>
      <a href="https://login.droppah.com/#/login?destination=DROPPAH_APP">
        Sign In to Droppah
      </a>
    </div>
  </div>

</div>
