<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      <span>Manage Access</span>
      {{client_partner_staff.partner_staff.full_name}}
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div class="ph-modal-container">
    <dp-list #accountList
             [emptyDescriptionOnly]="true"
             [noItemsMessage]="'No Accounts'"
             [primarySortProperty]="'company_name'"
             [itemType]="'account'"
             [itemTypePlural]="'accounts'"
             [itemSelectDisabled]="true"
             [items]="client_accounts">
      <ng-template dpListItem
                   let-item>

        <div class="dp-list-itemCell">
          <div class="mcsa-accountDetails">
            <div class="cal-accountDetails-name -companyName">
              {{item.company_name}}
            </div>
            <product-logo [productValue]="item.product_value"
                          [fullLogoOnly]="true">
            </product-logo>
          </div>
        </div>

        <div class="dp-list-itemCell">
          <div *ngIf="!staff_account_access_map[item.company_product_key].loading">
            {{staff_account_access_map[item.company_product_key].has_access ? 'Full Access' : 'No Access'}}
          </div>
          <spinner *ngIf="staff_account_access_map[item.company_product_key].loading"></spinner>
        </div>

        <div class="dp-list-itemCell">
          <ph-toggle [ngModel]="staff_account_access_map[item.company_product_key].has_access"
                     [disableInternalToggle]="true"
                     (click)="toggleAccess(item)">
          </ph-toggle>
        </div>

      </ng-template>
    </dp-list>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="close()">
      Close
    </button>
  </div>
</div>
