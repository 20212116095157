<div class="ph-modal-content">
  <div class="ph-modal-header">
    <span class="ion ion-ios-lock">
    </span>
    <div class="headerCenter">
      Set up Two Factor Authentication
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="cancel()">
    </i>
  </div>

  <div class="ph-modal-container" *ngIf="!loading">
    <div class="ph-modal-row">
      <div class="ph-modal-cellFull">
          <b>Step 1.</b> Install an Authenticator app such as <a href="https://support.google.com/accounts/answer/1066447" target="_blank">Google Authenticator</a>.
      </div>
  </div>

  <div class="ph-modal-row">
      <div class="ph-modal-cellFull">
          <b>Step 2.</b> Choose 'Scan a QR code' and scan the code below:
      </div>
  </div>

  <div class="ph-modal-row">
      <div class="ph-modal-cellFull twoFactorQR">
          <img [src]="qr_uri"/>
          <button class="btn btn-link" (click)="toggleQRKey()">or enter your setup key manually</button>
          <div *ngIf="showQRKey">
              <input class="form-control twoFactorQRKey"
                  (click)="copyQRKey($event.currentTarget)"
                  [(ngModel)]="qr_key"
                  readonly />

              <a class="ph-login-2fa-qrKey">{{qrKeyCopied ? 'Copied' : 'Copy'}}</a>
          </div>
            
      </div>
  </div>
  
  <div class="ph-modal-row">
      <div class="ph-modal-cellFull ph-modal-cellFull-splitContent">
         <b>Step 3.</b> Enter your verification code:
      </div>
  </div>

  <div class="ph-modal-row">
      <div class="ph-modal-cellFull ph-modal-cellFull-splitContent">
          <input class="form-control"
                  id="code"
                  name="code"
                  placeholder="Verification Code"
                  (ngModelChange)="hideError()"
                  (keyup)="handleKeyUp($event)"
                  [(ngModel)]="verification_code" /> 
      </div>
  </div>

  <div class="ph-modal-info error"
    *ngIf="error_message">
      <i class="ion ion-ios-warning sm-icon"></i> {{error_message}}
  </div>
</div>



  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="cancel()">
      Cancel
    </button>
    <button class="ph-modal-btnRight -color-success"
          [disabled] ="!verification_code"
          (click)="verify()">
      <span>
        Confirm
      </span>
    </button>
  </div>
</div>
