<div class="ph-formItem">
  <div class="ph-formItem-label"> {{inputLabel}}
    <div class="app-fieldOptional"> (optional)
    </div>
  </div>
  <div>
  </div>
    <file-uploader [file_url]="file_url"
                   [file_type]="'image'"
                   (file_changed)="updateImage($event.file)"
                   (file_cleared)="clearImage()"
                   (file_error)="imageError($event.error)">
    </file-uploader>

  <div class="ph-formItem-error"
       *ngIf="customError"> {{customError}} </div>
</div>
