<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      Transfer Account Subscription
    </div>
    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="cancel()">
    </i>
  </div>

  <div class="ph-modal-container">

    <div class="app-errorMessage"
         [ngClass]="{ '-visible': !!error_message }">
      <i class="ion ion-ios-warning sm-icon"></i> {{error_message}}
    </div>

    <div class="ph-formItem-container -smallForm">

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Contact Name
        </div>
        <div class="ph-formItem-value">
          <input class="ph-formItem-input"
                 type="text"
                 placeholder="Contact Name"
                 [(ngModel)]="transfer_contact_name">
        </div>
      </div>

      <div class="ph-formItem">
        <div class="ph-formItem-label">
          Email Address
        </div>
        <div class="ph-formItem-value">
          <input class="ph-formItem-input"
                 type="email"
                 placeholder="Email Address"
                 [(ngModel)]="transfer_email_address">
        </div>
      </div>
    </div>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnLeft"
            (click)="cancel()">
      Cancel
    </button>
    <button class="-color-success ph-modal-btnRight"
            (click)="transfer()">
      Transfer
    </button>
  </div>
