import { ConfirmModalType, ConfirmModalCustomData } from './../../app.types';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input() type: ConfirmModalType;
  @Input() itemType: string;
  @Input() itemTitle: string;
  @Input() itemDescription: string;
  @Input() additionalWarning: string;
  @Input() customData: ConfirmModalCustomData;

  icon: string;
  image: string;
  title: string;
  question: string;
  warning: string;
  buttonConfirmClass: string;

  buttonTitle: string;
  onlyWarningState: boolean = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.confirm();
    }
  }

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    switch (this.type) {

      case 'Delete':
        this.icon = 'ion-ios-close-circle-outline';
        this.title = 'Delete ' + this.itemType;
        this.question = 'Are you sure you want to delete <b>' + this.itemTitle + '</b>?';
        this.warning = this.additionalWarning;
        this.buttonConfirmClass = '-color-danger';
        break;

      case 'Remove':
        this.icon = 'ion-ios-close-circle-outline';
        this.title = 'Remove ' + this.itemType;
        this.question = 'Are you sure you want to remove <b>' + this.itemTitle + '</b>?';
        this.warning = this.additionalWarning;
        this.buttonConfirmClass = '-color-danger';
        break;

      case 'Continue':
        this.icon = 'ion-log-in';
        this.title = 'Continue ' + this.itemType;
        this.question = 'Are you sure you want to continue with <b>' + this.itemTitle + '</b>?';
        this.warning = this.additionalWarning;
        this.buttonConfirmClass = '-color-success';
        break;

      case 'Custom':
        this.icon = this.customData.icon;
        this.title = this.customData.title;
        this.question = this.customData.question;
        this.warning = this.customData.warning;
        this.buttonTitle = this.customData.buttonTitle;
        this.buttonConfirmClass = this.customData.buttonConfirmClass;
        break;

      case 'Disconnect':
        this.image = 'assets/icon/DisconnectIntegrationIcon.svg';
        this.title = 'Disconnect from ' + this.itemTitle;
        this.question = 'Are you sure you want to disconnect from <b>' + this.itemTitle + '</b>?';
        this.warning = this.additionalWarning;
        this.buttonConfirmClass = '-color-danger';
        break;

      case 'Cancel':
        this.icon = 'ion-ios-close-circle';
        this.title = 'Cancel ' + this.itemType;
        this.question = 'Are you sure you want to cancel <b>' + this.itemTitle + '</b>?';
        this.warning = this.additionalWarning;
        this.buttonConfirmClass = '-color-danger';
    }

    this.onlyWarningState = this.customData && !!this.customData.onlyWarningState;

  }

  confirm() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
