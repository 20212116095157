import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { FtAccount } from './../../models/ft-account/ft-account';
import { DbUtilService } from './../db-util/db-util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { AuthService } from './../auth/auth.service';
import { SortUtilService } from './../sort-util/sort-util.service';
import { SocketService } from './../socket/socket.service';
import { UserService } from './../user/user.service';
import { env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FtAccountService {

  ftAccounts: FtAccount[] = [];
  ftAccountsMap: Record<string, FtAccount> = {};

  private _ftAccountUpdateEvent = new Subject<any>();

  serviceSetup: boolean = false;

  constructor(
    public dbUtilService: DbUtilService,
    public authService: AuthService,
    public socketService: SocketService,
    public userService: UserService
  ) { }

  // Initialisation ///////////////

  initialiseService(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this._initSocketEvents();
        this.loadFtAccounts()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData(): void {
    this.ftAccounts = [];
    this.ftAccountsMap = {};
    this.serviceSetup = false;
  }

  private _initSocketEvents() {
    this.socketService.subToEvent('flexitime_account_updated', 'FlexitimeService', (event) => {
      this.loadFtAccounts().finally(() => {
        this._ftAccountUpdateEvent.next(event);
      });
    });
  }

  // Getters //////////////////////

  getFtAccount(
    company_code: string
  ): FtAccount {
    return this.ftAccountsMap[company_code] || null;
  }

  getAllFtAccounts(): FtAccount[] {
    return this.ftAccounts;
  }

  getFtAccountUpdatedEvent(): Observable<any> {
    return this._ftAccountUpdateEvent.asObservable();
  }

  // Network Calls ///////////////

  loadFtAccounts(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      if (this.userService.has_subscription && this.userService.is_partner) {
        this.dbUtilService.APIGet('partner/flexitimeaccounts')
          .then((data) => {
            this.ftAccounts = [];
            this.ftAccountsMap = {};

            const accounts = this.setupFtAccounts(data);

            for (const account of accounts) {
              if (!account.subscribed_flag) {
                this.ftAccounts.push(account);
                this.ftAccountsMap[account.company_code] = account;
              }
            }

            SortUtilService.sortList(this.ftAccounts, 'company_name');

            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
      else {
        this.ftAccounts = [];
        this.ftAccountsMap = {};
        resolve();
      }
    });
  }

  migrateToPayHero(ftAccount: FtAccount): void {
    window.open(
      env.subscription.app_url +
      'token/payhero/migrationloader?access_key=' +
      ftAccount.migration_access_key +
      '&session_key=' +
      this.authService.session_key);
  }

  setupFtAccounts(ft_accounts_data: any[]): FtAccount[] {
    const ft_accounts = [];

    for (const f of ft_accounts_data) {
      ft_accounts.push(this.setupFtAccount(f));
    }

    SortUtilService.sortList(ft_accounts, 'company_name');

    return ft_accounts;
  }

  setupFtAccount(f: any): FtAccount {
    return new FtAccount(
      f.company_code,
      f.company_name,
      f.migration_access_key,
      f.subscribed_flag,
      f.cancelled_flag,
      f.migrated_flag,
      TimeUtilService.dateStringToDate(f.migrated_date),
      f.company_contact_name,
      f.company_contact_email
    );
  }

}
