<div [hidden]="!initialised"
     class="app-auth-full-container">
  <form id="jwtForm"
        method="post"
        action=""
        target="_blank">
    <input id="jwtInput"
           type="hidden"
           name="jwt" />
  </form>
  <img class="app-auth-logo -externalPage"
       *ngIf="productTheme && !domain_data"
       [src]="productTheme.headerLogo">
  <a class="app-header-byFlexitime"
     *ngIf="product_value !== 'FLEXITIME' && product_value !== 'DROPPAH' && !domain_data"
     href="https://www.flexitime.works"
     target="_blank">
    <img [src]="productTheme.fromFlexiTimeLogo">
  </a>

  <img class="app-auth-domainLogo"
       *ngIf="!!domain_data"
       [src]="domain_data.company_logo">

  <div class="app-auth-pageTitleContainer"
       [ngClass]="{ '-expandTitle': forgottenPassword,
                    '-domainData': !!domain_data }">
    <h1 class="app-auth-pageTitle"
        *ngIf="forgottenPassword">
      {{ product_value === 'DROPPAH' ? 'Reset your Password' : 'Reset Password' }}
    </h1>
    <h1 class="app-auth-pageTitle"
        *ngIf="domain_data && !forgottenPassword">
      Sign In to {{domain_data.company_name}}
    </h1>
  </div>

  <div class="app-auth-contentContainer">

    <div class="-productGraphic"
         *ngIf="productTheme?.graphicLeft">
      <img [src]="productTheme.graphicLeft">
    </div>

    <div class="app-auth-card -signup-or-login-page"
         [ngClass]="{'-whiteCard': product_value !== 'DROPPAH'}">

      <spinner *ngIf="loading">
      </spinner>

      <div class="app-auth-card-inner"
           #authCardInner
           *ngIf="!forgottenPassword"
           [ngClass]="{ '-submitting': loading }">

        <div *ngIf="product_value === 'DROPPAH'"
             class="app-auth-droppah-content">
          <h1>
            Sheet, is that the time?

          </h1>
          <h4>
            Let’s get stuck in.
          </h4>
        </div>

        <div *ngIf="!from_native">
          <div class="app-auth-integration-container">
            <ng-container *ngFor="let integration of loginIntegrations">
              <integration-button *ngIf="productTheme.displayName !== 'FlexiTime' || integration.integration_type !== 'XERO'"
                                  [loginIntegration]="integration"
                                  [customPrefix]="'Sign in with'"
                                  [isDroppah]="product_value === 'DROPPAH'"
                                  (buttonPressed)="integrationButton($event.integration_type)">
              </integration-button>
            </ng-container>
          </div>

          <div *ngIf="product_value === 'DROPPAH'"
               class="app-integrationsDivider">
            <hr class="leftDivider">
            <div>
              Or
            </div>
            <hr class="rightDivider">
          </div>

          <div *ngIf="product_value !== 'DROPPAH'"
               class="app-integrationsDivider">
            <div class="app-integrationsDivider-text">
              or sign in with email
            </div>
            <hr class="app-integrationsDivider-line" />
          </div>
        </div>

        <div class="app-auth-errorMessage"
             *ngIf="!showSuccessMessage"
             [ngClass]="{ '-hidden': !errorMessage }">
          <i class="ion ion-ios-warning sm-icon"></i> {{errorMessage}}
        </div>
        <div class="app-auth-errorMessage -successMessage"
             *ngIf="showSuccessMessage"
             [ngClass]="{ '-hidden': !errorMessage }"
             [innerHTML]="errorMessage | safeHtml">

          <div class="app-auth-errorMessage -successMessage"
               *ngIf="!!zendesk_return_to">
            Sign in to {{productTheme.displayName}} with your usual login details to engage with our support centre
          </div>
        </div>

        <form class="app-auth-form"
              (ngSubmit)="login('PASSWORD')">
          <div class="fieldLabel">
            Email or Username
          </div>
          <input class="app-input -inputLarge"
                 type="text"
                 id="username"
                 name="username"
                 [placeholder]="product_value === 'DROPPAH' ? 'Email or Username' : ''"
                 [(ngModel)]="username"
                 (ngModelChange)="hideError()"
                 autocapitalize="none"
                 [disabled]="loading"
                 [autocomplete]="ignore_cached_username ? 'off' : ''">

          <div class="fieldLabel">
            Password
            <span class="app-auth-forgot"
                  (click)="resetPassword(true)">
              Forgot Password?
            </span>
          </div>
          <div class="password-field">
            <input class="app-input -inputLarge"
                   [type]="showPassword ? 'text' : 'password'"
                   id="password"
                   name="password"
                   [placeholder]="product_value === 'DROPPAH' ? 'Password' : ''"
                   [(ngModel)]="password"
                   (ngModelChange)="hideError()"
                   [disabled]="loading"
                   [autocomplete]="ignore_cached_username ? 'off' : ''">
            <span class="-showPasswordIcon"
                  (click)="toggleShowPassword($event)">
              <i class="ion"
                 [ngClass]="!showPassword ? 'ion-md-eye-off' : 'ion-md-eye'"></i>
            </span>
          </div>

          <div class="fieldLabel -mobile-only"
               *ngIf="product_value === 'DROPPAH'">
            <span class="app-auth-forgot"
                  (click)="resetPassword(true)">
              Forgot Password?
            </span>
          </div>

          <button class="app-auth-submit"
                  [disabled]="loading">
            {{signInButtonText}}
          </button>

        </form>



        <a class="app-footer-byFlexitime"
           *ngIf="product_value === 'DROPPAH' && !domain_data"
           href="https://www.flexitime.works"
           target="_blank">
          <img [src]="productTheme.fromFlexiTimeLogo">
        </a>


        <div class="app-auth-textLink"
             *ngIf="!!productTheme.employeeAccURL  && !from_native">
          <a id="employeehelp"
             [href]="productTheme.employeeAccURL"
             target="_blank"
             [hidden]="!initialised">
            {{employeeHelpText}}
          </a>
        </div>

        <div class="app-auth-textLink"
             *ngIf="!!productTheme.signUpLinkLabel  && !from_native">
          <a id="signuppayhero"
             [href]="productTheme.signUpURL"
             target="_blank"
             [hidden]="!initialised">
            {{productTheme.signUpLinkLabel}}
          </a>
        </div>

        <app-footer-user-agreements *ngIf="product_value === 'DROPPAH'"
                                    [support_centre_link]="productTheme.supportCentreURL"></app-footer-user-agreements>

      </div>

      <div class="app-auth-card-inner"
           #authCardInner
           *ngIf="forgottenPassword"
           [ngClass]="{ '-submitting': loading }">

        <form class="app-auth-form"
              (ngSubmit)="sendPasswordReset()">

          <div class="fieldLabel">
            Email or Username
          </div>

          <input class="app-input -inputLarge"
                 type="text"
                 id="email"
                 name="email"
                 [placeholder]="product_value === 'DROPPAH' ? 'Email or Username' : ''"
                 [(ngModel)]="resetEmail"
                 (ngModelChange)="hideError()"
                 autocapitalize="none"
                 [disabled]="loading">

          <button class="app-auth-submit"
                  id="reset"
                  [disabled]="loading">
            Send Email
          </button>
        </form>

        <div class="app-auth-textLink"
             (click)="resetPassword(false)">
          <a *ngIf="productTheme.displayName === 'PayHero'">Back to login</a>
          <ng-container *ngIf="productTheme.displayName !== 'PayHero'">Back to login</ng-container>
        </div>
      </div>
      <!-- end of app-card -->
    </div>

    <div class="-productGraphic"
         *ngIf="productTheme?.graphicRight">
      <img [src]="productTheme.graphicRight">
    </div>
  </div>
</div>

<app-footer-user-agreements *ngIf="product_value !== 'DROPPAH'"
                            [support_centre_link]="productTheme.supportCentreURL"></app-footer-user-agreements>

<a class="app-header-byFlexitime -domainData"
   *ngIf="!!domain_data"
   href="https://www.flexitime.works"
   target="_blank">
  <img src="assets/product-imgs/flexitime/FromFlexiTime_Horiz.svg" />
</a>

<div class="app-auth-mobileGraphicFooter"
     *ngIf="!!productTheme?.graphicMobileFooterFull && productTheme.displayName !== 'FlexiTime'">
  <img [src]="productTheme.graphicMobileFooterFull">
</div>
