<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          Partner Accounts
        </div>
      </div>

      <div class="app-nav-rowRight">
        <button class="-full -color-payhero"
                *ngIf="showAddAccountButton"
                (click)="newAccount()">
          New Account
        </button>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window"
         [ngClass]="{ '-noAccounts': !partnerAccounts.length }">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="dp-list-empty"
           *ngIf="!partnerAccounts.length">
        <div class="dp-list-emptyHeader">
          No Partner Accounts
        </div>
        <div class="dp-list-emptyDescription">
          You don't have any partner accounts set up
        </div>
        <button class="-type-text -color-payhero-clear"
                *ngIf="showAddAccountButton"
                (click)="newAccount()">
          New Account
        </button>
      </div>

      <account-list *ngIf="partnerAccounts.length"
                    [accounts]="partnerAccounts"
                    (selectAccount)="selectAccount($event.account)"
                    (subscribe)="subscribe($event.account)"
                    (signIn)="signIn($event.account, $event.external_state_name)"
                    (viewSubscription)="viewSubscription($event.account)">
      </account-list>

    </div>

  </div>

</div>
