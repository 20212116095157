<banner #banner
        [bannerConfig]="banner_config">
</banner>

<div class="sb-nav-wrapper"
    [ngClass]="{ '-navBanner' : banner.bannerVisible }">
  <div class="sb-menu"
       *ngIf="!activeStateIsNonMenu"
       [ngClass]="{ 'menuHidden': uiRouterGlobals.params.product || !menuOpen }">

    <div class="sb-menu-header">
      <button class="-type-icon -color-default-clear sb-menu-mobileMenuIcon"
              *ngIf="!menuOpen && activeStateIsStickieable"
              (click)="toggleMenu()">
        <i class="ion ion-ios-menu"></i>
      </button>
      <button class="-type-icon -color-default-clear sb-menu-mobileMenuIcon"
              *ngIf="!menuOpen && !activeStateIsStickieable"
              (click)="back()">
        <i class="ion-ios-arrow-back"></i>
      </button>

      <img class="sb-menu-headerImg"
           title="Toggle Menu"
           [src]="navHeaderImage"
           (click)="toggleMenu()">

      <button class="-type-icon -color-default-clear sb-menu-menuButton"
              (click)="toggleMenu()"
              title="Close Menu">
        <i class="ion ion-md-close sb-menu-menuIcon">
        </i>
      </button>
    </div>

    <div class="sb-menu-itemGroup"
         *ngFor="let itemGroup of menuItems; let i = index"
         [ngStyle]="{ 'margin-bottom': i === 0 ? 'auto' : '' }">
      <div class="sb-menu-item"
           *ngFor="let item of itemGroup"
           [title]="item.name"
           [ngClass]="{ '-activeRootState': activeRootState === item.root_state }"
           (click)="item.itemSelected()">

        <div class="sb-menu-itemHeader">
          <div class="sb-menu-itemHeader-name">
            {{item.name}}
          </div>
          <i class="sb-menu-itemHeader-img"
             *ngIf="item.imgClass"
             [ngClass]="item.imgClass">
          </i>
          <i class="sb-menu-itemHeader-arrow ion"
             *ngIf="menuItemChildren[item.root_state]?.length"
             [ngClass]="activeRootState === item.root_state ? 'ion-ios-arrow-down' : 'ion-ios-arrow-forward'">
          </i>
        </div>

        <div class="sb-menu-itemChild"
             *ngFor="let child_item of menuItemChildren[item.root_state]"
             [ngClass]="{'-activeState': activeState === child_item.state}"
             (click)="child_item.itemSelected()">
          <div class="sb-menu-itemChild-name">
            {{child_item.name}}
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="sb-menu-content"
       [ngClass]="{ 'menuHidden': uiRouterGlobals.params.product, '-menuInactive': activeStateIsNonMenu}">

    <ui-view></ui-view>
  </div>
</div>
