import { CoreUtilService } from "src/app/services/core-util/core-util.service";
import { TimeUtilService } from "src/app/services/time-util/time-util.service";
import { PostableObjectError, PostableObjectInterface } from "../../interfaces/postable-object.inerface";

export type PhWorkPatternValue = ('REGULAR_WEEK' | 'REGULAR_DAYS_HOURS' | 'NO_REGULAR_DAYS' | 'NO_REGULAR_HOURS' | 'NO_PATTERN');

export class PhEmployee implements PostableObjectInterface{

  employee_key: number;
  employee_code: string;
  first_name: string;
  last_name: string;
  email: string;
  email_payslips: boolean;
  invite_to_portal_flag: boolean;
  employment_start_date: Date;
  bank_acc: number;
  salary_flag: boolean;
  hourly_rate: number;
  annual_salary: number;
  ird_number: number;
  tax_code: string;
  hours_per_day: number;
  days_per_week: number;
  hours_mon: number;
  hours_tue: number;
  hours_wed: number;
  hours_thu: number;
  hours_fri: number;
  hours_sat: number;
  hours_sun: number;
  pay_cycle_key: number;
  has_hours_for_weekdays: boolean;
  has_hours_per_week: boolean;
  has_days_per_week: boolean;
  normal_hours_per_week: number;
  selected_work_pattern: PhWorkPatternValue;

  constructor(
    employee_key: number = null,
    employee_code: string = null,
    first_name: string = null,
    last_name: string = null,
    email: string = null,
    invite_to_portal_flag: boolean = false,
    employment_start_date: Date = null,
    bank_acc: number = null,
    salary_flag: boolean = false,
    hourly_rate: number = null,
    annual_salary: number = null,
    ird_number: number = null,
    tax_code: string = null,
    hours_per_day: number = 0,
    days_per_week: number = 0,
    hours_mon: number = 0,
    hours_tue: number = 0,
    hours_wed: number = 0,
    hours_thu: number = 0,
    hours_fri: number = 0,
    hours_sat: number = 0,
    hours_sun: number = 0,
    pay_cycle_key: number = null,
    has_hours_for_weekdays: boolean = true,
    has_hours_per_week: boolean = false,
    has_days_per_week: boolean = false,
    normal_hours_per_week: number = 0
  ) {
    this.employee_key = employee_key;
    this.employee_code = employee_code;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.email_payslips = !!email; // Set email payslips to true if they've provided an email address
    this.invite_to_portal_flag = invite_to_portal_flag;
    this.employment_start_date = employment_start_date;
    this.bank_acc = bank_acc;
    this.salary_flag = salary_flag;
    this.hourly_rate = hourly_rate;
    this.annual_salary = annual_salary;
    this.ird_number = ird_number;
    this.tax_code = tax_code;
    this.hours_per_day = hours_per_day;
    this.days_per_week = days_per_week;
    this.hours_mon = hours_mon;
    this.hours_tue = hours_tue;
    this.hours_wed = hours_wed;
    this.hours_thu = hours_thu;
    this.hours_fri = hours_fri;
    this.hours_sat = hours_sat;
    this.hours_sun = hours_sun;
    this.pay_cycle_key = pay_cycle_key;
    this.has_hours_for_weekdays = has_hours_for_weekdays;
    this.has_hours_per_week = has_hours_per_week;
    this.has_days_per_week = has_days_per_week;
    this.normal_hours_per_week = normal_hours_per_week;
  }

  formatForPosting(): any {
    return {
      employee_key: this.employee_key,
      employee_code: this.first_name + ' ' + this.last_name,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      email_payslips: this.email_payslips,
      employment_start_date: TimeUtilService.formatDateForPosting(this.employment_start_date, false),
      tax_code: this.tax_code,
      ird_number: this.ird_number,
      annual_salary: this.annual_salary,
      normal_rate: this.hourly_rate,
      bank_acc: this.bank_acc,
      hours_per_day: this.hours_per_day,
      days_per_week: this.days_per_week,
      hours_mon: this.hours_mon,
      hours_tue: this.hours_tue,
      hours_wed: this.hours_wed,
      hours_thu: this.hours_thu,
      hours_fri: this.hours_fri,
      hours_sat: this.hours_sat,
      hours_sun: this.hours_sun,
      salary_flag: this.salary_flag,
      contractor_flag: false,
      pay_cycle_key: this.pay_cycle_key,
      has_hours_for_weekdays: this.has_hours_for_weekdays,
      has_hours_per_week: this.has_hours_per_week,
      has_days_per_week: this.has_days_per_week,
      normal_hours_per_week: this.normal_hours_per_week
    }
  }

  getErrors(): PostableObjectError[] {
    const errors = [];

    if (!this.first_name){
      errors.push({
        error_path: 'first_name',
        error_message: 'First name required'
      });
    }
    if (!this.last_name){
      errors.push({
        error_path: 'last_name',
        error_message: 'Last name required'
      });
    }
    if (!this.validateEmailAddress()){
      errors.push({
        error_path: 'email',
        error_message: 'Invalid email'
      });
    }
    if (!this.email && this.invite_to_portal_flag) {
      errors.push({
        error_path: 'portal_invite',
        error_message: 'Email required to invite employee to PayHero Portal'
      });
    }
    if (!this.employment_start_date) {
      errors.push({
        error_path: 'employment_start_date',
        error_message: 'Employment start date required'
      });
    }
    if ((this.salary_flag && !this.annual_salary) ||
        (!this.salary_flag && !this.hourly_rate)) {
      errors.push({
        error_path: 'pay_rate',
        error_message: this.salary_flag ? 'Annual salary required' : 'Hourly rate required'
      });
    }
    if ((this.annual_salary > 999999999 || this.annual_salary < 0) && this.salary_flag) {
      errors.push({
        error_path: 'salary',
        error_message: 'Invalid salary'
      });
    }
    if ((this.hourly_rate > 999999999 || this.hourly_rate < 0) && !this.salary_flag) {
      errors.push({
        error_path: 'hourly',
        error_message: 'Invalid hourly rate'
      });
    }
    if (!this.validateEmployeeWorkPattern()) {
      errors.push({
        error_path: 'work_pattern',
        error_message: 'Invalid work pattern'
      });
    }
    if (!this.ird_number && this.tax_code !== 'ND') {
      errors.push({
        error_path: 'ird_number',
        error_message: 'IRD number required'
      });
    }
    if (!this.tax_code) {
      errors.push({
        error_path: 'tax_code',
        error_message: 'Tax code required'
      });
    }

    return errors;
  }
  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  validateEmailAddress(): boolean {
    if (!this.email) { return true; }
    return CoreUtilService.validateEmailAddress(this.email)
  }

  getNumDaysWithHours(): number {
    return (this.hours_mon ? 1 : 0) + (this.hours_tue ? 1 : 0) +
           (this.hours_wed ? 1 : 0) + (this.hours_thu ? 1 : 0) +
           (this.hours_fri ? 1 : 0) + (this.hours_sat ? 1 : 0) +
           (this.hours_sun ? 1 : 0);
  };

  validateEmployeeWorkPattern(): boolean {
    let clearDailyHours = () => {
      this.hours_mon = 0;
      this.hours_tue = 0;
      this.hours_wed = 0;
      this.hours_thu = 0;
      this.hours_fri = 0;
      this.hours_sat = 0;
      this.hours_sun = 0;
    };

    if (this.selected_work_pattern === 'REGULAR_WEEK'){
        this.has_hours_for_weekdays = true;
        this.has_hours_per_week = false;
        this.has_days_per_week = false;

        this.normal_hours_per_week = this.hours_mon + this.hours_tue + this.hours_wed + this.hours_thu + this.hours_fri + this.hours_sat + this.hours_sun;
        this.normal_hours_per_week = CoreUtilService.round(this.normal_hours_per_week, 2);
        this.days_per_week = this.getNumDaysWithHours();
        this.hours_per_day = (this.normal_hours_per_week / this.days_per_week) || 0;
    }
    else if (this.selected_work_pattern === 'REGULAR_DAYS_HOURS'){
        this.has_hours_for_weekdays = false;
        this.has_hours_per_week = false;
        this.has_days_per_week = true;

        clearDailyHours();
        this.normal_hours_per_week = this.days_per_week * this.hours_per_day;
        this.normal_hours_per_week = CoreUtilService.round(this.normal_hours_per_week, 2);
    }
    else if (this.selected_work_pattern === 'NO_REGULAR_HOURS'){
      this.has_hours_for_weekdays = false;
      this.has_hours_per_week = false;
      this.has_days_per_week = true;

      clearDailyHours();
      this.hours_per_day = 0;
      this.normal_hours_per_week = 0;
    }
    else if (this.selected_work_pattern === 'NO_REGULAR_DAYS'){
      this.has_hours_for_weekdays = false;
      this.has_hours_per_week = true;
      this.has_days_per_week = false;

      clearDailyHours();
      this.days_per_week = 0;
      this.hours_per_day = 0;
    }
    else {
        this.has_hours_for_weekdays = false;
        this.has_hours_per_week = false;
        this.has_days_per_week = false;

        clearDailyHours();
        this.hours_per_day = 0;
        this.days_per_week = 0;
        this.normal_hours_per_week = 0;
    }

    var valid = false;

    if (this.selected_work_pattern === 'REGULAR_WEEK'){
        valid = this.days_per_week > 0 && this.days_per_week <= 7 &&
        this.hours_mon <= 24 && this.hours_mon >= 0 &&
        this.hours_tue <= 24 && this.hours_tue >= 0 &&
        this.hours_wed <= 24 && this.hours_wed >= 0 &&
        this.hours_thu <= 24 && this.hours_thu >= 0 &&
        this.hours_fri <= 24 && this.hours_fri >= 0 &&
        this.hours_sat <= 24 && this.hours_sat >= 0 &&
        this.hours_sun <= 24 && this.hours_sun >= 0;
    }
    else if (this.selected_work_pattern === 'REGULAR_DAYS_HOURS'){
        valid = !!this.days_per_week && !!this.hours_per_day &&
        this.days_per_week <= 7 && this.days_per_week > 0 &&
        this.hours_per_day <= 24 && this.hours_per_day > 0;
    }
    else if (this.selected_work_pattern === 'NO_REGULAR_HOURS'){
      valid = !!this.days_per_week && !this.salary_flag &&
      this.days_per_week <= 7 && this.days_per_week > 0; // noRegularHours not valid for Salary
    }
    else if (this.selected_work_pattern === 'NO_REGULAR_DAYS'){
      valid = !!this.normal_hours_per_week && this.normal_hours_per_week <= 168
      && this.normal_hours_per_week > 0;
    }
    // No Regular Week
    else {
        valid = !this.salary_flag;
    }

    return valid;
  }
}
