<div class="ph-formItem">
  <div class="ph-formItem-label">
    {{formField.label}}
    <div class="app-fieldOptional"
         *ngIf="!formField.field_required">
      (optional)
    </div>
  </div>
  <div class="ph-formItem-value"
       *ngIf="!formField.field_readonly">
    <input class="ph-formItem-input"
           [type]="formField.field_type || 'text'"
           [placeholder]="formField.label"
           [(ngModel)]="formObject[formField.property]"
           [ngClass]="{ '-invalid': !!formFieldError }"
           (click)="clearFormFieldError.emit({ property: formField.property })">
  </div>
  <div class="ph-formItem-value"
       *ngIf="formField.field_readonly">
    {{formObject[formField.property]}}
  </div>
  <div class="ph-formItem-error"
       *ngIf="!!formFieldError">
    {{formFieldError}}
  </div>
</div>
