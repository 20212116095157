<dp-list #subscriptionAccountList
         [tableHeaders]="tableHeaders"
         [primarySortProperty]="orderProperty"
         [itemType]="'account'"
         [itemTypePlural]="'accounts'"
         [itemIsVisible]="accountIsVisible"
         [itemSelectDisabled]="isPartnerAccounts"
         [itemSelectEnabledProperty]="'subscribed_flag'"
         [noItemsMessage]="noAccountsMessage"
         [items]="accounts"
         (itemSelected)="goToSubscriptionAccount($event.item)">
  <ng-template dpListItem
               let-item>

    <div class="dp-list-itemCell -desktop-only">
      <product-logo [productValue]="item.product_value">
      </product-logo>
    </div>

    <div class="dp-list-itemCell">
      <div class="sal-companyName"
           [ngClass]="{ '-linkActive': !isPartnerAccounts && (item.account_status === 'SUBSCRIBED' || item.account_status === 'SUSPENDED') }">
        {{item.company_name}}
      </div>
      <div class="sal-detailsLines"
           *ngIf="item.ownership_transfer_status !== null">
        <div class="sal-detailsLine -pendingTransfer">
          <span>Pending Subscription Transfer</span>
          <div class="-pendingTransfer-email"
               *ngIf="item.account_actions.has('TRANSFER_REVOKE')">
            to&nbsp;{{item.pending_subscription_contact_email}}
          </div>
        </div>
      </div>
    </div>

    <div class="dp-list-itemCell -desktop-only">
      <div class="sal-trialStatus"
           *ngIf="item.account_status === 'ON_TRIAL' || item.account_status === 'TRIAL_EXPIRED'">
        Free Trial:&nbsp;
        <span *ngIf="item.account_status === 'TRIAL_EXPIRED'">
          Expired
        </span>
        <span *ngIf="item.account_status === 'ON_TRIAL'">
          {{item.trial_days_remaining}} day{{item.trial_days_remaining !== 1 ? 's' : ''}} remaining
        </span>
      </div>
      <div class="sal-trialStatus"
           *ngIf="item.account_status === 'UNSUBSCRIBED'">
        Unsubscribed
      </div>
      <div class="sal-planLines"
           *ngIf="item.account_status === 'SUBSCRIBED' || item.account_status === 'SUSPENDED'">
        <div class="sal-planLine"
             *ngFor="let line of item.account_subscription.account_subscription_lines">
          <span *ngIf="line.subscription_plan">
            {{line.subscription_plan.plan_display_name}}
          </span>
          <span *ngIf="!line.subscription_plan">
            -
          </span>
        </div>
      </div>
    </div>

    <div class="dp-list-itemCell -desktop-only"
         [ngStyle]="{ 'opacity': isPartnerAccounts ? 0 : '' }">
      <div class="sal-detailsLines"
           *ngIf="item.account_status === 'SUBSCRIBED' || item.account_status === 'SUSPENDED'">
        <div class="sal-detailsLine"
             *ngFor="let line of item.account_subscription.account_subscription_lines">
          <span *ngIf="line.line_type === 'UNITS'">
            {{line.quantity}} {{line.quantity === 1 ? line.unit_description : line.unit_description_plural}}
          </span>
          <span *ngIf="line.line_type === 'FIXED'">
            {{line.line_description}}
          </span>
        </div>
      </div>
    </div>

    <div class="dp-list-itemCell"
         [ngClass]="{ '-cancelled': item.account_status === 'CANCELLED' }"
         [ngStyle]="{ 'opacity': isPartnerAccounts || !this.account_has_discount_flag ? 0 : '' }">

      <div class="sal-costLines"
           *ngIf="item.account_status === 'SUBSCRIBED' || item.account_status === 'SUSPENDED'">
        <div class="sal-costLine"
             *ngFor="let line of item.account_subscription.account_subscription_lines">
          <span *ngIf="!!line.discount_percentage">{{line.discount_percentage}}%</span>
        </div>
      </div>
    </div>

    <div class="dp-list-itemCell"
         [ngClass]="{ '-cancelled': item.account_status === 'CANCELLED' }"
         [ngStyle]="{ 'opacity': isPartnerAccounts ? 0 : '' }">

      <div class="sal-costLines"
           *ngIf="item.account_status === 'SUBSCRIBED' || item.account_status === 'SUSPENDED'">
        <div class="sal-costLine"
             *ngFor="let line of item.account_subscription.account_subscription_lines"
             [ngStyle]="{ 'font-weight': line.line_total > 0 ? 500 : '' }">
          <span>{{line.line_total | appCurrency}}</span>
        </div>
      </div>

      <div class="sal-costLines"
           *ngIf="item.account_status === 'ON_TRIAL' || item.account_status === 'TRIAL_EXPIRED'">
        <div class="sal-costLine">
          <span>{{0 | appCurrency}}</span>
        </div>
      </div>
    </div>

    <div class="dp-list-itemCell"
         [ngClass]="{ '-cancelled': item.account_status === 'CANCELLED' }"
         (click)="$event.stopPropagation()">
      <div class="sal-cancelled"
           *ngIf="item.account_status === 'CANCELLED'">
        Cancelled
      </div>
      <div class="sal-suspended"
           *ngIf="item.account_status === 'SUSPENDED'">
        <i class="bi bi-exclamation-triangle-fill"
           [ngbTooltip]="'Suspended'"
           [container]="'body'"
           [placement]="'top'">
        </i>
      </div>

      <button class="-color-success -subscribe -desktop-only"
              *ngIf="item.account_actions.has('SUBSCRIBE')"
              (click)="subscribe.emit({ account: item })">
        Subscribe
      </button>

      <button class="-color-danger -transfer -desktop-only"
              *ngIf="item.account_actions.has('TRANSFER_REVOKE')"
              (click)="revokeOwnershipTransfer.emit({ account: item })">
        Revoke Transfer
      </button>

      <button class="-color-payhero -transfer -desktop-only"
              *ngIf="item.account_actions.has('TRANSFER_RESPOND')"
              (click)="subscribe.emit({ account: item })">
        Respond
      </button>

      <list-drop-down *ngIf="accountDropdownOptions[item.company_product_key][0]?.length || accountDropdownOptions[item.company_product_key][1]?.length"
                      [itemNameProperty]="'option_name'"
                      [itemIsToggleableProperty]="'option_is_toggleable'"
                      [itemToggleValueProperty]="'option_toggle_value'"
                      [itemFontColorProperty]="'option_color'"
                      [buttonTypeClass]="'-type-icon'"
                      [buttonIconClass]="'ion-ios-more'"
                      [buttonColorClass]="'-color-grey-clear'"
                      [listWidth]="'270px'"
                      [sortItems]="false"
                      [items]="accountDropdownOptions[item.company_product_key]"
                      (itemSelected)="accountDropdownOptionSelected($event.item.option_value, item)">
      </list-drop-down>

    </div>

  </ng-template>
</dp-list>
