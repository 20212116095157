<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          <div>
            Invoice History
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <dp-list #invoiceList
               [tableHeaders]="tableHeaders"
               [primarySortProperty]="orderProperty"
               [forwardOrder]="false"
               [itemType]="'invoice'"
               [itemTypePlural]="'invoices'"
               [itemSelectDisabled]="true"
               [itemIsVisible]="subscriptionInvoiceIsVisible"
               [noItemsMessage]="'You don\'t have any historic subscription invoices'"
               [items]="subscription.payments">
        <ng-template dpListItem
                     let-item>

          <div class="dp-list-itemCell"
               [ngClass]="{ '-downloading': item.downloading }">
            <button class="-type-text -color-payhero-clear"
                    (click)="downloadInvoice(item)">
              {{ item.invoice_date | date: 'd MMM, y' }}
            </button>
          </div>

          <div class="dp-list-itemCell -desktop-only"
               [ngClass]="{ '-downloading': item.downloading }">
            <div>{{ item.payment_date | date: 'd MMM, y' }}</div>
          </div>

          <div class="dp-list-itemCell"
               [ngClass]="{ '-downloading': item.downloading }">
            <span [ngClass]="{
              'suspended': !!item.suspension_date,
              'paid': !!item.payment_date
             }">
             <i class="bi bi-exclamation-triangle-fill" *ngIf="item.suspension_date"></i>
              {{ item.suspension_date ? 'Overdue' : (item.payment_date ? 'Paid' : 'Due') }}
            </span>
          </div>

          <div class="dp-list-itemCell"
               [ngClass]="{ '-downloading': item.downloading }">
            <div>{{ item.payment_amount | appCurrency }}</div>
          </div>

          <div class="dp-list-itemCell -desktop-only">
            <button class="-color-payhero-clear -type-icon"
                    *ngIf="!item.downloading"
                    (click)="downloadInvoice(item)">
              <i class="ion ion-ios-download"></i>
            </button>

            <img class="downloading-spinner"
                 src="assets/imgs/App_Loader.gif"
                 *ngIf="item.downloading">
          </div>

        </ng-template>
      </dp-list>

    </div>
  </div>
</div>
