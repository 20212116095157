import { Injectable } from '@angular/core';

import { TimeUtilService } from './../time-util/time-util.service';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';

import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class SortUtilService {

  /**
   * Sorts the given list by the specified primary and secondary object properties.
   * Sorts in ascending or descending order based on the forward_order param
   */
  static sortList(
    list: any[],
    primary_sort_property: string = null,
    secondary_sort_property: string = null,
    forward_order: boolean = true
  ): any[] {
    try {
      const primary_property_type = this._getSortPropertyType(list, primary_sort_property);
      const secondary_property_type = secondary_sort_property !== null ? this._getSortPropertyType(list, secondary_sort_property) : null;

      if (primary_property_type === 'STRING') {
        return _.clone(list.sort((a, b) => {
          return this._compareStrings(
            a, b, primary_sort_property, secondary_sort_property, secondary_property_type, forward_order
          );
        }));
      }
      else if (primary_property_type === 'NUMBER') {
        return _.clone(list.sort((a, b) => {
          return this._compareNumbers(
            a, b, primary_sort_property, secondary_sort_property, secondary_property_type, forward_order
          );
        }));
      }
      else if (primary_property_type === 'DATE') {
        return _.clone(list.sort((a, b) => {
          return this._compareDates(
            a, b, primary_sort_property, secondary_sort_property, secondary_property_type, forward_order
          );
        }));
      }
      else {
        return _.clone(list);
      }
    }
    catch (err) {
      console.log(err);
      return list;
    }
  }

  private static _compareStrings(
    a: any,
    b: any,
    primary_sort_property: string = null,
    secondary_sort_property: string = null,
    secondary_property_type: string = null,
    forward_order: boolean = true
  ): number {
    const values = this._getPropertyValues(a, b, primary_sort_property, secondary_sort_property);
    const av = (values.av || '').toUpperCase();
    const bv = (values.bv || '').toUpperCase();

    const result = forward_order ? av.localeCompare(bv) : bv.localeCompare(av);

    if (result === 0 && !!primary_sort_property && !!secondary_sort_property) {
      return this._getSecondaryPropertyResult(
        a, b, secondary_sort_property, secondary_property_type, forward_order, result
      );
    }
    else {
      return result;
    }
  }

  private static _compareNumbers(
    a: any,
    b: any,
    primary_sort_property: string = null,
    secondary_sort_property: string = null,
    secondary_property_type: string = null,
    forward_order: boolean = true
  ): number {
    const values = this._getPropertyValues(a, b, primary_sort_property, secondary_sort_property);
    const av = values.av;
    const bv = values.bv;

    if (av === null) {
      return 1;
    }
    else if (bv === null) {
      return -1;
    }
    let result: number;

    if (forward_order) {
      result = bv < av ? -1 : bv > av ? 1 : 0;
    }
    else {
      result = av < bv ? -1 : av > bv ? 1 : 0;
    }

    if (result === 0 && !!primary_sort_property && !!secondary_sort_property) {
      return this._getSecondaryPropertyResult(
        a, b, secondary_sort_property, secondary_property_type, forward_order, result
      );
    }
    else {
      return result;
    }
  }

  private static _compareDates(
    a: any,
    b: any,
    primary_sort_property: string = null,
    secondary_sort_property: string = null,
    secondary_property_type: string = null,
    forward_order: boolean = true
  ): number {
    const values = this._getPropertyValues(a, b, primary_sort_property, secondary_sort_property);
    const av = TimeUtilService.dateIsValid(values.av) ? values.av.valueOf() : null;
    const bv = TimeUtilService.dateIsValid(values.bv) ? values.bv.valueOf() : null;

    if (av === null) {
      return 1;
    }
    else if (bv === null) {
      return -1;
    }
    let result: number;

    if (forward_order) {
      result = av < bv ? -1 : av > bv ? 1 : 0;
    }
    else {
      result = bv < av ? -1 : bv > av ? 1 : 0;
    }

    if (result === 0 && !!primary_sort_property && !!secondary_sort_property) {
      return this._getSecondaryPropertyResult(
        a, b, secondary_sort_property, secondary_property_type, forward_order, result
      );
    }
    else {
      return result;
    }
  }

  private static _getSecondaryPropertyResult(
    a: any,
    b: any,
    secondary_sort_property: string = null,
    secondary_property_type: string = null,
    forward_order: boolean = true,
    result: number
  ): number {
    if (secondary_property_type === 'STRING') {
      return this._compareStrings(
        a, b, null, secondary_sort_property, secondary_property_type, forward_order
      );
    }
    else if (secondary_property_type === 'NUMBER') {
      return this._compareNumbers(
        a, b, null, secondary_sort_property, secondary_property_type, forward_order
      );
    }
    else if (secondary_property_type === 'DATE') {
      return this._compareDates(
        a, b, null, secondary_sort_property, secondary_property_type, forward_order
      );
    }
    else {
      return result;
    }
  }

  private static _getPropertyValues(
    a: any,
    b: any,
    primary_sort_property: string = null,
    secondary_sort_property: string = null,
  ): { av: any, bv: any } {
    let av: Date, bv: Date;

    if (primary_sort_property !== null) {
      av = CoreUtilService.getNestedPropertyValue(a, primary_sort_property);
      bv = CoreUtilService.getNestedPropertyValue(b, primary_sort_property);
    }
    else if (secondary_sort_property !== null) {
      av = CoreUtilService.getNestedPropertyValue(a, secondary_sort_property);
      bv = CoreUtilService.getNestedPropertyValue(b, secondary_sort_property);
    }
    else {
      av = a || null;
      bv = b || null;
    }

    return { av, bv };
  }

  private static _getSortPropertyType(list: any[], sort_property: string): string {
    for (const item of list) {
      if (item !== null) {
        const value = sort_property === null ? item : CoreUtilService.getNestedPropertyValue(item, sort_property);

        if (typeof value === 'string') {
          return 'STRING';
        }
        else if (typeof value === 'number') {
          return 'NUMBER';
        }
        else if (value instanceof Date) {
          return 'DATE';
        }
      }
    }
    return null;
  }
}
