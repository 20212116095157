import { ProductName, ProductValue, AccountStatus, AccountOwnershipTransferStatus, AccountAction } from './../../app.types';
import { AccountSubscription } from './../account-subscription/account-subscription';
import { ProductService } from '../../services/product/product.service';

export abstract class Account {

  private readonly _default_account_logos: string[] = [
    'https://flexitime.blob.core.windows.net/assets/Droppah_Single.svg',
    'https://payhero.blob.core.windows.net/images/PayHero_Single.svg',
    'https://payhero.blob.core.windows.net/images/PayHero_Icon_Background_White.png',
    'https://payhero.blob.core.windows.net/images/PayHero_Shield.png',
    'https://invoxy1.blob.core.windows.net/pictures/ByhkgI4ax.png',
    'https://invoxy1.blob.core.windows.net/pictures/Karmly_Single.png',
    'https://invoxy1.blob.core.windows.net/pictures/Karmly_Logo.png',
  ];

  access_deactivated: boolean;
  admin_flag: boolean;
  allow_user_access: boolean;
  allow_external_connect: boolean;
  allow_account_select: boolean;
  cancelled_flag: boolean;
  client_key: number;
  company_logo: string;
  company_name: string;
  company_product_key: number;
  external_company_reference: string;
  last_session_date: Date;
  owner_flag: boolean;
  pending_owner_flag: boolean;
  pending_subscription_key: number;
  pending_subscription_contact_name: string;
  pending_subscription_contact_email: string;
  product_key: number;
  product_name: ProductName;
  product_value: ProductValue;
  sample_company: boolean;
  subscription_key: number;
  subscription_billing_name: string;
  subscription_billing_email: string;
  no_subscription_access_flag: boolean;
  suspended_flag: boolean;
  trial_days_remaining: number;
  trial_expired_flag: boolean;
  subscribed_flag: boolean;
  user_access_key: number;
  account_subscription: AccountSubscription;
  has_company_settings_access: boolean;
  account_status: AccountStatus;
  ownership_transfer_status: AccountOwnershipTransferStatus;
  account_actions: Set<AccountAction>;
  incomplete_onboarding_steps: any;
  industry_key: number;
  auto_promo_code: string;

  constructor(
    access_deactivated: boolean,
    admin_flag: boolean,
    allow_user_access: boolean,
    allow_external_connect: boolean,
    allow_account_select: boolean,
    cancelled_flag: boolean,
    client_key: number,
    company_logo: string,
    company_name: string,
    company_product_key: number,
    external_company_reference: string,
    last_session_date: Date,
    owner_flag: boolean,
    pending_owner_flag: boolean,
    pending_subscription_key: number,
    pending_subscription_contact_name: string,
    pending_subscription_contact_email: string,
    product_key: number,
    product_name: ProductName,
    sample_company: boolean,
    subscription_key: number,
    subscription_billing_name: string,
    subscription_billing_email: string,
    no_subscription_access_flag: boolean,
    suspended_flag: boolean,
    trial_days_remaining: number,
    trial_expired_flag: boolean,
    subscribed_flag: boolean,
    user_access_key: number,
    account_subscription: AccountSubscription,
    has_company_settings_access: boolean,
    incomplete_onboarding_steps: [] = [],
    industry_key: number = null,
    auto_promo_code: string = null
  ) {
    this.access_deactivated = access_deactivated;
    this.admin_flag = admin_flag;
    this.allow_user_access = allow_user_access;
    this.allow_external_connect = allow_external_connect;
    this.allow_account_select = allow_account_select;
    this.cancelled_flag = cancelled_flag;
    this.client_key = client_key;
    this.company_logo = company_logo;
    this.company_name = company_name;
    this.company_product_key = company_product_key;
    this.external_company_reference = external_company_reference;
    this.last_session_date = last_session_date;
    this.owner_flag = owner_flag;
    this.pending_owner_flag = pending_owner_flag;
    this.pending_subscription_key = pending_subscription_key;
    this.pending_subscription_contact_name = pending_subscription_contact_name;
    this.pending_subscription_contact_email = pending_subscription_contact_email;
    this.product_key = product_key;
    this.product_name = product_name;
    this.product_value = ProductService.getProductValue(product_name);
    this.sample_company = sample_company;
    this.subscription_key = subscription_key;
    this.subscription_billing_name = subscription_billing_name;
    this.subscription_billing_email = subscription_billing_email;
    this.no_subscription_access_flag = no_subscription_access_flag;
    this.suspended_flag = suspended_flag;
    this.trial_days_remaining = trial_days_remaining;
    this.trial_expired_flag = trial_expired_flag;
    this.subscribed_flag = subscribed_flag;
    this.user_access_key = user_access_key;
    this.account_subscription = account_subscription;
    this.has_company_settings_access = has_company_settings_access;
    this.incomplete_onboarding_steps = incomplete_onboarding_steps;
    this.industry_key = industry_key;
    this.auto_promo_code = auto_promo_code;

    this.account_status = this._initAccountStatus();
    this.ownership_transfer_status = this._initOwnershipTransferStatus();

    this.updateAccountActions();
  }

  get has_default_account_logo(): boolean {
    return this._default_account_logos.indexOf(this.company_logo) !== -1;
  }

  private _initAccountStatus(): AccountStatus {

    if (this.sample_company) {
      return 'DEMO';
    }
    else if (this.cancelled_flag) {
      return 'CANCELLED';
    }
    else if (this.suspended_flag) {
      return 'SUSPENDED';
    }
    else if (this.subscribed_flag) {
      return 'SUBSCRIBED';
    }
    else if (this.trial_expired_flag) {
      return 'TRIAL_EXPIRED';
    }
    else if (this.trial_days_remaining !== null && this.trial_days_remaining >= 0) {
      return 'ON_TRIAL';
    }
    else {
      return 'UNSUBSCRIBED';
    }
  }

  private _initOwnershipTransferStatus(): AccountOwnershipTransferStatus {
    if (this.pending_owner_flag) {
      return 'PENDING_NEW_OWNER';
    }
    else if (this.owner_flag && !!this.pending_subscription_key) {
      return 'PENDING_OLD_OWNER';
    }
    return null;
  }

  updateAccountActions() {
    const actions: Set<AccountAction> = new Set();

    if (this.account_status !== 'DEMO') {
      if (this.ownership_transfer_status === null) {

        if (!!this.account_subscription) {

          if (this.account_subscription.show_subscribe_button) {
            actions.add('SUBSCRIBE');
          }
          if (this.account_subscription.can_change_plan) {
            actions.add('CHANGE_PLAN');
          }
          if (this.account_status !== 'CANCELLED' && this.account_status !== 'SUSPENDED') {
            actions.add('CANCEL_ACCOUNT');

            // Disallow transfers for Karmly for now
            if (this.product_value !== 'KARMLY') {
              actions.add('TRANSFER_START');
            }
          }
        }
      }
      else if (this.ownership_transfer_status === 'PENDING_NEW_OWNER') {
        actions.add('TRANSFER_RESPOND');
      }
      else if (this.ownership_transfer_status === 'PENDING_OLD_OWNER') {
        actions.add('TRANSFER_REVOKE');
      }
    }

    this.account_actions = actions;
  }
}
