import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { PostableObjectInterface, PostableObjectError } from './../../interfaces/postable-object.inerface';
import { IntegrationType, LoginIntegration, ProductValue } from './../../app.types';

import { env } from './../../../environments/environment';

declare const Sha1: any;


export class UserAccess implements PostableObjectInterface {

  contact_phone: string;
  full_name: string;
  login_integrations: Set<IntegrationType>;
  partner_flag: boolean;
  partner_owner_flag: boolean;
  partner_admin_flag: boolean;
  password_set: boolean;
  registered_email: string;
  subscription_flag: boolean;
  two_factor_enabled: boolean;
  two_factor_required: boolean;
  user_access_key: number;
  username: string;
  verification_email: string;
  first_name: string;
  intercom_settings: Intercom_.IntercomSettings;
  profile_picture: string;
  thumbnail_picture: string;
  droppah_candidate_flag: boolean;
  prompt_new_card_flag: boolean;

  constructor(
    contact_phone: string,
    full_name: string,
    login_integrations: Set<IntegrationType>,
    partner_flag: boolean,
    partner_owner_flag: boolean,
    partner_admin_flag: boolean,
    password_set: boolean,
    registered_email: string,
    subscription_flag: boolean,
    two_factor_enabled: boolean,
    two_factor_required: boolean,
    user_access_key: number,
    username: string,
    verification_email: string,
    intercom_settings: string,
    profile_picture: string,
    thumbnail_picture: string,
    droppah_candidate_flag: boolean,
    prompt_new_card_flag: boolean = false

  ) {
    this.contact_phone = contact_phone;
    this.full_name = full_name;
    this.login_integrations = login_integrations;
    this.partner_flag = partner_flag;
    this.partner_owner_flag = partner_owner_flag;
    this.partner_admin_flag = partner_admin_flag;
    this.password_set = password_set;
    this.registered_email = registered_email;
    this.subscription_flag = subscription_flag;
    this.two_factor_enabled = two_factor_enabled;
    this.two_factor_required = two_factor_required;
    this.user_access_key = user_access_key;
    this.username = username;
    this.verification_email = verification_email;
    this.first_name = full_name ? full_name.split(' ')[0] : null;
    this.intercom_settings = JSON.parse(intercom_settings);
    this.droppah_candidate_flag = droppah_candidate_flag;
    this.prompt_new_card_flag = prompt_new_card_flag;

    if (profile_picture) {
      this.profile_picture = env.subscription.blob_url + profile_picture;
    }
    if (thumbnail_picture) {
      this.thumbnail_picture = env.subscription.blob_url + thumbnail_picture;
    }
    if (this.intercom_settings) {
      this.intercom_settings.app_id = env.intercom_app_id;
    }
  }

  formatForPosting(product_value: ProductValue, old_password: string = null, new_password: string = null): any {
    if (!this.hasErrors()) {
      return {
        username: this.username,
        registered_email: this.registered_email,
        old_password: old_password ? Sha1.hash(old_password) : null,
        new_password: new_password ? Sha1.hash(new_password) : null,
        full_name: this.full_name,
        contact_phone: this.contact_phone,
        product_name: product_value,
      };
    }
    return null;
  }

  formatImageDataForPosting(imageData: any = null) {
    if (!this.hasErrors()) {
      return {
        thumbnail_container: imageData?.thumbContainer,
        profile_pic_container: imageData?.container,
        profile_pic_blob_UUID: imageData?.image_url,
        profile_pic_file_name: imageData?.file_name
      };
    }
    return null;
  }

  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  getErrors(): PostableObjectError[] {
    const errors: PostableObjectError[] = [];

    if (!this.registered_email) {
      errors.push({
        error_message: 'Login Email is required',
        error_path: 'registered_email'
      });
    }
    if (!CoreUtilService.validateEmailAddress(this.registered_email)) {
      errors.push({
        error_message: 'Login Email is invalid',
        error_path: 'registered_email'
      });
    }
    if (!this.full_name) {
      errors.push({
        error_message: 'Full Name is required',
        error_path: 'full_name'
      });
    }

    return errors;
  }

}
