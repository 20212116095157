<div class="ph-formItem">
  <div class="ph-formItem-label">
    {{inputLabel}}
    <div class="app-fieldOptional"
         *ngIf="!countryRequired">
      (optional)
    </div>
  </div>
  <div class="ph-formItem-value"
       [hidden]="readonly">
    <list-drop-down [itemNameProperty]="'country_name'"
                    [buttonColorClass]="'-color-white-outline'"
                    [buttonTypeClass]="'-type-dropdown'"
                    [buttonTitle]="value?.country_name || null"
                    [buttonTitlePlaceholder]="'Select Country'"
                    [searchBar]="true"
                    [listFillWidth]="true"
                    [items]="countries"
                    (itemSelected)="countryUpdated($event.item)">
    </list-drop-down>
  </div>
  <div class="ph-formItem-value"
       *ngIf="readonly">
    {{value?.country_name}}
  </div>
  <div class="ph-formItem-error"
       *ngIf="!customError && missing_country">
    {{inputLabel}} Required
  </div>
  <div class="ph-formItem-error"
       *ngIf="customError">
    {{customError}}
  </div>
</div>
