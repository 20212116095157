<div class="ph-formItem-container">
  <div class="ph-formItem">
    <div class="ph-formItem-label">
      Payment Method
    </div>
    <div class="ph-formItem-value">
      <span>Direct Debit</span>
    </div>
  </div>

  <div class="ph-formItem">
    <div class="ph-formItem-label">
      Bank Account Name
    </div>
    <div class="ph-formItem-value">
      <span>{{subscription.bank_account_name}}</span>
    </div>
  </div>

  <div class="ph-formItem">
    <div class="ph-formItem-label">
      Bank Account Number
    </div>
    <div class="ph-formItem-value">
      <span>{{subscription.bank_account_number}}</span>
    </div>
  </div>

  <div class="ph-formItem">
    <div class="ph-formItem-label">
      Payer Code
    </div>
    <div class="ph-formItem-value">
      <span>{{subscription.bank_code}}</span>
    </div>
  </div>

  <div class="app-buttonRow"
       *ngIf="!hide_button_row">
    <button class="-type-text -color-payhero-clear"
            *ngIf="!saving"
            (click)="editDetails.emit()">
      Edit Payment Method
    </button>
    <button class="-type-text -color-grey-clear"
            *ngIf="saving">
      Saving...
    </button>
  </div>
</div>
