import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Account } from 'src/app/models/account/account';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-cancel-account-modal',
  templateUrl: './cancel-account-modal.component.html',
  styleUrls: ['./cancel-account-modal.component.scss']
})
export class CancelAccountModalComponent implements OnInit {

  @Input() account: Account;
  @Input() isPartner: boolean;

  loading: boolean = false;
  feedbackState: boolean = false;

  cancellationReasons: Record<string, string>[] = [
    {
      title: 'Cost',
      value: 'PRICE'
    },
    {
      title: 'Missing features',
      value: 'FEATURES'
    },
    {
      title: 'No longer employing staff',
      value: 'RESTRUCTURE'
    },
    {
      title: 'Closed or sold business',
      value: 'CLOSED'
    },
    {
      title: 'Difficult to use or understand',
      value: 'DIFFICULTY'
    },
    {
      title: 'Service/support',
      value: 'SERVICE'
    },
    {
      title: 'Other',
      value: 'OTHER'
    }
  ];

  altSystems: Record<string, string>[] = [
    {
      title: 'Xero Payroll',
      value: 'XERO'
    },
    {
      title: 'iPayroll',
      value: 'IPAYROLL'
    },
    {
      title: 'Smartly',
      value: 'SMARTLY'
    },
    {
      title: 'Crystal Payroll',
      value: 'CRYSTAL'
    },
    {
      title: 'Paysauce',
      value: 'PAYSAUCE'
    },
    {
      title: 'Thankyou Payroll',
      value: 'THANKYOU'
    },
    {
      title: 'MYOB',
      value: 'MYOB'
    },
    {
      title: 'Employment Hero',
      value: 'EMPLOYMENTHERO'
    },
    {
      title: 'Other',
      value: 'OTHER'
    }
  ];

  selectedReason: Record<string, string>;
  selectedSystem: Record<string, string>;

  otherCancellationReason: string;
  otherSystem: string;
  feedbackComment: string;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Enter' && this.feedbackState) {
      this.sendFeedback();
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    // Add our partner specific option if applicable
    if (this.isPartner) {
      this.cancellationReasons.unshift({
        title: 'No longer working with client',
        value: 'EX CLIENT'
      });
    }

  }

  selectReason(reason) {
    this.selectedReason = reason;
  }

  selectSystem(system) {
    this.selectedSystem = system;
  }

  cancelAccount() {
    this.loading = true;

    this.accountService.cancelAccountSubscription(this.account.company_product_key, this.account.product_name)
      .then(() => {
        this.loading = false;
        this.feedbackState = true;
      })
      .catch((err) => {
        this.activeModal.dismiss(err);
      });
  }

  // Put together our result but post it from outside the modal so we can close it immediately
  sendFeedback() {
    // Don't post if no good answers provided
    if (!this.selectedReason && !this.selectedSystem &&  (this.feedbackComment === null || this.feedbackComment === '')) return this.activeModal.dismiss();

    let feedbackBody = {
      company_product_key: this.account.company_product_key,
      company_name: this.account.company_name,
      cancellation_reason: this.selectedReason?.value,
      cancellation_reason_other: this.selectedReason?.value === 'OTHER' ? this.otherCancellationReason : null,
      cancellation_system: this.selectedSystem?.value,
      cancellation_system_other: this.selectedSystem?.value === 'OTHER' ? this.otherSystem : null,
      feedback_comment: this.feedbackComment
    };

    this.activeModal.close(feedbackBody);
  }

  close() {
    this.activeModal.dismiss();
  }

}
