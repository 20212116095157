<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          <div>
            Rebates
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">


    </div>
  </div>
</div>
