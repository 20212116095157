<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          Payment Details
        </div>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <div class="subBilling-container">
        <div class="subBilling-details">

          <div class="ph-formItem-container -smallForm">
            <div class="ph-formItem-header">
              <span>Details</span>
              <spinner class="-spinnerFlex"
                       *ngIf="reloading_billing_details">
              </spinner>
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Billing Name
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !subscription.billing_name}">
                {{subscription.billing_name || '-'}}
              </div>
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Billing Email
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !subscription.billing_email}">
                {{subscription.billing_email || '-'}}
              </div>
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Billing Address
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !subscription.billing_address}">
                {{subscription.billing_address || '-'}}
              </div>
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Billing Country
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !subscription.country?.country_name}">
                {{subscription.country?.country_name || '-'}}
              </div>
            </div>

          </div>

          <div class="app-buttonRow">
            <button class="-type-text -color-payhero-clear"
                    (click)="editBillingDetails()">
              Edit
            </button>
          </div>
        </div>

        <div class="subBilling-method"
             *ngIf="subscription.payment_method !== 'INVOICE' && (
                    subscription.subscription_date ||
                    subscription.payment_method === 'DIRECTDEBIT' ||
                    (subscription.payment_method === 'CARD' && subscription.subscription_card))">

          <div class="ph-formItem-header">
            <span> Subscription Payments</span>
            <spinner class="-spinnerFlex"
                     *ngIf="reloading_payment_details">
            </spinner>
          </div>

          <sub-card-info *ngIf="subscription.payment_method === 'CARD' && subscription.subscription_card"
                         [subscriptioncard]="subscription.subscription_card">
          </sub-card-info>

          <div class="subBilling-method-empty"
               *ngIf="subscription.payment_method === 'CARD' && !subscription.subscription_card"
               (click)="updatePaymentMethod()">
            + Credit Card
          </div>

          <!-- <div class="form-btnRow"
               *ngIf="subscription.payment_method === 'CARD'">
            <button class="-type-text -color-grey-clear"
                    *ngIf="reloadingPayment">
              Saving...
            </button>

            <button class="-type-text -color-payhero-clear"
                    *ngIf="!reloadingPayment"
                    (click)="updatePaymentMethod()">
              Change Card
            </button>
          </div> -->

          <div class="ph-formItem-container -smallForm"
               *ngIf="subscription.payment_method === 'DIRECTDEBIT'">
            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Payment Method
              </div>
              <div class="ph-formItem-value">
                Direct Debit
              </div>
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Bank Account Name
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !subscription.bank_account_name}">
                {{subscription.bank_account_name || '-'}}
              </div>
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Bank Account Number
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !subscription.bank_account_number}">
                {{subscription.bank_account_number || '-'}}
              </div>
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Payer Code
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !subscription.bank_code}">
                {{subscription.bank_code || '-'}}
              </div>
            </div>
          </div>

          <div class="app-buttonRow">
            <button class="-type-text -color-payhero-clear"
                    (click)="updatePaymentMethod()">
              Edit
            </button>
          </div>
        </div>

        <div class="subBilling-revenueShare"
             *ngIf="partner?.receive_client_rebate_flag">

          <div class="ph-formItem-container -smallForm">
            <div class="ph-formItem-header">
              Revenue Share Payments
            </div>

            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Bank Account Name
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !partner.bank_account_name}">
                {{partner.bank_account_name || '-'}}
              </div>
            </div>
            <div class="ph-formItem">
              <div class="ph-formItem-label">
                Bank Account Number
              </div>
              <div class="ph-formItem-value"
                   [ngClass]="{'-empty': !partner.bank_account_number}">
                {{partner.bank_account_number || '-'}}
              </div>
            </div>
          </div>

          <div class="app-buttonRow">
            <button class="-type-text -color-payhero-clear"
                    (click)="editRevenueShareDetails()">
              Edit
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
