import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../../components/components.module';

import { TokenComponent } from './token.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SignUpComponent } from './signup/signup.component';
import { SignupIntegrationComponent } from './signup-integration/signup-integration.component';

export const TOKEN_CONFIG: any[] = [
  {
    name: 'token',
    url: '/token',
    component: TokenComponent,
    abstract: true
  },
  {
    name: 'token.passwordReset',
    url: '/passwordReset?token&email&product',
    component: PasswordResetComponent,
    params: {
      token: null,
      email: null,
      product: null
    }
  },
  {
    name: 'token.signUp',
    url: '/signup?token&product&marketing_additional_data',
    component: SignUpComponent,
    params: {
      token: null,
      product: null,
      marketing_additional_data: null,
      two_factor_token: null,
      password: null,
      full_name: null
    }
  },
  {
    name: 'token.signUpIntegration',
    url: '/signupIntegration?product&integration_type&country_key&referral_code&marketing_additional_data',
    component: SignupIntegrationComponent,
    params: {
      product: null,
      integration_type: null,
      country_key: null,
      referral_code: null,
      marketing_additional_data: null
    }
  }
];


@NgModule({
  declarations: [
    TokenComponent,
    PasswordResetComponent,
    SignUpComponent,
    SignupIntegrationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild( { states: TOKEN_CONFIG } )
  ]
})
export class TokenModule { }
