import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { Client } from 'src/app/models/client/client';
import { Account } from 'src/app/models/account/account';
import { ClientService } from 'src/app/services/client/client.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ProductService } from 'src/app/services/product/product.service';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { MegaSearchConfig } from 'src/app/components/mega-search/mega-search.component';
import { AccountService } from './../../../services/account/account.service';
import { FtAccount } from './../../../models/ft-account/ft-account';
import { SubscriptionAccountListComponent } from './../../../components/subscription-account-list/subscription-account-list.component';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { FtAccountService } from './../../../services/ft-account/ft-account.service';
import { DpListComponent } from './../../../components/dp-list/dp-list.component';
import { MegaSearchComponent } from './../../../components/mega-search/mega-search.component';
import { AccountFilter, DropdownOption } from './../../../app.types';

import { env } from './../../../../environments/environment';

import * as _ from 'lodash-es';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { StateAccessService } from 'src/app/services/state-access/state-access.service';

@Component({
  selector: 'app-client-dash',
  templateUrl: './client-dash.component.html',
  styleUrls: ['./client-dash.component.scss']
})
export class ClientDashComponent implements OnInit, OnDestroy {

  @ViewChild('megaSearch') megaSearch: MegaSearchComponent;
  @ViewChildren('accountList') accountLists: QueryList<SubscriptionAccountListComponent>;
  @ViewChild('ftAccountList') ftAccountList: DpListComponent;

  readonly has_partner_admin_access: boolean = this.userService.has_partner_admin_access;
  readonly is_partner_owner: boolean = this.stateAccessService.isPartnerOwner;
  readonly is_partner: boolean = this.stateAccessService.isPartner;

  // Client Dropdown Options ////////////
  clientDropdownOptions: DropdownOption[] = [
    {
      option_value: 'EXPAND_CLIENTS',
      option_name: 'Expand All Clients',
      option_hidden: false
    },
    {
      option_value: 'COLLAPSE_CLIENTS',
      option_name: 'Collapse All Clients',
      option_hidden: true
    }
  ];

  // Searching ////////////
  megaSearchId: string = 'ClientDashComponent';
  megaSearchConfigMap: Record<string, MegaSearchConfig> = {
    clients: {
      search_properties: [
        { label: 'Client', key: 'client_name', paths: ['client_name'] },
        { label: 'Email', key: 'contact_email', paths: ['contact_email'] },
        { label: 'Phone', key: 'contact_phone', paths: ['contact_phone'] }
      ],
      item_key_property: 'client_key',
      filtered_keys: new Set()
    },
    accounts: {
      search_properties: [
        { label: 'Account', key: 'account_name', paths: ['company_name'] }
      ],
      item_key_property: 'company_product_key',
      filtered_keys: new Set()
    },
    ftAccounts: {
      search_properties: [
        { label: 'Account', key: 'company_name', paths: ['company_name'] }
      ],
      item_key_property: 'company_code_hash',
      filtered_keys: new Set()
    }
  };

  clients: Client[] = [];
  accounts: Account[] = [];
  clientAccounts: Record<number, Account[]> = {};
  unlinkedPayHeroAccounts: Account[] = [];
  unlinkedDroppahAccounts: Account[] = [];
  unlinkedInvoxyAccounts: Account[] = [];
  unlinkedKarmlyAccounts: Account[] = [];
  ftAccounts: FtAccount[] = [];

  visibleClients: Client[] = [];
  clientAccountVisibility: Record<number, boolean> = {};
  unlinkedPayHeroAccountsVisible: boolean = false;
  unlinkedDroppahAccountsVisible: boolean = false;
  unlinkedInvoxyAccountsVisible: boolean = false;
  unlinkedKarmlyAccountsVisible: boolean = false;
  ftAccountsVisible: boolean = false;
  allClientsAndAccountsHiddenBySearch: boolean = false;

  searchVisibility = {
    clients: true,
    unlinkedPayHeroAccounts: true,
    unlinkedDroppahAccounts: true,
    unlinkedInvoxyAccounts: true,
    unlinkedKarmlyAccounts: true
  };

  private _ftAccountUpdateEvent: Subscription = null;
  private _accountUpdateEvent: Subscription = null;
  private _accountServiceDataUpdateEvent: Subscription = null;

  loading: boolean;

  clientIsVisible: (client: Client) => boolean = (client) => {
    if (this.megaSearchConfigMap.clients.filtered_keys.has(client.client_key)) {
      return true;
    }
    else {
      for (const account of this.clientAccounts[client.client_key]) {
        if (this.accountIsVisible(account)) {
          return true;
        }
      }
      return false;
    }
  }

  accountIsVisible: (account: Account) => boolean = (account) => {
    return this.megaSearchConfigMap.accounts.filtered_keys.has(account.company_product_key) ||
      this.megaSearchConfigMap.clients.filtered_keys.has(account.client_key);
  };

  ftAccountIsVisible: (ftAccount: FtAccount) => boolean = (ftAccount) => {
    return this.megaSearchConfigMap.ftAccounts.filtered_keys.has(ftAccount.company_code_hash);
  };

  constructor(
    public clientService: ClientService,
    public stateService: StateService,
    public productService: ProductService,
    public redirectService: RedirectService,
    public modalService: ModalService,
    public accountService: AccountService,
    public userService: UserService,
    public stateDataService: StateDataService,
    public ftAccountService: FtAccountService,
    public subscriptionService: SubscriptionService,
    public stateAccessService: StateAccessService
  ) { }

  ngOnInit(): void {
    this.loadPageData();
    this.initClientAccountVisibility();
    this.initEventListeners();
  }

  ngOnDestroy() {
    this.clearEventListeners();
  }

  initEventListeners(): void {
    this._ftAccountUpdateEvent = this.ftAccountService.getFtAccountUpdatedEvent().subscribe(() => {
      this.ftAccounts = this.ftAccountService.getAllFtAccounts();
    });

    // Go and get the list of accounts again from AccountService
    this._accountUpdateEvent = this.accountService.getAccountUpdatedEvent().subscribe(() => {
      this._initAccounts();
    });

    // Refresh the list of accounts if the api call is made again
    // Needed to remove suspension warnings on payment
    this._accountServiceDataUpdateEvent = this.accountService.getServiceDataUpdateEvent().subscribe(() => {
      this._initAccounts();
    })
  }

  clearEventListeners(): void {
    this._ftAccountUpdateEvent?.unsubscribe();
    this._ftAccountUpdateEvent = null;

    this._accountUpdateEvent?.unsubscribe();
    this._accountUpdateEvent = null;
  }

  megaSearchUpdated() {
    this.reloadVisibleClients();
    this.unlinkedPayHeroAccountsVisible = this._unlinkedPayHeroAccountsVisible();
    this.unlinkedDroppahAccountsVisible = this._unlinkedDroppahAccountsVisible();
    this.unlinkedInvoxyAccountsVisible = this._unlinkedInvoxyAccountsVisible();
    this.unlinkedKarmlyAccountsVisible = this._unlinkedKarmlyAccountsVisible();
    this.ftAccountsVisible = this._ftAccountsVisible();
    this.allClientsAndAccountsHiddenBySearch = this._allClientsAndAccountsHiddenBySearch();

    setTimeout(() => {
      const account_lists = this.accountLists.toArray();
      for (const account_list of account_lists) {
        account_list.reloadVisibleAccounts();
      }
      this.ftAccountList?.reloadVisibleItems();
      this.toggleClientAccountVisibilityOnSearch();
    });
  }

  reloadVisibleClients() {
    const visible_clients: Client[] = [];
    for (const client of this.clients) {
      if (this.clientIsVisible(client)) {
        visible_clients.push(client);
      }
    }
    this.visibleClients = visible_clients;
  }

  loadPageData() {
    this.clients = this.clientService.getClients(true).filter(c => !c.partner_flag);

    // Initialise accounts (Excluding FT)
    this._initAccounts();

    // Initialise FT accounts list
    this.ftAccounts = this.ftAccountService.getAllFtAccounts();
  }

  private _getClientAccounts(): Record<number, Account[]> {
    const client_accounts: Record<number, Account[]> = {};
    for (const client of this.clients) {
      client_accounts[client.client_key] = this.accountService.getAllAccounts(
        ['EXCLUDE_CANCELLED', 'EXCLUDE_DEMO'],
        client.client_key, null, null, true
      );
    }
    return client_accounts;
  }

  private _initAccounts() {
    const account_filters: AccountFilter[] = [
      'EXCLUDE_CANCELLED', 'EXCLUDE_DEMO', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS', 'EXCLUDE_PARTNER_ACCOUNTS'
    ];

    this.accounts = this.accountService.getAllAccounts(account_filters);
    this.clientAccounts = this._getClientAccounts();

    const unlinkedAccounts = this.accountService.getAllAccounts(
      account_filters.concat('UNLINKED_ONLY'), null, null, null, true
    );
    this.unlinkedPayHeroAccounts = unlinkedAccounts.filter((account) => {
      return account.product_value === 'PAYHERO';
    });
    this.unlinkedDroppahAccounts = unlinkedAccounts.filter((account) => {
      return account.product_value === 'DROPPAH';
    });
    this.unlinkedInvoxyAccounts = unlinkedAccounts.filter((account) => {
      return account.product_value === 'INVOXY';
    });
    this.unlinkedKarmlyAccounts = unlinkedAccounts.filter((account) => {
      return account.product_value === 'KARMLY';
    });
  }

  initClientAccountVisibility() {
    this.clientAccountVisibility = this.stateDataService.getCachedComponentSessionData(
      'ClientDashComponent',
      'clientAccountVisibility'
    );

    if (this.clientAccountVisibility === null) {
      const cav = {};
      for (const client of this.clients) {
        if (this.clientAccounts[client.client_key].length) {
          cav[client.client_key] = false;
        }
      }
      this.clientAccountVisibility = cav;
    }

    this._updateExpandCollapseDropdownVisibility();
  }

  addClient() {
    if (this.has_partner_admin_access) {
      this.modalService.formModal(
        'New Client',
        new Client(null, '', '', '', '', ''),
        [
          { property: 'client_name', label: 'Name', field_required: true },
          { property: 'contact_name', label: 'Contact Name' },
          { property: 'contact_phone', label: 'Contact Number' },
          { property: 'contact_email', label: 'Contact Email' },
          { property: 'client_address', label: 'Address', field_type: 'address' }
        ]
      )
        .then((client) => {
          if (!!client) {
            this.loading = true;

            this.clientService.saveClient(client)
              .then((savedClient) => {
                this.stateService.go('app.client.edit', {
                  client_key: savedClient.client_key
                });
              })
              .catch((err) => {
                this.loading = false;
              });
          }
        })
        .catch(() => { });
    }
  }

  selectClient(client: Client) {
    this.stateService.go('app.client.edit', {
      client_key: client.client_key
    });
  }

  accountSelect(account: Account) {
    if (!!account.suspended_flag && (!this.is_partner || !!this.is_partner_owner)) {
      this.subscriptionService.paySuspendedInvoicesModal()
        .then((payment_made_flag) => {
          if (payment_made_flag) {
            this.accountService.loadAccounts()
              .then(() => {
                this.loadPageData();
              });
          }
        })
        .catch(() => {});
    }
  }

  signIn(
    account: Account,
    external_state_name: string = null
  ) {
    if (account.allow_user_access) {
      this.loading = true;

      this.productService.startNewSession(account.company_product_key, account.product_value)
        .then((session) => {
          this.redirectService.redirectToProduct(session.login_source, true, external_state_name);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    }
  }

  goToAccountSettings(account: Account) {
    this.signIn(account, env[account.product_value.toLowerCase()].external_settings_state);
  }

  linkAccountToClient(account: Account, client_key: number) {
    if (this.has_partner_admin_access) {
      const client = this.clientService.getClient(client_key, true);

      if (!!client) {
        this.loading = true;

        this.accountService.addOrRemoveAccountFromClient(client, account)
          .finally(() => {
            this.loadPageData();
            this.loading = false;
          });
      }
    }
  }

  unlinkAccountFromClient(account: Account) {
    if (this.has_partner_admin_access) {
      this.loading = true;
      const client = this.clientService.getClient(account.client_key, true);

      this.accountService.addOrRemoveAccountFromClient(client, account, true)
        .finally(() => {
          this.loadPageData();
          this.loading = false;
        });
    }
  }

  migrateToPayHero(ftAccount: FtAccount) {
    this.ftAccountService.migrateToPayHero(ftAccount);
  }

  remigrateToPayHero(ftAccount: FtAccount) {
    // Need to cancel PH account before remigrating
    if (ftAccount.migrated_flag && !ftAccount.cancelled_flag) {
      this.modalService.errorModal(
        'You must first cancel your ' + ftAccount.company_name +
        ' PayHero account before upgrading from FlexiTime Payroll again'
      );
    }
    else {
      this.modalService.confirmModal('Custom', null, null, null, null, {
        icon: null,
        title: 'Upgrade to PayHero',
        question: '',
        warning: '',
        buttonTitle: 'Upgrade',
        buttonConfirmClass: '-color-payhero'
      })
        .then((result) => {
          if (result) {
            this.migrateToPayHero(ftAccount);
          }
        })
        .catch(() => { })
    }
  }

  clientDropdownOptionSelected(option: string) {
    switch (option) {
      case 'EXPAND_CLIENTS':
        this.expandAllClientAccountVisibility();
        break;
      case 'COLLAPSE_CLIENTS':
        this.collapseAllClientAccountVisibility();
        break;
    }
  }

  toggleClientAccountVisibility(client: Client) {
    if (this.clientAccounts[client.client_key]?.length) {
      this.clientAccountVisibility[client.client_key] = !this.clientAccountVisibility[client.client_key];
      this.stateDataService.cacheComponentSessionData(
        'ClientDashComponent',
        'clientAccountVisibility',
        this.clientAccountVisibility
      );
      this._updateExpandCollapseDropdownVisibility();
    }
  }

  expandAllClientAccountVisibility() {
    for (const client_key of Object.keys(this.clientAccountVisibility)) {
      this.clientAccountVisibility[client_key] = true;
    }
    this.stateDataService.cacheComponentSessionData(
      'ClientDashComponent',
      'clientAccountVisibility',
      this.clientAccountVisibility
    );
    this._updateExpandCollapseDropdownVisibility();
  }

  collapseAllClientAccountVisibility() {
    for (const client_key of Object.keys(this.clientAccountVisibility)) {
      this.clientAccountVisibility[client_key] = false;
    }
    this.stateDataService.cacheComponentSessionData(
      'ClientDashComponent',
      'clientAccountVisibility',
      this.clientAccountVisibility
    );
    this._updateExpandCollapseDropdownVisibility();
  }

  toggleClientAccountVisibilityOnSearch() {
    if (this.megaSearch.filterActive) {
      for (const client of this.visibleClients) {
        this.clientAccountVisibility[client.client_key] = true;
      }
    }
    this.stateDataService.cacheComponentSessionData(
      'ClientDashComponent',
      'clientAccountVisibility',
      this.clientAccountVisibility
    );
    this._updateExpandCollapseDropdownVisibility();
  }

  private _updateExpandCollapseDropdownVisibility() {
    if (this._allClientAccountsVisible()) {
      this.clientDropdownOptions[0].option_hidden = true;
      this.clientDropdownOptions[1].option_hidden = false;
    }
    else {
      this.clientDropdownOptions[0].option_hidden = false;
      this.clientDropdownOptions[1].option_hidden = true;
    }
    this.clientDropdownOptions = _.clone(this.clientDropdownOptions);
  }

  private _allClientAccountsVisible(): boolean {
    for (const client_key of Object.keys(this.clientAccountVisibility)) {
      if (this.clientAccountVisibility[client_key] !== true) {
        return false;
      }
    }
    return true;
  }

  private _unlinkedPayHeroAccountsVisible() {
    for (const account of this.unlinkedPayHeroAccounts) {
      if (this.accountIsVisible(account)) {
        return true;
      }
    }
    return false;
  }

  private _unlinkedDroppahAccountsVisible() {
    for (const account of this.unlinkedDroppahAccounts) {
      if (this.accountIsVisible(account)) {
        return true;
      }
    }
    return false;
  }

  private _unlinkedInvoxyAccountsVisible() {
    for (const account of this.unlinkedInvoxyAccounts) {
      if (this.accountIsVisible(account)) {
        return true;
      }
    }
    return false;
  }

  private _unlinkedKarmlyAccountsVisible() {
    for (const account of this.unlinkedKarmlyAccounts) {
      if (this.accountIsVisible(account)) {
        return true;
      }
    }
    return false;
  }

  private _ftAccountsVisible() {
    for (const ftAccount of this.ftAccounts) {
      if (this.ftAccountIsVisible(ftAccount)) {
        return true;
      }
    }
    return false;
  }

  private _allClientsAndAccountsHiddenBySearch() {
    return (
      !!this.clients.length ||
      !!this.unlinkedPayHeroAccounts.length ||
      !!this.unlinkedDroppahAccounts.length ||
      !!this.unlinkedInvoxyAccounts.length ||
      !!this.unlinkedKarmlyAccounts.length ||
      !!this.ftAccounts.length
    ) &&
      !this.visibleClients.length &&
      !this.unlinkedPayHeroAccountsVisible &&
      !this.unlinkedDroppahAccountsVisible &&
      !this.unlinkedInvoxyAccountsVisible &&
      !this.unlinkedKarmlyAccountsVisible &&
      !this.ftAccountsVisible;
  }

}
