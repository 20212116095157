import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  @Input() message: string;
  @Input() htmlMessage: string;

  constructor(private activeModal: NgbActiveModal){
    
  }

  ngOnInit(){
    
  }

  ok(){
    this.activeModal.dismiss();
  }

}
