import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../../components/components.module';
import { AppStateDeclaration } from './../../app.types';

import { ProfileComponent } from './profile.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';


export const PROFILE_CONFIG: AppStateDeclaration[] = [
  {
    name: 'app.profile',
    url: '/profile',
    component: ProfileComponent,
    abstract: true
  },
  {
    name: 'app.profile.edit',
    url: '/edit',
    component: ProfileEditComponent,
  }
];

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    UIRouterModule.forChild( { states: PROFILE_CONFIG } )
  ]
})
export class ProfileModule { }
