import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product/product.service';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

@Component({
  selector: 'app-token-droppah',
  templateUrl: './token-droppah.component.html',
  styleUrls: ['./token-droppah.component.scss']
})
export class TokenDroppahComponent implements OnInit {

  constructor(
    public productService: ProductService,
    public uiRouterGlobals: UIRouterGlobals,
  ) { }

  ngOnInit(): void {
    this.productService.current_product = 'DROPPAH';
  }
}
