import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'month-grid',
  templateUrl: './month-grid.component.html',
  styleUrls: ['./month-grid.component.scss']
})
export class MonthGridComponent {

  @Input() month: any = [];

  @Output() dateClicked = new EventEmitter<any>();
  daysOfTheWeek: string[]=[
    'M','T','W','T','F','S','S'
]

  constructor() { }


  // clickDay(day: any) {
  //   if (day.status !== 'hide-day') {
  //     this.dateClicked.emit(day);
  //   }
  // }

}
