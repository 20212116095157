<spinner></spinner>

<footer class="app-footer">
  <div class="app-footer-fromFlexitime"
        *ngIf="current_product !== 'DROPPAH'">
    <img src="../../../../assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg" />
  </div>

  <div class="app-footer-fromFlexitime"
        *ngIf="current_product === 'DROPPAH'">
    <img src="../../../../assets/product-imgs/flexitime/FlexiTime_WFM_Full_White.svg" />
  </div>
</footer>
