<div class="ph-modal-content">
  <div class="ph-modal-header">
    <i class="ion ion-ios-warning message-icon"></i>
  </div>

  <div class="ph-modal-container">
    <h5 *ngIf="message">
      {{message}}
    </h5>
    <h5 *ngIf="htmlMessage"
        [innerHTML]="htmlMessage">
    </h5>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-success ph-modal-btnCenter"
            (click)="ok()">
      Ok
    </button>
  </div>
</div>
