import { SubscriptionLine } from './../subscription-line/subscription-line';
import { PaymentMethod, Country } from './../../app.types';
import { SubscriptionPlan } from './../subscription-plan/subscription-plan';
import { PostableObjectInterface, PostableObjectError } from './../../interfaces/postable-object.inerface';

import { SubscriptionCard } from '../subscription-card/subscription-card';
import { SubscriptionInvoice } from '../subscription-invoice/subscription-invoice';
import { AccountSubscription } from './../account-subscription/account-subscription';

import { TimeUtilService } from './../../services/time-util/time-util.service';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';

export class FTSubscription implements PostableObjectInterface {

  bank_account_name: string;
  bank_account_number: string;
  bank_code: string;
  bank_name: string;
  billing_email: string;
  billing_name: string;
  billing_address: string;
  cancelled_date: Date;
  account_subscriptions: AccountSubscription[];
  subscription_plans: SubscriptionPlan[];
  country: Country;
  discount_description: string;
  discount_percentage: number;
  discount_until_date: Date;
  first_invoice_date: Date;
  next_invoice_date: Date;
  last_invoice_date: Date;
  last_payment_date: Date;
  money_symbol: string;
  billing_tax_type: string;
  monthly_credit: number;
  credit_description: string;
  credit_amount: number;
  monthly_discount: number;
  monthly_net: number;
  monthly_tax: number;
  has_no_payment_method: boolean;
  payment_method: PaymentMethod;
  payments: SubscriptionInvoice[];
  due_payment: SubscriptionInvoice;
  subscription_card: SubscriptionCard;
  subscription_date: Date;
  subscription_key: number;
  suspension_date: Date;
  total_monthly_cost: number;

  constructor(
    bank_account_name: string = null,
    bank_account_number: string = null,
    bank_code: string = null,
    bank_name: string = null,
    billing_email: string = null,
    billing_name: string = null,
    billing_address: string = null,
    cancelled_date: string = null,
    account_subscriptions: AccountSubscription[] = [],
    subscription_plans: SubscriptionPlan[],
    country: Country = null,
    discount_description: string = null,
    discount_percentage: number = null,
    discount_until_date: string = null,
    first_invoice_date: string = null,
    next_invoice_date: string = null,
    last_invoice_date: string = null,
    last_payment_date: string = null,
    money_symbol: string = null,
    billing_tax_type: string = 'No Tax',
    monthly_credit: number = 0,
    credit_description: string = null,
    credit_amount: number = 0,
    monthly_discount: number = 0,
    monthly_net: number = 0,
    monthly_tax: number = 0,
    has_no_payment_method: boolean = false,
    payment_method: PaymentMethod = null,
    payments: SubscriptionInvoice[] = [],
    subscription_card: SubscriptionCard = null,
    subscription_date: string = null,
    subscription_key: number = null,
    suspension_date: string = null,
    total_monthly_cost: number = null
  ) {
    this.bank_account_name = bank_account_name;
    this.bank_account_number = bank_account_number;
    this.bank_code = bank_code;
    this.bank_name = bank_name;
    this.billing_email = billing_email;
    this.billing_name = billing_name;
    this.billing_address = billing_address;
    this.cancelled_date = TimeUtilService.dateTimeStringToDate(cancelled_date);
    this.account_subscriptions = account_subscriptions;
    this.subscription_plans = subscription_plans;
    this.country = country;
    this.discount_description = discount_description;
    this.discount_percentage = discount_percentage;
    this.discount_until_date = TimeUtilService.dateTimeStringToDate(discount_until_date);
    this.first_invoice_date = TimeUtilService.dateTimeStringToDate(first_invoice_date);
    this.next_invoice_date = TimeUtilService.dateTimeStringToDate(next_invoice_date);
    this.last_invoice_date = TimeUtilService.dateTimeStringToDate(last_invoice_date);
    this.last_payment_date = TimeUtilService.dateTimeStringToDate(last_payment_date);
    this.money_symbol = money_symbol;
    this.billing_tax_type = billing_tax_type;
    this.monthly_credit = monthly_credit;
    this.credit_description = credit_description;
    this.credit_amount = credit_amount;
    this.monthly_discount = monthly_discount;
    this.monthly_net = monthly_net;
    this.monthly_tax = monthly_tax;
    this.has_no_payment_method = has_no_payment_method;
    this.payment_method = payment_method;
    this.payments = payments;
    this.subscription_card = subscription_card;
    this.subscription_date = TimeUtilService.dateTimeStringToDate(subscription_date);
    this.subscription_key = subscription_key;
    this.suspension_date = TimeUtilService.dateTimeStringToDate(suspension_date);
    this.total_monthly_cost = total_monthly_cost;

    this.initDuePayment();
  }

  initDuePayment() {
    for (const invoice of this.payments) {
      if (invoice.payment_date === null) {
        this.due_payment = invoice;
        return;
      }
    }
  }

  formatForPosting(): any {
    if (!this.hasErrors()) {
      return {
        bank_account_name: this.bank_account_name,
        bank_account_number: this.bank_account_number,
        bank_code: this.bank_code,
        billing_email: this.billing_email,
        billing_name: this.billing_name,
        billing_address: this.billing_address,
        bank_name: this.bank_name,
        country_key: this.country.country_key
      };
    }
    return null;
  }

  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  getErrors(): PostableObjectError[] {
    const errors: PostableObjectError[] = [];

    if (!this.billing_name.length) {
      errors.push({
        error_path: 'billing_name',
        error_message: 'Please enter a valid billing name'
      });
    }

    if (!this.billing_email.length || !CoreUtilService.validateEmailAddress(this.billing_email)) {
      errors.push({
        error_path: 'billing_email',
        error_message: 'Please enter a valid billing email'
      });
    }

    return errors;
  }
}
