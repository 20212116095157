import { Subscription } from 'rxjs';
import { IntegrationSocketEventType, LoginIntegration, ProductName } from './../../../app.types';
import { DomService } from 'src/app/services/dom/dom.service';
import { BlobService } from 'src/app/services/blob/blob.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { orderBy, cloneDeep } from 'lodash-es';

import { UserService } from 'src/app/services/user/user.service';
import { BannerType } from '../../../app.types';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AccountService } from 'src/app/services/account/account.service';
import { UserAccess } from '../../../models/user-access/user-access';
import { IntegrationService } from 'src/app/services/integration/integration.service';
import { ProductService } from 'src/app/services/product/product.service';
import { StateDataService } from 'src/app/services/state-data/state-data.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit, OnDestroy {

  loading: boolean = false;

  currentUser: UserAccess = null;

  newFile: File = null;
  usernameSet: boolean = false;

  // Used to tell the API which Xero App to use for connecting integration
  xeroConnectionProduct: ProductName = null;

  loginIntegrations: LoginIntegration[] = IntegrationService.login_integrations;

  private _integration_events: Partial<Record<IntegrationSocketEventType, Subscription>> = {
    'Integration_Connection_Complete': null,
    'Integration_Connection_Exists': null
  };

  errors: Record<string, string> = {};

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private authService: AuthService,
    private accountService: AccountService,
    private productService: ProductService,
    private stateService: StateService,
    private stateDataService: StateDataService,
    public domService: DomService,
    public blobService: BlobService
  ) { }

  ngOnInit() {
    this.initEventListeners();
    this._initXeroIntegration();
    this._getUserAccessDetails();
  }

  ngOnDestroy() {
    this.clearEventListeners();
  }

  initEventListeners(): void {
    this._integration_events.Integration_Connection_Complete =
      this.userService.getIntegrationEvent('Integration_Connection_Complete').subscribe((event_data: any) => {
        let message;

        if (event_data && event_data.integration_name) {
          message = 'Connected to ' + event_data.integration_name;
        }
        else {
          message = 'Connection Successful';
        }

        this._loadUserAccessDetails(message);
      });
    this._integration_events.Integration_Connection_Exists =
      this.userService.getIntegrationEvent('Integration_Connection_Exists').subscribe((event_data: any) => {
        let integration: string;

        if (event_data && event_data.integration_name) integration = event_data.integration_name;

        this.showBanner(this.userService.getIntegrationConnectionExistsMessage(integration), 'ERROR');
      });
  }

  clearEventListeners(): void {
    this._integration_events.Integration_Connection_Complete?.unsubscribe();
    this._integration_events.Integration_Connection_Complete = null;

    this._integration_events.Integration_Connection_Exists?.unsubscribe();
    this._integration_events.Integration_Connection_Exists = null;
  }

  setUsername() {
    this.usernameSet = true;
    setTimeout(() => {
      document.getElementById('UsernameInput').focus();
    }, 50);
  }

  editProfileDetails() {

    this.modalService.userAccessModal(cloneDeep(this.currentUser))
      .then((current_user) => {
        // this._postBlob(current_user.file)
        //   .then((result) => {
        //     this._updateImage(result, current_user)
        //       .then(() => {
        this.loading = true;

        // if (current_user.file) delete current_user.file;

        this.userService.updateUserAccessDetails(current_user)
          .then((message) => {
            this._getUserAccessDetails(message);
          })
          .catch((err) => {
            this._getUserAccessDetails(err?.message || null, 'ERROR');
          });

        // })
        // });
      })
      .catch(() => this._getUserAccessDetails());
  }


  private _postBlob(file: File): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!file) return resolve(null);
      this.loading = true;
      this.blobService.uploadImage(file.name, file, 'FLEXITIME', 450)
        .then((result) => {
          resolve(result);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  private _updateImage(result: any, current_user: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!result && !current_user?.removeImage) return resolve(null);
      this.loading = true;
      this.userService.updateUserAccessImage(current_user, result)
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }


  changePassword() {
    this.modalService.changePasswordModal()
      .then((result) => {
        this.loading = true;
        this.userService.updateUserAccessDetails(this.currentUser, result.new_password, result.old_password)
          .then((message) => {
            this.showBanner('Password successfully updated', 'SUCCESS', true);
            this._getUserAccessDetails(message);
          })
          .catch((err) => {
            this._getUserAccessDetails(err?.message || null, 'ERROR');
          });
      })
      .catch(() => { });
  }

  showBanner(message: string, type: BannerType, autoClose: boolean = false) {
    this.domService.showBanner({
      message,
      type,
      auto_hide_timer: autoClose ? 4000 : null
    });
  }

  hideBanner() {
    this.domService.hideBanner();
  }

  updateIntegration(integration_name: string, connected: boolean) {

    let disconnectFunction: any;
    let connectFunction: any;

    switch (integration_name) {
      case 'Google':
        disconnectFunction = () => this.userService.googleDisconnect();
        connectFunction = () => this.userService.googleConnect();
        break;

      case 'Xero':
        disconnectFunction = () => this.userService.xeroDisconnect();
        connectFunction = () => this.userService.xeroConnect(this.xeroConnectionProduct);
    }

    if (connected) {
      if (!this.currentUser.password_set) {
        this.showBanner('A password must be set before you can disconnect from ' + integration_name + '.', 'ERROR');
        return;
      }

      this.modalService.confirmModal('Disconnect', '', integration_name, '', '')
        .then(() => {
          disconnectFunction()
            .then(() => {
              this._getUserAccessDetails('Disconnected from ' + integration_name + '.');
            })
            .catch((err) => {
              this._getUserAccessDetails(err?.message || null, 'ERROR');
            });
        })
        .catch(() => { });
    }
    else {
      const from_native = this.stateDataService.getCachedGlobalLocalData('from_native');
      let tab = null;
      if (!from_native) {
        tab = this.userService.openIntegrationWindow();
      }
      connectFunction()
        .then((URL) => {
          const messageObj = { url: URL };
          const stringifiedMessageObj = JSON.stringify(messageObj);
          try {
            if ((window as any).webkit && (window as any).webkit.messageHandlers) {
              (window as any).webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);
            }
          } catch (e) { }
          if (from_native !== 'true') {
            tab.location.href = URL;
          }
        })
        .catch((err) => {
          tab.close();
          this.showBanner(err.message, 'ERROR');
        });
    }
  }

  twoFactorAuthentication() {
    if (this.currentUser.two_factor_enabled) {

      const customData = {
        icon: '',
        title: 'Disable Two Factor Authentication',
        question: 'Are you sure want to disable Two Factor Authentication for your Profile?',
        // warning: 'You will no longer be asked for your verification code when you log in.',
        buttonTitle: 'Disable',
        buttonConfirmClass: 'btn-danger'
      };

      this.modalService.confirmModal('Custom', null, null, null, null, customData)
        .then(() => {
          this.userService.disableTwoFactor()
            .then(() => {
              this._getUserAccessDetails(
                'Successfully disabled Two Factor Authentication. You will no longer be asked for your verification code when you sign in.'
              );
            })
            .catch((err) => {
              this._getUserAccessDetails(err?.message || null, 'ERROR');
            });
        })
        .catch(() => { });
    }
    else {
      this.modalService.initialiseTwoFactorModal()
        .then(() => {
          this._loadUserAccessDetails('Successfully set up Two Factor Authentication. You\'ll be asked for your verification code next time you sign in.');
        })
        .catch((err) => {
          this._getUserAccessDetails(err?.message || null, 'ERROR');
        });
    }
  }

  private _initXeroIntegration() {
    let product: ProductName = this.productService.current_product_name;

    if (!product || product === 'FlexiTime') {
      // We're looking to grab the top 1 account's product name, with preference for non-cancelled non-demo user-owned accounts.
      const orderedAccounts = orderBy(this.accountService.getAllAccounts(), ['cancelled_flag', 'sample_company', 'owner_flag'], ['asc', 'asc', 'desc']);

      product = orderedAccounts.length ? orderedAccounts[0].product_name : null;
    }

    // Defaulting to PayHero as it's most likely. Not a big deal if the user doesn't know what PayHero is.
    this.xeroConnectionProduct = product || 'PayHero';

  }

  private _getUserAccessDetails(completion_message: string = null, banner_type: BannerType = 'SUCCESS') {
    this.currentUser = this.userService.user_access;
    this.usernameSet = this.currentUser.registered_email !== this.currentUser.username;
    this.loading = false;

    if (!!completion_message) {
      this.showBanner(completion_message, banner_type, true);
    }
  }

  private _loadUserAccessDetails(completion_message: string = null, banner_type: BannerType = 'SUCCESS') {
    this.userService.loadUserAccessDetails()
      .then(() => {
        this.currentUser = this.userService.user_access;
        this.usernameSet = this.currentUser.registered_email !== this.currentUser.username;

        if (!!completion_message) {
          this.showBanner(completion_message, banner_type, true);
        }
        this.loading = false;
      })
      .catch(() => {
        this.authService.logout();
      });
  }

}
