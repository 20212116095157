import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { CoreUtilService } from '../../services/core-util/core-util.service';
@Component({
  selector: 'user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserIconComponent implements OnInit, OnChanges {

  @HostBinding('style.background-color') background_color: string = '';
  @HostBinding('style.color') @Input() text_color: string = '';

  @Input() display_name: string = null;
  @Input() display_image: string = null;
  @Input() invite_pending: boolean = false;
  @Input() active_flag: boolean = false;
  @Input() placeholder_color: string = 'var(--theme-color-accent)';

  @Input() randomise_placeholder_color: boolean = false;

  @Output() click = new EventEmitter();

  initials: string = null;
  img_error: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this._initInitials();
    this._initPlaceholderColour();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.placeholder_color || !!changes.display_image) {
      this._updateBackgroundImage();
    }
  }

  private _updateBackgroundImage() {
    this.background_color = '';

    if (!this.display_image && !!this.placeholder_color) {
      this.background_color = this.placeholder_color;
    }
  }

  private _initInitials() {
    const initials = this.display_name?.split(' ') || null;

    if (!!initials) {
      this.initials = initials[0][0] + (initials[1] ? initials[1][0] : '');
    }
  }

  private _initPlaceholderColour() {
    if (this.randomise_placeholder_color) {
      this.placeholder_color = '#004763';
    }
  }

  onError() {
    this.img_error = true;
  }

}
