import { Component, Input, Output, EventEmitter } from '@angular/core';

import { FormInputField } from './../../app.types';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent {

  @Input() formField: FormInputField;
  @Input() formFieldError: string = null;
  @Input() formObject: any;

  @Output() clearFormFieldError = new EventEmitter<{ property: string }>();

}
