<div class="ph-modal-content">
    <div class="ph-modal-header">
        <img class="image-icon"
            *ngIf="product_value !== 'DROPPAH'"
            src="assets/imgs/App_Success.svg">
        <img class="image-icon"
            *ngIf="product_value === 'DROPPAH'"
            src="assets/imgs/App_Success_Purple.svg">
        <div class="headerCenter">
        {{header}}
        </div>
    </div>

    <div class="ph-modal-container">
        <h5 *ngIf="message">
            {{message}}
        </h5>
        <h5 *ngIf="htmlMessage"
            [innerHTML]="htmlMessage">
        </h5>
    </div>

    <div class="ph-modal-btnFooter">
        <button class="-color-success ph-modal-btnCenter"
                (click)="ok()">
            Ok
        </button>
    </div>
</div>
