import { Component, Input } from '@angular/core';

import { CoreUtilService } from '../../services/core-util/core-util.service';

@Component({
  selector: 'app-footer-user-agreements',
  templateUrl: './app-footer-user-agreements.component.html',
  styleUrls: ['./app-footer-user-agreements.component.scss']
})
export class AppFooterUserAgreementsComponent {

  @Input() support_centre_link: string = null;

  readonly flexitime_terms_of_use_url = CoreUtilService.flexitime_terms_of_use_url;
  readonly flexitime_privacy_policy_url = CoreUtilService.flexitime_privacy_policy_url;

}
