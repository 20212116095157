import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import * as _ from 'lodash-es';
import { ModalService } from '../../../services/modal/modal.service';
import { TokenDroppahService } from '.././../../services/token-droppah/token-droppah.service';

@Component({
  selector: 'app-leave-request',
  templateUrl: './leave-request.component.html',
  styleUrls: ['./leave-request.component.scss']
})
export class LeaveRequestComponent implements OnInit {

  token_uid: string = null;
  loading: boolean = false;
  leave_request: any = null;
  token_expired: boolean = false;
  message: string = '';

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public tokenDroppahService: TokenDroppahService,
    public stateService: StateService,
    public modalService: ModalService
  ) { }

  ngOnInit() {
    window.document.title = 'Leave Request | Droppah';

    this.token_uid = this.uiRouterGlobals.params.token || null;

    if (this.token_uid) {
      this.getLeave();
    }
    else {
      this.token_expired = true;
    }
  }

  private getLeave() {
    this.loading = true;

    this.tokenDroppahService.getLeave(this.token_uid)
      .then((leave_request) => {
        this.leave_request = leave_request;

        if (!this.leave_request) {
          this.token_expired = true;
        }
        this.loading = false;
      })
      .catch(() => {
        this.token_expired = true;
        this.loading = false;
      });
  }

  acceptRequest() {
    this.loading = true;
    this.tokenDroppahService.postLeave(this.token_uid, true, false, this.message)
      .finally(() => window.location.reload());
  }

  declineRequest() {
    this.loading = true;
    this.tokenDroppahService.postLeave(this.token_uid, false, true, this.message)
      .finally(() => window.location.reload());
  }
}
