<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerLeft">
      Import Employees
    </div>
    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div>
    <h4 class="stepTitle">Step 1:</h4>
    <div class="stepDescription -bold-link">
      <a href="https://support.payhero.co.nz/hc/en-us/article_attachments/7642201058191">
        <i class="bi-cloud-download icon"></i>
        Download Employee Import Spreadsheet
      </a>
    </div>

    <h4 class="stepTitle">Step 2:</h4>
    <div class="stepDescription">Follow our <a href="https://support.payhero.co.nz/hc/en-us/articles/360002666496-Employee-Import" target="_blank">employee import guide</a> and add your employees' personal, employment & payroll details to the spreadsheet.</div>

    <h4 class="stepTitle">Step 3:</h4>
    <div class="stepDescription">Send your completed spreadsheet to <a href="mailto:support@payhero.co.nz">support@payhero.co.nz</a>. Our team will review the spreadsheet and get back to you.</div>
  </div>

  <div class="ph-modal-btnFooter -bold-link">
    <a href="https://support.payhero.co.nz/hc/en-us/articles/360002666496-Employee-Import" target="_blank">I need help <i class="bi-box-arrow-up-right"></i></a>
    <button class="ph-modal-btnRight -color-white-outline icon"
            (click)="close()">
        Close
    </button>
  </div>

</div>
