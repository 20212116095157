<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      <span>Manage Role</span>
      {{full_name}}
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div class="ph-modal-container">
    <staff-role-picker [(adminFlag)]="admin_flag"></staff-role-picker>
  </div>

  <div class="ph-modal-btnFooter">
      <button class="-color-white-outline ph-modal-btnRight"
              (click)="close()">
          Cancel
      </button>
      <button class="-color-success ph-modal-btnRight"
              (click)="save()">
          Save
      </button>
  </div>
</div>
