import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionCard } from 'src/app/models/subscription-card/subscription-card';

@Component({
  selector: 'sub-card-info',
  templateUrl: './sub-card-info.component.html',
  styleUrls: ['./sub-card-info.component.scss']
})
export class SubCardInfoComponent implements OnInit {

  @Input() subscriptioncard: SubscriptionCard;
  @Input() loading: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
