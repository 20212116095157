<div class="dp-list-item -header"
     *ngIf="!alternateDesign && visibleItems.length !== 0 && tableHeaders?.length">
  <div class="dp-list-itemCell"
       *ngFor="let column of tableHeaders; let i = index"
       [ngClass]="{ '-desktop-only': column.desktop_only === true, '-noSort': !column.property || !!sortDisabled }"
       (click)="orderItems(column.property)">

    <i class="ion"
       *ngIf="primarySortProperty === column.property && !sortDisabled"
       [ngClass]="{
       'ion-md-arrow-dropdown': forwardOrder,
       'ion-md-arrow-dropup': !forwardOrder }">
    </i>
    <span>
      {{column.label}}
    </span>
  </div>
  <div class="dp-list-itemCell -rowDelete"
       *ngIf="canDeleteItems">
  </div>
</div>

<div class="dp-list-scrollOuter"
     *ngIf="visibleItems.length !== 0"
     [ngClass]="{ '-alternateDesign': alternateDesign }"
     [id]="'dp-list-scrollOuter_' + listId"
     infinite-scroll
     [infiniteScrollContainer]="'#dp-list-scrollOuter_' + listId"
     [infiniteScrollDistance]="1"
     [fromRoot]="true"
     (scrolled)="loadMoreVisibleItems()">
  <div class="dp-list-scrollInner">

    <div class="dp-list-item"
         *ngFor="let item of visibleItems; let i = index"
         [ngClass]="{
           '-itemSelectDisabled': itemSelectDisabled || (itemSelectEnabledProperty && !item[itemSelectEnabledProperty])
          }"
         (click)="selectItem(item)">
      <ng-container *ngTemplateOutlet="dpListItemTemplate; context:{ $implicit: item, index: i }">
      </ng-container>
      <div class="dp-list-itemCell -rowDelete"
           *ngIf="canDeleteItems"
           [ngClass]="{ '-disabled': itemDeleteDisabled }"
           (click)="deleteItem($event, item)">
        <i class="ion ion-md-close"
           [ngStyle]="{ 'display': itemDeleteHiddenProperty && item[itemDeleteHiddenProperty] ? 'none': '' }">
        </i>
      </div>
      <div class="dp-list-itemCell -rowAdd"
           *ngIf="canAddItems"
           [ngClass]="{ '-disabled': itemAddDisabled }"
           (click)="addItem($event, item)">
        <i class="ion ion-md-add"></i>
      </div>
    </div>
  </div>
</div>

<div class="dp-list-empty"
     *ngIf="!loading && items.length !== 0 && visibleItems.length === 0">
  <div class="dp-list-emptyHeader"
       [hidden]="emptyDescriptionOnly">
    No <span>{{itemTypePlural}}</span> Found
  </div>
  <div class="dp-list-emptyDescription">
    Couldn't find any {{itemTypePlural}} for your current search
  </div>
</div>

<div class="dp-list-empty"
     *ngIf="!loading && items.length === 0">
  <div class="dp-list-emptyHeader"
       [hidden]="emptyDescriptionOnly">
    No <span>{{itemTypePlural}}</span>
  </div>
  <div class="dp-list-emptyDescription">
    {{ noItemsMessage || 'You don\'t currently have any ' + itemTypePlural + ' set up'}}
  </div>
</div>
