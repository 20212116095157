import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { FileReaderDirective } from './file-reader/file-reader.directive';


@NgModule({
  declarations: [
  FileReaderDirective,

  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileReaderDirective,
  ]
})
export class DirectivesModule { }
