import { Component, OnInit, Input, Output, EventEmitter, ComponentRef, ViewChild, ElementRef, DoCheck, OnDestroy } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { CoreUtilService } from './../../services/core-util/core-util.service';
import { MegaSearchConfig, MegaSearchComponent } from './../mega-search/mega-search.component';
import { SortUtilService } from './../../services/sort-util/sort-util.service';
import { BackdropComponent } from './../backdrop/backdrop.component';
import { DomService } from './../../services/dom/dom.service';
import { InfiniteParams } from './../../app.types';

import * as $ from 'jquery';
import * as _ from 'lodash-es';

@Component({
  selector: 'multi-select-drop-down',
  templateUrl: './multi-select-drop-down.component.html',
  styleUrls: ['./multi-select-drop-down.component.scss']
})
export class MultiSelectDropDownComponent implements OnInit, OnDestroy {

  @ViewChild(MegaSearchComponent) megaSearchComponent: MegaSearchComponent;
  @ViewChild(NgbDropdown) msddDropDown: NgbDropdown;

  // Item properties
  @Input() itemNameProperty: string;
  @Input() itemKeyProperty: string;
  @Input() itemDescriptionProperty: string = null;
  @Input() itemColorProperty: string = null;
  @Input() itemFontColorProperty: string = null;
  @Input() itemSortProperty: string = null;

  // Button modifier classes
  // Check styles/buttons.scss for class options
  @Input() buttonTypeClass: string = '';
  @Input() buttonColorClass: string = '';
  @Input() buttonIconClass: string = null;
  @Input() buttonIconAlignLeft: boolean = false;

  // Button options
  @Input() buttonTitle: string = null;
  @Input() buttonHeight: string = null;
  @Input() buttonFontSize: string = null;
  @Input() buttonIconSize: string = null;
  @Input() buttonTitleUppercase: boolean = false;

  // List options
  @Input() listWidth: string = null;
  @Input() listHeight: string = '350px';
  @Input() listFillWidth: boolean = false;
  @Input() listAutoClose: boolean = true;
  @Input() listDisabled: boolean = false;

  // Messages
  @Input() searchPlaceholderMessage: string = 'Search...';
  @Input() searchEmptyMessage: string = 'No Items Found';
  @Input() itemsEmptyMessage: string = 'No Items Available';
  @Input() itemsSelectMessage: string = 'Add selected';

  // Misc
  @Input() sortItems: boolean = true;
  @Input() disableBodyAppend: boolean = false;
  @Input() singularSelect: boolean = false;

  @Output() itemsSelected = new EventEmitter();

  private _items: any[] = [];
  @Input()
  set items(items: any[]) {
    this._items = items;

    if (!!this.sortItems) {
      const sortProperty = this.itemSortProperty || this.itemNameProperty || null;
      SortUtilService.sortList(this._items, sortProperty);
    }

    this.selectedItemsMap = {};
    for (const item of this._items) {
      this.selectedItemsMap[item[this.itemKeyProperty]] = false;
    }
    this.selectedItemCount = 0;

    this.reloadVisibleItems();
  }
  get items(): any[] {
    return this._items;
  }

  selectedItemsMap: any;
  selectedItemCount: number = 0;

  visibleItems: any[] = [];

  // Infinite Scroll ////////////
  infiniteParams: InfiniteParams = {
    rowsToRender: 40,
    indexOfLastVisibleItem: -1,
    infiniteScrollDisabled: false
  };

  dropdownId: string = Math.floor(Math.random() * 10000000) + '';

  // Mega Search ////////////
  megaSearchConfigMap: Record<string, MegaSearchConfig> = {
    items: {
      search_properties: [],
      item_key_property: null,
      filtered_keys: new Set()
    }
  };

  searchBarHeight: number = 54;
  baseContainerHeight: number = 18;

  dropUp: boolean = false;
  alignLeft: boolean = false;

  backdropRef: ComponentRef<BackdropComponent> = null;

  constructor(
    private eRef: ElementRef,
    private domService: DomService
  ) { }

  ngOnInit(): void {
    this.megaSearchConfigMap.items.search_properties = [{
      label: 'Item', key: 'item', paths: [this.itemNameProperty]
    }];
    this.megaSearchConfigMap.items.item_key_property = this.itemKeyProperty;
  }

  ngOnDestroy() {
    if (this.backdropRef) {
      this.domService.closeBackdrop();
    }
  }

  dropdownToggled(isOpen: boolean) {
    if (isOpen) {
      this.backdropRef = this.domService.openBackdrop();

      setTimeout(() => {
        this.megaSearchComponent.focusInput();
      });
    }
    else {
      this.domService.closeBackdrop();
    }
  }

  reloadVisibleItems() {
    CoreUtilService.reloadVisibleItems(
      this.infiniteParams,
      this.visibleItems,
      this.items,
      (item: any) => this.itemIsVisible(item)
    );
  }

  loadMoreVisibleItems() {
    CoreUtilService.loadMoreVisibleItems(
      this.infiniteParams,
      this.visibleItems,
      this.items,
      (item: any) => this.itemIsVisible(item)
    );
  }

  itemIsVisible(item: any) {
    return !!this.megaSearchConfigMap.items.filtered_keys.has(item[this.itemKeyProperty]);
  }

  determineDropAlignment(event: any) {
    const windowHeight = $(window).height();
    const windowWidth = $(window).width();
    const yOffsetFromWindow = $(event.target)[0].getBoundingClientRect().top;
    const xOffsetFromWindow = $(event.target)[0].getBoundingClientRect().left;

    if (this.listFillWidth) {
      this.listWidth = this.eRef.nativeElement.offsetWidth + 'px';
    }

    this.dropUp = yOffsetFromWindow > (windowHeight / 2);
    this.alignLeft = xOffsetFromWindow < (windowWidth / 2);
  }

  toggleItem(item: any) {
    if (this.singularSelect && this.selectedItemsMap[item[this.itemKeyProperty]]) {
      this.selectedItemsMap[item[this.itemKeyProperty]] = !this.selectedItemsMap[item[this.itemKeyProperty]];
      this.selectedItemCount = 0;
    }
    else if (this.singularSelect) {
      for (const item of this._items) {
        this.selectedItemsMap[item[this.itemKeyProperty]] = false;
      }

      this.selectedItemsMap[item[this.itemKeyProperty]] = !this.selectedItemsMap[item[this.itemKeyProperty]];
      this.selectedItemCount = 1;
    }
    else {
      this.selectedItemsMap[item[this.itemKeyProperty]] = !this.selectedItemsMap[item[this.itemKeyProperty]];
      this.selectedItemCount += this.selectedItemsMap[item[this.itemKeyProperty]] ? 1 : -1;
    }

  }

  toggleAll() {
    // Deselect item items
    if (this.selectedItemCount === this.items.length) {
      this.clearSelected();
    }
    // Select all items matching current search
    else {
      this.selectedItemCount = 0;

      for (const item of this._items) {
        this.selectedItemsMap[item[this.itemKeyProperty]] =
          this.itemIsVisible(item) ||
          this.selectedItemsMap[item[this.itemKeyProperty]];

        this.selectedItemCount += this.selectedItemsMap[item[this.itemKeyProperty]] ? 1 : 0;
      }
    }
  }

  clearSelected() {
    for (const item of this._items) {
      this.selectedItemsMap[item[this.itemKeyProperty]] = false;
    }
    this.selectedItemCount = 0;
  }

  addSelected() {
    const selected = [];

    for (const item of this._items) {
      if (this.selectedItemsMap[item[this.itemKeyProperty]]) {
        selected.push(item);
      }
    }
    this.clearSelected();

    this.itemsSelected.emit({ selected_items: selected });
    this.msddDropDown.close();
  }

}
