<div class="ph-loadingIcon"
     *ngIf="loading">
  <img src="assets/imgs/App_Loader.gif">
</div>


<div class="login-logo-container"
     *ngIf="!loading">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_White.svg">
</div>

<div class="ph-autoPilot-card"
     *ngIf="!loading && !expiredState && !success">

  <div class="ph-loadingIcon"
       *ngIf="sending">
    <img src="assets/imgs/App_Loader.gif">
  </div>

  <div class="ph-autoPilot-details"
       [ngStyle]="{'opacity': sending ? '0.3':''}">

    <div class="ph-autoPilot-details-grid">
      <div>
        <p class="ph-text-grey">Pay Cycle:</p>
        <h2>{{payDetails.pay_cycle_name}}</h2>
      </div>
      <div>
        <p class="ph-text-grey">Pay Period:</p>
        <h2>{{payDetails.start_date | date: 'd'}} - {{payDetails.end_date | date: 'd MMM,'}} <span
                class="ph-text-grey">{{payDetails.end_date | date: 'yyyy'}}</span></h2>
      </div>
      <div>
        <p class="ph-text-grey">Pay Date:</p>
        <h2>{{payDetails.pay_date | date: 'd MMM,'}} <span class="ph-text-grey">{{payDetails.pay_date | date:
            'yyyy'}}</span></h2>
      </div>
      <div>
        <p class="ph-text-grey">Total Payroll:</p>
        <h2>{{payDetails.gross_total | appCurrency}}</h2>
      </div>
    </div>

    <button class="-color-success"
            *ngIf="!success"
            [disabled]="sending"
            (click)="sendPay()">
      {{sending ? "Sending Pay..." : "Send Pay"}}
    </button>
  </div>
</div>


<!-- The token is no longer valid -->
<div class="ph-autoPilot-card ph-autoPilot-success-state"
     *ngIf="success">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_Success.png">
  <h1><strong><span style="color: #5eb22e">Pay sent!</span></strong> A confirmation email is on its way</h1>
</div>


<!-- The token is no longer valid -->
<div class="ph-autoPilot-card ph-autoPilot-expired-state"
     *ngIf="expiredState">
  <img src="assets/product-imgs/payhero/autopilot/PayHero_AutoPilot_Failed.png">
  <h3>This link is no longer valid</h3>
  <p (click)="login()"><a>Sign In to PayHero</a></p>
</div>


<footer class="ph-footer ph-auth-footer">
  <img src="assets/product-imgs/payhero/PayHero_Full_Registered.svg">
</footer>
