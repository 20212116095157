import { Component, Output, EventEmitter, HostListener, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss']
})
export class BackdropComponent {

  @HostBinding('class.-darkBackdrop') @Input() forceDarkBackdrop: boolean = false;

  @Output() backdropClicked = new EventEmitter();

  @HostListener('click', ['$event'])
  handleClick() {
    this.backdropClicked.emit();
  }

}
