import { Component, Input, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';

import { UserService } from './../../services/user/user.service';

@Component({
  selector: 'logo-editor',
  templateUrl: './logo-editor.component.html',
  styleUrls: ['./logo-editor.component.scss']
})
export class LogoEditorComponent {

  @ViewChild('logoSelect') logoSelect: ElementRef;

  readonly has_partner_admin_access: boolean = this.userService.has_partner_admin_access;

  @Input() logo: string = null;

  @Output() updateLogo = new EventEmitter();

  constructor(
    public userService: UserService
  ) { }

  editLogo() {
    this.logoSelect.nativeElement.click();
  }

}
