<dp-list #clientStaffList
         [noItemsMessage]="'Invite staff members to manage the accounts for ' + client.client_name"
         [primarySortProperty]="'partner_staff.full_name'"
         [itemType]="'staff'"
         [itemTypePlural]="'staff'"
         [itemSelectDisabled]="true"
         [items]="client.client_partner_staff">
  <ng-template dpListItem
               let-item>

    <div class="dp-list-itemCell">
      {{item.partner_staff.full_name}}
    </div>

    <div class="dp-list-itemCell">
      <div class="sb-partnerStaffAccess -desktop-only"
           *ngIf="client_accounts.length > 0">
        <span *ngIf="staffAccessMap[item.partner_staff.partner_staff_key] === 0">
          No Access
        </span>
        <span *ngIf="staffAccessMap[item.partner_staff.partner_staff_key] === client_accounts.length
                                && client_accounts.length !== 0">
          Full Access
        </span>

        <span
              *ngIf="staffAccessMap[item.partner_staff.partner_staff_key] !== 0 && staffAccessMap[item.partner_staff.partner_staff_key] !== client_accounts.length">
          Access to {{staffAccessMap[item.partner_staff.partner_staff_key]}}
          Account{{staffAccessMap[item.partner_staff.partner_staff_key] === 1 ? '' : 's'}}
        </span>
      </div>

      <list-drop-down *ngIf="staffDropdownOptions[item.partner_staff.partner_staff_key] && (staffDropdownOptions[item.partner_staff.partner_staff_key][0].length || staffDropdownOptions[item.partner_staff.partner_staff_key][1].length)"
                      [itemNameProperty]="'option_name'"
                      [itemFontColorProperty]="'option_color'"
                      [buttonTypeClass]="'-type-icon'"
                      [buttonIconClass]="'ion-ios-more'"
                      [buttonColorClass]="'-color-grey-clear'"
                      [listWidth]="'200px'"
                      [sortItems]="false"
                      [items]="staffDropdownOptions[item.partner_staff.partner_staff_key]"
                      (itemSelected)="staffDropdownOptionSelected($event.item.option_value, item)">
      </list-drop-down>
    </div>

  </ng-template>
</dp-list>
