import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { FTSubscription } from 'src/app/models/ft-subscription/ft-subscription';
import { SubscriptionInvoice } from 'src/app/models/subscription-invoice/subscription-invoice';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { DpListComponent, DpListTableHeader } from './../../../components/dp-list/dp-list.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-subscription-invoice',
  templateUrl: './subscription-invoice.component.html',
  styleUrls: ['./subscription-invoice.component.scss']
})
export class SubscriptionInvoiceComponent implements OnInit {

  subscription: FTSubscription;

  // Sorting ////////////
  tableHeaders: DpListTableHeader[] = [
    { label: 'Invoice Date', property: 'invoice_date' },
    { label: 'Payment Date', property: 'payment_date', desktop_only: true },
    { label: 'Status' },
    { label: 'Total Payment', property: 'payment_amount' },
    { label: null, desktop_only: true },
  ];
  orderProperty: string = this.tableHeaders[0].property;

  event_subscriptions: Subscription[] = [];

  @ViewChild('invoiceList') invoiceList: DpListComponent;

  subscriptionInvoiceIsVisible: (subscription_invoice: SubscriptionInvoice) => boolean = (subscription_invoice) => {
    return true;
  }

  constructor(
    private subscriptionService: SubscriptionService,
    private stateService: StateService
  ) { }

  ngOnInit(): void {
    this.subscription = this.subscriptionService.getSubscription();

    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  reloadVisibleInvoices() {
    setTimeout(() => {
      this.invoiceList.reloadVisibleItems();
    })
  }

  downloadInvoice(payment: SubscriptionInvoice) {
    if (payment.downloading) {
      return;
    }

    payment.downloading = true;

    this.subscriptionService.downloadPDFSchedule(payment)
      .finally(() => {
          payment.downloading = false;
      })
  }

  backToSubscription() {
    this.stateService.go('app.subscription.dash');
  }

  private _initEventListeners(): void {
    this.event_subscriptions.push(
      this.subscriptionService.getServiceDataUpdateEvent().subscribe(() => {
        this.subscription = this.subscriptionService.getSubscription();
        this.reloadVisibleInvoices();
      })
    );
  }

  private _clearEventListeners(): void {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.event_subscriptions = [];
  }

}
