import { ProductName, ProductValue } from './../../../app.types';
import { UIRouterGlobals } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';

import { DbPingResult } from './../../../services/db-util/db-util.service';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss']
})
export class LockedComponent implements OnInit {

  readonly default_title: string = ' is currently under maintenance';
  readonly default_description: string = 'We should be back shortly';

  ping_result: DbPingResult;
  product_value: ProductValue;
  product_name: ProductName;

  title: string;
  description: string;

  constructor(
    public uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void {
    this.ping_result = this.uiRouterGlobals.params.ping_result;
    this.product_value = 'FLEXITIME';
    this.product_name = 'FlexiTime';

    this.title = this.product_name + ' ' + this.default_title;
    this.description = this.default_description;
  }

}
