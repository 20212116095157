import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-staff-role-modal',
  templateUrl: './manage-staff-role-modal.component.html',
  styleUrls: ['./manage-staff-role-modal.component.scss']
})
export class ManageStaffRoleModalComponent implements OnInit {

  full_name: string;
  admin_flag: boolean;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.dismiss()
  }

  save() {
    this.activeModal.close(this.admin_flag);
  }

}
