import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';

import { UserService } from '../../services/user/user.service';
import { UserAccessAgreements, UserAccessAgreementFlags } from './../../app.types';

type AgreementDetails = {
  name: string,
  message: string,
  url: string
};

@Component({
  selector: 'app-user-access-agreements-modal',
  templateUrl: './user-access-agreements-modal.component.html',
  styleUrls: ['./user-access-agreements-modal.component.scss']
})
export class UserAccessAgreementsModalComponent implements OnInit {

  @Input() user_access_agreements: UserAccessAgreements;

  modal_title: string;
  checkbox_description: string;

  agree_checked: boolean = false;

  updated_agreements: AgreementDetails[];

  loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.initUpdatedAgreements();
    this.initModalTitle();
    this.initCheckboxDescription();
  }

  initUpdatedAgreements() {
    this.updated_agreements = [];
    if (this.user_access_agreements.subscription_updated_flag) {
      this.updated_agreements.push({
        name: 'Subscription Agreement',
        message: this.user_access_agreements.subscription_message,
        url: 'https://www.flexitime.works/subscription-agreement?reload'
      });
    }
    if (this.user_access_agreements.partner_updated_flag) {
      this.updated_agreements.push({
        name: 'Partner Agreement',
        message: this.user_access_agreements.partner_message,
        url: 'https://www.flexitime.works/partner-resources/partner-agreement?reload'
      });
    }
  }

  initModalTitle() {
    this.modal_title = '';

    if (!this.user_access_agreements.new_user_flag && !this.user_access_agreements.new_partner_flag) {
      this.modal_title = 'Changes to ';
    }

    if (this.updated_agreements.length > 1) {
      this.modal_title += 'User Agreements';
    }
    else {
      this.modal_title += (' ' + this.updated_agreements[0].name);
    }
  }

  initCheckboxDescription() {
    const names: string[] = [];

    for (const agreement of this.updated_agreements) {
      names.push(agreement.name);
    }

    let checkbox_description = 'I acknowledge that I have read and agree to FlexiTime\'s ';

    for (let i = 0; i < names.length; i++) {
      checkbox_description += ('<b>' + names[i] + '</b>');

      if (i < names.length - 1) {
        if (i === names.length - 2) {
          checkbox_description += ' and ';
        }
        else {
          checkbox_description += ', ';
        }
      }
    }

    this.checkbox_description = checkbox_description;
  }

  toggleCheckbox(){
    this.agree_checked = !this.agree_checked;
  }

  accept() {
    this.loading = true;

    const agreement_flags: Partial<Record<UserAccessAgreementFlags, boolean>> = {};
    if (!!this.user_access_agreements.subscription_updated_flag) {
      agreement_flags.subscription_agreed_flag = true;
    }

    if (!!this.user_access_agreements.partner_updated_flag) {
      agreement_flags.partner_agreed_flag = true;
    }

    this.userService.updateUserAccessAgreements(agreement_flags)
      .then(() => {
        this.activeModal.close();
      })
      .catch(() => {
        this.cancel();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
