import { ProductValue } from './../../app.types';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'product-logo',
  templateUrl: './product-logo.component.html',
  styleUrls: ['./product-logo.component.scss']
})
export class ProductLogoComponent {

  @Input() productValue: ProductValue;
  @Input() iconOnly: boolean = false;
  @Input() fullLogoOnly: boolean = false;

}
