import { Component, HostBinding, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { TokenPayheroService } from './../../../services/token-payhero/token-payhero.service';

@Component({
  selector: 'app-expense-request',
  templateUrl: './expense-request.component.html',
  styleUrls: ['./expense-request.component.scss']
})
export class ExpenseRequestComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  token: string = null;
  loading: boolean = false;
  approving: boolean = false;
  declining: boolean = false;
  declined: boolean = false;
  approved: boolean = false;
  expiredState: boolean = false;
  expenseLocked: boolean = false;

  expenseDetails: any = null;

  constructor(
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public tokenPayHeroService: TokenPayheroService
  ) { }

  ngOnInit() {
    window.document.title = 'Expense | PayHero';

    this.token = this.uiRouterGlobals.params.token || null;

    if (this.token) {
      this.getExpense();
    }
    else {
      this.expiredState = true;
    }
  }

  approve() {
    this.approving = true;
    this.respond(new Date(), null);
  }

  decline() {
    this.declining = true;
    this.respond(null, new Date());
  }

  private respond(approval_date, declined_date) {
    this.tokenPayHeroService.postExpense(this.token, approval_date, declined_date)
      .then(() => {
        this.declined = this.declining;
        this.approved = this.approving;

        this.approving = false;
        this.declining = false;

      })
      .catch(() => {
        this.getExpense();

        this.approving = false;
        this.declining = false;
      });
  }

  private getExpense() {
    this.loading = true;
    this.tokenPayHeroService.getExpense(this.token)
      .then((expense) => {
        this.expenseDetails = expense;
        this.loading = false;
        if (!this.expenseDetails) {
          this.expiredState = true;
        }
        else {
          window.document.title = 'Expense | ' + this.expenseDetails.company_name;
          this.expenseLocked = this.expenseDetails.expense_locked_flag;
          this.approved = !!this.expenseDetails.approval_date;
          this.declined = !!this.expenseDetails.declined_date;
        }
      })
      .catch(() => {
        this.loading = false;
        this.expiredState = true;
      });
  }

}
