import { CoreUtilService } from './../core-util/core-util.service';
import { StateAccessService } from './../state-access/state-access.service';
import { Injectable } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { AuthService } from './../auth/auth.service';
import { AppStateDeclaration } from './../../app.types';

import * as  _ from 'lodash-es';
import { SubscriptionService } from '../subscription/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class StateChangeService {

  private readonly backStatesToIgnore: string[] = [
    'splash'
  ];
  private readonly backStackSize: number = 50;
  private backStack: any[] = [];

  constructor(
    private transitionService: TransitionService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private stateAccessService: StateAccessService,
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
  ) { }

  initTransitionListeners() {
    this.listenForAppInit();
    this.listenForStateChangeStart();
    this.listenForStateChangeSubscriptionStart();
    this.listenForStateChangeSuccess();
    this.listenForNoAuthStateChangedSuccess();
  }

  // Only triggered when app is refreshed.
  // Before loading state user was on before refresh,
  // first temporarily redirect them to the splash screen so the stateAccessService and
  // ensure all service data is loaded and user still has access to the previous state
  listenForAppInit() {
    this.transitionService.onStart({ from: this.isRefreshState }, (trans) => {
      const to_state = trans.to().name;

      // return this.dbUtilService.pingAPI()
      //   // API/DB is live
      //   .then(() => {
          if (to_state === 'locked') {
            return this.stateService.target('login');
          }
          // Auth required state
          else if (to_state.indexOf('app.') === 0) {
            if (this.authService.verifyAuth()) {
              return this.stateService.target('splash', {
                refreshStateName: trans.to().name,
                refreshStateParams: trans.params('to')
              });
            }
            else {
              return this.stateService.target('login');
            }
          }
        // })
        // // API/DB is down
        // .catch((ping_result) => {
        //   this.stateAccessService.lockApp();
        //   if (to_state !== 'locked') {
        //     return this.stateService.target('locked', { ping_result });
        //   }
        // });
    });
  }

  // States starting with 'app.' require authentication to access.
  // The above function is triggered instead of this one if
  // the user has just refreshed the page
  listenForStateChangeStart() {
    this.transitionService.onStart({ from: this.notRefreshState, to: 'app.**' }, (trans) => {
      if (this.authService.verifyAuth()) {
        return this.stateAccessService.ensureServicesInitialised()
          .catch(() => {
            trans.abort();
            this.authService.logout();
          });
      }
      else {
        return this.stateService.target('login');
      }
    });
  }

  listenForStateChangeSubscriptionStart() {
    this.transitionService.onStart({ from: this.notRefreshState, to: 'app.subscription.**'}, (trans) => {
      if (this.authService.verifyAuth()) {
        // Check if we've initialised the subscription service. If not, direct via splash so that it will be initialised!
        if (!this.subscriptionService.service_set_up) {
          return this.stateService.target('splash', {
            refreshStateName: trans.to().name,
            refreshStateParams: trans.params('to')
          });
        }
      }
      else {
        return this.stateService.target('login');
      }
    });
  }

  listenForStateChangeSuccess() {
    this.transitionService.onSuccess({}, (trans) => {
      const fromName = trans.from().name;

      const toName = trans.to().name;
      const toParams = trans.params('to');

      if (toName && toName !== fromName) {
        // This value will be true when transition is triggered by this.back()
        if (!trans.options().custom.discardFromBackStack) {
          this.pushToBackStack(toName, toParams);
        }
      }
    });
  }

  // Ensure service data is cleared when not logged in
  listenForNoAuthStateChangedSuccess() {
    this.transitionService.onSuccess({ to: this.noAuthState }, (trans) => {
      // Exempt the splash state from clearing service data. It already does this in the case we're re-initialising the services
      // but we don't want to clear the service data when using the splash to initialise the subscription service specifically.
      if (trans.to().name !== 'splash') this.stateAccessService.clearServiceData();
    });
  }

  isRefreshState(state: any) {
    return state.name === '';
  }

  notRefreshState(state: any) {
    return state.name !== '';
  }

  noAuthState(state: any) {
    return state.name.indexOf('app.') === -1;
  }

  back(statesToSkip: string[] = [], params: any = {}) {
    const stateIndex = this.getNextBackStateIndex(statesToSkip);

    if (stateIndex !== null) {
      const backState = this.backStack[stateIndex];
      setTimeout(() => {
        this.stateService.go(
          backState.name,
          StateChangeService.mergeOldAndNewParams(backState.params, params),
          { reload: true, custom: { discardFromBackStack: true } }
        )
          .then(() => {
            // Remove previous state from back stack along with any states to skip
            // that were in between the previous state and the new current state
            this.backStack = this.backStack.slice(stateIndex, backState.length);
          })
          .catch(() => {
            // Usually caused by a user cancelling a back action due to unsaved changes
          });
      });
    }
    else {
      this.goToRootStateDefault();
    }
  }

  goToRootStateDefault() {
    const active_state = this.uiRouterGlobals.current.name;
    const end_index = CoreUtilService.indexOfNthOccurance(active_state, '.', 2);
    const current_root_state = active_state.slice(0, end_index);

    const all_states = this.stateService.get() as AppStateDeclaration[];
    for (const state of all_states) {
      if (
        state.name.indexOf(current_root_state) === 0 &&
        state.default_state
      ) {
        this.stateService.go(state.name);
        return;
      }
    }
  }

  getNextBackStateIndex(statesToSkip: string[]) {
    const skipStatesMap = {};

    if (statesToSkip) {
      for (const skipState of statesToSkip) {
        skipStatesMap[skipState] = true;
      }
    }

    if (!this.backStack.length) {
      return null;
    }
    else {
      for (let i = 0; i < this.backStack.length; i++) {
        const state = this.backStack[i];

        // Return state if it is different from the current state and isn't a skipState
        if (!skipStatesMap[state.name] &&
          state.name !== this.uiRouterGlobals.current.name) {
          return i;
        }
      }
      return null;
    }
  }

  pushToBackStack(stateName: string, stateParams: any) {
    if (this.backStatesToIgnore.indexOf(stateName) === -1) {
      // No point adding the same state multiple times in a row
      if (
        !this.backStack.length ||
        this.backStack[0].name !== stateName ||
        JSON.stringify(this.backStack[0].params) !== JSON.stringify(stateParams)
      ) {

        if (this.backStack.length >= this.backStackSize) {
          this.backStack = this.backStack.slice(0, this.backStackSize - 1);
        }

        this.backStack.unshift({
          name: stateName,
          params: stateParams
        });
      }
    }
  }

  /**
  * Merges two state param objects. Values in newParams take precedence
  * if both objects contain different values for the same key
  */
  static mergeOldAndNewParams(oldParams: any = {}, newParams: any = {}): any {
    const params = _.cloneDeep(newParams);

    for (const key of Object.keys(oldParams)) {
      if (!params[key]) {
        params[key] = oldParams[key];
      }
    }

    return params;
  }

}
