<banner #banner
        [bannerConfig]="banner_config">
</banner>

<div class="ph-modal-content">
  <div class="ph-modal-header">
    <div class="headerCenter">
      {{title}}
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="close()">
    </i>
  </div>

  <div class="ph-modal-container">
    <div class="ph-formItem-container -smallForm">

      <div *ngFor="let formField of formFields">

        <form-input *ngIf="!formField.field_visibility_requires_button_click && (!formField.field_type || formField.field_type === 'number' || formField.field_type === 'email')"
                    [formField]="formField"
                    [formFieldError]="formFieldErrors[formField.property]"
                    [formObject]="objectCopy"
                    (clearFormFieldError)="clearFormFieldError($event.property)">
        </form-input>

        <image-input #imageInput
                    *ngIf="!formField.field_visibility_requires_button_click && formField.field_type === 'image'"
                    [file_url]="objectCopy[formField.property]"
                    [customError]="formFieldErrors[formField.property]"
                    (file_changed)="updateFile($event.file)"
                    (file_cleared)="removeImage($event.remove_file_flag)">
       </image-input>

        <bank-account-input #bankAccountInput
                            *ngIf="!formField.field_visibility_requires_button_click && formField.field_type === 'bank'"
                            [inputLabel]="formField.label"
                            [activeValidationEnabled]="false"
                            [bankAccountRequired]="formField.field_required"
                            [customError]="formFieldErrors[formField.property]"
                            [readonly]="formField.field_readonly"
                            [(ngModel)]="objectCopy[formField.property]"
                            (click)="clearBannerError()">
        </bank-account-input>

        <address-input #addressInput
                       *ngIf="!formField.field_visibility_requires_button_click && formField.field_type === 'address'"
                       [inputLabel]="formField.label"
                       [activeValidationEnabled]="false"
                       [addressRequired]="formField.field_required"
                       [customError]="formFieldErrors[formField.property]"
                       [readonly]="formField.field_readonly"
                       [(ngModel)]="objectCopy[formField.property]"
                       (click)="clearBannerError()">
        </address-input>

        <country-input #countryInput
                       *ngIf="!formField.field_visibility_requires_button_click && formField.field_type === 'country'"
                       [inputLabel]="formField.label"
                       [activeValidationEnabled]="false"
                       [countryRequired]="formField.field_required"
                       [customError]="formFieldErrors[formField.property]"
                       [readonly]="formField.field_readonly"
                       [(ngModel)]="objectCopy[formField.property]"
                       (click)="clearBannerError()">
        </country-input>

        <div class="ph-formItem"
             *ngIf="!formField.field_visibility_requires_button_click && formField.field_type === 'staff-role'">
          <div class="ph-formItem-label">
            {{formField.label}}
          </div>
          <div class="ph-formItem-value">
            <staff-role-picker [(adminFlag)]="objectCopy[formField.property]"></staff-role-picker>
          </div>
        </div>

        <div class="ph-formItem"
             *ngIf="formField.field_visibility_requires_button_click">
          <div class="ph-formItem-label">
            {{formField.label}}
          </div>
          <div class="ph-formItem-value">
            <button class="-type-text -color-link-clear"
                    (click)="triggerFieldVisibility(formField)">
              Set {{formField.label}}
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="close()">
      Cancel
    </button>
    <button class="-color-success ph-modal-btnRight"
            (click)="save()">
      Save
    </button>
  </div>
</div>
