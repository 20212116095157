import { Injectable } from '@angular/core';
import { IntegrationType, LoginIntegration } from 'src/app/app.types';
import { DbUtilService } from '../db-util/db-util.service';
import { Account } from '../../models/account/account';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(public dbUtilService: DbUtilService) {

  }

  static get login_integrations(): LoginIntegration[] {
    return [
      {
        integration_type: 'GOOGLE',
        integration_name: 'Google',
        integration_logo_path: 'assets/imgs/google/Google_Logo.svg'
      },
      {
        integration_type: 'XERO',
        integration_name: 'Xero',
        integration_logo_path: 'assets/imgs/xero/Xero_Logo_Blue.svg'
      }
    ];
  }

  getLoginIntegration(integration_type: IntegrationType): LoginIntegration {
    let loginIntegration: LoginIntegration;

    for (loginIntegration of IntegrationService.login_integrations) {
      if (loginIntegration.integration_type === integration_type) return loginIntegration;
    }

    return null;
  }

  insertIntegrationVerificationToken(token_type: string, additional_data: Object): Promise<any> {
    return new Promise<string>((resolve, reject) => {

      const data = {
        token_type,
        additional_data: JSON.stringify(additional_data)
      };

      this.dbUtilService.APIPost('integration/verification', data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

}
