import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FTSubscription } from 'src/app/models/ft-subscription/ft-subscription';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { Subscription } from 'rxjs';
import { BankEditComponent } from 'src/app/components/bank-edit/bank-edit.component';
import { BankEditStep } from 'src/app/app.types';

@Component({
  selector: 'app-update-payment-method-modal',
  templateUrl: './update-payment-method-modal.component.html',
  styleUrls: ['./update-payment-method-modal.component.scss']
})
export class UpdatePaymentMethodModalComponent implements OnInit, OnDestroy {

  @ViewChild('bankEdit') bankEdit: BankEditComponent;


  subscription: FTSubscription = this.subscriptionService.getSubscription();
  cardUpdateEventListener: Subscription = null;


  currentDDStep: BankEditStep = 'FORM';
  bank_errors = {
    account_name: false,
    account_number: false,
    bank_name: false,
    bank_code: false
  };

  title: string = null;
  update_button_label: string = null;

  error_message: string = null;

  loading: boolean = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
    this._initButtonLabels();
    this._initTitle();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  updatePaymentMethod() {
    this.error_message = null;

    switch (this.subscription.payment_method) {
      case 'CARD':
        break;
      case 'DIRECTDEBIT':
        this._updateDirectDebit();
        break;
    }
  }

  goToDDStep(step: BankEditStep) {
    this.currentDDStep = step;
  }

  setErrorMessage(message: string) {
    this.error_message = message;
    setTimeout(() => {
      this.error_message = '';
    }, 5000);
  }

  private _updateDirectDebit() {
    this.bank_errors = this.bankEdit?.updateBankErrors();

    if (
      !Object.values(this.bank_errors).includes(true)
    ) {
      this.loading = true;

      this.subscriptionService.saveBillingInformation(this.subscription)
        .catch((err) => this._processApiError(err))
        .finally(() => {
          this.activeModal.close(true);
        });
    } else {
      this.currentDDStep = 'FORM';
    }
  }

  private _initEventListeners() {
    this.cardUpdateEventListener = this.subscriptionService.getCardUpdateEvent().subscribe(
      (event) => {
        if (event.success) {
          this.activeModal.close(true);
        }
        else {
          this.error_message = event.message;
          setTimeout(() => {
            this.error_message = '';
          }, 5000);
        }
        this.loading = false;
      });
  }

  private _clearEventListeners() {
    this.cardUpdateEventListener?.unsubscribe();
  }

  private _initTitle() {
    switch (this.subscription.payment_method) {
      case 'CARD':
        let label = !!this.subscription.subscription_card ? 'Update' : 'Add';
        this.title = label + ' Payment Method';
        break;
      case 'DIRECTDEBIT':
        this.title = 'Update Payment Method';
        break;
    }
  }

  private _initButtonLabels() {
    switch (this.subscription.payment_method) {
      case 'CARD':
        let label = !!this.subscription.subscription_card ? 'Update' : 'Add';
        this.update_button_label = label + ' Card';
        break;
      case 'DIRECTDEBIT':
        this.update_button_label = 'Authorise';
        break;
    }
  }

  private _processApiError(err) {
    if (typeof err === 'string') {
      this.error_message = err;
    }
  }

}
