<div class="ph-modal-content">
  <div class="ph-modal-header">
    <img class="image-icon"
         *ngIf="image"
         [src]="image">
    <span class="ion"
          *ngIf="icon"
          [ngClass]="icon">
    </span>
    <div class="headerCenter">
      {{title}}
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="cancel()">
    </i>
  </div>

  <div class="ph-modal-container">
    <h5 class="ph-modal-info warning"
        [style]="{'margin-bottom': onlyWarningState ? '0' : ''}"
        *ngIf="warning">
      <i class="ion ion-ios-warning sm-icon"></i>
      <span [innerHTML]="warning | safeHtml"></span>
    </h5>
    <h5 [hidden]="onlyWarningState"
        [innerHTML]="question | safeHtml"></h5>
  </div>

  <div class="ph-modal-btnFooter">
    <button class="-color-white-outline ph-modal-btnRight"
            (click)="cancel()">
      {{type === "Cancel" || onlyWarningState ? "Close" : "Cancel"}}
    </button>
    <button class="ph-modal-btnRight"
            [ngClass]="buttonConfirmClass"
            (click)="confirm()"
            [hidden]="onlyWarningState">

      <span *ngIf="buttonTitle">
        {{buttonTitle}}
      </span>
      <span *ngIf="!buttonTitle">
        {{type === "Cancel" ? type + ' ' + itemType : type}}
      </span>
    </button>
  </div>
</div>
