import { Component, OnInit, AfterContentInit, OnDestroy, HostBinding, ViewChild, ElementRef, HostListener } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { Subscription } from 'rxjs';

import { ProductService } from 'src/app/services/product/product.service';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { UserService } from 'src/app/services/user/user.service';
import { ConfirmModalCustomData, ProductTheme, LoginIntegration, IntegrationSocketEventType, IntegrationType, ProductValue } from './../../../app.types';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { TokenService } from 'src/app/services/token/token.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IntegrationService } from 'src/app/services/integration/integration.service';
import { AccountService } from 'src/app/services/account/account.service';

import * as _ from 'lodash-es';

declare var Sha1: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit, AfterContentInit, OnDestroy {

  @HostBinding('class.app-page') app_page: boolean = true;
  @HostBinding('class.-noMobileGraphic') noMobileGraphic: boolean = true;

  @ViewChild('authCardInner') auth_card_inner: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      this._updateAuthCardHeight();
    }, 200);
  }

  productTheme: ProductTheme;
  product_value: ProductValue;

  // State params
  token: string = null;
  two_factor_token: string = null;
  marketing_additional_data: string = null;
  // //

  type: string = null;
  email: string = null;
  subscriptionTransfer: boolean = false; // For invites to Partner organisations
  transferCompany: boolean = false; // For Pending Ownership Transfer invitations
  fullName: string = null;
  coworkInviterName: string = null;
  userRole: string = null;
  existingUser: boolean = null;
  existingUserWithPassword: boolean = false;
  existingIntegrations: String[] = null;
  companyName: string = null;
  isPartner: boolean = false;
  additional_data: any = null;

  loading: boolean = false;
  submitting: boolean = false;
  partnerTermsAgreed: boolean = false;
  userTermsAgreed: boolean = false;

  password: string = null;
  passwordConfirm: string = null;
  showPassword: boolean = false;

  private _errorMessage: string = null;

  loginToken: string = null;
  forgottenPassword: boolean = null;

  auth_card_height: number = null;

  displayConfig = {
    pageTitle: null,
    pageDescription: null,
    fullName: false,
    password: false,
    forgotPasswordButton: false,
    submitButton: false,
    submitButtonLabel: null,
    submitButtonLabelSubmitting: null,
    integrationText: null,
    partnerTsandCs: false,
    showFooterGraphic: false,
    disableIntegrations: null,
  };

  pageTitle: string = null;
  existingAccountTooltipContent: string = 'FlexiTime builds beautiful workforce management solutions. ' +
    'Your FlexiTime account is used to sign in to any of our solutions: ' +
    'PayHero, Droppah, Invoxy or Karmly';

  // activeIntegrations: LoginIntegration[];
  activeIntegrations: Set<IntegrationType>;
  loginIntegrations: LoginIntegration[] = IntegrationService.login_integrations;

  private _integration_events: Partial<Record<IntegrationSocketEventType, Subscription>> = {
    'Integration_Signup_Complete': null,
    'Integration_Connection_Exists': null,
    'Integration_Error': null
  };

  constructor(
    private stateService: StateService,
    private tokenService: TokenService,
    private modalService: ModalService,
    private authService: AuthService,
    private integrationService: IntegrationService,
    private productService: ProductService,
    private uiRouterGlobals: UIRouterGlobals,
    private userService: UserService,
    private redirectService: RedirectService,
    private accountService: AccountService
  ) { }

  get errorMessage(): string {
    return this._errorMessage;
  }
  set errorMessage(message: string) {
    this._errorMessage = message;

    // 200ms timeout needed to wait for errorMessage animation to finish
    setTimeout(() => {
      this._updateAuthCardHeight();
    }, 200);
  }

  ngOnInit() {
    this.initEventListeners();
    this.token = this.uiRouterGlobals.params.token || null;

    this.marketing_additional_data = this.uiRouterGlobals.params.marketing_additional_data || null;
    this.marketing_additional_data = this.marketing_additional_data === 'null' ? null : this.marketing_additional_data;

    if (this.uiRouterGlobals.params.product) {
      this.productService.current_product = this.uiRouterGlobals.params.product;
    }

    this.productTheme = this.productService.product_theme;
    this.product_value = this.productService.current_product;

    this.productService.setTitle('Sign up');
    this.noMobileGraphic = this.productTheme.displayName === 'FlexiTime';

    this.two_factor_token = this.uiRouterGlobals.params.two_factor_token || null;
    if (!this.two_factor_token) {
      this.two_factor_token = this.authService.getCachedTwoFactor() || null;
    }

    this.password = this.uiRouterGlobals.params.password || null;
    this.fullName = this.uiRouterGlobals.params.full_name || null;
    this.passwordConfirm = this.password || null;

    this.coworkInviterName = 'A Co-worker';

    if (this.token) {
      this.getTokenInfo();
    }
    else {
      this.goLogin();
    }
  }

  ngAfterContentInit(): void {
    if (this.loading) { return; }
    setTimeout(() => {
      document.getElementById('password').focus();
    }, 500);
  }

  ngOnDestroy() {
    this.clearEventListeners();
  }

  initEventListeners(): void {
    this._integration_events.Integration_Signup_Complete =
      this.userService.getIntegrationEvent('Integration_Signup_Complete').subscribe((event_data: any) => {
        this.integrationSignupComplete(event_data);
      });
    this._integration_events.Integration_Connection_Exists =
      this.userService.getIntegrationEvent('Integration_Connection_Exists').subscribe((event_data: any) => {
        this.errorMessage = this.userService.getIntegrationConnectionExistsMessage(event_data?.integration_name);
      });
    this._integration_events.Integration_Error =
      this.userService.getIntegrationEvent('Integration_Error').subscribe((event_data: any) => {
        if (event_data) this.errorMessage = event_data;
      });
  }

  clearEventListeners(): void {
    this._integration_events.Integration_Signup_Complete?.unsubscribe();
    this._integration_events.Integration_Signup_Complete = null;

    this._integration_events.Integration_Connection_Exists?.unsubscribe();
    this._integration_events.Integration_Connection_Exists = null;

    this._integration_events.Integration_Error?.unsubscribe();
    this._integration_events.Integration_Error = null;
  }

  integrationSignupComplete(event_data: any) {

    if (event_data?.login_token) this.loginToken = event_data.login_token;

    if (this.type === 'UPDATE VERIFICATION') {
      this.modalService.successModal('Account Updated', 'Your login email has successfully been updated', null);
    }
    else if (this.type === 'INTEGRATION VERIFICATION') {
      return this.stateService.go('login', {
        username: this.email,
        token: this.loginToken,
        internal_state_name: 'app.account.create',
        internal_state_params: CoreUtilService.stringifyJSON({
          product: this.productService.current_product,
          marketing_additional_data: this.marketing_additional_data,
          auto_account_create: this.productService.current_product === 'KARMLY'
        })
      });
    }

    // This transfer key will only be returned from check_registered when this is a pending owner invite
    this.goLogin();
  }

  integrationButton(integration_type: string) {
    switch (integration_type) {
      case 'GOOGLE':
        this._openGooglePopup();
        break;
      case 'XERO':
        this._openXeroPopup();
        break;
    }
  }

  hideError() {
    this.errorMessage = null;
  }

  toggleShowPassword(event) {
    event.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  signUp() {
    if (this.displayConfig.partnerTsandCs && !this.partnerTermsAgreed) {
      this.errorMessage = 'You must agree to the partner terms';
      return;
    }

    // No valid case for no password
    if (!this.password) { return; }

    if (!this.existingUser && this.password.length < 8) {
      this.errorMessage = 'Password must be at least 8 characters long';
      return;
    }

    if (this.type === 'UPDATE VERIFICATION') {
      this.updateUserRegisteredEmail();
    }
    else if (this.type === 'INVITE') {
      if (this.subscriptionTransfer) { // If subscription transfer flag is set, we need to tell them that this will happen if they accept the invite
        this.confirmSubscriptionTransfer();
      }
      else {
        this.acceptInvite();
      }
    }
    else if (this.type === 'INTEGRATION VERIFICATION') {
      // This is used for creating a User Access account from an integration
      this.continueIntegrationSignup();
    }
    else {
      if (this.displayConfig.partnerTsandCs && !this.partnerTermsAgreed) {
        return;
      }
      this.verifyUser();
    }
  }

  private _openGooglePopup() {
    let page;
    let auth_type: string = 'ACCEPT';
    let additional_data = null;

    if (this.type === 'COWORK INVITE') {
      auth_type = 'SIGN_UP';
      additional_data = CoreUtilService.stringifyJSON(this.additional_data);
      page = window; // Redirect in current window for karmly cowork invite
    } else {
      page = this.userService.openIntegrationWindow(); //popup window
    }

    this.userService.getGoogleLoginURI(auth_type, this.token, this.productService.current_product_name, null, additional_data, this.marketing_additional_data)
      .then((URL) => {
        page.location.href = URL;
      })
      .catch(() => {

      });
  }

  private _openXeroPopup() {
    let page;
    let auth_type: string = 'ACCEPT';
    let additional_data = null;

    if (this.type === 'COWORK INVITE') {
      auth_type = 'SIGN_UP';
      additional_data = CoreUtilService.stringifyJSON(this.additional_data);
      page = window; // Redirect in current window for karmly cowork invite
    } else {
      page = this.userService.openIntegrationWindow(); //popup window
    }

    this.userService.getXeroAuthURI(auth_type, this.token, this.productService.current_product_name, null, null, additional_data, this.marketing_additional_data)
      .then((URL) => {
        page.location.href = URL;
      })
      .catch(() => {

      });
  }

  private acceptInvite() {
    this.submitting = true;

    this.tokenService.postAcceptInvite(
      this.token,
      this.displayConfig.fullName ? this.fullName : null,
      Sha1.hash(this.password),
      this.two_factor_token
    ).then((result) => {
      if (result.two_factor_required) {
        this.goTwoFactor(result.two_factor_enabled, result.verification_token);
      }
      else if (result.partner_staff_key) {
        this.stateService.go('splash', {
          refreshStateName: 'app.client.dash',
        });
      }
      // Pending Owner signing up: kick through to sub page
      else if (this.transferCompany) {
        this.stateService.go('splash', {
          refreshStateName: 'app.subscription.dash'
        });
      }
      else if (result?.company_product_key) {
        this.selectAccount(result.company_product_key);
      }
      else {
        this.stateService.go('splash');
      }
    })
      .catch(() => {
        this.submitting = false;
      });
  }

  private continueIntegrationSignup() {
    this.submitting = true;

    this.tokenService.postContinueIntegrationSignup(
      this.token,
      this.email,
      Sha1.hash(this.password),
      this.two_factor_token,
      this.marketing_additional_data
    ).then((result) => {
      if (result.two_factor_required) {
        this.goTwoFactor(result.two_factor_enabled, result.verification_token);
      }
      else {
        // Check if we've created a company!
        // This should be the case currently only when signing up with Xero to Droppah
        if (result?.company_product_key) {
          this.selectAccount(result.company_product_key);
        }
        else {
          this.stateService.go('splash', {
            refreshStateName: 'app.account.create',
            refreshStateParams: {
              product: result?.product_name,
              marketing_additional_data: this.marketing_additional_data,
              auto_account_create: result?.product_name === 'KARMLY'
            }
          });
        }

      }
    })
      .catch(() => {
        this.submitting = false;
      });
  }

  private verifyUser() {
    this.submitting = true;

    this.tokenService.postRegisterPassword(
      this.token,
      this.email,
      this.displayConfig.fullName ? this.fullName : null,
      Sha1.hash(this.password),
      this.two_factor_token
    ).then((result) => {
      let token_data;

      // Firstly parse our additional data if needed
      if (result?.additional_data) {
        token_data = CoreUtilService.parseJSON(result.additional_data);
      }
      if (result.two_factor_required) {
        this.goTwoFactor(result.two_factor_enabled, result.verification_token);
      }
      else if (token_data?.registration_product && token_data?.registration_product === 'Droppah' && token_data.candidate_flag) {
        this.productService.startNewSession(null, token_data.registration_product)
          .then((res) => {
            this.authService.user_access_key = res.user_access_key;
            this.redirectService.redirectToProduct(res.login_source, false, 'crowdhire');
          })
          .catch(() => {
            this.submitting = false;
          });

      }
      else if (token_data?.registration_product && token_data?.registration_product !== 'KARMLY') { // TODO Use this product to determine which product view to use
        this.stateService.go('splash', {
          refreshStateName: 'app.account.create',
          refreshStateParams: {
            company_name: token_data.company_name,
            product: this.productService.current_product,
            marketing_additional_data: this.marketing_additional_data,
            auto_account_create: this.productService.current_product === 'KARMLY'
          }
        });
      }
      else {
        if (this.type === 'COWORK INVITE') {
          this.stateService.go('splash', {
            auto_login_flag: true
          });
        } else {
          this.stateService.go('splash');
        }
      }
    })
      .catch(() => {
        this.submitting = false;
      });
  }

  private updateUserRegisteredEmail() {
    this.submitting = true;

    this.tokenService.postUpdateUserRegisteredEmail(this.token, Sha1.hash(this.password), this.two_factor_token)
      .then((result) => {
        if (result.two_factor_required) {
          this.goTwoFactor(result.two_factor_enabled, result.verification_token);
        }
        else {
          this.modalService.successModal('Account Updated', 'Your login email has successfully been updated', null);
          this.goLogin();
        }
      })
      .catch(() => {
        this.submitting = false;
      });
  }

  private getTokenInfo() {
    this.loading = true;
    this.errorMessage = null;
    this.tokenService.getSubTokenInfo(this.token)
      .then((result) => {
        this.type = result.token_type;
        this.email = result.email;
        this.subscriptionTransfer = result.subscription_transfer;
        this.transferCompany = result.transfer_company_flag;

        if (result?.additional_data) {
          this.companyName = result.additional_data.company_name;
          this.fullName = result.additional_data.full_name;
          this.userRole = result.additional_data.role;
          this.isPartner = !!result.additional_data.partner_activation_flag;

          if (result.additional_data?.sender_full_name) {
            this.coworkInviterName = result.additional_data.sender_full_name;
          }
          if (!!result.additional_data?.marketing_additional_data && result.additional_data?.marketing_additional_data !== 'null') {
            this.marketing_additional_data = result.additional_data.marketing_additional_data;
            this.accountService.marketing_additional_data = this.marketing_additional_data;
          }

          this.additional_data = result.additional_data;
        }

        // init our active integrations list
        this.activeIntegrations = new Set(_.flatMap(result.existing_integrations, (type => {
          return type.integration_type;
        })));

        this.existingUser = !!(result.existing_password || this.activeIntegrations.size);
        this.existingUserWithPassword = !!result.existing_password;

        if (this.two_factor_token && this.password) {
          this.passwordConfirm = this.password;
          this.signUp();
        }
        else {
          this.setupDisplayConfig(result.existing_password);
          this._updateAuthCardHeight();
          this.loading = false;
        }

      })
      .catch((err) => {
        this.loading = false;
        if (err) { this.modalService.errorModal(err); }
        this.goLogin();
      });
  }

  private goTwoFactor(two_factor_enabled: boolean, verification_token: string) {
    this.stateService.go('loginTwoFactor', {
      verification_token,
      two_factor_enabled,
      targetStateName: 'token.signUp',
      targetStateParams: {
        password: this.password,
        token: this.token,
        product: this.productService.current_product,
        full_name: this.displayConfig.fullName ? this.fullName : null,
      }
    });
  }

  // If a transfer company key is provided we'll direct them to the sub page and open the target company
  goLogin() {
    this.stateService.go('login', {
      username: this.email,
      token: this.type !== 'UPDATE VERIFICATION' ? this.loginToken : null,
      internal_state_name: this.transferCompany ? 'app.subscription.dash' : null
    });
  }

  private selectAccount(company_product_key: number) {
    this.stateService.go('splash', {
      auto_login_flag: true,
      auto_login_company_product_key: company_product_key
    });
  }

  resetPassword(reset: boolean) {
    if (reset) {
      this.errorMessage = '';
      this.forgottenPassword = true;
      this.pageTitle = this.displayConfig.pageTitle;
      this.displayConfig.pageTitle = 'Reset Password';
    }
    else {
      this.errorMessage = '';
      this.displayConfig.pageTitle = this.pageTitle;
      this.forgottenPassword = false;
      this.loading = false;
    }
    this._updateAuthCardHeight();
  }

  sendPasswordReset() {
    this.loading = true;

    this.userService.emailPasswordReset(this.email)
      .then(() => {
        this.modalService.successModal('Password Reset Sent', null,
          'An email has been sent to <b>'
          + this.email
          + '</b> with a password reset link.');
        this.resetPassword(false);
      })
      .catch(() => {
        this.loading = false;
      });
  }

  private setupDisplayConfig(existing_password: boolean) {
    this.displayConfig = {
      pageTitle: null,
      pageDescription: null,
      fullName: false,
      password: false,
      forgotPasswordButton: false,
      submitButton: false,
      submitButtonLabel: null,
      submitButtonLabelSubmitting: null,
      integrationText: null,
      partnerTsandCs: false,
      showFooterGraphic: true,
      disableIntegrations: false,
    };

    if (this.productService?.current_product === 'DROPPAH' && this.additional_data?.role === 'EMPLOYEE') {
      this.displayConfig.disableIntegrations = true;
    }

    if (!this.existingUser) {
      this.displayConfig.pageTitle = !!this.companyName ? 'Join ' + this.companyName : null;
      this.displayConfig.integrationText = 'Sign up with';
      this.displayConfig.submitButtonLabel = 'Sign up with email';
      this.displayConfig.submitButtonLabelSubmitting = 'Signing up...'
      this.displayConfig.fullName = true;
      this.displayConfig.password = true;
      this.displayConfig.submitButton = true;

    }
    else {
      this.displayConfig.integrationText = 'Sign in with';
      this.displayConfig.submitButtonLabel = 'Sign in with email';
      this.displayConfig.submitButtonLabelSubmitting = 'Signing in...'
    }

    if (this.type === 'COWORK INVITE') {
      this.displayConfig.pageTitle = 'Let\'s start co-workin\'';
      this.displayConfig.pageDescription = this.coworkInviterName + ' wants to work with you, awesome!'
      this.displayConfig.integrationText = 'Continue with';

      if (!this.existingUser) {
        this.displayConfig.pageDescription += ' Choose how you\'d like to sign up.';
        this.displayConfig.submitButtonLabel = 'Sign up with email';
        this.displayConfig.submitButtonLabelSubmitting = 'Signing up...';
      }
    }

    if (this.isPartner) {
      this.displayConfig.partnerTsandCs = true;
    }

    if (existing_password) {
      this.displayConfig.pageTitle = 'Confirm your password';
      this.displayConfig.password = true;
      this.displayConfig.forgotPasswordButton = true;
      this.displayConfig.submitButton = true;
    }

    if (this.activeIntegrations?.size) {
      this.displayConfig.pageTitle = 'Sign in to continue';
    }
  }

  private _updateAuthCardHeight() {
    setTimeout(() => {
      this.auth_card_height = this.auth_card_inner?.nativeElement.offsetHeight;
    });
  }

  // Confirm for a User accepting an invite to a Partner organisation that their subscriptions will be transferred to the Partner
  private confirmSubscriptionTransfer() {
    const customData: ConfirmModalCustomData = {
      icon: '',
      title: 'Confirm Subscription Transfer',
      warning: '<h5>Accepting this invitation will transfer your subscription to the partner organisation.</h5>' +
        '<h5>If you do not want to transfer your subscription to this partner you should transfer your accounts to a new owner before accepting this invitation.</h5>',
      question: 'Are you sure want to join this partner organisation?',
      buttonTitle: 'Confirm',
      buttonConfirmClass: '-color-success'
    };

    this.modalService.confirmModal('Custom', null, null, null, null, customData)
      .then(() => {
        this.acceptInvite();
      })
      .catch(() => { });
  }


}
