<div class="integrationButton-outer"
  (click)="emitEvent(loginIntegration.integration_type)"
    [class.-droppah]="isDroppah">
  <div class="integrationButton-inner">
    <img class="integrationButton-logo" [src]="loginIntegration.integration_logo_path">
    <span class="integrationButton-text">
      {{customPrefix}} {{loginIntegration.integration_name}}
    </span>
  </div>
</div>
