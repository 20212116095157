import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

import { env } from '../../../../environments/environment';

@Component({
  selector: 'app-zendesk-sso',
  templateUrl: './zendesk-sso.component.html',
  styleUrls: ['./zendesk-sso.component.scss']
})
export class ZendeskSsoComponent implements OnInit {

  constructor(public stateService: StateService) { }

  ngOnInit(): void {

    let searchParams =  new URL(location.href).searchParams;

    let return_to = searchParams.get('return_to');
    let brand_id = searchParams.get('brand_id');

    this.stateService.go('login', {
      zendesk_return_to: return_to,
      zendesk_brand_name: brand_id ? env.zendesk.brand_ids[brand_id] : null
    });
  }

}
