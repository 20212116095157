<div class="banner"
     [ngClass]="{
        '-show' : bannerVisible,
        '-error': bannerConfig.type === 'ERROR',
        '-info': bannerConfig.type === 'INFO',
        '-success': bannerConfig.type === 'SUCCESS',
        '-suspended': bannerConfig.type === 'SUSPENDED'
      }">
  <div>
    <i class="bi bi-exclamation-triangle-fill"
       *ngIf="bannerConfig.type === 'SUSPENDED'"></i>
    <span>{{bannerConfig.message}}</span>
    <button class="-color-danger-dark"
            *ngIf="bannerConfig.button_config"
            [disabled]="!!bannerConfig.button_config.disabled"
            (click)="bannerConfig.button_config.button_function()">
      {{bannerConfig.button_config.button_title}}
    </button>
    <spinner class="-spinnerFlex"
             *ngIf="bannerConfig.show_spinner">
    </spinner>
  </div>
  <i class="ion ion-ios-close"
     [hidden]="bannerConfig.hide_disabled"
     (click)="hideBanner()">
  </i>
</div>
