import { StateService } from '@uirouter/angular';
import { Component, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';

import { CoreUtilService } from './../../services/core-util/core-util.service';
import { DpListComponent } from './../dp-list/dp-list.component';
import { Account } from '../../models/account/account';
import { Client } from './../../models/client/client';
import { UserService } from 'src/app/services/user/user.service';
import { ClientService } from './../../services/client/client.service';
import { DropdownOption } from '../../app.types';


@Component({
  selector: 'client-account-list',
  templateUrl: './client-account-list.component.html',
  styleUrls: ['./client-account-list.component.scss']
})
export class ClientAccountListComponent {

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setupAccountDropdownOptions();
  }

  @ViewChild('clientAccountList') clientAccountList: DpListComponent;

  readonly is_partner_owner: boolean = this.userService.is_partner_owner;
  readonly has_partner_admin_access: boolean = this.userService.has_partner_admin_access;

  accountDropdownOptions: Record<number, DropdownOption[][]> = null;
  clientDropdownOptions: DropdownOption[] = [];

  @Input() noAccountsMessage: string = null;
  @Input() clients: Client[] = [];
  @Input() hideProductLogo: boolean = false;
  @Input() accountIsVisible: (account: Account) => boolean = () => true;

  private _accounts: Account[] = [];
  @Input()
  set accounts(accounts: Account[]) {
    this._accounts = accounts || [];
    this.setupClientDropdownOptions();
    this.setupAccountDropdownOptions();
  }
  get accounts(): Account[] {
    return this._accounts;
  }

  @Output() linkAccountToClient = new EventEmitter();
  @Output() unlinkAccountFromClient = new EventEmitter();
  @Output() goToAccountSettings = new EventEmitter();
  @Output() deleteDemoAccount = new EventEmitter();
  @Output() signIn = new EventEmitter();
  @Output() accountSelect = new EventEmitter();

  constructor(
    public stateService: StateService,
    public userService: UserService,
    public clientService: ClientService
  ) { }

  reloadVisibleAccounts() {
    setTimeout(() => {
      this.clientAccountList.reloadVisibleItems();
    });
  }

  setupClientDropdownOptions() {
    const client_dd_options: DropdownOption[] = [];

    for (const client of this.clients) {
      client_dd_options.push({
        option_value: client.client_key,
        option_name: client.client_name
      });
    }

    this.clientDropdownOptions = client_dd_options;
  }

  setupAccountDropdownOptions() {
    this.accountDropdownOptions = {};

    for (const account of this.accounts) {
      const account_options: DropdownOption[][] = [[], []];

      if (account.allow_user_access && CoreUtilService.is_mobile) {
        account_options[0].push({
          option_value: 'SIGN_IN',
          option_name: 'Sign In',
          option_color: '#00adef'
        });
      }

      if (account.sample_company && account.owner_flag) {
        account_options[1].push({
          option_value: 'DELETE_DEMO',
          option_name: 'Delete Demo Account',
          option_color: '#d9534f'
        });
      }
      else {
        if (
          this.has_partner_admin_access &&
          !!account.account_subscription &&
          account.account_status === 'SUBSCRIBED'
        ) {
          account_options[1].push({
            option_value: 'VIEW_SUBSCRIPTION',
            option_name: 'View Subscription',
            option_color: '#333'
          });
        }

        if (account.allow_user_access) {
          account_options[0].push({
            option_value: 'SETTINGS',
            option_name: 'Account Settings',
            option_color: '#00adef'
          });
        }

        if (this.has_partner_admin_access && !!account.client_key) {
          const is_partner_client = this.clientService.partner_client?.client_key === account.client_key;

          account_options[1].push({
            option_value: 'UNLINK',
            option_name: 'Remove From ' + (is_partner_client ? 'Partner' : 'Client'),
            option_color: '#d9534f'
          });
        }
      }
      if (account_options[1].length === 0) {
        account_options.splice(1, 1);
      }
      if (account_options[0].length === 0) {
        account_options.splice(0, 1);
      }

      this.accountDropdownOptions[account.company_product_key] = account_options;
    }
  }

  accountDropdownOptionSelected(
    account: Account,
    option: DropdownOption,
    option_parent: DropdownOption = null
  ) {
    if (option_parent !== null) {
      switch (option_parent.option_value) {
        case 'LINK':
          this.linkAccountToClient.emit({ account, client_key: option.option_value });
          break;
      }
    }
    else {
      switch (option.option_value) {
        case 'SETTINGS':
          this.goToAccountSettings.emit({ account });
          break;
        case 'DELETE_DEMO':
          this.deleteDemoAccount.emit({ account });
          break;
        case 'UNLINK':
          this.unlinkAccountFromClient.emit({ account });
          break;
        case 'SIGN_IN':
          this.signIn.emit({ account });
          break;
        case 'VIEW_SUBSCRIPTION':
          this.stateService.go('app.subscription.account', {
            company_product_key: account.company_product_key
          });
          break;
      }
    }
  }


}
