import { TimeUtilService } from './../../services/time-util/time-util.service';

export class SubscriptionInvoice {

  due_date: Date;
  invoice_date: Date;
  suspension_date: Date;
  payment_date: Date;
  invoice_units: number;
  invoice_gross_total: number;
  payment_amount: number;
  subscription_invoice_key: number;
  subscription_key: number;

  downloading: boolean;

  constructor(
    due_date: string = null, invoice_date: string = null, suspension_date: string = null,
    payment_date: string = null, invoice_units: number = null, invoice_gross_total: number = null,
    payment_amount: number = null, subscription_invoice_key: number = null, subscription_key: number = null
  ) {
    this.due_date = TimeUtilService.dateTimeStringToDate(due_date);
    this.invoice_date = TimeUtilService.dateTimeStringToDate(invoice_date);
    this.suspension_date = TimeUtilService.dateTimeStringToDate(suspension_date);
    this.payment_date = TimeUtilService.dateTimeStringToDate(payment_date);
    this.invoice_units = invoice_units;
    this.invoice_gross_total = invoice_gross_total;
    this.payment_amount = payment_amount;
    this.subscription_invoice_key = subscription_invoice_key;
    this.subscription_key = subscription_key;
  }

}
