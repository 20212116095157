<img class="app-auth-logo"
     src="assets/product-imgs/payhero/PayHero_Full_White_Registered.svg">

<div class="ph-auth-card">

  <div class="ph-loadingIcon" *ngIf="loading">
    <img src="assets/imgs/App_Loader.gif">
  </div>

  <h2>PayHero Account Found</h2>

  <p class="ph-migrate-p">We've found an existing PayHero account attached to your email address. Please enter your password to continue the upgrade process.</p>

  <form class="ph-signUp-details"
        (ngSubmit)="displayConfirmModal()"
        [ngStyle]="{'opacity': (loading) ? '0.3':''}">

    <input class="app-input -inputLarge -disabled"
           disabled
           value={{email}} />

    <input class="app-input -inputLarge"
           type="password"
           placeholder="Password"
           id="password"
           name="password"
           [(ngModel)]="password"
           [autofocus]>

    <button class="-color-success ph-auth-submit"
            type="submit"
            [disabled]="loading || !password">
      Continue Upgrading
    </button>
  </form>

  <p class="ph-migrate-p ph-migrate-load-message" *ngIf="loading">Please wait while we upgrade your account to PayHero...</p>

</div>
