import { Component, Input, OnInit } from '@angular/core';
import { FTSubscription } from '../../models/ft-subscription/ft-subscription';
import { CoreUtilService } from '../../services/core-util/core-util.service';
import { env } from '../../../environments/environment';
import { BankEditStep } from '../../app.types';

@Component({
  selector: 'bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.scss']
})
export class BankEditComponent implements OnInit {

  @Input() subscription: FTSubscription;
  @Input() current_step: BankEditStep = 'FORM';

  bank_errors = {
    account_name: false,
    account_number: false,
    bank_name: false,
    bank_code: false
  };

  dd_auth_number = env.dd_auth_number;

  steps: BankEditStep[] = ['FORM', 'TERMS'];

  constructor() { }

  ngOnInit(): void {
    this.current_step = this.current_step || 'TERMS';
  }

  updateBankErrors() {
    this.bank_errors = {
      account_name: false,
      account_number: false,
      bank_name: false,
      bank_code: false
    };

    if (!this.subscription.bank_account_name || this.subscription.bank_account_name.length > 100) {
      this.bank_errors.account_name = true;
    }
    if (!this.subscription.bank_account_number || !CoreUtilService.validateBankAccount(this.subscription.bank_account_number)) {
      this.bank_errors.account_number = true;
    }
    if (!this.subscription.bank_name || this.subscription.bank_name.length > 30) {
      this.bank_errors.bank_name = true;
    } if (!!this.subscription.bank_code && this.subscription.bank_code.length > 15) {
      this.bank_errors.bank_code = true;
    }

    return this.bank_errors;
  }
}
