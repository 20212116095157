import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';

@Component({
  selector: 'app-initialise-two-factor-modal',
  templateUrl: './initialise-two-factor-modal.component.html',
  styleUrls: ['./initialise-two-factor-modal.component.scss']
})
export class InitialiseTwoFactorModalComponent implements OnInit {

  qr_uri: string;
  qr_key: string;
  verification_code: string;
  error_message: string;

  loading: boolean = true;
  saving: boolean = false;
  showQRKey: boolean = false;
  qrKeyCopied: boolean = false;
  qrKeyCopiedTimer: any;


  constructor(private userService: UserService,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.userService.initialiseTwoFactor()
      .then((qr) => {
        if (qr) {
          this.qr_uri = qr.qr_uri;
          this.qr_key = qr.qr_key;
        }
        else {
          this.activeModal.dismiss('no qr returned');
        }
        this.loading = false;
      })
      .catch((err) => {
        this.activeModal.dismiss(err);
      });
  }

  verify() {
    if (!this.verification_code) {
      this.error_message = 'Verification Code required.';
      return;
    }

    this.saving = true;

    this.userService.verifyTwoFactor(this.verification_code)
      .then((result) => {
        if (result && result.verified) {
          //   $(document).unbind('keypress');

          this.activeModal.close(result.verified);
        }
        else {
          this.saving = false;
          this.error_message = 'Invalid code. Please retry.';
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  copyQRKey(el) {
    const input = $(el);
    input.select();
    try {
      document.execCommand('copy');
      input.blur();
      this.qrKeyCopied = true;
    }
    catch (err) {
      console.log(err);
    }

    if (this.qrKeyCopiedTimer) {
      clearTimeout(this.qrKeyCopiedTimer);
    }

    this.qrKeyCopiedTimer = setTimeout(() => {
      this.qrKeyCopied = false;
    }, 3000);
  }

  toggleQRKey() {
    this.showQRKey = !this.showQRKey;
  }

  hideError() {
    this.error_message = '';
  }

  cancel() {
    this.activeModal.dismiss();
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.verify();
    }
  }

}
