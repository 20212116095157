
import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';

import { DpListComponent } from 'src/app/components/dp-list/dp-list.component';

import { MegaSearchConfig } from 'src/app/components/mega-search/mega-search.component';
import { PartnerStaff } from 'src/app/models/partner-staff/partner-staff';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UserService } from './../../../services/user/user.service';
import { UserAccess } from './../../../models/user-access/user-access';
import { PartnerStaffService } from './../../../services/partner-staff/partner-staff.service';

@Component({
  selector: 'app-staff-dash',
  templateUrl: './staff-dash.component.html',
  styleUrls: ['./staff-dash.component.scss']
})
export class StaffDashComponent implements OnInit {

  @ViewChild('staffList') staffList: DpListComponent;

  // Dropdown Options ////////////
  dropdownOptions: Record<number, any>;

  // Searching ////////////
  megaSearchId: string = 'StaffDashComponent';
  megaSearchConfigMap: Record<string, MegaSearchConfig> = {
    staff: {
      search_properties: [
        { label: 'Staff', key: 'full_name', paths: ['full_name'] },
        { label: 'Email', key: 'email_address', paths: ['email_address'] },
        { label: 'Phone', key: 'contact_phone', paths: ['contact_phone'] }
      ],
      item_key_property: 'partner_staff_key',
      filtered_keys: new Set()
    }
  };

  userAccess: UserAccess = this.userService.user_access;
  owner: PartnerStaff;
  staff: PartnerStaff[];

  loading: boolean;

  staffIsVisible: (staff: PartnerStaff) => boolean = (staff) => {
    return !!this.megaSearchConfigMap.staff.filtered_keys.has(staff.partner_staff_key);
  }

  constructor(
    public stateService: StateService,
    public modalService: ModalService,
    public partnerStaffService: PartnerStaffService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.refreshPartnerStaff();
    this.initDynamicDropdownOptions();
  }

  refreshPartnerStaff() {
    this.owner = this.partnerStaffService.getAllPartnerStaff().filter(staff => staff.owner_flag)[0]
    this.staff = this.partnerStaffService.getAllPartnerStaff().filter(staff => !staff.owner_flag);
  }

  initDynamicDropdownOptions() {
    this.dropdownOptions = {};
    for (const staff of this.staff) {
      this.dropdownOptions[staff.partner_staff_key] = [];

      if (staff.pending_flag) {
        this.dropdownOptions[staff.partner_staff_key].push({
          option_type: 'RESEND',
          option_name: 'Resend Invite',
          option_color: '#00adef'
        });
      }

      this.dropdownOptions[staff.partner_staff_key].push({
        option_type: 'MANAGE',
        option_name: 'Manage Role'
      });

      this.dropdownOptions[staff.partner_staff_key].push({
        option_type: 'DELETE',
        option_name: staff.pending_flag ? 'Cancel Invite' : 'Delete Staff Member',
        option_color: '#d9534f'
      });
    }
  }

  dropdownOptionSelected(option: string, staff: PartnerStaff) {
    if (staff.user_access_key !== this.userAccess.user_access_key) {
      switch (option) {
        case 'RESEND':
          this.resendInvite(staff);
          break;
        case 'MANAGE':
          this.manageStaffAccess(staff);
          break;
        case 'DELETE':
          this.deleteStaff(staff);
          break;
      }
    }
  }

  invite() {
    this.modalService.formModal(
      'Invite Staff Member',
      new PartnerStaff(),
      [
        { property: 'full_name', label: 'Contact Name', field_required: true },
        { property: 'email_address', label: 'Contact Email', field_required: true, field_type: 'email' },
        { property: 'admin_flag', label: 'Role', field_type: 'staff-role' }
      ]
    )
      .then((staff) => {
        this.loading = true;

        this.partnerStaffService.savePartnerStaff(staff)
          .finally(() => {
            this.refreshPartnerStaff();
            this.loading = false;
          })
      })
      .catch(() => { });
  }

  resendInvite(staff: PartnerStaff) {
    staff.invite_status = 'RESENDING';

    this.partnerStaffService.savePartnerStaff(staff, false, true)
      .then(() => {
        staff.invite_status = 'SENT';

        setTimeout(() => {
          staff.invite_status = 'PENDING';
        }, 4000);
      })
      .catch((err) => {
        staff.invite_status = 'PENDING'
      })
  }

  selectStaff(staff: PartnerStaff) {
    this.stateService.go('app.staff.edit', {
      partner_staff_key: staff.partner_staff_key
    });
  }

  manageStaffAccess(staff: PartnerStaff) {
    this.modalService.manageStaffRoleModal(staff.full_name, staff.admin_flag)
      .then((new_admin_flag) => {
        if (staff.admin_flag === new_admin_flag) {
          return;
        }

        this.loading = true;
        staff.admin_flag = new_admin_flag;

        this.partnerStaffService.savePartnerStaff(staff)
          .finally(() => {
            this.refreshPartnerStaff();
            this.loading = false;
          })
      })
      .catch(() => { });
  }

  deleteStaff(staff: PartnerStaff) {
    this.modalService.confirmModal(
      'Delete',
      'Staff Member',
      staff.full_name,
      null,
      staff.full_name + ' will no longer be accessible.', null
    )
      .then(() => {
        this.loading = true;

        this.partnerStaffService.savePartnerStaff(staff, true)
          .finally(() => {
            this.refreshPartnerStaff();
            this.loading = false;
          });
      })
      .catch(() => { });
  }

  megaSearchUpdated() {
    setTimeout(() => {
      this.staffList.reloadVisibleItems();
    });
  }
}
