<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          Subscription
        </div>
      </div>

      <div class="app-nav-rowRight">
        <div class="subDash-nextInvoiceDate"
             *ngIf="subscription.next_invoice_date">
          Next Invoice Date: <span>{{subscription.next_invoice_date | date: 'd MMM, y'}}</span>
        </div>
        <mega-search #megaSearch
                     [megaSearchId]="megaSearchId"
                     [megaSearchConfigMap]="megaSearchConfigMap"
                     [disabled]="loading"
                     [inputPlaceholder]="'Search...'"
                     [items]="{ clients: clients, accounts: accounts }"
                     (searchUpdated)="megaSearchUpdated()">
        </mega-search>
        <list-drop-down [itemNameProperty]="'option_name'"
                        [itemIsToggleableProperty]="'option_is_toggleable'"
                        [itemToggleValueProperty]="'option_toggle_value'"
                        [itemHideProperty]="'option_hidden'"
                        [buttonTypeClass]="'-type-icon'"
                        [buttonIconClass]="'ion-ios-more'"
                        [buttonColorClass]="'-color-grey-clear'"
                        [listWidth]="'300px'"
                        [listAutoClose]="false"
                        [sortItems]="false"
                        [items]="dropdownOptions"
                        (itemSelected)="dropdownOptionSelected($event.item.option_value)">
        </list-drop-down>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window"
         [ngStyle]="{ 'padding': isPartner ? 0 : '' }">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <subscription-account-list *ngIf="!isPartner"
                                 #accountList
                                 [accountIsVisible]="accountIsVisible"
                                 [noAccountsMessage]="'You don\'t currently have any accounts on your subscription'"
                                 [accounts]="accounts"
                                 (subscribe)="subscribe($event.account)"
                                 (cancelAccount)="cancelAccount($event.account)"
                                 (transferOwnership)="transferOwnership($event.account)"
                                 (declineOwnershipTransfer)="declineOwnershipTransfer($event.account)"
                                 (revokeOwnershipTransfer)="revokeOwnershipTransfer($event.account)">
      </subscription-account-list>

      <div class="dp-list subDash-clientList"
           *ngIf="isPartner">

        <div class="dp-list-empty"
             *ngIf="!visibleClients.length && !unlinkedAccountIsVisible && !partnerAccountIsVisible">
          <div class="dp-list-emptyHeader">
            No Accounts
          </div>
          <div class="dp-list-emptyDescription"
               *ngIf="!!clients.length || !!unlinkedAccounts.length || partnerAccounts.length">
            Couldn't find any accounts for your current search
          </div>
          <div class="dp-list-emptyDescription"
               *ngIf="!clients.length && !unlinkedAccounts.length && !partnerAccounts.length">
            You don't currently have any accounts on your subscription
          </div>
        </div>

        <ng-container *ngFor="let client of visibleClients">
          <div class="dp-list-item"
               [ngClass]="{ '-accountsVisible': !!clientAccountVisibility[client.client_key] }"
               (click)="toggleClientAccountVisibility(client)">

            <div class="dp-list-itemRow">
              <div class="dp-list-itemCell">
                <div>{{client.client_name}}</div>
                <i class="ion"
                   *ngIf="clientAccounts[client.client_key].length"
                   [ngClass]="clientAccountVisibility[client.client_key] ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down'">
                </i>
              </div>

              <div class="dp-list-itemCell">
                <div>
                  {{clientAccounts[client.client_key].length}}
                  Account{{clientAccounts[client.client_key].length === 1 ? '' : 's'}}&nbsp;
                </div>
                <div class="subDash-clientTrialCount"
                     *ngIf="clientDataMap[client.client_key].pending_trial_count !== 0">
                  ({{clientDataMap[client.client_key].pending_trial_count}}
                  Trial{{clientDataMap[client.client_key].pending_trial_count === 1 ? '' : 's'}})
                </div>
              </div>

              <div class="dp-list-itemCell">
                <div class="subDash-clientPendingOwnershipTransfer"
                     *ngIf="clientDataMap[client.client_key].pending_ownership_transfer_count !== 0"
                     [hidden]="clientAccountVisibility[client.client_key]">
                  {{clientDataMap[client.client_key].pending_ownership_transfer_count}}
                  Pending Subscription Transfer
                  {{clientDataMap[client.client_key].pending_ownership_transfer_count === 1 ? '' : 's'}}
                </div>
              </div>

              <div class="dp-list-itemCell">
                Total Monthly Cost:
                <span>{{clientDataMap[client.client_key].monthly_cost | appCurrency}}</span>
              </div>
            </div>

            <div class="dp-list-itemRow -clientAccounts"
                 *ngIf="!!clientAccountVisibility[client.client_key] && clientAccounts[client.client_key].length"
                 (click)="$event.stopPropagation()">
              <subscription-account-list #accountList
                                         [accountIsVisible]="accountIsVisible"
                                         [accounts]="clientAccounts[client.client_key]"
                                         (subscribe)="subscribe($event.account)"
                                         (cancelAccount)="cancelAccount($event.account)"
                                         (transferOwnership)="transferOwnership($event.account)"
                                         (declineOwnershipTransfer)="declineOwnershipTransfer($event.account)"
                                         (revokeOwnershipTransfer)="revokeOwnershipTransfer($event.account)">
              </subscription-account-list>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="app-dashListDivider"
           *ngIf="!!visibleClients.length && unlinkedAccountIsVisible">
      </div>

      <div class="subDash-additionalAccounts"
           *ngIf="isPartner"
           [hidden]="!unlinkedAccountIsVisible">

        <div class="ph-formItem-header">
          No Client
        </div>

        <subscription-account-list #accountList
                                   [accountIsVisible]="accountIsVisible"
                                   [accounts]="unlinkedAccounts"
                                   (subscribe)="subscribe($event.account)"
                                   (cancelAccount)="cancelAccount($event.account)"
                                   (transferOwnership)="transferOwnership($event.account)"
                                   (declineOwnershipTransfer)="declineOwnershipTransfer($event.account)"
                                   (revokeOwnershipTransfer)="revokeOwnershipTransfer($event.account)">
        </subscription-account-list>

      </div>

      <div class="app-dashListDivider"
           *ngIf="(!!visibleClients.length || unlinkedAccountIsVisible) && partnerAccountIsVisible">
      </div>

      <div class="subDash-additionalAccounts"
           *ngIf="isPartner"
           [hidden]="!partnerAccountIsVisible">

        <div class="ph-formItem-header">
          Partner Accounts
        </div>

        <subscription-account-list #accountList
                                   [isPartnerAccounts]="true"
                                   [accountIsVisible]="accountIsVisible"
                                   [accounts]="partnerAccounts"
                                   (subscribe)="subscribe($event.account)"
                                   (cancelAccount)="cancelAccount($event.account)"
                                   (transferOwnership)="transferOwnership($event.account)"
                                   (declineOwnershipTransfer)="declineOwnershipTransfer($event.account)"
                                   (revokeOwnershipTransfer)="revokeOwnershipTransfer($event.account)">
        </subscription-account-list>

      </div>

      <div class="subDash-footer">

        <div class="subDash-pricingSummary">

          <div class="subDash-pricingSubTotal">
            Sub Total:
            <div>{{subscription.monthly_net | appCurrency}}</div>
          </div>
          
          <div class="subDash-pricingSubTotal"
                *ngIf="subscription.monthly_discount">
            {{subscription.discount_description ? subscription.discount_description : 'Discount' + subscription.discount_percentage + '%'}}:
            <div>{{subscription.monthly_discount | appCurrency}}</div>
          </div>

          <div class="subDash-pricingSubTotal"
               *ngIf="subscription.billing_tax_type !== 'No Tax'">
            {{subscription.billing_tax_type}}:
            <div>{{subscription.monthly_tax | appCurrency}}</div>
          </div>

          <div class="subDash-pricingSubTotal"
               *ngIf="subscription.monthly_credit">
               {{subscription.credit_description ? subscription.credit_description : 'Credit' }}:
            <div>{{subscription.monthly_credit | appCurrency}}</div>
          </div>

          <div class="subDash-pricingTotal">
            Total Monthly Cost:
            <div>{{subscription.total_monthly_cost | appCurrency : 'symbol'}}</div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>
