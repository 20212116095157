import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { PipesModule } from './../pipes/pipes.module';
import {DirectivesModule} from './../directives/directives.module'

import { PhCheckboxComponent } from './ph-checkbox/ph-checkbox.component';
import { ListDropDownComponent } from './list-drop-down/list-drop-down.component';
import { TooltipPopoverComponent } from './tooltip-popover/tooltip-popover.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PhToggleComponent } from './ph-toggle/ph-toggle.component';
import { DpListComponent } from './dp-list/dp-list.component';
import { DpListItemDirective } from './dp-list/dp-list-item/dp-list-item.directive';
import { SubCardInfoComponent } from './sub-card-info/sub-card-info.component';
import { BankViewComponent } from './bank-view/bank-view.component';
import { BankEditComponent } from './bank-edit/bank-edit.component';
import { NgxMaskModule } from 'ngx-mask';
import { BackdropComponent } from './backdrop/backdrop.component';
import { CostPopoverComponent } from './cost-popover/cost-popover.component';
import { MultiSelectDropDownComponent } from './multi-select-drop-down/multi-select-drop-down.component';
import { MegaSearchComponent } from './mega-search/mega-search.component';
import { ProductLogoComponent } from './product-logo/product-logo.component';
import { SubscriptionAccountListComponent } from './subscription-account-list/subscription-account-list.component';
import { BannerComponent } from './banner/banner.component';
import { ClientAccountListComponent } from './client-account-list/client-account-list.component';
import { ClientStaffListComponent } from './client-staff-list/client-staff-list.component';
import { ClientNoteListComponent } from './client-note-list/client-note-list.component';
import { LogoEditorComponent } from './logo-editor/logo-editor.component';
import { AccountListComponent } from './account-list/account-list.component';
import { BankAccountInputComponent } from './bank-account-input/bank-account-input.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { RequiredFieldIconComponent } from './required-field-icon/required-field-icon.component';
import { StaffRolePickerComponent } from './staff-role-picker/staff-role-picker.component';
import { FormInputComponent } from './form-input/form-input.component';
import { CountryInputComponent } from './country-input/country-input.component';
import { AccountLogoComponent } from './account-logo/account-logo.component';
import { IntegrationButtonComponent } from './integration-button/integration-button.component';
import { APISearchComponent } from './api-search/api-search.component';
import { ItemBulletComponent } from './item-bullet/item-bullet.component';
import { SegmentValueComponent } from './segment-value/segment-value.component';
import { SteppedProgressComponent } from './stepped-progress/stepped-progress.component';
import { AppFooterUserAgreementsComponent } from './app-footer-user-agreements/app-footer-user-agreements.component';
import { AppFooterProductsComponent } from './app-footer-products/app-footer-products.component';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { CreditCardIconComponent } from './credit-card-icon/credit-card-icon.component';
import { ImageInputComponent } from './image-input/image-input.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PayheroCalendarComponent } from './payhero-calendar/payhero-calendar.component';
import { MonthGridComponent} from './payhero-calendar/month-grid/month-grid.component';
import { DateDividerComponent } from './date-divider/date-divider.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { UserIconComponent } from './user-icon/user-icon.component';
import { CountryCodeInputComponent } from './country-code-input/country-code-input.component';
import { PaystationCardInfoComponent } from './paystation-card-info/paystation-card-info.component';


@NgModule({
  declarations: [
    PhCheckboxComponent,
    ListDropDownComponent,
    TooltipPopoverComponent,
    SpinnerComponent,
    PhToggleComponent,
    DpListComponent,
    DpListItemDirective,
    SubCardInfoComponent,
    BankViewComponent,
    BankEditComponent,
    BackdropComponent,
    CostPopoverComponent,
    MultiSelectDropDownComponent,
    MegaSearchComponent,
    ProductLogoComponent,
    SubscriptionAccountListComponent,
    BannerComponent,
    ClientAccountListComponent,
    ClientStaffListComponent,
    ClientNoteListComponent,
    LogoEditorComponent,
    AccountListComponent,
    BankAccountInputComponent,
    AddressInputComponent,
    RequiredFieldIconComponent,
    StaffRolePickerComponent,
    FormInputComponent,
    CountryInputComponent,
    AccountLogoComponent,
    IntegrationButtonComponent,
    APISearchComponent,
    ItemBulletComponent,
    SegmentValueComponent,
    SteppedProgressComponent,
    AppFooterUserAgreementsComponent,
    AppFooterProductsComponent,
    CardDetailComponent,
    CreditCardIconComponent,
    ImageInputComponent,
    FileUploaderComponent,
    DatePickerComponent,
    ProgressBarComponent,
    PayheroCalendarComponent,
    MonthGridComponent,
    DateDividerComponent,
    RadioButtonComponent,
    UserIconComponent,
    CountryCodeInputComponent,
    PaystationCardInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    PipesModule,
    DirectivesModule,
    InfiniteScrollModule,
    NgxMaskModule,
  ],
  exports: [
    PhCheckboxComponent,
    ListDropDownComponent,
    TooltipPopoverComponent,
    SpinnerComponent,
    PhToggleComponent,
    DpListComponent,
    DpListItemDirective,
    SubCardInfoComponent,
    BankViewComponent,
    BankEditComponent,
    BackdropComponent,
    CostPopoverComponent,
    MultiSelectDropDownComponent,
    MegaSearchComponent,
    ProductLogoComponent,
    SubscriptionAccountListComponent,
    BannerComponent,
    ClientAccountListComponent,
    ClientStaffListComponent,
    ClientNoteListComponent,
    LogoEditorComponent,
    AccountListComponent,
    BankAccountInputComponent,
    AddressInputComponent,
    RequiredFieldIconComponent,
    StaffRolePickerComponent,
    FormInputComponent,
    CountryInputComponent,
    AccountLogoComponent,
    IntegrationButtonComponent,
    APISearchComponent,
    ItemBulletComponent,
    SegmentValueComponent,
    SteppedProgressComponent,
    AppFooterUserAgreementsComponent,
    AppFooterProductsComponent,
    CardDetailComponent,
    ImageInputComponent,
    FileUploaderComponent,
    DatePickerComponent,
    ProgressBarComponent,
    PayheroCalendarComponent,
    MonthGridComponent,
    DateDividerComponent,
    RadioButtonComponent,
    UserIconComponent,
    CountryCodeInputComponent,
    PaystationCardInfoComponent
  ]
})
export class ComponentsModule { }
