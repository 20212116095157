<div class="ph-formItem">
  <div class="ph-formItem-label"
       *ngIf="!!inputLabel && !inlineErrorStyling">
    {{inputLabel}}
    <div class="app-fieldOptional"
         *ngIf="!bankAccountRequired">
      (optional)
    </div>
  </div>
  <div class="ph-formItem-value"
       *ngIf="!readonly">
    <input class="ph-formItem-input"
           type="text"
           placeholder="00-0000-0000000-00"
           mask="00-0000-0000000-009"
           [dropSpecialCharacters]="false"
           [ngClass]="{ '-invalid': customError || missing_bank_account || invalid_bank_account }"
           [(ngModel)]="value"
           [ngModelOptions]="{ 'updateOn': 'blur' }"
           (ngModelChange)="bankAccountUpdated()">
    <i class="bi bi-exclamation-triangle-fill"
    *ngIf="inlineErrorStyling && invalid_bank_account">
      <tooltip-popover
      [tooltipContent]="'Invalid ' + inputLabel"></tooltip-popover>
    </i>
  </div>
  <div class="ph-formItem-value"
       *ngIf="readonly">
    {{value}}
  </div>
  <div class="ph-formItem-error"
       *ngIf="!customError && !missing_bank_account && invalid_bank_account && !inlineErrorStyling">
    Invalid {{inputLabel}}
  </div>
  <div class="ph-formItem-error"
       *ngIf="!customError && missing_bank_account && !inlineErrorStyling">
    {{inputLabel}} Required
  </div>
  <div class="ph-formItem-error"
       *ngIf="customError && !inlineErrorStyling">
    {{customError}}
  </div>
</div>
