import { Component, Input, OnInit } from '@angular/core';

import { Account } from '../../models/account/account';

@Component({
  selector: 'account-logo',
  templateUrl: './account-logo.component.html',
  styleUrls: ['./account-logo.component.scss']
})
export class AccountLogoComponent implements OnInit {

  @Input() account: Account;

  has_default_account_logo: boolean = true;
  account_initials: string = '';

  constructor() { }

  ngOnInit(): void {
    this.has_default_account_logo = this.account.has_default_account_logo;
    this.account_initials = this.account.company_name[0];
  }

}
