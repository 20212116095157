import { Component, HostBinding, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { env } from '../../../../environments/environment';
import { TokenPayheroService } from './../../../services/token-payhero/token-payhero.service';
import { ModalService } from 'src/app/services/modal/modal.service';

declare var Sha1: any;
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-migration-continue',
  templateUrl: './migration-continue.component.html',
  styleUrls: ['./migration-continue.component.scss']
})
export class MigrationContinueComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  token: string = null;
  email: string = null;
  exists: boolean = false;
  subscribed: boolean = false;
  two_factor_token: string = null;
  password: string = null;

  loading: boolean = false;

  constructor(
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals,
    public tokenPayHeroService: TokenPayheroService,
    public modalService: ModalService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    window.document.title = 'Continue Upgrade | PayHero';

    // URL params from migration API endpoint
    this.token = this.uiRouterGlobals.params.token || null;
    this.email = this.uiRouterGlobals.params.email ? decodeURIComponent(this.uiRouterGlobals.params.email) : null;
    this.exists = this.uiRouterGlobals.params.exists && this.uiRouterGlobals.params.exists === 'true';
    this.subscribed = this.uiRouterGlobals.params.subscribed && this.uiRouterGlobals.params.subscribed === 'true';

    // Passed in from loginTwoFactor state. These are not URL params
    this.two_factor_token = this.uiRouterGlobals.params.two_factor_token || null;
    if (!this.two_factor_token) {
      this.two_factor_token = this.authService.getCachedTwoFactor() || null;
    }

    this.password = this.uiRouterGlobals.params.password || null;

    // If bad data, go login.
    if (!this.token || !this.email) {
      this.goLogin();
    }
    // If we've come from loginTwoFactor then attempt to automatically continue
    else if (this.two_factor_token && this.email && this.password) {
      this.continueMigration();
    }
  }

  continueMigration() {
    this.loading = true;
    this.tokenPayHeroService.continueFlexiTimeMigration(this.token, this.email, Sha1.hash(this.password), this.two_factor_token)
      .then((result) => {
        // The result from continue endpoint is different when 2FA required.
        if (result.two_factor_required) {
          this.stateService.go('loginTwoFactor', {
            verification_token: result.verification_token,
            two_factor_enabled: result.two_factor_enabled,
            targetStateName: 'token.migrationContinue',
            targetStateParams: {
              token: this.token,
              email: this.email,
              exists: this.exists,
              subscribed: this.subscribed,
              two_factor_token: this.two_factor_token,
              password: this.password
            }
          });
        }
        else {
          this.stateService.go('login', {
            destination: 'PAYHERO_APP',
            company_product_key: result.company_product_key,
            token: result.token,
            two_factor_token: this.two_factor_token
          });
        }
      })
      .catch(() => {
        this.loading = false; // Stay here
      });

  }

  displayConfirmModal() {

    let modalWarning = '';
    let customData = { onlyWarningState: true };

    // Set the modal warning
    if (this.subscribed) {
      modalWarning = 'You have already subscribed to PayHero. If you wish to continue with this upgrade please get in touch with the PayHero support team by emailing <a href="mailto:support@payhero.co.nz">support@payhero.co.nz</a>.';
    }
    else if (this.exists) {
      modalWarning = 'This account has already been upgraded. If you wish to continue with this upgrade you\'ll first need to cancel the existing account by signing in to <a href="'
      + env.payhero.app_url
      + '">PayHero</a> and heading to <i>Subscription</i>.';
    }
    // If we're not hitting on either of the above conditions, don't display the modal just continue
    else{
      return this.continueMigration();
    }

    this.modalService.confirmModal(
      'Continue',
      'Upgrade',
      'the FlexiTime Upgrade',
      '',
      modalWarning,
      customData
    )
      .then(() => {
        this.continueMigration();
      })
      .catch(() => {
      });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      $('#password').focus();
    }, 50);
  }

  goLogin() {
    this.stateService.go('login', {
      username: this.email || null,
      destination: 'PAYHERO_APP'
    });
  }

}
