import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import * as _ from 'lodash-es';

import { ModalService } from '../../../services/modal/modal.service';
import { TokenDroppahService } from '../../../services/token-droppah/token-droppah.service';
import { CoreUtilService } from 'src/app/services/core-util/core-util.service';
import { ProductService } from '../../../services/product/product.service';

@Component({
  selector: 'app-cover-request',
  templateUrl: './cover-request.component.html',
  styleUrls: ['./cover-request.component.scss']
})
export class CoverRequestComponent implements OnInit {

  @HostBinding('class.token-page') token_page: boolean = true;

  token_uid: string = null;

  loading: boolean = false;

  tokenExpired: boolean = false;
  skill_text_color: string = '';

  message: string = '';

  cover_request: any = null;

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public tokenDroppahService: TokenDroppahService,
    public stateService: StateService,
    public modalService: ModalService,
    public productService: ProductService,
  ) { }

  ngOnInit() {
    this.productService.current_product = 'DROPPAH';
    window.document.title = 'Cover Request | Droppah';

    this.token_uid = this.uiRouterGlobals.params.token || null;

    if (this.token_uid) {
      this.getCover();
    }
    else {
      this.tokenExpired = true;
    }
  }

  private getCover() {
    this.loading = true;

    this.tokenDroppahService.getCover(this.token_uid)
      .then((cover_request) => {
        this.cover_request = cover_request;
        this._initSkillTextColor();
      })
      .catch(() => {
        this.tokenExpired = true;
      })
      .finally(() => this.loading = false);
  }

  private _initSkillTextColor() {
    this.skill_text_color = CoreUtilService.getTextColorForBaseColor(this.cover_request.skill_color);
  }

  acceptRequest() {
    this.loading = true;
    this.tokenDroppahService.postCover(this.token_uid, true, false, this.message)
      .finally(() => window.location.reload());
  }

  declineRequest() {
    this.loading = true;
    this.tokenDroppahService.postCover(this.token_uid, false, true, this.message)
      .finally(() => window.location.reload());
  }
}
