import { Injectable } from '@angular/core';

import { TimeUtilService } from '../../services/time-util/time-util.service';
import { BankValidatorService } from '../../services/bank-validator/bank-validator.service';
import { InfiniteParams, NZRegion } from './../../app.types';

import * as  _ from 'lodash-es';
import * as irdnz from 'ird-nz';

@Injectable({
  providedIn: 'root'
})
export class CoreUtilService {

  static readonly flexitime_terms_of_use_url: string = 'https://www.flexitime.works/terms?reload';
  static readonly flexitime_privacy_policy_url: string = 'https://www.flexitime.works/privacy?reload';

  static get is_mobile(): boolean {
    return window.innerWidth <= 850;
  }

  static padNumber(numb: number, size: number, ignoreClipping: boolean): string {
    const sign = Math.sign(numb) === -1 ? '-' : '';

    if (ignoreClipping && (Math.abs(numb) + '').length > size) {
      return sign + Math.abs(numb);
    }

    return sign + new Array(size).concat([Math.abs(numb)]).join('0').slice(-size);
  }

  /**
   * Creates an array of identical items using the length provided
   * eg. createArrayOfItems(3, null) = [null, null, null]
   *
   * @param {number} length
   * @param {any} item
   * @returns {Array<any>}
   */
  static createArrayOfItems(length, item) {
    const arr = [];

    for (let i = 0; i < length; i++) {
      arr.push(_.cloneDeep(item));
    }

    return arr;
  }

  static parseBoolean(boolean: boolean | string | number): boolean {
    return (boolean === true || boolean === 'true' || boolean === 1);
  }

  /**
   * Parses a json object returns the unparsed data if an error is thrown
   *
   * @param {any} data
   * @returns {any}
   */
  static parseJSON(data) {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }

  /**
   * Stringifies a json object returns NULL if an error is thrown
   */
  static stringifyJSON(data: any): any {
    try {
      return JSON.stringify(data);
    }
    catch (err) {
      return null;
    }
  }

  static numberIsValid(number) {
    return !(number === null || number === undefined || number === '' || isNaN(Number(number)));
  }

  /**
   * Rounds the given number to the number of decimal points passed into dp
   *
   * @param {number} numb
   * @param {number} dp
   * @returns {number}
   */
  static round(numb, dp) {
    return Math.round(numb * (Math.pow(10, dp))) / (Math.pow(10, dp));
  }

  /**
   * Generates a unique ID for use with Google login APIs and websocket sessions
   *
   * @returns {string}
   */
  static generateUUID() {
    let d = new Date().getTime();

    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return uuid;
  }

  /**
   * Determines if an IRD number is valid
   */
  static validateIRDNumber(irdNum: string): boolean {
    return irdnz.isValid(irdNum) || irdNum === '000000000' || irdNum === '000-000-000';
  }

  static generateTrialStatus(days) {
    if (days === null) {
      return null;
    }

    if (days > 0) {
      return days + (days === 1 ? ' day' : ' days') + ' remaining';
    }
    else if (days === 0) {
      return '1 day remaining';
    }
    else {
      return 'Expired';
    }
  }

  static validateEmailAddress(email: string): boolean {
    // Regex source: https://www.w3resource.com/javascript/form/email-validation.php
    const emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailValidator.test(email);
  }

  /**
   * Determines if a bank account number is valid
   *
   * @param {string} bankAcc
   * @returns {boolean}
   */
  static validateBankAccount(bankAcc, bankAccountOptional: boolean = false) {
    if (bankAccountOptional && !bankAcc) {
      return true;
    }
    if (!bankAcc || bankAcc.indexOf(' ') !== -1) {
      return false;
    }
    return BankValidatorService.nzBankAccountIsValid(bankAcc);
  }
  // Colour Conversion //////////////////////////////////////////////////

  /**
   * Returns rgba version of integer colour
   *
   * @param num
   * @returns {string} rgba color string
   */
  static intToRgbaColor(num) {
    num >>>= 0;
    let b = num & 0xFF,
      g = (num & 0xFF00) >>> 8,
      r = (num & 0xFF0000) >>> 16;
    // a = ( (num & 0xFF000000) >>> 24 ) / 255 ;

    return 'rgba(' + [r, g, b, 1].join(',') + ')';
  }

  static hexToRgb(hex: string): { r: number, g: number, b: number } {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  static getTextColorForBaseColor(color_hex: string): string {
    color_hex = color_hex.toUpperCase();

    const c = this.hexToRgb(color_hex);
    return (c.r + c.g + c.b) < 384 ? '#FFFFFF' : '#000000';
  }

  /**
   * Returns hex version of integer colour
   *
   * @param num
   * @returns {string} hex color string
   */
  static intToHexColor(num) {
    return CoreUtilService.rgbaToHex(CoreUtilService.intToRgbaColor(num));
  }

  /**
   * Returns integer version of rgba colour
   * Required format: 'rgba(xxx,xxx,xxx,1)' or '#xxxxxx'
   *
   * @param {string} col
   * @returns {int} num color string
   */
  static rgbaOrHexColorToInt(col) {
    let vals;

    if (col[0] === '#') {
      vals = [
        parseInt(col.slice(1, 3), 16),
        parseInt(col.slice(3, 5), 16),
        parseInt(col.slice(5, 7), 16)
      ];
    }
    else if (col[0] === 'r') {
      col = col.slice(col.indexOf('(') + 1, col.indexOf(')') - 2);
      vals = col.split(',');
    }
    else {
      throw new Error('"' + col + '" is not a valid colour format. Must be rgba or hex');
    }

    return parseInt(vals[0]) * 65536 + parseInt(vals[1]) * 256 + parseInt(vals[2]);
  }

  /**
   * Converts a rgba string to a hex string
   *
   * @param {string} rgb eg. 'rgba(xxx,xxx,xxx,1)'
   * @returns {string} hex eg. '#xxxxxx'
   */
  static rgbaToHex(rgb) {
    rgb = rgb.slice(rgb.indexOf('(') + 1, rgb.indexOf(')') - 2);
    let vals = rgb.split(',');

    return '#' + ((1 << 24) + (parseInt(vals[0]) << 16) + (parseInt(vals[1]) << 8) + parseInt(vals[2])).toString(16).slice(1);
  }

  /**
   * Converts a hex string and an alpha value to an rgba string
   *
   * @param {String} hex
   * @param {Number} alpha
   * @returns {String}
   */
  static hexToRgba(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + (alpha || 1) + ')';
  }

  /**
   * Converts 3 separate integer colour values to a hex string
   *
   * @param {int} r
   * @param {int} g
   * @param {int} b
   * @returns {string} hex
   */
  static rgbToHex(r, g, b) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  /**
   * Lighten or darken a colour by the given amount
   *
   * @param {string} col
   * @param {number} amt
   */
  static lightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }
    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;
    if (r > 255) {
      r = 255;
    }
    else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) {
      b = 255;
    }
    else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000FF) + amt;
    if (g > 255) {
      g = 255;
    }
    else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);

  }

  static formatMobileNumberForPosting(
    country_calling_code: string,
    mobile_number: string
  ): string {
    if (mobile_number[0] === '0') {
      mobile_number = mobile_number.slice(1);
    }
    return country_calling_code + mobile_number;
  }

  //  Infinite Scroll Functions ////////////////////////////////////////////////

  static reloadVisibleItems(
    infiniteParams: InfiniteParams, visibleItems: any[], items: any[],
    itemIsVisible: (...params: any) => boolean
  ): void {
    // Clear visibleItems without losing its object reference
    visibleItems.length = 0;

    infiniteParams.indexOfLastVisibleItem = -1;
    infiniteParams.infiniteScrollDisabled = false;

    CoreUtilService.loadVisibleItems(infiniteParams, visibleItems, items, itemIsVisible);
  }

  static loadMoreVisibleItems(
    infiniteParams: InfiniteParams, visibleItems: any[], items: any[],
    itemIsVisible: (...params: any) => boolean
  ): void {
    if (!infiniteParams.infiniteScrollDisabled) {

      const numVisibleItems = _.cloneDeep(visibleItems.length);
      CoreUtilService.loadVisibleItems(infiniteParams, visibleItems, items, itemIsVisible);

      // loadVisibleItems() hasn't added any more items,
      // so we must be at the end of the list
      if (numVisibleItems === visibleItems.length) {
        infiniteParams.infiniteScrollDisabled = true;
      }
    }
  }

  static loadVisibleItems(
    infiniteParams: InfiniteParams, visibleItems: any[], items: any[],
    itemIsVisible: (...params: any) => boolean
  ): void {
    const maxItems = visibleItems.length + infiniteParams.rowsToRender;

    for (let i = infiniteParams.indexOfLastVisibleItem + 1; i < items.length; i++) {
      const item = items[i];

      if (itemIsVisible(item)) {

        visibleItems.push(item);
        infiniteParams.indexOfLastVisibleItem = i;

        if (visibleItems.length === maxItems) {
          break;
        }
      }
    }
  }

  // Object & Array Manipulation ////////////////////////////////////////////////

  static getNestedProperty(object: any, propertyName: string): any {
    try {
      const propertyParts = propertyName.split('.');
      let property = object;

      for (const part of propertyParts) {
        property = property[part];
      }

      return property || null;
    }
    catch (err) {
      return null;
    }
  }


  /**
   * Sorts the given list by the given object property.
   * Sorts in ascending or descending order based on the forwardOrder param
   */
  static sortList(
    list: any[], sortProperty: string = null, forwardOrder: boolean = true,
    prioritiseNonArchived: boolean = false
  ): any[] {
    try {
      for (const item of list) {
        if (typeof (sortProperty ? this.getNestedPropertyValue(item, sortProperty) : item) === 'string') {
          return _.clone(CoreUtilService._sortStrings(list, sortProperty, forwardOrder, prioritiseNonArchived));
        }
        else if (typeof (sortProperty ? this.getNestedPropertyValue(item, sortProperty) : item) === 'number') {
          return _.clone(CoreUtilService._sortNumbers(list, sortProperty, forwardOrder, prioritiseNonArchived));
        }
        else if ((sortProperty ? this.getNestedPropertyValue(item, sortProperty) : item) instanceof Date) {
          return _.clone(CoreUtilService._sortDates(list, sortProperty, forwardOrder, prioritiseNonArchived));
        }
      }
      return _.clone(list);
    }
    catch (err) {
      console.log(err);
      return list;
    }
  }

  private static _sortStrings(
    list: string[] | any[], sortProperty: string = null, forwardOrder: boolean = true,
    prioritiseNonArchived: boolean = false
  ): string[] | any[] {

    return list.sort((a, b) => {
      let av: string = sortProperty ? this.getNestedPropertyValue(a, sortProperty) : (a || '');
      let bv: string = sortProperty ? this.getNestedPropertyValue(b, sortProperty) : (b || '');
      av = av.toUpperCase();
      bv = bv.toUpperCase();

      if (prioritiseNonArchived && a.archived_flag && !b.archived_flag) {
        return 1;
      }
      else if (prioritiseNonArchived && !a.archived_flag && b.archived_flag) {
        return -1;
      }
      else if (forwardOrder) {
        return av.localeCompare(bv);
      }
      else {
        return bv.localeCompare(av);
      }
    });
  }

  private static _sortNumbers(
    list: number[] | any[], sortProperty: string = null, forwardOrder: boolean = true,
    prioritiseNonArchived: boolean = false
  ): number[] | any[] {

    return list.sort((a, b) => {
      const av: number = sortProperty ? this.getNestedPropertyValue(a, sortProperty) : (a || null);
      const bv: number = sortProperty ? this.getNestedPropertyValue(b, sortProperty) : (b || null);

      if (prioritiseNonArchived && a.archived_flag && !b.archived_flag) {
        return 1;
      }
      else if (prioritiseNonArchived && !a.archived_flag && b.archived_flag) {
        return -1;
      }
      else if (forwardOrder) {
        if (av === null) {
          return 1;
        }
        else if (bv === null) {
          return -1;
        }
        else {
          return av < bv ? -1 : av > bv ? 1 : 0;
        }
      }
      else {
        if (av === null) {
          return 1;
        }
        else if (bv === null) {
          return -1;
        }
        else {
          return bv < av ? -1 : bv > av ? 1 : 0;
        }
      }
    });
  }

  private static _sortDates(
    list: Date[] | any[], sortProperty: string = null, forwardOrder: boolean = true,
    prioritiseNonArchived: boolean = false
  ): Date[] | any[] {

    return list.sort((a, b) => {
      let ad: Date, bd: Date;
      let av: number, bv: number;

      if (sortProperty) {
        ad = this.getNestedPropertyValue(a, sortProperty) || null;
        bd = this.getNestedPropertyValue(b, sortProperty) || null;
      }
      else {
        ad = a || null;
        bd = b || null;
      }
      av = TimeUtilService.dateIsValid(ad) ? ad.valueOf() : null;
      bv = TimeUtilService.dateIsValid(bd) ? bd.valueOf() : null;

      if (prioritiseNonArchived && a.archived_flag && !b.archived_flag) {
        return 1;
      }
      else if (prioritiseNonArchived && !a.archived_flag && b.archived_flag) {
        return -1;
      }
      else if (forwardOrder) {
        if (av === null) {
          return 1;
        }
        else if (bv === null) {
          return -1;
        }
        else {
          return av < bv ? -1 : av > bv ? 1 : 0;
        }
      }
      else {
        if (av === null) {
          return 1;
        }
        else if (bv === null) {
          return -1;
        }
        else {
          return bv < av ? -1 : bv > av ? 1 : 0;
        }
      }
    });
  }

  static getNestedPropertyValue(object: any, propertyName: string): any {
    try {
      const propertyParts = propertyName.split('.');
      let property = object;

      for (const part of propertyParts) {
        property = property[part];
      }

      return property === undefined ? null : property;
    }
    catch (err) {
      return null;
    }
  }

  /**
   * Standard update function for searching on standard lists with SuperSearch
   * Returns a boolean that determines whether any items in the list are visible after the search
   *
   * Example of the data param required:
   * {
   *      items: vm.teams,
   *      itemKey: 'team_key',
   *      filteredKeys: vm.superSearchFilteredKeys
   * }
   *
   * @param {Object} data { items: Array<Object>, itemKey: String, filteredKeys: Array<Number> }
   * @returns {boolean}
   */
  static updateSuperSearchList(data) {
    let itemVisible = false;

    for (const item of data.items) {
      item.visible = data.filteredKeys.length === 0 || data.filteredKeys[item[data.itemKey]];

      if (item.visible) {
        itemVisible = true;
      }
    }

    return itemVisible;
  }

  /**
   * Takes an array of objects OR primitives
   *
   * Removes the first item (or all items that match if removeAll is true) that matches the 'key' param
   * passed in by either literal equality, or by the keyField provided (eg. the string 'employee_key').
   *
   * If keyField is provided, the list is assumed to contain objects. If keyField is null, the list is assumed to contain primitives.
   * The remaining list is returned.
   *
   * @param {Array<*>} list
   * @param {Number} key
   * @param {String} keyField
   * @param {Boolean} removeAll
   * @returns {Array<*>}
   */
  static removeItemFromList(list, key, keyField, removeAll) {
    if (!(list && list.length) || !key) {
      return list;
    }

    for (let i = list.length - 1; i >= 0; i--) {

      if ((keyField ? list[i][keyField] : list[i]) === key) {
        list.splice(i, 1);

        if (!removeAll) {
          return list;
        }
      }
    }

    return list;
  }

  /**
   * Takes two arrays of values and returns a concatenated array of the two, filtering out any duplicates
   *
   * @param {Array} arrA
   * @param {Array} arrB
   * @returns {Array}
   */
  static concatUnique(arrA, arrB) {
    let uniqueVals = [];

    for (const val of arrA) {
      if (uniqueVals.indexOf(val) === -1) {
        uniqueVals.push(val);
      }
    }

    for (const val of arrB) {
      if (uniqueVals.indexOf(val) === -1) {
        uniqueVals.push(val);
      }
    }

    return uniqueVals;
  }

  /**
 * Returns the index of the nth occurance of a pattern in a string
 */
  static indexOfNthOccurance(str: string, pattern: string, n: number): number {
    let i = -1;

    while (n-- && i++ < str.length) {
      i = str.indexOf(pattern, i);
      if (i < 0) {
        break;
      }
    }

    return i;
  }

  /**
   * Checks if two objects or primitives are 'loosely' equal.
   * For objects, object properties need to match but don't need to reference the exact same value
   * ie. Difference between == and ===
   *
   * @param {*} x
   * @param {*} y
   * @param {Array<String>} propsToIgnore
   * @returns {boolean}
   */
  static isLooselyEqual(x, y, propsToIgnore) {

    if (x === y || (!x && !y)) {
      return true;
    }
    // if both x and y are exactly the same or are both null/undefined/''

    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false;
    }
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) {
      return false;
    }
    // they must have the exact same prototype chain, the closest we can do is
    // test their constructor.

    // Compare date objects
    if (x instanceof Date && y instanceof Date) {
      return x.valueOf() === y.valueOf();
    }

    // Should ignore 'visible' property on items by default
    if (!propsToIgnore) {
      propsToIgnore = ['visible'];
    }
    else {
      propsToIgnore.push('visible');
    }

    let i, xKeys = Object.keys(x), yKeys = Object.keys(y);
    let validXKeysLength = 0, validYKeysLength = 0;

    for (i = 0; i < xKeys.length; i++) {
      if (propsToIgnore.indexOf(xKeys[i]) === -1 && xKeys[i] !== '$$hashKey') {
        validXKeysLength++;
      }
    }
    for (i = 0; i < yKeys.length; i++) {
      if (propsToIgnore.indexOf(yKeys[i]) === -1 && yKeys[i] !== '$$hashKey') {
        validYKeysLength++;
      }
    }

    if (validXKeysLength !== validYKeysLength) {
      return false;
    }
    // taking into account properties that should be ignored,
    // both objects should have the same number of properties

    for (let p in x) {
      // Ignore properties in propsToIgnore
      if (propsToIgnore.indexOf(p) !== -1) {
        continue;
      }
      if (!x.hasOwnProperty(p) || !y.hasOwnProperty(p) || p[0] === '$') {
        continue;
      }
      // Want to ignore properties added automatically by AngularJS for things like tracking ng-repeats etc

      if (x[p] == y[p]) {
        continue;
      }
      // if they have the same value or identity then they are equal
      if (!CoreUtilService.isLooselyEqual(x[p], y[p], propsToIgnore)) {
        return false;
      }
      // Objects and Arrays must be tested recursively
    }

    return true;
  }

  static get nz_regions(): NZRegion[] {
    return [
      'Northland',
      'Auckland',
      'Waikato',
      'Bay of Plenty',
      'Gisborne',
      'Hawke\'s Bay',
      'Taranaki',
      'Manawatū - Whanganui',
      'Wellington',
      'Nelson',
      'Tasman',
      'Marlborough',
      'West Coast',
      'Canterbury',
      'Otago',
      'Southland',
      'Chatham Islands'
    ];
  }

  /**
   * Returns array of ordinal numbers as words up to 5.
   *
   * @returns string[]
   */
  static getOrdinalNumberStringsToFive() {
    return ['first', 'second', 'third', 'fourth', 'fifth'];
  }

  static calculateInifiniteScrollDistance(
    scroll_container_height_px: number,
    scroll_distance_px: number
  ): number {
    return 10 / (scroll_container_height_px / scroll_distance_px);
  }

  static generateUrlFromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result as string);
        };
      }
      catch (err) {
        reject(err);
      }
    });
  }
}
