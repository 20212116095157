import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { AuthService } from './../../../services/auth/auth.service';
import { Account } from '../../../models/account/account';
import { AccountService } from './../../../services/account/account.service';
import { ProductService } from './../../../services/product/product.service';
import { RedirectService } from './../../../services/redirect/redirect.service';
import { ProductName, ProductValue } from '../../../app.types';
import { IntegrationService } from 'src/app/services/integration/integration.service';

@Component({
  selector: 'app-account-integration',
  templateUrl: './account-integration.component.html',
  styleUrls: ['./account-integration.component.scss'],
})
export class AccountIntegrationComponent implements OnInit {

  product_value: ProductValue = null;
  product_name: ProductName = null;

  accounts: Account[] = [];

  loading: boolean = true;

  integrated_companies: string[]; // for PH integration with Droppah

  account_integration_map: Record<string, boolean> = {};

  readonly invoxy_token_type: string = 'INVOXY VERIFICATION';
  readonly payhero_token_type: string = 'PAYHERO VERIFICATION';

  constructor(
    public authService: AuthService,
    public stateService: StateService,
    public accountService: AccountService,
    public productService: ProductService,
    public redirectService: RedirectService,
    public integrationService: IntegrationService,
    public uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void {
    if (this.authService.integration_redirect_flag) {
      this.loadAccounts();
    }
    else {
      this.stateService.go('splash');
    }
  }

  loadAccounts() {
    this.integrated_companies = this.uiRouterGlobals.params?.integrated_companies?.split(',') || [];

    for (const company_code of this.integrated_companies) {
      this.account_integration_map[company_code] = true;
    }
    this.loading = true;

    this.product_value = this.authService.integration_product;
    this.product_name = ProductService.getProductName(this.product_value);

    this.accountService.loadAccounts()
      .then(() => {
        this.accounts = this.accountService.getAllAccounts([
          'EXCLUDE_CANCELLED', 'EXCLUDE_DEMO', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS', 'EXCLUDE_NO_USER_ACCESS', 'ADMIN_ONLY'
        ], null, [this.product_value], null, true);

        if (!this.tryAutoSelectAccount()) {
          this.loading = false;
        }
      })
      .catch(() => {
        this.redirectService.redirectToProduct();
      });
  }

  tryAutoSelectAccount(): boolean {
    if (this.product_value === 'PAYHERO') {
      if (!this.authService.integration_redirect.company_code) {
        // For Flexi integration (Flexi is a special case for creating accounts from another flexi product)
        // Auto select if 1 account
        if (this.authService.integration_redirect.system === 'FLEXITIME' && this.accounts.length === 1) {
          this.selectAccount(this.accounts[0]);
        }
        else return false;
      }
      else
        for (const account of this.accounts) {
          if (account.external_company_reference === this.authService.integration_redirect.company_code) {
            this.selectAccount(account);
          }
        }

      // A company_code was provided by Invoxy that doesn't
      // match any PH accounts that the user has access to.
      // Return the user to Invoxy without a refresh token
      // to trigger an error in Invoxy
      this.redirectService.redirectToProduct();
      return true;
    }
  }

  selectAccount(account: Account): void {
    this.loading = true;

    if (!account) {
      this.loading = false;
      return;
    }

    switch (this.product_value) {
      case 'PAYHERO': {
        // FlexiTime is set up as the integration system for custom external authorisation routes.
        // The first of which has been set up for creating Droppah accounts from an existing PH account
        // by getting them to authorise for a PH account then kicking on from the sub API
        if (this.authService.integration_redirect.system === 'FLEXITIME') {

          // Hard coded to Droppah here for now. This will influence the refresh token type created in the integration system (PH in this case)
          this.productService.startNewSession(account.company_product_key, account.product_value, false, false, 'DROPPAH')
            .then(() => {
              const token_data = {
                user_access_key: account.user_access_key,
                account_external_reference: account.external_company_reference,
                account_name: account.company_name,
                session_key: this.authService.session_key
              };

              // For this flow we create both a Sub DB verification token to continue this process in the API,
              // and also a session for the product so we can also get a refresh token with that (See Droppah-PH integration)
              this.integrationService.insertIntegrationVerificationToken(this.payhero_token_type, token_data)
                .then((result) => {
                  this.authService.integration_redirect.verification_token = result.verification_token;
                  this.redirectService.integrateWithAccount(account);
                })
                .catch(() => this.loading = false);
            })
            .catch(() => this.loading = false);
        }
        else {
          this.productService.startNewSession(account.company_product_key, account.product_value)
            .then(() => this.redirectService.integrateWithAccount(account))
            .catch(() => this.loading = false);
        }
        break;
      }
      case 'INVOXY': {
        const token_data = {
          invoxy_user_access_key: account.user_access_key,
          invoxy_company_key: account.external_company_reference
        };

        this.integrationService.insertIntegrationVerificationToken(this.invoxy_token_type, token_data)
          .then((result) => {
            this.authService.integration_redirect.verification_token = result.verification_token;
            this.redirectService.integrateWithAccount(account);
          })
          .catch(() => this.loading = false);
        break;
      }
    }
  }

  logout() {
    this.authService.logout();
  }
}
