<dp-list #clientAccountList
         [itemType]="'account'"
         [itemTypePlural]="'accounts'"
         [itemIsVisible]="accountIsVisible"
         [itemSelectEnabledProperty]="'suspended_flag'"
         [noItemsMessage]="noAccountsMessage"
         [items]="accounts"
         (itemSelected)="accountSelect.emit({ account: $event.item })">
  <ng-template dpListItem
               let-item>

    <div class="dp-list-itemCell">
      <list-drop-down *ngIf="!item.client_key && !item.sample_company && clients.length"
                      [itemNameProperty]="'option_name'"
                      [itemSortProperty]="'option_name'"
                      [buttonTypeClass]="'-type-icon'"
                      [buttonColorClass]="'-color-default-clear'"
                      [buttonImgIcon]="'Add_Client_Folder_Icon.svg'"
                      [buttonTooltip]="'Add to Client'"
                      [listName]="'clients'"
                      [listTitle]="'Add To Client'"
                      [items]="clientDropdownOptions"
                      (itemSelected)="linkAccountToClient.emit({ account: item, client_key: $event.item.option_value })">
      </list-drop-down>
      <div class="cal-accountDetails">
        <div class="cal-accountDetails-name -companyName">
          {{item.company_name}}
        </div>
        <product-logo *ngIf="!hideProductLogo"
                      [productValue]="item.product_value"
                      [fullLogoOnly]="true">
        </product-logo>
        <div class="cal-accountDetails-status -expired -mobile-only"
             *ngIf="item.account_status === 'TRIAL_EXPIRED'">
          Trial Expired
        </div>
        <div class="cal-accountDetails-status -trial -mobile-only"
             *ngIf="item.account_status === 'ON_TRIAL'">
          Free Trial: {{item.trial_days_remaining}} days remaining
        </div>
        <div class="cal-accountDetails-status -suspended -mobile-only"
             *ngIf="item.account_status === 'SUSPENDED'">
          <i class="bi bi-exclamation-triangle-fill"></i>
             Suspended
        </div>
        <div class="cal-accountDetails-status -demoDaysRemaining -mobile-only"
             *ngIf="item.account_status === 'DEMO'">
          Demo: {{item.trial_days_remaining}} days remaining
        </div>
      </div>
    </div>

    <div class="dp-list-itemCell -desktop-only">
      <div class="cal-accountDetails">
        <div class="cal-accountDetails-status -demoDaysRemaining"
             *ngIf="item.account_status === 'DEMO'">
          Demo: {{item.trial_days_remaining}} days remaining
        </div>
        <div class="cal-accountDetails-status -expired"
             *ngIf="item.account_status === 'TRIAL_EXPIRED'">
          Trial Expired
        </div>
        <div class="cal-accountDetails-status -trial"
             *ngIf="item.account_status === 'ON_TRIAL'">
          Free Trial: {{item.trial_days_remaining}} days remaining
        </div>
        <div class="cal-accountDetails-status -trial"
             *ngIf="item.account_status === 'UNSUBSCRIBED'">
          Unsubscribed
        </div>
        <div class="cal-accountDetails-status -suspended"
             *ngIf="item.account_status === 'SUSPENDED'">
             <i class="bi bi-exclamation-triangle-fill"></i>
             Suspended
        </div>
      </div>
    </div>

    <div class="dp-list-itemCell">
      <button class="-color-payhero-clear -type-text -desktop-only"
              *ngIf="item.allow_user_access"
              (click)="signIn.emit({ account: item })">
        Sign In
      </button>
      <div *ngIf="(!item.account_subscription && (item.account_status === 'TRIAL_EXPIRED' || item.account_status === 'SUSPENDED'))"
            class="-expiredAccountSubscriptionOwnerDetails -desktop-only">
        <div>
          The subscription for this account is managed by
          {{item.subscription_billing_name}} ({{item.subscription_billing_email}})
        </div>
      </div>
      <div class="cal-noAccess"
           *ngIf="!item.allow_user_access && (item.account_status === 'SUBSCRIBED' || item.account_status === 'ON_TRIAL')">
        No Access
      </div>

      <list-drop-down *ngIf="accountDropdownOptions[item.company_product_key]?.length"
                      [itemNameProperty]="'option_name'"
                      [itemFontColorProperty]="'option_color'"
                      [itemNestedListProperty]="'nested_options'"
                      [buttonTypeClass]="'-type-icon'"
                      [buttonIconClass]="'ion-ios-more'"
                      [buttonColorClass]="'-color-grey-clear'"
                      [listWidth]="'200px'"
                      [sortItems]="false"
                      [listName]="'options'"
                      [items]="accountDropdownOptions[item.company_product_key]"
                      (itemSelected)="accountDropdownOptionSelected(item, $event.item, $event.parent_item)">
      </list-drop-down>
    </div>

  </ng-template>

</dp-list>
