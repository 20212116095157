import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BannerComponent } from '../banner/banner.component';



@Component({
  selector: 'image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent{

  @Input() inputLabel: string = 'Profile Picture';

  @Input() customError: string = null;
  @Input() file_url: string;
  @Input() file_type: string = 'document';
  @Input() modal_header: string = 'Add Document';

  @Output() file_changed = new EventEmitter<{ file: File }>();

  @Output() file_cleared = new EventEmitter<{ remove_file_flag: Boolean }>();

  new_file: File;




  updateImage(new_image: File) {
    this.new_file = new_image;
    this.file_changed.emit({ file: this.new_file });
  }

  clearImage() {
    this.updateImage(null);
    this.customError = null;
    this.file_cleared.emit({ remove_file_flag: true });
  }

  imageError(error: string) {
    this.customError = error;
  }

}
