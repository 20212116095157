<div class="app-nav">

  <div class="app-nav-header"
       [hidden]="loading">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowOuter"></div>
      <div class="app-nav-rowCenter">
        <product-logo [productValue]="product_value"
                      [fullLogoOnly]="true">
        </product-logo>
        <div class="app-nav-titleDescription"
             [hidden]="!loading && accounts.length === 0">
          Select an account to integrate with
        </div>
      </div>
      <div class="app-nav-rowOuter app-nav-signout"
           (click)="logout()">
        Sign Out
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="dp-list accountInt-accounts"
           [hidden]="loading">
        <div class="dp-list-item"
             [class.-itemSelectDisabled]="account_integration_map[account.external_company_reference]"
             *ngFor="let account of accounts"
             (click)="!account_integration_map[account.external_company_reference] ? selectAccount(account) : null">
          <div class="dp-list-itemRow">

            <div class="dp-list-itemCell">
              <img class="accountInt-accountLogo"
                   *ngIf="account.company_logo && !account.has_default_account_logo"
                   [src]="account.company_logo">
              <div class="accountInt-accountDetails">
                <div class="accountInt-accountDetails-name">
                  {{account.company_name}}
                </div>
              </div>
              <div class="integratedIcon"
                   *ngIf="account_integration_map[account.external_company_reference]">
                <i class="ion ion-ios-checkmark"></i>
              </div>

            </div>


            <div class="dp-list-itemCell"
                 *ngIf="!account_integration_map[account.external_company_reference]">
              <i class="ion ion-ios-arrow-forward"></i>
            </div>

          </div>
        </div>

        <div class="dp-list-empty"
             *ngIf="accounts.length === 0">
          <div class="dp-list-emptyHeader">
            No {{product_name}} Accounts
          </div>
          <div class="dp-list-emptyDescription">
            You don't have access to any {{product_name}} accounts to integrate with
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
