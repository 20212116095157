import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductValue } from 'src/app/app.types';
import { ProductService } from 'src/app/services/product/product.service';


@Component({
  selector: 'success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {


  @Input() header: string;
  @Input() message: string;
  @Input() htmlMessage: string;

  product_value: ProductValue;

  constructor(
    private activeModal: NgbActiveModal,
    private productService: ProductService
    ) { }

  ngOnInit() {
    this.product_value = this.productService.current_product;
  }


  ok(){
    this.activeModal.dismiss();
  }

}
