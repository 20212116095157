<div class="app-nav">

  <div class="app-nav-header">
    <div class="app-nav-row -grid">
      <div class="app-nav-rowLeft">
        <div class="app-nav-title">
          <button class="app-nav-previousPage -type-text -desktop-only"
                  (click)="backToStaff()">
            <i class="ion ion-ios-arrow-back"></i> <span>Staff</span>
          </button>
          <div class="app-nav-titleRow">
            {{staff.full_name}}
            <span class="admin"
                  *ngIf="staff.admin_flag && !staff.owner_flag">
              <i class="ion ion-ios-contact"></i>
              Admin
            </span>
            <span *ngIf="staff.owner_flag">
              <i class="ion ion-md-key"></i>
              Owner
            </span>
            <button class="-color-default pendingBtn"
                    *ngIf="staff.invite_status === 'PENDING'"
                    [disabled]="true">
              Pending
            </button>
            <button class="-type-text- -color-payhero-clear"
                    *ngIf="staff.invite_status === 'RESENDING'">
              Resending...
            </button>
            <button class="-type-text -color-success-clear"
                    *ngIf="staff.invite_status === 'SENT'">
              Sent!
            </button>
          </div>
        </div>
      </div>

      <div class="app-nav-rowRight"
           *ngIf="!staff.owner_flag && staff.user_access_key !== userAccess.user_access_key">
        <list-drop-down [itemNameProperty]="'option_name'"
                        [itemFontColorProperty]="'option_color'"
                        [buttonTypeClass]="'-type-icon'"
                        [buttonIconClass]="'ion-ios-more'"
                        [buttonColorClass]="'-color-grey-clear'"
                        [listWidth]="'300px'"
                        [sortItems]="false"
                        [items]="dropdownOptions"
                        (itemSelected)="dropdownOptionSelected($event.item.option_type)">
        </list-drop-down>
      </div>
    </div>
  </div>

  <div class="app-nav-content">
    <div class="dp-window">

      <div class="dp-loadingOverlay"
           *ngIf="loading">
        <spinner></spinner>
      </div>

      <div class="staffEdit-sidePanel">
        <div class="ph-formItem-container -smallForm">
          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Name
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !staff.full_name}">
              {{staff.full_name || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Email
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !staff.email_address}">
              {{staff.email_address || '-'}}
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              Contact Number
            </div>
            <div class="ph-formItem-value"
                 [ngClass]="{'-empty': !staff.contact_phone}">
              {{staff.contact_phone || '-'}}
            </div>
          </div>

          <div class="app-buttonRow"
               *ngIf="is_partner_owner || !staff.owner_flag">
            <button class="-type-text -color-payhero-clear"
                    (click)="editStaffDetails()">
              Edit Details
            </button>
          </div>
        </div>
      </div>

      <div class="staffEdit-content">
        <div class="staffEdit-list">
          <div class="ph-formItem-header">
            Clients
          </div>

          <dp-list #clientList
                   class="staffEdit-list -clientList"
                   [noItemsMessage]="'Link Clients to this staff member to enable access to accounts associated with those Clients'"
                   [primarySortProperty]="'client_name'"
                   [itemType]="'client'"
                   [itemTypePlural]="'clients'"
                   [itemSelectDisabled]="true"
                   [items]="staffClients">
            <ng-template dpListItem
                         let-item>

              <div class="dp-list-itemCell">
                {{item.client_name}}
              </div>

              <div class="dp-list-itemCell -desktop-only">
                <div class="clientContact">
                  <a>{{item.contact_email}}</a>
                  <span *ngIf="item.contact_email && item.contact_phone">&#183;</span>
                  {{item.contact_phone}}
                </div>
              </div>

              <div class="dp-list-itemCell">
                <list-drop-down [itemNameProperty]="'option_name'"
                                [itemFontColorProperty]="'option_color'"
                                [buttonTypeClass]="'-type-icon'"
                                [buttonIconClass]="'ion-ios-more'"
                                [buttonColorClass]="'-color-grey-clear'"
                                [listWidth]="'200px'"
                                [sortItems]="false"
                                [items]="clientDropdownOptions"
                                (itemSelected)="clientDropdownOptionSelected($event.item.option_type, item)">
                </list-drop-down>
              </div>

            </ng-template>
          </dp-list>

          <div class="app-buttonRow">
            <multi-select-drop-down [itemNameProperty]="'client_name'"
                                    [itemKeyProperty]="'client_key'"
                                    [buttonColorClass]="'-color-payhero-clear'"
                                    [buttonTypeClass]="'-type-text'"
                                    [buttonTitle]="'+ Clients'"
                                    [listWidth]="'300px'"
                                    [listDisabled]="loading || availableClients.length === 0"
                                    [searchPlaceholderMessage]="'Search Clients'"
                                    [searchEmptyMessage]="'No Clients Found'"
                                    [itemsEmptyMessage]="'No Clients Available'"
                                    [itemsSelectMessage]="'Add Selected Clients'"
                                    [items]="availableClients"
                                    (itemsSelected)="addClients($event.selected_items)">
            </multi-select-drop-down>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
