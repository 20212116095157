<div class="app-radioButton">
  <div class="app-radioButton-inner"
       [ngStyle]="{'opacity': !!value ? 1 : ''}">
  </div>
</div>

<div class="app-radioButton-label"
     *ngIf="label">
  {{label}}
</div>
