import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CoreUtilService } from '../core-util/core-util.service';
import { DbUtilService } from '../db-util/db-util.service';
import { StateDataService } from './../state-data/state-data.service';
import { DestinationValue, ProductValue, ProductName, ProductTheme, ConfirmModalCustomData } from './../../app.types';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeUtilService } from '../time-util/time-util.service';
import { PhEmployee } from 'src/app/models/ph-employee/ph-employee';
import { Account } from 'src/app/models/account/account';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';


type ProductConfigs = {
  name: ProductName,
  whiteLogo: string,
  colorLogo?: string,
  whiteBadgeLogo?: string,
  colorBadgeLogo?: string,
  graphicLeft?: string,
  graphicRight?: string,
  graphicFooterFull?: string,
  fromFlexiTimeLogo: string,
  favicon: string,
  appleTouchIcon?: string,
  color: string,
  fontColor: string,
  linkColor: string,
  signUpLinkLabel?: string,
  signUpURL: string,
  pricingURL: string,
  employeeAccURL?: string,
  supportCentreURL?: string,
};

export type ProductServiceEventType = (
  'THEME_UPDATED'
);


@Injectable({
  providedIn: 'root'
})

export class ProductService {
  private _current_product: ProductValue;
  private _login_destination: DestinationValue;
  private _from_native_app: boolean = false;
  private _hide_flexTime: boolean;

  readonly destinationToProductMap: Record<DestinationValue, ProductValue> = {
    DROPPAH_APP: 'DROPPAH',
    DROPPAH_MOBILE: 'DROPPAH',
    PAYHERO_APP: 'PAYHERO',
    PAYHERO_PORTAL: 'PAYHERO',
    PAYHERO_MOBILE: 'PAYHERO',
    PAYHERO_SHIFT: 'PAYHERO',
    INVOXY_APP: 'INVOXY',
    INVOXY_MOBILE: 'INVOXY',
    KARMLY_APP: 'KARMLY'
  };

  readonly productConfigs: Record<ProductValue, ProductConfigs> = {
    DROPPAH: {
      name: 'Droppah',
      whiteLogo: 'assets/product-imgs/droppah/Droppah_Full_White.svg',
      //no full colour logo in droppah rebrand
      whiteBadgeLogo: 'assets/product-imgs/droppah/Droppah_Single_White.svg',
      colorBadgeLogo: 'assets/product-imgs/droppah/Droppah_Single.svg',
      graphicLeft: null,
      graphicRight: 'assets/graphics/Droppah/Goals.svg',
      fromFlexiTimeLogo: 'assets/product-imgs/flexitime/From_FlexiTime_Horiz_White.svg',
      favicon: 'assets/product-imgs/droppah/Droppah_Single.svg',
      appleTouchIcon: 'assets/product-imgs/droppah/apple-touch-icon.png',
      color: 'var(--app-color-droppah)',
      fontColor: 'var(--app-color-black)',
      linkColor: 'var(--app-color-black)',
      signUpLinkLabel: 'Looking to sign up your business? Let’s go!',
      signUpURL: 'https://www.droppah.com/signup',
      pricingURL: 'https://www.droppah.com/pricing',
      employeeAccURL: 'https://support.droppah.com/hc/en-us/articles/5751567650959',
      supportCentreURL: 'https://support.droppah.com/hc/en-us'
    },
    PAYHERO: {
      name: 'PayHero',
      whiteLogo: 'assets/product-imgs/payhero/PayHero_Full_White_Registered.svg',
      colorLogo: 'assets/product-imgs/payhero/PayHero_Full_Registered.svg',
      whiteBadgeLogo: 'assets/product-imgs/payhero/PayHero_Icon_White.svg',
      colorBadgeLogo: 'assets/product-imgs/payhero/PayHero_Icon.svg',
      graphicLeft: 'assets/graphics/Product_Hero_Graphics/PayHero_Hero_Left.svg',
      graphicRight: 'assets/graphics/Product_Hero_Graphics/PayHero_Hero_Right.svg',
      graphicFooterFull: 'assets/graphics/Dashboard_Graphic_Day.svg',
      fromFlexiTimeLogo: 'assets/product-imgs/flexitime/FromFlexiTime_Horiz.svg',
      favicon: 'assets/product-imgs/payhero/PayHero_Icon_64px.png',
      appleTouchIcon: 'assets/product-imgs/payhero/apple-touch-icon-background-white.png',
      color: 'var(--app-color-payhero)',
      fontColor: 'var(--app-color-dark)',
      linkColor: 'var(--app-color-link)',
      signUpLinkLabel: 'Start paying your employees with PayHero',
      signUpURL: 'https://www.payhero.co.nz/signup',
      pricingURL: 'https://www.payhero.co.nz/pricing',
      employeeAccURL: 'https://support.payhero.co.nz/hc/en-us/articles/4406495199631',
      supportCentreURL: 'https://support.payhero.co.nz/hc/en-us'
    },
    INVOXY: {
      name: 'Invoxy',
      whiteLogo: 'assets/product-imgs/invoxy/Invoxy_Full_White_Registered.svg',
      colorLogo: 'assets/product-imgs/invoxy/Invoxy_Full_Registered.svg',
      whiteBadgeLogo: 'assets/product-imgs/invoxy/Invoxy_Icon_White.svg',
      colorBadgeLogo: 'assets/product-imgs/invoxy/Invoxy_Icon.svg',
      // graphicFooterFull: 'assets/graphics/Product_Hero_Graphics/KarmlyInvoxy_Hero.svg',
      fromFlexiTimeLogo: 'assets/product-imgs/flexitime/FromFlexiTime_Horiz.svg',
      favicon: 'assets/product-imgs/invoxy/Invoxy_Icon_64px.png',
      appleTouchIcon: 'assets/product-imgs/invoxy/apple-touch-icon-vox.png',
      color: 'var(--app-color-invoxy)',
      fontColor: 'var(--app-color-dark)',
      linkColor: 'var(--app-color-link)',
      signUpURL: 'https://www.karmly.com/recruitment/features/quote',
      pricingURL: 'https://www.karmly.com/recruitment/features/pricing',
      supportCentreURL: 'https://support.karmly.com/hc/en-us'

    },
    KARMLY: {
      name: 'Karmly',
      whiteLogo: 'assets/product-imgs/karmly/Karmly_Full_White.svg',
      colorLogo: 'assets/product-imgs/karmly/Karmly_Full_Registered.svg',
      whiteBadgeLogo: 'assets/product-imgs/karmly/Karmly_Icon_White.svg',
      colorBadgeLogo: 'assets/product-imgs/karmly/Karmly_Icon.svg',
      graphicLeft: 'assets/graphics/Product_Hero_Graphics/KarmlyInvoxy_Hero_Left.svg',
      graphicRight: 'assets/graphics/Product_Hero_Graphics/KarmlyInvoxy_Hero_Right.svg',
      graphicFooterFull: 'assets/graphics/Product_Hero_Graphics/KarmlyInvoxy_Hero.svg',
      fromFlexiTimeLogo: 'assets/product-imgs/flexitime/FromFlexiTime_Horiz.svg',
      favicon: 'assets/product-imgs/karmly/Karmly_Icon.svg',
      appleTouchIcon: 'assets/product-imgs/karmly/apple-touch-icon.png',
      color: 'var(--app-color-karmly)',
      fontColor: 'var(--app-color-dark)',
      linkColor: 'var(--app-color-link)',
      signUpLinkLabel: 'New to Karmly? Get started for free.',
      signUpURL: 'https://www.karmly.io/signup',
      pricingURL: 'https://www.karmly.io/'
    },
    FLEXITIME: {
      name: 'FlexiTime',
      whiteLogo: 'assets/product-imgs/flexitime/FlexiTime_WFM_Full_White.svg',
      colorLogo: 'assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg',
      whiteBadgeLogo: 'assets/product-imgs/flexitime/FlexiTime_Icon_White.svg',
      colorBadgeLogo: 'assets/product-imgs/flexitime/FlexiTime_Icon.svg',
      fromFlexiTimeLogo: null,
      favicon: 'assets/product-imgs/flexitime/FlexiTime_Icon_64px.png',
      appleTouchIcon: 'assets/product-imgs/flexitime/apple-touch-icon.png',
      color: 'var(--app-color-flexitime-blue)',
      fontColor: 'var(--app-color-dark)',
      linkColor: 'var(--app-color-link)',
      signUpURL: 'https://www.flexitime.works/',
      pricingURL: 'https://www.flexitime.works/',
      supportCentreURL: 'https://support.flexitime.works/hc/en-us'
    },
  };

  static readonly productNameToProductValueMap: Record<ProductName, ProductValue> = {
    Droppah: 'DROPPAH',
    PayHero: 'PAYHERO',
    Invoxy: 'INVOXY',
    Karmly: 'KARMLY',
    FlexiTime: 'FLEXITIME'
  };

  static readonly productValueToProductNameMap: Record<ProductValue, ProductName> = {
    DROPPAH: 'Droppah',
    PAYHERO: 'PayHero',
    INVOXY: 'Invoxy',
    KARMLY: 'Karmly',
    FLEXITIME: 'FlexiTime'
  };

  private _events: Record<ProductServiceEventType, Subject<any>> = {
    THEME_UPDATED: new Subject<void>()
  };

  constructor(
    private dbUtilService: DbUtilService,
    private authService: AuthService,
    private stateDataService: StateDataService,
    private ngbModal: NgbModal
  ) {
    this._current_product = this.stateDataService.getCachedComponentSessionData(
      'ProductService',
      'currentProduct',
    ) || 'FLEXITIME';
    this._setupProductTheme();
  }

  get current_product_name(): ProductName {
    return this.productConfigs[this.current_product].name;
  }

  set login_destination(login_destination: DestinationValue) {
    login_destination = (login_destination?.toUpperCase() || null) as DestinationValue;
    if (!this.destinationIsValid(login_destination)) {
      login_destination = null;
    }

    this._login_destination = login_destination;
    this.current_product = this.destinationToProductMap[login_destination];
  }

  get login_destination(): DestinationValue {
    return this._login_destination;
  }

  get current_product(): ProductValue {
    if (!this._current_product) {
      this._current_product = 'FLEXITIME';
    }
    return this._current_product;
  }

  set current_product(current_product: ProductValue) {
    current_product = (current_product?.toUpperCase() || null) as ProductValue;
    if (!this.productIsValid(current_product)) {
      current_product = 'FLEXITIME';
    }

    this._current_product = current_product;
    this.stateDataService.cacheComponentSessionData(
      'ProductService',
      'currentProduct',
      this._current_product
    );
    this._setupProductTheme();
    this.emitEvent('THEME_UPDATED');
  }

  get product_theme(): ProductTheme {

    if (!this._current_product) {
      this._current_product = 'FLEXITIME';
    }
    return {
      loginFooterProductLogos: this.getLoginFooterProductLogos(),
      headerLogo: this.productConfigs[this._current_product].colorLogo || this.productConfigs[this._current_product].whiteLogo,
      colour: this.productConfigs[this._current_product].color,
      graphicLeft: this.productConfigs[this._current_product].graphicLeft,
      graphicRight: this.productConfigs[this._current_product].graphicRight,
      graphicMobileFooterFull: this.productConfigs[this._current_product].graphicFooterFull,
      graphicMobile: this.current_product === 'DROPPAH' ? this.getRandomDroppahGraphic() : null,
      displayName: this.productConfigs[this._current_product].name,
      hideFlexiTime: !!this._hide_flexTime || (this._current_product === 'DROPPAH' || this._current_product === 'INVOXY' || this._current_product === 'KARMLY'),
      fromFlexiTimeLogo: this.productConfigs[this._current_product].fromFlexiTimeLogo,
      signUpLinkLabel: this.productConfigs[this._current_product].signUpLinkLabel,
      signUpURL: this.productConfigs[this._current_product].signUpURL,
      pricingURL: this.productConfigs[this._current_product].pricingURL,
      employeeAccURL: this.productConfigs[this._current_product].employeeAccURL,
      supportCentreURL: this.productConfigs[this._current_product].supportCentreURL
    };
  }

  get hide_flexiTime(): boolean {
    return this._hide_flexTime;
  }

  set hide_flexiTime(hideFlexiTime: boolean) {
    this._hide_flexTime = hideFlexiTime;
  }

  subscribeToEvent(
    event_type: ProductServiceEventType,
    callback: (event_data: any) => void
  ): Subscription {
    if (!!this._events[event_type]) {

      return this._events[event_type]
        .asObservable()
        .subscribe((event_data) => callback(event_data));
    }
  }

  emitEvent(
    event_type: ProductServiceEventType,
    event_data: any = null
  ) {
    this._events[event_type].next(event_data);
  }

  getRandomDroppahGraphic() {
    const droppah_graphics = [
      'assets/graphics/Droppah/Balance.svg',
      'assets/graphics/Droppah/Goals.svg',
      'assets/graphics/Droppah/Hospo.svg',
      'assets/graphics/Droppah/Juggle.svg',
      'assets/graphics/Droppah/Time.svg',
      'assets/graphics/Droppah/Upskill.svg'
    ];
    const rand_num = Math.floor(Math.random() * 6);
    return droppah_graphics[rand_num];
  }

  productIsValid(product: string): boolean {
    if (!product) { return false; }
    return !!this.productConfigs[product.toUpperCase()];
  }

  destinationIsValid(destination: string): boolean {
    if (!destination) { return false; }
    return !!this.destinationToProductMap[destination.toUpperCase()];
  }

  getProductFromDestination(destination: DestinationValue): ProductValue {
    return this.destinationToProductMap[destination] || null;
  }

  getTheme(product: string, key: string): any {
    if (!product) { product = this._current_product; }

    if (!this.productIsValid(product)) {
      product = this._current_product || 'FLEXITIME';
    }

    if (!key) { return this.productConfigs[product.toUpperCase()]; }
    return this.productConfigs[product.toUpperCase()][key];
  }

  setTitle(title: string) {
    if (!title) {
      window.document.title = this.productConfigs[this._current_product].name;
    }
    else {
      window.document.title = title + ' | ' + this.productConfigs[this._current_product].name;
      if (this.productConfigs[this._current_product].name === 'Invoxy') {
        window.document.title = title + ' | Karmly';
      }
    }
  }

  startNewSession(
    company_product_key: number,
    product_value: ProductValue = this._current_product,
    hide_error: boolean = false,
    ignore_mobile_device: boolean = false,
    integration_redirect_system: string = null
  ) {
    return new Promise<any>((resolve, reject) => {

      if (
        !!this._login_destination &&
        product_value !== this.getProductFromDestination(this._login_destination)
      ) {
        this.login_destination = null;
      }

      // Allow system to be passed in rather than using authservice field. Needed for Flexi 'integrations'
      let integration_system: string;
      if (this.authService.integration_redirect_flag) {
        integration_system = integration_redirect_system || this.authService.integration_redirect.system;
      }

      const params: any = {
        additional_product_params: CoreUtilService.stringifyJSON({
          mobile_device: ignore_mobile_device ? false : !!(window.innerWidth < 768),
          login_destination: this._login_destination,
          integration_redirect_system: integration_system
        })
      };
      if (!!company_product_key) {
        params.company_product_key = company_product_key;
      }
      if (!!product_value) {
        params.product_name = product_value;
      }

      this.dbUtilService.APIPost('company_product/new_session', params, hide_error)
        .then((data) => {

          if (!!data?.refresh_token) {
            const redirect = this.authService.integration_redirect;
            redirect.refresh_token = data.refresh_token;
            this.authService.integration_redirect = redirect;

            resolve(data);
          }
          else if (
            this._login_destination === 'PAYHERO_SHIFT' &&
            data.login_source !== 'PAYHERO_SHIFT'
          ) {
            this._customConfirmModal({
              icon: 'ion-ios-warning',
              title: 'Only Team Managers can sign in to PayHero Shift',
              question: 'Would you like to continue to PayHero instead?',
              buttonTitle: 'Continue to PayHero',
              buttonConfirmClass: '-color-payhero'
            })
              .then(() => {
                this.authService.external_session_key = data.session_key;
                this.authService.external_company_key = data.external_company_reference;
                this.authService.external_user_access_company_key = data.external_user_access_company_key;
                resolve(data);
              })
              .catch(() => reject(null));
          }
          else if (
            !!data?.session_key &&
            !!data?.product_name &&
            (!!data?.external_company_reference || data.login_source === 'DROPPAH_MOBILE')
          ) {
            this.authService.external_session_key = data.session_key;

            if (!!data?.external_company_reference) {
              this.authService.external_company_key = data.external_company_reference;
              this.authService.external_user_access_company_key = data.external_user_access_company_key;
            }
            resolve(data);
          }
          else {
            reject({
              message: AuthService.fallbackAuthError,
              data: null
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  savePayCycle(pay_cycle, payhero_session) {
    return new Promise<any>((resolve, reject) => {

      const data = {
        pay_cycle_name: pay_cycle.pay_cycle_name,
        pay_frequency: pay_cycle.pay_frequency,
        pay_date: TimeUtilService.dateToDateString(pay_cycle.pay_date),
        period_start_date: TimeUtilService.dateToDateString(pay_cycle.period_start_date),
        same_day_of_month: pay_cycle.same_day_of_month,
        deleted_flag: pay_cycle.deleted_flag
      };

      this.dbUtilService.APIPostPayHero('paycycle', data, false, payhero_session)
        .then((res) => resolve(res[0]))
        .catch((err) => reject());
    });
  }

  saveEmployee(employee: PhEmployee, payhero_session) {
    return new Promise<any>((resolve, reject) => {

      employee = employee.formatForPosting();

      this.dbUtilService.APIPostPayHero('employee', employee, false, payhero_session)
        .then((res) => resolve(res[0]))
        .catch((err) => reject(err));
    });
  }

  inviteEmployee(employees: PhEmployee[], payhero_session) {
    return new Promise<void>((resolve, reject) => {

      const data = {
        company_product_key: payhero_session.company_product_key,
        employees
      };

      this.dbUtilService.APIPost('user_access/invite/all', data)
        .then((res) => resolve())
        .catch((err) => reject());
    });
  }

  loadEmployee(employee_key: number, invite_to_portal_flag: boolean, payhero_session) {
    return new Promise<PhEmployee>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('employee', { employee_key }, false, null, payhero_session)
        .then((res) => {
          resolve(this._setupEmployee(res[0], invite_to_portal_flag));
        })
        .catch((err) => reject(err));
    });
  }

  loadEmployees(account: Account, payhero_session) {
    return new Promise<PhEmployee[]>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('employee/all', {}, false, null, payhero_session)
        .then((res) => { resolve(this._setupEmployees(account, res)); })
        .catch((err) => reject(err));
    });
  }

  loadPayCycle(payhero_session) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGetPayHero('paycycle', {}, false, null, payhero_session)
        .then((res) => resolve(res[0]))
        .catch((err) => reject(err));
    });
  }

  private async _customConfirmModal(
    customData: ConfirmModalCustomData
  ) {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(ConfirmModalComponent, { size: 'lg' });

      modal.componentInstance.type = 'Custom';
      modal.componentInstance.customData = customData;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch(() => {
          reject();
        });
    });
  }

  private _setupProductTheme() {
    const product = this._current_product;

    // Set Favicon
    const link: any = document.querySelector('link[rel="shortcut icon"]') || document.createElement('link');
    link.href = this.productConfigs[product.toUpperCase()].favicon;
    document.getElementsByTagName('head')[0].appendChild(link);

    // Set Apple Touch Homescreen Icon
    const apple_touch_link: any = document.querySelector('link[rel="apple-touch-icon"]') || document.createElement('link');
    apple_touch_link.href = this.productConfigs[product.toUpperCase()].appleTouchIcon;
    document.getElementsByTagName('head')[0].appendChild(apple_touch_link);

    //Set Apple Touch Homescreen Title
    let apple_touch_title_string: string = this.productConfigs[product.toUpperCase()].name;
    if (apple_touch_title_string === 'Invoxy') {
      apple_touch_title_string = 'Karmly';
    }
    const apple_touch_title: any = document.querySelector('meta[name="apple-mobile-web-app-title"]') || document.createElement('meta');
    apple_touch_title.content = apple_touch_title_string;
    document.getElementsByTagName('head')[0].appendChild(apple_touch_title);


    // Set tab title
    this.setTitle(null);

    // Set colours
    document.documentElement.style.setProperty('--theme-font-color', this.productConfigs[product].fontColor);
    document.documentElement.style.setProperty('--theme-link-color', this.productConfigs[product].linkColor);
    document.documentElement.style.setProperty('--app-theme-color', this.productConfigs[product].color);
  }

  private _setupEmployees(account: Account, employees: any[]): PhEmployee[] {
    const ph_employees: PhEmployee[] = [];

    const invitee_employee_keys: number[] = [];
    const employee_step = _.find(account.incomplete_onboarding_steps, (step) => step.onboarding_step_name === 'EMPLOYEE');
    if (!!employee_step?.additional_data) {
      for (const e of employee_step.additional_data) {
        invitee_employee_keys.push(e.employee_key);
      }
    }

    for (const e of employees) {
      const employee = this._setupEmployee(e, invitee_employee_keys.includes(e.employee_key));
      ph_employees.push(employee);
    }
    return ph_employees;
  }

  private _setupEmployee(e, invite_to_portal_flag): PhEmployee {
    return new PhEmployee(
      e.employee_key,
      e.employee_code,
      e.first_name,
      e.last_name,
      e.email,
      invite_to_portal_flag,
      TimeUtilService.dateStringToDate(e.employment_start_date),
      e.bank_acc,
      e.salary_flag,
      e.normal_rate,
      e.annual_salary,
      e.ird_number,
      e.tax_code,
      e.hours_per_day,
      e.days_per_week,
      e.hours_mon,
      e.hours_tue,
      e.hours_wed,
      e.hours_thu,
      e.hours_fri,
      e.hours_sat,
      e.hours_sun,
      e.pay_cycle_key,
      e.has_hours_for_weekdays,
      e.has_hours_per_week,
      e.has_days_per_week,
      e.normal_hours_per_week
    );

  }

  getLoginFooterProductLogos(): Record<string, string> {
    return {
      FLEXITIME: 'assets/product-imgs/flexitime/From_FlexiTime.svg',
      PAYHERO: this.productConfigs.PAYHERO.colorBadgeLogo,
      DROPPAH: this.productConfigs.DROPPAH.colorBadgeLogo,
      INVOXY: this.productConfigs.INVOXY.colorBadgeLogo,
      KARMLY: this.productConfigs.KARMLY.colorBadgeLogo
    };
  }

  static getProductValue(product_name: ProductName): ProductValue {
    return this.productNameToProductValueMap[product_name];
  }

  static getProductName(product_value: ProductValue): ProductName {
    return this.productValueToProductNameMap[product_value];
  }
}
